import _remove from 'lodash/remove';

const ioTasks = class {
  constructor() {
    this.tasks = [];
  }

  add(id) {
    this.tasks.push(id);
  };

  remove(id) {
    this.tasks = _remove(this.tasks, (task) => task !== id);
  }

  wait(tasksToWait) {
    let loops = 0;
    const maxLoops = 200;

    return new Promise((resolve) => {
      const interval = setInterval(() => {
        let count = 0;

        loops = loops + 1;

        for (let task of tasksToWait) {
          if (this.tasks.indexOf(task) !== -1) count += 1;
        }

        if (count === 0 || loops >= maxLoops) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  }

  getAll() {
    return this.tasks;
  }
};

export default new ioTasks();