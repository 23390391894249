import memoizeOne from 'memoize-one';
import _orderBy from 'lodash/orderBy';

import mapBy from './mapBy';

/* --------------------------------- ARRAYS --------------------------------- */

// Fields ordered by position
export const calculateOrderedFields = memoizeOne((fields) => {
  return _orderBy(fields, 'position');
});

// Fields that don't have parent
export const calculateTopLevelOrderedFields = memoizeOne((fields) => {
  return calculateOrderedFields(fields).filter((field) => field.section === 'root' || !field.section);
});

// Fields that have a parent
export const calculateNestedOrderedFields = memoizeOne((fields) => {
  return calculateOrderedFields(fields).filter((field) => field.section !== 'root' && field.section);
});

/* ------------------------ MAPS/OBJECTS BY FIELD ID ------------------------ */

// Map of fields by id
export const calculateFieldsMap = memoizeOne((fields) => {
  return mapBy(fields, '_id');
});

// Creates a map where for each key there is an ordered array of given field's children
// Children arrays are computed only based on section prop (children array provided by backend is not used).
// If there is no field in a map it either didn't exist or wasn't a section
export const calculateFieldsChildrenMap = memoizeOne((fields) => {
  const orderedFields = calculateOrderedFields(fields);

  const sectionsMap = new Map();

  for (const section of orderedFields.filter((field) => field.type === 'section')) {
    sectionsMap.set(
      section._id,
      orderedFields.filter((field) => field.section === section._id).map((field) => field._id)
    );
  }

  sectionsMap.set(
    'root',
    orderedFields.filter((field) => field.section === 'root' || !field.section).map((field) => field._id)
  );

  return sectionsMap;
});

// Field, dot separated index (text editor style) that describes nesting and positions
// * This could also be solved with CSS counters but placeholder/ghost elements would break correct ordering.
export const calculateFieldsNumbering = memoizeOne((fields) => {
  const fieldIndexes = {};
  const fieldsMap = calculateFieldsMap(fields);

  const fieldsChildrenMap = calculateFieldsChildrenMap(fields);

  const topLevelOrderedFields = calculateTopLevelOrderedFields(fields);
  const nestedOrderedFields = calculateNestedOrderedFields(fields);

  // Calculates indexes for top level fields first
  let position = 1;
  for (const field of topLevelOrderedFields) {
    fieldIndexes[field._id] = `${position++}.`;
  }

  const getFieldIndex = (field) => {
    if (!field) {
      // Shouldn't happen.
      return null;
    }

    const parentId = field.section;

    const parentChildren = fieldsChildrenMap.get(parentId);

    if (parentChildren) {
      const foundIndex = parentChildren.findIndex((id) => id === field._id);
      if (foundIndex === -1) {
        // Shouldn't happen.
        return null;
      }

      const localIndex = `${foundIndex + 1}.`;

      // Parent index was already calculated
      if (parentId in fieldIndexes) {
        return fieldIndexes[parentId] + localIndex;
      }
      // Parent index needs to be calculated
      else {
        const parent = fieldsMap.get(parentId);
        return getFieldIndex(parent) + localIndex;
      }
    } else {
      // Shouldn't happen.
      return null;
    }
  };

  // Then for nested fields
  for (const field of nestedOrderedFields) {
    fieldIndexes[field._id] = getFieldIndex(field);
  }

  return fieldIndexes;
});

// How deep inside sections tree is a field
export const calculateFieldsIndentLevels = memoizeOne((fields) => {
  const indentLevels = {};
  const fieldsMap = calculateFieldsMap(fields);

  const getFieldIndentLevel = (field) => {
    const parentSection = fieldsMap.get(field.section);

    if (!parentSection) {
      return 0;
    } else {
      // Parent indent was already calculated
      if (parentSection._id in indentLevels) {
        return indentLevels[parentSection._id] + 1;
      }
      // Parent indent needs to be calculated
      else {
        return getFieldIndentLevel(parentSection) + 1;
      }
    }
  };

  for (const field of fields) {
    indentLevels[field._id] = getFieldIndentLevel(field);
  }

  return indentLevels;
});

// Options for all fields
export const calculateFieldsOptions = memoizeOne((fields) => {
  const options = {};

  for (const field of fields) {
    options[field._id] = field.options || [];
  }

  return options;
});

/* ------------------------- SINGLE FIELD PROPERTIES ------------------------ */

// Finds top level parent section of field and returns it or a field if it has no parents.
// Fallbacks to null.
export const calculateFieldTopLevelParent = ({ fieldId, fields }) => {
  if (!fieldId || !fields) return null;

  const fieldsMap = calculateFieldsMap(fields);

  let field = fieldsMap.get(fieldId);

  while (true) {
    const parentId = field?.section;
    if (!parentId || parentId === 'root') {
      break;
    }

    const parentField = fieldsMap.get(parentId);
    if (!parentField) {
      break;
    }

    field = parentField;
  }

  return field || null;
};

// Tries to detect in what page field top level parent is
// (page breaks should not be placed inside of sections so page detection is based
// on top level parent). For page break it returns a number of page that
// this page break has started. Fallbacks to 1 if can't find a field.
export const calculateFieldParentPageNumber = ({ fieldId, fields }) => {
  let page = 1;

  if (!fieldId || !fields) return page;

  const topLevelField = calculateFieldTopLevelParent({ fieldId, fields });
  if (!topLevelField) {
    return page;
  }

  // Detects top level field page by counting how many page breaks were before it
  const topLevelOrderedFields = calculateTopLevelOrderedFields(fields);
  for (const f of topLevelOrderedFields) {
    if (f.type === 'pageBreak') {
      page++;
    }
    if (f._id === topLevelField._id) {
      break;
    }
  }

  return page;
};

// Returns id of passed field and of it's all descendants in occurrence order
export const calculateFieldFamily = ({ fieldId, fields }) => {
  const ids = [];

  const fieldsMap = calculateFieldsMap(fields);
  const fieldsChildrenMap = calculateFieldsChildrenMap(fields);

  const traverseField = (fieldId) => {
    const field = fieldsMap.get(fieldId);
    const children = fieldsChildrenMap.get(fieldId);

    if (field) {
      ids.push(fieldId);
    }
    if (children) {
      for (const childId of children) {
        traverseField(childId);
      }
    }
  };
  traverseField(fieldId);

  return ids;
};

/* ---------------------------- ARRAYS - COMPLEX ---------------------------- */

// Returns array of field ids that describes order as seen in navigator
// (including ordering within sections)
export const calculateDeepOrderedFieldsIds = memoizeOne((fields) => {
  return calculateFieldFamily({ fieldId: 'root', fields });
});

// Same as above but returns fields objects
export const calculateDeepOrderedFields = memoizeOne((fields) => {
  const fieldsMap = calculateFieldsMap(fields);
  const ids = calculateDeepOrderedFieldsIds(fields);
  return ids.map((_id) => fieldsMap.get(_id));
});
