/*
    Creates all permutations of a path:
    Example:
    
    parseObjectPathWithArrays(['fields', 'thankYouPages'], '393893893', ['condition', 'type'])
        =>
    ["fields.393893893.condition", "fields.393893893.type", "thankYouPages.393893893.condition", "thankYouPages.393893893.type"]
*/
const parseObjectPathWithArrays = (...objPath) => {
  if (!objPath.length) return [];

  let pathPerms = Array.isArray(objPath[0]) ? objPath[0] : [objPath[0]];

  const _parseLevel = (i) => {
    if (i > objPath.length - 1) return;

    const key = objPath[i];

    if (Array.isArray(key)) {
      const nextPathPerms = [];

      // Extends paths with all possible permutations with array of keys
      for (const path of pathPerms) {
        for (const nextPathPart of key) {
          nextPathPerms.push(`${path}.${nextPathPart}`);
        }
      }

      pathPerms = nextPathPerms;
    } else {
      // Appends another key to path/paths
      const nextPathPart = key;

      for (let i in pathPerms) {
        pathPerms[i] += `.${nextPathPart}`;
      }
    }

    _parseLevel(i + 1);
  };
  _parseLevel(1);

  return pathPerms;
};

export default parseObjectPathWithArrays;
