import ContainerStyles from './ContainerStyles.module.css';

import React, { useMemo, useRef, useEffect } from 'react';

import PopupsContext from './PopupsContext';
import { Provider as ReactAlertProvider, useAlert } from 'react-alert';

import Snackbar from './components/SnackbarTemplate';

/* -------------------------------------------------------------------------- */
/*                             REACT ALERT CONFIG                             */
/* -------------------------------------------------------------------------- */

// https://github.com/schiehll/react-alert

const REACT_ALERT_SNACKBAR_PROVIDER_PROPS = {
  context: React.createContext(),

  className: ContainerStyles.snackbarContainer,
  template: Snackbar,
  position: 'bottom right',
  timeout: undefined,
  transition: 'fade',
  containerStyle: {
    zIndex: 3000
  }
};

const withReactAlertProviders = (Component) =>
  React.memo((props) => (
    <ReactAlertProvider {...REACT_ALERT_SNACKBAR_PROVIDER_PROPS}>
      <Component {...props} />
    </ReactAlertProvider>
  ));

/* -------------------------------------------------------------------------- */
/*                        MAIN POPUP PROVIDER COMPONENT                       */
/* -------------------------------------------------------------------------- */

const PopupsProvider = React.memo(({ children }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const snackbarsApiRef = useRef(null);
  snackbarsApiRef.current = useAlert(REACT_ALERT_SNACKBAR_PROVIDER_PROPS.context);

  /* -------------------------------- REDUCERS -------------------------------- */

  const snackbarsReducers = useMemo(() => {
    const prepareProps = (props) => (typeof props === 'string' ? { message: props } : props);

    const showSnackbar = (props) => {
      props = prepareProps(props);

      const snackbarsApi = snackbarsApiRef.current;

      const capturedReducers = {
        propsUpdater: null,
        propsSetter: null
      };

      const updateProps = (changes) => {
        if (capturedReducers.propsUpdater) {
          capturedReducers.propsUpdater(changes);
        }
      };
      const setProps = (nextProps) => {
        if (capturedReducers.propsUpdater) {
          capturedReducers.propsSetter(nextProps);
        }
      };

      const snackbar = snackbarsApi.show('', {
        // Passes non react-alert props through options
        initialProps: props,
        getPropsUpdater: (func) => (capturedReducers.propsUpdater = func),
        getPropsSetter: (func) => (capturedReducers.propsSetter = func)
      });

      return {
        close: snackbar.close,
        setProps,
        updateProps
      };
    };

    return {
      showSnackbar,
      showSuccessSnackbar: (props) => showSnackbar({ ...prepareProps(props), type: 'success' }),
      showErrorSnackbar: (props) => showSnackbar({ ...prepareProps(props), type: 'error' }),
      showWarningSnackbar: (props) => showSnackbar({ ...prepareProps(props), type: 'warning' }),
      showInfoSnackbar: (props) => showSnackbar({ ...prepareProps(props), type: 'info' })
    };
  }, []);

  useEffect(() => {
    // TEMPORARY: Easy way to provide this function to all reducers
    window.QS.showError = snackbarsReducers.showErrorSnackbar;
    window.QS.showSuccess = snackbarsReducers.showSuccessSnackbar;
    window.QS.showInfo = snackbarsReducers.showInfoSnackbar;
    return () => {
      window.QS.showError = null;
      window.QS.showSuccess = null;
      window.QS.showInfo = null;
    };
  }, [snackbarsReducers]);

  /* --------------------------------- CONTEXT -------------------------------- */

  const context = useMemo(() => {
    return {
      ...snackbarsReducers
    };
  }, [snackbarsReducers]);

  /* ----------------------------------- JSX ---------------------------------- */

  return <PopupsContext.Provider value={context}>{children}</PopupsContext.Provider>;
});

export default withReactAlertProviders(PopupsProvider);
