import checkLogic from 'helpers/checkLogic.js';

export default function getThankYouPage(defaultThankYouPage, thankYouPages, fields, values, variables, forceThankYouPage) {
  // Fix enforced thank you page by builder navigator
  if (forceThankYouPage && forceThankYouPage === 'default') {
    return defaultThankYouPage;
  }

  let selected = defaultThankYouPage;

  if (Array.isArray(thankYouPages)) {
    for (let page of thankYouPages) {
      // Fix enforced thank you page by builder navigator
      if (forceThankYouPage && forceThankYouPage === page._id) {
        selected = page;
        break;
      }

      if (checkLogic(page.conditions, page.conditionsType, page.calculationConditions, values, fields, variables)) {
        selected = page;
        break;
      }
    }
  }

  return selected;
}
