import axios from 'axios';
import _remove from 'lodash/remove';

import config from 'config.js';

// Constants
const CLEAR = 'dashboard/CLEAR';

const SHOW_DELETE_FORM_MODAL = 'dashboard/SHOW_DELETE_FORM_MODAL';
const HIDE_DELETE_FORM_MODAL = 'dashboard/HIDE_DELETE_FORM_MODAL';

const SHOW_DELETE_WORKSPACE_MODAL = 'dashboard/SHOW_DELETE_WORKSPACE_MODAL';
const HIDE_DELETE_WORKSPACE_MODAL = 'dashboard/HIDE_DELETE_WORKSPACE_MODAL';

const SHOW_CREATE_WORKSPACE_MODAL = 'dashboard/SHOW_CREATE_WORKSPACE_MODAL';
const HIDE_CREATE_WORKSPACE_MODAL = 'dashboard/HIDE_CREATE_WORKSPACE_MODAL';

const SHOW_CREATE_FORM_MODAL = 'dashboard/SHOW_CREATE_FORM_MODAL';
const HIDE_CREATE_FORM_MODAL = 'dashboard/HIDE_CREATE_FORM_MODAL';

const SHOW_WORKSPACE_USERS_MODAL = 'dashboard/SHOW_WORKSPACE_USERS_MODAL';
const HIDE_WORKSPACE_USERS_MODAL = 'dashboard/HIDE_WORKSPACE_USERS_MODAL';

// Initiual State
const initialState = {
  deleteFormModal: {
    form: null,
    _show: false
  },

  deleteWorkspaceModal: {
    workspace: null,
    _show: false
  },

  createEditWorkspaceModal: {
    workspace: null,
    _show: false
  },

  workspaceUsersModal: {
    workspace: null,
    _show: false
  },

  createFormModal: {
    workspace: null,
    _show: false
  }
};

// Reducer
export default function reducer(state = JSON.parse(JSON.stringify(initialState)), action = {}) {
  if (action.type === CLEAR) {
    state = JSON.parse(JSON.stringify(initialState));

    return { ...state };
  }

  // deleteFormModal
  if (action.type === SHOW_DELETE_FORM_MODAL) {
    state.deleteFormModal._show = true;
    state.deleteFormModal.form = action.payload.form;

    return { ...state };
  }

  if (action.type === HIDE_DELETE_FORM_MODAL) {
    state.deleteFormModal._show = false;
    state.deleteFormModal = { ...initialState.createFormModal };

    return { ...state };
  }

  // deleteFormModal
  if (action.type === SHOW_DELETE_WORKSPACE_MODAL) {
    state.deleteWorkspaceModal._show = true;
    state.deleteWorkspaceModal.workspace = action.payload.workspace;

    return { ...state };
  }

  if (action.type === HIDE_DELETE_WORKSPACE_MODAL) {
    state.deleteWorkspaceModal._show = false;
    state.deleteWorkspaceModal = { ...initialState.createFormModal };

    return { ...state };
  }

  // createEditWorkspaceModal
  if (action.type === SHOW_CREATE_WORKSPACE_MODAL) {
    state.createEditWorkspaceModal._show = true;
    state.createEditWorkspaceModal.workspace = action.payload.workspace;

    return { ...state };
  }

  if (action.type === HIDE_CREATE_WORKSPACE_MODAL) {
    state.createEditWorkspaceModal._show = false;
    state.createEditWorkspaceModal = { ...initialState.createEditWorkspaceModal };

    return { ...state };
  }

  // createFormModal
  if (action.type === SHOW_CREATE_FORM_MODAL) {
    state.createFormModal._show = true;
    state.createFormModal.workspace = action.payload.workspace;

    return { ...state };
  }

  if (action.type === HIDE_CREATE_FORM_MODAL) {
    state.createFormModal._show = false;
    state.createFormModal = { ...initialState.createFormModal };

    return { ...state };
  }

  // workspaceUsersModal
  if (action.type === SHOW_WORKSPACE_USERS_MODAL) {
    state.workspaceUsersModal._show = true;
    state.workspaceUsersModal.workspace = action.payload.workspace;

    return { ...state };
  }

  if (action.type === HIDE_WORKSPACE_USERS_MODAL) {
    state.workspaceUsersModal._show = false;
    state.workspaceUsersModal = { ...initialState.workspaceUsersModal };

    return { ...state };
  }

  return state;
}

// Action Creators
export function clear() {
  return (dispatch) => {
    dispatch({ type: CLEAR });
  };
}

// deleteFormModal
export function showDeleteFormModal(form) {
  return (dispatch) => {
    dispatch({ type: SHOW_DELETE_FORM_MODAL, payload: { form } });
  };
}

export function hideDeleteFormModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_DELETE_FORM_MODAL });
  };
}

// workspaceUsersModal
export function showWorkspaceUsersModal(workspace) {
  return (dispatch) => {
    dispatch({ type: SHOW_WORKSPACE_USERS_MODAL, payload: { workspace } });
  };
}

export function hideWorkspaceUsersModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_WORKSPACE_USERS_MODAL });
  };
}

// deleteWorkspaceModal
export function showDeleteWorkspaceModal(workspace) {
  return (dispatch) => {
    dispatch({ type: SHOW_DELETE_WORKSPACE_MODAL, payload: { workspace } });
  };
}

export function hideDeleteWorkspaceModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_DELETE_WORKSPACE_MODAL });
  };
}

// createEditWorkspaceModal
export function showCreateEditWorkspaceModal(workspace) {
  return (dispatch) => {
    dispatch({ type: SHOW_CREATE_WORKSPACE_MODAL, payload: { workspace } });
  };
}

export function hideCreateEditWorkspaceModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_CREATE_WORKSPACE_MODAL });
  };
}

// createFormsModal
export function showCreateFormModal(workspace) {
  return (dispatch) => {
    dispatch({ type: SHOW_CREATE_FORM_MODAL, payload: { workspace } });
  };
}

export function hideCreateFormModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_CREATE_FORM_MODAL });
  };
}
