import axios from 'axios';
import _remove from 'lodash/remove';

import config from 'config.js';

// Constants
const GET_SMTPS_REQUEST = '_smtps/GET_SMTPS_REQUEST';
const GET_SMTPS_SUCCESS = '_smtps/GET_SMTPS_SUCCESS';
const GET_SMTPS_FAILURE = '_smtps/GET_SMTPS_FAILURE';

const UPDATE_SMTP_REQUEST = '_forms/UPDATE_SMTP_REQUEST';
const UPDATE_SMTP_SUCCESS = '_forms/UPDATE_SMTP_SUCCESS';
const UPDATE_SMTP_FAILURE = '_forms/UPDATE_SMTP_FAILURE';

const TEST_SMTP_REQUEST = '_forms/TEST_SMTP_REQUEST';
const TEST_SMTP_SUCCESS = '_forms/TEST_SMTP_SUCCESS';
const TEST_SMTP_FAILURE = '_forms/TEST_SMTP_FAILURE';

const CREATE_SMTP_REQUEST = '_smtps/CREATE_SMTP_REQUEST';
const CREATE_SMTP_SUCCESS = '_smtps/CREATE_SMTP_SUCCESS';
const CREATE_SMTP_FAILURE = '_smtps/CREATE_SMTP_FAILURE';

const DELETE_SMTP_REQUEST = '_smtps/DELETE_SMTP_REQUEST';
const DELETE_SMTP_SUCCESS = '_smtps/DELETE_SMTP_SUCCESS';
const DELETE_SMTP_FAILURE = '_smtps/DELETE_SMTP_FAILURE';

const RESET_ENDPOINT_STATE = '_smtps/RESET_ENDPOINT_STATE';

const endpoitInitialStatus = {
  loading: false,
  error: false,
  success: false,
  errors: []
};

// Initiual State
const initialState = {
  smtps: [],

  getSmtps: { ...endpoitInitialStatus },
  createSmtp: { ...endpoitInitialStatus },
  updateSmtp: { ...endpoitInitialStatus },
  deleteSmtp: { ...endpoitInitialStatus }
};

const getEndpointStatus = (type, errors = []) => {
  const types = {
    request: {
      loading: true,
      error: false,
      success: false,
      errors: []
    },
    success: {
      loading: false,
      error: false,
      success: true,
      errors: []
    },
    failure: {
      loading: false,
      error: true,
      success: false,
      errors
    },
  };

  return types[type];
};

// Reducer
export default function reducer(state = JSON.parse(JSON.stringify(initialState)), action = {}) {
  if (action.type === RESET_ENDPOINT_STATE) {
    state[action.payload.name] = JSON.parse(JSON.stringify(endpoitInitialStatus));

    return { ...state };
  }

  // getSmtps
  if (action.type === GET_SMTPS_REQUEST) {
    state.getSmtps = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === GET_SMTPS_SUCCESS) {
    state.getSmtps = getEndpointStatus('success');

    state.smtps = [...action.payload];

    return { ...state };
  }

  if (action.type === GET_SMTPS_FAILURE) {
    state.getSmtps = getEndpointStatus('failure');

    return { ...state };
  }

  // updateSmtp
  if (action.type === UPDATE_SMTP_REQUEST) {
    state.updateSmtp = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === UPDATE_SMTP_SUCCESS) {
    const index = state.smtps.findIndex((smtp) => smtp._id === action.payload.data._id);

    state.updateSmtp = getEndpointStatus('success');
    state.smtps[index] = { ...state.smtps[index], ...action.payload.data };
    state.smtps = [...state.smtps];

    return { ...state };
  }

  if (action.type === UPDATE_SMTP_FAILURE) {
    state.updateSmtp = getEndpointStatus('failure', [action.payload.message]);

    return { ...state };
  }

  // createSmtp
  if (action.type === CREATE_SMTP_REQUEST) {
    state.createSmtp = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === CREATE_SMTP_SUCCESS) {
    state.createSmtp = getEndpointStatus('success');

    return { ...state };
  }

  if (action.type === CREATE_SMTP_FAILURE) {
    state.createSmtp = getEndpointStatus('failure', [action.payload.message]);

    return { ...state };
  }

  // deleteSmtp
  if (action.type === DELETE_SMTP_REQUEST) {
    state.deleteSmtp = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === DELETE_SMTP_SUCCESS) {
    state.deleteSmtp = getEndpointStatus('success');
    state.smtps = _remove(state.smtps, (smtp) => smtp._id !== action.payload.id);

    return { ...state };
  }

  if (action.type === DELETE_SMTP_FAILURE) {
    state.deleteSmtp = getEndpointStatus('failure');

    return { ...state };
  }
  return state;
}

// Action Creators
export function resetEndpoitState(name) {
  return (dispatch) => {
    dispatch({ type: RESET_ENDPOINT_STATE, payload: { name } });
  };
}

export function getSmtps() {
  const request = () => { return { type: GET_SMTPS_REQUEST } };
  const success = (data) => { return { type: GET_SMTPS_SUCCESS, payload: data } };
  const failure = () => { return { type: GET_SMTPS_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getSmtps(state._users.token);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}

export function createSmtp(data) {
  const request = () => { return { type: CREATE_SMTP_REQUEST } };
  const success = () => { return { type: CREATE_SMTP_SUCCESS } };
  const failure = (data) => { return { type: CREATE_SMTP_FAILURE, payload: data } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.createSmtp(state._users.token, data);

    if (response.status === 200) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure(response.data));
    }
  };
}

export function testSmtp(data) {
  const request = () => { return { type: TEST_SMTP_REQUEST } };
  const success = (data) => { return { type: TEST_SMTP_SUCCESS, payload: data } };
  const failure = (data) => { return { type: TEST_SMTP_FAILURE, payload: data } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.testSmtp(state._users.token, data);

    if (response.status === 200) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure(response.data));
    }
  };
}

export function updateSmtp(id, data) {
  const request = () => { return { type: UPDATE_SMTP_REQUEST } };
  const success = (data) => { return { type: UPDATE_SMTP_SUCCESS, payload: { data } } };
  const failure = (data) => { return { type: UPDATE_SMTP_FAILURE, payload: data } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.updateSmtp(state._users.token, id, data);

    if (response.status === 200) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure(response.data));
    }
  };
}

export function deleteSmtp(id) {
  const request = () => { return { type: DELETE_SMTP_REQUEST } };
  const success = (id) => { return { type: DELETE_SMTP_SUCCESS, payload: { id } } };
  const failure = () => { return { type: DELETE_SMTP_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request(id));

    const state = getState();
    const response = await services.deleteSmtp(state._users.token, id);

    if (response) {
      return dispatch(success(id));
    } else {
      return dispatch(failure());
    }
  };
}

const services = {
  getSmtps: async (token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/smtps`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateSmtp: async (token, id, data) => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${config.apiUrl}/smtps/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  createSmtp: async (token, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/smtps`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  testSmtp: async (token, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/smtps/null/test`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  deleteSmtp: async (token, id) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${config.apiUrl}/smtps/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  }
};
