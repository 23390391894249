import { useContext } from 'react';
import { ActivityContext } from 'contexts';

const useActivity = () => {
  const { activity } = useContext(ActivityContext);

  return activity;
};

export default useActivity;
