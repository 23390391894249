import styles from './QSFormLabel.module.css';

import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Icon, Popover, TransparentInput } from 'ui-components';

import QSFormStatus from '../QSFormStatus/QSFormStatus';

import { MainContext, BuilderContext } from 'contexts';

import { useSearchParams } from 'helpers';

/* -------------------------------------------------------------------------- */
/*                        FORM HANDLING RENAME FEATURE                        */
/* -------------------------------------------------------------------------- */

// onChange will be called on blur or on enter
const QSFormNameEditor = ({ value, onChanged }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const handleBlur = () => {
    onChanged(localValue);
  };

  const handleLocalChange = (e) => {
    setLocalValue(e.target.value);
  };

  /* ----------------------------------- JSX ---------------------------------- */

  return <TransparentInput autoFocus blurOnEnter value={localValue} onChange={handleLocalChange} onBlur={handleBlur} />;
};

/* -------------------------------------------------------------------------- */
/*                              MEMOIZED RENDERER                             */
/* -------------------------------------------------------------------------- */

const QSFormLabelRenderer = React.memo(
  ({ className, workspaceId, workspaceName, formId, formName, onFormNameChange, onFormStatusChange, onFormDelete, status }) => {
    const history = useHistory();

    const [searchParams, setSearchParams] = useSearchParams({
      publishingFlowTab: null,
      publishingFlowEmbedType: null
    });

    const openPublishing = () => {
      setSearchParams({ modal: 'publishingFlow', publishingFlowTab: null });
    };
    const openAvailibilitySettings = () => {
      setSearchParams({ modal: 'publishingFlow', publishingFlowTab: 'availability' });
    };

    /* ------------------------------ FETCHED DATA ------------------------------ */

    const { user } = useContext(MainContext);
    const userCanEdit = Boolean(user?.permissions[formId]?.edit);
    const userCanDelete = Boolean(user?.permissions[formId]?.delete);

    /* ---------------------------------- STATE --------------------------------- */

    const [mode, setMode] = useState(null);

    const isDropdownVisible = mode === 'dropdown';
    const isInRenameMode = mode === 'rename';

    /* ----------------------------- EVENT HANDLERS ----------------------------- */

    const handleWorkspaceClick = () => {
      history.push(`/workspace/${workspaceId}`);
    };

    const handleOpenModal = () => {
      if (userCanEdit || userCanDelete) {
        // Modal can be open only if rename mode is not active,
        // and if modal is not already open
        setMode((mode) => (mode === null ? 'dropdown' : mode));
      }
    };

    const handleReset = () => {
      // Delayed to move dropdown close request after dropdown open when button inside dropdown is clicked
      setTimeout(() => setMode(null));
    };

    /* -------------------------- EVENT HANDLERS - EDIT ------------------------- */

    const handleEnterRenameMode = () => {
      if (userCanEdit) {
        setMode('rename');
      }
    };

    const handleFormNameChange = (nextValue) => {
      handleReset();
      if (userCanEdit) {
        onFormNameChange(nextValue);
      }
    };

    const handlePublishForm = () => {
      handleReset();
      if (userCanEdit) {
        if (status === 'paused') {
          openAvailibilitySettings();
        } else {
          openPublishing();
        }

        // Delays change so that users can see how toggle moves
        setTimeout(() => {
          onFormStatusChange('live');
        }, 500);
      }
    };

    const handlePauseForm = () => {
      handleReset();
      if (userCanEdit) {
        openAvailibilitySettings();

        // Delays change so that users can see how toggle moves
        setTimeout(() => {
          onFormStatusChange('paused');
        }, 500);
      }
    };

    /* ----------------------------------- JSX ---------------------------------- */

    if (isInRenameMode) {
      return <QSFormNameEditor value={formName} onChanged={handleFormNameChange} />;
    }

    return (
      <div
        className={[className, styles.formLabel].join(' ')}
        onClick={handleOpenModal}
        style={{ cursor: userCanEdit || userCanDelete ? 'pointer' : 'default' }}>
        <div className={styles.breadcrumbs}>
          <span className={styles.workspace} onClick={handleWorkspaceClick}>
            {workspaceName || workspaceId}
          </span>
          <span className={styles.slash}>/</span>
          <span className={styles.form}>{formName || 'Untitled Form'}</span>
        </div>
        <div className={styles.status}>
          <QSFormStatus status={status} />
        </div>

        {(userCanEdit || userCanDelete) && (
          <Popover
            theme="black"
            placement="bottom"
            visible={isDropdownVisible}
            onRequestClose={handleReset}
            content={
              <Popover.Group>
                {userCanEdit && (
                  <>
                    {onFormStatusChange && status === 'live' && (
                      <Popover.Button icon="pause" onClick={handlePauseForm}>
                        Pause
                      </Popover.Button>
                    )}
                    {onFormStatusChange && (status === 'disabled' || status === 'draft' || status === 'paused') && (
                      <Popover.Button icon="publish" onClick={handlePublishForm}>
                        Publish
                      </Popover.Button>
                    )}
                    <Popover.Button icon="edit-solid" onClick={handleEnterRenameMode}>
                      Rename
                    </Popover.Button>
                  </>
                )}
                {userCanDelete && (
                  <Popover.Button icon="delete" delay onClick={onFormDelete}>
                    Delete
                  </Popover.Button>
                )}
              </Popover.Group>
            }>
            <div className={styles.icon}>
              <Icon id="dropdown-closed-thin" />
            </div>
          </Popover>
        )}
      </div>
    );
  }
);

/* -------------------------------------------------------------------------- */
/*                    COMPONENT PROVIDING DATA AND CONTEXT                    */
/* -------------------------------------------------------------------------- */

const QSFormLabel = ({ className = '', form }) => {
  const { updateForm, deleteForm } = useContext(BuilderContext);

  const handleFormNameChange = useCallback(
    (nextName) => {
      updateForm({ name: nextName });
    },
    [updateForm]
  );

  const handleFormDelete = useCallback(() => {
    deleteForm();
  }, [deleteForm]);

  const handleFormStatusChange = useCallback(
    (nextStatus) => {
      updateForm({ status: nextStatus });
    },
    [updateForm]
  );

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <QSFormLabelRenderer
      className={className}
      status={form?.status}
      workspaceId={form?.workspace?._id}
      workspaceName={form?.workspace?.name}
      formId={form?._id}
      formName={form?.name}
      onFormDelete={handleFormDelete}
      onFormNameChange={handleFormNameChange}
      onFormStatusChange={handleFormStatusChange}
    />
  );
};

export default QSFormLabel;
