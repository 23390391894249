/* --------------------------------- CONFIG --------------------------------- */

const CONDITION_OPTIONS_BY_DATA_TYPE = {
  string: [
    { value: 'contains', label: 'Contains', input: 'text' },
    { value: 'startsWith', label: 'Starts with', input: 'text' },
    { value: 'endsWith', label: 'Ends with', input: 'text' },
    { value: 'notEmpty', label: 'In not empty', input: null },
    { value: 'isEmpty', label: 'Is empty', input: null }
  ],
  number: [
    { value: 'eual', label: 'Equal to', input: 'number' },
    { value: 'more', label: 'Greater than', input: 'number' },
    { value: 'moreOrEqual', label: 'Greater or equal', input: 'number' },
    { value: 'less', label: 'Smaller than', input: 'number' },
    { value: 'lessOrEqual', label: 'Smaller or equal', input: 'number' },
    { value: 'range', label: 'Has a min and max of', input: 'range' }
  ],
  date: [
    { value: 'isBefore', label: 'Is before', input: 'date' },
    { value: 'isAfter', label: 'Is after', input: 'date' }
  ],
  option: [
    { value: 'is', label: 'Is', input: 'select' },
    { value: 'includes', label: 'Includes', input: 'multi-select' }
  ]
};

export default CONDITION_OPTIONS_BY_DATA_TYPE;

/* ------------------------------- VALIDATION ------------------------------- */

const ALLOWED_INPUTS = [null, 'text', 'number', 'range', 'date', 'select', 'multi-select'];

export const assertConditionOptions = (options, allowedConditions = null) => {
  for (const option of options) {
    if (
      !option ||
      !option.label ||
      (allowedConditions && !allowedConditions.includes(option.value)) ||
      !ALLOWED_INPUTS.includes(option.input)
    ) {
      throw new Error(`DEBUG: invalid filtering condition option. ${JSON.stringify(option, 0, 2)}`);
    }
  }
};

if (process.env.NODE_ENV === 'development') {
  const ALLOWED_PREDEFINED_CONDITIONS = [
    'eual',
    'contains',
    'startsWith',
    'endsWith',
    'notEmpty',
    'isEmpty',
    'more',
    'moreOrEqual',
    'less',
    'lessOrEqual',
    'range',
    'is',
    'includes',
    'isBefore',
    'isAfter'
  ];

  for (const type in CONDITION_OPTIONS_BY_DATA_TYPE) {
    const options = CONDITION_OPTIONS_BY_DATA_TYPE[type];
    assertConditionOptions(options, ALLOWED_PREDEFINED_CONDITIONS);
  }
}
