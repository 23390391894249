import styles from './BooleanPreview.module.css';

import React from 'react';

import EmptyPreview from './EmptyPreview';

const BooleanPreview = ({ theme, value = null, trueMessage = 'Yes', falseMessage = 'No', placeholder }) => {
  let parsedValue = null;
  if (value === true || String(value).toLowerCase() === 'true' || String(value).toLowerCase() === 'yes') {
    parsedValue = true;
  } else if (value === false || String(value).toLowerCase() === 'false' || String(value).toLowerCase() === 'no') {
    parsedValue = false;
  }

  if (parsedValue === true) {
    return <div className={[styles.booleanPreview, styles.truthy, styles[`theme-${theme}`]].join(' ')}>{trueMessage}</div>;
  } else if (parsedValue === false) {
    return <div className={[styles.booleanPreview, styles.falsy, styles[`theme-${theme}`]].join(' ')}>{falseMessage}</div>;
  } else if (parsedValue === null) {
    return <EmptyPreview placeholder={placeholder} />;
  } else {
    console.error('DEBUG: unexpected value', value);
    return null;
  }
};

export default BooleanPreview;
