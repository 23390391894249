import styles from './Label.module.css';

import React from 'react';
import { Icon } from '../Icon/Icon.js';
import { Checkbox } from '../Checkbox/Checkbox.js';

export const Label = ({
  children,
  tip,
  button,
  style,
  buttonOnClick,
  checkboxValue,
  checkboxOnClick,
  checkboxDisabled,
  checkboxLoading,
  checkboxAutoLoading
}) => {
  if (checkboxDisabled) checkboxOnClick = null;

  return (
    <>
      <div className={styles.main} style={style}>
        <label className={checkboxOnClick ? styles.withClick : ''}>
          <span onClick={checkboxOnClick}>{children}</span>
          {tip && (
            <div className={styles.tip}>
              <Icon id="help" tip={tip} />
            </div>
          )}
        </label>

        {button && (
          <div className={styles.button} onClick={buttonOnClick}>
            {button}
          </div>
        )}

        {checkboxOnClick && (
          <Checkbox
            className={styles.checkbox}
            loading={checkboxLoading}
            autoLoading={checkboxAutoLoading}
            value={checkboxValue}
            onClick={checkboxOnClick}
            disabled={checkboxDisabled}
          />
        )}
      </div>
    </>
  );
};
