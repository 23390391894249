import styles from './SelectModal.module.css';
import React, { useEffect, useState } from 'react';

import { Modal } from '../Modal/Modal';
import { Button } from '../Button/Button';
import TabsHeader from '../TabsHeader/TabsHeader';
import { Select } from '../Select/Select';
import { Icon } from '../Icon/Icon';
import Text from '../Text/Text';

// Messaging modal
// isOpen prop opens modal
// calls: onRequestClose(null) on cancel
// calls: onRequestClose(formId) on import
const SelectModal = ({
  className,
  style,
  isOpen,
  initialValue = null,
  title = 'Placeholder title',
  label = 'Placeholder text above select',
  labelTooltip = null,
  description = 'Placeholder text under select',
  descriptionIcon = null,
  confirm = 'Ok', // Message when change is from something to something
  clear = 'Clear', // Message when change is from something to null
  cancel = 'Cancel',
  options,
  onConfirm,
  onCancel,
  allowNull,
  ...rest
}) => {
  const [selectValue, setSelectValue] = useState(null);

  useEffect(() => {
    setSelectValue(initialValue);
  }, [initialValue]);

  const isNull = selectValue === null;
  const isInitial = selectValue === initialValue;
  return (
    <Modal
      {...rest}
      isOpen={isOpen}
      loading={rest.loading || !options}
      onRequestClose={() => {
        onCancel();
      }}
      title={<TabsHeader>{title}</TabsHeader>}
      footer={
        <div className={styles.buttons}>
          <Button theme="transparent" onClick={() => onCancel()}>
            {cancel}
          </Button>
          <Button theme="black" disabled={(isInitial && isNull) || (!allowNull && isNull)} onClick={() => onConfirm(selectValue)}>
            {allowNull ? (isNull && !isInitial ? clear || confirm : confirm) : confirm}
          </Button>
        </div>
      }>
      <div className={className} style={style}>
        <div className={styles.label}>
          <Text theme="description" weight="medium">
            {label}
          </Text>
          {labelTooltip && <Icon id="help" tip={labelTooltip} />}
        </div>
        <Select
          options={options || []}
          isClearable={true}
          placeholder="— Select —"
          value={selectValue}
          onChange={(data) => setSelectValue((data && data.value) || null)}
        />
        <div className={styles.description}>
          {descriptionIcon && <Icon id={'information'} />}
          <Text theme="description">{description}</Text>
        </div>
      </div>
    </Modal>
  );
};

export default SelectModal;
