import styles from './Icon.module.css';

import React, { forwardRef } from 'react';
import { Spinner } from './../Spinner/Spinner.js';
import icons from './list.json';

import Tooltip from '../Tooltip/Tooltip.js';

export const Icon = forwardRef(({ id, color, animation, className, tip, scale, ...rest }, ref) => {
  const classNames = [
    'icon',
    styles.icon,
    className,
    color ? styles.inheritColor : '',
    //  Temporary to see how it looks like
    animation ? styles[`animation-${animation}`] : ''
  ].join(' ');

  if (!icons[id]) return null;

  const style = {};
  if (color) style.color = color;
  if (scale) style.transform = `scale(${scale})`;

  const iconJsx = (
    <div style={style} ref={ref} data-component="icon" className={classNames} dangerouslySetInnerHTML={{ __html: icons[id] }} {...rest} />
  );
  if (tip) {
    return (
      <Tooltip content={tip} delay={0}>
        {iconJsx}
      </Tooltip>
    );
  } else return iconJsx;
});

export const IconAddWithLoader = forwardRef(({ style, className, onClick, loading }, ref) => {
  return (
    <div
      className={[styles.addIconWithLoader, loading ? styles.loading : '', className].join(' ')}
      style={style}
      onClick={onClick}
      ref={ref}>
      <div className={styles.activeShadow} />
      <Icon id="open-sq" className={styles.button} />
      <Spinner size={8} borderSize={0.2} speed="0.8s" className={styles.spinner} />
    </div>
  );
});

export const IconUpgrade = forwardRef(({ style, className, color }, ref) => {
  return (
    <div className={[styles.upgradeIcon, styles[color], className].join(' ')} style={style} ref={ref}>
      <Icon id={`upgrade-${color}`} />
      Get feature
    </div>
  );
});

export const IconInRectangle = forwardRef(({ className, id, tip, tipSingleton = undefined, animation, ...rest }, ref) => {
  const iconJsx = (
    <div className={[className, styles.iconInRectangle].join(' ')} {...rest} ref={ref}>
      <Icon id={id} animation={animation} />
    </div>
  );

  if (tip) {
    return (
      <Tooltip content={tip} singleton={tipSingleton} delay={0}>
        {iconJsx}
      </Tooltip>
    );
  } else return iconJsx;
});
