import styles from './Avatar.module.css';

import React from 'react';
import ColorHash from 'color-hash';

const colorHash = new ColorHash({ lightness: 0.8, saturation: 0.5 });

export const Avatar = ({ name, email, size, tooltip, clickable, border }) => {
  const initials = (name || email).split(' ').map((n) => n[0]).join('').toUpperCase();
  const color = colorHash.hex(name || email);
  const className = [styles.avatar];

  if (border) {
    className.push(styles.border);
  }

  return (
    <>
      <div className={className.join(' ')} style={{ cursor: clickable ? 'pointer' : 'default', background: color, minWidth: size, minHeight: size }}>
        <span>{initials}</span>
      </div>
    </>
  );
};
