import { useState, useEffect, useCallback, useRef } from 'react';
import { cloneDeep as _cloneDeep, isString as _isString } from 'lodash';

import {
  usePrevious,
  objectBy,
  calculateDeepOrderedFieldsIds,
  calculateFieldsMap,
  checkFieldLogic,
  getVariables,
  getFieldLabel
} from 'helpers';

import fastDeepEqual from 'fast-deep-equal';

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

/*
  Notes for future:
  - values objects describe not only fields values but full field states (value + visibility + other things)
*/

const parsePreFilledValue = (value, urlParams = []) => {
  const isString = _isString(value);
  const isSlateLikeString = isString && value.trim().indexOf('[{') === 0;

  if (isSlateLikeString) {
    const result = getFieldLabel(value, undefined, 'text', undefined, undefined, urlParams);
    return typeof result === 'string' ? result : value;
  } else {
    return value;
  }
};

// Used to re-calculate values and variables after props that can affect them change,
// or on user input (valuesChanges). When old values are passed values from them will be used
// during calculations, if not values will be initialized based on field configuration.
const calculateNextValuesAndVariables = ({
  // Current values by field _id
  values = {},
  // Values changes by field _id
  valuesChanges = {},
  // Form state
  fields = [],
  previousFields = [],
  calculationVariables = [],
  urlParams = [],
  // Other state
  previewLogic = true
}) => {
  if (!Array.isArray(fields)) {
    return {
      values: {},
      variables: {}
    };
  }

  // Storage for next values
  const nextValues = {};

  // Helper data
  const fieldsMap = calculateFieldsMap(fields);
  const deepOrderedFieldsIds = calculateDeepOrderedFieldsIds(fields);

  const previousFieldsById = objectBy(previousFields, '_id');

  // Step 1 : Calculates calculationVariables states before calculating visibility (value change could affect them)
  for (const field of fields) {
    const _id = field._id;
    const previousField = previousFieldsById[_id];

    const initialValueChanged = Boolean(previousField && !fastDeepEqual(previousField.value ?? null, field.value ?? null));

    const nextValue = initialValueChanged
      ? // Picks current value with fallbacks in order: newest change > field config pre-filed value (ignores previous value)
        valuesChanges[_id] ?? parsePreFilledValue(field.value, urlParams) ?? null
      : // Picks current value with fallbacks in order: newest change > previous value > field config pre-filed value
        valuesChanges[_id] ?? values[_id]?.value ?? parsePreFilledValue(field.value, urlParams) ?? null;

    nextValues[_id] = {
      // These values are always copied from field config
      type: field.type,
      position: field.position,
      hidden: field.hidden,
      section: field.section,

      // Fallback values (true is letter set for two appropriate fields)
      isLast: false,
      isFirst: false,

      // On first pass it is assumed that field is visible, then previous value is taken
      visible: values[_id]?.visible ?? true,

      // Picks current value with fallbacks in order: newest change > previous value > field config pre-filed value
      value: _cloneDeep(nextValue)
    };
  }

  // Step 2 : Calculates calculationVariables states before calculating visibility (value change could affect them)
  const variables = getVariables(calculationVariables, urlParams, fields, nextValues);

  // Step 3 : Calculates visibility state based on  field type, logic, calculations and preview settings
  for (const fieldId in nextValues) {
    const field = fieldsMap.get(fieldId);

    if (field && nextValues[fieldId]) {
      // If logic is disabled fields are always visible, pageBreaks are always visible too
      if (field.type === 'pageBreak' || !previewLogic) {
        nextValues[fieldId].visible = true;
      }
      // For other fields visibility depends on other fields values and on variables states
      else {
        nextValues[fieldId].visible = checkFieldLogic(field, nextValues, fields, variables);
      }
    }
  }

  // Step 4 : Looks for first and last visible fields
  for (let i = 0; i < deepOrderedFieldsIds.length - 1; i++) {
    const _id = deepOrderedFieldsIds[i];
    const fieldState = nextValues[_id];

    if (fieldState && fieldState.visible && !fieldState.hidden) {
      fieldState.isFirst = true;
      break;
    }
  }
  for (let i = deepOrderedFieldsIds.length - 1; i >= 0; i--) {
    const _id = deepOrderedFieldsIds[i];
    const fieldState = nextValues[_id];

    if (fieldState && fieldState.visible && !fieldState.hidden) {
      fieldState.isLast = true;
      break;
    }
  }

  // Step 5 (TEMPORARY SOLUTION) :
  // Calculates variables values once again because field visibility change may change
  // which variables are used for calculations. One additional pass may not be enough
  // since updated variables can change what fields are visible again (there may even be
  // cyclic dependencies) but this is how live form's engine does.
  const nextVariables = getVariables(calculationVariables, urlParams, fields, nextValues);

  return {
    values: nextValues,
    variables: nextVariables
  };
};

/* -------------------------------------------------------------------------- */
/*                                    HOOK                                    */
/* -------------------------------------------------------------------------- */

/*
  Hook for handling values and variables management within FormEngine inside builder context
*/
const useFormEngineValuesAndVariablesProps = ({ form, previewLogic = false }) => {
  /* ---------------------------------- STATE --------------------------------- */

  // Form props that affect values and variables
  const fields = form?.fields;
  const calculationVariables = form?.calculationVariables;
  const urlParams = form?.urlParams;

  // Storage for form values and variables states
  const [values, setValues] = useState({});
  const [variables, setVariables] = useState({});

  // Ref is used so it won't trigger effect
  const previousFields = usePrevious(fields);
  const previousFieldsRef = useRef(null);
  previousFieldsRef.current = previousFields;

  /* ------------------------- AUTOMATIC STATE UPDATES ------------------------ */

  // When builder changes some form configuration that could affect values
  // or variables will be calculated again
  useEffect(() => {
    setValues((values) => {
      const { values: nextValues, variables: nextVariables } = calculateNextValuesAndVariables({
        values,
        fields,
        previousFields: previousFieldsRef.current ?? fields,
        calculationVariables,
        urlParams,
        previewLogic
      });

      setVariables(nextVariables);

      const previousFields = previousFieldsRef.current;
      if (fields !== previousFields) {
      }

      return nextValues;
    });
  }, [previewLogic, calculationVariables, fields, urlParams]);

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  // For handling value changes made inside FormEngine
  const handleValuesChange = useCallback(
    (valuesChanges) => {
      if (window.QS?.verbose) {
        console.log(`%cFORM VALUES CHANGE:`, 'color: #777', valuesChanges);
      }

      setValues((values) => {
        const { values: nextValues, variables: nextVariables } = calculateNextValuesAndVariables({
          values,
          valuesChanges,
          fields,
          previousFields: previousFieldsRef.current ?? fields,
          calculationVariables,
          urlParams,
          previewLogic
        });

        setVariables(nextVariables);

        return nextValues;
      });
    },
    [previewLogic, fields, urlParams, calculationVariables]
  );

  /* ---------------------- PROPS ACCEPTED BY FORM ENGINE --------------------- */

  return { values, variables, onChange: handleValuesChange };
};

export default useFormEngineValuesAndVariablesProps;
