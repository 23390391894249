import formatDistance from 'date-fns/formatDistance';

// Takes a date object or a timestamp string/int and returns time distance
// as string with fallback to 'unknown time ago' in production build.
// (this function never throws exceptions in production builds)
const safeFormatDistance = (from, to) => {
  try {
    from = new Date(from);
    to = typeof to === 'undefined' ? new Date() : new Date(to);

    return formatDistance(from, to, { addSuffix: true });
  } catch (e) {
    console.error(e);

    return 'unknown time ago';
  }
};

export default safeFormatDistance;
