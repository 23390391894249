import styles from './TitlePreview.module.css';

import React from 'react';

import EmptyPreview from './EmptyPreview';

const TitlePreview = ({ theme, value = null, placeholder }) => {
  if (!value) {
    return <EmptyPreview placeholder={placeholder} />;
  } else {
    const label = value?.label;
    const description = value?.description;

    return (
      <div className={[styles.titlePreview, styles[`theme-${theme}`]].join(' ')}>
        {label && <span className={styles.label}>{label}</span>}
        {description && <span className={styles.description}>{description}</span>}
      </div>
    );
  }
};

export default TitlePreview;
