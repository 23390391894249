import styles from './LocalLink.module.css';

import React, { useCallback, useContext } from 'react';
import { Button } from 'ui-components';

import { MainContext } from 'contexts/main.js';

export const LinkButton = ({ className, ...rest }) => {
  return (
    <Button
      className={[className, styles.localLink].join(' ')}
      icon="open-external"
      theme="black-underline"
      iconPlacement="right"
      {...rest}
    />
  );
};

// Button that can open Profile modal with certain initial page
export const ProfileLink = ({ page = 'profile', ...rest }) => {
  // Profile modal opener
  const { setShowProfile, setProfilePage } = useContext(MainContext);

  const handleOpenPaymentsAccounts = useCallback(() => {
    setShowProfile(true);
    setProfilePage(page);
  }, [page, setShowProfile, setProfilePage]);

  return <LinkButton {...rest} onClick={handleOpenPaymentsAccounts} />;
};

// Button that opens payment accounts settings
export const PaymentAccountsLink = (props) => (
  <ProfileLink page="apps" {...props}>
    Payment Accounts
  </ProfileLink>
);
export const LogsLink = (props) => (
  <ProfileLink page="logs" {...props}>
    Logs
  </ProfileLink>
);
export const AccountsDomainLink = (props) => (
  <ProfileLink page="domains" {...props}>
    Account Domains
  </ProfileLink>
);
