import { QueryClient } from 'react-query';

/*
  Helper class for handling optimistic updates with react-query client.
  Running queries that match key will be canceled so that they
  don't overwrite modified data.
*/
export class QueryCacheModifier {
  #state = null;

  constructor({ queryClient, invalidateRestored = true }) {
    if (!(queryClient instanceof QueryClient)) {
      throw new Error('DEBUG: Query client was not passed as parameter.');
    }

    this.#state = {
      queryClient,
      invalidateRestored,
      history: [],
      usedQueryKeys: []
    };
  }

  // Clears usage history
  clear() {
    this.#state.history.length = 0;
    this.#state.usedQueryKeys.length = 0;
  }

  // Overwrite query data but save previous data and register used key
  async setQueryDataAsync(queryKey, nextData) {
    const { queryClient, history, usedQueryKeys } = this.#state;

    const currentQueryData = queryClient.getQueryData(queryKey);
    history.push({ queryKey, data: currentQueryData });
    usedQueryKeys.push(queryKey);

    await queryClient.cancelQueries(queryKey, { exact: true });

    return queryClient.setQueryData(queryKey, nextData);
  }

  // Invalidate all queries of used keys
  async invalidateAllModifiedAsync() {
    const { queryClient, usedQueryKeys } = this.#state;

    for (const queryKey of usedQueryKeys) {
      await queryClient.invalidateQueries(queryKey);
    }
  }

  async restoreLastModifiedAsync() {
    const { queryClient, history, invalidateRestored } = this.#state;

    if (!history.length) {
      return;
    }

    const { queryKey, data } = history.pop();

    await queryClient.cancelQueries(queryKey, { exact: true });
    queryClient.setQueryData(queryKey, data);

    if (invalidateRestored) {
      await queryClient.invalidateQueries(queryKey);
    }
  }

  async restoreAllModifiedAsync() {
    const { queryClient, history } = this.#state;

    for (const { queryKey, data } of history) {
      await queryClient.cancelQueries(queryKey, { exact: true });
      queryClient.setQueryData(queryKey, data);
    }

    await this.invalidateAllModifiedAsync();

    history.length = 0;
  }
}
