import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import * as Sentry from '@sentry/browser';

import './index.scss';
import 'react-circular-progressbar/dist/styles.css';
import App from './components/App2/App.js';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: 'https://9066de05eb724c4c9912e2ce6bd9234e@sentry.io/1492827' });
}

// Used to show some additional things in development
if(process.env.NODE_ENV === "development"){
  document.body.className += ` development-mode`;
}

window.QS = {
  verbose: window.localStorage.getItem('debugLogs'),
  devPages: window.localStorage.getItem('devPages')
};

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

window['__react-beautiful-dnd-disable-dev-warnings'] = true;
