import parseNumber from './parseNumber';

const isValidDate = (d) => {
  d = new Date(d);
  return d instanceof Date && !isNaN(d);
};

// Pausing Conditions error format: { submissionsLimit, beforeDate, afterDate }
const validateFormPausingConditions = (pausingConditions) => {
  const errors = {};

  if (pausingConditions && pausingConditions.enabled) {
    const { submissionsLimit = null, beforeDate = null, afterDate = null } = pausingConditions;

    if (submissionsLimit !== null && !parseNumber(submissionsLimit, 'positive-int')) {
      errors.submissionsLimit = 'Enter a positive integer';
    }
    if (beforeDate !== null && !isValidDate(beforeDate)) {
      errors.beforeDate = 'Enter a valid date';
    }
    if (afterDate !== null && !isValidDate(afterDate)) {
      errors.afterDate = 'Enter a valid date';
    }
    if (beforeDate && afterDate && !errors.afterDate && !errors.beforeDate && beforeDate >= afterDate) {
      errors.afterDate = 'Close date should be later than open date';
    }
  }

  return Object.keys(errors).length ? errors : false;
};

export default validateFormPausingConditions;
