import React, { useContext, useMemo } from 'react';

import { isSetEqual } from 'helpers';

// Used to manage dynamic table content without re-rendering an entire table
const TableContentContext = React.createContext(null);

/* -------------------------------------------------------------------------- */
/*                              CONTEXT PROVIDER                              */
/* -------------------------------------------------------------------------- */

const EMPTY_SET = new Set();

export const TableContentContextManager = React.memo(
  ({
    selectedRowsIds = EMPTY_SET,
    allRowsIds = EMPTY_SET,
    // Will receive set of selected rows
    onSelectedRowsChange = null,

    children
  }) => {
    /* ------------------------------ ROW SELECTION ----------------------------- */

    /*
        Potential row selection alternative solution for future could use properties:

        selectedRowsIds = []/'all',
        excludedRowsIds = [],
        totalRowsIds = number,
         + internal state: selectAllCheckbox = true/false/'intermediate',
         this with some tweaking should be enough to simulate knowledge of all ids
    */
    const rowSelectionContext = useMemo(() => {
      if (process.env.NODE_ENV === 'development') {
        if (!(selectedRowsIds instanceof Set) || !(allRowsIds instanceof Set)) {
          throw new Error('DEBUG: pass sets for performance');
        }
      }

      const areAllRowsAreSelected = allRowsIds.size > 0 && isSetEqual(selectedRowsIds, allRowsIds);

      return {
        areAllRowsAreSelected,

        isRowSelected: (rowId) => {
          if (areAllRowsAreSelected) {
            return true;
          } else {
            return selectedRowsIds.has(rowId);
          }
        },

        toggleRowSelected: (rowId) => {
          const nextSet = new Set(selectedRowsIds);

          // Remove from selected
          if (selectedRowsIds.has(rowId)) {
            nextSet.delete(rowId);
            onSelectedRowsChange(nextSet);
          }
          // Add to selected
          else {
            nextSet.add(rowId);
            onSelectedRowsChange(nextSet);
          }
        },

        toggleAllRowsSelected: () => {
          // Deselect all
          if (areAllRowsAreSelected) {
            onSelectedRowsChange(new Set());
          }
          // Select all
          else {
            onSelectedRowsChange(allRowsIds);
          }
        }
      };
    }, [selectedRowsIds, allRowsIds, onSelectedRowsChange]);

    /* ------------------------------ CONTEXT VALUE ----------------------------- */

    const context = useMemo(() => {
      return { ...rowSelectionContext };
    }, [rowSelectionContext]);

    return <TableContentContext.Provider value={context}>{children}</TableContentContext.Provider>;
  }
);

/* -------------------------------------------------------------------------- */
/*                                ACCESSOR HOOK                               */
/* -------------------------------------------------------------------------- */

export const useTableContentContext = () => useContext(TableContentContext);
