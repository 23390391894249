import styles from './Button.module.css';

import React from 'react';
import { Link } from 'react-router-dom';

export const Button = React.forwardRef(({ children, onClick, disabled, theme, margin, fullWidth, icon, width, className, size, type, link, href, target, rel }, ref) => {
// export const Button = ({ children, onClick, disabled, theme, margin, fullWidth, icon, width, className, size, type, link, href, ref }) => {
  size = size ? size : 'default'; // small | default | medium | large

  let classList = [styles.button, styles[theme], className, styles[size]];

  if (fullWidth) {
    classList.push(styles.fullWidth);
  }

  if (!children) {
    classList.push(styles.onlyIcon);
  }

  if (disabled) {
    classList.push(styles.disabled);
  }

  return (
    <>
      {!link && !href && <button className={classList.join(' ')}
        disabled={disabled}
        style={{ margin, width }}
        type={type || 'button'}
        ref={ref}
        onClick={onClick}>
        {icon}<span>{children}</span>
      </button>}

      {link && <Link to={link} className={classList.join(' ')}
        disabled={disabled}
        style={{ margin, width }}
        ref={ref}
        onClick={onClick}>{icon}<span>{children}</span></Link>}

      {href && <a href={href}
        className={classList.join(' ')}
        target={target}
        rel={rel}
        disabled={disabled}
        style={{ margin, width }}
        ref={ref}
        onClick={onClick}>{icon}<span>{children}</span></a>}
    </>
  );
});
