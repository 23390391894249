export default (data, property) => {
  const obj = {};

  for (const i in data) {
    const key = data[i][property];
    obj[key] = data[i];
  }

  return obj;
};
