import styles from './GridLayout.module.css';

import React, { useEffect, useMemo, useRef } from 'react';
import _throttle from 'lodash/throttle';

import { Checkbox } from '../Checkbox/Checkbox';

import { useTableCellContext } from './TableCellContext';

// Components used to display react-table with useGridLayout plugin

/* -------------------------------------------------------------------------- */
/*                              HELPER COMPONENTS                             */
/* -------------------------------------------------------------------------- */

// Component for showing placeholders during rendering
const Placeholder = React.memo(() => <div className={styles.cellPlaceholder} style={{ width: `${Math.random() * 50 + 30}%` }} />);

const RegularCell = React.forwardRef(({ children, ...rest }, ref) => {
  const includePlaceholder = rest.role === 'cell';
  return (
    <div className={styles.cell} {...rest} ref={ref}>
      {includePlaceholder ? <Placeholder /> : null}
      {children}
    </div>
  );
});

const ContextAwareCell = ({ style: injectedStyle, columnId, columnIndex, ...rest }) => {
  const { registerColumnWidth, getColumnLeftOffset } = useTableCellContext();

  // Node reference of additional offset provider cells
  const offsetProviderCellRef = useRef(null);
  const setOffsetProviderCellRef = rest.role === 'offset-provider' ? offsetProviderCellRef : null;

  // react-table-sticky doesn't support sticky columns with no predefined with
  // this is a workaround for getting true sticky offsets.
  useEffect(() => {
    if (!offsetProviderCellRef?.current) return;

    // Since every element in grid column has same width, only one row can be measured for performance.
    const offsetProviderCellNode = offsetProviderCellRef.current;

    const updateColumnsWidth = _throttle(() => {
      registerColumnWidth(columnId, offsetProviderCellNode.offsetWidth);
    }, 100);

    // Initial offset detection
    updateColumnsWidth();

    // Listening for resize events if ResizeObserver is available
    let observer = null;
    if (typeof ResizeObserver !== 'undefined') {
      observer = new ResizeObserver(updateColumnsWidth);
      observer.observe(offsetProviderCellNode);
    }
    // For older browsers, probably will be slower, but this should detect width changes too
    else if (typeof MutationObserver !== 'undefined') {
      observer = new MutationObserver(updateColumnsWidth);
      observer.observe(offsetProviderCellNode, { attributes: true, childList: true, subtree: true });
    }
    return () => {
      if (observer) observer.disconnect();
    };
  }, [columnId, offsetProviderCellRef.current, registerColumnWidth]);

  const style = useMemo(() => {
    return { ...injectedStyle, left: getColumnLeftOffset(columnId), zIndex: undefined };
  }, [injectedStyle, getColumnLeftOffset, columnId]);

  return <RegularCell style={style} ref={setOffsetProviderCellRef} {...rest} />;
};

/* -------------------------------------------------------------------------- */
/*                                 COMPONENTS                                 */
/* -------------------------------------------------------------------------- */

export const Container = ({ className, loading, ...rest }) => {
  return <div className={[className, styles.table, styles.sticky, loading ? styles.loading : ''].join(' ')} {...rest} />;
};

export const Body = ({ ...rest }) => {
  return <div className={styles.body} {...rest} />;
};

export const Header = ({ sticky, ...rest }) => {
  return <div className={[styles.header, sticky ? styles.sticky : ''].join(' ')} {...rest} />;
};
export const Footer = ({ sticky, ...rest }) => {
  return <div className={[styles.footer, sticky ? styles.sticky : ''].join(' ')} {...rest} />;
};

export const Row = ({ ...rest }) => {
  return <div className={styles.row} {...rest} />;
};

export const Cell = ({ columnIndex, columnId, ...rest }) => {
  // For cases that need some additional logic
  if (rest['data-sticky-td']) {
    return <ContextAwareCell columnIndex={columnIndex} columnId={columnId} {...rest} />;
  }
  // Basic cases
  else {
    return <RegularCell {...rest} />;
  }
};

/* -------------------------------------------------------------------------- */
/*                        ADDITIONAL CONTENT COMPONENTS                       */
/* -------------------------------------------------------------------------- */

const RowSelector = ({ checked, onClick, label, ...rest }) => {
  return (
    <div className={[styles.rowSelector, checked ? styles.checked : ''].join(' ')}>
      <span className={styles.rowSelectorLabel}>{label || '\u00A0'}</span>
      <Checkbox className={styles.rowSelectorCheckbox} theme="black" value={checked} onClick={onClick} {...rest} />
    </div>
  );
};

export const IndexCell = ({ selected, onToggleSelected, label, content, hoverContent }) => {
  return (
    <div className={styles.indexCell}>
      <RowSelector checked={selected} onClick={onToggleSelected} label={label} />
      {(content || hoverContent) && (
        <div className={styles.indexCellContent}>
          {content && <div className={styles.indexCellMainContent}>{content}</div>}
          {hoverContent && <div className={styles.indexCellHoverContent}>{hoverContent}</div>}
        </div>
      )}
    </div>
  );
};
