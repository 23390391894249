import React, { useEffect } from 'react';

const useStopScrollPropagationOnElement = (element)=>{
    useEffect(()=>{
      if(element){
        const handleScroll = (e)=>{
          e.stopPropagation();
          e.stopImmediatePropagation();
        }
  
        element.addEventListener('scroll', handleScroll);
        element.addEventListener('wheel', handleScroll);
        return ()=>{
          element.removeEventListener('scroll', handleScroll);
          element.removeEventListener('wheel', handleScroll);
        }
      }
    }, [element])
  }

  export default useStopScrollPropagationOnElement;