import ObjectID from 'bson-objectid';

// Returns object with default properties required by every kind of field
const generateRequiredFieldData = () => {
  return {
    _id: ObjectID().toHexString(),
    type: 'shortText',
    label: 'Field',
    value: null,
    section: 'root',
    position: 0,
    conditionsType: 'all',
    conditionsState: 'show',
    childrens: [],
    conditions: [],
    calculationConditions: [],
    selectionLimitsMin: 0,
    selectionLimitsMax: null,
    calculations: [],
    options: []
  };
};

export default generateRequiredFieldData;
