import styles from './EmailAddressChangePanel.module.css';

import React, { useState, useContext, useCallback, useEffect } from 'react';
import validator from 'validator';

import { OtpInput, Panel, Button, Input, FormRow, usePopups, Modal } from 'ui-components';

import { asyncSocketAckEmit } from 'helpers';

import config from 'config.js';
import { MainContext } from 'contexts';

const INITIAL_BACKEND_ERRORS = {};
const INITIAL_VALUES = { otpCode: '', password: '', newEmail: '' };

const EmailAddressChangePanel = ({ show, onClose }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const popups = usePopups();

  const { user } = useContext(MainContext);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState(INITIAL_VALUES);
  const [backendErrors, setBackendErrors] = useState(INITIAL_BACKEND_ERRORS);
  const [errorsAreVisible, setErrorsAreVisible] = useState(false);

  // Clear state
  useEffect(() => {
    if (!show) {
      setLoading(false);
      setValues(INITIAL_VALUES);
      setBackendErrors(INITIAL_BACKEND_ERRORS);
      setErrorsAreVisible(false);
      setSuccess(false);
    }
  }, [show]);

  /* --------------------------------- HELPERS -------------------------------- */

  const updateValue = useCallback((key, nextValue) => {
    setValues((values) => ({ ...values, [key]: nextValue }));
    setBackendErrors((errors) => ({ ...errors, [key]: false }));
  }, []);

  const computeLocalErrors = () => {
    const localErrors = {};

    // otpCode
    if (user.otp.enabled && values.otpCode.length !== 6) {
      localErrors.otpCode = 'This field is required';
    }
    // password
    if (!values.password.length) {
      localErrors.password = 'This field is required';
    }
    // email
    if (!values.newEmail.length) {
      localErrors.newEmail = 'This field is required';
    } else if (!validator.isEmail(values.newEmail)) {
      localErrors.newEmail = 'Email is invalid';
    }

    return localErrors;
  };

  /* --------------------------------- ERRORS --------------------------------- */

  const localErrors = computeLocalErrors();

  const errors = {
    otpCode: backendErrors.otpCode || localErrors.otpCode,
    password: backendErrors.password || localErrors.password,
    newEmail: backendErrors.newEmail || localErrors.newEmail
  };

  const thereAreSomeErrors = Object.values(errors).filter(Boolean).length > 0;

  /* --------------------------------- REDUCER -------------------------------- */

  const sendUpdateRequest = async () => {
    setErrorsAreVisible(true);

    if (loading || thereAreSomeErrors) {
      return;
    }

    setLoading(true);

    const { success, message, data } = await asyncSocketAckEmit(
      'updateUserEmail',
      { otpCode: values.otpCode, password: values.password, newEmail: values.newEmail },
      { showToasts: false }
    );

    setLoading(false);

    if (success) {
      setSuccess(true);
    } else {
      if (message) {
        popups.showErrorSnackbar(message);
      }

      setBackendErrors(data?.errors || {});
    }
  };

  /* ----------------------- EVENT HANDLERS AND HELPERS ----------------------- */

  const handleClickOutside = () => {
    !loading && onClose();
  };

  const getInputProps = (key) => {
    return {
      disabled: loading,
      value: values[key],
      error: Boolean(errorsAreVisible && errors[key]),
      onChange: (value) => updateValue(key, value),
      onKeyPress: (e) => {
        if (e.key === 'Enter') sendUpdateRequest();
      }
    };
  };

  const getRowProps = (key) => {
    return {
      errorMessage: errorsAreVisible && errors[key]
    };
  };

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <>
      <Modal isOpen={success} onRequestClose={onClose}>
        <div className={styles.successModal}>
          <div className={styles.header}>We’ve sent you an email!</div>
          <div className={styles.text}>
            Head over to your inbox and open the email we’ve sent you. We just need to verify that <b>{values.newEmail || 'ww'}</b> email is
            yours.
          </div>
          <div className={styles.footer}>
            <Button theme="black" onClick={onClose}>
              Got it!
            </Button>
          </div>
        </div>
      </Modal>

      <Panel
        mainStyle={{ height: '90vh' }}
        coverStyle={{ width: '80vw', height: '90vh', maxWidth: '950px', minWidth: '850px', left: '-200px' }}
        title="Change email address"
        show={show}
        loading={loading}
        success={success}
        buttons={[
          <Button theme="white" onClick={onClose}>
            Cancel
          </Button>,
          <Button theme="black" disabled={loading || (errorsAreVisible && thereAreSomeErrors)} onClick={sendUpdateRequest}>
            Update email address
          </Button>
        ]}
        onOutsideClick={handleClickOutside}>
        <div className={config.fullStoryExcludeClass}>
          <FormRow label="New email address" {...getRowProps('newEmail')}>
            <Input {...getInputProps('newEmail')} />
          </FormRow>

          <FormRow label="Current password" {...getRowProps('password')}>
            <Input type="password" {...getInputProps('password')} />
          </FormRow>

          {user.otp.enabled && (
            <FormRow label="Two-factor authentication code" {...getRowProps('otpCode')}>
              <OtpInput {...getInputProps('otpCode')} />
            </FormRow>
          )}
        </div>
      </Panel>
    </>
  );
};

export default EmailAddressChangePanel;
