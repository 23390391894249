import styles from './EmptyHint.module.css';

import React from 'react';
import { Icon } from '../Icon/Icon.js';

const EmptyHint = React.forwardRef(({ style, className, children, icon, background = '#F6F5F4' }, ref) => {
  const classList = [styles.emptyHint, className];

  return (
    <div ref={ref} style={{ ...style, background }} className={classList.join(' ')}>
      {icon && <Icon id={icon} className={styles.icon} />}
      {children}
    </div>
  );
});

export default EmptyHint;
