import React from 'react';
import Color from 'color';
import { Link } from 'react-router-dom';

const getColor = (colors, id) => {
  if (!id) return 'transparent';

  const res = colors.find((color) => `$color:${color._id}` === id);

  return res ? res.value : 'transparent';
};

const ThemePreview = ({ id, href, type, height, colors, images, settings, onClick, className }) => {
  if (!colors) colors = [];
  if (!images) images = {};

  const getButtonBackground = (gradient, gradientDirection, background, secondBackground, darken) => {
    if (gradient) {
      if (gradientDirection === 'left-right') {
        return `linear-gradient(225deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      } else if (gradientDirection === 'right-left') {
        return `linear-gradient(25deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      } else if (gradientDirection === 'top-bottom') {
        return `linear-gradient(180deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      } else if (gradientDirection === 'bottom-top') {
        return `linear-gradient(0deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      }
    } else {
      return Color(background).darken(darken);
    }
  };

  const inputContainerStyleTypes = {
    style1: {
      borderBottom: `${settings.inputFieldsIdleBorderSize}px solid ${getColor(colors, settings.inputFieldsIdleBorderColor)}`,
    },
    style2: {
      border: 'none'
    },
    style3: {
      border: `${settings.inputFieldsIdleBorderSize}px solid ${getColor(colors, settings.inputFieldsIdleBorderColor)}`,
    },
  };

  const alignOptions = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  const preview = {
    display: 'flex',
    width: '100%',
    height: height,
    flexDirection: 'row',
    justifyContent: alignOptions[settings.displaySettingsFormDisplayAlignment],
    position: 'relative',
    boxSizing: 'border-box',
    overflow: 'hidden'
  };

  const backgroundBox = {
    backgroundColor: getColor(colors, settings.backgroundColor),
    width: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };

  const background = {
    width: '100%',
    height: '100%'
  };

  const form = {
    width: `70%`,
    height: settings.displaySettingsFormDisplayBoxMinimumHeight === 'window' ? '100%' : 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: alignOptions[settings.displaySettingsFormDisplayAlignment],
    justifyContent: 'flex-start',
    padding: `15px 10px`,
    borderRadius: `${settings.displaySettingsFormDisplayBoxRoundness || 0}${settings.displaySettingsFormDisplayBoxRoundnessType}`,
    background: settings.displaySettingsFormDisplayBox ? getColor(colors, settings.displaySettingsFormDisplayBoxBackground) : 'transparent',
    position: 'relative',
    boxSizing: 'border-box',
    boxShadow: settings.displaySettingsFormDisplayBox && settings.displaySettingsFormDisplayBoxShadow ? `${settings.displaySettingsFormDisplayBoxShadowOffsetY / 2}px ${settings.displaySettingsFormDisplayBoxShadowOffsetX / 2}px ${settings.displaySettingsFormDisplayBoxShadowBlur / 2}px ${settings.displaySettingsFormDisplayBoxShadowSpread / 2}px ${getColor(colors, settings.displaySettingsFormDisplayBoxShadowColor)}` : 'none',
  };

  const question = {
    textAlign: 'left',
    width: '100%',
    boxSizing: 'border-box',
    fontSize: 16,
    lineHeight: '142%',
    fontWeight: settings.generalFieldsSettingsTextIdleLabelWeight,
    color: getColor(colors, settings.generalFieldsSettingsTextIdleLabelColor),
    boxSizing: 'border-box',
    fontFamily: settings.typographyLabelFontFamily,
    margin: `0 0 5px 0`
  };

  const answer = {
    textAlign: 'left',
    width: '100%',
    boxSizing: 'border-box',
    margin: `5px 0 0 0`,
    fontFamily: settings.typographyContentFontFamily,
    lineHeight: '142%',
    color: getColor(colors, settings.inputFieldsIdleTextColor),
    fontWeight: settings.inputFieldsIdleTextWeight,
    padding: `0 ${settings.inputFieldsIdleHorizontalPadding / 2}px`,
    fontSize: 14,
    borderRadius: `${settings.inputFieldsIdleRoundness}px`,
    boxShadow: settings.inputFieldsIdleShadow ? `${settings.inputFieldsIdleShadowOffsetX / 2}px ${settings.inputFieldsIdleShadowOffsetY / 2}px ${settings.inputFieldsIdleShadowBlur / 2}px ${settings.inputFieldsHoverShadowSpread / 2}px ${getColor(colors, settings.inputFieldsIdleShadowColor)}` : 'none',
    ...inputContainerStyleTypes[settings.inputFieldsDropdownsType],
  };

  const button = {
    background: getButtonBackground(settings.submitIdleButtonGradient, settings.submitIdleButtonGradientDirection, getColor(colors, settings.submitIdleButtonBackground), getColor(colors, settings.submitIdleButtonSecondBackground), 0),
    minHeight: '30px',
    lineHeight: '30px',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'center',
    fontFamily: settings.typographyButtonsFontFamily,
    color: getColor(colors, settings.submitIdleButtonColor),
    borderRadius: `${settings.submitIdleButtonRoundness}${settings.submitIdleButtonRoundnessType}`,
    border: `${settings.submitIdleButtonBorderSize}px solid ${getColor(colors, settings.submitIdleButtonBorderColor)}`,
    boxShadow: settings.submitIdleButtonShadow ? `${settings.submitIdleButtonShadowOffsetX / 2}px ${settings.submitIdleButtonShadowOffsetY / 2}px ${settings.submitIdleButtonShadowBlur / 2}px ${getColor(colors, settings.submitIdleButtonShadowColor)}` : '',
    margin: `10px 0 0 0`
  };

  if (settings.backgroundImage && settings.backgroundUseImage) {
    background.backgroundImage = `url(${images.background.thumb})`;
    background.backgroundRepeat = 'no-repeat';
    background.backgroundSize = settings.backgroundImageLayout;
    background.backgroundPosition = settings.backgroundImagePosition;
    background.filter = `blur(${settings.backgroundImageBlur}px)`;
    background.opacity = settings.backgroundImageOpacity;
  }

  if (settings.backgroundImageBlur > 0) {
    background.transform = 'scale(1.05)';
  }

  const content = <>
    <div style={backgroundBox}><div style={background} /></div>

    <div style={form}>
      <div style={question}>Question</div>
      <div style={answer}>Answer</div>
      <div style={button}>Submit</div>
    </div>
  </>;

  return href ? <Link to={href} className={className} onClick={onClick} style={preview}>{content}</Link> : <div className={className} onClick={onClick} style={preview}>{content}</div>;
}

export default ThemePreview;
