import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import _throttle from 'lodash/throttle';

import { FloatingLogger } from 'ui-components';

import { ActivityContext, MainContext } from 'contexts';

import { parseActivityIntoTitle } from './parseActivity';

import { asyncSocketAckEmit, memoizeOneDeepAsync } from 'helpers';
import io from 'io.js';

/* -------------------------------------------------------------------------- */
/*                                   CONFIG                                   */
/* -------------------------------------------------------------------------- */

const ALLOWED_ACTIVITY_PROPERTIES = ['form', 'theme', 'workspace', 'field', 'submissionWorkspace'];

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */

const ActivityManager = React.memo(({ children }) => {
  const { isAuthenticated } = useContext(MainContext);

  const location = useLocation();
  const { pathname, search } = location;

  /* ---------------------- FETCHING AND SENDING ACTIVITY --------------------- */

  const [activity, setActivity] = useState([]);

  const fetchActivity = useCallback(async () => {
    const { success, data } = await asyncSocketAckEmit(
      'getActivity',
      {},
      {
        // Mute error toasts,
        // there are some issues in the backend that needs to be fixed
        // but it doesn't break user experience
        showToasts: false
      }
    );

    // TEMPORARY FIX FOR DUPLICATED ACTIVITY (find on this array will return newest activity)
    const dataSorted = (data || []).sort((d1, d2) => {
      return new Date(d2?.activity?.updatedAt ?? 0) - new Date(d1?.activity?.updatedAt ?? 0);
    });

    setActivity(success ? dataSorted : []);
  }, []);

  const updateActivity = useMemo(() => {
    const updateActivity = async (activity) => {
      if (window.QS?.verbose) {
        console.log(`%cREQUEST: updateActivity`, 'color: #4685F1', activity);
      }

      io.socket.emit('updateActivity', activity);
    };

    // Prevents updates that don't change anything and limits them to at most 2 per/sec
    const updateActivityMemoized = memoizeOneDeepAsync(updateActivity);
    const updateActivityThrottled = _throttle(updateActivityMemoized, 500);

    return updateActivityThrottled;
  }, []);

  /* ------------------------------ AUTO FETCHING ----------------------------- */

  useEffect(() => {
    if (isAuthenticated) {
      fetchActivity();

      io.socket.on('refreshActivity', fetchActivity);
      return () => {
        io.socket.off('refreshActivity', fetchActivity);
      };
    }
  }, [isAuthenticated, fetchActivity]);

  /* ----------------- STORAGE FOR VARIOUS ACTIVITY PROPERTIES ---------------- */

  // Storage for registering activity properties
  // that allow creating better status messages (field with field name for example)
  const [activityProperties, setActivityProperties] = useState({});

  const setActivityProperty = useCallback((key, value = null) => {
    if (process.env.NODE_ENV === 'development') {
      if (!ALLOWED_ACTIVITY_PROPERTIES.indexOf(key) === -1) {
        throw new Error(`DEBUG: tried to set unexpected activity property: '${key}'.`);
      }
    }

    setActivityProperties((properties) => {
      if ((properties[key] ?? null) === value) {
        return properties;
      } else {
        return { ...properties, [key]: value };
      }
    });
  }, []);

  /* ----------------------- ACTIVITY READY TO BE SHARED ---------------------- */

  const selfActivity = useMemo(() => {
    const selfActivity = {
      pathname,
      search
    };

    for (const key of ALLOWED_ACTIVITY_PROPERTIES) {
      selfActivity[key] = activityProperties[key] ?? null;
    }

    return selfActivity;
  }, [activityProperties, pathname, search]);

  /* -------------------- SHARING ACTIVITY WITH OTHER USERS ------------------- */

  useEffect(() => {
    if (isAuthenticated) {
      updateActivity(selfActivity);
    }
  }, [selfActivity, isAuthenticated, updateActivity]);

  /* --------------------------- UPDATING TAB TITLE --------------------------- */

  useEffect(() => {
    document.title = parseActivityIntoTitle(selfActivity);
  }, [selfActivity]);

  /* --------------------------------- CONTEXT -------------------------------- */

  const context = useMemo(() => {
    return {
      activity,
      setActivityProperty
    };
  }, [activity, setActivityProperty]);

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <>
      <ActivityContext.Provider value={context}>
        {false && <FloatingLogger activity={selfActivity} />}
        {children}
      </ActivityContext.Provider>
    </>
  );
});

export default ActivityManager;
