import { useMemo, useContext } from 'react';

import { IntegrationsContext } from 'contexts';

import CONFIG from './config/nolt';

const useNolt = () => {
  /* ---------------------------------- STATE --------------------------------- */

  const { getNoltAPI } = useContext(IntegrationsContext);

  /* ----------------------------------- API ---------------------------------- */

  return useMemo(() => {
    return {
      initializeFeedbackButton: async (selector) => {
        const noltAPI = await getNoltAPI();
        if (noltAPI) {
          noltAPI('init', { selector, url: CONFIG.boardUrl + CONFIG.pages.mostVoted });
        }
      },

      initializeRoadmapButton: async (selector) => {
        const noltAPI = await getNoltAPI();
        if (noltAPI) {
          noltAPI('init', { selector, url: CONFIG.boardUrl + CONFIG.pages.roadmap });
        }
      }
    };
  }, [getNoltAPI]);
};

export default useNolt;
