import { ioEmitValidatedRequest } from '../../helpers';

const previewAutoSubmissionDeletionRulesOnFormSubmissions = {
  keyFn: ({ formId = null, autoSubmissionDeletionRules = null }) => [
    'previewAutoSubmissionDeletionRulesOnFormSubmissions',
    { form: formId, autoSubmissionDeletionRules }
  ],
  fn: async ({ queryKey }) => {
    const [, params] = queryKey;
    return await ioEmitValidatedRequest('previewAutoSubmissionDeletionRulesOnFormSubmissions', params, { timeout: Infinity });
  }
};

export default {
  previewAutoSubmissionDeletionRulesOnFormSubmissions
};
