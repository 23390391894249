import styles from './PageTabs.module.css';

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Icon } from '../../Icon/Icon.js';
import Hint from '../../Hint/Hint';

const PageTabs = React.forwardRef(({ className, style, data, active, onChange }, ref) => {
  let classList = [styles.tabs, className];

  return (
    <div ref={ref} style={style} className={classList.join(' ')}>
      {data.map((item) => (
        <div
          key={item.id}
          className={[styles.tabsItem, active === item.id ? styles.active : '', onChange ? styles.withClick : ''].join(' ')}
          onClick={() => onChange && onChange(item.id)}>
          {item.icon ? <Icon id={item.icon} color="inherit" /> : <span style={{ width: '16px' }} />}
          {item.error ? <Hint icon="warning" children={item.error} /> : null}
        </div>
      ))}
    </div>
  );
});

export default PageTabs;
