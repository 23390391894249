import styles from './Panel.module.css';

import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { Spinner } from 'ui-components';

import { ReactComponent as CheckmarkCircle } from 'assets/images/checkmark-circle.svg';

const Panel = ({
  title,
  description,
  children,
  className,
  buttons,
  show,
  loading,
  success,
  onOutsideClick,
  mainStyle,
  coverStyle,
  mode = 'modal'
}) => {
  const [visible, setVisible] = useState(false);
  const [showCover, setShowCover] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShowCover(true);
        setVisible(true);
      });
    } else {
      setTimeout(() => setVisible(false));
      setTimeout(() => setShowCover(false), 300);
    }
  }, [show]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => setShowSpinner(true));
    } else {
      setTimeout(() => setShowSpinner(false));
    }
  }, [loading]);

  useEffect(() => {
    if (success) {
      setTimeout(() => setShowCheckmark(true));
    } else {
      setTimeout(() => setShowCheckmark(false), 300);
    }
  }, [success]);

  const contentHeight = useMemo(() => {
    let fixer = 0;

    if (!!buttons) fixer += 69;
    if (!!title) fixer += 60;

    return `calc(${mode === 'fullscreen' ? '100vh' : '90vh'} - ${fixer}px)`;
  }, [buttons, title, mode]);

  return (
    <>
      {showCover && (
        <div
          className={[styles.cover, visible ? styles.visible : ''].join(' ')}
          style={coverStyle}
          onClick={() => onOutsideClick && onOutsideClick()}
        />
      )}

      {show && (
        <>
          <div className={[styles.main, className, visible ? styles.visible : ''].join(' ')} style={mainStyle}>
            <div className={styles.box}>
              {title && <div className={styles.header}>{title}</div>}
              <div
                className={styles.content}
                style={{
                  minHeight: contentHeight,
                  maxHeight: contentHeight
                }}>
                {children}
              </div>
            </div>

            {buttons && (
              <div className={styles.buttons}>
                {buttons.map((button, index) => (
                  <Fragment key={index}>{button}</Fragment>
                ))}
              </div>
            )}

            {(loading || success) && (
              <div className={styles.loader}>
                <Spinner size={30} borderSize={2} speed="0.8s" className={[styles.spinner, showSpinner ? styles.visible : ''].join(' ')} />
                <CheckmarkCircle className={[styles.checkmark, showCheckmark ? styles.visible : ''].join(' ')} />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Panel;
