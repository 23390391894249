import React from 'react';

import { Spinner, InlineBadge } from 'ui-components';

import { withQueryBoundary } from 'api';

import useAvailableTutorials from '../hooks/useAvailableTutorials';

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */

const PendingTutorialsBadge = React.memo(() => {
  /* ---------------------------------- STATE --------------------------------- */

  const availableTutorials = useAvailableTutorials();

  const tutorialsCount = availableTutorials.length;
  const completedTutorialsCount = availableTutorials.filter((t) => t.completionRatio === 1).length;

  /* ----------------------------------- JSX ---------------------------------- */

  if (completedTutorialsCount === tutorialsCount) {
    return null;
  } else {
    return (
      <InlineBadge>
        {completedTutorialsCount}/{tutorialsCount}
      </InlineBadge>
    );
  }
});

/* -------------------------------------------------------------------------- */
/*                                  FALLBACKS                                 */
/* -------------------------------------------------------------------------- */

export default withQueryBoundary(PendingTutorialsBadge, {
  SuspenseFallbackComponent: () => <Spinner size={10} />,
  ErrorFallbackComponent: () => null
});
