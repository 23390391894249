import styles from './Tooltip.module.css';
import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';

import { sticky as stickyPlugin } from 'tippy.js';
import Tippy, { useSingleton } from '@tippyjs/react/headless';

import { useTimeout } from 'helpers';

import { Button } from '../Button/Button';
import SuspenseFallback from '../SuspenseFallback/SuspenseFallback';

// Context used to specify tooltips container
const TooltipContext = React.createContext({});

// Handles hiding tooltips on scroll
const hideOnScrollPlugin = {
  name: 'onScroll',

  fn(tippyInstance) {
    const handleScroll = (e) => {
      if (!tippyInstance.state.isDestroyed && tippyInstance.state.isVisible && tippyInstance.props.onScroll) {
        tippyInstance.props.onScroll(tippyInstance);
      }
    };
    let hasListener = false;

    return {
      onShow() {
        if (!hasListener) {
          window.addEventListener('wheel', handleScroll);
          hasListener = true;
        }
      },
      onHidden() {
        if (hasListener) {
          window.removeEventListener('wheel', handleScroll);
          hasListener = false;
        }
      },
      onDestroy() {
        if (hasListener) {
          window.removeEventListener('wheel', handleScroll);
          hasListener = false;
        }
      }
    };
  }
};

/* -------------------------------------------------------------------------- */
/*                         TOOLTIP CONTENT COMPONENTS                         */
/* -------------------------------------------------------------------------- */

// Detects outside clicks and scrolls. Works with react portals.
const useOutsideEvents = ({ disabled = false, onClickOutside, onScrollOutside }) => {
  const nodeRef = useRef(null);

  const lastInsideClickTargetRef = useRef(null);
  const lastInsideWheelTargetRef = useRef(null);

  const handleClick = useCallback((e) => {
    lastInsideClickTargetRef.current = e.target;
  }, []);

  const handleWheel = useCallback((e) => {
    lastInsideWheelTargetRef.current = e.target;
  }, []);

  useEffect(() => {
    if (!disabled) {
      const handleClickOutside = onClickOutside
        ? (e) => {
            // React tree inside tree
            if (e.target === lastInsideClickTargetRef.current) return;
            // DOM tree inside click
            if (nodeRef.current.contains(e.target)) return;

            onClickOutside(e);
          }
        : null;

      const handleScrollOutside = onScrollOutside
        ? (e) => {
            // React tree inside tree
            if (e.target === lastInsideWheelTargetRef.current) return;
            // DOM tree inside click
            if (nodeRef.current.contains(e.target)) return;

            onScrollOutside(e);
          }
        : null;

      if (handleClickOutside) document.addEventListener('click', handleClickOutside);
      if (handleScrollOutside) document.addEventListener('wheel', handleScrollOutside);
      return () => {
        if (handleClickOutside) document.removeEventListener('click', handleClickOutside);
        if (handleScrollOutside) document.removeEventListener('wheel', handleScrollOutside);
      };
    }
  }, [disabled, onClickOutside, onScrollOutside]);

  const props = { ref: nodeRef };
  if (onClickOutside) props.onClick = handleClick;
  if (onScrollOutside) props.onWheel = handleWheel;

  return props;
};

// Tooltip component used for text content or basic component
const TooltipContentBasic = ({ theme, arrow, content, tippyAttrs }) => {
  const isTextTooltip = typeof content === 'string';

  return (
    <div className={styles.box} tabIndex="-1" {...tippyAttrs}>
      <div className={[styles.innerBox, styles[`theme-${theme}`], isTextTooltip ? styles.textTooltip : ''].join(' ')}>
        <div className={styles.content}>{content}</div>
        {arrow && <div data-popper-arrow="" className={styles.arrow}></div>}
      </div>
    </div>
  );
};

// Tooltip component for content that is used as a interactive dropdown
// Creates context that helps positioning nested tooltips and that provides some api
const TooltipContentInteractive = ({
  theme,
  arrow,
  content,
  tippyAttrs,
  tippyInstance,
  isShown,
  hideOnClickInside,
  unmountContentOnHide,
  onRequestClose
}) => {
  const contextValue = useMemo(() => {
    return {
      tippyInstance: tippyInstance,
      isShown,
      hide: () => {
        if (onRequestClose) {
          onRequestClose();
        } else {
          tippyInstance.hide();
        }
      }
    };
  }, [tippyInstance, isShown, onRequestClose]);

  const { ref: contentRef, ...contentProps } = useOutsideEvents({
    disabled: !isShown,
    onClickOutside: onRequestClose,
    onScrollOutside: onRequestClose
  });

  return (
    <TooltipContext.Provider value={contextValue}>
      <>
        <div className={styles.box} tabIndex="-1" {...tippyAttrs}>
          <div
            className={[styles.innerBox, styles[`theme-${theme}`]].join(' ')}
            onClick={() => {
              if (hideOnClickInside && contextValue.hide) {
                contextValue.hide();
              }
            }}>
            <div className={styles.content} {...contentProps} ref={contentRef}>
              {!isShown && unmountContentOnHide ? <SuspenseFallback inline small /> : content}
            </div>
            {arrow && <div data-popper-arrow="" className={styles.arrow}></div>}
          </div>
        </div>
      </>
    </TooltipContext.Provider>
  );
};

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

// Important about usage:
// Component passed as a child needs to pass a ref to a dom node with forwardRef.
//
// Important props:
// - visible : Controlled mode: (true/false or undefined)
// - trigger : Uncontrolled mode: 'click'/'mouseenter'...
// - disabled
// - appendTo
// - delay
// - placement
// - reference
// - triggerTarget
// https://atomiks.github.io/tippyjs/v6/all-props
const Tooltip = React.forwardRef(
  (
    {
      visible,

      placement = 'top',
      theme = 'black',
      arrow = true,
      backdrop = false,

      onCreate,
      onDestroy,
      onHide,
      onShow,
      onClickOutside,
      onRequestClose,
      onRequestOpen,

      interactive,
      hideOnScroll = true,
      hideOnClick = true,
      hideOnClickInside = false,
      sticky = undefined,

      unmountContentOnHide = true, // for dropdowns

      offset,
      skidding,
      distance,
      ...rest
    },
    ref
  ) => {
    // Temporary
    if (onClickOutside) throw new Error('DEBUG: replace onRequestClose with onClickOutside');

    const isMountedRef = useRef(false);
    const wasMountedRef = useRef(false);
    useEffect(() => {
      isMountedRef.current = true;
      wasMountedRef.current = true;
      return () => {
        isMountedRef.current = false;
      };
    }, []);

    const tippyInstanceRef = useRef(null);

    const [isShown, setIsShown] = useState(false);

    const isControlled = typeof visible !== 'undefined';

    if (process.env.NODE_ENV === 'development') {
      // To remember in the future
      const children = rest.children;
      const reference = rest.reference;
      const singleton = rest.singleton;
      if (!singleton && !reference && (!children || typeof children === 'string' || children instanceof String)) {
        throw Error('Content inside tooltip has to be a React component (no strings). If you cant pass it use reference prop.');
      }
    }

    /* -------------------- GETTING ACCESS TO TIPPY INSTANCE -------------------- */

    const handleCreate = useCallback(
      (instance) => {
        tippyInstanceRef.current = instance;
        if (onCreate) onCreate(instance);
      },
      [onCreate]
    );

    const handleDestroy = useCallback(
      (instance) => {
        tippyInstanceRef.current = null;
        if (onDestroy) onDestroy(instance);
      },
      [onDestroy]
    );

    /* ----------- ADDS SUPPORT FOR SHOW/HIDE ANIMATIONS AND BACKDROP ----------- */

    const backdropNode = useRef(null);
    const allowedToHide = useRef(false);
    const setHideTimeout = useTimeout();
    const delayShowTimeout = useTimeout();

    useEffect(() => {
      if (backdrop) {
        const node = document.createElement('div');
        node.classList.add(styles.backdrop);
        document.body.appendChild(node);
        backdropNode.current = node;
        return () => {
          node.remove();
          backdropNode.current = null;
        };
      }
    }, [backdrop]);

    const handleShow = useCallback(
      (tippy) => {
        delayShowTimeout(null);
        if (!wasMountedRef.current) {
          delayShowTimeout(() => handleShow(tippy));
          return false;
        }

        // Redirects uncontrolled attempts to open a controlled component to a callback prop
        if (typeof visible !== 'undefined') {
          if (onRequestOpen) onRequestOpen();

          if (!visible) return false;
        }

        setHideTimeout(null);
        if (!isMountedRef.current) return;

        const popperNode = tippy.popper;

        allowedToHide.current = false;

        // Resting styles
        popperNode.classList.remove(styles.hiding);
        if (backdropNode.current) {
          backdropNode.current.classList.add(styles.active);
        }

        setIsShown(true);
        if (onShow) onShow(tippy);
      },
      [visible, onRequestOpen, backdropNode, setHideTimeout, onShow, delayShowTimeout]
    );

    const handleHide = useCallback(
      (tippy) => {
        if (!isMountedRef.current) return false;
        if (allowedToHide.current) return true;

        const popperNode = tippy.popper;

        // Setting hiding styles
        popperNode.classList.add(styles.hiding);
        if (backdropNode.current) {
          backdropNode.current.classList.remove(styles.active);
        }

        setHideTimeout(() => {
          if (!isMountedRef.current) return;

          allowedToHide.current = true;
          tippy.hide();

          setIsShown(false);
          if (onHide) onHide(tippy);
        }, 150);

        return false;
      },
      [backdropNode, setHideTimeout, onHide, isMountedRef]
    );

    /* ----------------- CLOSING ON CLICK OUTSIDE AND SCROLLING ----------------- */

    // Support for controlled/uncontrolled, interactive/not interactive dropdowns,
    // and hiding on scroll / click outside.
    // Should be refactored in the future to make it more maintainable.
    const { tippyProps, rendererProps } = useMemo(() => {
      // Events
      const handleRequestClose = (e) => {
        const instance = tippyInstanceRef.current;
        if (!instance) {
          return;
        }

        // Cancel closing request if dropdown is about to be opened
        if (e && instance.reference.contains(e.target)) {
          return;
        }

        if (isControlled) {
          if (onRequestClose) onRequestClose(instance);
        } else {
          instance.hide();
        }
      };

      // Props
      const tippyProps = { plugins: [] };
      const rendererProps = {};

      if (interactive && !isControlled) {
        rendererProps.onRequestClose = onRequestClose;
      }

      if (sticky) {
        tippyProps.sticky = true;
        tippyProps.plugins.push(stickyPlugin);
      }

      if (hideOnClick) {
        if (interactive) {
          rendererProps.onRequestClose = handleRequestClose;
          tippyProps.hideOnClick = isControlled ? undefined : false;
        } else {
          if (!isControlled) {
            tippyProps.hideOnClick = true;
          }
        }
      }

      // Supported in interactive tooltips (for non interactive hideOnClick does the same)
      if (hideOnClickInside && interactive) {
        rendererProps.hideOnClickInside = true;
      }

      if (hideOnScroll) {
        if (interactive) {
          rendererProps.onRequestClose = handleRequestClose;
        } else {
          tippyProps.onScroll = handleRequestClose;
          tippyProps.plugins.push(hideOnScrollPlugin);
        }
      }

      return { tippyProps, rendererProps };
    }, [isControlled, interactive, hideOnClick, hideOnClickInside, hideOnScroll, sticky, onRequestClose]);

    /* ----------------------------------- JSX ---------------------------------- */

    return (
      <Tippy
        visible={visible}
        interactive={interactive}
        zIndex={227}
        delay={[500, 0]}
        appendTo={document.body}
        placement={placement}
        offset={offset || [skidding, distance || arrow ? 6 : 0]}
        onCreate={handleCreate}
        onDestroy={handleDestroy}
        onShow={handleShow}
        onHide={handleHide}
        render={(attrs, singletonContent, tippyInstance) => {
          const content = rest.content || singletonContent;
          return interactive ? (
            <TooltipContentInteractive
              theme={theme}
              arrow={arrow}
              content={content}
              tippyAttrs={attrs}
              tippyInstance={tippyInstance}
              isShown={isShown}
              unmountContentOnHide={unmountContentOnHide}
              {...rendererProps}
            />
          ) : (
            <TooltipContentBasic theme={theme} arrow={arrow} isShown={isShown} content={content} tippyAttrs={attrs} />
          );
        }}
        {...tippyProps}
        {...rest}
        ref={ref}
      />
    );
  }
);

Tooltip.Context = TooltipContext;
Tooltip.useSingleton = useSingleton;

export default Tooltip;

// Popover preset
export const Popover = React.forwardRef(({ theme = 'white', ...rest }, ref) => (
  <Tooltip
    backdrop={theme === 'white' ? true : false}
    appendTo={document.body}
    delay={0}
    theme={'popover-' + theme}
    placement="bottom-start"
    hideOnScroll={false}
    hideOnClickInside={false}
    interactive={true}
    sticky={true}
    // Default trigger only for uncontrolled mode
    trigger={'visible' in rest ? undefined : 'click'}
    {...rest}
    ref={ref}
  />
));
Popover.Context = TooltipContext;

Popover.Hr = ({ className, ...rest }) => <hr className={[styles.popoverHr].join(' ')} {...rest} />;
Popover.Group = ({ compact = false, separators = true, ...rest }) => (
  <div className={[styles.popoverGroup, compact ? styles.compact : '', separators ? styles.separators : ''].join(' ')} {...rest} />
);
Popover.Button = React.forwardRef(({ className, ...props }, ref) => (
  <Button className={[className || '', styles.popoverButton].join(' ')} theme="inherit" tooltipPlacement="right" {...props} ref={ref} />
));
