import styles from './Header.module.css';

import React from 'react';

export const Header = ({ children, style, className }) => {
  return (
    <div className={[styles.main, className].join(' ')} style={style}>
      {children}
    </div>
  );
};
