import styles from './Header.module.css';

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from 'ui-components';

const ContentHeader = ({ title, description, tabs, onTabClick }) => {
  return <div className={styles.main}>
    <Header className={styles.title}>{title}</Header>
    {description && <div className={styles.description}>{description}</div>}

    {tabs && <ul className={styles.tabs}>
      {tabs.map((tab) => <li key={tab._id} className={tab.active ? styles.active : ''} onClick={() => onTabClick(tab)}>
        {tab.label}
        {tab.number && <div className={[
          styles.number,
          tab.number <= 9 ? styles.one : '',
          tab.number >= 10 ? styles.two : ''
        ].join(' ')}>{tab.number}</div>}
      </li>)}
    </ul>}
  </div>;
};

export default ContentHeader;
