import styles from './Logs.module.css';

import React, { useState, useMemo } from 'react';
import { addDays, addMonths } from 'date-fns';

import { Tabs, Select } from 'ui-components';
import ContentHeader from '../Header.js';

import PaginatedLogs from './PaginatedLogs';

/* -------------------------------------------------------------------------- */
/*                                   CONFIG                                   */
/* -------------------------------------------------------------------------- */

const CURRENT_DATE = new Date();

const REQUESTED_LOGS_FILTERS_OPTIONS = [
  {
    value: null,
    label: 'All'
  },
  {
    value: {
      types: ['emailSent', 'emailError'],
      smtp: false
    },
    label: 'QuestionScout Emails'
  },
  {
    value: {
      types: ['emailSent', 'emailError'],
      smtp: true
    },
    label: 'SMTP Emails'
  },
  {
    value: {
      types: ['submissionsAutoUpdated', 'submissionsAutoDeleted']
    },
    label: 'Submissions'
  }

  /*
  // These seem to be not used ATM

  {
    value: ['formDelete', 'formCreate'],
    label: 'Forms'
  },
  {
    value: ['userDelete'],
    label: 'Users'
  }
  */
];

const REQUESTED_LOGS_START_DATE_OPTIONS = [
  {
    value: null,
    label: 'All'
  },
  {
    value: addDays(CURRENT_DATE, -3),
    label: 'Last 3 days'
  },
  {
    value: addDays(CURRENT_DATE, -5),
    label: 'Last 5 days'
  },
  {
    value: addDays(CURRENT_DATE, -7),
    label: 'Last 7 days'
  },
  {
    value: addMonths(CURRENT_DATE, -1),
    label: 'Last month'
  }
];

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const Logs = React.memo(() => {
  /* ---------------------------------- STATE --------------------------------- */

  const [requestedFilters, setRequestedFilters] = useState(null);
  const [requestedStartDateOption, setRequestedStartDateOption] = useState(REQUESTED_LOGS_START_DATE_OPTIONS[0]);

  const filters = useMemo(() => {
    return {
      ...requestedFilters,
      startDate: requestedStartDateOption.value
    };
  }, [requestedStartDateOption, requestedFilters]);

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <>
      <ContentHeader title="Logs" />

      <div className={styles.container}>
        <div className={styles.filters}>
          <Tabs
            theme="contained"
            data={REQUESTED_LOGS_FILTERS_OPTIONS}
            value={requestedFilters}
            onChange={(value) => setRequestedFilters(value)}
          />

          <Select
            width="150px"
            value={requestedStartDateOption}
            onChange={(option) => setRequestedStartDateOption(option)}
            options={REQUESTED_LOGS_START_DATE_OPTIONS}
          />
        </div>

        <PaginatedLogs filters={filters} />
      </div>
    </>
  );
});

export default Logs;
