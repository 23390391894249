import styles from './Slider.module.css';

import React from 'react';
import { Icon, Slider as MainSlider } from 'ui-components';

const Slider = React.forwardRef(
  ({ label, style, className, min, max, value, onChange, showValues, unit, hint, borderTop, borderBottom }, ref) => {
    let classList = [styles.slider, className, borderTop ? styles.borderTop : '', borderBottom ? styles.borderBottom : ''];

    return (
      <div ref={ref} style={style} className={classList.join(' ')}>
        <div className={styles.sliderLabel}>{label}</div>

        <MainSlider min={min} max={max} value={value} onChange={onChange} showValues unit={unit} style={{ width: '100%' }} />

        {hint && (
          <div className={styles.sliderHint}>
            <Icon id="warning" />
            {hint}
          </div>
        )}
      </div>
    );
  }
);

export default Slider;
