import React from 'react';
import { Redirect } from 'react-router-dom';
import config from 'config.js';

export const vatEuCountryCodes = [
  'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'EL', 
  'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 
  'SI', 'ES', 'SE'
];

export const euCountryCodes = [
  'AL', 'AD', 'AM', 'AT', 'BY', 'BE', 'BA', 'BG', 'CH', 'CY', 'CZ', 'DE',
  'DK', 'EE', 'ES', 'FO', 'FI', 'FR', 'GB', 'GE', 'GI', 'GR', 'HU', 'HR',
  'IE', 'IS', 'IT', 'LI', 'LT', 'LU', 'LV', 'MC', 'MK', 'MT', 'NO', 'NL', 
  'PL', 'PT', 'RO', 'RU', 'SE', 'SI', 'SK', 'SM', 'TR', 'UA', 'VA',
];

export const HexToRgbA = (hex, opacity) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ' )';
  }
  throw new Error('Bad Hex');
};

export const getFormsPrice = (forms) => {
  const priceArr = [...Array(forms).keys()];

  let price = 0;

  if (forms === 1) return 0;

  price += priceArr.slice(1, 10).length * 0.75;
  price += priceArr.slice(10, 20).length * 0.65;
  price += priceArr.slice(20, 30).length * 0.60;
  price += priceArr.slice(30, 40).length * 0.50;
  price += priceArr.slice(40, 50).length * 0.40;
  price += priceArr.slice(50, 100).length * 0.30;
  price += priceArr.slice(100, 200).length * 0.20;
  price += priceArr.slice(200, 500).length * 0.15;
  price += priceArr.slice(500, 1000).length * 0.10;

  return Math.round(price * 100) / 100;
};

export const getUsersPrice = (users) => {
  return (users - 1) * 5;
};

export const getDomainsPrice = (domains) => {
  return domains * 10;
};

export const getSubmissionsPrice = (submissions) => {
  return Math.round((submissions * 0.01 - 0.5) * 100) / 100;
};

export const getFilesStoragePrice = (filesStorage) => {
  const priceArr = [...Array(filesStorage).keys()];

  let price = 0;

  if (filesStorage === 100) return 0;

  price += priceArr.slice(101, 500).length * 0.015;
  price += priceArr.slice(501, 1000).length * 0.01;
  price += priceArr.slice(1001, 10000).length * 0.005;

  return Math.ceil(price * 10) / 10;
};

export const formatBytes = (bytes, decimals = 1) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getTaxRateForCountry = (country, vatEu) => {
  let taxRate = 0;

  if (euCountryCodes.indexOf(country) !== -1) {
    taxRate = 21;
  }

  if (country !== 'NL' && vatEuCountryCodes.indexOf(country) !== -1 && vatEu) {
    taxRate = 0;
  }

  return taxRate;
};

export const getUrlWithParameters = (url, params) => {
  const urlObject = new URL(url);

  for (let param of params) {
    if (!param.key) continue;

    urlObject.searchParams.append(param.key, param.value);
  }

  return urlObject.href;
};

export const verifyPermission = (name, user) => {
  return (config.permissions[name].indexOf(user.role) !== -1);
};

export const verifyPermissionAndRender = (component, name, user) => {
  if (config.permissions[name].indexOf(user.role) !== -1) {
    return component;
  } else {
    return () => <Redirect to="/" />;
  }
};

export const isJsonString = (str) => {
  if (str === null) return false;

  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};
