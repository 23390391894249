import styles from './Avatar.module.css';

import React, { forwardRef } from 'react';
import ColorHash from 'color-hash';
import { Spinner } from 'ui-components';

import config from 'config.js';

const colorHash = new ColorHash({});

const sizes = {
  huge: 50,
  large: 34,
  medium: 24,
  small: 18
};

const dotSizes = {
  huge: {
    width: '14px',
    height: '14px',
    bottom: '-5px',
    right: '-5px'
  },
  large: {
    width: '14px',
    height: '14px',
    bottom: '-5px',
    right: '-5px'
  },
  medium: {
    width: '10px',
    height: '10px',
    bottom: '-4px',
    right: '-4px'
  },
  small: {
    width: '7px',
    height: '7px',
    bottom: '-3px',
    right: '-3px'
  }
};

export const Avatar = forwardRef(({ className, onClick, user, size, active, loading }, ref) => {
  if (!user) return null;

  const selectedSize = typeof size === 'number' ? `${size}px` : sizes[size || 'medium'];
  const color = colorHash.hex((user.email || '').replace(/[^a-zA-Z0-9]+/g, ''));
  const owner = user.owner && user.owner._id ? user.owner._id : user.owner;

  let mainStyles = {
    minHeight: `${selectedSize}px`,
    minWidth: `${selectedSize}px`,
    maxHeight: `${selectedSize}px`,
    maxWidth: `${selectedSize}px`,
    fontSize: `${selectedSize / 1.8}px`,
    cursor: onClick ? 'pointer' : 'default'
  };

  if (user.avatar)
    mainStyles.backgroundImage = `url('https://d3djdih2k2vfi2.cloudfront.net/avatars/${owner || user._id}/${user._id}.png?hash=${
      user.avatar
    }')`;

  return (
    <div
      ref={ref}
      className={[
        styles.main,
        className,
        active ? styles.active : '',
        loading ? styles.loading : '',
        config.fullStoryHardExcludeClass
      ].join(' ')}
      style={mainStyles}
      onClick={onClick}>
      {!user.avatar && (user.firstName || user.lastName || user.email || '').charAt(0).toUpperCase()}
      <div className={styles.activeDot} style={{ background: color, ...dotSizes[size || 'medium'] }} />

      {loading && (
        <div className={styles.loader}>
          <Spinner size={sizes[size || 'medium'] / 2.5} borderSize={2} speed="0.8s" />
        </div>
      )}
    </div>
  );
});
