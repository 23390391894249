import styles from './ChatBox.module.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Popover } from '../Tooltip/Tooltip';
import { Input } from '../Input/Input';
import { Icon } from '../Icon/Icon';

import { safeFormatDistance } from 'helpers';

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

const SKELETONS = {
  authorAvatar: <div className={styles.skeleton} style={{ width: 19, height: 19, borderRadius: 4 }} />,
  authorName: <div className={styles.skeleton}>Author</div>,
  createdAt: <div className={styles.skeleton}>1d ago</div>,
  text: <div className={styles.skeleton}>Some placeholder text</div>
};

const parseUserAvatar = (userAvatar) => {
  if (userAvatar) {
    return typeof userAvatar === 'string' ? <img className={styles.avatar} src={userAvatar} alt={'avatar'} /> : userAvatar;
  } else {
    return SKELETONS.authorAvatar;
  }
};

/* -------------------------------------------------------------------------- */
/*                              MESSAGE COMPONENT                             */
/* -------------------------------------------------------------------------- */

const AutoRefreshingTimestamp = React.memo(({ timestamp }) => {
  const [parsedTimestamp, setParsedTimestamp] = useState(null);

  const refreshTimestamp = useCallback(() => {
    let parsedTimestamp = null;

    if (timestamp) {
      parsedTimestamp = safeFormatDistance(new Date(timestamp));
    }
    if (parsedTimestamp === 'less than a minute ago') {
      // there is a very limited space, this is more compact
      parsedTimestamp = 'now';
    }

    setParsedTimestamp(parsedTimestamp);
  }, [timestamp]);

  useEffect(() => {
    refreshTimestamp();

    const interval = setInterval(refreshTimestamp, 30 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [refreshTimestamp]);

  return parsedTimestamp;
});

const Message = React.memo(({ id, authorName, authorAvatar, createdAt, text, editable = false, onRequestDeleteMessage }) => {
  return (
    <div className={styles.message}>
      <div className={styles.messageLeftColumn}>{parseUserAvatar(authorAvatar)}</div>
      <div className={styles.messageRightColumn}>
        <div className={styles.messageHeader}>
          <div className={styles.messageAuthor}>{authorName ?? SKELETONS.authorName}</div>
          <div className={styles.messageDate}>{<AutoRefreshingTimestamp timestamp={createdAt} /> ?? SKELETONS.createdAt}</div>
          {editable && (
            <div className={styles.messageShowMore}>
              {
                <Popover
                  theme="black"
                  backdrop={false}
                  placement="bottom"
                  content={
                    <Popover.Group compact>
                      <Popover.Button icon="delete" delay={1000} onClick={() => onRequestDeleteMessage(id)}>
                        Delete
                      </Popover.Button>
                    </Popover.Group>
                  }>
                  <Icon id="more-horizontal" />
                </Popover>
              }
            </div>
          )}
        </div>
        <div className={styles.messageText}>{text ?? SKELETONS.text}</div>
      </div>
    </div>
  );
});

/* -------------------------------------------------------------------------- */
/*                             CHAT BOX COMPONENT                             */
/* -------------------------------------------------------------------------- */

const ChatBox = React.memo(
  ({
    className = '',
    readOnly,
    placeholder = 'There are no messages yet.',
    editorPlaceholder = 'Adding comments is disabled.',
    messages = [],
    userAvatar,
    onRequestCreateMessage,
    onRequestDeleteMessage
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [messageInput, setMessageInput] = useState('');

    const messagesContainerRef = useRef(null);

    /* ----------------------------- EVENT HANDLERS ----------------------------- */

    const newMessageWasRecentlyRequested = useRef(false);

    const scrollToBottom = useCallback(() => {
      const messagesContainer = messagesContainerRef.current;
      if (messagesContainer) {
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      }
    }, []);

    // Scrolls to bottom on mount
    useEffect(() => {
      scrollToBottom();
    }, [scrollToBottom]);

    const handleInputKeyDown = useCallback(
      (e) => {
        if (e.key === 'Enter') {
          // Prevents onChange with enter
          e.preventDefault();

          onRequestCreateMessage(e.target.value);
          setMessageInput('');

          newMessageWasRecentlyRequested.current = true;
        }
      },
      [onRequestCreateMessage]
    );

    // Scrolls to the bottom when new message is added
    useEffect(() => {
      if (newMessageWasRecentlyRequested.current) {
        scrollToBottom();
        newMessageWasRecentlyRequested.current = false;
      }
    }, [messages, scrollToBottom]);

    /* ----------------------------------- JSX ---------------------------------- */

    return (
      <div className={[className, styles.chatBox].join(' ')}>
        <div className={styles.messages} ref={messagesContainerRef}>
          {messages?.length ? (
            messages.map((data, i) => {
              const key = data?.id || data.text || i;
              return <Message key={key} {...data} onRequestDeleteMessage={onRequestDeleteMessage} />;
            })
          ) : (
            <div className={styles.placeholder}>{placeholder}</div>
          )}
        </div>

        {readOnly ? (
          <div className={styles.editorPlaceholder}>{editorPlaceholder}</div>
        ) : (
          <div className={styles.editor}>
            {parseUserAvatar(userAvatar)}
            {isEditing ? (
              <Input
                autoFocus
                multiline
                value={messageInput}
                onChange={setMessageInput}
                onKeyDown={handleInputKeyDown}
                onBlur={() => {
                  if (!messageInput) setIsEditing(false);
                }}
              />
            ) : (
              <Input
                placeholder="Write comment"
                onClick={() => {
                  setIsEditing(true);
                  setTimeout(scrollToBottom);
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);

export default ChatBox;
