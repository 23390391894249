import styles from './Mentions.module.css';

import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react';
import { Editor, Transforms, Range, Point, Path, createEditor } from 'slate';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import isUrl from 'is-url';
import isHtml from 'is-html';
import { withHistory } from 'slate-history';
import { jsx } from 'slate-hyperscript';
import { Slate, Editable, ReactEditor, withReact, useSlate } from 'slate-react';
import isHotkey from 'is-hotkey';
import scrollIntoView from 'scroll-into-view';
import { isString as _isString } from 'lodash';

import * as Sentry from '@sentry/browser';

import { Icon } from '../Icon/Icon';
import SearchBox from '../SearchBox/SearchBox';
import NavigatorEntry from '../NavigatorEntry/NavigatorEntry';

import isJsonString from 'helpers/isJsonString.js';
import getFieldLabel from 'helpers/getFieldLabel.js';

/* -------------------------------------------------------------------------- */
/*                                   CONFIG                                   */
/* -------------------------------------------------------------------------- */

const FALLBACK_MENTION_PICKER_ICON = <span style={{ opacity: 0.5 }}>@</span>;
const FALLBACK_MENTION_ICON = <span style={{ opacity: 0.5, transform: 'translateY(-1px)' }}>@</span>;

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline'
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const ELEMENT_TAGS = {
  A: (el) => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-five' }),
  H6: () => ({ type: 'heading-six' }),
  IMG: (el) => ({ type: 'image', url: el.getAttribute('src') }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' })
};

const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true })
};

/* -------------------------------------------------------------------------- */
/*                             UTILITY COMPONENTS                             */
/* -------------------------------------------------------------------------- */

const Button = React.forwardRef(({ className, active, reversed, icon, ...props }, ref) => (
  <span {...props} data-not-outside={true} ref={ref} className={[styles.button, active ? styles.active : '', styles[icon]].join(' ')} />
));

const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

/* -------------------------------------------------------------------------- */
/*                    SLATE - LINKS AND FORMATTING (LOGIC)                    */
/* -------------------------------------------------------------------------- */

/* --------------------------------- PLUGIN --------------------------------- */

const withLinks = (editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

/* -------------------------------- CHECKERS -------------------------------- */

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, { match: (n) => n.type === 'link' });
  return !!link;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format
  });

  return !!match;
};

/* -------------------------------- COMMANDS -------------------------------- */

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, { match: (n) => n.type === 'link' });
};

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : []
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

/* -------------------------------------------------------------------------- */
/*                 SLATE - LINKS AND FORMATTING (COMPONENTS)                  */
/* -------------------------------------------------------------------------- */

/* ----------------------------- TOOLBAR BUTTONS ---------------------------- */

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();

  return (
    <Button
      icon={icon}
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    />
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();

  return (
    <Button
      icon={icon}
      active={isMarkActive(editor, format)}
      onMouseDown={(e) => {
        e.preventDefault();
        toggleMark(editor, format);
      }}
    />
  );
};

const LinkButton = () => {
  const editor = useSlate();
  return (
    <Button
      icon="link"
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        event.preventDefault();
        const url = window.prompt('Enter the URL of the link:');
        if (!url) return;
        insertLink(editor, url);
      }}
    />
  );
};

/* --------------------------------- TOOLBAR -------------------------------- */

const HoveringToolbar = () => {
  const ref = useRef();
  const editor = useSlate();

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) return;

    if (!selection || !ReactEditor.isFocused(editor) || Range.isCollapsed(selection) || Editor.string(editor, selection) === '') {
      el.removeAttribute('style');
      return;
    }

    const domSelection = window.getSelection();
    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();

    el.style.opacity = 1;
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;
  });

  return (
    <Portal>
      <div ref={ref} data-not-outside={true} className={styles.tooltipMenu}>
        <MarkButton format="bold" icon="formatBold" />
        <MarkButton format="italic" icon="formatItalic" />
        <MarkButton format="underline" icon="formatUnderlined" />
        <LinkButton />
      </div>
    </Portal>
  );
};

/* -------------------------------------------------------------------------- */
/*                              SLATE - MENTIONS                              */
/* -------------------------------------------------------------------------- */

/* --------------------------------- PLUGIN --------------------------------- */

const withMentions = (editor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === 'mention' ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === 'mention' ? true : isVoid(element);
  };

  return editor;
};

/* -------------------------------- COMMANDS -------------------------------- */

const insertMention = (editor, data) => {
  const mentionNode = {
    type: 'mention',
    _id: data._id,
    key: data.label,
    origin: (() => {
      if (data.type === 'urlParam') return 'urlParams';
      if (data.type === 'variable') return 'calculations';

      return 'field';
    })(),
    children: [{ text: '' }]
  };

  Transforms.insertNodes(editor, mentionNode);
};

/* -------------------------------- CHECKERS -------------------------------- */

// Checks if currently user edits a mention. Returns either null or {range, text},
// where text is mention text and range is selection from @ to mention text end.
// Mention ins matched if cursor is collapsed and at the end or in the middle of a mention.
// TODO: find some more appropriate API in Slate docs and optimize this
const matchEditedMention = (editor) => {
  const selection = editor.selection;

  if (selection && Range.isCollapsed(selection)) {
    const [cursor] = Range.edges(selection);
    if (!cursor) return null;

    // Checks if @ symbol is right before cursor
    const charBeforeCursorStart = Editor.before(editor, cursor, { unit: 'character' });
    const charBeforeCursorRange = charBeforeCursorStart && Editor.range(editor, charBeforeCursorStart, cursor);
    const charBeforeCursorText = charBeforeCursorRange && Editor.string(editor, charBeforeCursorRange);
    const cursorIsAfterAt = charBeforeCursorText === '@';

    // Given: 'aaa @wwwww|www' <--- Looks for this point : @(!)wwwwwww
    // If char before cursor is @, cursor point will be treated as start of the word because
    // slate would look for something even earlier as @ is not threated as a word character (like spaces)
    const wordStart = cursorIsAfterAt ? cursor : Editor.before(editor, cursor, { unit: 'word' });

    // Given: 'aaa @wwwww|www' <--- Looks for this point : @wwwwwww(!)
    // Word start is used as a fallback (empty selection) because if word starts after @,
    // and then there is nothing else, world would not be matched.
    let wordEnd = (wordStart && Editor.after(editor, wordStart, { unit: 'word' })) || wordStart;

    // Word was not matched
    if (!wordStart || !wordEnd) {
      return null;
    }

    // Cursor is after a matched word
    // Prevents detection in cases like this: '@mention   |'
    if (Point.isAfter(cursor, wordEnd)) {
      return null;
    }

    // Start and end should be in the same node. If they are not most likely a inline block was
    // detected as a word end. Scenario: '@|[MENTION]' (there were no other chars earlier).
    // Attempt to later replace it could cause exceptions so it will be assumed that wordStart is word end.
    if (!Path.equals(wordStart.path, wordEnd.path) && !Path.isSibling(wordStart.path, wordEnd.path)) {
      wordEnd = wordStart;
    }

    const wordRange = Editor.range(editor, wordStart, wordEnd);
    const wordText = wordRange && Editor.string(editor, wordRange);

    // @ is considered by Slate to be one of characters that split words so it have to be matched separately
    // If everything above was matched correctly mentionText is a mention if right before it there is an @ symbol
    const charBeforeWordStart = wordStart && Editor.before(editor, wordStart, { unit: 'character' });
    const charBeforeWordRange = charBeforeWordStart && Editor.range(editor, charBeforeWordStart, wordStart);
    const charBeforeWordText = charBeforeWordRange && Editor.string(editor, charBeforeWordRange);
    const wordIsAfterAt = charBeforeWordText === '@';

    // Word is not a mention
    if (!wordIsAfterAt) {
      return null;
    }

    if (!charBeforeWordStart || !wordEnd) {
      return null;
    }

    let mentionText = wordText || '';
    let mentionRange = null;

    // Additional checks for mention text content that will make
    // using mentions around math symbols / url symbols way easier
    // since there will be less unnecessary matches and replacements.

    const mentionTextLooksLikeWord = /^\w+$/.test(mentionText);
    if (mentionTextLooksLikeWord) {
      // Range over string like @abc123
      mentionRange = Editor.range(editor, charBeforeWordStart, wordEnd);
    } else {
      // Range over @ symbol only since word contained some weird symbols.
      mentionText = '';
      mentionRange = Editor.range(editor, charBeforeWordStart, wordStart);
    }

    if (!mentionRange) {
      return null;
    }

    return {
      range: mentionRange,
      text: mentionText.trim()
    };
  }
};

/* ---------------------- SLATE INLINE BLOCK COMPONENT ---------------------- */

const MentionElement = ({ attributes, children, element, data }) => {
  // Field data
  const mention = data.find((e) => e._id === element._id);

  // Classes
  const className = [styles.mentionElement, !mention ? styles.error : ''].join(' ');

  // Content JSX
  const iconJsx = mention?.icon || FALLBACK_MENTION_ICON;
  const indexJsx = mention?.index;
  let labelJsx = null;

  if (element?.origin === 'urlParams') {
    // Temporary fix:
    // it seems that form engine is taking urlParm key directly from mention,
    // and not by looking at _id and connected urlParam object. Because of that if urlParam key
    // is changed, mention will be in a way disconnected from that urlParam defined in settings.
    // That is why key will be used to make mention label as it is the source of truth in this case.
    labelJsx = element.key;
  } else {
    labelJsx = mention?.parsedLabel || getFieldLabel(mention?.label || '');
  }

  // Fallback for label
  if (!labelJsx) {
    const parsedElementKey = getFieldLabel(element.key);
    if (parsedElementKey) {
      labelJsx = <>@{parsedElementKey}</>;
    }
  }

  return (
    <span {...attributes} contentEditable={false} className={className}>
      {iconJsx ? <span className={styles.image}>{iconJsx}</span> : null}

      <span className={styles.label}>
        {indexJsx ? <strong>{indexJsx}</strong> : null}
        <span>
          {!mention && '(DELETED) '}
          {labelJsx || '___'}
          {children}
        </span>
      </span>
    </span>
  );
};

const MentionsPickerEntry = React.memo(({ active, element, onClick }) => {
  const elementRef = useRef(null);

  const icon = element.icon || FALLBACK_MENTION_PICKER_ICON;
  const index = element.index;
  const label = element.parsedLabel || getFieldLabel(element.label);

  useEffect(() => {
    const element = elementRef.current;
    if (active && element) {
      scrollIntoView(element, { time: 100 });
    }
  }, [active]);

  return (
    <NavigatorEntry
      active={active}
      dimmedBackground={false}
      style={useMemo(() => ({ cursor: 'pointer' }), [])}
      icon={icon}
      index={index}
      label={label}
      onClick={onClick}
      // Prevents immediate blur event for inputs
      // so that above onClick is called, before blur on other elements
      // is emitted
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      ref={elementRef}
    />
  );
});

/* -------------------------------------------------------------------------- */
/*                              SLATE - RENDERERS                             */
/* -------------------------------------------------------------------------- */

const Element = (props) => {
  const { attributes, children, element, menu } = props;

  if (!menu && element.type !== 'mention') return <span {...attributes}>{children}</span>;

  switch (element.type) {
    case 'mention':
      return <MentionElement {...props} />;
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'paragraph':
      return <p {...attributes}>{children}</p>;
    case 'link':
      return (
        <a {...attributes} href={element.url}>
          {children}
        </a>
      );
    default:
      return <span {...attributes}>{children}</span>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

/* -------------------------------------------------------------------------- */
/*                               SLATE - PARSERS                              */
/* -------------------------------------------------------------------------- */

const getInitialValue = (value) => {
  value = JSON.parse(value);

  if (Array.isArray(value) && value.length === 0) {
    return deserializeString('');
  }

  return value;
};

const deserialize = (value) => {
  if (!isHtml(value)) return deserializeString(value);

  let document = new DOMParser().parseFromString(value, 'text/html');
  const childs = document.body.childNodes;

  for (let child of childs) {
    if (child.nodeType === 3) {
      document = new DOMParser().parseFromString(`<p>${value}</p>`, 'text/html');

      break;
    }
  }

  return deserializeHtml(document.body);
};

const deserializeString = (value) => {
  return [
    {
      type: 'paragraph',
      children: [{ text: value }]
    }
  ];
};

const deserializeHtml = (el) => {
  if (el.nodeType === 3) {
    if ((el.textContent.match(/\n/g) || []).length !== 0) return null;

    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  }

  const { nodeName } = el;
  let parent = el;

  if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
    parent = el.childNodes[0];
  }

  let children = Array.from(parent.childNodes).map(deserializeHtml).flat();

  if (children.length === 0) return jsx('fragment', {}, children);
  if (el.nodeName === 'BODY') return jsx('fragment', {}, children);

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);

    return jsx('element', attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);

    return children.map((child) => jsx('text', attrs, child));
  }

  return jsx('fragment', {});
};

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const EMPTY_ARRAY = [];

const _Mentions = ({
  value: currentValue,
  initialValue, //
  data = EMPTY_ARRAY,
  showMentionsIfThereIsNoData = true,
  menu,
  style,
  disabled,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  className,
  inline = false
}) => {
  // Returns stringified Slate editor value, as object with parsed value and source string value.
  const parseExternalValue = (value) => {
    return {
      obj: isJsonString(value) ? getInitialValue(value) : deserialize(value || ''),
      string: value
    };
  };

  /* ---------------------------------- STATE --------------------------------- */

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const mentionSelectedInPickerRef = useRef(null);

  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [focused, setFocused] = useState(false);
  const [searchBoxFocused, setSearchBoxFocused] = useState(false);
  const [localValueVersions, setLocalValueVersions] = useState(() => parseExternalValue(currentValue ?? initialValue));

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const renderElement = useCallback((props) => <Element {...props} data={data} menu={menu} />, [menu, data]);

  // NOTE: Editor object has to be stable, and useState is safer than useMemo in that regard
  const [editor] = useState(() => withLinks(withMentions(withReact(withHistory(createEditor())))));

  /* -------------------------------- REDUCERS -------------------------------- */

  const closeMentionsPicker = useCallback(() => {
    mentionSelectedInPickerRef.current = null;
    setTarget(null);
    setSearchBoxFocused(false);
    setSearchTerm('');
  }, []);

  const addMention = useCallback(
    (data) => {
      if (data) {
        Transforms.select(editor, target);
        insertMention(editor, data);
        Transforms.move(editor);
      }

      closeMentionsPicker();
    },
    [editor, target, closeMentionsPicker]
  );

  // Closes picker if nighter input or dropdown has focus
  useEffect(() => {
    if (!focused && !searchBoxFocused) {
      closeMentionsPicker();
    }
  }, [focused, searchBoxFocused, closeMentionsPicker]);

  /* ------------------------ CONTROLLED VALUE UPDATES ------------------------ */

  // When value prop changes (uses currentValue)
  useEffect(() => {
    setLocalValueVersions((localValueVersions) => {
      // Passed external value is already local
      if (localValueVersions?.string === currentValue) {
        return localValueVersions;
      }
      // External value is different from local
      else {
        const nextLocalValueVersions = parseExternalValue(currentValue);

        // IMPORTANT:
        // When new data is set, selection is defaulted to start to prevent
        // errors with referring to not existing slate note (can happen when new
        // data is shorter and selection was after new data end)
        // TODO: detect if reset is actually needed somehow to prevent cursor jumps on collaboration
        const point = { path: [0, 0], offset: 0 };
        editor.selection = { anchor: point, focus: point };

        closeMentionsPicker();

        return nextLocalValueVersions;
      }
    });
  }, [currentValue, editor, closeMentionsPicker]);

  /* ----------------------- MENTIONS POPOVER PLACEMENT ----------------------- */

  const updateDropdownPlacement = useCallback(() => {
    // DOM node that will be used as a reference during position computation
    const atCharElement = target ? ReactEditor.toDOMRange(editor, target) : null;
    const inputElement = inputRef.current;
    const dropdownElement = dropdownRef.current;

    if (!inputElement || !dropdownElement) return;

    const inputRect = inputElement.getBoundingClientRect();
    const dropdownRect = dropdownElement.getBoundingClientRect();
    const cursorRect = atCharElement && atCharElement.getBoundingClientRect();
    const yRefRect = cursorRect || inputRect;

    // Dropdown will have same width as input has,
    // and will be aligned with it in horizontal axis.
    const nextWidth = inputRect.width;
    const nextLeft = inputRect.left;

    // Placement in vertical axis will be either:
    // - bellow/above input box with MARGIN_Y if cursor position is not known
    // - under/above cursor with MARGIN_Y
    const MARGIN_Y = 10;
    const elHeight = dropdownRect.height;
    const winHeight = document.body.clientHeight;
    const refTop = yRefRect.top;
    const refBottom = yRefRect.bottom;
    const targetTop = refBottom + MARGIN_Y;
    const maxTop = winHeight - elHeight - MARGIN_Y;
    const fitsBellow = targetTop <= maxTop;
    const top = fitsBellow ? targetTop : refTop - elHeight - MARGIN_Y;

    // Style adjustments
    dropdownElement.style.width = nextWidth + 'px';
    dropdownElement.style.left = nextLeft + 'px';
    dropdownElement.style.top = top + 'px';
  }, [editor, target]);

  useEffect(() => {
    updateDropdownPlacement();
  }, [updateDropdownPlacement]);

  /* ------------------ MENTIONS RENDERING AND DATA HANDLING ------------------ */

  const dataStringifier = useCallback((v) => {
    let text = '';

    if (_isString(v.index)) {
      text += v.index;
    }

    if (_isString(v.parsedLabel)) {
      text += v.parsedLabel;
    } else {
      text += getFieldLabel(v.label);
    }

    return text.replace(/_/g, '');
  }, []);

  const renderMatchedData = useCallback(
    (matchedElements, searchTerm) => {
      mentionSelectedInPickerRef.current = null;
      if (!matchedElements.length) return null;

      const roundRobinIndex =
        index >= 0 ? index % matchedElements.length : ((index + 1) % matchedElements.length) + matchedElements.length - 1;

      return matchedElements.map((element, i) => {
        const isSelected = i === roundRobinIndex;

        if (isSelected) {
          mentionSelectedInPickerRef.current = element;
        }

        return (
          <MentionsPickerEntry
            key={element._id}
            active={isSelected}
            element={element}
            onClick={(e) => {
              addMention(element);
            }}
          />
        );
      });
    },
    [index, addMention]
  );

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const onSlateValueChange = useCallback(
    (value) => {
      const valueChanged = !localValueVersions || localValueVersions.obj !== value;

      if (valueChanged) {
        const stringifiedValue = JSON.stringify(value);

        setLocalValueVersions({
          obj: value,
          string: stringifiedValue
        });
        onChange(stringifiedValue);
      }

      if (!showMentionsIfThereIsNoData && !data?.length) return;

      const { selection } = editor;

      const mention = matchEditedMention(editor);
      if (mention) {
        setTarget(mention.range);
        setSearchTerm(mention.text);
        setIndex(0);
      }
      // If mention was not matched picker will be closed
      // but only if there is some selection in input, or if something changed.
      // Because of this condition when user focuses dropdown after typing in @,
      // component will know where to keep the dropdown and where to insert mention
      // after interaction in dropdown is finished
      else if (selection || valueChanged) {
        closeMentionsPicker();
      }
    },
    [editor, localValueVersions, onChange, showMentionsIfThereIsNoData, data?.length, closeMentionsPicker]
  );

  const onReferToIconClick = (e) => {
    // Mention editor is already open
    if (target) return;

    ReactEditor.focus(editor);

    if (!editor.selection) {
      Transforms.select(editor, Editor.end(editor, []));
    }

    Transforms.insertText(editor, '@');

    e.preventDefault();
  };

  const handleFocus = () => {
    if (onFocus) onFocus();
    setFocused(true);

    // Restores mention selection if one is still saved
    if (target) {
      Transforms.select(editor, target);
    }

    // Temporary fix for mention at cursor not detected
    // sometimes after blur because selection state at the same spot,
    // as before blur and on change was not called again.
    if (localValueVersions) onSlateValueChange(localValueVersions.obj);
  };

  const handleBlur = (e) => {
    // Temporary solution: delays blur so that other
    // focus events can be processed first
    setTimeout(() => {
      if (onBlur) onBlur();
      setFocused(false);
    });
  };

  const handleSearchBoxFocus = () => {
    setSearchBoxFocused(true);
  };
  const handleSearchBoxBlur = () => {
    // Temporary solution: delays blur so that other
    // focus events and onCLicks can be processed first
    setTimeout(() => setSearchBoxFocused(false));
  };

  const handleSearchBoxKeyDown = useCallback(
    (event) => {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          setIndex((index) => index + 1);
          break;

        case 'ArrowUp':
          event.preventDefault();
          setIndex((index) => index - 1);
          break;

        case 'Tab':
        case 'Enter':
          event.preventDefault();
          addMention(mentionSelectedInPickerRef.current);
          break;

        case 'Escape':
          event.preventDefault();
          closeMentionsPicker();
          setSearchBoxFocused(false);
          break;

        default:
      }
    },
    [closeMentionsPicker, addMention]
  );

  const onKeyDown = useCallback(
    (event) => {
      // Detects hot keys
      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event)) {
          event.preventDefault();

          toggleMark(editor, HOTKEYS[hotkey]);
        }
      }

      if (target) {
        handleSearchBoxKeyDown(event);
      }
    },
    [editor, target, handleSearchBoxKeyDown]
  );

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <>
      <Slate editor={editor} value={localValueVersions.obj} onChange={onSlateValueChange}>
        {menu === 'static' && (
          <div className={styles.staticMenu}>
            <MarkButton format="bold" icon="formatBold" />
            <MarkButton format="italic" icon="formatItalic" />
            <MarkButton format="underline" icon="formatUnderlined" />

            <BlockButton format="numbered-list" icon="numberedList" />
            <BlockButton format="bulleted-list" icon="bulletedList" />

            <LinkButton />
          </div>
        )}
        {menu === 'tooltip' && <HoveringToolbar />}

        <div
          style={style}
          className={[
            'intro-mentions',
            styles.mentions,
            inline ? styles.inline : styles.editable,
            menu === 'static' ? styles.withBar : '',
            disabled ? styles.disabled : '',
            focused || Boolean(target) ? styles.focused : '',
            className || ''
          ].join(' ')}
          ref={inputRef}>
          <Editable
            className={styles.content}
            renderElement={renderElement}
            onKeyDown={onKeyDown}
            renderLeaf={renderLeaf}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholder}
          />

          {Boolean(showMentionsIfThereIsNoData || data?.length) && (
            <button className={styles.referToIcon} disabled={disabled} onMouseDown={onReferToIconClick}>
              <Icon id="refer" />
            </button>
          )}
        </div>

        {/* Mentions picker popover */}
        {Boolean(showMentionsIfThereIsNoData || data?.length) && Boolean(target || searchBoxFocused) && (
          <Portal>
            <SearchBox
              className={styles.dropdown}
              searchTerm={searchTerm}
              inputPlaceholder="Refer to information from..."
              data={data}
              dataStringifier={dataStringifier}
              renderMatchedData={renderMatchedData}
              onInputFocus={handleSearchBoxFocus}
              onInputBlur={handleSearchBoxBlur}
              onInputKeyDown={handleSearchBoxKeyDown}
              ref={dropdownRef}
            />
          </Portal>
        )}
      </Slate>
    </>
  );
};

/* -------------------------------------------------------------------------- */
/*                    ERROR BOUNDARY FOR MENTIONS COMPONENT                   */
/* -------------------------------------------------------------------------- */

/*
  Temporary fix for slate based mentions crashing.
  Special error boundary for mentions that will allow one component
  crash and instant reload, but if component crashes on instant reload it will
  show permanent error message. Sometimes mentions crashed when value was changed
  and internal slate state got it confused and silent reload can help.
*/
class MentionSilentCrashHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error) {
    console.error(error);
    Sentry.captureException(error);

    return { error: true };
  }

  componentDidUpdate(prevProps, prevState) {
    const error = this.state.error;
    const prevError = prevState.error;

    // Clears errors and prevents infinite loops
    if (error && !prevError) {
      this.setState({ error: false });
    }
  }

  render() {
    const { children, childrenProps } = this.props;
    const { error } = this.state;

    return error ? (
      <div className={styles.silentCrashHandler} style={childrenProps?.style}>
        <span>Something went wrong. Please refresh page and try again or contact our support.</span>
      </div>
    ) : (
      children
    );
  }
}

const MentionsSilentCrashHandlerHOC = (Component) => {
  return (props) => (
    <MentionSilentCrashHandler childrenProps={props}>
      <Component {...props} />
    </MentionSilentCrashHandler>
  );
};

export const Mentions = MentionsSilentCrashHandlerHOC(_Mentions);
