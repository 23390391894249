import styles from './Table.module.css';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Icon } from 'ui-components';
import { MainContext } from 'contexts/main.js';
import ContentHeader from '../Header.js';
import scrollIntoView from 'scroll-into-view';
import { useSearchParams } from 'helpers';
import config from 'config.js';

const Table = ({ onUpgrade, onDowngrade, onUpdate, responses, setResponses }) => {
  const [searchParams] = useSearchParams({
    upsell: null
  });

  /* ------------------------------ FETCHED DATA ------------------------------ */

  const { user } = useContext(MainContext);

  const plan = user.billing.plan;

  /* ---------------------------------- STATE --------------------------------- */

  const [showMoreResponses, setShowMoreResponses] = useState(false);

  const highlightedFeatureId = searchParams.upsell;

  const highlightedFeature = config.features[highlightedFeatureId];
  const requiredPlanId = highlightedFeature?.requiredPlan;

  /* ---------------------------- HIGHLIGHTING ROWS --------------------------- */

  const tableElementRef = useRef(null);

  useEffect(() => {
    const tableElement = tableElementRef.current;
    const rowElement = document.getElementById(`feature-${highlightedFeatureId}`);

    const giveAttention = () => {
      // Scrolls element into a view and highlights it
      if (rowElement) {
        scrollIntoView(rowElement);
        rowElement.classList.add(styles.highlighted);
      }
      if (tableElement) {
        tableElement.classList.add(styles[`highlighted-plan-${requiredPlanId}`]);
      }
    };
    const takeAttention = () => {
      // Removes highlight
      if (rowElement) {
        rowElement.classList.remove(styles.highlighted);
      }
      if (tableElement) {
        tableElement.classList.remove(styles[`highlighted-plan-${requiredPlanId}`]);
      }
    };

    setTimeout(() => {
      giveAttention();
      setTimeout(takeAttention, 1500);
    }, 250);
  }, [highlightedFeatureId, requiredPlanId]);

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <>
      <ContentHeader
        title="Upgrade"
        description={
          <>
            {user.billing.plan && (
              <>
                Your account is currently on a <strong className={styles[user.billing.plan]}>{user.billing.plan}</strong> plan.
              </>
            )}
            {!user.billing.plan && (!user.billing.planStatus ? <>Your trial expired.</> : <>Your subscription was canceled.</>)}
          </>
        }
      />

      <div className={styles.content}>
        <div className={styles.table} ref={tableElementRef}>
          <div className={styles.head}>
            <div className={styles.item} />
            <div className={styles.item}>
              <div>
                <div className={[styles.name, styles.blue].join(' ')}>Personal</div>
                <div className={styles.price}>$5</div>
                <div className={styles.priceDesc}>Per month</div>
              </div>
              <div>
                {plan !== 'personal' && plan !== 'starter' && plan !== 'pro' && (
                  <Button theme="black" className={styles.button} onClick={() => onUpgrade('personal')} fullWidth>
                    Upgrade
                  </Button>
                )}
                {(plan === 'starter' || plan === 'pro') && (
                  <Button theme="white" className={styles.button} onClick={() => onDowngrade('personal')} fullWidth>
                    Downgrade
                  </Button>
                )}
              </div>
            </div>
            <div className={styles.item}>
              <div>
                <div className={[styles.name, styles.purple].join(' ')}>Starter</div>
                <div className={styles.price}>$10</div>
                <div className={styles.priceDesc}>Per collaborator, per month</div>
              </div>
              <div>
                {plan !== 'starter' && plan !== 'pro' && (
                  <Button theme="black" className={styles.button} onClick={() => onUpgrade('starter')} fullWidth>
                    Upgrade
                  </Button>
                )}
                {plan === 'pro' && (
                  <Button theme="white" className={styles.button} onClick={() => onDowngrade('starter')} fullWidth>
                    Downgrade
                  </Button>
                )}
                {user.billing.plan === 'starter' && (
                  <Button theme="black" className={styles.button} onClick={() => onUpdate()} fullWidth>
                    Update
                  </Button>
                )}
              </div>
            </div>
            <div className={styles.item}>
              <div>
                <div className={[styles.name, styles.orange].join(' ')}>Pro</div>
                <div className={styles.price}>$20</div>
                <div className={styles.priceDesc}>Per collaborator, per month</div>

                {responses > 10000 && (
                  <>
                    <div className={styles.price}>+${config.responsesOptions.find((option) => option.value === responses)?.price}</div>
                    <div className={styles.priceDesc}>Per month</div>
                  </>
                )}
              </div>
              <div>
                {plan !== 'pro' && (
                  <Button theme="black" className={styles.button} onClick={() => onUpgrade('pro')} fullWidth>
                    Upgrade
                  </Button>
                )}
                {user.billing.plan === 'pro' && (
                  <Button theme="black" className={styles.button} onClick={() => onUpdate()} fullWidth>
                    Update
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className={styles.body}>
            <div className={styles.header}>
              <div>Usage</div>
              <div />
              <div />
              <div />
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Fields per form</div>
              <div className={styles.blue}>Unlimited</div>
              <div>Unlimited</div>
              <div>Unlimited</div>
            </div>
            <div id="feature-unlimitedForms" className={styles.row}>
              <div className={styles.label}>Number of forms</div>
              <div className={styles.blue}>10</div>
              <div className={styles.purple}>Unlimited</div>
              <div>Unlimited</div>
            </div>

            <div id="feature-moreResponses" className={styles.row}>
              <div className={styles.label}>Monthly responses</div>
              <div className={styles.blue}>500 per month</div>
              <div className={styles.purple}>2,500 per month</div>
              <div className={styles.moreResponses}>
                <div className={styles.moreResponsesLabel} onClick={() => setShowMoreResponses(true)}>
                  {config.responsesOptions.find((option) => option.value === (responses >= 10000 ? responses : 10000))?.label}
                  <Icon id="open-sq" />
                </div>

                {showMoreResponses && (
                  <>
                    <div className={styles.moreResponsesCover} onClick={() => setShowMoreResponses(false)} />

                    <div className={styles.moreResponsesOptions}>
                      <div className={styles.label}>
                        <strong>Need more monthly responses?</strong>
                        <p>Select an option below to continue</p>
                      </div>
                      {config.responsesOptions.map((option) => (
                        <div
                          className={styles.option}
                          key={option.price}
                          onClick={() => {
                            setResponses(option.value);
                            setShowMoreResponses(false);
                          }}>
                          {option.label}
                          {` (+$${option.price}/mo)`}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Monthly file uploads</div>
              <div className={styles.blue}>250MB</div>
              <div className={styles.purple}>500mb</div>
              <div className={styles.orange}>5GB</div>
            </div>
            <div id="feature-collaborators" className={styles.row}>
              <div className={styles.label}>Collaborators</div>
              <div className={styles.blue}>Just you</div>
              <div className={styles.purple}>Up to 5</div>
              <div className={styles.orange}>As many as you need</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Guests</div>
              <div></div>
              <div className={styles.purple}>Up to 5</div>
              <div className={styles.orange}>Up to 10</div>
            </div>

            <div className={styles.spacer}>
              <div />
              <div />
              <div />
              <div />
            </div>

            <div className={styles.header}>
              <div>Features</div>
              <div />
              <div />
              <div />
            </div>

            <div className={styles.row}>
              <div className={styles.label}>Collect payments</div>
              <div className={styles.blue}>Included</div>
              <div>Included</div>
              <div>Included</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Refer to</div>
              <div className={styles.blue}>Included</div>
              <div>Included</div>
              <div>Included</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Integrations</div>
              <div className={styles.blue}>Included</div>
              <div>Included</div>
              <div>Included</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Custom thank you pages</div>
              <div className={styles.blue}>Included</div>
              <div>Included</div>
              <div>Included</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Custom welcome pages</div>
              <div className={styles.blue}>Included</div>
              <div>Included</div>
              <div>Included</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Custom messaging</div>
              <div className={styles.blue}>Included</div>
              <div>Included</div>
              <div>Included</div>
            </div>
            <div id="feature-customSeo" className={styles.row}>
              <div className={styles.label}>Custom SEO settings</div>
              <div></div>
              <div className={styles.purple}>Included</div>
              <div>Included</div>
            </div>
            <div id="feature-customEmail" className={styles.row}>
              <div className={styles.label}>Custom email content</div>
              <div></div>
              <div className={styles.purple}>Included</div>
              <div>Included</div>
            </div>
            <div id="feature-urlParams" className={styles.row}>
              <div className={styles.label}>URL parameter</div>
              <div></div>
              <div className={styles.purple}>Included</div>
              <div>Included</div>
            </div>
            <div id="feature-teamwork" className={styles.row}>
              <div className={styles.label}>Teamwork features</div>
              <div></div>
              <div className={styles.purple}>Included</div>
              <div>Included</div>
            </div>
            <div id="feature-redirectOnCompletion" className={styles.row}>
              <div className={styles.label}>Redirect on completion</div>
              <div></div>
              <div className={styles.purple}>Included</div>
              <div>Included</div>
            </div>
            <div id="feature-liveChatSupport" className={styles.row}>
              <div className={styles.label}>Live chat support</div>
              <div></div>
              <div className={styles.purple}>Included</div>
              <div>Included</div>
            </div>
            <div id="feature-removeBranding" className={styles.row}>
              <div className={styles.label}>Branding removal</div>
              <div />
              <div></div>
              <div className={styles.orange}>Included</div>
            </div>
            {/* <div className={styles.row}>
              <div className={styles.label}>Realtime collaboration</div>
              <div />
              <div />
              <div className={styles.orange}>Included</div>
            </div> */}
            <div id="feature-respondentLimits" className={styles.row}>
              <div className={styles.label}>Close on limits</div>
              <div />
              <div />
              <div className={styles.orange}>Included</div>
            </div>
            <div id="feature-ai" className={styles.row}>
              <div className={styles.label}>AI sentiment analysis</div>
              <div />
              <div />
              <div className={styles.orange}>Included</div>
            </div>
            <div id="feature-partialResponses" className={styles.row}>
              <div className={styles.label}>Partial responses</div>
              <div />
              <div />
              <div className={styles.orange}>Included</div>
            </div>
            <div id="feature-customDomain" className={styles.row}>
              <div className={styles.label}>Custom domains</div>
              <div />
              <div />
              <div className={styles.orange}>Included</div>
            </div>
            <div id="feature-smtp" className={styles.row}>
              <div className={styles.label}>SMTP settings</div>
              <div />
              <div />
              <div className={styles.orange}>Included</div>
            </div>
            <div id="feature-disableSave" className={styles.row}>
              <div className={styles.label}>Disable saving responses</div>
              <div />
              <div />
              <div className={styles.orange}>Included</div>
            </div>
            <div id="feature-customCode" className={styles.row}>
              <div className={styles.label}>Custom code</div>
              <div />
              <div />
              <div className={styles.orange}>Included</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
