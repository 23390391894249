import styles from './Radio.module.css';

import React, { forwardRef } from 'react';

export const Radio = forwardRef(({
  children, onClick, disabled, value, style, display
}, ref) => {
  const classList = [
    styles.radio,
    disabled ? styles.disabled : '',
    value ? styles.checked : '',
    disabled ? styles.disabled : '',
    !children ? styles.noText : '',
    styles[display || 'block'] // inline | block
  ];

  return <div ref={ref} className={classList.join(' ')} style={style} onClick={onClick}>
    <div className={styles.circle} />
    {children}
  </div>;
});

