import validator from 'validator';

const defaultOptions = {
  protocols: ['http', 'https'],
  require_tld: true,
  require_protocol: false,
  require_host: true,
  require_valid_protocol: true,
  allow_underscores: true,
  host_whitelist: false,
  host_blacklist: false,
  allow_trailing_dot: false,
  allow_protocol_relative_urls: false,
  disallow_auth: false
};

const validateUrl = (url, options) => {
  return validator.isURL(url, options ? { defaultOptions, ...options } : defaultOptions);
};

export default validateUrl;
