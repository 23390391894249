import io from 'io.js';

import { RequestError, BackendError } from './CustomErrors';

const withTimeout = (onResponse, onTimeout, TIMEOUT_MS) => {
  let called = false;

  const timer =
    TIMEOUT_MS !== Infinity
      ? setTimeout(() => {
          if (called) return;

          called = true;
          onTimeout();
        }, TIMEOUT_MS)
      : null;

  return (...args) => {
    if (called) return;

    called = true;
    clearTimeout(timer);
    onResponse(...args);
  };
};

export const ioEmitValidatedRequest = (event, params, requestConfig = {}) => {
  const { timeout = 10000 } = requestConfig;

  return new Promise((resolve, reject) => {
    if (window.QS?.verbose) {
      console.log(`%cREQUEST: ${event}`, 'color: #4685F1', params);
    }

    const handleResponse = (payload) => {
      if (window.QS?.verbose) {
        console.log(`%cRESPONSE: ${event}`, 'color: #0F9F6E', payload);
      }

      if (!payload || typeof payload !== 'object' || typeof payload.success !== 'boolean') {
        return reject(new BackendError('Frontend received unexpected data format.', `Event: ${event}`));
      }

      if (payload.success) {
        return resolve(payload.data ?? null);
      } else {
        return reject(
          new BackendError([
            'Request returned but did not succeed.',
            `Reason: ${payload.error || 'Unhandled backend error.'}`,
            `Event: ${event}`
          ])
        );
      }
    };

    const handleTimeout = () => {
      if (window.QS?.verbose) {
        console.log(`%cTIMEOUT: ${event}`, 'color: #F05252');
      }

      /*
      alert(
        'WARNING:\n\n' +
          "The Server didn't respond.\n" +
          'This is not an expected behavior.\n' +
          'Please check your internet connection and try again.\n\n' +
          `Event: ${event}.`
      );
      */

      return reject(new BackendError([`Action rejected due to ${timeout} ms timeout.`, `Event: ${event}`]));
    };

    io.socket.emit(event, params, withTimeout(handleResponse, handleTimeout, timeout));
  });
};
