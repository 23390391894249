import axios from 'axios';
import _remove from 'lodash/remove';

import config from 'config.js';

// Constants
const CLEAR = 'results/CLEAR';

const GET_SUBMISSIONS_REQUEST = 'results/GET_SUBMISSIONS_REQUEST';
const GET_SUBMISSIONS_SUCCESS = 'results/GET_SUBMISSIONS_SUCCESS';
const GET_SUBMISSIONS_FAILURE = 'results/GET_SUBMISSIONS_FAILURE';

const GET_SUMMARY_REQUEST = 'results/GET_SUMMARY_REQUEST';
const GET_SUMMARY_SUCCESS = 'results/GET_SUMMARY_SUCCESS';
const GET_SUMMARY_FAILURE = 'results/GET_SUMMARY_FAILURE';

const GET_FORM_FIELDS_REQUEST = 'results/GET_FORM_FIELDS_REQUEST';
const GET_FORM_FIELDS_SUCCESS = 'results/GET_FORM_FIELDS_SUCCESS';
const GET_FORM_FIELDS_FAILURE = 'results/GET_FORM_FIELDS_FAILURE';

const SHOW_EXPORT_MODAL = 'results/SHOW_EXPORT_MODAL';
const HIDE_EXPORT_MODAL = 'results/HIDE_EXPORT_MODAL';

const SHOW_DELETE_MODAL = 'results/SHOW_DELETE_MODAL';
const HIDE_DELETE_MODAL = 'results/HIDE_DELETE_MODAL';

const SELECT_ALL_COLUMNS = 'results/SELECT_ALL_COLUMNS';
const DESELECT_ALL_COLUMNS = 'results/DESELECT_ALL_COLUMNS';
const TOGGLE_COLUMN = 'results/TOGGLE_COLUMN';

const UPDATE_SUBMISSIONS_PAGINATION = 'results/UPDATE_SUBMISSIONS_PAGINATION';
const UPDATE_SHOW_SELECT_MAIN_COLUMN_ID = 'results/UPDATE_SHOW_SELECT_MAIN_COLUMN_ID';
const UPDATE_MAIN_COLUMN_ID = 'results/UPDATE_MAIN_COLUMN_ID';
const UPDATE_SELECTED_SUBMISSIONS = 'results/UPDATE_SELECTED_SUBMISSIONS';
const UPDATE_EXPORT_MODAL_DATA = 'results/UPDATE_EXPORT_MODAL_DATA';

const DELETE_SELECTED_SUBMISSIONS_REQUEST = 'results/DELETE_SELECTED_SUBMISSIONS_REQUEST';
const DELETE_SELECTED_SUBMISSIONS_SUCCESS = 'results/DELETE_SELECTED_SUBMISSIONS_SUCCESS';
const DELETE_SELECTED_SUBMISSIONS_FAILURE = 'results/DELETE_SELECTED_SUBMISSIONS_FAILURE';

const REQUEST_EXPORT_REQUEST = 'results/REQUEST_EXPORT_REQUEST';
const REQUEST_EXPORT_SUCCESS = 'results/REQUEST_EXPORT_SUCCESS';
const REQUEST_EXPORT_FAILURE = 'results/REQUEST_EXPORT_FAILURE';

const GET_FILE_URL_REQUEST = 'results/GET_FILE_URL_REQUEST';
const GET_FILE_URL_SUCCESS = 'results/GET_FILE_URL_SUCCESS';
const GET_FILE_URL_FAILURE = 'results/GET_FILE_URL_FAILURE';

const endpoitInitialStatus = {
  loading: false,
  error: false,
  success: false
};

// Initiual State
const initialState = {
  columns: [],
  submissions: [],
  summary: {},

  submissionsLimit: 20,
  submissionsSkip: 0,
  submissionsHasMore: true,
  submissionsTotal: null,

  selectedSubmissions: [],

  mainColumnId: null,
  showSelectMainColumnId: false,

  showExportModal: false,
  showExportLoading: false,
  showExportSuccess: false,
  exportModalData: {
    exportAs: 'csv',
    exportDeleted: false,
    structuralFields: false
  },

  showDeleteModal: false,

  columnOptions: {
    checkbox: {
      icon: require('assets/images/fields-v2/checkbox.svg')
    },
    dropdown: {
      icon: require('assets/images/fields-v2/dropdown.svg')
    },
    longText: {
      icon: require('assets/images/fields-v2/longText.svg')
    },
    radio: {
      icon: require('assets/images/fields-v2/radio.svg')
    },
    shortText: {
      icon: require('assets/images/fields-v2/shortText.svg')
    },
    datetime: {
      icon: require('assets/images/fields-v2/calendar.svg')
    },
    fileUpload: {
      icon: require('assets/images/fields-v2/upload.svg')
    },
    signature: {
      icon: require('assets/images/fields-v2/signature.svg')
    },
    scale: {
      icon: require('assets/images/fields-v2/scale.svg')
    },
    imageChoice: {
      icon: require('assets/images/fields-v2/imageChoice.svg')
    },
    title: {
      icon: require('assets/images/fields-v2/title.svg')
    },
    description: {
      icon: require('assets/images/fields-v2/description.svg')
    },
    section: {
      icon: require('assets/images/fields-v2/section.svg')
    },
    variable: {
      icon: require('assets/images/variable.svg')
    }
  },

  getSummary: { ...endpoitInitialStatus },
};

const getEndpointStatus = (type) => {
  const types = {
    request: {
      loading: true,
      error: false,
      success: false
    },
    success: {
      loading: false,
      error: false,
      success: true
    },
    failure: {
      loading: false,
      error: true,
      success: false
    },
  };

  return types[type];
};

const sortFields = (arr) => {
  return arr.sort((a, b) => {
    if (a.position < b.position) {
      return -1;
    }

    if (a.position > b.position) {
      return 1;
    }

    return 0;
  });
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  if (action.type === CLEAR) {
    state = { ...initialState };

    return { ...state };
  }

  // getSummary
  if (action.type === GET_SUMMARY_REQUEST) {
    state.getSummary = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === GET_SUMMARY_SUCCESS) {
    state.getSummary = getEndpointStatus('success');

    state.summary = action.payload;

    return { ...state };
  }

  if (action.type === GET_SUMMARY_FAILURE) {
    state.getSummary = getEndpointStatus('failure');

    return { ...state };
  }

  if (action.type === REQUEST_EXPORT_REQUEST) {
    state.showExportLoading = true;
    state.showExportSuccess = false;

    return { ...state };
  }

  if (action.type === REQUEST_EXPORT_SUCCESS) {
    state.showExportLoading = false;
    state.showExportSuccess = true;

    return { ...state };
  }

  if (action.type === REQUEST_EXPORT_FAILURE) {
    state.showExportLoading = false;
    state.showExportSuccess = false;

    return { ...state };
  }

  if (action.type === SHOW_DELETE_MODAL) {
    state.showDeleteModal = true;

    return { ...state };
  }

  if (action.type === HIDE_DELETE_MODAL) {
    state.showDeleteModal = false;

    return { ...state };
  }

  if (action.type === SELECT_ALL_COLUMNS) {
    state.columns = state.columns.map((column) => {
      column.selected = true;

      return column;
    });

    state.columns = [...state.columns];

    return { ...state };
  }

  if (action.type === DESELECT_ALL_COLUMNS) {
    state.columns = state.columns.map((column) => {
      column.selected = false;

      return column;
    });

    state.columns = [...state.columns];

    return { ...state };
  }

  if (action.type === TOGGLE_COLUMN) {
    const index = state.columns.findIndex((column) => column._id === action.payload.id);

    state.columns[index].selected = !state.columns[index].selected;

    state.columns = [...state.columns];

    return { ...state };
  }

  if (action.type === UPDATE_EXPORT_MODAL_DATA) {
    state.exportModalData = { ...state.exportModalData, ...action.payload.params };

    return { ...state };
  }

  if (action.type === SHOW_EXPORT_MODAL) {
    state.showExportModal = true;

    return { ...state };
  }

  if (action.type === HIDE_EXPORT_MODAL) {
    state.showExportModal = false;

    state.exportModalData.exportDeleted = false;
    state.exportModalData.exportAs = 'csv';
    state.exportModalData.structuralFields = false;
    state.showExportLoading = false;
    state.showExportSuccess = false;

    return { ...state };
  }

  if (action.type === UPDATE_SUBMISSIONS_PAGINATION) {
    state.submissionsSkip = action.payload.skip;

    return state;
  }

  if (action.type === UPDATE_SELECTED_SUBMISSIONS) {
    state.selectedSubmissions = action.payload.value;

    state.selectedSubmissions = [...state.selectedSubmissions];

    return { ...state };
  }

  if (action.type === UPDATE_SHOW_SELECT_MAIN_COLUMN_ID) {
    state.showSelectMainColumnId = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_MAIN_COLUMN_ID) {
    state.mainColumnId = action.payload.id;

    return { ...state };
  }

  if (action.type === GET_SUBMISSIONS_REQUEST) {

    return { ...state };
  }

  if (action.type === GET_SUBMISSIONS_SUCCESS) {
    if (action.payload.data.length === 0) {
      state.submissionsHasMore = false;
    } else if (action.payload.data.length !== state.submissionsLimit) {
      state.submissionsHasMore = false;
      state.submissions = state.submissions.concat(action.payload.data);
    } else {
      state.submissions = state.submissions.concat(action.payload.data);
    }

    state.submissionsTotal = action.payload.meta.total;

    return { ...state };
  }

  if (action.type === GET_SUBMISSIONS_FAILURE) {
    state.submissions = [];

    return { ...state };
  }

  if (action.type === GET_FORM_FIELDS_REQUEST) {
    return { ...state };
  }

  if (action.type === GET_FORM_FIELDS_SUCCESS) {
    state.columns = _remove(sortFields(action.payload), (field) => ['divider', 'pageBreak', 'image'].indexOf(field.type) === -1);

    return { ...state };
  }

  if (action.type === GET_FORM_FIELDS_FAILURE) {
    state.columns = [];

    return { ...state };
  }

  if (action.type === DELETE_SELECTED_SUBMISSIONS_REQUEST) {
    state.submissions = _remove(state.submissions, (submission) => state.selectedSubmissions.indexOf(submission._id) === -1);

    return { ...state };
  }

  if (action.type === DELETE_SELECTED_SUBMISSIONS_SUCCESS) {
    state.selectedSubmissions = [];

    if (action.payload.deleteAll) {
      state.submissions = [];
    }

    return { ...state };
  }

  if (action.type === DELETE_SELECTED_SUBMISSIONS_FAILURE) {
    state.selectedSubmissions = [];

    return { ...state };
  }

  if (action.type === GET_FILE_URL_SUCCESS) {
    window.open(action.payload.url, '_blank');
  }

  return state;
}

// Action Creators
export function clear() {
  return (dispatch) => {
    dispatch({ type: CLEAR });
  };
}

export function updateSubmissionsPagination(skip) {
  return (dispatch) => {
    dispatch({ type: UPDATE_SUBMISSIONS_PAGINATION, payload: { skip } });
  };
}

export function updateShowSelectMainColumnId(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_SHOW_SELECT_MAIN_COLUMN_ID, payload: { value } });
  };
}

export function updateMainColumnId(id) {
  return (dispatch) => {
    dispatch({ type: UPDATE_MAIN_COLUMN_ID, payload: { id } });
  };
}

export function updateSelectedSubmissions(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_SELECTED_SUBMISSIONS, payload: { value } });
  };
}

export function showDeleteModal() {
  return (dispatch) => {
    return dispatch({ type: SHOW_DELETE_MODAL });
  };
}

export function hideDeleteModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_DELETE_MODAL });
  };
}

export function showExportModal() {
  return (dispatch) => {
    return dispatch({ type: SHOW_EXPORT_MODAL });
  };
}

export function hideExportModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_EXPORT_MODAL });
  };
}

export function updateExportModalData(params) {
  return (dispatch) => {
    dispatch({ type: UPDATE_EXPORT_MODAL_DATA, payload: { params } });
  };
}

export function selectAllColumns() {
  return (dispatch) => {
    return dispatch({ type: SELECT_ALL_COLUMNS });
  };
}

export function deselectAllColumns() {
  return (dispatch) => {
    return dispatch({ type: DESELECT_ALL_COLUMNS });
  };
}

export function toggleColumn(id) {
  return (dispatch) => {
    return dispatch({ type: TOGGLE_COLUMN, payload: { id } });
  };
}

export function deleteSelectedSubmissions() {
  const request = () => { return { type: DELETE_SELECTED_SUBMISSIONS_REQUEST } };
  const success = (count, deleteAll) => { return { type: DELETE_SELECTED_SUBMISSIONS_SUCCESS, payload: { count, deleteAll } } };
  const failure = () => { return { type: DELETE_SELECTED_SUBMISSIONS_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const deleteAll = state.results.selectedSubmissions.length === 0;

    const response = await Promise.all([
      (deleteAll ? state.results.submissions : state.results.selectedSubmissions).map(async (item) => {
        const id = typeof item === 'object' ? item._id : item;

        return await services.deleteSubmission(state._users.token, state._users.user.permissions, state.builder.formId, id);
      })
    ]);

    if (response) {
      dispatch(success(state.results.selectedSubmissions.length, deleteAll));
    } else {
      dispatch(failure());
    }
  };
}

export function getSubmissions() {
  const request = () => { return { type: GET_SUBMISSIONS_REQUEST } };
  const success = (response) => { return { type: GET_SUBMISSIONS_SUCCESS, payload: response } };
  const failure = () => { return { type: GET_SUBMISSIONS_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getSubmissions(state._users.token, state._users.user.permissions, state.builder.formId, state.results.submissionsLimit, state.results.submissionsSkip);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function getFields() {
  const request = () => { return { type: GET_FORM_FIELDS_REQUEST } };
  const success = (form) => { return { type: GET_FORM_FIELDS_SUCCESS, payload: form } };
  const failure = () => { return { type: GET_FORM_FIELDS_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getFields(state._users.token, state._users.user.permissions, state.builder.formId);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}

export function requestExport() {
  const request = () => { return { type: REQUEST_EXPORT_REQUEST } };
  const success = (data) => { return { type: REQUEST_EXPORT_SUCCESS, payload: { data } } };
  const failure = () => { return { type: REQUEST_EXPORT_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());
    const state = getState();

    let selectedColumns = state.results.columns.filter((column) => column.selected).map((column) => column._id);

    if (selectedColumns.length === 0) {
      return dispatch(failure());
    }

    const response = await services.requestExport(state._users.token, state._users.user.permissions, state.builder.formId, state.builder.form.name, selectedColumns, state.results.selectedSubmissions, state.results.exportModalData.exportDeleted, state.results.exportModalData.exportAs, state.results.exportModalData.structuralFields);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function downloadFile(id) {
  const request = (id) => { return { type: GET_FILE_URL_REQUEST, payload: { id } } };
  const success = (url) => { return { type: GET_FILE_URL_SUCCESS, payload: { url } } };
  const failure = () => { return { type: GET_FILE_URL_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request(id));

    const state = getState();
    const response = await services.downloadFile(state._users.token, state._users.user.permissions, id);

    if (response) {
      return dispatch(success(response.url));
    } else {
      return dispatch(failure());
    }
  };
}

export function getSummary() {
  const request = () => { return { type: GET_SUMMARY_REQUEST } };
  const success = (data) => { return { type: GET_SUMMARY_SUCCESS, payload: data } };
  const failure = () => { return { type: GET_SUMMARY_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getSummary(state._users.token, state._users.user.permissions, state.builder.formId);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}

const services = {
  getSummary: async (token, permissions, formId) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/forms/${formId}/summary`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  downloadFile: async (token, permissions, id) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/files/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  getSubmissions: async (token, permissions, formId, limit, skip) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/forms/${formId}/submissions?limit=${limit}&skip=${skip}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  getFields: async (token, permissions, formId) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/forms/${formId}/fields`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteSubmission: async (token, permissions, formId, id) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${config.apiUrl}/forms/${formId}/submissions/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  requestExport: async (token, permissions, formId, formName, columns, submissions, deleted, type, structuralFields) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/forms/${formId}/submissions/export`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        },
        data: {
          formName, columns, submissions, deleted, type, structuralFields
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  }
};
