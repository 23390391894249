import axios from 'axios';
import _remove from 'lodash/remove';

import config from 'config.js';

// Constants
const GET_THEMES_REQUEST = '_themes/GET_THEMES_REQUEST';
const GET_THEMES_SUCCESS = '_themes/GET_THEMES_SUCCESS';
const GET_THEMES_FAILURE = '_themes/GET_THEMES_FAILURE';

const CREATE_THEME_REQUEST = '_themes/CREATE_THEME_REQUEST';
const CREATE_THEME_SUCCESS = '_themes/CREATE_THEME_SUCCESS';
const CREATE_THEME_FAILURE = '_themes/CREATE_THEME_FAILURE';

const DELETE_THEME_REQUEST = '_themes/DELETE_THEME_REQUEST';
const DELETE_THEME_SUCCESS = '_themes/DELETE_THEME_SUCCESS';
const DELETE_THEME_FAILURE = '_themes/DELETE_THEME_FAILURE';

const RESET_ENDPOINT_STATE = '_themes/RESET_ENDPOINT_STATE';

const UPDATE_THEME_SETTINGS = '_themes/UPDATE_THEME';
const UPDATE_THEME_COLORS = '_themes/UPDATE_THEME_COLORS';
const UPDATE_THEME_IMAGES = '_themes/UPDATE_THEME_IMAGES';
const UPDATE_THEME_NAME = '_themes/UPDATE_THEME_NAME';

const IO_UPDATE_THEME_SETTINGS_REQUEST = 'io/IO_UPDATE_THEME_SETTINGS_REQUEST';

const IO_UPDATE_THEME_COLORS_REQUEST = 'io/IO_UPDATE_THEME_COLORS_REQUEST';
const IO_UPDATE_THEME_COLORS_SUCCESS = 'io/IO_UPDATE_THEME_COLORS_SUCCESS';

const IO_UPDATE_THEME_IMAGES_REQUEST = 'io/IO_UPDATE_THEME_IMAGES_REQUEST';

const IO_UPDATE_THEME_NAME_REQUEST = 'io/IO_UPDATE_THEME_NAME_REQUEST';

const FILES_UPLOAD_REQUEST = '_files/FILES_UPLOAD_REQUEST';
const FILES_UPLOAD_SUCCESS = '_files/FILES_UPLOAD_SUCCESS';
const FILES_UPLOAD_PROGRESS = '_files/FILES_UPLOAD_PROGRESS';

const FILES_UPLOAD_CANCEL = '_files/FILES_UPLOAD_CANCEL';
const FILES_UPLOAD_FAILURE = '_files/FILES_UPLOAD_FAILURE';

const REMOVE_IMAGE_REQUEST = '_files/REMOVE_IMAGE_REQUEST';
const REMOVE_IMAGE_SUCCESS = '_files/REMOVE_IMAGE_SUCCESS';
const REMOVE_IMAGE_FAILURE = '_files/REMOVE_IMAGE_FAILURE';


const endpoitInitialStatus = {
  loading: false,
  error: false,
  success: false
};

// Initiual State
const initialState = {
  themes: {
    custom: [],
    stock: []
  },

  uploads: [],

  getThemes: { ...endpoitInitialStatus },
  createTheme: { ...endpoitInitialStatus },
  deleteTheme: { ...endpoitInitialStatus }
};

const getEndpointStatus = (type) => {
  const types = {
    request: {
      loading: true,
      error: false,
      success: false
    },
    success: {
      loading: false,
      error: false,
      success: true
    },
    failure: {
      loading: false,
      error: true,
      success: false
    },
  };

  return types[type];
};

// Reducer
export default function reducer(state = JSON.parse(JSON.stringify(initialState)), action = {}) {
  if (action.type === RESET_ENDPOINT_STATE) {
    state[action.payload.name] = JSON.parse(JSON.stringify(endpoitInitialStatus));

    return { ...state };
  }

  if (action.type === UPDATE_THEME_SETTINGS) {
    state.themes[action.payload.type].find((theme) => theme._id === action.payload.id).settings[action.payload.key] = action.payload.value;
    state.themes = { ...state.themes };

    return { ...state };
  }

  if (action.type === UPDATE_THEME_COLORS || action.type === IO_UPDATE_THEME_COLORS_SUCCESS) {
    state.themes[action.payload.type].find((theme) => theme._id === action.payload.id).colors = [...action.payload.colors];
    state.themes = { ...state.themes };

    return { ...state };
  }

  if (action.type === UPDATE_THEME_IMAGES) {
    state.themes[action.payload.type].find((theme) => theme._id === action.payload.id).images = { ...action.payload.images };
    state.themes = { ...state.themes };

    return { ...state };
  }

  if (action.type === UPDATE_THEME_NAME) {
    state.themes.custom.find((theme) => theme._id === action.payload.id).name = action.payload.name;
    state.themes = { ...state.themes };

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_REQUEST) {
    state.uploads = [...state.uploads, action.payload.file];

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_PROGRESS) {
    state.uploads.find((file) => file.ref === action.payload.ref).loaded = action.payload.percentage;

    state.uploads = [...state.uploads];

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_SUCCESS) {
    state.uploads.find((file) => file.ref === action.payload.ref).loaded = true;
    state.uploads.find((file) => file.ref === action.payload.ref).url = action.payload.url;
    state.uploads.find((file) => file.ref === action.payload.ref).cancel = undefined;

    state.uploads = [...state.uploads];

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_FAILURE) {
    state.uploads.find((file) => file.ref === action.payload.ref).cancel = undefined;

    state.uploads = [...state.uploads];

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_CANCEL) {
    state.uploads.find((file) => file.ref === action.payload.ref).cancel = action.payload.cancel;

    state.uploads = [...state.uploads];

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_CANCEL) {
    state.uploads.find((file) => file.ref === action.payload.ref).cancel();
    state.uploads.splice(state.uploads.findIndex((file) => file.ref === action.payload.ref), 1);

    state.uploads = [...state.uploads];

    return { ...state };
  }

  if (action.type === REMOVE_IMAGE_REQUEST) {
    const index = state.uploads.findIndex((file) => file.ref === action.payload.ref);

    if (index !== -1) {
      state.uploads.splice(index, 1);
      state.uploads = [...state.uploads];
    }

    return { ...state };
  }

  // getThemes
  if (action.type === GET_THEMES_REQUEST) {
    state.getThemes = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === GET_THEMES_SUCCESS) {
    state.getThemes = getEndpointStatus('success');

    state.themes = action.payload;
    state.themes.stock = [...action.payload.stock];
    state.themes.custom = [...action.payload.custom];

    return { ...state };
  }

  if (action.type === GET_THEMES_FAILURE) {
    state.getThemes = getEndpointStatus('failure');

    return { ...state };
  }

  // createTheme
  if (action.type === CREATE_THEME_REQUEST) {
    state.createTheme = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === CREATE_THEME_SUCCESS) {
    state.createTheme = {
      loading: true,
      error: false,
      success: true
    };

    return { ...state };
  }

  if (action.type === CREATE_THEME_FAILURE) {
    state.createTheme = getEndpointStatus('failure');

    return { ...state };
  }

  // deleteTheme
  if (action.type === DELETE_THEME_REQUEST) {
    state.deleteTheme = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === DELETE_THEME_SUCCESS) {
    const index = state.themes.custom.findIndex((theme) => theme._id === action.payload.id);

    state.deleteTheme = getEndpointStatus('success');

    if (index !== -1) {
      state.themes.custom.splice(index, 1);
      state.themes.custom = [...state.themes.custom];
      state.themes = {...state.themes};
    }

    return { ...state };
  }

  if (action.type === DELETE_THEME_FAILURE) {
    state.deleteTheme = getEndpointStatus('failure');

    return { ...state };
  }

  return state;
}

// Action Creators
export function resetEndpoitState(name) {
  return (dispatch) => {
    dispatch({ type: RESET_ENDPOINT_STATE, payload: { name } });
  };
}

export function updateThemeSettings(type, id, key, value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_THEME_SETTINGS, payload: { type, id, key, value } });
  };
}

export function ioUpdateThemeSettings(type, id, key, value) {
  return (dispatch) => {
    dispatch({ type: IO_UPDATE_THEME_SETTINGS_REQUEST, payload: { type, id, key, value } });
  };
}

export function updateThemeColors(type, id, colors) {
  return (dispatch) => {
    dispatch({ type: UPDATE_THEME_COLORS, payload: { type, id, colors } });
  };
}

export function ioUpdateThemeColors(type, id, colors) {
  return (dispatch) => {
    dispatch({ type: IO_UPDATE_THEME_COLORS_REQUEST, payload: { type, id, colors } });
  };
}

export function updateThemeImages(type, id, images) {
  return (dispatch) => {
    dispatch({ type: UPDATE_THEME_IMAGES, payload: { type, id, images } });
  };
}

export function ioUpdateThemeImages(type, id, images) {
  return (dispatch) => {
    dispatch({ type: IO_UPDATE_THEME_IMAGES_REQUEST, payload: { type, id, images } });
  };
}

export function updateThemeName(id, name) {
  return (dispatch) => {
    dispatch({ type: UPDATE_THEME_NAME, payload: { id, name } });
  };
}

export function ioUpdateThemeName(id, name) {
  return (dispatch) => {
    dispatch({ type: IO_UPDATE_THEME_NAME_REQUEST, payload: { id, name } });
  };
}

export function getThemes() {
  const request = () => { return { type: GET_THEMES_REQUEST } };
  const success = (data) => { return { type: GET_THEMES_SUCCESS, payload: data } };
  const failure = () => { return { type: GET_THEMES_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getThemes(state._users.token);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}

export function createTheme({ name, baseTheme, baseThemeType }) {
  const request = () => { return { type: CREATE_THEME_REQUEST } };
  const success = (res) => { return { type: CREATE_THEME_SUCCESS, payload: res } };
  const failure = () => { return { type: CREATE_THEME_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.createTheme(state._users.token, state._users.user.permissions, name, baseTheme, baseThemeType);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}

export function deleteTheme(id) {
  const request = () => { return { type: DELETE_THEME_REQUEST } };
  const success = (id) => { return { type: DELETE_THEME_SUCCESS, payload: { id } } };
  const failure = () => { return { type: DELETE_THEME_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.deleteTheme(state._users.token, state._users.user.permissions, id);

    if (response) {
      return dispatch(success(id));
    } else {
      return dispatch(failure());
    }
  };
}

export function removeImage(formId, ref, url) {
  const request = (ref) => { return { type: REMOVE_IMAGE_REQUEST, payload: { ref } } };
  const success = () => { return { type: REMOVE_IMAGE_SUCCESS } };
  const failure = () => { return { type: REMOVE_IMAGE_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request(ref));

    const state = getState();
    const response = await services.removeImage(state._users.token, state._users.user.permissions, formId, url);

    if (response) {
      dispatch(success());
    } else {
      dispatch(failure());
    }
  };
}

export function uploadImage(themeType, themeId, ref, files) {
  const request = (file) => { return { type: FILES_UPLOAD_REQUEST, payload: { file } } };
  const success = (ref, url) => { return { type: FILES_UPLOAD_SUCCESS, payload: { ref, url } } };
  const progress = (ref, percentage) => { return { type: FILES_UPLOAD_PROGRESS, payload: { ref, percentage } } };
  const cancel = (ref, cancel) => { return { type: FILES_UPLOAD_CANCEL, payload: { ref, cancel } } };
  const failure = (ref) => { return { type: FILES_UPLOAD_FAILURE, payload: { ref } } };

  return async (dispatch, getState) => {
    const uploadProgress = (ref) => (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentage = Math.floor((loaded * 100) / total);

      dispatch(progress(ref, percentage));
    }

    const uploadSuccess = (ref, themeType, themeId, images) => (respose) => {
      dispatch(success(ref, respose.data.url));

      images.background.authorName = null;
      images.background.authorUrl = null;
      images.background.thumb = respose.data.url;
      images.background.type = 'upload';
      images.background.url = respose.data.url;

      dispatch(updateThemeImages(themeType, themeId, images));
      dispatch(ioUpdateThemeImages(themeType, themeId, images));
    }

    const uploadFailure = (ref) => (respose) => {
      dispatch(failure(ref, respose.data));
    }

    const state = getState();

    let newFile = {};
    let data;
    let source;

    for (let file of files) {
      data = new FormData();
      source = axios.CancelToken.source();

      newFile = {
        ref,
        size: file.size,
        loaded: 0,
        name: file.name,
        type: file.type
      };

      data.append(newFile.ref, file);

      dispatch(request(newFile));
      dispatch(cancel(newFile.ref, source.cancel));

      axios({
        method: 'POST',
        url: `${config.apiUrl}/themes/${themeId}/images`,
        data,
        cancelToken: source.token,
        onUploadProgress: uploadProgress(newFile.ref),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${state._users.token}`,
          'Permissions': state._users.user.permissions
        }
      }).then(uploadSuccess(newFile.ref, themeType, themeId, state._themes.themes[themeType].find((theme) => theme._id === themeId).images)).catch((thrown) => {
        if (!axios.isCancel(thrown)) {
          uploadFailure(newFile.ref);
        }
      });
    }
  };
}

const services = {
  getThemes: async (token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/themes`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  createTheme: async (token, permissions, name, baseTheme, baseThemeType) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/themes`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        },
        data: {
          name, baseTheme, baseThemeType
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  removeImage: async (token, permissions, themeId, url) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${config.apiUrl}/themes/${themeId}/images`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        },
        data: {
          url
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteTheme: async (token, permissions, id) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${config.apiUrl}/themes/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
};
