import fields from '../fields';

let FIELDS_BY_ID = null;

const getFieldConfig = (filedId) => {
  // Creates map on first usage
  if (!FIELDS_BY_ID) {
    const fieldMap = new Map();

    for (const field of fields) {
      fieldMap.set(field._id, field);
    }

    FIELDS_BY_ID = fieldMap;
  }

  return FIELDS_BY_ID.get(filedId);
};

export default getFieldConfig;
