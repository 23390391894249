import { QueryError, BackendError, ioEmitValidatedRequest, RequestBatcher } from '../../helpers';

import populateSubmissionsPropsMetadataWithIcons from './helpers/populateSubmissionsPropsMetadataWithIcons';
import getSubmissionsInterpretationsOptions from './helpers/getSubmissionsInterpretationsOptions';
import getSubmissionsInterpretations from './helpers/getSubmissionsInterpretations';
import { objectBy } from 'helpers';

/* ------------------- GETTING SUBMISSIONS PROPS METADATA ------------------- */

const getSubmissionsPropsMetadata = {
  keyFn: ({ formId }) => ['getSubmissionsPropsMetadata', formId],

  fn: async ({ queryKey }) => {
    const [, formId] = queryKey;

    if (!formId) {
      throw new QueryError('DEBUG: Missing formId parameter.');
    }

    // Fetching data
    const submissionsPropsMetadata = await ioEmitValidatedRequest('getSubmissionsPropsMetadata', {
      form: formId
    });

    // Pre-processing data that will be needed in various components
    populateSubmissionsPropsMetadataWithIcons(submissionsPropsMetadata);

    const submissionsInterpretations = getSubmissionsInterpretations(submissionsPropsMetadata);
    const submissionsInterpretationsByPath = objectBy(submissionsInterpretations, 'path');

    const submissionsInterpretationsOptions = getSubmissionsInterpretationsOptions(submissionsPropsMetadata);
    const submissionsNumericInterpretationsOptions = getSubmissionsInterpretationsOptions(submissionsPropsMetadata, {
      interpretationsFilter: (interpretation) => {
        return interpretation.type === 'number';
      }
    });

    return {
      // Raw data + icons
      submissionsPropsMetadata,

      // Processed data
      submissionsInterpretations,
      submissionsInterpretationsByPath,
      submissionsInterpretationsOptions,
      submissionsNumericInterpretationsOptions
    };
  }
};

/* --------------------------- GETTING CHART DATA --------------------------- */

const getSubmissionsChartDataRequestBatcher = new RequestBatcher({
  debounceTime: 250,
  // maxPendingRequests: 20,

  onAreRequestsCompatible: (previousParams, currentParams) => {
    const { formA } = previousParams;
    const { formB } = currentParams;

    return formA === formB;
  },
  onFlush: async (arrayOfParams) => {
    // FormId should be same everywhere at this point
    const formId = arrayOfParams[0].form;

    const arrayOfResults = await ioEmitValidatedRequest(
      'getSubmissionsChartsData',
      {
        form: formId,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        charts: arrayOfParams.map((params) => params.chart)
      },
      {
        timeout: 20000 // 20s
      }
    );

    return arrayOfResults;
  }
});

const getSubmissionsChartData = {
  keyFn: ({ formId, chart }) => {
    const { groupByPrimary, groupBySecondary, accumulator } = chart;

    const neededChartProps = {
      groupByPrimary,
      groupBySecondary,
      accumulator: { as: accumulator.as, of: accumulator.of }
    };

    return ['getSubmissionsChartData', formId, neededChartProps];
  },

  fn: async ({ queryKey }) => {
    const [, formId, chart] = queryKey;

    if (!formId) {
      throw new QueryError('Missing form id.');
    }
    if (!chart) {
      throw new QueryError('Missing chart data.');
    }

    const chartData = await getSubmissionsChartDataRequestBatcher.scheduleRequest({
      form: formId,
      chart: chart
    });

    if (typeof chartData?.success !== 'boolean') {
      throw new BackendError('Chart data is missing boolean success property.');
    } else if (chartData.success !== true) {
      throw new BackendError(chartData.error || 'Unexpected backend error.');
    }

    delete chartData.success;

    return chartData;
  }
};

/* --------------------------------- EXPORTS -------------------------------- */

export default {
  getSubmissionsPropsMetadata,
  getSubmissionsChartData
};
