import styles from './DeleteDomainPanel.module.css';

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'io.js';

import { Icon, Panel, Button, Input, Label, Select, Hr, IconUpgrade, Avatar, Slider, FormRow } from 'ui-components';

const DeleteDomainPanel = ({ show, onClose, domain }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const cancel = () => {
    onClose();
    setLoading(false);
    setSuccess(false);
  };

  const confirm = () => {
    if (loading) return;

    setLoading(true);

    io.socket.once('deleteDomain', () => {
      setLoading(false);
      setSuccess(true);

      setTimeout(() => cancel(), 1000);
    });

    io.socket.emit('deleteDomain', { id: domain._id });
  };

  return <Panel mainStyle={{
    height: '90vh'
  }} coverStyle={{
    width: '80vw',
    height: '90vh',
    maxWidth: '950px',
    minWidth: '850px', left: '-200px'
  }}className={styles.main} title="Confirm custom domain delete" show={show} loading={loading} success={success} buttons={[
    <Button theme="white" onClick={() => !loading && cancel()}>Cancel</Button>,
    <Button theme="black" onClick={() => confirm()}>Delete domain</Button>
  ]} onOutsideClick={() => !loading && cancel()}>
    <div className={styles.content}>
      {domain && <p>Are you sure you want to delete <strong>{domain.hostname}</strong> domain?</p>}
    </div>
  </Panel>;
};

export default DeleteDomainPanel;
