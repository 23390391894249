import validateFormElementLogic from './validateFormElementLogic';

// Thank you pages are validated like fields (they only can't have calculations)
const validateFormThankYouPages = ({ thankYouPages, fields, calculationVariables }) => {
  if (!thankYouPages || !fields || !calculationVariables) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('DEBUG: missing data.');
    }
  }

  const errors = {};

  for (const thankYouPage of thankYouPages) {
    const error = validateFormElementLogic({ element: thankYouPage, fields, calculationVariables });
    if (error) {
      errors[thankYouPage._id] = error;
    }
  }

  return Object.keys(errors).length ? errors : false;
};

export default validateFormThankYouPages;
