import React, { useCallback, createContext, useContext, useMemo } from 'react';

import _isEqual from 'lodash/isEqual';
import { usePrevious } from 'helpers';

const QueryParamContext = createContext(null);

/*
  Can be used to pass things like formId / workspaceId to queries
  in a part of UI.

  NOTE: Initially was created with @fluentui/react-context-selector'
  but sometimes there was a propagation delay which is not acceptable.
  This package can be used once it fixes 'stale props' issue.
*/

/* --------------------------------- SETTER --------------------------------- */

export const QueryParamProvider = React.memo(({ children, ...params }) => {
  const upperContext = useContext(QueryParamContext) ?? null;

  const context = { ...upperContext, ...params };
  const previousContext = usePrevious(context);
  const deepMemoizedContext = _isEqual(context, previousContext) ? previousContext : context;

  return <QueryParamContext.Provider value={deepMemoizedContext}>{children}</QueryParamContext.Provider>;
});

/* --------------------------------- GETTER --------------------------------- */

export const useQueryParam = (key, fallbackValue) => {
  const contextPropSelector = (context) => {
    if (!context) {
      return undefined;
    } else {
      return context[key];
    }
  };

  const context = useContext(QueryParamContext);

  return contextPropSelector(context) ?? fallbackValue;
};
