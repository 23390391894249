import axios from 'axios';
import _remove from 'lodash/remove';

import config from 'config.js';

const sortFields = (arr) => {
  return arr.sort((a, b) => {
    if (a.position < b.position) {
      return -1;
    }

    if (a.position > b.position) {
      return 1;
    }

    return 0;
  });
};

// Constants
const GET_FORM_REQUEST = '_forms/GET_FORM_REQUEST';
const GET_FORM_SUCCESS = '_forms/GET_FORM_SUCCESS';
const GET_FORM_FAILURE = '_forms/GET_FORM_FAILURE';

const GET_FORMS_REQUEST = '_forms/GET_FORMS_REQUEST';
const GET_FORMS_SUCCESS = '_forms/GET_FORMS_SUCCESS';
const GET_FORMS_FAILURE = '_forms/GET_FORMS_FAILURE';

const CREATE_FORM_REQUEST = '_forms/CREATE_FORM_REQUEST';
const CREATE_FORM_SUCCESS = '_forms/CREATE_FORM_SUCCESS';
const CREATE_FORM_FAILURE = '_forms/CREATE_FORM_FAILURE';

const UPDATE_FORM_REQUEST = '_forms/UPDATE_FORM_REQUEST';
const UPDATE_FORM_SUCCESS = '_forms/UPDATE_FORM_SUCCESS';
const UPDATE_FORM_FAILURE = '_forms/UPDATE_FORM_FAILURE';

const DELETE_FORM_REQUEST = '_forms/DELETE_FORM_REQUEST';
const DELETE_FORM_SUCCESS = '_forms/DELETE_FORM_SUCCESS';
const DELETE_FORM_FAILURE = '_forms/DELETE_FORM_FAILURE';

const DUPLICATE_FORM_REQUEST = '_forms/DUPLICATE_FORM_REQUEST';
const DUPLICATE_FORM_SUCCESS = '_forms/DUPLICATE_FORM_SUCCESS';
const DUPLICATE_FORM_FAILURE = '_forms/DUPLICATE_FORM_FAILURE';

const CREATE_FORM_FROM_TEMPLATE_REQUEST = '_forms/CREATE_FORM_FROM_TEMPLATE_REQUEST';
const CREATE_FORM_FROM_TEMPLATE_SUCCESS = '_forms/CREATE_FORM_FROM_TEMPLATE_SUCCESS';
const CREATE_FORM_FROM_TEMPLATE_FAILURE = '_forms/CREATE_FORM_FROM_TEMPLATE_FAILURE';

const RESET_ENDPOINT_STATE = '_forms/RESET_ENDPOINT_STATE';

const endpoitInitialStatus = {
  loading: false,
  error: false,
  success: false
};

// Initiual State
const initialState = {
  form: {},
  forms: [],

  getForm: { ...endpoitInitialStatus },
  getForms: { ...endpoitInitialStatus },
  createForm: { ...endpoitInitialStatus },
  updateForm: { ...endpoitInitialStatus },
  deleteForm: { ...endpoitInitialStatus },
  duplicateForm: { ...endpoitInitialStatus },
  createFormFromTemplate: { ...endpoitInitialStatus }
};

const getEndpointStatus = (type) => {
  const types = {
    request: {
      loading: true,
      error: false,
      success: false
    },
    success: {
      loading: false,
      error: false,
      success: true
    },
    failure: {
      loading: false,
      error: true,
      success: false
    },
  };

  return types[type];
};

// Reducer
export default function reducer(state = JSON.parse(JSON.stringify(initialState)), action = {}) {
  if (action.type === RESET_ENDPOINT_STATE) {
    state[action.payload.name] = JSON.parse(JSON.stringify(endpoitInitialStatus));

    return { ...state };
  }

  // getForm
  if (action.type === GET_FORM_REQUEST) {
    state.getForm = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === GET_FORM_SUCCESS) {
    let page = 1;
    let form = { ...action.payload };

    state.getForm = getEndpointStatus('success');

    form.fields = sortFields(form.fields);

    for (let index in form.fields) {
      form.fields[index].page = page;

      if (form.fields[index].type === 'pageBreak') {
        page = page + 1;
      }
    }

    form.pages = page;

    state.form = { ...form };

    return { ...state };
  }

  if (action.type === GET_FORM_FAILURE) {
    state.getForm = getEndpointStatus('failure');

    return { ...state };
  }

  // getForms
  if (action.type === GET_FORMS_REQUEST) {
    state.getForms = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === GET_FORMS_SUCCESS) {
    state.getForms = getEndpointStatus('success');
    state.forms = [...action.payload];

    return { ...state };
  }

  if (action.type === GET_FORMS_FAILURE) {
    state.getForms = getEndpointStatus('failure');

    return { ...state };
  }

  // updateForm
  if (action.type === UPDATE_FORM_REQUEST) {
    state.updateForm = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === UPDATE_FORM_SUCCESS) {
    const index = state.forms.findIndex((form) => form._id === action.payload.data._id);

    state.updateForm = getEndpointStatus('success');
    state.forms[index] = { ...state.forms[index], ...action.payload.data };
    state.forms = [...state.forms];

    return { ...state };
  }

  if (action.type === UPDATE_FORM_FAILURE) {
    state.updateForm = getEndpointStatus('failure');

    return { ...state };
  }

  // createForm
  if (action.type === CREATE_FORM_REQUEST) {
    state.createForm = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === CREATE_FORM_SUCCESS) {
    state.createForm = getEndpointStatus('success');

    return { ...state };
  }

  if (action.type === CREATE_FORM_FAILURE) {
    state.createForm = getEndpointStatus('failure');

    return { ...state };
  }

  // createFormFromTemplate
  if (action.type === CREATE_FORM_FROM_TEMPLATE_REQUEST) {
    state.createFormFromTemplate = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === CREATE_FORM_FROM_TEMPLATE_SUCCESS) {
    state.createFormFromTemplate = getEndpointStatus('success');

    return { ...state };
  }

  if (action.type === CREATE_FORM_FROM_TEMPLATE_FAILURE) {
    state.createFormFromTemplate = getEndpointStatus('failure');

    return { ...state };
  }

  // deleteForm
  if (action.type === DELETE_FORM_REQUEST) {
    state.deleteForm = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === DELETE_FORM_SUCCESS) {
    state.deleteForm = getEndpointStatus('success');
    state.forms = _remove(state.forms, (form) => form._id !== action.payload.id);

    return { ...state };
  }

  if (action.type === DELETE_FORM_FAILURE) {
    state.deleteForm = getEndpointStatus('failure');

    return { ...state };
  }

  // duplicateForm
  if (action.type === DUPLICATE_FORM_REQUEST) {
    state.duplicateForm = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === DUPLICATE_FORM_SUCCESS) {
    // redirect

    return { ...state };
  }

  if (action.type === DUPLICATE_FORM_FAILURE) {
    state.duplicateForm = getEndpointStatus('failure');

    return { ...state };
  }

  return state;
}

// Action Creators
export function resetEndpoitState(name) {
  return (dispatch) => {
    dispatch({ type: RESET_ENDPOINT_STATE, payload: { name } });
  };
}

export function getForms() {
  const request = () => { return { type: GET_FORMS_REQUEST } };
  const success = (data) => { return { type: GET_FORMS_SUCCESS, payload: data } };
  const failure = () => { return { type: GET_FORMS_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getForms(state._users.token);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function getForm(id) {
  const request = () => { return { type: GET_FORM_REQUEST } };
  const success = (data) => { return { type: GET_FORM_SUCCESS, payload: data } };
  const failure = () => { return { type: GET_FORM_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getForm(state._users.token, id);

    if (response) {
      dispatch(success(response));

      return Promise.resolve(response);
    } else {
      dispatch(failure());
    }
  };
}

export function updateForm(id, data) {
  const request = () => { return { type: UPDATE_FORM_REQUEST } };
  const success = (data) => { return { type: UPDATE_FORM_SUCCESS, payload: { data } } };
  const failure = () => { return { type: UPDATE_FORM_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.updateForm(state._users.token, state._users.user.permissions, id, data);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}

export function createForm(data) {
  const request = () => { return { type: CREATE_FORM_REQUEST } };
  const success = (res) => { return { type: CREATE_FORM_SUCCESS, payload: res } };
  const failure = () => { return { type: CREATE_FORM_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.createForm(state._users.token, state._users.user.permissions, data);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}

export function createFormFromTemplate(id, data) {
  const request = () => { return { type: CREATE_FORM_FROM_TEMPLATE_REQUEST } };
  const success = (res) => { return { type: CREATE_FORM_FROM_TEMPLATE_SUCCESS, payload: res } };
  const failure = () => { return { type: CREATE_FORM_FROM_TEMPLATE_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.createFormFromTemplate(state._users.token, state._users.user.permissions, id, data);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}


export function deleteForm(id) {
  const request = () => { return { type: DELETE_FORM_REQUEST } };
  const success = (id, status) => { return { type: DELETE_FORM_SUCCESS, payload: { id, status } } };
  const failure = () => { return { type: DELETE_FORM_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request(id));

    const state = getState();
    const status = state._forms.forms.find((form) => form._id === id).status;
    const response = await services.deleteForm(state._users.token, state._users.user.permissions, id);

    if (response) {
      dispatch(success(id, status));
    } else {
      dispatch(failure());
    }
  };
}

export function duplicateForm(id) {
  const request = () => { return { type: DUPLICATE_FORM_REQUEST } };
  const success = () => { return { type: DUPLICATE_FORM_SUCCESS } };
  const failure = () => { return { type: DUPLICATE_FORM_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request(id));

    const state = getState();
    const response = await services.duplicateForm(state._users.token, state._users.user.permissions, id);

    if (response) {
      dispatch(success());

      return Promise.resolve(response._id);
    } else {
      dispatch(failure());

      return Promise.resolve(null);
    }
  };
}

const services = {
  getForm: async (token, id) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/forms/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  getForms: async (token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/forms`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  createForm: async (token, permissions, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/forms`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        },
        data
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  createFormFromTemplate: async (token, permissions, id, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/forms/${id}/template`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        },
        data
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateForm: async (token, permissions, id, data) => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${config.apiUrl}/forms/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        },
        data
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteForm: async (token, permissions, id) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${config.apiUrl}/forms/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  duplicateForm: async (token, permissions, id) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/forms/${id}/duplicate`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
};
