import styles from './OtpCreatePanel.module.css';

import React, { useState, useEffect, useContext } from 'react';
import io from 'io.js';
import QRCode from 'qrcode.react';
import ReactCodeInput from 'react-verification-code-input';

import { OtpInput, Panel, Button, Hr } from 'ui-components';

import { MainContext } from 'contexts/main.js';

import config from 'config.js';

const OtpCreatePanel = ({ show, onClose }) => {
  const { user, getAndUpdateUser } = useContext(MainContext);

  const [qrUrl, setQrUrl] = useState(null);
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const init = async () => {
    setLoading(true);

    io.socket.once('initUserOtp', (payload) => {
      setQrUrl(payload && payload.otpAuth ? payload.otpAuth : null);
      setLoading(false);
    });

    io.socket.emit('initUserOtp', {
      email: user.email
    });
  };

  const cancel = () => {
    onClose();
    setQrUrl(null);
    setError(null);
    setLoading(false);
    setSuccess(false);
  };

  const save = async (value) => {
    setError(null);
    setLoading(true);

    io.socket.once('enableUserOtp', (payload) => {
      getAndUpdateUser();
      setLoading(false);

      if (!payload.success) {
        setError(payload.message);
        return;
      }

      setSuccess(true);

      setTimeout(() => cancel(), 1000);
    });

    io.socket.emit('enableUserOtp', {
      code: value
    });
  };

  useEffect(() => {
    if (show) init();
  }, [show]);

  return (
    <Panel
      mainStyle={{
        height: '90vh'
      }}
      coverStyle={{
        width: '80vw',
        height: '90vh',
        maxWidth: '950px',
        minWidth: '850px',
        left: '-200px'
      }}
      className={styles.main}
      title="Enabling two-factor authentication"
      show={show}
      loading={loading}
      success={success}
      buttons={[
        <Button theme="white" onClick={() => cancel()}>
          Cancel
        </Button>,
        <Button theme="black" onClick={() => save(code)} disabled={loading || code.length !== 6}>
          Enable
        </Button>
      ]}
      onOutsideClick={() => !loading && cancel()}>
      <div className={[styles.content, config.fullStoryExcludeClass].join(' ')}>
        <QRCode value={qrUrl || ''} size={150} className={[styles.qrCode, qrUrl ? styles.visible : ''].join(' ')} />

        <p>
          <strong>Step one:</strong> Install the Google Authenticator, Authy or any other compatible 2FA app on your mobile device if you
          don’t already have one installed.
        </p>
        <p>
          <strong>Step two:</strong> Scan QR code with the mobile authenticator app.
        </p>

        <Hr />

        <div className={styles.digitsTitle}>Insert your 6-digit code here</div>
        <OtpInput style={{ margin: '10px auto 0 auto' }} onChange={(value) => setCode(value)} />
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </Panel>
  );
};

export default OtpCreatePanel;
