import styles from './JSONTreeModal.module.css';

import React from 'react';
import JSONTree from 'react-json-tree';

import { Modal } from '../Modal/Modal';
import ModalContent from '../ModalContent/ModalContent';
import { Button } from '../Button/Button';

const DEFAULT_THEME = {
  scheme: 'bright',
  author: 'chris kempson (http://chriskempson.com)',
  base00: '#000000',
  base01: '#303030',
  base02: '#505050',
  base03: '#b0b0b0',
  base04: '#d0d0d0',
  base05: '#e0e0e0',
  base06: '#f5f5f5',
  base07: '#ffffff',
  base08: '#fb0120',
  base09: '#fc6d24',
  base0A: '#fda331',
  base0B: '#a1c659',
  base0C: '#76c7b7',
  base0D: '#6fb3d2',
  base0E: '#d381c3',
  base0F: '#be643c'
};

const JSONTreeModal = ({
  // Modal props
  title,
  isOpen,
  onRequestClose,
  // Json tree props
  data,
  shouldExpandNode,
  hideRoot = true
}) => {
  return (
    <Modal loading={false} isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalContent
        header={title}
        buttons={
          <Button theme="black" onClick={onRequestClose}>
            Close
          </Button>
        }>
        <div style={{ width: 600, maxWidth: '70vw', maxHeight: 400, overflowY: 'auto' }}>
          <JSONTree data={data} hideRoot={hideRoot} shouldExpandNode={shouldExpandNode} theme={DEFAULT_THEME} />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default JSONTreeModal;
