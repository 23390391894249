/* -------------------------------------------------------------------------- */
/*                                PROFILE ROUTE                               */
/* -------------------------------------------------------------------------- */

const extractProfileStatusFromActivity = (activity) => {
  const { urlSearchParams } = activity;
  if (urlSearchParams.get('modal') !== 'profile') {
    return null;
  }

  const profileTab = urlSearchParams.get('profileTab');

  switch (profileTab) {
    case 'profile':
      return { location: 'My Profile' };

    case 'usage':
      return { location: 'Plan Usage' };

    case 'members':
      return { location: 'Plan Members' };

    case 'apps':
      return { location: 'Connected Services' };

    case 'domains':
      return { location: 'Custom Domains' };

    case 'smtps':
      return { location: 'SMTP Settings' };

    case 'upgrade':
      return { location: 'Upgrade Plan' };

    case 'billing':
      return { location: 'Billing' };

    default:
      return null;
  }
};

/* -------------------------------------------------------------------------- */
/*                                 FORM ROUTE                                 */
/* -------------------------------------------------------------------------- */

// Expected url: /form/:id/:page/:tab
const extractBuilderStatusFromActivity = (activity) => {
  /* -------------------- PREPARING DATA AND EARLY RETURNS -------------------- */

  const { urlParts, povUrlParts, urlSearchParams, form, submissionWorkspace } = activity;

  // Checks if activity describes a vailid form route that user could access
  if (urlParts[0] !== 'form' || !form?._id || form?._id !== urlParts[1]) {
    return null;
  }

  const page = urlParts[2];
  const tab = urlParts[3];
  const pageAndTab = page + (tab ? '/' + tab : '');

  /* --------------------------- NOT DETAILED STATUS -------------------------- */

  // Provided POV doesn't require detailed response (pov form outside of form or from within different form)
  if (urlParts[0] !== povUrlParts[0] || urlParts[1] !== povUrlParts[1]) {
    return {
      location: page === 'results' ? 'Form Results' : 'Form Editor',
      activeElement: form.name || form._id
    };
  }

  /* ----------------------------- PUBLISHING FLOW ---------------------------- */

  // If publishing flow is open then user probably does something inside it
  if (urlSearchParams.get('modal') === 'publishingFlow') {
    const publishingFlowTab = urlSearchParams.get('publishingFlowTab');

    switch (publishingFlowTab) {
      case 'embed':
        return { location: 'Embed Maker' };

      case 'link':
        return { location: 'Link Settings' };

      case 'availability':
        return { location: 'Availability Settings' };

      case 'seo':
        return { location: 'SEO Settings' };

      case 'domain':
        return { location: 'Domain Settings' };

      default:
        return { location: 'Publishing' };
    }
  }

  /* --------------------------------- RESULTS -------------------------------- */

  // User is somewhere in 'results tab'
  if (page === 'results') {
    // NICE TO HAVE TODO: more detailed results status and urls
    return {
      location: 'Results',
      activeElement: submissionWorkspace?.name || submissionWorkspace?._id || urlParts[3] || null
    };
  }

  /* --------------------------------- CREATE --------------------------------- */

  // User is somewhere in 'create tab' or in a 404 status
  switch (pageAndTab) {
    case 'connect/integrations':
      return { location: 'Integrations' };

    case 'connect/webhooks':
      return { location: 'Webhooks' };

    case 'settings/general':
      return { location: 'Settings' };

    case 'settings/url':
      return { location: 'Default Url Parameters' };

    case 'settings/advanced':
      return { location: 'Advanced Settings' };

    case 'payments/method':
      return { location: 'Payment Method' };

    case 'payments/settings':
      return { location: 'Payment Settings' };

    case 'payments/taxes':
      return { location: 'Smart Taxes' };

    case 'messaging':
      return { location: 'Messaging' };

    case 'notifications':
      // NICE TO HAVE TODO: more detailed notifications status and urls
      return { location: 'Notifications' };

    case 'design':
      // NICE TO HAVE TODO: more detailed notifications status and urls
      return { location: 'Design' };

    case 'build': {
      const fieldName = activity?.field?.name || activity?.field?._id || null;

      // User is probably modifying a field
      switch (urlSearchParams.get('properties')) {
        case 'general':
          return { activeElement: fieldName, location: 'Element Properties' };

        case 'options':
          return { activeElement: fieldName, location: 'Element Options' };

        case 'logic':
          return { activeElement: fieldName, location: 'Element Logic' };

        case 'calculations':
          return { activeElement: fieldName, location: 'Element Calculations' };

        default:
          return { activeElement: fieldName, location: 'Element Navigator' };
      }
    }

    default:
      return { location: 'Form Editor' };
  }
};

/* -------------------------------------------------------------------------- */
/*                               WORKSPACE ROUTE                              */
/* -------------------------------------------------------------------------- */

const extractWorkspaceStatusFromActivity = (activity) => {
  const { urlParts, workspace } = activity;

  // Checks if activity describes a vailid workspace route that user could access
  if (urlParts[0] !== 'workspace' || !workspace?._id || workspace?._id !== urlParts[1]) {
    return null;
  }

  return {
    location: 'Workspaces',
    activeElement: workspace?.name || workspace?._id || null
  };
};

/* -------------------------------------------------------------------------- */
/*                                 THEMES ROUTE                               */
/* -------------------------------------------------------------------------- */

// Expected url: /themes/:themeType/:id/:page
const extractThemesStatusFromActivity = (activity) => {
  /* -------------------- PREPARING DATA AND EARLY RETURNS -------------------- */

  const { urlParts, theme, povUrlParts } = activity;

  // Checks if activity describes a vailid themes route
  if (urlParts[0] !== 'themes') {
    return null;
  }

  const themeType = urlParts[1];
  const page = urlParts[3];

  // User is in a theme picker panel
  if (!urlParts[1] || !urlParts[2]) {
    return {
      location: 'Themes'
    };
  }

  // Checks if activity describes a vailid theme route that user could access
  if (!theme?._id || theme?._id !== urlParts[2]) {
    return null;
  }

  /* --------------------------- NOT DETAILED STATUS -------------------------- */

  // Provided POV doesn't require detailed response (pov form outside of theme or from within different theme)
  if (urlParts[0] !== povUrlParts[0] || urlParts[1] !== povUrlParts[1]) {
    return {
      location: 'Theme Editor',
      activeElement: theme.name || theme._id
    };
  }

  /* ----------------------------- DETAILED STATUS ---------------------------- */

  switch (page) {
    case 'display-settings':
      return { location: 'Theme Display Settings' };

    case 'background':
      return { location: 'Theme Background' };

    case 'typography':
      return { location: 'Theme Typography' };

    case 'colors':
      return { location: 'Theme Predefined Colors' };

    case 'progress-bar':
      return { location: 'Theme Progress Bar' };

    case 'general-fields-settings':
      return { location: 'Theme General Fields Settings' };

    case 'input-fields-dropdowns':
      return { location: 'Theme Input Fields & Dropdowns' };

    case 'single-multiple-choice':
      return { location: 'Theme Single/Multiple Choice' };

    case 'image-choice':
      return { location: 'Theme Image Choice' };

    case 'file-upload':
      return { location: 'Theme File Upload' };

    case 'date-time':
      return { location: 'Theme Date-Time' };

    case 'signatures':
      return { location: 'Theme Signatures' };

    case 'titles':
      return { location: 'Theme Titles' };

    case 'descriptions':
      return { location: 'Theme Descriptions' };

    case 'scales':
      return { location: 'Theme Scales' };

    case 'dividers':
      return { location: 'Theme Dividers' };

    case 'images':
      return { location: 'Theme Images' };

    case 'buttons':
      return { location: 'Theme Buttons' };

    case 'welcome-screen':
      return { location: 'Theme Welcome Screen' };

    case 'thank-you-screen':
      return { location: 'Theme Thank You Screen' };

    default:
      return { location: 'Theme Editor' };
  }
};

/* -------------------------------------------------------------------------- */
/*                                 MAIN ROUTE                                 */
/* -------------------------------------------------------------------------- */

const extractStatusFromActivity = (rawActivity, povPathname = null) => {
  if (!rawActivity) return null;
  const { pathname = '', search = '', ...activityParams } = rawActivity;

  /* ----------------------------- PREPARING DATA ----------------------------- */

  const urlParts = pathname.split('/');
  const urlSearchParams = new URLSearchParams(search);
  urlParts.shift();

  const povUrlParts = (povPathname || pathname).split('/');
  povUrlParts.shift();

  const activity = { ...activityParams, urlParts, urlSearchParams, povUrlParts };

  /* --------------------------------- PARSING -------------------------------- */

  // Profile modal is open so user is probably primarily something inside it
  if (urlSearchParams.get('modal') === 'profile') {
    return extractProfileStatusFromActivity(activity);
  }

  // User is somewhere else in the application
  const mainRoute = urlParts[0];
  switch (mainRoute) {
    case 'form':
      return extractBuilderStatusFromActivity(activity);

    case 'workspace':
      return extractWorkspaceStatusFromActivity(activity);

    case 'themes':
      return extractThemesStatusFromActivity(activity);

    case 'onboarding':
      return { location: 'Onboarding' };

    case 'login':
      return { location: 'Login' };

    case 'otp':
      return { location: 'One Time Password' };

    case 'verify':
      return { location: 'Verification' };

    case 'signup':
      return { location: 'Signup' };

    case 'join':
      return { location: 'Join QuestionScout' };

    case 'forgot':
      return { location: 'Restore Password' };

    case 'unsubscribe':
      return { location: 'Unsubscribe' };

    default:
      return null;
  }
};

/* -------------------------------------------------------------------------- */
/*                               MAIN FUNCTIONS                               */
/* -------------------------------------------------------------------------- */

export const parseActivityIntoTitle = (rawActivity, povPathname) => {
  const status = extractStatusFromActivity(rawActivity, povPathname);

  if (!status?.location) {
    return 'QuestionScout';
  }

  return `${status.location} - QuestionScout`;
};

export const parseActivityIntoStatus = (rawActivity, povPathname) => {
  const status = extractStatusFromActivity(rawActivity, povPathname);

  if (!status) {
    return null;
  } else {
    return {
      ...status,
      pathname: rawActivity.pathname || '',
      search: rawActivity.search || ''
    };
  }
};
