import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { Icon } from 'ui-components/Icon/Icon.js';

import useLongPressEvents from './useLongPressEvents.js';
import styles from './useLongPress.module.css';

// Constants
const SPINNER_REFRESH_RATE = 100;
const DEFAULT_ON_CLICK = () => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Empty on click.');
  }
};

const useLongPress = ({ onClick = DEFAULT_ON_CLICK, icon, delay, tooltip, hint = 'Hold to confirm' }) => {
  // Tippy/popper instance
  const [tippy, setTippy] = useState(null);

  /* ------------------------ LONG PRESS FUNCTIONALITY ------------------------ */

  // To detect if onClick caused component unmount
  const isMounted = useRef(false);

  const timeouts = useRef({});
  const [showHint, setShowHint] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [transitionDuration, setTransitionDuration] = useState(0);
  const [percentage, setPercentage] = useState(0);

  // cleanup
  const restartLongPress = useCallback(() => {
    setTransitionDuration(0);
    setPercentage(0);
    setShowHint(false);
    setShowSuccess(false);

    timeouts.current.interval && clearInterval(timeouts.current.interval);
    timeouts.current.interval = null;

    timeouts.current.success && clearTimeout(timeouts.current.success);
    timeouts.current.success = null;

    timeouts.current.hint && clearTimeout(timeouts.current.hint);
    timeouts.current.hint = null;
  }, []);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      restartLongPress();
      isMounted.current = false;
    };
  }, [restartLongPress]);

  // Preparing long press callbacks
  const initializeLongPress = useCallback(() => {
    restartLongPress();

    const startTime = performance.now();

    timeouts.current.interval = setInterval(() => {
      const localPercentage = ((performance.now() - startTime) / delay) * 100;
      setTransitionDuration((SPINNER_REFRESH_RATE / 1000) * 0.8);

      if (localPercentage >= 100) {
        setPercentage(100);
      } else {
        setPercentage(localPercentage);
      }
    }, SPINNER_REFRESH_RATE);
  }, [delay, restartLongPress]);

  const finalizeLongPress = useCallback(
    (event) => {
      restartLongPress();

      // User waited
      if (event) {
        // Event can potentially cause unmount
        onClick(event);

        // If longPressed component still exists
        if (isMounted.current) {
          setShowSuccess(true);
          timeouts.current.success = setTimeout(() => {
            setShowSuccess(false);
            timeouts.current.success && clearTimeout(timeouts.current.success);
            timeouts.current.success = null;
          }, 1000);
        }
      }
      // Long press canceled
      else {
        setShowHint(true);
        timeouts.current.hint = setTimeout(() => {
          setShowHint(false);
          tippy && tippy.hide();
          timeouts.current.hint && clearTimeout(timeouts.current.hint);
          timeouts.current.hint = null;
        }, 2000);
      }
    },
    [tippy, onClick, restartLongPress]
  );

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const handleOnClick = useCallback(
    (e) => {
      // If long press is not required
      if (!delay) {
        onClick(e);
      }
    },
    [delay, onClick]
  );

  const handleLongPressStart = useCallback(() => {
    if (delay) {
      initializeLongPress();
    }
  }, [delay, initializeLongPress]);

  const handleLongPressCancel = useCallback(() => {
    if (delay) {
      finalizeLongPress(null);
    }
  }, [delay, finalizeLongPress]);

  const handleLongPressEnd = useCallback(
    (e) => {
      if (delay) {
        finalizeLongPress(e);
      }
    },
    [delay, finalizeLongPress]
  );

  const longPressEvents = useLongPressEvents(handleLongPressStart, handleLongPressEnd, handleLongPressCancel, {
    shouldPreventDefault: true,
    delay
  });

  /* ----------------------- LONG PRESS CONDITIONAL ICONS ---------------------- */

  // Conditional icons
  let iconJsx = null;
  let iconMode = 'icon';

  if (percentage > 0) {
    // When long press is in progress
    iconMode = 'spinner';
    iconJsx = (
      <CircularProgressbar
        value={percentage}
        strokeWidth={14}
        styles={buildStyles({
          pathColor: '#807E7C',
          trailColor: '#DDDBD7',
          pathTransitionDuration: transitionDuration
        })}
      />
    );
  } else if (showSuccess) {
    // For a moment after long press is done
    iconMode = 'check';
    iconJsx = <Icon id="save" color="inherit" />;
  } else {
    // Otherwise
    iconJsx = typeof icon === 'string' ? <Icon id={icon} /> : icon;
  }

  iconJsx = (icon || delay) && (
    <div className={styles.icon} data-icon={iconMode}>
      {iconJsx}
    </div>
  );

  /* ------------------------------ TOOLTIP PROPS ----------------------------- */

  const tooltipAttrs = useMemo(() => {
    const props = showHint
      ? {
          content: hint,
          disabled: false,
          trigger: 'click',
          delay: 0
        }
      : {
          content: tooltip || hint,
          disabled: !tooltip,
          trigger: 'mouseenter',
          delay: [500, 0]
        };

    props.onCreate = (instance) => setTippy(instance);
    return props;
  }, [showHint, tooltip, hint]);

  // Hook data
  return {
    // Icon indicating state of long press
    indicator: iconJsx,
    // Event handlers to add to input
    eventHandlers: {
      onClick: handleOnClick,
      ...(delay ? longPressEvents : null)
    },
    // Attributes to add to Tooltip instance
    tooltipAttrs
  };
};

export default useLongPress;
