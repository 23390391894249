import styles from './DeleteAccountPanel.module.css';

import React, { useState, useContext } from 'react';
import io from 'io.js';

import { OtpInput, Panel, Button, Input, Checkbox, FormRow } from 'ui-components';

import { MainContext } from 'contexts/main.js';

import config from 'config.js';
import { asyncSocketAckEmit } from 'helpers';

const DeleteAccountPanel = ({ show, onClose }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const { user, getAndUpdateUser } = useContext(MainContext);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [backendError, setBackendError] = useState(null);

  // Inputs
  const [confirm, setConfirm] = useState(false);
  const [password, setPassword] = useState('');
  const [otpCode, setOtpCode] = useState('');

  // Only if user is not on a subscription
  const deleteAllowed = !user.billing.plan || user.billing.plan === 'trial';

  const cancel = () => {
    onClose();
    setBackendError(null);
    setLoading(false);
    setSuccess(false);
    setPassword('');
    setConfirm(false);
  };

  /* --------------------------------- REDUCER -------------------------------- */

  const confirmDelete = async () => {
    setLoading(true);

    const { success, message } = await asyncSocketAckEmit(
      'deleteUser',
      { otpCode, password },
      {
        showToasts: false
      }
    );

    getAndUpdateUser();
    setLoading(false);

    if (!success) {
      setBackendError(message);
    } else {
      setSuccess(true);

      setTimeout(() => {
        window.localStorage.removeItem('token');
        window.location.reload();

        cancel();
      }, 1000);
    }
  };

  /* ----------------------------------- JSX ---------------------------------- */

  let inputsJsx = null;
  let deleteButtonJsx = null;

  // User either uses otp or a password
  if (user.otp.enabled || user.authType === 'local') {
    inputsJsx = (
      <>
        {user.authType === 'local' && (
          <FormRow label="Enter your current password" errorMessage={backendError}>
            <Input type="password" error={Boolean(backendError)} value={password} onChange={(value) => setPassword(value)} />
          </FormRow>
        )}
        {user.otp.enabled && (
          <FormRow label="Enter your authentication code" errorMessage={backendError}>
            <OtpInput error={Boolean(backendError)} onChange={(value) => setOtpCode(value)} />
          </FormRow>
        )}
      </>
    );

    deleteButtonJsx = (
      <Button
        size="small"
        theme="black"
        onClick={confirmDelete}
        disabled={(user.otp.enabled && otpCode.length !== 6) || (user.authType === 'local' && password.length < 6)}>
        Delete account
      </Button>
    );
  }

  // User only logs in with google/facebook
  else {
    inputsJsx = (
      <Checkbox value={confirm} style={{ width: '100%' }} onChange={() => {}} onClick={() => setConfirm(!confirm)}>
        I understand, delete my account anyway
      </Checkbox>
    );

    deleteButtonJsx = (
      <Button size="small" theme="black" onClick={confirmDelete} disabled={!confirm}>
        Delete account
      </Button>
    );
  }

  return (
    <Panel
      mainStyle={{
        height: '90vh'
      }}
      coverStyle={{
        width: '80vw',
        height: '90vh',
        maxWidth: '950px',
        minWidth: '850px',
        left: '-200px'
      }}
      className={styles.main}
      title="Delete account"
      show={show}
      loading={loading}
      success={success}
      buttons={
        deleteAllowed
          ? [
              <Button size="small" theme="white" onClick={cancel}>
                Cancel
              </Button>,
              deleteButtonJsx
            ]
          : []
      }
      onOutsideClick={() => !loading && cancel()}>
      <div className={[styles.content, config.fullStoryExcludeClass].join(' ')}>
        {deleteAllowed ? (
          <>
            <p>
              Please be aware that deleting your account will result in all of your forms, responses, and all other data associated with
              your account to be <strong>permanently deleted</strong>.
            </p>
            {inputsJsx}
          </>
        ) : (
          <p>You need to cancel your subscription first.</p>
        )}
      </div>
    </Panel>
  );
};

export default DeleteAccountPanel;
