import parseMention from './parseMention';

// Create react-select options from form.calculationVariables
const optionsFromCalculationVariables = (calculationVariables) => {
  return calculationVariables.map((variable) => {
    return { label: parseMention(variable.name), value: variable._id };
  });
};

export default optionsFromCalculationVariables;
