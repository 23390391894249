import styles from './MediaCard.module.css';

import React, { useState, useEffect, useCallback, useMemo } from 'react';

import {} from 'helpers';

const MediaCard = ({ className = '', image, imageAlt, title, description, buttons }) => {
  return (
    <div className={[className, styles.mediaCard].join(' ')}>
      {image && <div className={styles.image}>{<img src={image} alt={imageAlt || String(title || description)} />}</div>}
      {(title || description) && (
        <div className={styles.label}>
          {title && <div className={styles.title}>{title}</div>}
          {description && <div className={styles.description}>{description}</div>}
        </div>
      )}
      {buttons && <div className={styles.buttons}>{buttons}</div>}
    </div>
  );
};

export default MediaCard;
