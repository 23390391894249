import address from './prebuiltSections/address';

const sections = [
  {
    _id: 'address',
    name: 'Address',
    icon: require('assets/images/fields-v3/checkbox.svg'),
    example: address
  }
];

export default sections;
