import styles from './NestedPropertySelect.module.css';
import inputStyles from '../Input/Input.module.css';

import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from 'react';

import scrollIntoView from 'scroll-into-view';

import { Button } from '../Button/Button';
import SearchBox from '../SearchBox/SearchBox';
import NavigatorEntry from '../NavigatorEntry/NavigatorEntry';
import { Icon } from '../Icon/Icon';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import { Popover } from '../Tooltip/Tooltip';

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

const Context = React.createContext();

const EMPTY_ARRAY = [];

const INPUT_POPOVER_OFFSET = [0, 8];

const NAVIGATOR_ENTRY_DEFAULTS = {
  style: { cursor: 'pointer' },
  dimmedBackground: false
};

// Adds . at the end of string if it's not there
const normalizeIndex = (index) => {
  // Adds . at the end of string if it's not there
  const trimmedIndex = String(index || '').trim();
  return !trimmedIndex || trimmedIndex.slice(-1) === '.' ? trimmedIndex : trimmedIndex + '.';
};

// Prepares mapped and flattened data for rendering
const prepareEntriesData = (entriesData = []) => {
  // Extracts all element's children into one big array of elements

  const traverseAndFlattenEntriesData = (data, parentObj = null) => {
    const flatData = [];

    for (const entry of data) {
      flatData.push({
        ...entry,
        parent: parentObj ? parentObj.value : null,
        children: entry.children ? entry.children.map((child) => child.value) : []
      });

      if (entry.children) {
        flatData.push(...traverseAndFlattenEntriesData(entry.children, entry));
      }
    }

    return flatData;
  };

  // Grouping and validation
  const flattenedEntries = traverseAndFlattenEntriesData(entriesData);
  const entriesByValue = new Map();
  const entriesGroupedByParent = new Map();

  for (const entry of flattenedEntries) {
    const value = entry.value;
    const parent = entry.parent;

    if (!value) {
      throw new Error(`DEBUG: missing value property in: ${JSON.stringify(entry, 0, 2)}`);
    }
    if (entriesByValue.has(value)) {
      throw new Error(`DEBUG: every value should be unique but '${value}' is not in: ${JSON.stringify(flattenedEntries, 0, 2)}`);
    }

    entriesByValue.set(value, entry);

    if (!entriesGroupedByParent.has(parent)) {
      entriesGroupedByParent.set(parent, []);
    }
    entriesGroupedByParent.get(parent).push(entry);
  }

  return {
    flattenedEntries,
    entriesByValue,
    entriesGroupedByParent
  };
};

// Given entry value returns array with all ancestors data and it data
// or null if something went wrong (like if entry or parent doesn't exist though it should).
const getEntryWithAncestors = (entryValue, entriesByValue) => {
  const entries = [];

  do {
    const entry = entriesByValue.get(entryValue);
    if (!entry) {
      return null;
    } else {
      entries.push(entry);
      entryValue = entry.parent;
    }
  } while (entryValue !== null);

  return entries.reverse();
};

// Traverses entry with child value up and checks if there is entry with parent value directly above.
const isParentOfEntry = (parentValue, childValue, entriesByValue) => {
  do {
    const child = entriesByValue.get(childValue);
    if (!child) {
      return false;
    } else if (child.parent === parentValue) {
      return true;
    } else {
      childValue = child.parent;
    }
  } while (childValue);
};

/* -------------------------------------------------------------------------- */
/*                              CONTEXT PROVIDER                              */
/* -------------------------------------------------------------------------- */

const ContextProvider = React.memo(({ data = [], value = null, onChange = null, children = null }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const { entriesByValue, entriesGroupedByParent } = useMemo(() => {
    return prepareEntriesData(data);
  }, [data]);

  // Controlled state for active entry (highlighted)
  const [activeEntry, setActiveEntry] = useState(null);
  // Uncontrolled state for currently opened entries group
  const [activeEntryGroup, setActiveEntryGroup] = useState(null);

  useEffect(() => {
    setActiveEntry(value);

    const entry = entriesByValue.get(value);
    if (entry) {
      setActiveEntryGroup(entry.parent);
    }
  }, [value, entriesByValue]);

  // Detects if group doesn't exist
  useEffect(() => {
    if (activeEntryGroup && !entriesGroupedByParent.has(activeEntryGroup)) {
      setActiveEntryGroup(null);
    }
  }, [activeEntryGroup, entriesGroupedByParent]);

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const handleSelectEntry = useCallback(
    (entryValue) => {
      onChange(entryValue);
    },
    [onChange]
  );

  const handleRequestShowChildrenOfEntry = useCallback((entryValue) => {
    setActiveEntryGroup(entryValue);
  }, []);

  /* --------------------------------- CONTEXT -------------------------------- */

  const context = useMemo(() => {
    return {
      activeEntry,
      activeEntryGroup,
      entriesByValue,
      entriesGroupedByParent,
      handleSelectEntry,
      handleRequestShowChildrenOfEntry
    };
  }, [activeEntry, activeEntryGroup, entriesByValue, entriesGroupedByParent, handleSelectEntry, handleRequestShowChildrenOfEntry]);

  /* ----------------------------------- JSX ---------------------------------- */

  return <Context.Provider value={context}>{children}</Context.Provider>;
});

/* -------------------------------------------------------------------------- */
/*                            NAVIGATOR COMPONENTS                            */
/* -------------------------------------------------------------------------- */

const NavigatorItem = React.memo(({ active = false, data = null, onRequestShowChildrenOfEntry = null, onSelectEntry = null }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const { value, icon, index, label, children: childrenValues } = data;
  const normalizedIndex = normalizeIndex(index);

  // Scroll active elements in view
  const elementRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;
    if (active && element) {
      scrollIntoView(element, { time: 100 });
    }
  }, [active]);

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const handleClick = useCallback(() => {
    if (childrenValues.length) {
      onRequestShowChildrenOfEntry(value);
    } else {
      onSelectEntry(value);
    }
  }, [childrenValues.length, value, onRequestShowChildrenOfEntry, onSelectEntry]);

  /* ----------------------------------- JSX ---------------------------------- */

  let suffixJsx = null;

  const numberOfNestedProperties = childrenValues.length;

  let rightArrowText;
  if (numberOfNestedProperties === 0) {
    rightArrowText = null;
  } else if (numberOfNestedProperties === 1) {
    rightArrowText = `${numberOfNestedProperties} option`;
  } else {
    rightArrowText = `${numberOfNestedProperties} options`;
  }

  suffixJsx = <Button theme="transparent" icon="manage-arrow" iconPlacement="right" children={rightArrowText} />;

  return (
    <NavigatorEntry
      {...NAVIGATOR_ENTRY_DEFAULTS}
      // Content
      icon={icon}
      index={normalizedIndex}
      label={label}
      suffix={suffixJsx}
      // State and events
      active={active}
      onClick={handleClick}
      ref={elementRef}
    />
  );
});

const EntryBreadcrumbs = React.memo(({ entryValue = null, isInteractive = false, suffix = null }) => {
  const { entriesByValue, handleRequestShowChildrenOfEntry } = useContext(Context);

  const entryWithAncestors = getEntryWithAncestors(entryValue, entriesByValue);
  if (!entryWithAncestors) return null;

  const breadcrumbsData = entryWithAncestors.map((entry) => {
    const { icon, index, label, parent: parentValue } = entry;

    return {
      icon,
      index,
      label,
      onClick:
        isInteractive && handleRequestShowChildrenOfEntry
          ? (e) => {
              e.preventDefault();
              handleRequestShowChildrenOfEntry(parentValue);
            }
          : null
    };
  });

  return <Breadcrumbs className={styles.breadcrumbs} data={breadcrumbsData} suffix={suffix} />;
});

const Navigator = React.memo(({ className = '', ...rest }) => {
  const {
    // Data
    entriesByValue,
    entriesGroupedByParent,
    // State
    activeEntry,
    activeEntryGroup,
    // Handlers
    handleSelectEntry,
    handleRequestShowChildrenOfEntry
  } = useContext(Context);

  /* --------------------------------- HELPERS -------------------------------- */

  // Stringifies entry into string representation matched by search box
  const entryStringifier = useCallback((entry) => {
    return (entry.index || '') + (entry.label || '');
  }, []);

  /* -------------------------------- RENDERERS ------------------------------- */

  // Rendering result of search in search box
  const renderMatchedEntries = useCallback(
    (matchedEntries) => {
      if (!matchedEntries.length) return null;

      return matchedEntries.map((entry) => {
        return (
          <NavigatorItem
            key={entry.value}
            data={entry}
            active={activeEntry === entry.value || isParentOfEntry(entry.value, activeEntry, entriesByValue)}
            onSelectEntry={handleSelectEntry}
            onRequestShowChildrenOfEntry={handleRequestShowChildrenOfEntry}
          />
        );
      });
    },
    [activeEntry, entriesByValue, handleSelectEntry, handleRequestShowChildrenOfEntry]
  );

  const renderEntryChildrenGroup = useCallback(
    (entryValue) => {
      const entry = entriesByValue.get(entryValue);
      const children = entriesGroupedByParent.get(entryValue);

      if (!entry || !children) return null;

      return (
        <div className={styles.navigatorGroup}>
          <div className={styles.navigatorGroupHeader} onClick={() => handleRequestShowChildrenOfEntry(entry.parent)}>
            <div className={styles.goBack}>
              <Icon id="arrow-back" />
            </div>
            <EntryBreadcrumbs entryValue={entryValue} />
          </div>
          <div className={styles.navigatorGroupContent}>
            {children.map((child) => (
              <NavigatorItem
                key={child.value}
                data={child}
                active={activeEntry === child.value || isParentOfEntry(child.value, activeEntry, entriesByValue)}
                onSelectEntry={handleSelectEntry}
                onRequestShowChildrenOfEntry={handleRequestShowChildrenOfEntry}
              />
            ))}
          </div>
        </div>
      );
    },
    [activeEntry, entriesGroupedByParent, entriesByValue, handleSelectEntry, handleRequestShowChildrenOfEntry]
  );

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <div className={[className, styles.navigator].join(' ')} {...rest}>
      {activeEntryGroup === null ? (
        <SearchBox
          className={styles.searchBox}
          theme="block"
          inputPlaceholder="Search for available options"
          dataStringifier={entryStringifier}
          renderMatchedData={renderMatchedEntries}
          data={entriesGroupedByParent.get(null) || EMPTY_ARRAY}
        />
      ) : (
        renderEntryChildrenGroup(activeEntryGroup)
      )}
    </div>
  );
});

/* -------------------------------------------------------------------------- */
/*                              INPUT COMPONENTS                              */
/* -------------------------------------------------------------------------- */

const EntryPreview = React.memo(
  React.forwardRef(
    (
      {
        className = '',
        isClearable = false,
        isFocused = false,
        fullWidth = true,
        error = false,
        disabled = false,
        placeholder = 'Select property...',
        onRequestClear = null,
        ...rest
      },
      ref
    ) => {
      /* ---------------------------------- STATE --------------------------------- */

      const {
        // Data
        entriesByValue,
        // State
        activeEntry: activeEntryValue
      } = useContext(Context);

      const activeEntry = entriesByValue.get(activeEntryValue);
      const isLowestLevel = activeEntry && activeEntry.children.length === 0;

      const isDisabled = disabled;
      const isEmpty = !activeEntryValue;
      const isError = Boolean(error) || (activeEntryValue && (!activeEntry || !isLowestLevel));

      /* ----------------------------------- JSX ---------------------------------- */

      let suffixJsx = (
        <>
          {isClearable && !isEmpty && <Button icon="close" onClick={onRequestClear} />}
          <Icon id="dropdown-closed-thin" />
        </>
      );

      let contentJsx = null;

      if (isEmpty) {
        contentJsx = <NavigatorEntry.Content className={styles.placeholderText} label={placeholder} suffix={suffixJsx} />;
      } else if (isError) {
        contentJsx = <NavigatorEntry.Content className={styles.errorText} label="[Invalid value]" suffix={suffixJsx} />;
      } else {
        contentJsx = <EntryBreadcrumbs entryValue={activeEntryValue} suffix={suffixJsx} />;
      }

      return (
        <div
          className={[
            className,
            styles.input,
            inputStyles.inputWrapper,
            isError ? inputStyles.errorOutline : '',
            isDisabled ? inputStyles.disabled : '',
            isFocused ? inputStyles.focused : '',
            fullWidth ? inputStyles.fullWidth : ''
          ].join(' ')}
          {...rest}
          ref={ref}>
          {contentJsx}
        </div>
      );
    }
  )
);

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENTS                              */
/* -------------------------------------------------------------------------- */

const NestedPropertySelectInline = ({ className = '', options, value, onChange }) => {
  return (
    <ContextProvider data={options} value={value} onChange={onChange}>
      <Navigator className={className} />
    </ContextProvider>
  );
};

const NestedPropertySelect = ({ className = '', isClearable, options, disabled, value, onChange, fullWidth, error, placeholder }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const [isPickerOpen, setIsPickerOpen] = useState(false);

  useEffect(() => {
    if (disabled) {
      setIsPickerOpen(false);
    }
  }, [disabled]);

  const [inputNode, setInputNode] = useState(null);
  const [navigatorStyle, setNavigatorStyle] = useState(null);

  /* -------------------------------- REDUCERS -------------------------------- */

  const refreshNavigatorStyle = useCallback(() => {
    if (inputNode) {
      setNavigatorStyle({ width: inputNode.getBoundingClientRect().width + 'px' });
    } else {
      setNavigatorStyle(null);
    }
  }, [inputNode]);

  useEffect(() => {
    refreshNavigatorStyle();
  }, [refreshNavigatorStyle]);

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const handleRequestClose = useCallback(() => {
    setIsPickerOpen(false);
  }, []);

  const handleRequestOpen = useCallback(() => {
    if (!disabled) {
      refreshNavigatorStyle();
      setIsPickerOpen(true);
    }
  }, [refreshNavigatorStyle, disabled]);

  // Wraps onChange so that picker will be closed
  const handleChange = useCallback(
    (...args) => {
      handleRequestClose();
      onChange(...args);
    },
    [onChange, handleRequestClose]
  );

  const handleRequestClear = useCallback(() => {
    handleChange(null);
  }, [handleChange]);

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <ContextProvider data={options} value={value} onChange={handleChange}>
      <Popover
        visible={isPickerOpen}
        content={<Navigator style={navigatorStyle} />}
        arrow={false}
        backdrop={false}
        sticky={false}
        hideOnScroll={true}
        offset={INPUT_POPOVER_OFFSET}
        onRequestClose={handleRequestClose}>
        <EntryPreview
          className={className}
          isFocused={isPickerOpen}
          isClearable={isClearable}
          error={error}
          fullWidth={fullWidth}
          placeholder={placeholder}
          onClick={handleRequestOpen}
          onRequestClear={handleRequestClear}
          ref={setInputNode}
        />
      </Popover>
    </ContextProvider>
  );
};

NestedPropertySelect.Inline = NestedPropertySelectInline;

export default NestedPropertySelect;
