import styles from './Content.module.css';

import React, { useContext } from 'react';

import { ProfileContext } from 'contexts/profile.js';

import Profile from './Profile/Profile.js';
import Members from './Members/Members.js';
import Domains from './Domains/Domains.js';
import Apps from './Apps/Apps.js';
import Smtps from './Smtps/Smtps.js';
import Upgrade from './Upgrade/Upgrade.js';
import Billing from './Billing/Billing.js';
import Usage from './Usage/Usage.js';
import LifeTime from './LifeTime/LifeTime.js';
import ZapierKey from './ZapierKey/ZapierKey.js';
import Affiliate from './Affiliate/Affiliate.js';
import Logs from './Logs/Logs.js';

const Content = React.memo(() => {
  const { page } = useContext(ProfileContext);

  return (
    <div className={styles.main}>
      {page === 'profile' && <Profile />}
      {page === 'members' && <Members />}
      {page === 'domains' && <Domains />}
      {page === 'apps' && <Apps />}
      {page === 'smtps' && <Smtps />}
      {page === 'upgrade' && <Upgrade />}
      {page === 'billing' && <Billing />}
      {page === 'usage' && <Usage />}
      {page === 'ltd' && <LifeTime />}
      {page === 'zapier' && <ZapierKey />}
      {page === 'affiliate' && <Affiliate />}
      {page === 'logs' && <Logs />}
    </div>
  );
});

export default Content;
