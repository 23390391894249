import styles from './DateInput.module.css';
import inputStyles from '../Input/Input.module.css';

import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';

import { Input } from '../Input/Input';
import { Icon } from '../Icon/Icon';
import DatePicker from '../DatePicker/DatePicker';

import { Popover } from '../Tooltip/Tooltip';

const safelyParseDate = (date) => {
  if (!date) {
    return null;
  }

  try {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return null;
    } else {
      return parsedDate;
    }
  } catch {
    return null;
  }
};

const safelyFormatDate = (date) => {
  try {
    return format(new Date(date), 'dd LLL yyyy HH:mm OOOO');
  } catch (e) {
    return 'Invalid date';
  }
};

const DateInput = React.memo(({ className = '', showTimeSelect, style, value, disabled, error, onChange, placeholder }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const parsedValue = safelyParseDate(value);
  const isInvalid = value && !parsedValue;

  const handleChange = useCallback(
    (value) => {
      setIsPickerOpen(false);
      onChange(value);
    },
    [onChange]
  );

  useEffect(() => {
    if (disabled) {
      setIsPickerOpen(false);
    }
  }, [disabled]);

  return (
    <Popover
      visible={isPickerOpen}
      backdrop={false}
      arrow={false}
      offset={[0, 6]}
      onRequestClose={() => setIsPickerOpen(false)}
      content={<DatePicker showTimeSelect={showTimeSelect} value={parsedValue} onChange={handleChange} />}>
      <div
        className={[className, styles.dateInput, isPickerOpen ? styles.focused : ''].join(' ')}
        style={style}
        onClick={() => (disabled ? null : setIsPickerOpen(true))}>
        <Input
          className={[styles.input, isPickerOpen ? inputStyles.focused : '', isInvalid ? inputStyles.error : ''].join(' ')}
          readOnly
          value={parsedValue ? safelyFormatDate(parsedValue) : ''}
          after={<Icon className={styles.afterIcon} id="date" />}
          placeholder={placeholder}
          disabled={disabled}
          error={error}
        />
      </div>
    </Popover>
  );
});

export default DateInput;
