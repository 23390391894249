import React, { useContext, useEffect } from 'react';

import { Popover } from 'ui-components';

import { useHelpScout, useNolt } from 'integrations';

/* -------------------------------------------------------------------------- */
/*                                   CONTENT                                  */
/* -------------------------------------------------------------------------- */

const QSHelpPopoverContent = React.memo(() => {
  const { hide } = useContext(Popover.Context);

  const helpScoutAPI = useHelpScout();
  const noltAPI = useNolt();

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const handleKnowledgeBase = () => {
    hide();
  };
  const handleContactSupport = () => {
    hide();
    helpScoutAPI.openAsk();
  };
  const handleSubmitFeedback = () => {
    hide();
  };

  useEffect(
    () => {
      // Called only at mount
      noltAPI.initializeFeedbackButton('.nolt-button-feedback');
    },
    // eslint-disable-next-line
    []
  );

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <Popover.Group>
      <Popover.Button onClick={handleKnowledgeBase} href="https://support.questionscout.com/" target="_blank">
        Help center
      </Popover.Button>
      <Popover.Button onClick={handleContactSupport}>Contact support</Popover.Button>
      <Popover.Button className="nolt-button-feedback" onClick={handleSubmitFeedback}>
        Submit feedback
      </Popover.Button>
    </Popover.Group>
  );
});

/* -------------------------------------------------------------------------- */
/*                                   WRAPPER                                  */
/* -------------------------------------------------------------------------- */

// Popover wrapper without any visible component, can be used to add help to any button/icon
const QSHelpPopover = ({ children, ...rest }) => {
  return (
    <>
      <Popover backdrop={false} theme="white" placement="left-start" {...rest} content={<QSHelpPopoverContent />}>
        {children || <div>[Empty QSHelpPopover]</div>}
      </Popover>
    </>
  );
};

export default QSHelpPopover;
