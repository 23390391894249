export class RequestBatcher {
  constructor({
    // How long will wait for another similar request
    debounceTime = 250,
    // How many requests at most can be batched
    maxPendingRequests = Infinity,
    // Used for checking if passed request args are similar enough to previous args that
    // these two request can be batched into one request.
    onAreRequestsCompatible = null,
    // Gets array of batched request args and should return array of results
    onFlush = null
  }) {
    this._maxPendingRequests = maxPendingRequests;
    this._debounceTime = debounceTime;
    this._flushTimeout = null;
    this._onAreRequestsCompatible = onAreRequestsCompatible;
    this._onFlush = onFlush;
    this._pendingRequests = [];
  }

  _clearScheduledFlush() {
    if (this._flushTimeout) {
      clearTimeout(this._flushTimeout);
    }
    this._flushTimeout = null;
  }

  _scheduleFlush() {
    this._clearScheduledFlush();

    this._flushTimeout = setTimeout(() => {
      this._clearScheduledFlush();
      this.forceFlush();
    }, this._debounceTime);
  }

  forceFlush() {
    this._clearScheduledFlush();

    // scheduleRequests() needs this to be called synchronously.
    // array of requests is shallow copied and original array is cleared.
    const flushedRequests = [...this._pendingRequests];
    this._pendingRequests.length = 0;
    if (!flushedRequests.length) {
      return;
    }

    // This can be called later independently
    const onFlush = this._onFlush;
    const runBatch = async () => {
      const arrayOfParams = flushedRequests.map((request) => request.params);
      const arrayOfResolvers = flushedRequests.map((request) => request.resolve);
      const arrayOfRejectors = flushedRequests.map((request) => request.reject);

      try {
        const results = await onFlush(arrayOfParams);

        if (!Array.isArray(results) || results.length !== arrayOfParams.length) {
          throw new Error('DEBUG: onFlush should return array of size equal to size of array received as a parameter.');
        }

        // Resolves all requests that made this batch
        for (let i = 0; i < arrayOfResolvers.length; i++) {
          const resolve = arrayOfResolvers[i];
          const result = results[i];

          resolve(result);
        }
      } catch (e) {
        // Rejects all requests that made this batch
        for (const reject of arrayOfRejectors) {
          reject(e);
        }
      }
    };

    runBatch();
  }

  scheduleRequest(params) {
    this._clearScheduledFlush();

    const maxPendingRequests = this._maxPendingRequests;
    const pendingRequests = this._pendingRequests;
    const onAreRequestsCompatible = this._onAreRequestsCompatible;

    const currentRequestParams = params;

    let flushInRequired = true;

    if (pendingRequests.length >= maxPendingRequests) {
      flushInRequired = true;
    } else if (pendingRequests.length > 0) {
      const { params: previousRequestArgs } = pendingRequests[pendingRequests.length - 1];
      flushInRequired = !onAreRequestsCompatible(previousRequestArgs, currentRequestParams);
    } else {
      flushInRequired = false;
    }

    if (flushInRequired) {
      this.forceFlush();
    }

    return new Promise((resolve, reject) => {
      pendingRequests.push({ params: currentRequestParams, resolve, reject });
      this._scheduleFlush();
    });
  }
}
