import styles from './MediaGrid.module.css';

import React, { useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { sortBy as _sortBy, uniqBy as _uniqBy, throttle as _throttle } from 'lodash';

const MediaGridContext = React.createContext();

const createEqualWidthTemplateColumnsCSS = (numberOfColumns) => {
  return '1fr '.repeat(numberOfColumns).trim();
};

/* -------------------------------------------------------------------------- */
/*                                    HOOKS                                   */
/* -------------------------------------------------------------------------- */

const useItemContainerProps = ({
  // Usage mode 1:
  // Int - explicitly says how many columns element should cover
  colspan = null,

  // Usage mode 2:
  // Fraction - what fraction of columns element should cover (more responsive friendly)
  // Will pick closest column span.
  size = null,

  // Will be merged with prepared props
  className = '',
  style = null
}) => {
  /* ---------------------------------- STATE --------------------------------- */

  const context = useContext(MediaGridContext);

  const injectedItemStyle = {};

  if (context) {
    const { numberOfColumns } = context;

    let calculatedColumnSpan = null;

    if (colspan !== null) {
      calculatedColumnSpan = parseInt(colspan) || 0;
    } else if (size !== null) {
      calculatedColumnSpan = Math.round((parseFloat(size) || 0) * numberOfColumns);
    } else {
      calculatedColumnSpan = 1;
    }

    const clampedColumnSpan = Math.min(Math.max(calculatedColumnSpan, 1), numberOfColumns) || 1;

    injectedItemStyle.gridColumnStart = `${clampedColumnSpan} span`;
  }

  /* ----------------------------------- JSX ---------------------------------- */

  return {
    className: [className, styles.mediaGridItem].join(' '),
    style: {
      ...style,
      ...injectedItemStyle
    }
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ITEM                                    */
/* -------------------------------------------------------------------------- */

const MediaGridItem = React.memo(
  React.forwardRef(({ Component = 'div', ...props }, ref) => {
    const preparedProps = useItemContainerProps(props);

    return <Component {...props} {...preparedProps} ref={ref} />;
  })
);

/* -------------------------------------------------------------------------- */
/*                                  CONTAINER                                 */
/* -------------------------------------------------------------------------- */

const MediaGrid = React.memo(
  React.forwardRef(
    (
      {
        className = '',
        gridClassName = '',
        style = null,

        // Grid display props
        gap = '12px',
        padding = 0,
        columns = 4, // Either int or object with breakpoints
        maxColumnWidth = null, // If provided will limit individual columns widths which can result in side margin

        // Content
        children = null
      },
      ref
    ) => {
      /* ---------------------------------- STATE --------------------------------- */

      const sortedBreakpoints = useMemo(() => {
        const breakpoints = [];

        // Only one breakpoint
        if (typeof columns === 'number') {
          breakpoints.push({
            minWidth: 0,
            numberOfColumns: Math.max(columns, 1) || 1
          });
        }

        // Multiple breakpoints
        else {
          for (const [numberOfColumns, minWidth] of Object.entries(columns)) {
            breakpoints.push({
              minWidth: Math.max(minWidth, 0) || 0,
              numberOfColumns: Math.max(numberOfColumns, 1) || 1
            });
          }
        }

        return _uniqBy(_sortBy(breakpoints, 'minWidth'), 'numberOfColumns');
      }, [columns]);

      const getNumberOfColumnsAtWindowWidth = useCallback(
        (windowWidth) => {
          let nextNumberOfColumns = sortedBreakpoints[0];

          for (let i = 0; i < sortedBreakpoints.length; i++) {
            const breakpoint = sortedBreakpoints[i];

            if (breakpoint.minWidth <= windowWidth) {
              nextNumberOfColumns = breakpoint.numberOfColumns;
            } else {
              break;
            }
          }

          return nextNumberOfColumns;
        },
        [sortedBreakpoints]
      );

      const [numberOfColumns, setNumberOfColumns] = useState(() => getNumberOfColumnsAtWindowWidth(window.innerWidth));

      const context = useMemo(() => {
        return {
          numberOfColumns: numberOfColumns
        };
      }, [numberOfColumns]);

      const gridWrapperStyle = { ...style };

      /* ----------------------------- EVENT LISTENERS ---------------------------- */

      useEffect(() => {
        const handleWindowResize = _throttle(() => {
          const numberOfColumns = getNumberOfColumnsAtWindowWidth(window.innerWidth);
          setNumberOfColumns(numberOfColumns);
        }, 100);

        window.addEventListener('resize', handleWindowResize);
        return () => {
          handleWindowResize.flush();
          window.removeEventListener('resize', handleWindowResize);
        };
      }, [getNumberOfColumnsAtWindowWidth]);

      /* ----------------------------------- JSX ---------------------------------- */

      return (
        <MediaGridContext.Provider value={context}>
          <div className={[className, styles.mediaGridWrapper].join(' ')} style={gridWrapperStyle} ref={ref}>
            <div
              className={[gridClassName, styles.mediaGrid].join(' ')}
              style={{
                maxWidth: typeof maxColumnWidth === 'number' && !isNaN(maxColumnWidth) ? numberOfColumns * maxColumnWidth : undefined,
                gridTemplateColumns: createEqualWidthTemplateColumnsCSS(numberOfColumns),
                gap: gap,
                padding: padding
              }}>
              {children}
            </div>
          </div>
        </MediaGridContext.Provider>
      );
    }
  )
);

// Returns number of columns from nearest grid or null
const useNumberOfColumns = () => {
  return useContext(MediaGridContext)?.numberOfColumns || null;
};

MediaGrid.Item = MediaGridItem;
MediaGrid.useNumberOfColumns = useNumberOfColumns;
MediaGrid.useItemContainerProps = useItemContainerProps;

export default MediaGrid;
