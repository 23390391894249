import styles from './SubmissionFieldPreview.module.css';

import React, { useMemo } from 'react';

import config, { identifySubmissionField } from 'config.js';
import ContentPreview from './ContentPreview';

const SubmissionFieldPreview = ({
  // Submission field metadata
  submissionId,
  submissionIndex,
  columnId,
  type,
  format,
  // Submission value, metadata and additional value
  value,
  valueMetadata,
  additionalValue,
  // Props that affect content behavior
  theme = 'for-table',
  blurred,
  onFieldAction
}) => {
  /* ------------------ IDENTIFYING DATA FETCHED FROM BACKEND ------------------ */

  let metadata = null;

  if (type === 'n/a') {
    metadata = { rendersAs: 'n/a' };
  } else {
    metadata = identifySubmissionField({ columnId, type, format });
  }

  if (!metadata) {
    console.warn('Unable to identify submission field', { columnId, type, format });
  }

  /* ------------------------- PREPARING CONTENT PROPS ------------------------ */

  // Determines renderer type used in ContentPreview component
  const rendersAs = metadata?.rendersAs;

  // Other props that can affect various content renderers in various ways
  const contentProps = useMemo(() => {
    const contentProps = { ...metadata?.rendererProps };

    // Custom logic that needs to provided outside of main app config
    // as these things depend on other parts of UI.
    if (theme === 'for-table') {
      contentProps.onDoubleClick = () =>
        onFieldAction({
          type: 'show-submission',
          payload: {
            submissionId,
            columnId
          }
        });
    }

    return contentProps;
  }, [theme, submissionId, columnId, onFieldAction, metadata?.rendererProps]);

  /* ----------------------------------- JSX ---------------------------------- */

  if (!rendersAs) return null;
  else
    return (
      <ContentPreview
        className={[
          styles.submissionFieldPreview,
          blurred ? styles.blurred : '',
          styles[`theme-${theme}`],
          config.fullStoryExcludeClass
        ].join(' ')}
        theme={theme}
        type={rendersAs}
        value={value}
        valueMetadata={valueMetadata}
        additionalValue={additionalValue}
        {...contentProps}
      />
    );
};

export default SubmissionFieldPreview;
