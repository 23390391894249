import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

/*
    Example: 
    const [searchParams, setSearchParams] = useSearchParams({ page: 1, pageSize: 25 });
    searchParams.page
    setSearchParams({page: 1, pageSize: 25})
*/
const useSearchParams = (defaultValue, { appendToExisting = true } = {}) => {
  /* ---------------------------- SETTING LOCATION ---------------------------- */

  const history = useHistory();

  // Additional ref for history to make setURLSearchParams callback reference stable
  const historyRef = useRef(history);
  historyRef.current = history;

  // Callback that allows modification of url search params
  const setURLSearchParams = useCallback((searchParams, appendToExisting = true) => {
    const location = historyRef.current.location;

    const nextSearchParams = new URLSearchParams(appendToExisting ? location.search : '');

    for (const key in searchParams) {
      const value = searchParams[key];
      if (value === null || typeof value === 'undefined') {
        nextSearchParams.delete(key);
      } else {
        nextSearchParams.set(key, searchParams[key]);
      }
    }

    const nextSearchParamsString = nextSearchParams.toString();

    if (nextSearchParamsString) {
      historyRef.current.replace(location.pathname + '?' + nextSearchParamsString);
    } else {
      historyRef.current.replace(location.pathname);
    }
  }, []);

  // Initialization of search params with defaultValue
  useEffect(
    () => {
      if (defaultValue) {
        const location = historyRef.current.location;

        // Sets search params from default value that are no in url already
        const currentSearchParams = new URLSearchParams(location.search);
        const searchParamsToSet = { ...defaultValue };
        for (const key in searchParamsToSet) {
          if (currentSearchParams.has(key)) {
            delete searchParamsToSet[key];
          }
        }

        setURLSearchParams(searchParamsToSet, appendToExisting);
      }
    },
    // This effect should be called only once
    // eslint-disable-next-line
    []
  );

  const setState = useCallback(
    (searchParams) => setURLSearchParams(searchParams, appendToExisting),
    [setURLSearchParams, appendToExisting]
  );

  /* ---------------------------- GETTING LOCATION ---------------------------- */

  const location = useLocation();

  // Gets search params from url
  const state = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    const state = {};
    for (const [key, value] of searchParams.entries()) {
      state[key] = value;
    }

    return state;
  }, [location.search]);

  return [state, setState];
};

export default useSearchParams;
