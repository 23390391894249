import styles from './Optional.module.css';

import React, { useState } from 'react';
import { Icon, Hint } from 'ui-components';
import ObjectID from 'bson-objectid';

const Optional = React.forwardRef(
  ({ children, label, error, show, style, className, onAddClick, onRemoveClick, borderTop, borderBottom }, ref) => {
    const [hex] = useState(ObjectID().toHexString());

    let classList = [styles.optional, className, borderTop ? styles.borderTop : '', borderBottom ? styles.borderBottom : ''];

    const handleClick = () => {
      onAddClick();

      setTimeout(() => {
        const childrenNode = document.getElementById(hex);

        if (!childrenNode) return;

        const inputs = childrenNode.getElementsByTagName('INPUT');

        if (inputs.length > 0) inputs[0].focus();
      });
    };

    return (
      <div ref={ref} style={style} className={classList.join(' ')}>
        <div className={styles.optionalLabel}>
          <div onClick={!show ? handleClick : onRemoveClick} className={styles.optionalLabelTitle}>
            {label}
            {error && <Hint icon="warning" children={error} />}
            <Icon id="plus" className={[styles.icon, show ? styles.rotate : ''].join(' ')} />
          </div>
        </div>
        <div id={hex}>{show && <div className={styles.optionalChildren}>{children}</div>}</div>
      </div>
    );
  }
);

export default Optional;
