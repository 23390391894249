import styles from './ListPreview.module.css';

import React from 'react';
import { isNil as _isNil } from 'lodash';

import { Expandible } from 'ui-components';

import EmptyPreview from './EmptyPreview';

const parseValue = (data) => {
  if (typeof data === 'string') {
    return data;
  }
  // Expects {text, value}
  else if (typeof data === 'object' && typeof data.text === 'string') {
    return (
      <>
        <span>{data.text}</span>
        {data.value && data.value !== data.text ? <span style={{ opacity: 0.5 }}> / {String(data.value)}</span> : null}
      </>
    );
  } else {
    return String(data);
  }
};

const ListPreview = ({ theme, value = null, valueMetadata, additionalValue, limit, allowCollapsing, placeholder }) => {
  // TODO use options to color show options
  // console.log(valueMetadata);

  const values = [];

  if (Array.isArray(value)) {
    values.push(...value);
  } else if (!_isNil(value)) {
    values.push(value);
  }

  if (!_isNil(additionalValue)) {
    values.push(additionalValue);
  }

  if (!values.length) {
    return <EmptyPreview theme={theme} placeholder={placeholder} />;
  } else {
    return (
      <div className={[styles.listPreview, styles[`theme-${theme}`]].join(' ')}>
        <Expandible
          data={values}
          allowCollapsing={allowCollapsing}
          limit={limit}
          renderData={(data) => (
            <ul className={styles.list}>
              {data.map((value, i) => {
                return (
                  <li key={value + i} className={styles.listItem}>
                    {parseValue(value)}
                  </li>
                );
              })}
            </ul>
          )}
        />
      </div>
    );
  }
};

export default ListPreview;
