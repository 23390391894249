import styles from './Spinner.module.css';

import React from 'react';

export const Spinner = ({ size = 10, borderSize = 2, speed = '0.5s', style, className, color1, color2 }) => {
  return (
    <div
      className={[styles.main, className].join(' ')}
      style={{
        ...style,
        width: `${size}px`,
        height: `${size}px`,
        minWidth: `${size}px`,
        minHeight: `${size}px`,
        border: `${borderSize}px solid ${color1 || '#DDDBD7'}`,
        borderTop: `${borderSize}px solid ${color2 || 'rgb(128, 126, 124)'}`,
        animationDuration: speed
      }}
    />
  );
};
