import styles from './Toggle.module.css';
import './Toggle.scss';

import React from 'react';
import ReactToggle from 'react-toggle';

export const Toggle = ({ onChange, checked, disabled, readOnly, className }) => {
  return (
    <ReactToggle
      className={[styles.toggle, className, disabled ? styles.disabled : ''].join(' ')}
      checked={checked || false}
      icons={false}
      disabled={disabled}
      onChange={onChange}
      readOnly={readOnly} />
  );
};

export const Toggle2 = ({ onClick, value, disabled, children, className, style }) => {
  return (
    <div onClick={onClick} className={[styles.toggle2, className, disabled ? styles.disabled : ''].join(' ')} style={style}>
      <ReactToggle
        checked={value || false}
        icons={false}
        disabled={disabled}
        onClick={() => { }}
        onChange={() => { }}
        readOnly={true} />
      {children && <div className={styles.label}>{children}</div>}
    </div>
  );
};
