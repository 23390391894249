import styles from './SettingRow.module.css';
import React, { useState, useEffect } from 'react';

import { Icon } from '../Icon/Icon.js';
import Text from '../Text/Text.js';
import Tooltip from '../Tooltip/Tooltip.js';

// Setting that has a radio/toggle to the right, if toggle is set to true children content is shown
const SettingRow = ({
  // Styling
  className = '',
  style,
  theme = 'major', // major / minor
  compact = false,
  inputPlacement = 'left',
  inputMiddled = false,
  limitChildrenWidth,
  hiding = true,

  // Content
  title,
  afterTitle,
  description,
  children,
  error,
  tip,

  // Toggle/radio settings
  input,
  value,
  onChange,
  onBlur,
  disabled
}) => {
  // Changes sizes of gaps between elements
  const gaps = theme === 'major' && !compact ? 'big' : 'small';

  // Used as tooltip trigger
  const [headerRef, setHeaderRef] = useState(null);

  const isClickable = !disabled && input;
  const cursor = isClickable ? 'pointer' : 'inherit';

  const handleClick = () => {
    if (isClickable) onChange(!value);
  };

  // Tooltip props
  let tooltipIcon = null;
  let tooltipProps = null;
  if (error && error !== true) {
    tooltipIcon = 'warning';
    tooltipProps = {
      content: error,
      trigger: 'mouseenter'
    };
  } else if (tip) {
    tooltipIcon = 'information';
    tooltipProps = {
      content: tip,
      trigger: 'mouseenter'
    };
  }

  const inputJsx = input && (
    <div className={styles.toggle}>{React.cloneElement(input, { disabled, value, onClick: handleClick, onBlur })}</div>
  );
  const descriptionJsx = (
    <Text className={styles.description} theme="description" weight="regular">
      {description}
    </Text>
  );
  const titleJsx = (
    <div className={styles.title} weight="medium" ref={setHeaderRef}>
      <Text style={{ cursor }} onClick={handleClick} theme={error ? 'error' : theme === 'major' ? 'header' : 'description'} weight="medium">
        {title}
      </Text>
      {tooltipProps && (
        <Tooltip {...tooltipProps} triggerTarget={headerRef}>
          <Icon
            id={tooltipIcon}
            // Testing stuff
            animation="headShake"
          />
        </Tooltip>
      )}
      {afterTitle}
    </div>
  );

  return (
    <div
      style={style}
      className={[
        className,
        styles.setting,
        styles['gaps-' + gaps],
        styles['inputPlacement-' + inputPlacement],
        inputMiddled && description ? styles.inputMiddled : ''
      ].join(' ')}>
      {input && inputPlacement === 'left' && inputJsx}
      {titleJsx}
      {input && inputPlacement === 'right' && inputJsx}
      {description && theme === 'major' && descriptionJsx}
      {children && (
        <div
          style={limitChildrenWidth ? { maxWidth: limitChildrenWidth === true ? '250px' : limitChildrenWidth } : null}
          className={[styles.children, error ? styles.error : '', hiding === 'animated' ? styles.animated : ''].join(' ')}
          data-hidden={input && hiding && !value}>
          {children}
        </div>
      )}
      {description && theme === 'minor' && descriptionJsx}
    </div>
  );
};

export default SettingRow;
