import styles from './PaymentPreview.module.css';

import React from 'react';

import { PaymentStatus } from 'ui-components';

import EmptyPreview from './EmptyPreview';

const PaymentPreview = ({ theme, value = null, placeholder }) => {
  if (!value || !Object.keys(value)?.length) {
    return <EmptyPreview placeholder={placeholder} />;
  } else {
    const paymentProvider = value?.provider;
    const paymentData = { ...value };
    delete paymentData.provider;

    return (
      <div className={[styles.paymentPreview, styles[`theme-${theme}`]].join(' ')}>
        <PaymentStatus provider={paymentProvider} data={paymentData} compact={theme === 'for-table'} />
      </div>
    );
  }
};

export default PaymentPreview;
