import React, { useMemo, useContext } from 'react';

import { ThemeContext } from 'contexts';
import { useRouteChanger } from 'helpers';

/* -------------------------------------------------------------------------- */
/*                                  CALLBACK                                  */
/* -------------------------------------------------------------------------- */

const createThemesIntroductionSteps = (params) => {
  /* --------------------- PARAM VALIDATION AND CALLBACKS --------------------- */

  const { themeId, themeType, navigateInApp } = params;

  if (!themeId || !themeType || !navigateInApp) return null;

  const navigateInTheme = (route = '', urlParams = {}) => navigateInApp(`/themes/${themeType}/${themeId}${route}`, urlParams);
  const navigateToMainRoute = () => navigateInTheme();

  /* ---------------------------------- STEPS --------------------------------- */

  const PART_1_START = [
    {
      titleSize: 'large',
      onAfterChange: navigateToMainRoute,

      title: 'Theme editor introduction',
      content: (
        <>
          <p>
            Welcome to the <b>theme editor</b>!
          </p>
          <p>If you feel that none of our stock themes cuts it, here you can create a perfect custom theme yourself.</p>
          <small>🕐 You'll need approx. 3 minutes to finish this tutorial.</small>
        </>
      )
    },
    {
      target: '.intro-themes-controls',
      placement: 'right',
      onAfterChange: navigateToMainRoute,

      title: 'Properties panel',
      content: (
        <>
          <p>
            This is the most important part of the theme editor. You can set the value of every single customizable theme property form
            here.
          </p>
          <p>There are all kind of properties to play with. We'll take a closer look at them in just a moment.</p>
        </>
      )
    },
    {
      target: '.intro-themes-preview',
      placement: 'left',
      style: { maxWidth: 350 },
      onAfterChange: navigateToMainRoute,

      title: 'Theme preview',
      content: <p>This is a real-time preview of the currently opened theme. By default, it's applied to a basic pre-made example form.</p>
    },
    {
      target: '.intro-themes-preview-settings-form',
      placement: 'bottom',
      spotlightPadding: 6,
      onAfterChange: navigateToMainRoute,

      title: 'Theme preview - custom form',
      content: (
        <p>
          It's also possible to preview a different form within this theme preview. This dropdown allows you to pick any form that you've
          created in the past.
        </p>
      )
    },
    {
      target: '.intro-themes-preview-settings-size',
      placement: 'bottom',
      spotlightPadding: 8,
      onAfterChange: navigateToMainRoute,

      title: 'Theme preview - preview size',
      content: (
        <p>
          A lot of people use phones or tablets for some daily tasks, so it's a good practice to make sure that your forms/themes are
          responsive, and that they look and feel great on mobile devices. These buttons will help you with that. Click any of them to
          simulate the desired device type.
        </p>
      )
    }
  ];

  const PART_2_PROPERTIES = [
    {
      target: '.intro-themes-controls',
      placement: 'right',
      onAfterChange: navigateToMainRoute,

      title: 'Properties panel - categories',
      content: (
        <p>
          To make navigation easier, all properties were broken down into smaller groups, which then were organized into three main
          categories.
        </p>
      )
    },
    {
      target: '.intro-themes-general-settings',
      placement: 'right',
      spotlightPadding: 12,
      onAfterChange: navigateToMainRoute,

      title: 'General settings',
      content: (
        <>
          <p>This category is for properties that give a theme it's overall feel. That includes things like:</p>
          <ul>
            <li>color palette,</li>
            <li>spacing,</li>
            <li>fonts,</li>
            <li>backgrounds,</li>
            <li>form width on various devices,</li>
            <li>and more.</li>
          </ul>
        </>
      )
    },
    {
      target: '.intro-themes-predefined-colors-button',
      placement: 'right',
      spotlightPadding: 4,
      onAfterChange: navigateToMainRoute,

      title: 'Predefined colors',
      content: (
        <p>
          '<b>Predefined colors</b>' group is especially worth pointing out. It can be used to define a set of colors, that various elements
          can reference. When you decide to change that color, every element that uses it (e.g. a field border) will be updated
          automatically.
        </p>
      )
    },
    {
      target: '.intro-themes-fields-settings',
      placement: 'right',
      spotlightPadding: 12,
      onAfterChange: navigateToMainRoute,

      title: 'Fields settings',
      content: (
        <>
          <p>
            In this category you'll find properties that describe how individual fields should look like. This includes both the default
            look, and the look during user interactions (e.g. mouse hover).
          </p>
          <p>
            As you can see, almost every field is in separate group, so you can style in some fields some way, and other fields in the
            opposite way.
          </p>
        </>
      )
    },
    {
      target: '.intro-themes-pages-settings',
      placement: 'right',
      spotlightPadding: 12,
      onAfterChange: navigateToMainRoute,

      title: 'Pages settings',
      content: (
        <>
          <p>
            All good things come to an end, including your fancy new theme. The last category is where you'll find settings for both these
            states.
          </p>
        </>
      )
    }
  ];

  return [...PART_1_START, ...PART_2_PROPERTIES];
};

/* -------------------------------------------------------------------------- */
/*                                    HOOK                                    */
/* -------------------------------------------------------------------------- */

const useThemesIntroductionSteps = () => {
  const { replace: navigateInApp } = useRouteChanger();

  // Required data
  const { theme, themeType } = useContext(ThemeContext);
  const themeId = theme?._id;

  // Steps
  return useMemo(
    () =>
      createThemesIntroductionSteps({
        themeId,
        themeType,
        navigateInApp
      }),
    [themeId, themeType, navigateInApp]
  );
};

export default useThemesIntroductionSteps;
