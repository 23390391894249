import React, { useEffect, useCallback, useState, useContext, useRef } from 'react';
import { IntroTour } from 'ui-components';

import TutorialsContext from '../tutorialsContext';

const createTutorialComponent = ({ tutorialKey, autoPlay, onUseSteps }) => {
  /* -------------------------------------------------------------------------- */
  /*                               TUTORIAL CONFIG                              */
  /* -------------------------------------------------------------------------- */

  if (process.env.NODE_ENV === 'development') {
    if (!tutorialKey || typeof tutorialKey !== 'string') {
      throw new Error('DEBUG: Invalid tutorialKey');
    }
    if (typeof onUseSteps !== 'function') {
      throw new Error('DEBUG: Invalid onUseSteps');
    }
  }

  const useSteps = onUseSteps;

  /* -------------------------------------------------------------------------- */
  /*                               TUTORIAL PLAYER                              */
  /* -------------------------------------------------------------------------- */

  /*
    Component responsible for preparing steps and playing them.
    Rendering this component is equal to starting a tutorial.
  */

  const TutorialPlayer = React.memo(({ onCompleted, onSkipped, onProgress }) => {
    const steps = useSteps();

    const [isTourOpen, setIsPlaying] = useState(true);

    const handleRequestClose = useCallback(
      (status) => {
        setIsPlaying(false);

        if (status === 'completed') {
          onCompleted && onCompleted();
        } else {
          onSkipped && onSkipped();
        }
      },
      [onCompleted, onSkipped]
    );

    return <IntroTour isOpen={isTourOpen} loading={!steps} steps={steps} onRequestClose={handleRequestClose} onProgress={onProgress} />;
  });

  /* -------------------------------------------------------------------------- */
  /*                              TUTORIAL MANAGER                              */
  /* -------------------------------------------------------------------------- */

  /*
    Component responsible for detecting if tutorial should be played.
    Mounts/unmounts TutorialPlayer so that additional calculations in hooks
    are performed only once tutorial starts and so that after tutorial there is a cleanup.
  */

  const TutorialManager = React.memo(() => {
    /* ---------------------------------- STATE --------------------------------- */

    const {
      playedTutorials,
      userAllowsAutoPlay,
      preventTutorials,
      updateTutorialStatus,
      requestedTutorial,
      setRequestedTutorial,
      showTutorialSkipper,
      showTutorialFinisher
    } = useContext(TutorialsContext);

    const tutorialStatus = playedTutorials.find((t) => t.key === tutorialKey);
    const tutorialWasPlayed = Boolean(tutorialStatus);

    const currentCompletionRatio = Number(tutorialStatus?.completionRatio) || 0;

    const highestCompletionRatioRef = useRef(0);
    highestCompletionRatioRef.current = Math.max(highestCompletionRatioRef.current, currentCompletionRatio);

    /* ---------------------------- PLAYING TUTORIAL ---------------------------- */

    const [isPlaying, setIsPlaying] = useState(false);

    const shouldBePlayed =
      !preventTutorials &&
      Boolean(
        // Checks if tutorial should be played because it was manually requested
        tutorialKey === requestedTutorial ||
          // Checks if tutorial should be played because of autoplay feature
          (autoPlay && !tutorialWasPlayed && userAllowsAutoPlay)
      );

    useEffect(() => {
      if (isPlaying && preventTutorials) {
        setIsPlaying(false);
      }
    }, [isPlaying, preventTutorials]);

    useEffect(() => {
      if (shouldBePlayed) {
        // Clears a possible flag
        setRequestedTutorial(null);
        // Starts tutorial
        setIsPlaying(true);
      }
    }, [shouldBePlayed, setRequestedTutorial]);

    /* -------------------------------- REDUCERS -------------------------------- */

    const updateCompletionRatio = useCallback(
      (nextCompletionRatio = 0) => {
        nextCompletionRatio = Number(nextCompletionRatio) || 0;
        highestCompletionRatioRef.current = Math.max(highestCompletionRatioRef.current, nextCompletionRatio);

        updateTutorialStatus({
          tutorialKey,
          changes: {
            completionRatio: highestCompletionRatioRef.current
          }
        });
      },
      [updateTutorialStatus]
    );

    /* ----------------------------- EVENT HANDLERS ----------------------------- */

    const handleSkipped = useCallback(() => {
      setIsPlaying(false);
      showTutorialSkipper();
    }, [showTutorialSkipper]);

    const handleCompleted = useCallback(() => {
      setIsPlaying(false);
      updateCompletionRatio(1);
      showTutorialFinisher();
    }, [updateCompletionRatio, showTutorialFinisher]);

    const handleProgress = useCallback(
      (progress) => {
        updateCompletionRatio(progress.completionRatio);
      },
      [updateCompletionRatio]
    );

    /* ----------------------------------- JSX ---------------------------------- */

    return <>{isPlaying && <TutorialPlayer onCompleted={handleCompleted} onSkipped={handleSkipped} onProgress={handleProgress} />}</>;
  });

  return TutorialManager;
};

export default createTutorialComponent;
