import styles from './ErrorFallback.module.css';

import React from 'react';

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */

const ErrorFallback = ({
  // Style
  className = '',
  style = null,
  theme = 'block',
  positionAbsolute = false,
  // Content
  error = null
}) => {
  let errorMessageJsx = null;

  if (error instanceof Error) {
    let prefix = `${error.name}: `;
    if (prefix === 'Error: ') prefix = 'GuiError: ';

    errorMessageJsx = `${prefix}${error.message}`;
  } else {
    errorMessageJsx = String(error);
  }

  return (
    <div
      className={[className, styles.errorFallback, styles[`theme-${theme}`], positionAbsolute ? styles.positionAbsolute : ''].join(' ')}
      style={style}>
      {errorMessageJsx}
    </div>
  );
};

export default ErrorFallback;
