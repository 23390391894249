import styles from './FormRow.module.css';

import React from 'react';

import { Toggle } from 'ui-components';

export const FormRow = ({ label, required, description, children, hide, className, errorMessage, style, showToggle, toggleValue, onLabelClick }) => {
  return (
    <div style={style} className={[
      styles.formRow,
      className,
      hide ? styles.hide : ''
    ].join(' ')}>
      <label onClick={onLabelClick}>
        {showToggle && <Toggle checked={toggleValue} readOnly={true} className={styles.toggle} onChange={() => { }} />}
        {label}
        {required && <span className={styles.required}>*</span>}
      </label>
      {children}
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
      {description && <div className={styles.description}>{description}</div>}
    </div>
  );
};
