import styles from './CancelPlan.module.css';

import React, { useState, useEffect, useContext, useRef } from 'react';
import io from 'io.js';
import asyncSocketAckEmit from 'helpers/asyncSocketAckEmit.js';

import { Panel, Button, Input, Select, Header, FormRow } from 'ui-components';

import { MainContext } from 'contexts/main.js';

const CancelPlan = ({ show, onClose, onSuccess, selectedPlan }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const submit = async () => {
    setLoading(true);

    try {
      await asyncSocketAckEmit('deleteUserSubscription');
      setSuccess(true);

      window.location.replace('/');
    } catch (e) {
      setSuccess(false);
    }
  };

  return (
    <Panel
      mainStyle={{
        height: '90vh'
      }}
      coverStyle={{
        width: '80vw',
        height: '90vh',
        maxWidth: '950px',
        minWidth: '850px',
        left: '-200px'
      }}
      className={styles.main}
      title="Cancel Subscription"
      show={show}
      loading={loading}
      success={success}
      onOutsideClick={onClose}>
      <div className={styles.content}>
        <p>Will generate a final invoice that invoices for any un-invoiced metered usage and new/pending proration invoice items. </p>
        <br />
        <br />

        <Button theme="red" onClick={submit}>
          Yes, cancel my subscription
        </Button>
      </div>
    </Panel>
  );
};

export default CancelPlan;
