import createTutorialComponent from './components/createTutorialComponent';

import useBuilderIntroductionSteps from './steps/useBuilderIntroductionSteps';
import useDashboardIntroductionSteps from './steps/useDashboardIntroductionSteps';
import useResultsIntroductionSteps from './steps/useResultsIntroductionSteps';
import useThemesIntroductionSteps from './steps/useThemesIntroductionSteps';

import TutorialsContextProvider from './TutorialsContextProvider';
import PendingTutorialsBadge from './components/PendingTutorialsBadge';

import useTutorialsApi from './useTutorialsApi';

/* -------------------------- TUTORIALS COMPONENTS -------------------------- */

const DashboardIntroduction = createTutorialComponent({
  tutorialKey: 'dashboard-introduction',
  autoPlay: true,
  onUseSteps: useDashboardIntroductionSteps
});

const BuilderIntroduction = createTutorialComponent({
  tutorialKey: 'builder-introduction',
  autoPlay: true,
  onUseSteps: useBuilderIntroductionSteps
});

const ResultsIntroduction = createTutorialComponent({
  tutorialKey: 'results-introduction',
  autoPlay: true,
  onUseSteps: useResultsIntroductionSteps
});

const ThemesIntroduction = createTutorialComponent({
  tutorialKey: 'themes-introduction',
  autoPlay: true,
  onUseSteps: useThemesIntroductionSteps
});

/* --------------------------------- EXPORTS -------------------------------- */

export {
  // Helper components and hooks
  useTutorialsApi,
  TutorialsContextProvider,
  PendingTutorialsBadge,
  // Tutorials
  DashboardIntroduction,
  BuilderIntroduction,
  ResultsIntroduction,
  ThemesIntroduction
};
