import styles from './UpdateInvoiceInformation.module.css';

import React, { useState, useEffect, useContext, useMemo } from 'react';
import io from 'io.js';
import asyncSocketAckEmit from 'helpers/asyncSocketAckEmit.js';
import config from 'config.js';
import { getData } from 'country-list';

import { Panel, Button, Input, Select, Header, FormRow, Radio } from 'ui-components';

import { MainContext } from 'contexts/main.js';

const countries = getData().map((obj) => {
  return {
    label: obj.name.replace('Taiwan, Province of China', 'Taiwan'),
    value: obj.code
  };
});

const UpdateInvoiceInformation = ({
  show,
  onClose,
  onSuccess,
  title,
  submitText,
  selectedPlan,
  selectedUsersNumber,
  setSelectedUsersNumber,
  showCollaboratorsSelect
}) => {
  const { user } = useContext(MainContext);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [type, setType] = useState('individual');
  const [taxNumber, setTaxNumber] = useState('');
  const [name, setName] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [postal, setPostal] = useState('');
  const [city, setCity] = useState('');

  const [errors, setErrors] = useState({});

  useEffect(() => {
    setEmail(user.billing.email || user.email);
    setCountry(user.billing.address.country || user.country);

    if (user.billing.address.taxNumber) setTaxNumber(user.billing.address.taxNumber.replace(user.billing.address.country || user.country, ''));
    if (user.billing.address.type) setType(user.billing.address.type);
    if (user.billing.address.name) setName(user.billing.address.name);
    if (user.billing.address.address1) setAddress1(user.billing.address.address1);
    if (user.billing.address.address2) setAddress2(user.billing.address.address2);
    if (user.billing.address.postal) setPostal(user.billing.address.postal);
    if (user.billing.address.city) setCity(user.billing.address.city);
  }, []);

  const cancel = () => {
    setLoading(false);
    setSuccess(false);
    setErrors({});
    onClose();
  };

  const onChange = (name, func, value) => {
    let issues = { ...errors };

    delete issues[name];

    func(value);
    setErrors(issues);
  };

  const next = async () => {
    let issues = {};

    setLoading(true);
    setErrors({});

    if (!country) issues = { ...issues, country: 'Country is required to calculate tax' };
    if (!name) issues = { ...issues, name: 'Your full name or company name is required' };
    if (!email) issues = { ...issues, email: 'Billing email is required' };
    if (!address1 && type === 'business') issues = { ...issues, address1: 'Address is required' };
    if (!postal && type === 'business') issues = { ...issues, postal: 'Postal number is required' };
    if (!city && type === 'business') issues = { ...issues, city: 'City is required' };

    if (taxNumber && country && type === 'business') {
      const { success, data, message } = await asyncSocketAckEmit('validateVatEu', {
        country,
        taxNumber
      });

      if (success && !data.valid) {
        issues = { ...issues, taxNumber: 'Invalid VatEU number' };
      }

      if (!success) {
        issues = { ...issues, taxNumber: message };
      }
    }

    if (Object.keys(issues).length > 0) {
      setLoading(false);
      setErrors(issues);

      return;
    }

    io.socket.emit(
      'updateUserInvoiceInformations',
      {
        type,
        name,
        country,
        email,
        address1,
        address2,
        postal,
        city,
        taxNumber: taxNumber && country && type === 'business' ? `${country}${taxNumber}` : null
      },
      () => {
        setLoading(false);
        setSuccess(true);
        onSuccess();
      }
    );
  };

  const collaboratorsNumber = useMemo(() => {
    if (selectedPlan === 'starter') {
      return [0, 1, 2, 3, 4, 5].map((number) => ({ label: number === 0 ? 'Just me' : number, value: number }));
    } else if (selectedPlan === 'pro') {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100].map((number) => ({
        label: number === 0 ? 'Just me' : number,
        value: number
      }));
    }
  }, [selectedPlan]);

  return (
    <Panel
      mainStyle={{
        height: '90vh'
      }}
      coverStyle={{
        width: '80vw',
        height: '90vh',
        maxWidth: '950px',
        minWidth: '850px',
        left: '-200px'
      }}
      className={styles.main}
      title={title}
      show={show}
      loading={loading}
      success={success}
      buttons={[
        <Button theme="white" onClick={cancel}>
          Cancel
        </Button>,
        <Button theme="black" onClick={next}>
          {submitText}
        </Button>
      ]}
      onOutsideClick={cancel}>
      <div className={styles.content}>
        {showCollaboratorsSelect && (
          <>
            <Header className={styles.header} style={{ margin: '0 0 15px 0' }}>
              Collaborators
            </Header>

            <Select
              width="100%"
              options={collaboratorsNumber}
              value={selectedUsersNumber}
              onChange={(selected) => setSelectedUsersNumber(selected.value)}
              style={{ margin: '0 0 30px 0' }}
            />
          </>
        )}

        <Header className={styles.header} style={{ margin: '0 0 15px 0' }}>
          Your invoice information
        </Header>

        <div className={styles.description} style={{ margin: '0 0 15px 0' }}>
          This information is displayed on your invoices, and used to contact you incase there is something wrong with your billing.
        </div>

        <FormRow>
          <Radio value={type === 'business'} width={'100%'} onClick={() => onChange('type', setType, 'business')}>
            Business
          </Radio>
          <Radio value={type === 'individual'} width={'100%'} onClick={() => onChange('type', setType, 'individual')}>
            Individual
          </Radio>
        </FormRow>

        <FormRow label={type === 'business' ? 'Your company name' : 'Your full name'} required={true} errorMessage={errors.name || false}>
          <Input name="name" value={name} width={'100%'} onChange={(value) => onChange('name', setName, value)} error={errors.name} />
        </FormRow>

        <FormRow label="Billing email" required={true} errorMessage={errors.email || false}>
          <Input
            name="Billing email"
            type="email"
            value={email}
            width={'100%'}
            onChange={(value) => onChange('email', setEmail, value)}
            error={errors.email}
          />
        </FormRow>

        <FormRow label="Country of billing address" required={true} errorMessage={errors.country || false}>
          <Select
            width="100%"
            options={countries}
            filter={true}
            value={country}
            onChange={(selected) => onChange('country', setCountry, selected.value)}
            error={errors.country}
          />
        </FormRow>

        <FormRow label="Address line 1" required={type === 'business'}>
          <Input
            name="Address Line 1"
            value={address1}
            width={'100%'}
            onChange={(value) => onChange('address1', setAddress1, value)}
            error={errors.address1}
          />
        </FormRow>

        <FormRow label="Address line 2" required={false}>
          <Input name="Address Line 2" value={address2} width={'100%'} onChange={(value) => onChange('address2', setAddress2, value)} />
        </FormRow>

        <FormRow label="Postal code" required={type === 'business'}>
          <Input
            name="Postal code"
            value={postal}
            width={'100%'}
            onChange={(value) => onChange('postal', setPostal, value)}
            error={errors.postal}
          />
        </FormRow>

        <FormRow label="City" required={type === 'business'}>
          <Input name="City" value={city} width={'100%'} onChange={(value) => onChange('city', setCity, value)} error={errors.city} />
        </FormRow>

        {type === 'business' && config.euCountries.indexOf(country) !== -1 && (
          <FormRow
            label="Your EU VAT number"
            errorMessage={errors.taxNumber || false}
            description="Leave empty if your business is not registered to trade cross-border within the EU.">
            <Input
              name="Vat number"
              type="text"
              suffix={country}
              placeholder="1234567890"
              value={taxNumber}
              width={'100%'}
              onChange={(value) => onChange('taxNumber', setTaxNumber, value)}
              error={errors.taxNumber}
            />
          </FormRow>
        )}
      </div>
    </Panel>
  );
};

export default UpdateInvoiceInformation;
