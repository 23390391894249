import styles from './Beta.module.css';

import React from 'react';

const Beta = ({ className = '', style }) => {
  console.log(styles);
  return (
    <div className={[className, styles.beta].join(' ')} style={style}>
      BETA
    </div>
  );
};

export default Beta;
