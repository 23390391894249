import styles from './Colors.module.css';

import React, { forwardRef } from 'react';

export const DEFAULT_COLORS = [
  '#BFBFBF',
  '#8A8A8A',
  '#404040',
  '#E1B0B0',
  '#E57777',
  '#CF0000',
  '#C4DEFF',
  '#7CB6FF',
  '#0027FF',
  '#90A7BF',
  '#437DBA',
  '#313F8E',
  '#BCDA88',
  '#AAE04E',
  '#6FAF00',
  '#86CDBC',
  '#58CAAF',
  '#05C698',
  '#CCB0E1',
  '#A777E5',
  '#8D00CF',
  '#D6A7C3',
  '#E17BB9',
  '#C42384',
  '#BFA790',
  '#B1865C',
  '#895017',
  '#EED88B',
  '#F0D131',
  '#FFBA1A'
];

export const Colors = forwardRef(({ colors, value, onChange }, ref) => {
  return (
    <ul className={styles.main} ref={ref}>
      {(colors || DEFAULT_COLORS).map((hex) => (
        <li key={hex} className={value === hex ? styles.selected : ''} onClick={() => onChange(hex)}>
          <span style={{ background: hex }}></span>
        </li>
      ))}
    </ul>
  );
});
