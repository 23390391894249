import styles from './SettingFlow.module.css';

import React, { useState, useContext, useMemo, useEffect } from 'react';

import Flex from '../Flex/Flex';
import { Button } from '../Button/Button';
import SettingRow from '../SettingRow/SettingRow';

const SettingFlowContext = React.createContext(null);

/* -------------------------------------------------------------------------- */
/*                                  BASIC HR                                  */
/* -------------------------------------------------------------------------- */

const SettingFlowHr = ({ style }) => {
  return <hr className={styles.hr} style={style} />;
};

/* -------------------------------------------------------------------------- */
/*                       SETTING FLOW MANAGER COMPONENT                       */
/* -------------------------------------------------------------------------- */

const SettingFlowTabFooter = ({ children, style, ...rest }) => {
  return (
    <>
      <SettingFlowHr style={{ marginBottom: 0 }} />
      <Flex className={styles.footer} padding="9px 20px 9px 20px" style={{ margin: 0, ...style }} {...rest}>
        {children}
      </Flex>
    </>
  );
};

// Main setting flow component that manages setting tabs
const SettingFlow = React.forwardRef(
  (
    {
      // Switching tabs
      value = null,
      onChange,
      // Style
      className = '',
      style,
      childrenWithMargin = false,
      // Tabs
      children
    },
    ref
  ) => {
    // Context data
    const contextValue = useMemo(() => ({ onLinkClick: onChange }), [onChange]);

    /* ----------------------------------- JSX ---------------------------------- */

    // Finds child that will be displayed
    const activeChild = React.Children.toArray(children).find((child, i) => child.props.name === value);
    const { title, description, footer, parent, className: itemClassName = '', gap: itemGap, headerLine = true } = activeChild?.props || {};

    const headerJsx = useMemo(() => {
      if (title || description) {
        const hasParent = typeof parent !== 'undefined';
        return (
          <>
            <Flex
              className={styles.header}
              style={hasParent ? { cursor: 'pointer' } : undefined}
              onClick={hasParent ? () => onChange(parent) : null}>
              <Button
                className={styles.goBack}
                theme="transparent"
                icon="arrow-back"
                // Hides go back arrow instead of removing it completely so that headers
                // of tabs with "return to parent" arrow aren't higher than headers of tabs without that arrow
                style={
                  hasParent
                    ? null
                    : {
                        width: 0,
                        margin: 0,
                        visibility: 'hidden'
                      }
                }
              />
              <SettingRow title={<strong>{title}</strong>} description={description} />
            </Flex>
            {headerLine ? <SettingFlowHr /> : <div />}
          </>
        );
      } else {
        return null;
      }
    }, [title, description, parent, headerLine, onChange]);

    return (
      <SettingFlowContext.Provider value={contextValue}>
        <div className={className} style={style} ref={ref}>
          <Flex vertical className={[itemClassName, childrenWithMargin ? styles.childrenWithMargin : ''].join(' ')} gap={itemGap ?? 2}>
            {headerJsx}
            {activeChild}
            {footer && <SettingFlowTabFooter>{footer}</SettingFlowTabFooter>}
          </Flex>
        </div>
      </SettingFlowContext.Provider>
    );
  }
);

const SettingFlowLink = ({ to, onClick, theme = 'transparent', icon = 'manage-arrow', iconPlacement = 'right', ...rest }) => {
  const { onLinkClick } = useContext(SettingFlowContext);
  return (
    <Button
      theme={theme}
      icon={icon}
      iconPlacement={iconPlacement}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
        onLinkClick(to);
      }}
      {...rest}
    />
  );
};

/* -------------------------------------------------------------------------- */
/*                         SETTING FLOW TAB COMPONENT                         */
/* -------------------------------------------------------------------------- */

const SettingFlowTab = ({
  // Used by SettingFlow
  name,
  parent,
  title,
  description,
  children
}) => {
  return children;
};

// Binding related components for convince
SettingFlow.Context = SettingFlowContext;
SettingFlow.Tab = SettingFlowTab;
SettingFlow.TabFooter = SettingFlowTabFooter;
SettingFlow.Link = SettingFlowLink;
SettingFlow.Hr = SettingFlowHr;

export default SettingFlow;
