import styles from './QSUpsell.module.css';

import React, { useCallback, useContext, useState } from 'react';

import { Button, Icon, Modal } from 'ui-components';

import config from 'config.js';

import { MainContext } from 'contexts';

/* -------------------------------------------------------------------------- */
/*                                   CONFIG                                   */
/* -------------------------------------------------------------------------- */

const BILLING_PLANS = config.plans;
const FEATURES = config.features;

const getFeature = (featureId) => {
  if (process.env.NODE_ENV === 'development') {
    if (!FEATURES[featureId]) {
      throw new Error(`DEBUG: feature with id '${featureId}' is not defined in config.features.`);
    }
  }

  return FEATURES[featureId];
};

/* -------------------------------------------------------------------------- */
/*                                   BUTTON                                   */
/* -------------------------------------------------------------------------- */

const GetFeatureButton = React.memo(
  ({
    // Style
    style,
    // Content
    text,
    color,
    iconPlacement,
    onClick
  }) => {
    const icon = `upgrade-${color}`;
    if (text) {
      return (
        <Button
          style={style}
          icon={icon}
          iconPlacement={iconPlacement}
          theme={`transparent-${color}`}
          size="default-thin"
          onClick={onClick}>
          {text}
        </Button>
      );
    } else {
      return <Icon id={icon} style={{ cursor: 'pointer', ...style }} onClick={onClick} />;
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                               FEATURE UPSELL                               */
/* -------------------------------------------------------------------------- */

// Upsell component that can be used to promote a feature
const QSFeatureUpsell = ({ featureId, ...rest }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const { user, users, showUpgradeTableUpsell } = useContext(MainContext);

  const feature = getFeature(featureId);
  const requiredPlan = BILLING_PLANS[feature.requiredPlan];
  const userPlan = BILLING_PLANS[user.billing.plan];

  const holderUser = users.find((u) => u.role === 'holder');
  const holderName = holderUser.getNameOrEmail();

  /* ---------------------------------- STATE --------------------------------- */

  const [isUpsellModalOpen, setIsUpsellModalOpen] = useState(false);

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const isHolder = user?.role === 'holder';

  const handleClick = useCallback(() => {
    setIsUpsellModalOpen(true);
  }, []);

  const handleRequestClose = useCallback(() => {
    setIsUpsellModalOpen(false);
    if (isHolder) {
      showUpgradeTableUpsell(featureId);
    }
  }, [featureId, isHolder, showUpgradeTableUpsell]);

  if (!requiredPlan || !userPlan) return <></>;

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <>
      <Modal isOpen={isUpsellModalOpen} theme="tight" onRequestClose={handleRequestClose}>
        <div className={styles.upsellModal}>
          <div className={styles.featureName} style={{ backgroundColor: requiredPlan.colorHex }}>
            {feature.name}
          </div>

          <div className={styles.message}>
            <div>
              This feature requires a{' '}
              <span className={styles.planName} style={{ color: requiredPlan.colorHex }}>
                {requiredPlan.name}
              </span>{' '}
              plan or above.
            </div>
            {!isHolder ? (
              <div>Contact your account administrator ({holderName}) if you would like access to this feature.</div>
            ) : (
              <div>
                Currently you're on a{' '}
                <span className={styles.planName} style={{ color: userPlan.colorHex }}>
                  {userPlan.name}
                </span>{' '}
                plan.
              </div>
            )}{' '}
          </div>

          <div className={styles.buttons}>
            {isHolder ? (
              <Button icon="open-external" theme="black-underline" onClick={handleRequestClose}>
                Compare billing plans
              </Button>
            ) : (
              <Button theme="white" onClick={handleRequestClose}>
                Close
              </Button>
            )}
          </div>
        </div>
      </Modal>
      <GetFeatureButton text="Get feature" color={requiredPlan.color} onClick={handleClick} {...rest} />
    </>
  );
};

/* -------------------------------------------------------------------------- */
/*                                 PLAN UPSELL                                */
/* -------------------------------------------------------------------------- */

// Upsell component that can be used to promote a plan
const QSPlanUpsell = ({ planId, onClick, ...rest }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const { user, users, showUpgradeTableUpsell } = useContext(MainContext);

  const requiredPlan = BILLING_PLANS[planId];
  const userPlan = BILLING_PLANS[user.billing.plan];

  const holderUser = users.find((u) => u.role === 'holder');
  const holderName = holderUser.getNameOrEmail();

  /* ---------------------------------- STATE --------------------------------- */

  const [isUpsellModalOpen, setIsUpsellModalOpen] = useState(false);

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const isHolder = user?.role === 'holder';

  const handleClick = useCallback(() => {
    if (isHolder) {
      showUpgradeTableUpsell();
    } else {
      setIsUpsellModalOpen(true);
    }

    if (onClick) onClick();
  }, [onClick, isHolder, showUpgradeTableUpsell]);

  const handleRequestClose = useCallback(() => {
    setIsUpsellModalOpen(false);
  }, []);

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <>
      <Modal zIndex={500} isOpen={isUpsellModalOpen} theme="tight" onRequestClose={handleRequestClose}>
        <div className={styles.upsellModal}>
          <div className={styles.featureName} style={{ backgroundColor: requiredPlan.colorHex }}>
            {requiredPlan.name} billing plan
          </div>

          <div className={styles.message}>
            <div>
              Currently you're on a{' '}
              <span className={styles.planName} style={{ color: userPlan.colorHex }}>
                {userPlan.name}
              </span>{' '}
              plan.
            </div>

            <div>Contact your account administrator ({holderName}) if you would like to upgrade your plan.</div>
          </div>

          <div className={styles.buttons}>
            <Button theme="white" onClick={handleRequestClose}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <GetFeatureButton text="Get feature" color={requiredPlan.color} onClick={handleClick} {...rest} />
    </>
  );
};

/* -------------------------------------------------------------------------- */
/*                                   UPSELL                                   */
/* -------------------------------------------------------------------------- */

const QSUpsell = ({ featureId, planId, ...rest }) => {
  if (featureId) {
    return <QSFeatureUpsell featureId={featureId} {...rest} />;
  } else {
    return <QSPlanUpsell planId={planId || 'pro'} {...rest} />;
  }
};

export default QSUpsell;
