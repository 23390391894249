import styles from './Input.module.css';

import React from 'react';
import { Icon, Input as MainInput } from 'ui-components';

const Input = React.forwardRef(
  ({ label, style, placeholder, className, value = null, onChange, disabled = false, hint, multiline, borderTop, borderBottom }, ref) => {
    let classList = [styles.input, className, borderTop ? styles.borderTop : '', borderBottom ? styles.borderBottom : ''];

    return (
      <div ref={ref} style={style} className={classList.join(' ')}>
        {label && <div className={styles.selectLabel}>{label}</div>}

        <MainInput value={value} onChange={onChange} disabled={disabled} placeholder={placeholder} multiline={multiline} />

        {hint && (
          <div className={styles.selectHint}>
            <Icon id="warning" />
            {hint}
          </div>
        )}
      </div>
    );
  }
);

export default Input;
