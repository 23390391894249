import styles from './FileInput.module.css';
import inputStyles from '../Input/Input.module.css';

import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Button } from '../Button/Button.js';
import { Spinner } from '../Spinner/Spinner';

export const FileInput = ({
  className,
  style,

  onChange,
  onClear,
  previewUri = null,

  accept = null,
  loading = false,
  multiple = false,
  error,
  disabled,
  maxSizeMB = 50,
  imagePreviewHeight = '150px',

  placeholder = 'No file chosen',
  suffix = 'Select file',
  showPreview = true
}) => {
  if (process.env.NODE_ENV === 'development') {
    if (!accept) throw new Error('accept prop with mime types was not passed');
  }

  const isDisabled = disabled || loading;
  const maxSizeB = maxSizeMB * 1e6;

  /* ---------------------------------- STATE --------------------------------- */

  const [fileNames, setFileNames] = useState(null);
  const [innerError, setInnerError] = useState(false);

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  // Dropzone or input interaction
  const handleDrop = useCallback(
    (acceptedFiles) => {
      setInnerError(false);

      if (acceptedFiles.length) {
        // Checking for too big files
        for (const file of acceptedFiles) {
          if (file.size > maxSizeB) {
            return setInnerError(`Max file size is ${maxSizeMB} Mb!`);
          }
        }

        // All files passed size check
        const files = [...acceptedFiles];

        setFileNames(files.map((file) => file.name).join(', '));
        onChange(files);
      } else {
        // react-dropzone rejected all files due to other reasons
        setInnerError(`File didn't meet requirements!`);
      }
    },
    [onChange, maxSizeB, maxSizeMB]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop: handleDrop,
    accept,
    multiple,
    disabled: isDisabled,
    noKeyboard: true
  });

  // Clear button
  const handleClear = useCallback(() => {
    setFileNames(null);
    onChange(null);
    if (onClear) onClear();
  }, [onChange, onClear]);

  /* ----------------------------------- JSX ---------------------------------- */

  const suffixJsx = (
    <span className={styles.suffixWrapper}>
      <div className={styles.suffixWidthProvider}>
        <span>{suffix}</span>
      </div>
      <span>{loading ? <Spinner /> : suffix}</span>
    </span>
  );

  let inputTextJsx = null;
  if (loading) {
    inputTextJsx = null;
  } else {
    inputTextJsx = error || innerError || fileNames || previewUri || <span className={inputStyles.placeholder}>{placeholder}</span>;
  }

  let previewJsx = null;
  if (loading) {
    previewJsx = null;
  } else if (previewUri) {
    previewJsx = <img alt="input-preview" src={previewUri} />;
  } else {
    previewJsx = (
      <>
        Nothing to preview
        <br />
        (select a file or drop it here)
      </>
    );
  }

  return (
    <div style={style} className={[className, styles.imageInput].join(' ')}>
      <div className={styles.fileInput}>
        <label
          className={[
            inputStyles.inputWrapper,
            error || innerError ? inputStyles.errorOutline : '',
            isDisabled ? inputStyles.disabled : ''
          ].join(' ')}>
          <input {...getInputProps()} disabled={isDisabled} />
          <div className={[styles.filePath, inputStyles.innerWrapper].join(' ')}>{inputTextJsx}</div>
          <div className={inputStyles.affix}>{suffixJsx}</div>
        </label>
        <Button theme="white" onClick={handleClear} disabled={isDisabled}>
          Clear
        </Button>
      </div>
      {showPreview && (
        <div
          className={[
            styles.imagePreview,
            isDragActive && isDragAccept ? inputStyles.focusOutline : '',
            isDisabled ? styles.disabled : ''
          ].join(' ')}
          style={{ height: imagePreviewHeight }}
          {...getRootProps()}>
          {previewJsx}
        </div>
      )}
    </div>
  );
};
