const options = [
  {
    label: '10,000 per month',
    value: 10000,
    price: 0
  },
  {
    label: '20,000 per month',
    value: 20000,
    price: 25
  },
  {
    label: '30,000 per month',
    value: 30000,
    price: 50
  },
  {
    label: '40,000 per month',
    value: 40000,
    price: 75
  },
  {
    label: '50,000 per month',
    value: 50000,
    price: 100
  },
  {
    label: '100,000 per month',
    value: 100000,
    price: 200
  },
  {
    label: '200,000 per month',
    value: 200000,
    price: 300
  }
];

export default options;
