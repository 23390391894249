import { getData } from 'country-list';

const optionsForCountrySelect = getData().map((obj) => {
  return {
    label: obj.name.replace('Taiwan, Province of China', 'Taiwan'),
    value: obj.code
  };
});

export default optionsForCountrySelect;
