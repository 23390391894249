import styles from './FloatingLogger.module.css';

import React from 'react';

// Quick component for easier debugging
const FloatingLogger = React.memo(({ ...data }) => {
  return (
    <div className={styles.floatingLogger}>
      {Object.entries(data).map(([key, value]) => {
        return (
          <React.Fragment key={key}>
            <div>{key}</div>
            <pre>{JSON.stringify(value, 0, 2)}</pre>
          </React.Fragment>
        );
      })}
    </div>
  );
});

export default FloatingLogger;
