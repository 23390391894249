import axios from 'axios';
import _remove from 'lodash/remove';

import config from 'config.js';

// Constants
const GET_DOMAINS_REQUEST = '_domains/GET_DOMAINS_REQUEST';
const GET_DOMAINS_SUCCESS = '_domains/GET_DOMAINS_SUCCESS';
const GET_DOMAINS_FAILURE = '_domains/GET_DOMAINS_FAILURE';

const UPDATE_DOMAIN_REQUEST = '_forms/UPDATE_DOMAIN_REQUEST';
const UPDATE_DOMAIN_SUCCESS = '_forms/UPDATE_DOMAIN_SUCCESS';
const UPDATE_DOMAIN_FAILURE = '_forms/UPDATE_DOMAIN_FAILURE';

const CREATE_DOMAIN_REQUEST = '_domains/CREATE_DOMAIN_REQUEST';
const CREATE_DOMAIN_SUCCESS = '_domains/CREATE_DOMAIN_SUCCESS';
const CREATE_DOMAIN_FAILURE = '_domains/CREATE_DOMAIN_FAILURE';

const DELETE_DOMAIN_REQUEST = '_domains/DELETE_DOMAIN_REQUEST';
const DELETE_DOMAIN_SUCCESS = '_domains/DELETE_DOMAIN_SUCCESS';
const DELETE_DOMAIN_FAILURE = '_domains/DELETE_DOMAIN_FAILURE';

const RESET_ENDPOINT_STATE = '_domains/RESET_ENDPOINT_STATE';

const endpoitInitialStatus = {
  loading: false,
  error: false,
  success: false,
  errors: []
};

// Initiual State
const initialState = {
  domains: [],

  getDomains: { ...endpoitInitialStatus },
  createDomain: { ...endpoitInitialStatus },
  updateDomain: { ...endpoitInitialStatus },
  deleteDomain: { ...endpoitInitialStatus }
};

const getEndpointStatus = (type, errors = []) => {
  const types = {
    request: {
      loading: true,
      error: false,
      success: false,
      errors: []
    },
    success: {
      loading: false,
      error: false,
      success: true,
      errors: []
    },
    failure: {
      loading: false,
      error: true,
      success: false,
      errors
    },
  };

  return types[type];
};

// Reducer
export default function reducer(state = JSON.parse(JSON.stringify(initialState)), action = {}) {
  if (action.type === RESET_ENDPOINT_STATE) {
    state[action.payload.name] = JSON.parse(JSON.stringify(endpoitInitialStatus));

    return { ...state };
  }

  // getDomains
  if (action.type === GET_DOMAINS_REQUEST) {
    state.getDomains = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === GET_DOMAINS_SUCCESS) {
    state.getDomains = getEndpointStatus('success');

    state.domains = [...action.payload];

    return { ...state };
  }

  if (action.type === GET_DOMAINS_FAILURE) {
    state.getDomains = getEndpointStatus('failure');

    return { ...state };
  }

  // updateDomain
  if (action.type === UPDATE_DOMAIN_REQUEST) {
    state.updateDomain = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === UPDATE_DOMAIN_SUCCESS) {
    const index = state.domains.findIndex((domain) => domain._id === action.payload.data._id);

    state.updateDomain = getEndpointStatus('success');
    state.domains[index] = { ...state.domains[index], ...action.payload.data };
    state.domains = [...state.domains];

    return { ...state };
  }

  if (action.type === UPDATE_DOMAIN_FAILURE) {
    state.updateDomain = getEndpointStatus('failure', [action.payload.message]);

    return { ...state };
  }

  // createDomain
  if (action.type === CREATE_DOMAIN_REQUEST) {
    state.createDomain = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === CREATE_DOMAIN_SUCCESS) {
    state.createDomain = getEndpointStatus('success');

    return { ...state };
  }

  if (action.type === CREATE_DOMAIN_FAILURE) {
    state.createDomain = getEndpointStatus('failure', [action.payload.message]);

    return { ...state };
  }

  // deleteDomain
  if (action.type === DELETE_DOMAIN_REQUEST) {
    state.deleteDomain = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === DELETE_DOMAIN_SUCCESS) {
    state.deleteDomain = getEndpointStatus('success');
    state.domains = _remove(state.domains, (domain) => domain._id !== action.payload.id);

    return { ...state };
  }

  if (action.type === DELETE_DOMAIN_FAILURE) {
    state.deleteDomain = getEndpointStatus('failure');

    return { ...state };
  }
  return state;
}

// Action Creators
export function resetEndpoitState(name) {
  return (dispatch) => {
    dispatch({ type: RESET_ENDPOINT_STATE, payload: { name } });
  };
}

export function getDomains() {
  const request = () => { return { type: GET_DOMAINS_REQUEST } };
  const success = (data) => { return { type: GET_DOMAINS_SUCCESS, payload: data } };
  const failure = () => { return { type: GET_DOMAINS_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getDomains(state._users.token);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}

export function createDomain(data) {
  const request = () => { return { type: CREATE_DOMAIN_REQUEST } };
  const success = () => { return { type: CREATE_DOMAIN_SUCCESS } };
  const failure = (data) => { return { type: CREATE_DOMAIN_FAILURE, payload: data } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.createDomain(state._users.token, data);

    if (response.status === 200) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure(response.data));
    }
  };
}

export function updateDomain(id, data) {
  const request = () => { return { type: UPDATE_DOMAIN_REQUEST } };
  const success = (data) => { return { type: UPDATE_DOMAIN_SUCCESS, payload: { data } } };
  const failure = (data) => { return { type: UPDATE_DOMAIN_FAILURE, payload: data } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.updateDomain(state._users.token, id, data);

    if (response.status === 200) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure(response.data));
    }
  };
}

export function deleteDomain(id) {
  const request = () => { return { type: DELETE_DOMAIN_REQUEST } };
  const success = (id) => { return { type: DELETE_DOMAIN_SUCCESS, payload: { id } } };
  const failure = () => { return { type: DELETE_DOMAIN_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request(id));

    const state = getState();
    const response = await services.deleteDomain(state._users.token, id);

    if (response) {
      return dispatch(success(id));
    } else {
      return dispatch(failure());
    }
  };
}

const services = {
  getDomains: async (token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/domains`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateDomain: async (token, id, data) => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${config.apiUrl}/domains/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  createDomain: async (token, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/domains`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  deleteDomain: async (token, id) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${config.apiUrl}/domains/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  }
};
