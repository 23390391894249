import ObjectID from 'bson-objectid';

import { ioEmitRequest } from './helpers';

const createSubmissionsWorkspace = (formId, setSubmissionsWorkspaces) => (params) => {
  return new Promise((resolve, reject) => {
    setSubmissionsWorkspaces((submissionsWorkspaces) => {
      const nextWorkspacePosition = submissionsWorkspaces.length + 1;
      const callback = async () => {
        const _id = ObjectID().toHexString();

        const paramsWithDefaultValues = {
          _id,
          label: null,
          color: '##9061F9',
          position: nextWorkspacePosition,
          stickyColumns: [],
          hiddenColumns: ['_id', 'submitted', 'active', 'referrer', 'ip', 'userAgent', 'completionTime', 'updatedAt'],
          sorting: [],
          filters: [],
          filtersCondition: 'all',
          automatedMode: {
            enabled: false,
            visibleChartsGroups: []
          },
          ...params
        };

        const newSubmissionsWorkspace = await ioEmitRequest('createSubmissionsWorkspace', {
          form: formId,
          params: paramsWithDefaultValues
        });

        resolve(newSubmissionsWorkspace);
        setSubmissionsWorkspaces((submissionsWorkspaces) => [...submissionsWorkspaces, newSubmissionsWorkspace]);
      };

      callback();
      return submissionsWorkspaces;
    });
  });
};

export default createSubmissionsWorkspace;
