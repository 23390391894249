import styles from './Aligner.module.css';

import React from 'react';

// Main feature is center-absolute alignment that keeps children in the true middle of container
// even if left and right are not equal sized
const Aligner = ({
  className = null,
  align = 'left', // left / right / center / center-absolute
  classes = {}, // { root, left, right, middle }
  left = null,
  right = null,
  children = null,
  ...rest
}) => {
  return (
    <div className={[className, styles[`align-${align}`], classes.root].join(' ')}>
      <div>{left && <div className={classes.left}>{left}</div>}</div>
      <div>
        <div>{children && <div className={classes.middle}>{children}</div>}</div>
      </div>
      <div>{right && <div className={classes.right}>{right}</div>}</div>
    </div>
  );
};

export default Aligner;
