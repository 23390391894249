import { format } from 'date-fns';

const safeFormatDate = (date) => {
  try {
    return format(new Date(date), 'dd MMM yyyy hh:mma');
  } catch (e) {
    return 'Invalid date';
  }
};

export default safeFormatDate;
