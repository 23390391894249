const HELP_SCOUT_CONFIG = {
  // HelpScout for users with personal and starter plan
  personalKey: '806c6ff2-b8eb-4c6b-91f3-163ba5efb321',
  // HelpScout for users with pro
  proKey: '1085e76d-4059-43b7-9cb1-775cbc483674',

  // Passed in config command
  beaconConfig: {
    display: {
      style: 'manual'
    }
  },

  // Ids various articles referenced in app
  articles: {
    sharing: '5faa9109cff47e0017d3036a',
    smtp: '5fae8c63cff47e00160b9a28',
    customDomains: '5faa91a94cedfd00165aec4a',
    mathematicalExpressions: '5faa9182cff47e0017d30371'
  }
};

export default HELP_SCOUT_CONFIG;
