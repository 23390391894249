import { useRouteMatch } from 'react-router-dom';

// Gets form id form url
const useFormId = () => {
  const match = useRouteMatch('/form/:id');
  const formId = match?.params?.id ?? null;

  return formId;
};

export default useFormId;
