import axios from 'axios';
import { differenceInCalendarDays, addDays, format } from 'date-fns';

import config from 'config.js';

// Constants
const CLEAR = 'statistics/CLEAR';

const EXPAND_BOX = 'statistics/EXPAND_BOX';
const COLLAPSE_BOX = 'statistics/COLLAPSE_BOX';

const UPDATE_TAB = 'statistics/UPDATE_TAB';

const LOAD_STATISTICS_REQUEST = 'statistics/LOAD_STATISTICS_REQUEST';
const LOAD_STATISTICS_SUCCESS = 'statistics/LOAD_STATISTICS_SUCCESS';
const LOAD_STATISTICS_FAILURE = 'statistics/LOAD_STATISTICS_FAILURE';

// Initiual State
const initialState = {
  loading: true,

  statistics: {},

  expanded: {
    abandonmentRate: false,
    avgCompletionTime: false,
    completionRate: false,
    totalReplies: false,
    totalViews: false,
    uniqueViews: false
  },

  tabs: {
    operatingSystemsAndBrowserTypes: 'os',
    country: 'table'
  }
};

const getChartData = (arr = []) => {
  if (arr.length === 0) {
    return [];
  }

  const firstDay = new Date(arr[0].date);
  const lastDay = new Date();
  const difference = differenceInCalendarDays(lastDay, firstDay);

  let day, dayString, index;

  if (difference > 0) {
    for (let i = 0; i <= difference; i++) {
      day = addDays(firstDay, i);
      dayString = format(day, 'yyyy/MM/dd');
      index = arr.findIndex((day) => day.date === dayString);

      if (index === -1) {
        arr.splice(i, 0, {
          date: dayString, value: 0
        });
      }
    }
  }

  arr = arr.slice(arr.length - 30, arr.length);

  let emptySize = Math.max.apply(Math, arr.map((d) => d.value)) * 5 / 100;

  if (!isFinite(emptySize) || emptySize === 0) {
    return [];
  }

  return [...new Array(30 - arr.length), ...arr].map((item) => {
    if (typeof item === 'undefined') {
      return { date: null, value: emptySize };
    }

    item.value = item.value + emptySize;
    item.emptySize = emptySize;

    return item;
  });
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  if (action.type === CLEAR) {
    state = { ...initialState };

    state.statistics = {};
    state.expanded = { ...initialState.expanded };
    state.tabs = { ...initialState.tabs };

    return { ...state };
  }

  if (action.type === EXPAND_BOX) {
    state.expanded[action.payload.id] = true;
    state.expanded = { ...state.expanded };

    return { ...state };
  }

  if (action.type === COLLAPSE_BOX) {
    state.expanded[action.payload.id] = false;
    state.expanded = { ...state.expanded };

    return { ...state };
  }

  if (action.type === UPDATE_TAB) {
    state.tabs[action.payload.id] = action.payload.value;
    state.tabs = { ...state.tabs };

    return { ...state };
  }

  if (action.type === LOAD_STATISTICS_REQUEST) {
    state.loading = true;
    state.statistics = {};

    return { ...state };
  }

  if (action.type === LOAD_STATISTICS_SUCCESS) {
    state.loading = false;
    state.statistics = action.payload;

    state.statistics.abandonmentRateHistory = getChartData(state.statistics.abandonmentRateHistory);
    state.statistics.avgCompletionTimeHistory = getChartData(state.statistics.avgCompletionTimeHistory);
    state.statistics.completionRateHistory = getChartData(state.statistics.completionRateHistory);
    state.statistics.totalRepliesHistory = getChartData(state.statistics.totalRepliesHistory);
    state.statistics.totalViewsHistory = getChartData(state.statistics.totalViewsHistory);
    state.statistics.uniqueViewsHistory = getChartData(state.statistics.uniqueViewsHistory);

    if (state.statistics.totalViewsHistory.length > 0) {
      state.expanded.totalViews = true;
    } else {
      state.expanded.totalViews = false;
    }

    state.expanded = { ...state.expanded };
    state.statistics = { ...action.payload };

    return { ...state };
  }

  if (action.type === LOAD_STATISTICS_FAILURE) {
    state.loading = false;

    return { ...state };
  }

  return state;
}

// Action Creators
export function clear() {
  return (dispatch) => {
    dispatch({ type: CLEAR });
  };
}

export function expandBox(id) {
  return (dispatch) => {
    dispatch({ type: EXPAND_BOX, payload: { id } });
  };
}

export function collapseBox(id) {
  return (dispatch) => {
    dispatch({ type: COLLAPSE_BOX, payload: { id } });
  };
}

export function updateTab(id, value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_TAB, payload: { id, value } });
  };
}

export function loadStatistics() {
  const request = () => { return { type: LOAD_STATISTICS_REQUEST } };
  const success = (data) => { return { type: LOAD_STATISTICS_SUCCESS, payload: data } };
  const failure = () => { return { type: LOAD_STATISTICS_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.loadStatistics(state._users.token, state._users.user.permissions, state.builder.formId);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

const services = {
  loadStatistics: async (token, permissions, formId) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/forms/${formId}/analytics`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
};