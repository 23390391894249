import styles from './Reminder.module.css';

import React from 'react';

import { Icon } from '../Icon/Icon';

const Reminder = React.memo(
  ({
    // Styling
    className = '',
    style,
    // Config
    theme = 'default',
    vertical = false,
    // Content
    icon,
    header,
    message,
    suffix
  }) => {
    return (
      <div
        className={[className, styles.reminder, !vertical ? styles.horizontal : '', styles[`theme-${theme}`] || ''].join(' ')}
        style={style}>
        {icon && <div className={styles.icon}>{<Icon id={icon} />}</div>}
        <div className={styles.content}>
          <div className={styles.textContent}>
            {header && <div className={styles.header}>{header}</div>}
            {message && <div className={styles.message}>{message}</div>}
          </div>

          {suffix && <div className={styles.suffix}>{suffix}</div>}
        </div>
      </div>
    );
  }
);

export default Reminder;
