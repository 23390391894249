import styles from './ScrollableContent.module.css';

import React from 'react';

import TabsHeader from '../TabsHeader/TabsHeader.js';

// Wrapper to create a scrollable, centered builder page inside position fixed content
const Scrollable = React.forwardRef(({ header, headerContent, disabled, children }, ref) => {
  return (
    <div className={styles.scrollableAbsoluteWrapper} ref={ref}>
      <div className={styles.scrollableWrapper}>
        <div className={styles.scrollable}>
          {header && (
            <div className={styles.header}>
              <TabsHeader>{header}</TabsHeader>
              {headerContent}
            </div>
          )}
          <div className={[styles.children, disabled ? styles.disabled : ''].join(' ')}>{children}</div>
        </div>
      </div>
    </div>
  );
});

export default Scrollable;
