import styles from './Select.module.css';

import React, { useMemo } from 'react';
import ReactSelect from 'react-select';
import { Spinner } from './../Spinner/Spinner.js';

import { ReactComponent as DropdownIcon } from 'assets/images/dropdown-arrow.svg';

const DropdownIndicator = () => (
  <div className={styles.dropdownIndicator}>
    <DropdownIcon />
  </div>
);

export const Select = (props) => {
  const selectCustomStyles = {
    container: (provided, state) => ({
      ...provided,
      minWidth: props.width || '100%'
    }),
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: props.minHeight || '28px',
        border: state.isFocused ? '1px solid #B4B2AF' : '1px solid #DDDBD7',
        boxSizing: 'border-box',
        background: '#FFFFFF',
        backgroundImage: state.isDisabled ? 'linear-gradient(hsla(30, 2%, 18%, 0.04), hsla(30, 2%, 18%, 0.04))' : 'none',
        borderRadius: '4px',
        color: state.isDisabled ? 'rgba(57, 55, 54, 0.4)' : state.isFocused ? '#454442' : '#807E7C',
        fontSize: '13px',
        fontWeight: '400',
        lineHeight: '22px',
        boxShadow: state.isFocused
          ? state.selectProps.error
            ? '0px 0px 0px 3px rgba(212, 61, 81, 0.3)'
            : '0px 0px 0px 3px #EAE8E4'
          : '0px 0px 0px 1px transparent',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        // opacity: state.isDisabled ? 0.5 : 1,
        '&:hover': {
          borderColor: '#B4B2AF',
          color: '#454442'
        },
        borderColor: state.selectProps.error ? '#d43d51 !important' : 'initial',
        // Long placeholders fix: 22.03.2021
        overflow: 'hidden',
        transition: 'box-shadow 0.2s ease-in-out, border 0.1s ease-in-out'
      };
    },
    placeholder: (provided, state) => ({
      ...provided,
      marginLeft: '0',
      marginRight: '0',
      color: 'inherit',
      // Long placeholders fix: 22.03.2021
      whiteSpace: 'nowrap'
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? '#DDDBD7' : state.isFocused ? '#F6F6F6' : '#FFF',
      color: '#454442',
      cursor: 'pointer',
      padding: '5px 15px',
      border: 0,
      '&:hover': !state.isSelected
        ? {
            background: '#F6F6F6'
          }
        : {
            background: '#DDDBD7',
            cursor: 'default'
          }
    }),
    singleValue: (provided, state) => ({
      ...provided,
      width: props.width || '100%',
      color: '#807E7C',
      marginLeft: 0,
      marginRight: 0,
      maxWidth: `calc(${props.width || '100%'} - 16px)`
      // minHeight: '25px'
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 4
    }),
    clearIndicator: (provided) => ({
      ...provided,
      // padding: 4
      padding: 0
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 0 0 10px'

      // TODO: add something that will make it work inline-ish
    }),
    multiValue: (provided) => ({
      ...provided,
      margin: '4px 0 4px 2px'
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#807E7C',
      fontSize: '13px',
      lineHeight: '20px'
    }),
    multiValueRemove: (provided) => ({
      ...provided
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0
    })
  };

  const formattedValue = useMemo(() => {
    if (!props.options) return null;

    const valueIsArray = Array.isArray(props.value);
    const valueIsPlainObject = props.value instanceof Object && !valueIsArray;

    // Most-likely an extracted option (value + label) was passed
    if (valueIsPlainObject) {
      return props.value;
    }
    // Most-likely multiple values were passed
    else if (valueIsArray) {
      return props.value
        .map((value) => {
          const option = props.options.find((o) => o.value === value);
          if (!option) return false;

          return { value, label: option ? option.label : value };
        })
        .filter(Boolean);
    }
    // Most-likely one value was passed
    else {
      return props.options.find((o) => o.value === props.value) ?? null;
    }
  }, [props.options, props.value]);

  return (
    <div className={[styles.main, props.className].join(' ')} style={{ ...props.style, width: props.width || '100%' }}>
      <ReactSelect
        {...props}
        value={formattedValue}
        isDisabled={props.disabled}
        styles={{
          ...selectCustomStyles,
          ...props.styles
        }}
        isClearable={props.isClearable}
        placeholder={props.placeholder ?? '- Select -'}
        menuShouldBlockScroll={props.menuShouldBlockScroll}
        menuPortalTarget={props.menuPortalTarget}
        isSearchable={props.filter === true}
        components={{ DropdownIndicator }}
        isMulti={props.isMulti}
        multi={props.isMulti}
        tabSelectsValue={props.tabSelectsValue}
        closeMenuOnSelect={props.closeMenuOnSelect}
        className={styles.select}
      />

      {props.loading && (
        <div className={styles.spinnerContainer}>
          <Spinner size={10} borderSize={0.2} speed="0.8s" />
        </div>
      )}
    </div>
  );
};
