import styles from './Profile.module.css';

import React, { useState, useContext } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Avatar, Input, Header, FormRow } from 'ui-components';
import io from 'io.js';

import { MainContext } from 'contexts/main.js';

import ContentHeader from './../Header.js';
import AvatarChangePanel from './panels/AvatarChangePanel.js';
import PasswordChangePanel from './panels/PasswordChangePanel.js';
import EmailAddressChangePanel from './panels/EmailAddressChangePanel';
import OtpCreatePanel from './panels/OtpCreatePanel.js';
import OtpRemovePanel from './panels/OtpRemovePanel.js';
import DeleteAccountPanel from './panels/DeleteAccountPanel.js';

import config from 'config.js';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Profile = () => {
  const { user, updateUser, getAndUpdateUser } = useContext(MainContext);

  const [image, setImage] = useState(null);

  const [deleteUserAvatarLoading, setDeleteUserAvatarLoading] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showOtpCreatePanel, setShowOtpCreatePanel] = useState(false);
  const [showOtpRemovePanel, setShowOtpRemovePanel] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [showChangeEmail, setShowChangeEmail] = useState(false);

  const handleDrop = (dropped) => setImage(dropped[0]);

  const deleteAvatar = () => {
    if (deleteUserAvatarLoading) return;

    setDeleteUserAvatarLoading(true);

    io.socket.once('deleteUserAvatar', () => {
      getAndUpdateUser();
      setDeleteUserAvatarLoading(false);
    });

    io.socket.emit('deleteUserAvatar');
  };

  const update = updateUser;

  return (
    <>
      <ContentHeader title="My profile" />

      <div className={styles.content}>
        <FormRow label="Profile picture" style={{ width: '48%', margin: '0 0 15px 0' }}>
          <div className={styles.profilePicture}>
            <Avatar className={styles.avatar} user={user} loading={deleteUserAvatarLoading} size="huge" />

            {!user.avatar && (
              <Dropzone onDrop={handleDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button size="small" theme="white">
                      Upload image
                    </Button>
                  </div>
                )}
              </Dropzone>
            )}
            {user.avatar && (
              <Button size="small" theme="red" onClick={() => deleteAvatar()}>
                Delete avatar
              </Button>
            )}
          </div>
        </FormRow>

        <div className={styles.firstLastName}>
          <FormRow label="Your first name" style={{ width: '48%' }}>
            <Input className={config.fullStoryExcludeClass} value={user.firstName} onChange={(value) => update({ firstName: value })} />
          </FormRow>
          <FormRow label="Your last name" style={{ width: '48%' }}>
            <Input className={config.fullStoryExcludeClass} value={user.lastName} onChange={(value) => update({ lastName: value })} />
          </FormRow>
        </div>

        <FormRow label="Company name" style={{ width: '48%' }}>
          <Input
            className={config.fullStoryExcludeClass}
            value={user.companyName}
            onChange={(value) => update({ companyName: value })}
            disabled={user.role !== 'holder'}
          />
        </FormRow>

        <FormRow
          label={<>Your email address{user.verified ? null : <span style={{ opacity: 0.5, marginLeft: '0.5ch' }}>(not verified)</span>}</>}
          style={{ width: '48%' }}>
          <Input className={config.fullStoryExcludeClass} value={user.email} disabled={true} />
        </FormRow>

        {user.authType !== 'local' && (
          <FormRow label="Authentication method" style={{ width: '48%' }}>
            <Input className={config.fullStoryExcludeClass} value={capitalizeFirstLetter(user.authType)} disabled={true} />
          </FormRow>
        )}

        <Header style={{ padding: '20px 0 15px 0' }}>Security</Header>

        {user.authType !== 'local' ? (
          <div className={styles.aboutAuthType}>
            <p>
              Your account uses <strong>{capitalizeFirstLetter(user.authType)}</strong> authentication which means that:
            </p>
            <ul>
              <li>you can't change your password,</li>
              <li>you can't change your email address,</li>
              <li>you can't enable two-factor authentication.</li>
            </ul>
          </div>
        ) : (
          <>
            {!user?.lifeTime?.enabled && (
              <FormRow label="Email address" style={{ width: '48%' }}>
                <Button theme="white" onClick={() => setShowChangeEmail(true)}>
                  Change email address
                </Button>
              </FormRow>
            )}

            <FormRow label="Password" style={{ width: '48%' }}>
              <Button theme="white" onClick={() => setShowChangePassword(true)}>
                Change password
              </Button>
            </FormRow>

            <FormRow label="Two-factor authentication" style={{ width: '48%' }}>
              {(!user.otp || !user.otp.enabled) && (
                <Button theme="white" onClick={() => setShowOtpCreatePanel(true)}>
                  Enable two-factor authentication
                </Button>
              )}
              {user.otp && user.otp.enabled && (
                <Button theme="red" onClick={() => setShowOtpRemovePanel(true)}>
                  Disable two-factor authentication
                </Button>
              )}
            </FormRow>
          </>
        )}

        <Header style={{ padding: '20px 0 15px 0' }}>Scary zone</Header>

        <Button theme="red" onClick={() => setShowDeleteAccount(true)}>
          Delete account
        </Button>
      </div>

      <AvatarChangePanel image={image} setImage={setImage} />
      <PasswordChangePanel show={showChangePassword} onClose={() => setShowChangePassword(false)} />
      <OtpCreatePanel show={showOtpCreatePanel} onClose={() => setShowOtpCreatePanel(false)} />
      <OtpRemovePanel show={showOtpRemovePanel} onClose={() => setShowOtpRemovePanel(false)} />
      <DeleteAccountPanel show={showDeleteAccount} onClose={() => setShowDeleteAccount(false)} />
      <EmailAddressChangePanel show={showChangeEmail} onClose={() => setShowChangeEmail(false)} />
    </>
  );
};

export default Profile;
