import sections from '../sections';
import generateSectionDataFromExample from './generateSectionDataFromExample';

// Uses data from config to create a brand new section (array of fields) data that can be send to backend
const generatePrebuiltSectionData = (prebuiltSectionId) => {
  const config = sections.find((c) => c._id === prebuiltSectionId);
  if (!config) {
    throw new Error('DEBUG: prebuilt section like this in unknown.');
  }

  // Creates a section data based on provided example (data of some existing isolated section)
  return generateSectionDataFromExample(config.example);
};

export default generatePrebuiltSectionData;
