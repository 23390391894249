import styles from './FormPreview.module.css';

import React from 'react';
import QuestionscoutFormEngine from 'form';

import { SuspenseFallback } from 'ui-components';

import useFormEngineMainProps from './useFormEngineProps';

/* -------------------------------------------------------------------------- */
/*                                   CONFIG                                   */
/* -------------------------------------------------------------------------- */

// This id is used by lazy loader in form-engine
const FORM_ENGINE_CONTAINER_ID = 'form';

const EMPTY_CALLBACK = () => {};

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */

const FormPreview = React.memo(
  ({
    // === Styling
    className = '',

    // === Config
    placement = null,
    device = 'desktop',

    // === Data
    user = null,
    theme = null,
    form = null,

    // === Additional config
    previewLogic = false,
    loading = false,

    // === State control
    activeField = null,
    onChangeActiveField = EMPTY_CALLBACK
  }) => {
    /* ---------------------------------- STATE --------------------------------- */

    const formEngineProps = useFormEngineMainProps({ user, theme, form, previewLogic, activeField, onChangeActiveField });

    let shouldScroll = false;

    /* ----------------------------------- JSX ---------------------------------- */

    let contentJsx = null;

    // Loading state or first pass of debounce
    if (loading) {
      contentJsx = (
        <div className={styles.placeholder}>
          <SuspenseFallback />
        </div>
      );
    }

    // Form wasn't passed
    else if (!formEngineProps.form) {
      contentJsx = (
        <div className={styles.placeholder}>
          Selected form was removed, never existed or is invalid.
          <br />
          Choose a different one to continue.
        </div>
      );
    }

    // Form type is unknown
    else if (!formEngineProps.form?.type) {
      contentJsx = <div className={styles.placeholder}>Select a type of this form to continue.</div>;
    }

    // Theme wasn't passed
    else if (!formEngineProps.theme) {
      contentJsx = (
        <div className={styles.placeholder}>
          Selected theme was removed, never existed or is invalid.
          <br />
          Choose a different one to continue.
        </div>
      );
    }

    // Everything is ok
    else {
      shouldScroll = formEngineProps.form?.type === 'classic' || formEngineProps.submitted || formEngineProps.showWelcomePage;
      contentJsx = (
        <QuestionscoutFormEngine
          // Display mode props
          placement={placement}
          device={device}
          // Prepared props
          {...formEngineProps}
        />
      );
    }

    return (
      <div
        id={FORM_ENGINE_CONTAINER_ID}
        className={[className, styles.form].join(' ')}
        style={{ overflowY: shouldScroll ? 'scroll' : 'hidden' }}>
        {contentJsx}
      </div>
    );
  }
);

export default FormPreview;
