import styles from './SuspenseFallback.module.css';

import React, { useEffect, useState } from 'react';

import { Spinner } from '../Spinner/Spinner';

import { useTimeout } from 'helpers';

const NOT_RESPONDING_MESSAGE_DELAY = 4000;

const SuspenseFallback = ({ inline = false, small = false }) => {
  const [notRespondingVisible, setNotRespondingVisible] = useState(false);
  const setNotRespondingVisibleTimeout = useTimeout();

  useEffect(() => {
    setNotRespondingVisibleTimeout(() => setNotRespondingVisible(true), NOT_RESPONDING_MESSAGE_DELAY);
  }, [setNotRespondingVisibleTimeout]);

  return (
    <div className={inline ? styles.inlineLoader : styles.absoluteLoader}>
      <div className={styles.spinnerContainer}>
        <Spinner size={small ? 20 : 40} borderSize={2} speed="0.8s" />
        {notRespondingVisible && (
          <p className={styles.spinnerText} style={{ maxWidth: small ? 200 : 240, fontSize: small ? 11 : 13 }}>
            (crickets) Well.. this is taking a while... <br />
            If this page does not end up responding, try refreshing instead.
          </p>
        )}
      </div>
    </div>
  );
};

export default SuspenseFallback;
