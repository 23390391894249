import React, { useState, useEffect, useContext, useCallback } from 'react';

import { useUserLogs, withQueryBoundary } from 'api';
import { usePopups, Text } from 'ui-components';
import { LinkButton } from 'components/shared';
import { MainContext } from 'contexts';

/* -------------------------------------------------------------------------- */
/*                                   CONFIG                                   */
/* -------------------------------------------------------------------------- */

const EMAIL_SUCCESS_LOG_TYPE = 'emailSent';
const EMAIL_ERROR_LOG_TYPE = 'emailError';

const LOCAL_STORAGE_LATEST_NOTIFIED_ERROR_LOG_ID_KEY = 'latestNotifiedSmtpErrorLogId';

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */

const BackgroundSmtpLogsChecker = React.memo(() => {
  /* --------------------------------- POPUPS --------------------------------- */

  const popups = usePopups();

  const { setShowProfile, setProfilePage } = useContext(MainContext);

  const showErrorPopup = useCallback(() => {
    const showLogsPage = () => {
      setShowProfile(true);
      setProfilePage('logs');
    };

    popups.showErrorSnackbar({
      message: (
        <div>
          <div style={{ marginBottom: 5 }}>Some of your emails were not sent.</div>
          <Text theme="description" style={{ fontWeight: 500 }}>
            Go to your <LinkButton onClick={showLogsPage}>Logs</LinkButton> page to learn more.
          </Text>
        </div>
      )
    });
  }, [setShowProfile, setProfilePage, popups]);

  /* ---------------------------------- LOGIC --------------------------------- */

  const [suppressNotifications, setSuppressNotifications] = useState(false);

  const { data } = useUserLogs({
    types: [EMAIL_SUCCESS_LOG_TYPE, EMAIL_ERROR_LOG_TYPE],
    smtp: true,
    page: 1,
    pageSize: 5,
    refetchInterval: 10 * 60 * 1000 // Check logs once every 10 minutes
  });

  const latestLogs = data.logs;

  useEffect(() => {
    const latestErrorLogs = latestLogs.filter((log) => log.type === EMAIL_ERROR_LOG_TYPE);
    const latestErrorLogId = latestErrorLogs[0]?._id;

    if (latestErrorLogs.length > 0) {
      const latestNotifiedErrorLogId = window.localStorage.getItem(LOCAL_STORAGE_LATEST_NOTIFIED_ERROR_LOG_ID_KEY);
      window.localStorage.setItem(LOCAL_STORAGE_LATEST_NOTIFIED_ERROR_LOG_ID_KEY, latestErrorLogId);

      const shouldNotifyUserAboutErrors =
        // Notify only if all latest logs were errors
        latestLogs.length === latestErrorLogs.length &&
        // Don't notify again if no new logs arrived since last notification
        latestErrorLogId !== latestNotifiedErrorLogId &&
        // Additional flag to suppress notifications for the rest of a session
        suppressNotifications === false;

      if (shouldNotifyUserAboutErrors) {
        setSuppressNotifications(true);
        showErrorPopup();
      }
    }
  }, [showErrorPopup, latestLogs, suppressNotifications]);

  return null;
});

export default withQueryBoundary(BackgroundSmtpLogsChecker, {
  SuspenseFallbackComponent: () => null,
  ErrorFallbackComponent: () => null
});
