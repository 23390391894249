import io from 'io.js';

export default function asyncSocketAckEmit(event, params = {}, requestConfig = {}) {
  const { showToasts = true } = requestConfig;

  return new Promise((resolve) => {
    if (window.QS?.verbose) {
      console.log(`%cREQUEST: ${event}`, 'color: #4685F1', params);
    }

    const handleResponse = (payload) => {
      if (window.QS?.verbose) {
        console.log(`%cRESPONSE: ${event}`, 'color: #0F9F6E', payload);
      }

      // Temporary
      if (payload?.success === false) {
        const showError = window.QS?.showError;
        const message = payload?.error || payload?.message || 'Something went wrong.';
        showToasts &&
          showError &&
          showError({
            message: message,
            footer: `Event ID: ${event}`
          });
      }

      resolve(payload);
    };

    io.socket.emit(event, params, handleResponse);
  });
}
