import arrayMove from 'array-move';
import _orderBy from 'lodash/orderBy';
import mapBy from './mapBy';

import { calculateFieldsChildrenMap } from './calculateFieldsUtilityProperties';

// Passed index is threated literally (at what visible position field should be)
const calculateFieldsToUpdateAfterReorder = ({
  fields,
  movedFieldId: fieldId,
  nextMovedFieldIndex: nextFieldIndex = Infinity,
  nextMovedFieldSection: nextFieldSectionId = 'root'
}) => {
  fieldId = String(fieldId);

  const fieldsToUpdate = {
    positions: {},
    childrens: {},
    sections: {}
  };

  /* ------------------------------- HELPER DATA ------------------------------ */

  // Fields by id
  const fieldsMap = mapBy(fields, '_id');
  // Local fields sections map with virtual root section
  const sectionsChildrenMap = calculateFieldsChildrenMap(fields);

  // ---- GETTING REQUIRED DATA AND VALIDATING IT:

  const movedField = fieldsMap.get(fieldId);

  if (!movedField) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('DEBUG: Field like this does not exist.');
    }
    return fieldsToUpdate;
  }

  // Sections that will be modified in some way
  const fieldSectionId = movedField.section ?? 'root';
  const fieldSectionChildren = sectionsChildrenMap.get(fieldSectionId);
  const nextFieldSectionChildren = sectionsChildrenMap.get(nextFieldSectionId);

  // Index in parent section childrens array
  const fieldIndex = fieldSectionChildren.findIndex((id) => id === fieldId);

  if (!fieldSectionChildren || !nextFieldSectionChildren || fieldIndex === -1) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('DEBUG: Situation like this should not be possible with vailid data.');
    }
    return fieldsToUpdate;
  }

  // Next index clamped
  nextFieldIndex = Math.max(Math.min(nextFieldSectionChildren.length, nextFieldIndex), 0);

  /* ----------------------- UPDATING SECTIONS CHILDREN ----------------------- */

  /*
       Note:
        - Minimal required update is calculated by comparing old sections children,
          with new section children so only section are updated.
      */

  const nextSectionsChildrenMap = new Map(sectionsChildrenMap);

  // Case 1: fields stays in the same section
  if (fieldSectionId === nextFieldSectionId) {
    // Re-arranges items in parent section:
    nextSectionsChildrenMap.set(fieldSectionId, arrayMove(fieldSectionChildren, fieldIndex, nextFieldIndex));
  }

  // Case 2: fields is moved to a different section
  else {
    // Removes field from original parent section:
    nextSectionsChildrenMap.set(
      fieldSectionId,
      fieldSectionChildren.filter((id) => id !== fieldId)
    );

    // Insets an item to a group.
    const clonedChildren = [...nextFieldSectionChildren];
    clonedChildren.splice(nextFieldIndex, 0, fieldId);

    nextSectionsChildrenMap.set(nextFieldSectionId, clonedChildren);
  }

  /* --------------------- COMPARING OLD AND NEW CHILDREN --------------------- */

  for (const sectionId of sectionsChildrenMap.keys()) {
    const children = sectionsChildrenMap.get(sectionId);
    const nextChildren = nextSectionsChildrenMap.get(sectionId);

    if (!children || !nextChildren) {
      if (process.env.NODE_ENV === 'development') throw new Error('DEBUG: probably wrong set somewhere.');
      return;
    }

    // Detecting position and section changes
    for (let position = 1; position <= nextChildren.length; position++) {
      const fieldId = nextChildren[position - 1];
      const field = fieldsMap.get(fieldId);

      if (field.position !== position) {
        fieldsToUpdate.positions[fieldId] = position;
      }
      if (field.section !== sectionId) {
        fieldsToUpdate.sections[fieldId] = sectionId;
      }
    }

    // Detecting children changes
    if (sectionId !== 'root' && children !== nextChildren) {
      fieldsToUpdate.childrens[sectionId] = nextChildren;
    }
  }

  return fieldsToUpdate;
};

export default calculateFieldsToUpdateAfterReorder;
