import { useContext } from 'react';
import TutorialsContext from './tutorialsContext';

// For use in other parts of app
const useTutorialsApi = () => {
  const { showHelpCenter } = useContext(TutorialsContext);

  return {
    showHelpCenter
  };
};

export default useTutorialsApi;
