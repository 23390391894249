import { ioEmitRequest } from './helpers';

/**
 * Get submission file
 *
 * @param {string} form - form _id
 * @param {string} file - file _id
 */
export default (formId) => async (params) => {
  if (!formId) return null;
  return await ioEmitRequest('getSubmissionFile', {
    form: formId,
    ...params
  });
};
