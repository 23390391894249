import styles from './Flex.module.css';

import React, { useRef, useEffect, useCallback, useState } from 'react';
import _throttle from 'lodash/throttle';

import { useIsMountedRef } from 'helpers';

const PADDING_PRESETS = ['0px', '10px', '15px 20px'];

const Flex = React.forwardRef(
  ({ className, vertical, growItems, gap = 1, padding = 0, alignItems, justifyContent = 'flex-start', style, ...rest }, ref) => {
    return (
      <div
        style={{
          alignItems: alignItems || (vertical ? 'stretch' : 'center'),
          justifyContent,
          padding: typeof padding === 'string' ? padding : PADDING_PRESETS[padding],
          ...style
        }}
        className={[
          className,
          styles.flex,
          vertical ? styles.vertical : styles.horizontal,
          growItems ? styles.growItems : '',
          styles[`gap-${gap}`]
        ].join(' ')}
        {...rest}
        ref={ref}
      />
    );
  }
);

const FlexScrollable = ({ className, ...rest }) => {
  const isMountedRef = useIsMountedRef();

  const [scrollableRef, setScrollableRef] = useState(null);

  // State
  const [isOverflownY, setIsOverflownY] = useState(false);
  const [isOnTop, setIsOnTop] = useState(true);
  const [isOnBottom, setIsOnBottom] = useState(true);

  // Adds event handles used for overflow detection
  useEffect(() => {
    if (scrollableRef) {
      // Updates flex overflow props
      const updateOverflow = () => {
        if (isMountedRef.current) {
          const element = scrollableRef;

          const scrollHeight = element.scrollHeight,
            clientHeight = element.clientHeight,
            scrollTop = element.scrollTop;

          // Detecting container vertical overflow state
          setIsOverflownY(scrollHeight > clientHeight);
          setIsOnTop(scrollTop - 1 < 0);
          setIsOnBottom(scrollHeight - (clientHeight + scrollTop + 1) < 0);

          /*
            const scrollWidth = element.scrollWidth,
              clientWidth = element.clientWidth,
              scrollLeft = element.scrollLeft;
  
            const isOverflownX = scrollWidth > clientWidth;
            const isOnLeft = scrollLeft - 1 < 0;
            const isOnRight = scrollWidth - (clientWidth + scrollLeft + 1) < 0;
          */
        }
      };

      const updateOverflowDebounced = _throttle(updateOverflow, 250);

      updateOverflowDebounced();

      // Listening for screen resize events
      window.addEventListener('resize', updateOverflowDebounced);

      // Listening for scroll events
      scrollableRef.addEventListener('scroll', updateOverflowDebounced);

      // Listening for resize events if ResizeObserver is available
      let observer = null;
      if (typeof ResizeObserver !== 'undefined') {
        observer = new ResizeObserver(updateOverflow);
        observer.observe(scrollableRef);
      }
      return () => {
        // Cleanup
        window.removeEventListener('resize', updateOverflowDebounced);
        scrollableRef.removeEventListener('scroll', updateOverflowDebounced);
        if (observer) {
          observer.disconnect();
        }
      };
    }
  }, [scrollableRef, isMountedRef]);

  return (
    <div className={[className, styles.flexScrollableWrapper].join(' ')}>
      <div className={[styles.scrollIndicatorTop, !isOnTop ? styles.scrollIndicatorVisible : ''].join(' ')} />
      <div className={[styles.scrollIndicatorBottom, !isOnBottom ? styles.scrollIndicatorVisible : ''].join(' ')} />
      <div className={[styles.flexScrollable, isOverflownY ? styles.overflownY : ''].join(' ')} ref={setScrollableRef}>
        <Flex {...rest} />
      </div>
    </div>
  );
};

// To align last item to right/left
Flex.Margin = () => <div style={{ margin: 'auto' }} />;
Flex.Scrollable = FlexScrollable;

export default Flex;
