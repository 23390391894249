import styles from './CopyField.module.css';

import React, { useCallback, useRef, useState } from 'react';

import { Button } from '../Button/Button.js';
import { Input } from '../Input/Input.js';
import { useTimeout } from 'helpers';

const CopyField = ({ className, value, multiline = false, placeholder = 'Nothing to copy', suffix = 'Copy', ...rest }) => {
  const inputRef = useRef(null);
  const [recentlyCopied, setRecentlyCopied] = useState(false);
  const setCopiedTimeout = useTimeout();

  const handleCopy = useCallback(
    (e) => {
      const input = inputRef.current;
      if (input) {
        input.select();
        input.setSelectionRange(0, 99999);
        document.execCommand('copy');

        setRecentlyCopied(true);
        setCopiedTimeout(() => {
          setRecentlyCopied(false);
        }, 1000);
      }
    },
    [setCopiedTimeout]
  );

  /* ----------------------------------- JSX ---------------------------------- */

  const COPY_MSG = 'Copied!';

  const suffixJsx = (
    <span className={styles.suffixWrapper}>
      <div className={styles.suffixWidthProvider}>
        <span>{COPY_MSG}</span>
        <span>{suffix}</span>
      </div>
      <span>{recentlyCopied ? COPY_MSG : suffix}</span>
    </span>
  );

  if (multiline) {
    return (
      <div className={[styles.multilineCopyFiled, className].join(' ')}>
        <Input
          multiline
          {...rest}
          style={{ cursor: 'pointer', flexShrink: 1 }}
          success={recentlyCopied}
          onClick={handleCopy}
          value={value}
          placeholder={placeholder}
          readOnly
          ref={inputRef}
        />
        <Button theme="black" onClick={handleCopy} fullWidth>
          {recentlyCopied ? COPY_MSG : suffix}
        </Button>
      </div>
    );
  } else {
    return (
      <Input
        {...rest}
        className={className}
        style={{ cursor: 'pointer', flexShrink: 1 }}
        success={recentlyCopied}
        onClick={handleCopy}
        value={value}
        placeholder={placeholder}
        prefix={suffixJsx}
        readOnly
        ref={inputRef}
      />
    );
  }
};

export default CopyField;
