import { some } from 'lodash';
import { useCallback } from 'react';
import { useRouteMatch, useHistory, useLocation, generatePath } from 'react-router-dom';

// Allows changing one or more params of nearest route.
// Usage:
// const changeParams = useRouteParamsChanger();
// changeParams({page: 'otherPage'})
const useRouteParamsChanger = () => {
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const routePath = routeMatch?.path;
  const currentParams = routeMatch?.params;

  return useCallback(
    (changes, keepSearchParams = false) => {
      let somethingWillChange = false;
      for (const key in changes) {
        if (changes[key] !== currentParams[key]) {
          somethingWillChange = true;
        }
      }

      if (!somethingWillChange) return;

      const nextRouteParams = { ...currentParams, ...changes };
      history.push(generatePath(routePath, nextRouteParams) + (keepSearchParams ? location.search : ''));
    },
    [history, routePath, currentParams, location.search]
  );
};

export default useRouteParamsChanger;
