import styles from './FilesUploadPreview.module.css';

import React, { useState } from 'react';

import { formatBytes } from 'misc/helpers.js';

import { Expandible, Icon, Spinner } from 'ui-components';

import EmptyPreview from './EmptyPreview';

import useFormId from '../../../Builder2/useFormId';
import getSubmissionFileReducer from '../../../Builder2/reducers/getSubmissionFile';

/* -------------------------------------------------------------------------- */
/*                             SINGLE FILE UPLOAD                             */
/* -------------------------------------------------------------------------- */

const UploadPreview = ({ uploadId, uploadName, uploadSizeInBytes, uploadFailed, onRequestDownloadUrl }) => {
  const [isLoading, setIsLoading] = useState(false);

  const formattedUploadSize = formatBytes(uploadSizeInBytes);

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const handleDownload = async () => {
    setIsLoading(true);

    const url = await onRequestDownloadUrl(uploadId);
    if (url) {
      window.open(url, '_blank');
    }

    setIsLoading(false);
  };

  const handleClick = () => {
    if (!uploadFailed && uploadId) {
      handleDownload();
    } else {
      return;
    }
  };

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <div
      style={{ cursor: isLoading ? 'wait' : undefined }}
      className={uploadFailed ? styles.uploadPreviewFailed : styles.uploadPreviewSuccessful}
      onClick={isLoading ? undefined : handleClick}>
      {uploadFailed ? (
        <>
          <span style={{ textDecoration: 'line-through' }}>{uploadName}</span> (upload failed)
        </>
      ) : (
        <>
          <div className={styles.downloadIcon}>
            {isLoading ? <Spinner /> : <Icon className={styles.downloadIcon} id="download" color="inherit" />}
          </div>
          {uploadName} ({formattedUploadSize})
        </>
      )}
    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                            LIST OF FILE UPLOADS                            */
/* -------------------------------------------------------------------------- */

const FilesUploadPreview = ({ theme, value = null, allowCollapsing, limit, placeholder }) => {
  /* --------------------------- TEMPORARY SOLUTION --------------------------- */

  // In future this callback should be created outside of this file and passed as a prop
  const formId = useFormId();

  const onRequestDownloadUrl = async (uploadId) => {
    const payload = await getSubmissionFileReducer(formId)({ file: uploadId });
    if (payload.url) {
      return payload.url;
    } else {
      return null;
    }
  };

  /* ------------------------ TEMPORARY SOLUTION - END ------------------------ */

  if (value && !Array.isArray(value)) value = [value];

  if (!value?.length) {
    return <EmptyPreview placeholder={placeholder} />;
  } else {
    return (
      <div className={[styles.filesUploadPreview, styles[`theme-${theme}`]].join(' ')}>
        <Expandible
          data={value}
          allowCollapsing={allowCollapsing}
          limit={limit}
          renderData={(data) => (
            <div className={styles.uploadsList}>
              {data.map((upload) => {
                return (
                  <UploadPreview
                    key={upload._id}
                    uploadId={upload._id}
                    uploadName={upload.name}
                    uploadFailed={upload.hidden}
                    uploadSizeInBytes={Number(upload.size) * 1000000}
                    onRequestDownloadUrl={onRequestDownloadUrl}
                  />
                );
              })}
            </div>
          )}
        />
      </div>
    );
  }
};

export default FilesUploadPreview;
