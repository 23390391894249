import styles from './Slate.module.css';

import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react'
import { Editor, Transforms, Range, createEditor } from 'slate';
import ReactDOM from 'react-dom'
import isUrl from 'is-url';
import isHtml from 'is-html';
import { withHistory } from 'slate-history';
import { jsx } from 'slate-hyperscript';
import { Slate, Editable, ReactEditor, withReact, useSlate } from 'slate-react';
import isHotkey from 'is-hotkey'
import isJsonString from 'helpers/isJsonString.js';
import getFieldLabel from 'helpers/getFieldLabel.js';

const fieldIcons = {
  checkbox: require('assets/images/fields-v2/checkbox.svg'),
  dropdown: require('assets/images/fields-v2/dropdown.svg'),
  longText: require('assets/images/fields-v2/longText.svg'),
  radio: require('assets/images/fields-v2/radio.svg'),
  shortText: require('assets/images/fields-v2/shortText.svg'),
  shortTextemail: require('assets/images/fields-v2/email.svg'),
  shortTextnumber: require('assets/images/fields-v2/number.svg'),
  shortTextphone: require('assets/images/fields-v2/phone.svg'),
  scale: require('assets/images/fields-v2/scale.svg'),
  datetime: require('assets/images/fields-v2/calendar.svg'),

  variable: require('assets/images/variable.svg'),
  urlParam: require('assets/images/urlParam.svg')
};

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline'
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const Button = React.forwardRef(({ className, active, reversed, icon, ...props }, ref) => (
  <span {...props}
    data-not-outside={true}
    ref={ref}
    className={[styles.button, active ? styles.active : '', styles[icon]].join(' ')}
  />
));

const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
}

const withMentions = editor => {
  const { isInline, isVoid } = editor

  editor.isInline = element => {
    return element.type === 'mention' ? true : isInline(element);
  }

  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element);
  }

  return editor
}

const insertMention = (editor, character) => {
  const mention = {
    type: 'mention', _id: character._id, key: character.label, origin: (() => {
      if (character.type === 'urlParam') return 'urlParams';
      if (character.type === 'variable') return 'calculations';

      return 'field';
    })(), children: [{ text: '' }]
  };

  console.log(mention);

  Transforms.insertNodes(editor, mention);
}

const Element = (props) => {
  const { attributes, children, element, menu } = props;

  if (!menu && element.type !== 'mention') return <span {...attributes}>{children}</span>;

  switch (element.type) {
    case 'mention':
      return <MentionElement {...props} />;
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'paragraph':
      return <p {...attributes}>{children}</p>;
    case 'link':
      return <a {...attributes} href={element.url}>{children}</a>;
    default:
      return <span {...attributes}>{children}</span>;
  }
}

const MentionElement = ({ attributes, children, element, data }) => {
  const field = data.find((f) => f._id === element._id);

  if (!field) return <></>;

  const label = getFieldLabel(field.label);

  if (!label) return <></>;

  return (
    <span {...attributes} contentEditable={false} className={styles.mentionElement}>
      {fieldIcons[`${field.type}${field.format || ''}`] && <span className={styles.image}>
        <img src={fieldIcons[`${field.type}${field.format || ''}`]} alt="" />
      </span>}
      <span className={styles.label}>@{label}{children}</span>
    </span>
  )
}

const getInitialValue = (value) => {
  value = JSON.parse(value);

  if (Array.isArray(value) && value.length === 0) {
    return deserializeString('');
  }

  return value;
}

const deserialize = (value) => {
  if (!isHtml(value)) return deserializeString(value);

  let document = new DOMParser().parseFromString(value, 'text/html');
  const childs = document.body.childNodes;

  for (let child of childs) {
    if (child.nodeType === 3) {
      document = new DOMParser().parseFromString(`<p>${value}</p>`, 'text/html');

      break;
    }
  }

  return deserializeHtml(document.body);
};

const deserializeString = (value) => {
  return [{
    type: 'paragraph',
    children: [{ text: value }],
  }];
};

const ELEMENT_TAGS = {
  A: el => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-five' }),
  H6: () => ({ type: 'heading-six' }),
  IMG: el => ({ type: 'image', url: el.getAttribute('src') }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' }),
};

const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

const deserializeHtml = (el) => {
  if (el.nodeType === 3) {
    if ((el.textContent.match(/\n/g) || []).length !== 0) return null;

    return el.textContent
  } else if (el.nodeType !== 1) {
    return null;
  }

  const { nodeName } = el;
  let parent = el;

  if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
    parent = el.childNodes[0]
  }

  let children = Array.from(parent.childNodes).map(deserializeHtml).flat();

  if (children.length === 0) return jsx('fragment', {}, children);
  if (el.nodeName === 'BODY') return jsx('fragment', {}, children);

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);

    return jsx('element', attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);

    return children.map((child) => jsx('text', attrs, child))
  }

  return jsx('fragment', {});
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, { match: n => n.type === 'link' })
  return !!link
}

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'link' })
}

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  })

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const withLinks = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();

  return (
    <Button icon={icon} active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }} />
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();

  return (
    <Button icon={icon} active={isMarkActive(editor, format)}
      onMouseDown={(e) => {
        e.preventDefault()
        toggleMark(editor, format)
      }} />
  )
}

const LinkButton = () => {
  const editor = useSlate()
  return (
    <Button icon="link" active={isLinkActive(editor)}
      onMouseDown={event => {
        event.preventDefault()
        const url = window.prompt('Enter the URL of the link:')
        if (!url) return;
        insertLink(editor, url)
      }} />
  )
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const HoveringToolbar = () => {
  const ref = useRef()
  const editor = useSlate()

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) return;

    if (!selection || !ReactEditor.isFocused(editor) || Range.isCollapsed(selection) || Editor.string(editor, selection) === '') {
      el.removeAttribute('style');
      return;
    }

    const domSelection = window.getSelection();
    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();

    el.style.opacity = 1;
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;
  })

  return (
    <Portal>
      <div ref={ref} data-not-outside={true} className={styles.tooltipMenu}>
        <MarkButton format="bold" icon="formatBold" />
        <MarkButton format="italic" icon="formatItalic" />
        <MarkButton format="underline" icon="formatUnderlined" />
        <LinkButton />
      </div>
    </Portal>
  )
}

export const MentionsInput = ({ initialValue, initialValueKey, data, menu, style, disabled, placeholder, onChange }) => {
  const ref = useRef();
  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const renderElement = useCallback(props => <Element {...props} data={data} menu={menu} />, [data]);
  const editor = useMemo(() => withLinks(withMentions(withReact(withHistory(createEditor())))), []);
  const [value, setValue] = useState(isJsonString(initialValue) ? getInitialValue(initialValue) : deserialize(initialValue || ''));

  const chars = data.filter((c) => {
    const label = getFieldLabel(c.label);

    if (!label) return true;

    return label.toLowerCase().replace(/_/g, '').trim().startsWith(search.toLowerCase());
  }).slice(0, 8);

  const onKeyDown = useCallback((event) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();

        toggleMark(editor, HOTKEYS[hotkey]);
      }
    }

    if (target) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          const prevIndex = index >= chars.length - 1 ? 0 : index + 1;
          setIndex(prevIndex);
          break;
        case 'ArrowUp':
          event.preventDefault();
          const nextIndex = index <= 0 ? chars.length - 1 : index - 1;
          setIndex(nextIndex);
          break;
        case 'Tab':
        case 'Enter':
          event.preventDefault();
          Transforms.select(editor, target);
          insertMention(editor, chars[index]);
          Transforms.move(editor);
          setTarget(null);
          break;
        case 'Escape':
          event.preventDefault();
          setTarget(null);
          break;
        default:
      }
    }
  }, [index, search, target]);

  useEffect(() => {
    setValue(isJsonString(initialValue) ? getInitialValue(initialValue) : deserialize(initialValue || ''));
  }, [initialValueKey]);

  useEffect(() => {
    if (target && chars.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();

      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [chars.length, editor, index, search, target]);

  return (
    <>
      <Slate editor={editor}
        value={value}
        onChange={(value) => {
          const { selection } = editor;

          setValue(value);
          onChange(JSON.stringify(value));

          if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection);

            const _wordBefore = Editor.before(editor, start, { unit: 'character' });
            const _before = _wordBefore && Editor.before(editor, _wordBefore);
            const _beforeRange = _before && Editor.range(editor, _before, start);
            const _beforeText = _beforeRange && Editor.string(editor, _beforeRange);

            const wordBefore = Editor.before(editor, start, { unit: 'word' });
            const before = wordBefore && Editor.before(editor, wordBefore);
            let beforeRange = before && Editor.range(editor, before, start);
            const beforeText = beforeRange && Editor.string(editor, beforeRange);
            let beforeMatch = beforeText && beforeText.match(/^@(\w*)$/);
            const after = Editor.after(editor, start);
            const afterRange = Editor.range(editor, start, after);
            const afterText = Editor.string(editor, afterRange);
            const afterMatch = afterText.match(/^(\s|$)/);

            if (_beforeText && _beforeText.slice(-1) === '@') {
              beforeMatch = ['@', ''];
              beforeRange = _beforeRange;
            }

            if (beforeMatch && afterMatch) {
              setTarget(beforeRange);
              setSearch(beforeMatch[1]);
              setIndex(0);

              return;
            }
          }

          setTarget(null);
        }}>
        {menu === 'static' && <div className={styles.staticMenu}>
          <MarkButton format="bold" icon="formatBold" />
          <MarkButton format="italic" icon="formatItalic" />
          <MarkButton format="underline" icon="formatUnderlined" />

          <BlockButton format="numbered-list" icon="numberedList" />
          <BlockButton format="bulleted-list" icon="bulletedList" />

          <LinkButton />
        </div>}
        {menu === 'tooltip' && <HoveringToolbar />}
        <Editable
          renderElement={renderElement}
          onKeyDown={onKeyDown}
          renderLeaf={renderLeaf}
          style={style}
          className={[styles.editable, menu === 'static' ? styles.withBar : '', disabled ? styles.disabled : ''].join(' ')}
          placeholder={placeholder} />
        {target && chars.length > 0 && (
          <Portal>
            <div ref={ref} className={styles.dropdown} data-not-outside={true}>
              {chars.map((char, i) => {
                const label = getFieldLabel(char.label);

                if (!label) return null;

                return <div key={char._id} className={styles.item} data-not-outside={true} style={{
                  background: i === index ? 'rgba(0, 100, 246, 0.05)' : 'transparent',
                }} onMouseDown={(e) => {
                  e.preventDefault();

                  Transforms.select(editor, target);
                  insertMention(editor, chars[i]);
                  setTarget(null);
                }}>
                  {fieldIcons[`${char.type}${char.format || ''}`] && <span data-not-outside={true}>
                    <img src={fieldIcons[`${char.type}${char.format || ''}`]} alt="" />
                  </span>}
                  <p data-not-outside={true}>{label}</p>
                </div>;
              })}
            </div>
          </Portal>
        )}
      </Slate>
    </>
  );
};
