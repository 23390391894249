import styles from './Sortful.module.css';

import React, { useMemo, useCallback, useContext, useState, useRef, useEffect } from 'react';
import { Item, List, DragHandleComponent } from 'react-sortful';

import { useTimeout } from 'helpers';

/*
  IMPORTANT NOTES ABOUT USAGE:
  - Be careful with 'position: relative' in direct items.
    It will cause some nasty hard to track dropline placement bugs in nested lists.
*/

/* -------------------------------------------------------------------------- */
/*                                  CONTEXTS                                  */
/* -------------------------------------------------------------------------- */

import { Context as ReactSortfulGroupContext } from 'react-sortful/lib/groups/context';
import { Context as ReactSortfulItemContext } from 'react-sortful/lib/item/context';
import { Context as ReactSortfulListContext } from 'react-sortful/lib/list/context';

// This context is used to handle items updates during dragging,
// at first there are no changes, but after some timeout styling will be updated through context flag
const SortfulListContext = React.createContext();

/* -------------------------------------------------------------------------- */
/*                                   CONFIG                                   */
/* -------------------------------------------------------------------------- */

const DEFAULT_SORTING_DELAY_MS = 200;

/* -------------------------------------------------------------------------- */
/*                              DEFAULT RENDERERS                             */
/* -------------------------------------------------------------------------- */

const renderDropLineElement = (injectedProps) => (
  <div ref={injectedProps.ref} className={[styles.dropLine, styles.horizontal].join(' ')} style={injectedProps.style} />
);

/* -------------------------------------------------------------------------- */
/*                            SORTED ITEM COMPONENT                           */
/* -------------------------------------------------------------------------- */

// Uses context co compute some data about items with given identifers
// and provides data as props in children render prop.
const SortfulItemContextHOC = ({ identifier, children }) => {
  const { draggedItemIdentifier } = useContext(SortfulListContext);

  if (!identifier) throw new Error('DEBUG: missing identifier.');

  const isDragged = draggedItemIdentifier === identifier;

  return useMemo(() => {
    if (typeof children === 'function') {
      return children({ isDragged });
    } else {
      return children;
    }
  }, [isDragged, children]);
};

/* -------------------------------------------------------------------------- */
/*                                 DRAG HANDLE                                */
/* -------------------------------------------------------------------------- */

const SortfulDragHandle = ({ className = '', ...props }) => {
  return <DragHandleComponent className={[styles.dragHandle, className].join(' ')} {...props} />;
};

/* -------------------------------------------------------------------------- */
/*                      WRAPPER AROUND REACT-SORTFUL ITEM                     */
/* -------------------------------------------------------------------------- */

const SortfulItem = React.memo(({ identifier, children, ...rest }) => {
  // IMPORTANT:
  // Even though this HOC doesn't do anything in this content,
  // it's presence is require. Because it's used both here,
  // and during placeholder rendering, react will not mount/unmount when not needed.
  // Thanks to that onClick on item content works as expected.
  return (
    <Item identifier={identifier} {...rest}>
      <SortfulItemContextHOC identifier={identifier}>{children}</SortfulItemContextHOC>
    </Item>
  );
});

/* -------------------------------------------------------------------------- */
/*                              HELPER COMPONENTS                             */
/* -------------------------------------------------------------------------- */

const ReactSortfulContextCapturer = ({ contextStorageRef }) => {
  const context = useContext(ReactSortfulListContext);
  contextStorageRef.current = context;
  return null;
};

/* -------------------------------------------------------------------------- */
/*                      WRAPPER AROUND REACT-SORTFUL LIST                     */
/* -------------------------------------------------------------------------- */

const SortfulList = React.memo(
  ({
    className,
    children,
    isDisabled = false,
    delay = DEFAULT_SORTING_DELAY_MS,

    renderDropLine = renderDropLineElement,
    renderSorted,

    onDragStart,
    onDragEnd,

    ...rest
  }) => {
    /* ---------------------------------- STATE --------------------------------- */

    const setDraggingTimeout = useTimeout();

    // Sorting state
    const [draggedItemIdentifier, setDraggedItemIdentifier] = useState(false);
    const draggedItemIdentifierRef = useRef(draggedItemIdentifier);
    const isDragging = Boolean(draggedItemIdentifier);

    const lastMouseClickTypeRef = useRef(null);

    const contextValue = useMemo(() => ({ draggedItemIdentifier }), [draggedItemIdentifier]);

    /* ------------------------ CAPTURED PACKAGE CONTEXT ------------------------ */

    // ### REQUIRED FOR TEMPORARY STACKED GROUP HOVER FIX:
    const reactSortfulListContextRef = useRef(null);

    /* --------------------------- RENDERING FUNCTIONS -------------------------- */

    const renderItem = useCallback(
      ({ role, meta, injectedProps }) => {
        return (
          <SortfulItemContextHOC identifier={meta.identifier}>
            {({ isDragged }) => {
              const injectedStyle = injectedProps?.style;

              // Width and height will be only injected for ghost.
              // Implementation of renderSorted is responsible for rendering items
              // so that the height is consistent.
              // (it's a workaround for bad calculation of height in nested items in stacked mode)
              if (role !== 'ghost') {
                delete injectedStyle.height;
                delete injectedStyle.width;
              }

              if (!renderSorted) throw new Error('DEBUG: missing sorted renderer.');

              const style = { ...injectedStyle };

              if (isDragged) style.cursor = 'grab';
              if (role === 'ghost' && !isDragged) {
                return null;
              }

              // Used for conditional styling
              const sortingRole = role === 'placeholder' && !isDragged ? 'item' : role;

              // Rendering function is used instead of a component to allow render customization
              // without unnecessary unmount & mount
              return renderSorted({
                role: sortingRole,
                meta,
                injectedProps: {
                  className: styles.sortedItem,
                  style,
                  'data-sortful-role': sortingRole
                }
              });
            }}
          </SortfulItemContextHOC>
        );
      },
      [renderSorted]
    );

    const renderGhostItem = useCallback(
      (meta) =>
        renderItem({
          role: 'ghost',
          meta
        }),
      [renderItem]
    );

    const renderStackedGroupItem = useCallback(
      (injectedProps, meta) =>
        renderItem({
          role: 'stacked',
          meta,
          injectedProps
        }),
      [renderItem]
    );

    const renderPlaceholderItem = useCallback(
      (injectedProps, meta) =>
        renderItem({
          role: 'placeholder',
          meta,
          injectedProps
        }),
      [renderItem]
    );

    const handleRenderDropLine = useCallback(
      ({ style, ref }) => renderDropLine({ className: styles.dropLine, style, ref }),

      [renderDropLine]
    );

    /* ----------------------------- EVENT HANDLERS ----------------------------- */

    // Detect what mouse button was used for dragging
    useEffect(() => {
      const clickListener = (e) => {
        lastMouseClickTypeRef.current = e.which === 3 ? 'right' : 'other';
      };
      window.document.addEventListener('mousedown', clickListener);
      return () => {
        window.document.removeEventListener('mousedown', clickListener);
      };
    }, []);

    const handleDragStart = useCallback(
      (meta) => {
        setDraggingTimeout(() => {
          // Prevents dragging with right mouse button
          if (!isDisabled && lastMouseClickTypeRef.current !== 'right') {
            draggedItemIdentifierRef.current = meta.identifier;
            setDraggedItemIdentifier(meta.identifier);

            if (onDragStart) onDragStart(meta);
          }
        }, delay);
      },
      [onDragStart, isDisabled, delay, setDraggingTimeout]
    );

    const handleDragEnd = useCallback(
      (meta) => {
        // ### TEMPORARY STACKED GROUP HOVER FIX:
        // (react-sortful should return a result like this when stackedGroupIdentifier is not undefined)
        const { stackedGroupIdentifier } = reactSortfulListContextRef.current;
        if (stackedGroupIdentifier) {
          meta.nextGroupIdentifier = stackedGroupIdentifier;
          meta.nextIndex = undefined;
        }

        const wasInvokedAfterTimeout = Boolean(draggedItemIdentifierRef.current);

        draggedItemIdentifierRef.current = null;
        setDraggedItemIdentifier(null);
        setDraggingTimeout(null);

        // Prevents any updates before timeout
        if (wasInvokedAfterTimeout) {
          if (onDragEnd) onDragEnd(meta);
        }
      },
      [setDraggingTimeout, onDragEnd]
    );

    /* ----------------------------------- JSX ---------------------------------- */

    return (
      <SortfulListContext.Provider value={contextValue}>
        <List
          {...rest}
          className={[className, styles.list, isDragging ? styles.dragging : '', !isDisabled ? styles.enabled : ''].join(' ')}
          isDisabled={isDisabled}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          // Rendering
          renderDropLine={handleRenderDropLine}
          renderGhost={renderGhostItem}
          renderPlaceholder={renderPlaceholderItem}
          renderStackedGroup={renderStackedGroupItem}>
          {/* ### REQUIRED FOR TEMPORARY STACKED GROUP HOVER FIX */}
          <ReactSortfulContextCapturer contextStorageRef={reactSortfulListContextRef} />
          {children}
        </List>
      </SortfulListContext.Provider>
    );
  }
);

/* -------------------------------------------------------------------------- */
/*                      REACT-SORTFUL IMPROVED COMPONENTS                     */
/* -------------------------------------------------------------------------- */

const Sortful = {
  List: SortfulList,
  Item: SortfulItem,
  DragHandle: SortfulDragHandle
};

export default Sortful;
