import styles from './GridLayout.module.css';

import React from 'react';

const GridLayout = React.forwardRef(({ className, ...rest }, ref) => {
  return <div className={[className, styles.gridLayout].join(' ')} {...rest} ref={ref} />;
});

GridLayout.Top = React.forwardRef(({ className, ...rest }, ref) => {
  return <div className={[className, styles.top].join(' ')} {...rest} ref={ref} />;
});
GridLayout.Left = React.forwardRef(({ className, ...rest }, ref) => {
  return <div className={[className, styles.left].join(' ')} {...rest} ref={ref} />;
});
GridLayout.Middle = React.forwardRef(({ className, ...rest }, ref) => {
  return <div className={[className, styles.middle].join(' ')} {...rest} ref={ref} />;
});
GridLayout.Right = React.forwardRef(({ className, ...rest }, ref) => {
  return <div className={[className, styles.right].join(' ')} {...rest} ref={ref} />;
});
GridLayout.Bottom = React.forwardRef(({ className, ...rest }, ref) => {
  return <div className={[className, styles.bottom].join(' ')} {...rest} ref={ref} />;
});

export default GridLayout;
