import { useRef, useCallback, useEffect } from 'react';

// For quickly adding timeouts with cleanup and canceling not called timeout on new timeout
const useTimeout = () => {
  const timeoutRef = useRef(null);

  // Cleanups after timeout and during unmount
  const cleanup = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  useEffect(
    () => {
      return cleanup;
    },
    // eslint-disable-next-line
    []
  );

  // Function for setting timeout
  const set = useCallback(
    (callback, time) => {
      cleanup();
      if (callback) {
        timeoutRef.current = setTimeout(() => {
          callback();
          cleanup();
        }, time);
      }
    },
    [cleanup]
  );

  return set;
};

export default useTimeout;
