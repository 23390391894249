const services = [
  {
    _id: 'paypal',
    name: 'PayPal',
    description: 'Collect payments with PayPal',
    icon: require('assets/images/services/paypal.png')
  },
  {
    _id: 'stripe',
    name: 'Stripe',
    description: 'Collect payments with Stripe',
    icon: require('assets/images/services/stripe.png')
  },
  {
    _id: 'mollie',
    name: 'Mollie',
    description: 'Collect payments with Mollie',
    icon: require('assets/images/services/mollie.png')
  },
  {
    _id: 'square',
    name: 'Square',
    description: 'Collect payments with Square',
    icon: require('assets/images/services/square.png')
  }
];

export default services;
