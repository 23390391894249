// Billing plans in order of price
const plans = {
  personal: {
    quality: 1,
    name: 'Personal',
    color: 'blue',
    colorHex: '#2675D1'
  },
  starter: {
    quality: 2,
    name: 'Starter',
    color: 'purple',
    colorHex: '#9061F9'
  },
  pro: {
    quality: 3,
    name: 'Pro',
    color: 'orange',
    colorHex: '#FF623D'
  },
  trial: {
    quality: 3,
    name: 'Trial',
    color: 'gray',
    colorHex: '#807E7C'
  },
  pro2: {
    quality: 4,
    name: 'Pro',
    color: 'orange',
    colorHex: '#FF623D'
  }
};

export default plans;
