import styles from './SortingMatrix.module.css';

import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Select as SelectComponent } from '../Select/Select';
import { Button } from '../Button/Button';

// Config
import DIRECTION_OPTIONS_BY_DATA_TYPE from './directionOptionsByDataType';

const SUPPORTED_DATA_TYPES = Object.keys(DIRECTION_OPTIONS_BY_DATA_TYPE);

// Select with fixed positioning
const Select = (props) => <SelectComponent menuPosition="fixed" menuPlacement="auto" menuPortalTarget={document.body} {...props} />;

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

const getDirectionOptionsByType = (type) => {
  if (process.env.NODE_ENV === 'development') {
    if (!SUPPORTED_DATA_TYPES.includes(type)) {
      throw new Error('DEBUG: unsupported type ' + type);
    }
  }

  return DIRECTION_OPTIONS_BY_DATA_TYPE[type];
};

/* -------------------------------------------------------------------------- */
/*                                ROW COMPONENT                               */
/* -------------------------------------------------------------------------- */

const SortingRow = React.memo(
  ({
    // Data
    index,
    value: currentValue,
    onRowChange,
    onRowRemoval,
    // Computed config
    columnOptions,
    columnsById
  }) => {
    /* ---------------------------------- DATA ---------------------------------- */

    const columnData = columnsById[currentValue.column];
    const directionOptions = columnData?.sortsAs ? getDirectionOptionsByType(columnData?.sortsAs) : null;

    /* ----------------------------- EVENT HANDLERS ----------------------------- */

    const handleRemoval = () => onRowRemoval(index);

    const handleChange = useCallback(
      (property, nextValue) => {
        const changes = { [property]: nextValue === '' ? null : nextValue ?? null };

        // Resets direction and value when column changes (direction options may change)
        if (property === 'column') {
          changes.direction = null;
        }

        onRowChange({ ...currentValue, ...changes }, index);
      },
      [index, currentValue, onRowChange]
    );

    useEffect(() => {
      // Column like this don't exist in columns options
      // Maybe it was removed.
      if (currentValue.column && !columnData) {
        handleChange('column', null);
      }
    }, [columnData, currentValue.column, handleChange]);

    /* ----------------------------------- JSX ---------------------------------- */
    return (
      <div className={styles.sortingRow}>
        <div className={styles.columnInput}>
          <div className={styles.label}>{!index ? 'Sort by' : 'And then by'}</div>
          <Select
            placeholder="Choose column"
            options={columnOptions}
            value={currentValue.column}
            onChange={(data) => handleChange('column', data.value)}
          />
        </div>
        <div className={styles.label}>{directionOptions ? 'from' : null}</div>
        <div className={styles.directionInput}>
          {directionOptions && (
            <>
              <Select
                placeholder="Choose direction"
                options={directionOptions}
                value={currentValue.direction}
                onChange={(data) => handleChange('direction', data.value)}
              />
            </>
          )}
        </div>
        <div className={styles.deleteButton}>
          <Button theme="transparent" icon="close" onClick={handleRemoval} />
        </div>
      </div>
    );
  }
);

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const SortingMatrix = React.memo(
  ({
    className = '',

    placeholder,

    columns = [], // [ {id: string, label: string, sortsAs: string, disabled: boolean}, ...]
    value: values = [], // [ {column: string, direction: string}, ...]

    onChange
  }) => {
    /* ---------------------------------- STATE --------------------------------- */

    // Local controlled state that allows better re-rendering optimizations
    const [localValues, setLocalValues] = useState(values);
    useEffect(() => {
      setLocalValues(values);
    }, [values]);

    const { columnsOptions: columnOptions, columnsById } = useMemo(() => {
      const columnsOptions = columns.map(({ id, label, disabled }) => ({ value: id, label, disabled }));

      const columnsById = {};
      for (const column of columns) {
        columnsById[column.id] = column;
      }
      return { columnsOptions, columnsById };
    }, [columns]);

    /* ----------------------------- EVENT HANDLERS ----------------------------- */

    const handleRowChange = useCallback(
      (nextRowValue, rowIndex) => {
        let nextValues = null;

        setLocalValues((localValues) => {
          nextValues = [...localValues];
          nextValues[rowIndex] = nextRowValue;
          return nextValues;
        });

        onChange(nextValues);
      },
      [onChange]
    );

    const handleRowRemoval = useCallback(
      (rowIndex) => {
        let nextValues = null;

        setLocalValues((localValues) => {
          nextValues = [...localValues];
          nextValues.splice(rowIndex, 1);
          return nextValues;
        });

        onChange(nextValues);
      },
      [onChange]
    );

    /* ----------------------------------- JSX ---------------------------------- */

    let rowsJsx = null;
    if (localValues?.length) {
      rowsJsx = localValues.map((value, i) => {
        return (
          <SortingRow
            key={'' + i + value.column}
            index={i}
            value={value}
            onRowChange={handleRowChange}
            onRowRemoval={handleRowRemoval}
            columnOptions={columnOptions}
            columnsById={columnsById}
          />
        );
      });
    }

    return (
      <div className={[className, styles.sortingMatrix].join(' ')}>
        {rowsJsx ? rowsJsx : <span className={styles.placeholder}>{placeholder}</span>}
      </div>
    );
  }
);

SortingMatrix.DIRECTION_OPTIONS_BY_DATA_TYPE = DIRECTION_OPTIONS_BY_DATA_TYPE;
SortingMatrix.SUPPORTED_DATA_TYPES = SUPPORTED_DATA_TYPES;

export default SortingMatrix;
