import axios from 'axios';
import _remove from 'lodash/remove';

import config from 'config.js';

// Constants
const RESET_ENDPOINT_STATE = '_connections/RESET_ENDPOINT_STATE';

const CREATE_CONNECTION_REQUEST = '_connections/CREATE_CONNECTION_REQUEST';
const CREATE_CONNECTION_SUCCESS = '_connections/CREATE_CONNECTION_SUCCESS';
const CREATE_CONNECTION_FAILURE = '_connections/CREATE_CONNECTION_FAILURE';

const GET_CONNECTIONS_REQUEST = '_connections/GET_CONNECTIONS_REQUEST';
const GET_CONNECTIONS_SUCCESS = '_connections/GET_CONNECTIONS_SUCCESS';
const GET_CONNECTIONS_FAILURE = '_connections/GET_CONNECTIONS_FAILURE';

const UPDATE_CONNECTION_REQUEST = '_connections/UPDATE_CONNECTION_REQUEST';
const UPDATE_CONNECTION_SUCCESS = '_connections/UPDATE_CONNECTION_SUCCESS';
const UPDATE_CONNECTION_FAILURE = '_connections/UPDATE_CONNECTION_FAILURE';

const DELETE_CONNECTION_REQUEST = '_connections/DELETE_CONNECTION_REQUEST';
const DELETE_CONNECTION_SUCCESS = '_connections/DELETE_CONNECTION_SUCCESS';
const DELETE_CONNECTION_FAILURE = '_connections/DELETE_CONNECTION_FAILURE';

const UPDATE_CONNECTION_WEBHOOK_REQUEST = '_connections/UPDATE_CONNECTION_WEBHOOK_REQUEST';
const UPDATE_CONNECTION_WEBHOOK_SUCCESS = '_connections/UPDATE_CONNECTION_WEBHOOK_SUCCESS';
const UPDATE_CONNECTION_WEBHOOK_FAILURE = '_connections/UPDATE_CONNECTION_WEBHOOK_FAILURE';

const GET_CONNECTIONS_WEBHOOK_REQUEST = '_connections/GET_CONNECTIONS_WEBHOOK_REQUEST';
const GET_CONNECTIONS_WEBHOOK_SUCCESS = '_connections/GET_CONNECTIONS_WEBHOOK_SUCCESS';
const GET_CONNECTIONS_WEBHOOK_FAILURE = '_connections/GET_CONNECTIONS_WEBHOOK_FAILURE';

const DELETE_CONNECTION_WEBHOOK_REQUEST = '_connections/DELETE_CONNECTION_WEBHOOK_REQUEST';
const DELETE_CONNECTION_WEBHOOK_SUCCESS = '_connections/DELETE_CONNECTION_WEBHOOK_SUCCESS';
const DELETE_CONNECTION_WEBHOOK_FAILURE = '_connections/DELETE_CONNECTION_WEBHOOK_FAILURE';

const CREATE_CONNECTION_WEBHOOK_REQUEST = '_connections/CREATE_CONNECTION_WEBHOOK_REQUEST';
const CREATE_CONNECTION_WEBHOOK_SUCCESS = '_connections/CREATE_CONNECTION_WEBHOOK_SUCCESS';
const CREATE_CONNECTION_WEBHOOK_FAILURE = '_connections/CREATE_CONNECTION_WEBHOOK_FAILURE';

const endpoitInitialStatus = {
  loading: false,
  error: false,
  success: false
};

// Initiual State
const initialState = {
  connections: [],

  createConnection: { ...endpoitInitialStatus },
  getConnections: { ...endpoitInitialStatus },
  editConnection: { ...endpoitInitialStatus },
  deleteConnection: { ...endpoitInitialStatus },
  createConnectionWebhook: { ...endpoitInitialStatus },
  editConnectionWebhook: { ...endpoitInitialStatus },
  deleteConnectionWebhook: { ...endpoitInitialStatus },
  getConnectionWebhook: { ...endpoitInitialStatus }
};

const getEndpointStatus = (type) => {
  const types = {
    request: {
      loading: true,
      error: false,
      success: false
    },
    success: {
      loading: false,
      error: false,
      success: true
    },
    failure: {
      loading: false,
      error: true,
      success: false
    },
  };

  return types[type];
};

// Reducer
export default function reducer(state = JSON.parse(JSON.stringify(initialState)), action = {}) {
  // getConnections
  if (action.type === GET_CONNECTIONS_REQUEST) {
    state.getConnections = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === GET_CONNECTIONS_SUCCESS) {
    state.getConnections = getEndpointStatus('success');
    state.connections = [ ...action.payload ];

    return { ...state };
  }

  if (action.type === GET_CONNECTIONS_FAILURE) {
    state.getConnections = getEndpointStatus('failure');

    return { ...state };
  }

  // createConnection
  if (action.type === CREATE_CONNECTION_REQUEST) {
    state.createConnection = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === CREATE_CONNECTION_SUCCESS) {
    state.createConnection = getEndpointStatus('success');

    return { ...state };
  }

  if (action.type === CREATE_CONNECTION_FAILURE) {
    state.createConnection = getEndpointStatus('failure');

    return { ...state };
  }

  // editConnection
  if (action.type === UPDATE_CONNECTION_REQUEST) {
    state.editConnection = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === UPDATE_CONNECTION_SUCCESS) {
    state.editConnection = getEndpointStatus('success');

    return { ...state };
  }

  if (action.type === UPDATE_CONNECTION_FAILURE) {
    state.editConnection = getEndpointStatus('failure');

    return { ...state };
  }

  // deleteConnection
  if (action.type === DELETE_CONNECTION_REQUEST) {
    state.deleteConnection = getEndpointStatus('request');
    state.connections = _remove(state.connections, (connection) => connection._id !== action.payload.id);

    return { ...state };
  }

  if (action.type === DELETE_CONNECTION_SUCCESS) {
    state.deleteConnection = getEndpointStatus('success');

    return { ...state };
  }

  if (action.type === DELETE_CONNECTION_FAILURE) {
    state.deleteConnection = getEndpointStatus('failure');

    return { ...state };
  }

  // getConnectionWebhook
  if (action.type === GET_CONNECTIONS_WEBHOOK_REQUEST) {
    state.getConnectionWebhook = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === GET_CONNECTIONS_WEBHOOK_SUCCESS) {
    state.getConnectionWebhook = getEndpointStatus('success');

    return { ...state };
  }

  if (action.type === GET_CONNECTIONS_WEBHOOK_FAILURE) {
    state.getConnectionWebhook = getEndpointStatus('failure');

    return { ...state };
  }

  // createConnectionWebhook
  if (action.type === CREATE_CONNECTION_WEBHOOK_REQUEST) {
    state.createConnectionWebhook = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === CREATE_CONNECTION_WEBHOOK_SUCCESS) {
    state.createConnectionWebhook = getEndpointStatus('success');

    return { ...state };
  }

  if (action.type === CREATE_CONNECTION_WEBHOOK_FAILURE) {
    state.createConnectionWebhook = getEndpointStatus('failure');

    return { ...state };
  }

  // editConnectionWebhook
  if (action.type === UPDATE_CONNECTION_WEBHOOK_REQUEST) {
    state.editConnectionWebhook = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === UPDATE_CONNECTION_WEBHOOK_SUCCESS) {
    state.editConnectionWebhook = getEndpointStatus('success');

    return { ...state };
  }

  if (action.type === UPDATE_CONNECTION_WEBHOOK_FAILURE) {
    state.editConnectionWebhook = getEndpointStatus('failure');

    return { ...state };
  }

  // deleteConnectionWebhook
  if (action.type === DELETE_CONNECTION_WEBHOOK_REQUEST) {
    state.deleteConnectionWebhook = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === DELETE_CONNECTION_WEBHOOK_SUCCESS) {
    state.deleteConnectionWebhook = getEndpointStatus('success');

    return { ...state };
  }

  if (action.type === DELETE_CONNECTION_WEBHOOK_FAILURE) {
    state.deleteConnectionWebhook = getEndpointStatus('failure');

    return { ...state };
  }

  return state;
}

// Action Creators
export function resetEndpoitState(name) {
  return (dispatch) => {
    dispatch({ type: RESET_ENDPOINT_STATE, payload: { name } });
  };
}

export function createConnection(data) {
  const request = () => { return { type: CREATE_CONNECTION_REQUEST } };
  const success = (res) => { return { type: CREATE_CONNECTION_SUCCESS, payload: res } };
  const failure = () => { return { type: CREATE_CONNECTION_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.createConnection(state._users.token, data);

    if (response) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure());
    }
  };
}

export function editConnection(id, data) {
  const request = () => { return { type: UPDATE_CONNECTION_REQUEST } };
  const success = (_id, res) => { return { type: UPDATE_CONNECTION_SUCCESS, payload: { _id, ...res } } };
  const failure = () => { return { type: UPDATE_CONNECTION_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.editConnection(state._users.token, id, data);

    if (response) {
      return dispatch(success(id, response.data));
    } else {
      return dispatch(failure());
    }
  };
}

export function deleteConnection(id) {
  const request = (id) => { return { type: DELETE_CONNECTION_REQUEST, payload: { id }  } };
  const success = () => { return { type: DELETE_CONNECTION_SUCCESS} };
  const failure = () => { return { type: DELETE_CONNECTION_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request(id));

    const state = getState();
    const response = await services.deleteConnection(state._users.token, id);

    if (response) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure());
    }
  };
}

export function getConnections() {
  const request = () => { return { type: GET_CONNECTIONS_REQUEST } };
  const success = (res) => { return { type: GET_CONNECTIONS_SUCCESS, payload: res } };
  const failure = () => { return { type: GET_CONNECTIONS_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getConnections(state._users.token);

    if (response) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure());
    }
  };
}

export function createConnectionWebhook(connectionId) {
  const request = () => { return { type: CREATE_CONNECTION_WEBHOOK_REQUEST } };
  const success = (res) => { return { type: CREATE_CONNECTION_WEBHOOK_SUCCESS, payload: res } };
  const failure = () => { return { type: CREATE_CONNECTION_WEBHOOK_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.createConnectionWebhook(state._users.token, connectionId);

    if (response) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure());
    }
  };
}

export function editConnectionWebhook(connectionId, webhookId, data) {
  const request = () => { return { type: UPDATE_CONNECTION_WEBHOOK_REQUEST } };
  const success = (connectionId, webhookId, res) => { return { type: UPDATE_CONNECTION_WEBHOOK_SUCCESS, payload: { connectionId, webhookId, ...res } } };
  const failure = () => { return { type: UPDATE_CONNECTION_WEBHOOK_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.editConnectionWebhook(state._users.token, connectionId, webhookId, data);

    if (response) {
      return dispatch(success(connectionId, webhookId, response.data));
    } else {
      return dispatch(failure());
    }
  };
}

export function deleteConnectionWebhook(connectionId, webhookId) {
  const request = (connectionId, webhookId) => { return { type: DELETE_CONNECTION_WEBHOOK_REQUEST, payload: { connectionId, webhookId }  } };
  const success = () => { return { type: DELETE_CONNECTION_WEBHOOK_SUCCESS} };
  const failure = () => { return { type: DELETE_CONNECTION_WEBHOOK_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request(connectionId, webhookId));

    const state = getState();
    const response = await services.deleteConnectionWebhook(state._users.token, connectionId, webhookId);

    if (response) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure());
    }
  };
}

export function getConnectionWebhook(connectionId, webhookId) {
  const request = () => { return { type: GET_CONNECTIONS_WEBHOOK_REQUEST } };
  const success = (res) => { return { type: GET_CONNECTIONS_WEBHOOK_SUCCESS, payload: res } };
  const failure = () => { return { type: GET_CONNECTIONS_WEBHOOK_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getConnectionWebhook(state._users.token, connectionId, webhookId);

    if (response) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure());
    }
  };
}

const services = {
  getConnections: async (token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/connections`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  createConnection: async (token, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/connections`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  editConnection: async (token, id, data) => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${config.apiUrl}/connections/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  deleteConnection: async (token, id) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${config.apiUrl}/connections/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  getConnectionWebhook: async (token, connectionId, webhookId) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/connections/${connectionId}/webhook/${webhookId}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  createConnectionWebhook: async (token, connectionId, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/connections/${connectionId}/webhook`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  editConnectionWebhook: async (token, connectionId, webhookId, data) => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${config.apiUrl}/connections/${connectionId}/webhook/${webhookId}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  deleteConnectionWebhook: async (token, connectionId, webhookId) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${config.apiUrl}/connections/${connectionId}/webhook/${webhookId}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response;
    } catch (e) {
      return e.response;
    }
  }
};
