import styles from './UserAgentPreview.module.css';

import React from 'react';

import EmptyPreview from './EmptyPreview';

const UserAgentPreview = ({ theme, value = null, placeholder }) => {
  if (!value) {
    return <EmptyPreview theme={theme} placeholder={placeholder} />;
  } else {
    /*
        For now at least adds a new line after parenthesis to improve readability
        Could be greatly improved with this in the future:
        https://www.npmjs.com/package/ua-parser-js
    */
    const userAgent = value.replace(/\) /g, ')\n');
    return <span className={[styles.userAgentPreview, styles[`theme-${theme}`]].join(' ')}>{userAgent}</span>;
  }
};

export default UserAgentPreview;
