import React from 'react';

const INTRO_IMAGES = {
  dashboard_form_preview: require(`assets/images/intro/dashboard_form_preview.png`),
  dashboard_walkthroughs: require(`assets/images/intro/dashboard_walkthroughs.png`),

  builder_hello_world: require(`assets/images/intro/builder_hello_world.gif`),
  builder_users: require(`assets/images/intro/builder_users.png`),
  builder_form_label_dropdown: require(`assets/images/intro/builder_form_label_dropdown.png`),
  builder_add_field_dnd: require(`assets/images/intro/builder_add_field_dnd.gif`),
  builder_add_field_click: require(`assets/images/intro/builder_add_field_click.gif`),
  builder_fields_interactions: require(`assets/images/intro/builder_fields_interactions.gif`),
  builder_errors: require(`assets/images/intro/builder_errors.png`),
  builder_calculations: require(`assets/images/intro/builder_calculations.gif`),
  builder_logic: require(`assets/images/intro/builder_logic.gif`),
  builder_refer_to: require(`assets/images/intro/builder_refer_to.gif`),

  messaging_elements: require(`assets/images/intro/messaging_elements.png`),

  results_table: require(`assets/images/intro/results_table.png`),
  results_table_filters: require(`assets/images/intro/results_table_filters.png`),
  results_table_column_dropdown: require(`assets/images/intro/results_table_column_dropdown.png`),
  results_charts: require(`assets/images/intro/results_charts.png`),
  results_single_response: require(`assets/images/intro/results_single_response.png`),
  results_comments: require(`assets/images/intro/results_comments.png`),
  results_automated_charts: require(`assets/images/intro/results_automated_charts.png`),
  results_creating_charts: require(`assets/images/intro/results_creating_charts.png`)
};

const TutorialImg = ({ name }) => <img src={INTRO_IMAGES[name]} alt={name} />;

export default TutorialImg;
