import styles from './DropdownIcon.module.css';

import React from 'react';

import { Icon } from '../Icon/Icon';

const DropdownIcon = React.memo(
  React.forwardRef(({ open = false, onChange }, ref) => {
    return (
      <>
        <div
          className={[styles.dropdownIcon, open ? styles.open : ''].join(' ')}
          onClick={() => {
            onChange(!open);
          }}
          ref={ref}>
          <Icon id="dropdown-closed" />
        </div>
      </>
    );
  })
);

export default DropdownIcon;
