import ObjectID from 'bson-objectid';

import generateRequiredFieldData from './generateRequiredFieldData';

import { calculateFieldFamily } from 'helpers';

import _cloneDeep from 'lodash/cloneDeep';

const createUID = () => {
  return ObjectID().toHexString();
};

/* -------------------------------------------------------------------------- */
/*                                   CLONING                                  */
/* -------------------------------------------------------------------------- */

// Creates an array with cloned field and all cloned children.
// Every _id in field and children is replaced with brand new _id.
const cloneFieldWithoutCheckingForErrors = ({ fieldId, fields }) => {
  /* ------------------- DETECTS WHAT FIELD SHOULD BE COPIED ------------------ */

  const fieldFamilyIds = calculateFieldFamily({ fieldId, fields });

  if (!fieldFamilyIds.length) {
    throw new Error('DEBUG: Cloned field was not found.');
  }

  const fieldsToCopy = fieldFamilyIds.map((_id) => fields.find((f) => f._id === _id) || null);

  /* -------- CREATE ID MAPS (existing _id -> new _id in cloned fields) ------- */

  const __mappedFieldIds = new Map();

  for (const field of fieldsToCopy) {
    if (!field) continue;

    if (!field._id) {
      throw new Error('DEBUG: Cloned field _id id missing.');
    }

    __mappedFieldIds.set(field._id, createUID());
  }

  // Returns mapped _id or original _id if passed _id was not mapped
  // (only this function should be used for getting mapped _id as it has a fallback to original _id)
  const getMappedId = (_id) => {
    if (__mappedFieldIds.has(_id)) {
      return __mappedFieldIds.get(_id);
    } else {
      return _id;
    }
  };

  /* ------------------------- CLONES A FIELD SECTION ------------------------- */

  const clonedFields = [];

  // Coverts all properties into a default-like one for a new section of this architecture
  for (const field of fieldsToCopy) {
    const clonedField = generateRequiredFieldData();

    const processClonedFieldProp = (key, data) => {
      switch (key) {
        // ref is not needed anymore in new fields
        case 'ref':
          return undefined;

        // Returns mapped id of existing field
        case '_id':
          const mappedId = getMappedId(data);
          if (data === mappedId) {
            throw new Error('DEBUG: cloned field _id was not mapped to new _id.');
          }
          return mappedId;

        // Returns mapped section of existing field.
        case 'section':
          // Main field is moved to root.
          if (field._id === fieldId) {
            return 'root';
          }
          // Other fields should be inside a field that was mapped to new id.
          else {
            const mappedParentId = getMappedId(data);
            if (data === mappedParentId) {
              throw new Error('DEBUG: cloned child section _id was not mapped to new _id.');
            }
            return mappedParentId;
          }

        // Returns array of mapped children _ids
        case 'childrens':
          return data.map((childId) => {
            const mappedChildId = getMappedId(childId);
            if (childId === mappedChildId) {
              throw new Error('DEBUG: cloned field child _id was not mapped to new _id.');
            }
            return mappedChildId;
          });

        // Conditions are copied with new replaced ids and mapped ids of triggers
        case 'conditions':
          return data.map(({ ref, _id, triggerField, ...rest }) => {
            return {
              ...rest,
              _id: createUID(), // Creates brand new id
              triggerField: getMappedId(triggerField)
            };
          });

        // Calculation conditions are copied with new replaced ids
        case 'calculationConditions':
          return data.map(({ ref, _id, ...rest }) => {
            return {
              ...rest,
              _id: createUID() // Creates brand new id
            };
          });

        // Calculations are copied with new replaced ids
        case 'calculations':
          return data.map(({ ref, _id, ...rest }) => {
            return {
              ...rest,
              _id: createUID() // Creates brand new id
            };
          });

        // Other properties are copied as they are
        default:
          return data;
      }
    };

    for (const key in field) {
      const clonedProp = _cloneDeep(field[key]);
      const processedProp = processClonedFieldProp(key, clonedProp);

      if (typeof processedProp !== 'undefined') {
        clonedField[key] = processedProp;
      }
    }

    clonedFields.push(clonedField);
  }

  /* ------------------------------- VALIDATION ------------------------------- */

  let rootsFound = 0;
  for (const field of clonedFields) {
    if (field.section === 'root') rootsFound++;
  }

  if (rootsFound !== 1) {
    throw new Error('DEBUG: at this point there should be exactly one field in root section.');
  }

  if (clonedFields[0].section !== 'root') {
    throw new Error('DEBUG: main cloned field should be at the start (calculateFieldFamily helper probably has a bug)');
  }

  /* --------------------------- OPTIONAL VALIDATION -------------------------- */

  return clonedFields;
};

/* -------------------------------------------------------------------------- */
/*                          CLONE WITH ERROR CHECKING                         */
/* -------------------------------------------------------------------------- */

const cloneField = ({ fieldId, fields, calculationVariables = [], shouldBeVailid = false, shouldBeIsolated = false }) => {
  try {
    const clonedFields = cloneFieldWithoutCheckingForErrors({ fieldId, fields });

    /*
    if(shouldBeVailid){
        const suroundingFields = shouldBeIsolated ? clonedFields : fields;

        // TODO
        // Add error checking with methods from Builder during field clone.
        // will be useful for creating custom user prebuilt sections.
    }
    */

    return { success: true, clonedFields };
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(e);
    }

    return { success: false, error: e.message };
  }
};

export default cloneField;
