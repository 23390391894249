import styles from './LifeTime.module.css';

import React, { useState, useContext } from 'react';
import { Header, Button, Hr } from 'ui-components';
import { format } from 'date-fns';
import config from 'config.js';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { BrowserRouter as Redirect } from 'react-router-dom';

import { MainContext } from 'contexts/main.js';

import ContentHeader from '../Header.js';

import { ReactComponent as CheckmarkIcon } from 'assets/images/checkmark-circle.svg';
import ltdLogo from 'assets/images/ltd.png';

import UpdateLifeTimePlan from './panels/UpdateLifeTimePlan.js';

const initialOptions = {
  'client-id': config.paypalClientId,
  currency: 'USD'
};

const LifeTime = React.memo(() => {
  const { user } = useContext(MainContext);

  const [showUpdateLifeTimePlan, setShowUpdateLifeTimePlan] = useState(false);

  if (!user.lifeTime || !user.lifeTime.enabled) return <></>;
  if (!user.lifeTime.paid) return <Redirect to="/ltdf" />;

  return (
    <>
      <ContentHeader title={`A summary of your ${user.lifeTime.plan} LTDF Plan`} />

      <div className={styles.content}>
        <div className={styles.head}>
          <img className={styles.headerLogo} src={ltdLogo} alt="LTDFans" height="60px" style={{ margin: '0 0 30px 0' }} />
          {/* {!(user.lifeTime.plan === 'business' && user.lifeTime.users === 90) && (
            <Button theme="black" onClick={() => setShowUpdateLifeTimePlan(true)}>
              Upgrade your LTDF Plan
            </Button>
          )} */}
        </div>

        <ul className={styles.summary}>
          <li>
            <CheckmarkIcon />
            <span>Unlimited Forms</span>
            <p>Unlimited forms and surveys, no questions asked.</p>
          </li>
          <li>
            <CheckmarkIcon />
            <span>Unlimited Responses</span>
            <p>Remove all limits from the number of responses you collect.</p>
          </li>
          <li>
            <CheckmarkIcon />
            <span>
              Up to {user.plan.users} user{user.plan.users === 1 ? '' : 's'}
            </span>
            <p>
              Teamwork doesn't have to be expensive. Add up to {user.plan.users} user{user.plan.users === 1 ? '' : 's'} to work together!
            </p>
          </li>
          {user.plan.guests > 0 && (
            <li>
              <CheckmarkIcon />
              <span>
                Up to {user.plan.guests} guest{user.plan.guests === 1 ? '' : 's'}
              </span>
              <p>
                Add up to {user.plan.guests} guest{user.plan.guests === 1 ? '' : 's'} to work together!
              </p>
            </li>
          )}
          <li>
            <CheckmarkIcon />
            <span>{user.plan.filesStorage / 1000}GB Monthly Upload</span>
            <p>Collect up to {user.plan.filesStorage / 1000}GB Monthly in file uploads using your forms & surveys.</p>
          </li>
          <li>
            <CheckmarkIcon />
            <span>{user.plan.domains >= 1000 ? 'Unlimited' : `Up to ${user.plan.domains}`}Custom Domains</span>
            <p>Add your own domain to all of your forms and surveys and give your respondents a truly white-labelled experience.</p>
          </li>
          <li>
            <CheckmarkIcon />
            <span>All features included</span>
            <p>All existing & upcoming features are included, free of charge.</p>
          </li>
          <li>
            <CheckmarkIcon />
            <span>Priority Support</span>
            <p>Live-chat support from QuestionScout staff whenever you need it.</p>
          </li>
          <li>
            <CheckmarkIcon />
            <span>Branding Removal</span>
            <p>Remove all QuestionScout related branding on all of your forms and surveys.</p>
          </li>
          <li>
            <CheckmarkIcon />
            <span>Client Teamwork</span>
            <p>Work together with clients in a single account.</p>
          </li>
        </ul>

        <Header style={{ margin: '30px 0 15px 0' }}>Payments</Header>

        <ul className={styles.payments}>
          {user.lifeTime.orders.map((item) => {
            let amount = user.lifeTime.vatEu
              ? parseFloat(item.order.amount) - parseFloat(item.order.vatAmount)
              : parseFloat(item.order.amount);

            return (
              <li key={item._id}>
                <div className={styles.left}>
                  <div className={styles.amount}>${(amount || 0).toFixed(2)}</div>
                  <div className={styles.createdAt}>{format(new Date(item.order.createdAt), 'dd MMM yyyy hh:mma')}</div>
                </div>
                <div className={styles.right}>
                  <div className={styles.status}>{item.order.status}</div>
                  {item.order.version === 2 && <Button theme="link" href={`/invoice/${item._id}/${item.order.id}`} target="_blank">Invoice</Button>}
                </div>
              </li>
            );
          })}
        </ul>

        {user.lifeTime.version === 2 && (
          <Button
            className={styles.refund}
            theme="black-underline"
            icon="open-external"
            iconPlacement="right"
            href="https://form.questionscout.com/610adfdbdc75e54e465a930f?id">
            Cancel account & request refund
          </Button>
        )}
      </div>

      {showUpdateLifeTimePlan && <UpdateLifeTimePlan onClose={() => setShowUpdateLifeTimePlan(false)} />}
    </>
  );
});

export default LifeTime;
