import styles from './TutorialSkipper.module.css';

import React from 'react';

import { Button, ModalContent } from 'ui-components';

import walkthroughsImg from 'assets/images/intro/dashboard_walkthroughs.png';

const TutorialSkipper = React.memo(({ onRequestClose }) => {
  return (
    <ModalContent
      className={styles.tutorialSkipper}
      header="Wrapping up"
      buttons={
        <Button theme="black" onClick={onRequestClose}>
          Got it!
        </Button>
      }>
      <div className={styles.content}>
        <p>
          This tutorial and many others can be accessed at any time by clicking the <b>Walkthroughs</b> button at any time, directly from
          your dashboard.
        </p>
        <img src={walkthroughsImg} alt="walkthroughs" />
      </div>
    </ModalContent>
  );
});

export default TutorialSkipper;
