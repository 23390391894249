import styles from './YouLackPermission.module.css';

import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';

import { TabsHeader, Button, Avatar } from 'ui-components';

import { MainContext } from 'contexts';

const YouLackPermission = ({ className, onRequestClose }) => {
  const { users } = useContext(MainContext);

  const holder = users.find((u) => u.role === 'holder');
  const holderName = `${holder.firstName || ''} ${holder.lastName || ''}`.trim();

  const holderCard = (
    <div className={styles.holderCard}>
      <div className={styles.holderCardHeader}>Account holder:</div>
      <div className={styles.holderCardContent}>
        <Avatar className={styles.holderCardAvatar} user={holder} size="huge" />
        <div className={styles.holderCardAbout}>
          {holderName && <div>{holderName}</div>}
          <a href={`mailto: ${holder.email}`}>{holder.email}</a>
        </div>
      </div>
    </div>
  );

  return (
    <div className={[className, styles.youLackPermission].join(' ')}>
      <div className={styles.header}>
        <TabsHeader>You lack permissions to perform this action</TabsHeader>
      </div>
      <div className={styles.content}>
        <div className={styles.message}>Get in touch with your account holder or account administrator to grant you access.</div>
        {holderCard}
      </div>
      <div className={styles.buttons}>
        <Button theme="black" children="Close" onClick={onRequestClose} />
      </div>
    </div>
  );
};

export default YouLackPermission;
