import 'normalize.css';

import React, { useCallback, useState, useEffect, Suspense, lazy, useMemo } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import _debounce from 'lodash.debounce';
import _set from 'lodash/set';
import _isString from 'lodash/set';
import { useHistory } from 'react-router';
import deepIsEqual from 'fast-deep-equal';

import io from 'io.js';

import UserModel from 'models/UserModel.js';
import { MainContext } from 'contexts/main.js';

import { ActivityContextProvider } from 'activity';
import { IntegrationsProvider } from 'integrations';

import Profile from 'components/Profile/Profile.js';
import { Modal, SuspenseFallback, PopupsProvider } from 'ui-components';
import { YouLackPermission } from 'components/shared';

import ConnectionManager from './ConnectionManager';
import SomethingWentWrongBoundary from './SomethingWentWrongBoundary';

import { useSearchParams, asyncSocketAckEmit } from 'helpers';

import { TutorialsContextProvider } from 'components/Tutorials';

import BackgroundSmtpLogsChecker from './BackgroundSmtpLogsChecker';

const Builder = lazy(() => import('components/Builder2/Builder.js'));
const Dashboard = lazy(() => import('components/Dashboard2/Dashboard2.js'));
const Login = lazy(() => import('components/Auth/Login.js'));
const Signup = lazy(() => import('components/Auth/Signup.js'));
const Verify = lazy(() => import('components/Auth/Verify.js'));
const Join = lazy(() => import('components/Auth/Join.js'));
const Onboarding = lazy(() => import('components/Auth/Onboarding.js'));
const Ltdf = lazy(() => import('components/Ltdf/Ltdf.js'));
const SplitPayment = lazy(() => import('components/Ltdf/SplitPayment.js'));
const LtdfHello = lazy(() => import('components/Ltdf/modals/LtdfHello.js'));
const ChangeToLtdf = lazy(() => import('components/Ltdf/ChangeToLtdf.js'));
const Hello = lazy(() => import('components/Auth/modals/Hello.js'));
const Theme = lazy(() => import('components/Theme/Theme.js'));
const Forgot1 = lazy(() => import('components/Auth/Forgot1.js'));
const Forgot2 = lazy(() => import('components/Auth/Forgot2.js'));
const ChangeEmail = lazy(() => import('components/Auth/ChangeEmail.js'));
const Unsubscribe = lazy(() => import('components/Auth/Unsubscribe.js'));
const Otp = lazy(() => import('components/Auth/Otp.js'));
const Impersonate = lazy(() => import('components/Auth/Impersonate.js'));
const Invoice = lazy(() => import('components/Invoice/Invoice.js'));
const Empty = () => <></>;

const OUTSIDE_PAGES = ['/login', '/signup', '/verify', '/join', '/forgot', '/unsubscribe', '/ltdf/pay', '/changeEmail'];

const urlParams = new URLSearchParams(window.location.search);

// FullStory
const persistentEnableFullStory =
  window.persistentEnableFullStory ||
  ((enable) => {
    console.log('Empty persistentEnableFullStory() call', enable);
  });

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

const isOutsidePage = () => {
  let res = false;

  for (let page of OUTSIDE_PAGES) {
    if (window.location.pathname.indexOf(page) === 0) {
      res = true;
      break;
    }
  }

  return res;
};

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const App = React.memo(({ isAuthenticated, authenticating, checkAuthentication }) => {
  const routerHistory = useHistory();

  const [searchParams, setSearchParams] = useSearchParams({
    modal: null,
    profileTab: null,
    upsell: null
  });

  /* ---------------------------------- STATE --------------------------------- */

  const [userFromDb, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [connections, setConnections] = useState([]);
  const [otpToken, setOtpToken] = useState(null);
  const [smtps, setSmtps] = useState([]);

  /* ------------------------ CHECKING IF PLAN EXPIRED ------------------------ */

  /*
    Backend doesn't update user data right away after plan expires.
    Frontend hoverer will simulate disabled plan ASAP so users
    won't think that they can still use it.
  */

  // Positive value if value didn't expire, negative otherwise
  const msLeftToExpiredPlan = useMemo(() => {
    const MS_EXPIRED = 0;
    const MS_UNABLE_TO_TELL = Infinity;

    const billing = userFromDb?.billing;
    if (!billing) {
      return MS_UNABLE_TO_TELL;
    }

    try {
      if (!billing.plan) {
        return MS_EXPIRED;
      } else if (billing.plan === 'trial') {
        return billing.trialEnd ? new Date(billing.trialEnd) - new Date() : MS_EXPIRED;
      } else if (billing.plan !== 'trial' && ['unpaid', 'canceled'].indexOf(billing.planStatus) !== -1) {
        return billing.unpaidPlanTimeoutEnd ? new Date(billing.unpaidPlanTimeoutEnd) - new Date() : MS_EXPIRED;
      } else {
        return MS_UNABLE_TO_TELL;
      }
    } catch (e) {
      console.error(e);
      return MS_UNABLE_TO_TELL;
    }
  }, [userFromDb]);

  useEffect(() => {
    if (msLeftToExpiredPlan > 0 && isFinite(msLeftToExpiredPlan)) {
      const forceReRender = () => setUser((user) => ({ ...user }));

      // Force re-render after plan expires
      const timeout = setTimeout(forceReRender, msLeftToExpiredPlan);

      return () => clearTimeout(timeout);
    }
  }, [msLeftToExpiredPlan]);

  const user = useMemo(() => {
    const subscriptionExpired = msLeftToExpiredPlan <= 0;

    if (!userFromDb?.billing || !subscriptionExpired) {
      return userFromDb;
    } else {
      // Artificially nullify expired plan,
      // it will trigger update modal.
      const fixedBilling = {
        ...userFromDb.billing,
        plan: null
      };

      return {
        ...userFromDb,
        billing: fixedBilling
      };
    }
  }, [userFromDb, msLeftToExpiredPlan]);

  /* ------------------------------- FULL STORY ------------------------------- */

  // (only for logged in trial users)
  const fullStoryShouldBeEnabled = user?.billing?.plan === 'trial';
  useEffect(() => {
    persistentEnableFullStory(fullStoryShouldBeEnabled);
  }, [fullStoryShouldBeEnabled]);

  /* ------------------------------- UI SETTINGS ------------------------------ */

  // Ui Settings
  const [uiSettings, setUiSettings] = useState({});

  useEffect(() => {
    setUiSettings(user?.uiSettings || {});
  }, [user?.uiSettings]);

  // Syntaxes:
  // updateUiSettings({['some.path']: value, ...})
  // updateUiSettings('some.path', value) - only one
  const updateUiSettings = useCallback((changesOrKey, value) => {
    const changes = _isString(changesOrKey) ? { [changesOrKey]: value } : value;

    setUiSettings((uiSettings) => {
      const nextUiSettings = { ...uiSettings };

      for (const path in changes) {
        _set(nextUiSettings, path, changes[path]);
      }

      return nextUiSettings;
    });

    asyncSocketAckEmit('updateUserUiSettings', changes);
  }, []);

  /* --------------------------- PROFILE MODAL STATE -------------------------- */

  const PROFILE_MODAL_NAME = 'profile';
  const showProfile = searchParams.modal === PROFILE_MODAL_NAME;
  const setShowProfile = useCallback(
    (show) => {
      if (show) {
        setSearchParams({ modal: PROFILE_MODAL_NAME, profileTab: 'profile' });
      } else {
        setSearchParams({ modal: null, profileTab: null, upsell: null });
      }
    },
    [setSearchParams]
  );

  const profilePage = searchParams.profileTab || null;
  const setProfilePage = useCallback(
    (page) => setSearchParams({ modal: PROFILE_MODAL_NAME, profileTab: page, upsell: null }),
    [setSearchParams]
  );

  const showUpgradeTableUpsell = useCallback(
    (featureId = null) => setSearchParams({ modal: PROFILE_MODAL_NAME, profileTab: 'upgrade', upsell: featureId }),
    [setSearchParams]
  );

  const [showHello, setShowHello] = useState(false);

  useEffect(() => {
    if (user?.billing?.plan === null && !showProfile) {
      routerHistory.push('/upgrade?modal=profile&profileTab=upgrade');
    }
  }, [showProfile, user?.billing?.plan, routerHistory]);

  const handleRequestProfileClose = useCallback(() => {
    if (user?.billing?.plan) {
      setShowProfile(false);
    }
  }, [user?.billing?.plan, setShowProfile]);

  /* ------------------------- PERMISSIONS MODAL STATE ------------------------ */

  const [isYouLackPermissionsModalOpen, setIsYouLackPermissionModalOpen] = useState(false);
  const [youLackPermissionsModalProps, setYouLackPermissionsModalProps] = useState(null);

  const showYouLackPermissions = useCallback((props = null) => {
    setYouLackPermissionsModalProps(props);
    setIsYouLackPermissionModalOpen(true);
  }, []);

  const closeYouLackPermissions = useCallback(() => {
    setIsYouLackPermissionModalOpen(false);
  }, []);

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const getAndUpdateSmtps = async () => {
    const { success, data } = await asyncSocketAckEmit('getSmtps');

    if (!success) return {};

    setSmtps(data);
  };

  const getAndUpdateUser = async (payload) => {
    const { success, data } = await asyncSocketAckEmit('getUser', payload);

    if (!success) return {};

    setUser(data);
  };

  const getAndUpdateUsers = async () => {
    const { success, data } = await asyncSocketAckEmit('getUsers');
    const parsedPayload = [];

    if (!success) return [];

    for (let user of data) parsedPayload.push(new UserModel(user));

    setUsers(parsedPayload);
  };

  const getAndUpdateConnections = async () => {
    const { success, data } = await asyncSocketAckEmit('getConnections');

    if (!success) return [];

    setConnections(data);
  };

  useEffect(() => {
    if (isAuthenticated) {
      getAndUpdateUser();
      getAndUpdateUsers();
      getAndUpdateSmtps();
      getAndUpdateConnections();

      io.socket.on('refreshUsers', () => getAndUpdateUsers());
      io.socket.on('refreshUser', (payload) => getAndUpdateUser(payload));
      io.socket.on('refreshSmtps', () => getAndUpdateSmtps());
      io.socket.on('refreshConnections', () => getAndUpdateConnections());
      io.socket.on('refreshWorkspacesPermissions', () => io.socket.emit('refreshWorkspacesPermissions'));
    } else {
      if (io.socket) {
        io.socket.off('refreshUsers');
        io.socket.off('refreshUser');
        io.socket.off('refreshWorkspacesPermissions');
      }
    }
  }, [isAuthenticated]);

  // Temporary fix for Redirect in 'pages not logged in users' not always working.
  useEffect(() => {
    if (!isAuthenticated && !authenticating && !isOutsidePage()) {
      window.location.href = '/login';
    }
  }, [isAuthenticated, authenticating]);

  /* -------------------------------- REDUCERS -------------------------------- */

  const updateUser = useMemo(() => {
    // ##### updateUser #####

    let updateUser_previousUpdatedKeys = [];

    const updateUser_debouncedIoEmit = _debounce((params) => {
      asyncSocketAckEmit('updateUser', params);
    }, 1000);

    return (params) => {
      // Local state update
      setUser((user) => {
        return {
          ...user,
          ...params
        };
      });

      // Backend state update
      const updatedKeys = Object.keys(params);

      // If different keys are updated than in previous call flush previous update
      if (!deepIsEqual(updatedKeys, updateUser_previousUpdatedKeys)) {
        updateUser_debouncedIoEmit.flush();
      }

      updateUser_debouncedIoEmit(params);

      updateUser_previousUpdatedKeys = Object.keys(params);
    };
  }, []);

  /* -------------------------------- DEV MODE -------------------------------- */

  useEffect(() => {
    if (window.QS?.verbose) {
      console.log(`%cUSER CHANGED:`, 'color: #777', user);
    }

    // questionscout email domain or impersonated user
    const isUserDev = /@questionscout.com$/.test(user?.email) || Boolean(window.localStorage.getItem('adminToken'));
    if (isUserDev) {
      window.localStorage.setItem('debugLogs', true);
      window.localStorage.setItem('devPages', true);

      // Logging io socket events
      const handler = (eventName, ...data) => {
        console.log(`%cEVENT: ${eventName}`, 'color: #9061F9', ...data);
      };
      io.socket.onAny(handler);
      return () => io.socket.offAny(handler);
    }
  }, [user]);

  /* ----------------------------------- JSX ---------------------------------- */

  const developmentPagesJsx = [];

  // Pages for development build
  if (window.QS?.devPages) {
    const UiTestPage = lazy(() => import('ui-components/UiTestPage.js'));
    const AiTestPage = lazy(() => import('components/TestPage/AiTestPage.js'));
    const TestPage = lazy(() => import('components/TestPage/TestPage.js'));

    developmentPagesJsx.push(
      <Route key="/dev" path="/dev" exact component={TestPage} />,
      <Route key="/ui" path="/ui" exact component={UiTestPage} />,
      <Route key="/ai" path="/ai" exact component={AiTestPage} />
    );
  }

  let contentJsx = null;

  // Content for logged in users
  if (isAuthenticated) {
    const isAdminOrHolder = Boolean(user && (user.role === 'holder' || user.role === 'admin'));

    contentJsx = user ? (
      <>
        <TutorialsContextProvider>
          {isAdminOrHolder && <BackgroundSmtpLogsChecker />}

          {user.onboarding && window.location.pathname.indexOf('/ltdf') !== 0 && <Redirect to="/onboarding" />}
          {showHello && (!user.lifeTime || !user.lifeTime.enabled) && <Hello />}
          {urlParams.get('ltdfhello') === 'true' && user.lifeTime && user.lifeTime.paid && <LtdfHello />}

          <Switch>
            {!user.billing.plan && <Route path="/upgrade" exact component={Empty} />}
            <Route path={['/', '/workspace/:id?', '/themes']} exact component={Dashboard} />
            <Route path="/form/:id/:page?" component={Builder} />
            <Route path="/onboarding" exact component={Onboarding} />
            <Route path="/invoice/:orderId/:stripeId" exact component={Invoice} />
            <Route path="/ltdf" exact component={Ltdf} />
            <Route path="/ltdf/pay/:userId/:splitId" exact component={SplitPayment} />
            <Route path="/signup" exact component={ChangeToLtdf} /> {/* fake signup for ltdf  */}
            <Route path="/themes/:type/:id/:page?" exact component={Theme} />
            <Route path="/impersonate/:id" exact component={Impersonate} />
            <Route path="/unsubscribe/:code?" exact component={Unsubscribe} />
            <Route path="/verify/:code?" exact component={Verify} />
            <Route path="/changeEmail/:code?" exact component={ChangeEmail} />
            {developmentPagesJsx}
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>

          <Modal showOnTop theme="tight" isOpen={showProfile} onRequestClose={handleRequestProfileClose}>
            <Profile />
          </Modal>
          <Modal showOnTop theme="tight" isOpen={isYouLackPermissionsModalOpen} onRequestClose={closeYouLackPermissions}>
            <YouLackPermission onRequestClose={closeYouLackPermissions} {...youLackPermissionsModalProps} />
          </Modal>
        </TutorialsContextProvider>
      </>
    ) : (
      <SuspenseFallback />
    );
  }
  // Content for not logged in users
  else {
    contentJsx = (
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/otp" exact component={Otp} />
        <Route path="/signup" exact component={Signup} />

        <Route path="/verify/:code?" exact component={Verify} />
        <Route path="/join/:token?" exact component={Join} />
        <Route path="/forgot" exact component={Forgot1} />
        <Route path="/forgot/:code" exact component={Forgot2} />
        <Route path="/unsubscribe/:code?" exact component={Unsubscribe} />
        <Route path="/changeEmail/:code?" exact component={ChangeEmail} />
        <Route path="/ltdf/pay/:userId/:splitId" exact component={SplitPayment} />

        {!authenticating && (
          <Route>
            <Redirect to="/login" />
          </Route>
        )}
      </Switch>
    );
  }

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <MainContext.Provider
        value={{
          isAuthenticated,
          checkAuthentication,

          otpToken,
          setOtpToken,

          uiSettings,
          updateUiSettings,

          user,
          setUser,
          users,
          setUsers,

          connections,
          setConnections,

          smtps,
          setSmtps,

          showYouLackPermissions,
          updateUser,
          showProfile,
          setShowProfile,
          profilePage,
          setProfilePage,
          showUpgradeTableUpsell,
          showHello,
          setShowHello,

          getAndUpdateUser,
          getAndUpdateUsers,
          getAndUpdateSmtps,
          getAndUpdateConnections
        }}>
        <ActivityContextProvider>
          <IntegrationsProvider>{contentJsx}</IntegrationsProvider>
        </ActivityContextProvider>
      </MainContext.Provider>
    </Suspense>
  );
});

/* -------------------------------------------------------------------------- */
/*                           ROUTER, ERROR HANDLING                           */
/* -------------------------------------------------------------------------- */

export default React.memo(() => {
  return (
    <SomethingWentWrongBoundary>
      <Router>
        <PopupsProvider>
          <ConnectionManager component={App} />
        </PopupsProvider>
      </Router>
    </SomethingWentWrongBoundary>
  );
});
