import styles from './SortableList.module.css';

import React, { useMemo, useCallback, useContext, useState, useRef, useEffect } from 'react';
import { Icon } from '../Icon/Icon';
import arrayMove from 'array-move';

import { Item, List, DragHandleComponent } from 'react-sortful';

import { useTimeout } from 'helpers';

/* -------------------------------------------------------------------------- */
/*                                   CONFIG                                   */
/* -------------------------------------------------------------------------- */

const SORTING_DELAY_MS = 200;
const SCROLL_EDGE_INTERVAL_MS = 30;
const SCROLL_EDGE_JUMP = 7;

// This context is used to handle items updates during dragging,
// at first there are no changes, but after some timeout styling will be updated through context flag
const ScrollableListContext = React.createContext();

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

const defaultIdentifierExtractor = (item) => {
  if (process.env.NODE_ENV === 'development') {
    if (!('_id' in item || 'id' in item || 'value' in item)) {
      throw new Error('DEBUG: unable to create identifier from provided item data.');
    }
  }
  return item._id || item.id || item.value;
};

/* -------------------------------------------------------------------------- */
/*                            SCROLLABLE LIST ITEM                            */
/* -------------------------------------------------------------------------- */

// Uses context to provide data to item renderer
const ScrollableListItem = React.memo(({ renderItem, identifier, item, role = 'item', injectedProps = null }) => {
  const { draggedItemIdentifier, scrollableNodeRef } = useContext(ScrollableListContext);
  const isDragged = draggedItemIdentifier === identifier;

  const injectedStyle = injectedProps?.style;
  const injectedRef = injectedProps?.ref;

  return useMemo(() => {
    const style = { ...injectedStyle };
    if (isDragged) style.cursor = 'grab';
    if (role === 'ghost' && !isDragged) style.display = 'none';

    // Used for conditional styling
    const sortingRole = role === 'placeholder' && !isDragged ? 'item' : role;

    // Rendering function is used instead of a component to allow render customization
    // without unnecessary unmount & mount
    return renderItem({
      item,
      itemRole: sortingRole,
      injectedProps: {
        className: styles.item,
        style,
        'data-sorting-role': sortingRole,
        ref: injectedRef
      },
      injectedContext: {
        scrollableNodeRef
      }
    });
  }, [renderItem, item, role, isDragged, injectedStyle, injectedRef, scrollableNodeRef]);
});

/* -------------------------------------------------------------------------- */
/*                              HELPER COMPONENTS                             */
/* -------------------------------------------------------------------------- */

/* -------------------------------- DROPLINE -------------------------------- */

const DropLine = (injectedProps) => {
  return <div className={styles.dropLine} style={injectedProps.style} ref={injectedProps.ref} />;
};

/* ------------------------------- DROP HANDLE ------------------------------ */

const DragHandle = ({ className, ...rest }) => {
  return (
    <DragHandleComponent className={[className, styles.dragHandle].join(' ')} {...rest}>
      <Icon id="drag" />
    </DragHandleComponent>
  );
};

/* ------------------------------- SCROLL EDGE ------------------------------ */

// Handles auto scrolling on edges
const ScrollEdge = React.memo(({ enabled, horizontal, back = false, scrollableNodeRef = null }) => {
  const intervalRef = useRef();

  // Creates an interval that scrolls provided node
  const startScrolling = useCallback(() => {
    const jump = back ? SCROLL_EDGE_JUMP : -SCROLL_EDGE_JUMP;

    intervalRef.current = setInterval(() => {
      const node = scrollableNodeRef.current;

      if (node) {
        if (horizontal) node.scrollLeft += jump;
        else node.scrollTop += jump;
      }
    }, SCROLL_EDGE_INTERVAL_MS);
  }, [back, scrollableNodeRef, horizontal]);

  // Removes intervals
  const cleanup = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);
  useEffect(() => {
    if (!enabled) cleanup();
  }, [cleanup, enabled]);

  return (
    <div
      className={back ? styles.scrollEdgeBottom : styles.scrollEdgeTop}
      onMouseEnter={startScrolling}
      onMouseLeave={cleanup}
      // Workaround for pointer-events: none; preventing mouseenter events
      // Hides if disabled to don't cover clickable elements
      style={{ display: enabled ? 'block' : 'none' }}
    />
  );
});

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const SortableList = ({
  className,
  classes = {},
  // Data and rendering
  items,
  renderItem,
  identifierExtractor = defaultIdentifierExtractor,
  // Events
  onSort,
  callbackArgument = 'array',
  // Config
  dragHandle = false,
  horizontal = false,
  sortable = true,
  scrollable = true,
  shrinkItems = false,
  itemSpacing = 10,

  ...rest
}) => {
  /* ---------------------------------- STATE --------------------------------- */

  const setDraggingTimeout = useTimeout();

  // Sorting state
  const [draggedItemIdentifier, setDraggedItemIdentifier] = useState(false);
  const draggedItemIdentifierRef = useRef(draggedItemIdentifier);
  const isDragging = Boolean(draggedItemIdentifier);

  const lastMouseClickTypeRef = useRef(null);

  const scrollableNodeRef = useRef(null);

  const contextValue = useMemo(
    () => ({
      scrollableNodeRef,
      draggedItemIdentifier,
      horizontal
    }),
    [draggedItemIdentifier, horizontal]
  );

  /* ---------------------------- RENDERING CONFIG ---------------------------- */

  // Gets item by it's unique identifier returned by identifierExtractor
  const getItem = useMemo(() => {
    const itemsById = new Map();
    for (const item of items) itemsById.set(identifierExtractor(item), item);

    return (identifier) => itemsById.get(identifier);
  }, [items, identifierExtractor]);

  /* --------------------------- RENDERING FUNCTIONS -------------------------- */

  const renderElement = useCallback(
    ({ identifier }) => {
      return (
        <ScrollableListItem
          // Data
          renderItem={renderItem}
          item={getItem(identifier)}
          // Sort state
          identifier={identifier}
        />
      );
    },
    [renderItem, getItem]
  );

  const renderGhostElement = useCallback(
    ({ identifier }) => {
      return (
        <ScrollableListItem
          // Data
          renderItem={renderItem}
          item={getItem(identifier)}
          // Sort state
          identifier={identifier}
          role="ghost"
        />
      );
    },
    [renderItem, getItem]
  );

  const renderPlaceholderElement = useCallback(
    (injectedProps, { identifier }) => {
      // If container
      if (shrinkItems) {
        delete injectedProps.style.width;
        delete injectedProps.style.height;
      }

      return (
        <ScrollableListItem
          // Data
          renderItem={renderItem}
          item={getItem(identifier)}
          // Sort state
          identifier={identifier}
          role="placeholder"
          injectedProps={injectedProps}
        />
      );
    },
    [renderItem, getItem, shrinkItems]
  );

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  // Detect what mouse button was used for dragging
  useEffect(() => {
    const clickListener = (e) => {
      lastMouseClickTypeRef.current = e.which === 3 ? 'right' : 'other';
    };
    window.document.addEventListener('mousedown', clickListener);
    return () => {
      window.document.removeEventListener('mousedown', clickListener);
    };
  }, []);

  const onDragStart = useCallback(
    (meta) => {
      setDraggingTimeout(() => {
        // Prevents dragging with right mouse button
        if (sortable && lastMouseClickTypeRef.current !== 'right') {
          draggedItemIdentifierRef.current = meta.identifier;
          setDraggedItemIdentifier(meta.identifier);
        }
      }, SORTING_DELAY_MS);
    },
    [setDraggingTimeout, sortable]
  );

  const onDragEnd = useCallback(
    (meta) => {
      const wasInvokedAfterTimeout = Boolean(draggedItemIdentifierRef.current);

      draggedItemIdentifierRef.current = null;
      setDraggedItemIdentifier(null);
      setDraggingTimeout(null);

      // Prevents any updates before timeout
      if (wasInvokedAfterTimeout) {
        if (meta.nextIndex === undefined) return;

        if (onSort && callbackArgument === 'meta') onSort(meta);
        else if (onSort && callbackArgument === 'array') onSort(arrayMove(items, meta.index, meta.nextIndex));
        else {
          throw new Error("DEBUG: wrong 'returnedData' prop.");
        }
      }
    },
    [items, setDraggingTimeout, onSort, callbackArgument]
  );

  const onWheel = useMemo(() => {
    // In horizontal mode enables scrolling without shift
    if (horizontal) {
      return (e) => {
        if (scrollableNodeRef.current) {
          const node = scrollableNodeRef.current;
          node.scrollLeft += e.deltaY;
        }
      };
    } else return null;
  }, [horizontal]);

  /* ----------------------------------- JSX ---------------------------------- */

  const itemsJsx = useMemo(
    () =>
      items.map((item, index) => {
        const identifier = identifierExtractor(item);
        return (
          // This identifier and index will be referenced during sorting updates
          <Item key={identifier} identifier={identifier} index={index} isUsedCustomDragHandlers={dragHandle}>
            {renderElement({ identifier })}
          </Item>
        );
      }),
    [items, renderElement, identifierExtractor, dragHandle]
  );

  const listJsx = (
    <List
      className={[classes.list || '', styles.list, isDragging ? styles.dragging : ''].join(' ')}
      // Config
      direction={horizontal ? 'horizontal' : 'vertical'}
      itemSpacing={itemSpacing}
      isDisabled={!sortable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      // Rendering
      renderDropLine={DropLine}
      renderGhost={renderGhostElement}
      renderPlaceholder={renderPlaceholderElement}>
      {itemsJsx}
    </List>
  );

  return (
    <ScrollableListContext.Provider value={contextValue}>
      <div
        className={[
          className,
          styles.listWrapper,
          shrinkItems ? styles.shrinkItems : '',
          scrollable ? styles.scrollable : '',
          horizontal ? styles.horizontal : styles.vertical
        ].join(' ')}
        {...rest}>
        {scrollable ? (
          <>
            <ScrollEdge enabled={isDragging} horizontal={horizontal} scrollableNodeRef={scrollableNodeRef} back />
            <div
              className={[styles.scrollContainer, horizontal ? styles.hideScrollBar : ''].join(' ')}
              onWheel={onWheel}
              ref={scrollableNodeRef}>
              {listJsx}
            </div>
            <ScrollEdge enabled={isDragging} horizontal={horizontal} scrollableNodeRef={scrollableNodeRef} />
          </>
        ) : (
          listJsx
        )}
      </div>
    </ScrollableListContext.Provider>
  );
};

SortableList.DragHandle = DragHandle;

export default SortableList;
