import { useQuery } from 'react-query';

import { useQueryParam } from '../../providers';
import formsQueries from './formsQueries';

export const usePreviewAutoSubmissionDeletionRulesOnFormSubmissions = (props) => {
  /* --------------------------------- PARAMS --------------------------------- */

  const { autoSubmissionDeletionRules } = props;
  const formId = useQueryParam('formId', null);

  /* --------------------------------- QUERIES -------------------------------- */

  const { refetch, data, error, isLoading, isFetching } = useQuery({
    queryFn: formsQueries.previewAutoSubmissionDeletionRulesOnFormSubmissions.fn,
    queryKey: formsQueries.previewAutoSubmissionDeletionRulesOnFormSubmissions.keyFn({ formId, autoSubmissionDeletionRules }),
    structuralSharing: false,
    notifyOnChangeProps: ['data', 'error', 'isLoading', 'isFetching'],
    suspense: false
  });

  return {
    refetch,
    error,
    isLoading,
    isFetching,
    data
  };
};
