import React, { useMemo, useState, useRef, useCallback, useEffect, useContext } from 'react';
import Script from 'react-load-script';

import { MainContext, IntegrationsContext } from 'contexts';

import { QSFeature } from 'components/shared';

import HS_CONFIG from './config/helpScout';
import NLT_CONFIG from './config/nolt';

/* -------------------------------------------------------------------------- */
/*                                   HELPER                                   */
/* -------------------------------------------------------------------------- */

const usePromise = () => {
  const resolverRef = useRef(null);
  const rejectorRef = useRef(null);

  const [promise] = useState(() => {
    return new Promise((resolve, reject) => {
      resolverRef.current = resolve;
      rejectorRef.current = reject;
    });
  });

  return {
    promise,
    resolve: resolverRef.current,
    reject: rejectorRef.current
  };
};

/* -------------------------------------------------------------------------- */
/*                            INTEGRATIONS PROVIDER                           */
/* -------------------------------------------------------------------------- */

const IntegrationsProvider = React.memo(({ children }) => {
  const { user } = useContext(MainContext);

  /* ---------------------------------- NOLT ---------------------------------- */

  const [isNoltNeeded, setIsNoltNeeded] = useState(false);

  // Nolt promise and resolver
  const { promise: noltApiPromise, resolve: resolveNoltApi } = usePromise();

  const handleNoltScriptLoad = useCallback(() => {
    console.log('[NOLT] Script done loading.');
    resolveNoltApi((...args) => {
      // NOTE: wrapped in function in case nolt overwrites this object
      return window.nolt(...args);
    });
  }, [resolveNoltApi]);

  const getNoltAPI = useCallback(() => {
    setIsNoltNeeded(true);
    return noltApiPromise;
  }, [noltApiPromise]);

  /* ------------------------------- HELP SCOUT ------------------------------- */

  const [isHelpScoutNeeded, setIsHelpScoutNeeded] = useState(false);

  // HelpScout api key depends on user plan
  const isLifeChatSupportEnabled = QSFeature.useIsFeatureEnabled('liveChatSupport');
  const helpScoutApiKey = isLifeChatSupportEnabled ? HS_CONFIG.proKey : HS_CONFIG.personalKey;

  // Help scout promise and resolver
  const { promise: helpScoutApiPromise, resolve: resolveHelpScoutApi } = usePromise();

  // Handles initialization of help scout api and key changes
  useEffect(() => {
    if (!isHelpScoutNeeded) return; // get help scout api was not called yet
    if (!window.Beacon) return; // script did not load
    if (!helpScoutApiKey) return; // key is not known yet

    window.Beacon('init', helpScoutApiKey);
    window.Beacon('config', HS_CONFIG.beaconConfig);
    window.Beacon('identify', {
      name: user.firstName || user.lastName ? `${user.firstName}${user.lastName ? ' ' + user.lastName : ''}` : null,
      email: user.email,
      company: user.company || '',
      avatar: user.avatar ? `https://d3djdih2k2vfi2.cloudfront.net/avatars/${user.owner || user._id}/${user._id}.png?hash=${user.avatar}` : null,
      plan: `${user.billing.plan}${user?.lifeTime?.enabled ? ' (LTD)' : ''}`,
      _id: user._id,
      root: `https://root.questionscout.com/users/${user._id}`
    });

    resolveHelpScoutApi((...args) => {
      // NOTE: this object can't be passed directly as reference can change
      return window.Beacon(...args);
    });

    return () => {
      window.Beacon('destroy');
    };
  }, [isHelpScoutNeeded, helpScoutApiKey, resolveHelpScoutApi, user?._id]);

  // Returns a promise of helpscout api object
  const getHelpScoutAPI = useCallback(() => {
    setIsHelpScoutNeeded(true);
    return helpScoutApiPromise;
  }, [helpScoutApiPromise]);

  /* --------------------------------- CONTEXT -------------------------------- */

  const context = useMemo(() => {
    return {
      getNoltAPI,
      getHelpScoutAPI
    };
  }, [getNoltAPI, getHelpScoutAPI]);

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <IntegrationsContext.Provider value={context}>
      {/* Injects nolt script when it's needed */}
      {isNoltNeeded && <Script url={NLT_CONFIG.scriptUrl} onLoad={handleNoltScriptLoad} />}

      {/* Children content */}
      {children}
    </IntegrationsContext.Provider>
  );
});

export default IntegrationsProvider;
