import React, { useCallback, useContext } from 'react';

import { MainContext } from 'contexts';
import config from 'config.js';

import QSUpsell from '../QSUpsell/QSUpsell';

/* -------------------------------------------------------------------------- */
/*                                   CONFIG                                   */
/* -------------------------------------------------------------------------- */

const BILLING_PLANS = config.plans;
const FEATURES = config.features;

const getFeature = (featureId) => {
  if (process.env.NODE_ENV === 'development') {
    if (!FEATURES[featureId]) {
      throw new Error(`DEBUG: feature with id '${featureId}' is not defined in config.features.`);
    }
  }

  return FEATURES[featureId];
};

/* -------------------------------------------------------------------------- */
/*                                    HOOKS                                   */
/* -------------------------------------------------------------------------- */

const useBillingPlan = (requiredPlanId) => {
  /* ------------------------------ FETCHED DATA ------------------------------ */

  const { user } = useContext(MainContext);

  const userPlanId = user?.billing?.plan;

  /* ----------------------------- VALIDATING PLAN ---------------------------- */

  const userPlan = BILLING_PLANS[userPlanId];

  const requiredPlan = BILLING_PLANS[requiredPlanId];

  if (process.env.NODE_ENV === 'development') {
    if (!requiredPlan) {
      throw new Error(`DEBUG: Unknown requiredPlan (${requiredPlanId}).`);
    }
  }

  return {
    // If user plan is unknown (maybe data is pending, worst scenario is assumed)
    isBetterThanCurrent: requiredPlan.quality > (userPlan?.quality ?? -1),
    ...requiredPlan
  };
};

// Identifies feature and determines if it can be used
const useIsFeatureEnabled = (featureId) => {
  const { user } = useContext(MainContext);
  const isLTD = Boolean(user && user.lifeTime && user.lifeTime.enabled);
  const isTrial = Boolean(user && user.billing.plan === 'trial');

  const feature = getFeature(featureId);
  const requiredPlan = useBillingPlan(feature.requiredPlan);

  if (requiredPlan.isBetterThanCurrent) {
    return false;
  } else if (feature.notAvailableDuringTrial && isTrial) {
    return false;
  } else if (feature.disableIfLTD && isLTD) {
    return false;
  } else {
    return true;
  }
};

/* -------------------------------------------------------------------------- */
/*                              UPSELL COMPONENT                              */
/* -------------------------------------------------------------------------- */

const QSFeature = ({ featureId, renderer }) => {
  const isFeatureEnabled = useIsFeatureEnabled(featureId);

  // Upsell component that can be used to promote a feature
  const Upsell = useCallback(
    (props) => {
      if (isFeatureEnabled) {
        return null;
      } else {
        return <QSUpsell featureId={featureId} {...props} />;
      }
    },
    [featureId, isFeatureEnabled]
  );

  // Using render prop to show content
  if (!renderer) return null;
  return renderer({
    featureId,
    isFeatureEnabled,
    Upsell
  });
};

QSFeature.useIsFeatureEnabled = useIsFeatureEnabled;

export default QSFeature;
