import styles from './Hint.module.css';

import React from 'react';

import { Icon } from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';

const Hint = React.memo(({ icon = 'information', color, iconAnimation, placement, children, ...rest }) => {
  const iconJsx = (
    <Icon
      {...rest}
      color={color}
      id={icon}
      style={{ cursor: 'help' }}
      // Warning will be animated by default to give them more attention
      animation={iconAnimation ?? (icon === 'warning' ? 'headShake' : undefined)}
    />
  );

  if (children) {
    return (
      <Tooltip content={<div className={styles.content}>{children}</div>} placement={placement} delay={0}>
        {iconJsx}
      </Tooltip>
    );
  } else {
    return iconJsx;
  }
});

export default Hint;
