import styles from './ZapierKey.module.css';

import React, { useContext, useMemo } from 'react';
import { CopyField } from 'ui-components';

import { MainContext } from 'contexts/main.js';

import ContentHeader from '../Header.js';

import config from 'config.js';

const ZapierKey = () => {
  const { user } = useContext(MainContext);

  if (!user.apiKey) return <></>;

  return (
    <>
      <ContentHeader title="Zapier/API Key" description="This key will allow you to authenticate with Zapier." />

      <div className={styles.content}>
        <CopyField className={config.fullStoryExcludeClass} value={user.apiKey} suffix="Copy" />
      </div>
    </>
  );
};

export default ZapierKey;
