import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { loadReCaptcha } from 'react-recaptcha-v3';

import { Modal, ModalContent, SuspenseFallback, Button, Spinner, Flex } from 'ui-components';

import io from 'io.js';

import config from 'config.js';

import { QueryProvider } from 'api';

/* -------------------------------------------------------------------------- */
/*                              CONNECTION MODAL                              */
/* -------------------------------------------------------------------------- */

const ConnectionProgress = ({ connectionState }) => {
  const [message, setMessage] = useState(null);

  const MESSAGES = useMemo(
    () => ({
      authenticated: {
        header: <>Authentication succeeded</>,
        content: (
          <Flex>
            <Spinner />
            <span>Please wait, you'll be redirected...</span>
          </Flex>
        )
      },

      notAuthenticated: {
        header: <>Authentication failed</>,
        content: (
          <Flex>
            <Spinner />
            <span>Please wait, you'll be redirected to login page...</span>
          </Flex>
        )
      },

      maintenance: {
        header: <>📦 We're unpacking some new features</>,
        content: <>QuestionScout will be available again in just a moment.</>
      },

      connectionLost: {
        header: <>Your connection with QuestionScout was lost</>,
        content: (
          <Flex>
            <Spinner />
            <span>Please wait, we're trying to connect you to QuestionScout servers...</span>
          </Flex>
        )
      },

      connecting: {
        header: <>Connecting to QuestionScout servers</>,
        content: (
          <Flex>
            <Spinner />
            <span>Please wait, we're trying to connect you to QuestionScout servers...</span>
          </Flex>
        )
      },

      offline: {
        header: <>Unable to connect to QuestionScout servers</>,
        content: <>Please make sure that you're not having any connection issues and try again.</>
      }
    }),
    []
  );

  const { authenticated, authenticatedCount, authenticating, subsequentConnectionErrors } = connectionState;

  useEffect(() => {
    setMessage(null);

    // Authentication succeeded
    if (authenticated) {
      setMessage(MESSAGES.authenticated);
    }
    // Authentication not succeeded but there are no connection errors
    else if (!authenticated && !authenticating && !subsequentConnectionErrors) {
      setMessage(MESSAGES.notAuthenticated);
    }
    // Authentication is in progress
    else if (authenticating && subsequentConnectionErrors < 3) {
      if (authenticatedCount > 0) {
        setMessage(MESSAGES.connectionLost);
      } else {
        setMessage(MESSAGES.connecting);
      }
    }
    // Authentication is in progress for too long or it completely stopped without succeeding
    else {
      // TODO
      const userHasInternetConnection = true;

      if (userHasInternetConnection) {
        setMessage(MESSAGES.maintenance);
      } else {
        setMessage(MESSAGES.offline);
      }
    }
  }, [authenticated, authenticatedCount, authenticating, subsequentConnectionErrors, MESSAGES]);

  return (
    <ModalContent
      style={{ minHeight: 80, minWidth: 80 }}
      loading={!message}
      header={message?.header}
      buttons={
        message ? (
          <Button theme="black" onClick={() => window.location.reload()}>
            Reload page
          </Button>
        ) : null
      }>
      {message?.content}
    </ModalContent>
  );
};

/* -------------------------------------------------------------------------- */
/*                                   MANAGER                                  */
/* -------------------------------------------------------------------------- */

const ConnectionManager = ({ component }) => {
  /* ----------------------- IO SOCKET CONNECTION STATE ----------------------- */

  const [connectionState, setConnectionState] = useState(io.state);

  useEffect(() => {
    const handleConnectionStateChange = (state) => {
      setConnectionState({ ...state });
    };

    io.on('state_change', handleConnectionStateChange);
    return () => {
      io.off('state_change', handleConnectionStateChange);
    };
  }, []);

  /* -------------------------- AUTO AUTHENTICATION ------------------------- */

  const checkAuthentication = useCallback(() => {
    io.connect();
  }, []);

  useEffect(() => {
    loadReCaptcha(config.reCaptcha);

    checkAuthentication();
  }, [checkAuthentication]);

  /* -------------------- CONNECTION STATE CHANGE HANDLING -------------------- */

  const Component = component;
  const { authenticated, authenticatedCount, authenticating, totalAuthenticationAttempts, subsequentConnectionErrors } = connectionState;

  useEffect(() => {
    if (authenticatedCount > 1) {
      // When authentication is regained after disconnect page
      // will be reloaded to reset state of the application
      // (user could do some actions while server was down, these actions
      // would exist in local state but no in backend)
      window.location.reload();
    }
  }, [authenticatedCount]);

  /* ----------------------------------- JSX ---------------------------------- */

  // Page is loading for the first time
  if (!totalAuthenticationAttempts) {
    return <SuspenseFallback />;
  } else {
    return (
      <>
        <QueryProvider>
          <Component isAuthenticated={authenticated} authenticating={authenticating} checkAuthentication={checkAuthentication} />

          <Modal showOnTop isOpen={authenticating || subsequentConnectionErrors > 0 || authenticatedCount > 1}>
            <ConnectionProgress connectionState={connectionState} />
          </Modal>
        </QueryProvider>
      </>
    );
  }
};

export default ConnectionManager;
