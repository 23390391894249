import styles from './OtpInput.module.css';

import React, { useEffect, useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';

import { Spinner } from '../Spinner/Spinner';

const OtpInput = React.memo(({ className = '', style, disabled, error, onChange, onComplete }) => {
  const [isFakeLoading, setIsFakeLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setIsFakeLoading(false), 500);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={[className, styles.otpInput, disabled ? styles.disabled : '', error ? styles.error : ''].join(' ')} style={style}>
      {isFakeLoading ? (
        /*
          Temporary workaround for use within Panel component.
          ReactCodeInput somehow messes up Panel's slide in animation so 
          it will be displayed after some small delay.
        */
        <div className={styles.placeholder} style={{ cursor: 'wait' }}>
          <Spinner size={22} />
        </div>
      ) : (
        <ReactCodeInput
          className={styles.reactCodeInput}
          style={{ margin: 'auto' }}
          onChange={onChange}
          onComplete={onComplete}
          disabled={disabled}
          // Content config
          type="number"
          fields={6}
          placeholder={['0', '0', '0', '0', '0', '0']}
          // Each number size
          fieldWidth={25}
          fieldHeight={40}
        />
      )}
    </div>
  );
});

export default OtpInput;
