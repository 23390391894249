import React, { useMemo, useContext, useEffect, useState } from 'react';

import { generateFieldData } from 'config.js';
import { useRouteChanger, useUnmount } from 'helpers';
import { BuilderContext } from 'contexts';
import { Icon } from 'ui-components';

import TutorialImg from '../components/TutorialImg';

/* -------------------------------------------------------------------------- */
/*                                  CALLBACK                                  */
/* -------------------------------------------------------------------------- */

const createBuilderIntroductionSteps = (params) => {
  /* --------------------- PARAM VALIDATION AND CALLBACKS --------------------- */

  const { formId, demoFieldId, navigateInApp } = params;

  if (!formId || !demoFieldId || !navigateInApp) return null;

  const navigateInForm = (route = '', urlParams = {}) => navigateInApp(`/form/${formId}${route}`, urlParams);

  // Build tab
  const showBuildRoute = () => navigateInForm('/build');
  const showElementPicker = () => navigateInForm('/build', { elementPicker: 'elements' });
  const showDemoFieldGeneralTab = () => navigateInForm('/build', { element: demoFieldId, properties: 'general' });
  const showDemoFieldOptionsTab = () => navigateInForm('/build', { element: demoFieldId, properties: 'options' });
  const showDemoFieldLogicTab = () => navigateInForm('/build', { element: demoFieldId, properties: 'logic' });
  const showDemoFieldCalculationsTab = () => navigateInForm('/build', { element: demoFieldId, properties: 'calculations' });

  // Other tabs
  const showDesignRoute = () => navigateInForm('/design');
  const showMessagingRoute = () => navigateInForm('/messaging');
  const showNotificationsRoute = () => navigateInForm('/notifications');
  const showIntegrationsRoute = () => navigateInForm('/connect/integrations');
  const showWebhooksRoute = () => navigateInForm('/connect/webhooks', { tab: 'configuration' });
  const showPaymentsRoute = () => navigateInForm('/payments/method');
  const showSettingsRoute = () => navigateInForm('/settings/general');
  const showUrlParamsRoute = () => navigateInForm('/settings/url');
  const showCustomCodeRoute = () => navigateInForm('/settings/code');

  /* ---------------------------------- STEPS --------------------------------- */

  const PART_1_START = [
    {
      titleSize: 'large',
      onAfterChange: showBuildRoute,

      title: 'Form editor introduction',
      content: (
        <>
          <p>
            Welcome to the <b>form editor</b>.
          </p>
          <p>
            The amount of settings may feel overwhelming at first, but as you'll see in moment, creating forms with QuestionScout is as easy
            as writing a regular text document.
          </p>
          <TutorialImg name="builder_hello_world" />
          <small>🕐 You'll need approx. 10 minutes to finish this tutorial.</small>
        </>
      )
    },
    {
      target: '.intro-top-bar',
      placement: 'bottom',
      spotlightPadding: 0,
      onAfterChange: showBuildRoute,

      title: 'Top bar',
      content: <p>Let's start this introduction by taking a quick look at elements, which you'll find in the top bar.</p>
    },
    {
      target: '.intro-top-bar-form-label',
      placement: 'bottom',
      spotlightPadding: 10,
      onAfterChange: showBuildRoute,

      title: 'Breadcrumbs [1/2]',
      content: (
        <p>
          In this top area you'll always find the name of the form that you're currently editing, and the name of the workspace that this
          form is in. You can navigate back to the workspace by clicking on it.
        </p>
      )
    },
    {
      target: '.intro-top-bar-form-label',
      placement: 'bottom',
      spotlightPadding: 10,
      onAfterChange: showBuildRoute,

      title: 'Breadcrumbs [2/2]',
      content: (
        <>
          <p>If you click a form name on the other hand, you'll open a dropdown with some useful context actions.</p>
          <TutorialImg name="builder_form_label_dropdown" />
        </>
      )
    },
    {
      target: '.intro-top-bar-navigation',
      placement: 'bottom',
      spotlightPadding: 6,
      onAfterChange: showBuildRoute,

      title: 'Create view',
      content: (
        <>
          <p>
            Every form can be opened in either the <b>create view</b> or in the <b>results view</b>. You can switch between these views
            using the highlighted buttons.
          </p>
          <p>
            Currently we are in the <b>create view</b>. This is were you'll edit the contents of a form. There is also a <b>results view</b>{' '}
            which you can access by clicking the <dfn>Results</dfn> button. Here, you'll find all your collected responses.
          </p>
        </>
      )
    },
    {
      target: '.intro-top-bar-share',
      placement: 'bottom',
      spotlightPadding: 6,
      onAfterChange: showBuildRoute,

      title: 'Publishing a form',
      content: (
        <>
          <p>Once you're done editing your form, press this button to publish it.</p>
          <p>
            When your form is already published, this button opens a dropdown that contains ways to share your form, embed your form, as
            well as some additional settings (such as creating a <b>custom link</b>, <b>managing SEO</b>, <b>per-user responses limit</b>{' '}
            and more).
          </p>
        </>
      )
    },
    {
      target: '.intro-top-bar-users',
      placement: 'bottom',
      spotlightPadding: 6,
      onAfterChange: showBuildRoute,

      title: 'Active collaborators',
      content: (
        <>
          <p>
            Other account users that have form opened, or that are viewing the responses that it has received, will always be listed next to
            the share button.
          </p>
          <TutorialImg name="builder_users" />
          <p>
            You can hover a users avatar to view a detailed description of what this person is currently working on or looking at. To open
            your <b>profile</b> click on your own avatar.
          </p>
        </>
      )
    },
    {
      target: '.intro-left-bar',
      placement: 'right',
      onAfterChange: showBuildRoute,

      title: 'Editor navigation',
      content: (
        <p>
          The <b>create view</b> is separated into a couple of sub pages. Each of them cover a very specific use case, so in majority of
          cases you won't need to worry about all of them.
        </p>
      )
    }
  ];

  const PART_2_BUILD = [
    {
      target: '.intro-left-bar-build',
      placement: 'right',
      spotlightPadding: 5,
      onAfterChange: showBuildRoute,

      title: 'Builder page',
      titlePrefix: <Icon id="build" scale={1.2} />,
      content: (
        <>
          <p>
            The most important sub page out of them all is the <b>builder page</b>. It's used for managing the contents of your form.
          </p>
          <p>Let's take a closer look at what you can do with it.</p>
        </>
      )
    },
    {
      target: '.intro-form-preview',
      style: { maxWidth: 300 },
      placement: 'left',
      onAfterChange: showBuildRoute,

      title: 'Form preview',
      content: (
        <>
          <p>
            In the center of the builder page lies a heart of the editor. This displays form preview which reflects current state of your
            form. You can interact with it and fill it as you wish.
          </p>
          <p>
            Don't worry, <u>responses submitted in this preview won't be saved</u>.
          </p>
        </>
      )
    },
    {
      target: '.intro-properties',
      placement: 'left',
      onAfterChange: showBuildRoute,

      title: 'Properties panel [1/2]',
      content: (
        <p>
          To the right of the form preview there is a properties panel. After you select a form element with some customizable properties,
          this is where you'll be able to edit them.
        </p>
      )
    },
    {
      target: '.intro-form-type-and-variables',
      placement: 'left',
      onAfterChange: showBuildRoute,

      title: 'Properties panel [2/2]',
      content: (
        <p>
          At times when no element is selected, the properties panel can be used to change a <b>form type</b> (which was selected during
          form creation), and for managing <b>calculation variables</b>. Calculation variables are used by our <b>calculations system</b>{' '}
          We'll get back to that later.
        </p>
      )
    },
    {
      target: '.into-fields-navigator',
      placement: 'right',
      onAfterChange: showBuildRoute,

      title: 'Navigator [1/2]',
      content: (
        <>
          <p>
            This area is used both for adding content to your form, and for summarizing existing content. For the sake of simplicity, from
            now on we will call all elements which can be added to a form <b>fields</b>.
          </p>
          <p>After you've added a field here, it will appear in the form preview in the center of the page.</p>
        </>
      )
    },
    {
      target: '.into-navigator-field',
      placement: 'right',
      onAfterChange: showBuildRoute,

      title: 'Navigator [2/2]',
      content: (
        <>
          <p>You can interact with fields in the navigator in two ways.</p>
          <ul>
            <li>You can select fields by clicking on them,</li>
            <li>and reorder fields by dragging and dropping them around.</li>
          </ul>
          <TutorialImg name="builder_fields_interactions" />
        </>
      )
    },
    {
      target: '.intro-fields-add-button',
      placement: 'bottom',
      spotlightPadding: 8,
      onAfterChange: showBuildRoute,

      title: 'Adding fields [1/3]',
      content: (
        <p>
          To add a new field to your form, you'll firstly need to open the field picker. You can do this by clicking the <dfn>Add</dfn>{' '}
          button.
        </p>
      )
    },
    {
      target: '.into-fields-navigator',
      placement: 'right',
      onAfterChange: showElementPicker,

      title: 'Adding fields [2/3]',
      content: (
        <p>
          All of these elements are fields that you can add to your form. As you can see there are a lot different kinds fields to choose
          from. Feel free to play with all of them later, since they all come with unique customizable properties.
        </p>
      )
    },
    {
      target: '.into-navigator-element',
      placement: 'right',
      onAfterChange: showElementPicker,

      title: 'Adding fields [3/3 - drag & drop]',
      content: (
        <>
          <p>
            There are two ways for adding fields to your form. First one is by dragging a field, and dropping it in a navigator wherever you
            wish to place it.
          </p>
          <TutorialImg name="builder_add_field_dnd" />
        </>
      )
    },
    {
      target: '.into-navigator-element',
      placement: 'right',
      onAfterChange: showElementPicker,

      title: 'Adding fields [3/3 - click]',
      content: (
        <>
          <p>
            You can also add fields to your form with a simple click. With this approach they'll be placed as the last field on your form.
            If you're building your form from top to bottom, this is the fastest way to build your form!
          </p>
          <TutorialImg name="builder_add_field_click" />
        </>
      )
    },
    {
      target: '.intro-element-properties',
      placement: 'left',
      onAfterChange: showDemoFieldGeneralTab,

      title: 'Field properties panel [1/2]',
      content: (
        <p>
          Once you've added and selected a field, you can start customizing it. As you can see, now that a field is selected, the{' '}
          <b>properties panel</b> was populated with editable properties of a currently selected field.
        </p>
      )
    },
    {
      target: '.intro-element-properties-tabs',
      placement: 'left',
      onAfterChange: showDemoFieldGeneralTab,

      title: 'Field properties panel [2/2]',
      content: (
        <>
          <p>
            The field properties panel is separated into up to four following tabs: <b>General properties tab</b>, <b>Options tab</b>,{' '}
            <b>Logic tab</b> and <b>Calculations tab</b>.
          </p>

          <p>These tabs group settings by a functionality, and a couple of fields don't come with all of them.</p>
        </>
      )
    },
    {
      target: '.intro-element-properties',
      placement: 'left',
      onAfterChange: showDemoFieldGeneralTab,

      title: 'General properties tab',
      titlePrefix: <Icon id="settings" scale={1.2} />,
      content: (
        <>
          <p>
            <b>General properties tab</b> is guaranteed to be supported by all fields. This is where you'll find the most generic settings
            (e.g., field label, placeholder text, pre-filled text).
          </p>
          <p>
            At the top of this tab you'll also find the buttons for performing <dfn>Duplicate</dfn> and <dfn>Delete</dfn> actions.
          </p>
        </>
      )
    },
    {
      target: '.intro-mentions',
      placement: 'left',
      spotlightPadding: 4,
      onAfterChange: showDemoFieldGeneralTab,

      title: 'Refer-to inputs',
      content: (
        <>
          <p>
            You'll notice that some inputs have a <dfn>+</dfn> symbol next to them. These are so called <b>refer-to inputs</b>, and you can
            use them to refer to a respondents input from a previous field.
          </p>
          <p>
            To add a reference, just click the <dfn>+</dfn> symbol, or simply type an '@' symbol within the input.
          </p>
          <TutorialImg name="builder_refer_to" />
        </>
      )
    },
    {
      target: '.intro-element-properties',
      placement: 'left',
      onAfterChange: showDemoFieldOptionsTab,

      title: 'Options tab',
      titlePrefix: <Icon id="options" scale={1.2} />,
      content: (
        <>
          <p>
            Single Choice field, Multiple Choice field, and other fields that only allow inputs from a finite set of values, always come
            with <b>options tab</b>.
          </p>
          <p>
            This is where you can add options manually, use pre-defined options (e.g. countries), set selection limits, and set default
            option/s.
          </p>
        </>
      )
    },
    {
      target: '.intro-element-properties',
      placement: 'left',
      onAfterChange: showDemoFieldCalculationsTab,

      title: 'Calculations tab [1/2]',
      titlePrefix: <Icon id="calculations" scale={1.2} />,
      content: (
        <>
          <p>
            QuestionScout comes with a powerful <b>calculations system</b>. It works by converting a field input a into numeric value, which
            can be assigned to some custom defined <b>calculation variables</b>, which finally can be used to perform some fancy custom
            calculations.
          </p>
          <p>You can use this system to turn your form into an interactive expenses calculator for example.</p>
          <TutorialImg name="builder_calculations" />
        </>
      )
    },
    {
      target: '.intro-element-properties',
      placement: 'left',
      onAfterChange: showDemoFieldCalculationsTab,

      title: 'Calculations tab [2/2]',
      titlePrefix: <Icon id="calculations" scale={1.2} />,
      content: (
        <p>
          In this tab you'll be able to configure how a field value will be converted into a numeric value, and what this value will be used
          for. This tab is only present for <u>fields that support converting a field value into a numeric value</u>.
        </p>
      )
    },
    {
      target: '.intro-element-properties',
      placement: 'left',
      onAfterChange: showDemoFieldLogicTab,

      title: 'Logic tab [1/2]',
      titlePrefix: <Icon id="logic" scale={1.2} />,
      content: (
        <>
          <p>
            QuestionScout also comes with a <b>logic system</b>. It can be used to define some custom rules, that describe how to
            dynamically show or hide a field, based on inputs currently present in all other fields. This makes creating branching forms
            tailored to the respondent very easy.
          </p>
          <TutorialImg name="builder_logic" />
        </>
      )
    },
    {
      target: '.intro-element-properties',
      placement: 'left',
      onAfterChange: showDemoFieldLogicTab,

      title: 'Logic tab [2/2]',
      titlePrefix: <Icon id="logic" scale={1.2} />,
      content: (
        <>
          <p>
            The logic system also has an access to variables used by calculations system, so it's even possible to create field visibility
            rules that involve some complex math.
          </p>
          <p>
            All of the fields can be displayed dynamically, so you'll find all of them in this tab. Only some of the fields can be used
            inside the actual logic rules, however.
          </p>
        </>
      )
    },
    {
      target: '.intro-form-logic-toggle',
      spotlightPadding: 4,
      placement: 'bottom',
      onAfterChange: showBuildRoute,

      title: 'Previewing logic',
      content: (
        <>
          <p>
            Now that you've been introduced to logic, there is one more thing that needs to be mentioned when it comes to the form preview.
          </p>
          <p>
            By default even if you have some logic defined, form preview will keep displaying all fields at all times to improve your
            editing experience. You can enable logic preview with this toggle.
          </p>
          <p>Calculations are always performed, but they aren't used for logic, unless the logic preview is enabled.</p>
        </>
      )
    },
    {
      target: '.into-fields-navigator',
      placement: 'right',
      onAfterChange: showBuildRoute,

      title: 'Warnings',
      content: (
        <>
          <p>
            It's possible that during editing you'll forget to set something, or that you'll set something incorrectly. When our builder
            detects that something is wrong, it shows warning icons in all affected areas. These can give you some helpful hints on hover,
            so be sure to keep an eye on those. You'll find them in other pages, too.
          </p>
          <TutorialImg name="builder_errors" />
        </>
      )
    }
  ];

  const PART_3_DESIGN = [
    {
      target: '.intro-left-bar-design',
      placement: 'right',
      spotlightPadding: 5,
      onAfterChange: showDesignRoute,

      title: 'Design page',
      titlePrefix: <Icon id="design" scale={1.2} />,
      content: (
        <p>
          We've finished your introduction to the most feature rich sub page of our form editor. Now let's take a very quick look at what
          you can use other sub pages for. Next one on the list is the <b>design page</b>.
        </p>
      )
    },
    {
      target: '.intro-form-preview',
      style: { maxWidth: 300 },
      placement: 'left',
      onAfterChange: showDesignRoute,

      title: 'Previewing themes [1/2]',
      content: (
        <p>
          Similarly to the builder page, it has the <b>form preview</b> in the center, however it serves a different purpose here. It's used
          for previewing themes, instead.
        </p>
      )
    },
    {
      target: '.intro-themes-navigator',
      placement: 'right',
      onAfterChange: showDesignRoute,

      title: 'Previewing themes [2/2]',
      content: (
        <>
          <p>
            To preview what your form would look like with a different theme applied, just select any theme from this list. It displays all
            of the currently available <b>stock themes</b> plus <b>custom themes</b> which you've created yourself within the{' '}
            <b>themes editor</b>.
          </p>
          <p>
            To permanently update a theme just click <dfn>Apply</dfn>, to open a theme editor click <dfn>Edit</dfn> or <dfn>Duplicate</dfn>.
          </p>
        </>
      )
    }
  ];

  const PART_4_MESSAGING = [
    {
      target: '.intro-left-bar-messaging',
      placement: 'right',
      spotlightPadding: 5,
      onAfterChange: showMessagingRoute,

      title: 'Messaging page [1/2]',
      titlePrefix: <Icon id="messaging" scale={1.2} />,
      content: (
        <>
          <p>
            Besides fields that you can add in the builder page, your form will also have some additional elements that QuestionScout will
            automatically manage for you. These elements include things like buttons, error messages, progress bars and so on.
          </p>
          <TutorialImg name="messaging_elements" />
        </>
      )
    },
    {
      target: '.intro-left-bar-messaging',
      placement: 'right',
      onAfterChange: showMessagingRoute,

      title: 'Messaging page [2/2]',
      titlePrefix: <Icon id="messaging" scale={1.2} />,
      content: (
        <>
          <p>
            <b>Messaging page</b> exists to provide you with a easy way to customize text visible on all of these elements. It's especially
            useful if you need to localize your form into a different language.
          </p>
          <p>Just find the right messages and modify them. You can also import messaging from some of your already existing forms.</p>
        </>
      )
    }
  ];

  const PART_5_NOTIFICATIONS = [
    {
      target: '.intro-left-bar-notifications',
      placement: 'right',
      spotlightPadding: 5,
      onAfterChange: showNotificationsRoute,

      title: 'Notifications page',
      titlePrefix: <Icon id="emails" scale={1.2} />,
      content: (
        <>
          <p>
            <b>Notifications page</b> is where you can manage everything email related. That includes:
          </p>
          <ul>
            <li>
              <b>Response Confirmations</b> - these are sent to your respondents when they complete a form,
            </li>
            <li>
              <b>Payment Confirmations</b> - these are sent to your respondents when they complete a payment,
            </li>
            <li>
              <b>Self Notifications</b> - these can be sent to you, account guests or account collaborators once a new response comes in.
            </li>
          </ul>
          <p>
            QuestionScout gives you a lot of flexibility in this regard. You can modify recipients, contents of the emails, and even set a
            custom SMTP to put more emphasis on your own brand.
          </p>
        </>
      )
    }
  ];

  const PART_6_INTEGRATIONS = [
    {
      target: '.intro-left-bar-connect',
      placement: 'right',
      spotlightPadding: 5,
      onAfterChange: showIntegrationsRoute,

      title: 'Integrations page',
      titlePrefix: <Icon id="integrations" scale={1.2} />,
      content: (
        <p>
          If you'll ever want to connect your form with some 3rd party app to enable some additional tracking, or to send all incoming
          responses somewhere outside of QuestionScout, you should visit the <b>integrations page</b>.
        </p>
      )
    },
    {
      target: '.intro-integrations-tab',
      placement: 'bottom',
      spotlightPadding: 7,
      onAfterChange: showIntegrationsRoute,

      title: 'Supported integrations',
      content: <p>In this tab you'll find a list of all currently supported 3rd party integrations.</p>
    },
    {
      target: '.intro-webhooks-tab',
      placement: 'right',
      spotlightPadding: 7,
      onAfterChange: showWebhooksRoute,

      title: 'Webhooks',
      content: (
        <p>
          You can also send collected data outside of QuestionScout through regular webhooks (i.e. a POST request with some JSON content).
          This is probably the most versatile solution that advanced users will appreciate.
        </p>
      )
    }
  ];

  const PART_7_PAYMENTS = [
    {
      target: '.intro-left-bar-payments',
      placement: 'right',
      spotlightPadding: 5,
      onAfterChange: showPaymentsRoute,

      title: 'Payments page',
      titlePrefix: <Icon id="payments" scale={1.2} />,
      content: (
        <>
          <p>
            As the name suggests, the <b>payments page</b> is a place where you can manage everything related to collecting payments through
            your form.
          </p>
          <p>
            First you'll need to select a payment method, after that you'll be able to specify how to determine the amount of money you want
            to collect (you can use our <b>calculations system</b> for that), and configure tax rates.
          </p>
          <p>Payments in QuestionScout forms are always collected at the end of a form.</p>
        </>
      )
    }
  ];

  const PART_8_SETTINGS = [
    {
      target: '.intro-left-bar-settings',
      placement: 'right',
      spotlightPadding: 5,
      onAfterChange: showSettingsRoute,

      title: 'Settings page [1/2]',
      titlePrefix: <Icon id="settings" scale={1.2} />,
      content: (
        <>
          <p>
            Last but not least, there is a <b>settings page.</b> This is were you can customize how your form will work.
          </p>
          <p>
            Most of these settings won't be very useful for very basic use cases, however if you're an advanced user, you will definitely
            make a use of them at some point.
          </p>
        </>
      )
    },
    {
      target: '.intro-left-bar-settings',
      placement: 'right',
      spotlightPadding: 5,
      onAfterChange: showSettingsRoute,

      title: 'Settings page [2/2]',
      titlePrefix: <Icon id="settings" scale={1.2} />,
      content: (
        <>
          <p>These advanced features include:</p>
          <ul>
            <li>
              <b>Partial responses</b> - With this setting enabled, the form will collect everything the respondent inserts into the form,
              even when they do not submit,
            </li>
            <li>
              <b>Not saving responses in QuestionScout</b> - we will collect and send you responses through integrations, but we won't store
              any response.
            </li>
          </ul>
        </>
      )
    },
    {
      target: '.intro-url-params-tab',
      placement: 'right',
      spotlightPadding: 7,
      onAfterChange: showUrlParamsRoute,

      title: 'Url parameters',
      content: (
        <>
          <p>
            Other advanced feature worth mentioning is <b>url parameters</b> support. You can use url parameters to:
          </p>
          <ul>
            <li>identify respondents by origin,</li>
            <li>pre-fill one or more text fields in your form,</li>
            <li>
              <u>chain multiple forms together</u>.
            </li>
          </ul>
        </>
      )
    },
    {
      target: '.intro-custom-code-tab',
      placement: 'right',
      spotlightPadding: 7,
      onAfterChange: showCustomCodeRoute,

      title: 'Custom HTML, CSS, and JS code',
      content: (
        <>
          <p>
            For even greated customizability, QuestionScout also supports adding custom HTML, CSS and JavaScript code before automatically
            generated form markup.
          </p>
          <p>Please keep in mind that for safety reasons, all custom code is verified by our team.</p>
        </>
      )
    }
  ];

  const PART_9_GOODBYE = [
    {
      onAfterChange: showBuildRoute,

      title: "That's it!",
      content: <p>Happy building!</p>
    }
  ];

  return [
    ...PART_1_START,
    ...PART_2_BUILD,
    ...PART_3_DESIGN,
    ...PART_4_MESSAGING,
    ...PART_5_NOTIFICATIONS,
    ...PART_6_INTEGRATIONS,
    ...PART_7_PAYMENTS,
    ...PART_8_SETTINGS,
    ...PART_9_GOODBYE
  ];
};

/* -------------------------------------------------------------------------- */
/*                                    HOOK                                    */
/* -------------------------------------------------------------------------- */

const FIELD_TYPES_WITH_ALL_PROPERTIES_TABS = ['radio', 'checkbox', 'dropdown', 'imageChoice'];

const useBuilderIntroductionSteps = () => {
  const { replace: navigateInApp } = useRouteChanger();

  // Required data
  const [temporaryCreatedFieldId, setTemporaryCreatedFieldId] = useState(null);
  const { form, createField, deleteField, refreshFormFieldsPages } = useContext(BuilderContext);
  const formId = form?._id;
  const fields = form?.fields;

  // Looking for an example field
  const demoField = useMemo(() => {
    if (!fields) return null;

    return fields.find((f) => FIELD_TYPES_WITH_ALL_PROPERTIES_TABS.indexOf(f.type) !== -1) || null;
  }, [fields]);

  const demoFieldId = demoField?._id ?? null;

  // Creating field if there is no field that can be used as an example,
  // it will be hidden in case it's added to a live form
  useEffect(() => {
    if (fields && !demoFieldId && !temporaryCreatedFieldId) {
      const newField = {
        ...generateFieldData(FIELD_TYPES_WITH_ALL_PROPERTIES_TABS[0]),
        section: 'root',
        position: Infinity,
        hidden: true
      };

      setTemporaryCreatedFieldId(newField._id);

      createField(newField);
      refreshFormFieldsPages();
    }
  }, [fields, demoFieldId, temporaryCreatedFieldId, createField, refreshFormFieldsPages]);

  // Deleting created fields on unmount
  useUnmount(() => {
    if (temporaryCreatedFieldId) {
      deleteField(temporaryCreatedFieldId);
      setTemporaryCreatedFieldId(null);
    }
  });

  // Steps
  return useMemo(
    () =>
      createBuilderIntroductionSteps({
        formId,
        demoFieldId,
        navigateInApp
      }),
    [formId, demoFieldId, navigateInApp]
  );
};

export default useBuilderIntroductionSteps;
