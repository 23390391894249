import styles from './CheckboxGroup.module.css';

import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { mapBy } from 'helpers';

import { Checkbox } from '../Checkbox/Checkbox';
import { Radio } from '../Radio/Radio';
import { Toggle } from '../Toggle/Toggle';
import Tooltip from '../Tooltip/Tooltip';

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

const INPUT_COMPONENTS = {
  checkbox: Checkbox,
  radio: Radio,
  toggle: Toggle
};

const getInputComponent = (InputComponent) => {
  return typeof InputComponent === 'string' ? INPUT_COMPONENTS[InputComponent] : InputComponent;
};

/* -------------------------------------------------------------------------- */
/*                                 LOGIC HOOK                                 */
/* -------------------------------------------------------------------------- */

const useCheckboxGroupLogic = ({ data, value: values, onChange, multiSelect, selectionLimitsMin = null, selectionLimitsMax = null }) => {
  /* ---------------------------------- STATE --------------------------------- */
  if (process.env.NODE_ENV === 'development') {
    if (multiSelect && !(values instanceof Set)) {
      throw new Error('DEBUG: if multiSelect=true, value has to be a set');
    }
  }

  const dataByValue = useMemo(() => mapBy(data, 'value'), [data]);

  let numberOfSelectedValues = 0;

  if (multiSelect) {
    // Only count these options that exist
    for (const value of values) {
      if (dataByValue.has(value)) {
        numberOfSelectedValues += 1;
      }
    }
  } else {
    // Only count option if it exists
    if (dataByValue.has(values)) {
      numberOfSelectedValues += 1;
    }
  }

  const canSelect = selectionLimitsMax === null ? true : numberOfSelectedValues < selectionLimitsMax;
  const canDeselect = selectionLimitsMin === null ? true : numberOfSelectedValues > selectionLimitsMin;

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const handleChange = (checkboxValue, nextState) => {
    nextState = Boolean(nextState);

    if (nextState === true && canSelect === false) return;
    if (nextState === false && canDeselect === false) return;

    if (multiSelect) {
      // New checkbox was selected
      if (nextState) {
        const nextValues = new Set(values);
        nextValues.add(checkboxValue);
        onChange(nextValues);
      }
      // Checkbox was deselected
      else {
        const nextValues = new Set(values);
        nextValues.delete(checkboxValue);
        onChange(nextValues);
      }
    } else {
      // In single mode, deselecting means that nothing is selected anymore
      const nextValue = nextState ? checkboxValue : null;
      onChange(nextValue);
    }
  };

  /* ------------------------------ PROP INJECTOR ----------------------------- */

  // Given checkbox value (key) will return value (is active) and onClick callback
  const getCheckboxProps = (checkboxValue) => {
    const isSelected = multiSelect ? values.has(checkboxValue) : values === checkboxValue;
    const handleClick = () => handleChange(checkboxValue, !isSelected);

    return {
      value: isSelected,
      onClick: handleClick
    };
  };

  return { getCheckboxProps, canSelect, canDeselect };
};

/* -------------------------------------------------------------------------- */
/*                               DEFAULT LAYOUT                               */
/* -------------------------------------------------------------------------- */

const CheckboxGroupDefault = ({ data, value, onChange, multiSelect, selectionLimitsMin, selectionLimitsMax, InputComponent }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const { getCheckboxProps } = useCheckboxGroupLogic({
    data,
    value,
    onChange,
    multiSelect,
    selectionLimitsMin,
    selectionLimitsMax
  });

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <div className={styles.checkboxGroupDefault}>
      {data.map((props) => {
        const checkboxValue = props.value;
        const { label } = props;
        const { value, onClick } = getCheckboxProps(checkboxValue);

        return (
          <React.Fragment key={checkboxValue}>
            <div className={styles.checkboxGroupDefaultInput}>
              <InputComponent value={value} onClick={onClick} />
            </div>
            <div className={styles.checkboxGroupDefaultLabel} onClick={onClick}>
              {label}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                             LARGE BLOCKS LAYOUT                            */
/* -------------------------------------------------------------------------- */

const CheckboxGroupBlocks = ({ data, value, onChange, multiSelect, selectionLimitsMin, selectionLimitsMax, InputComponent }) => {
  const { getCheckboxProps } = useCheckboxGroupLogic({
    data,
    value,
    onChange,
    multiSelect,
    selectionLimitsMin,
    selectionLimitsMax
  });

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <div className={styles.checkboxGroupBlocks}>
      {data.map((props) => {
        const checkboxValue = props.value;
        const { icon, label } = props;
        const { value, onClick } = getCheckboxProps(checkboxValue);

        return (
          <div key={checkboxValue} className={[styles.checkboxGroupBlocksBlock, value ? styles.active : ''].join(' ')} onClick={onClick}>
            {icon}
            <div className={styles.checkboxGroupBlocksLabel}>{label}</div>
            <InputComponent value={value} onClick={onClick} />
          </div>
        );
      })}
    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                            MINIMAL BLOCK LAYOUT                            */
/* -------------------------------------------------------------------------- */

const CheckboxGroupBlocksCompact = ({ data, value, onChange, selectionLimitsMin, selectionLimitsMax, multiSelect }) => {
  const { getCheckboxProps } = useCheckboxGroupLogic({
    data,
    value,
    onChange,
    multiSelect,
    selectionLimitsMin,
    selectionLimitsMax
  });

  const [tooltipSingletonSource, tooltipSingletonTarget] = Tooltip.useSingleton();

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <>
      <Tooltip
        singleton={tooltipSingletonSource}
        delay={[0, 300]}
        placement="top"
        popperOptions={{
          modifiers: [{ name: 'flip', enabled: false }]
        }}
      />
      <div className={styles.checkboxGroupBlocksCompact}>
        {data.map((props) => {
          const checkboxValue = props.value;
          const { icon, label } = props;
          const { value, onClick } = getCheckboxProps(checkboxValue);

          const contentJsx = (
            <div
              key={checkboxValue}
              className={[styles.checkboxGroupBlocksCompactBlock, value ? styles.active : ''].join(' ')}
              onClick={onClick}>
              {icon || label}
            </div>
          );

          if (label) {
            return (
              <Tooltip key={checkboxValue} content={label} singleton={tooltipSingletonTarget}>
                {contentJsx}
              </Tooltip>
            );
          } else {
            return contentJsx;
          }
        })}
      </div>
    </>
  );
};

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const CheckboxGroup = ({
  // Style
  className = '',
  theme = 'default',
  // Content
  label = null,
  placeholder = null,

  ...props
}) => {
  // Use provided input component or pick default based on multiSelect prop
  props.InputComponent = props.InputComponent || (props.multiSelect ? 'checkbox' : 'radio');
  props.InputComponent = getInputComponent(props.InputComponent);

  // Use provided min selection limit or default to 1 but only for radio
  props.selectionLimitsMin = props.selectionLimitsMin ? props.selectionLimitsMin : !props.multiSelect ? 1 : null;

  let checkboxesJsx = null;

  if (!props?.data?.length && placeholder) {
    checkboxesJsx = <div className={styles.placeholder}>{placeholder}</div>;
  } else if (theme === 'default') {
    checkboxesJsx = <CheckboxGroupDefault {...props} />;
  } else if (theme === 'blocks') {
    checkboxesJsx = <CheckboxGroupBlocks {...props} />;
  } else if (theme === 'blocks-compact') {
    checkboxesJsx = <CheckboxGroupBlocksCompact {...props} />;
  } else {
    throw new Error('DEBUG: Unknown theme ' + theme);
  }

  return (
    <div className={[className, styles.checkboxGroup].join(' ')}>
      {/* Label above checkboxes */}
      {label && <div className={styles.header}>{label}</div>}
      {/* Checkboxes */}
      {checkboxesJsx}
    </div>
  );
};

export default CheckboxGroup;
