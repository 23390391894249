import styles from './HelpCenter.module.css';

import React, { useContext, useCallback } from 'react';

import { Toggle, Button, TabsHeader, Text, Icon } from 'ui-components';
import { LinkButton } from 'components/shared';
import TutorialPicker from './TutorialPicker';

import TutorialsContext from '../tutorialsContext';

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */

const HelpCenter = React.memo(({ postTutorialMode, onRequestClose }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const { userAllowsAutoPlay, clearPlayedTutorials, updateAllowAutoPlayTutorials } = useContext(TutorialsContext);

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const handleResetPlayedTutorials = clearPlayedTutorials;

  const handleToggleAllowAutoPlay = useCallback(
    () => updateAllowAutoPlayTutorials(!userAllowsAutoPlay),
    [userAllowsAutoPlay, updateAllowAutoPlayTutorials]
  );

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <div className={styles.helpCenter}>
      {postTutorialMode ? (
        <div className={styles.headerSection}>
          <TabsHeader>
            That concludes this tutorial.
            <br />
            Interested in learning more?
          </TabsHeader>
          <Button onClick={onRequestClose} className={styles.closeButton} icon="close" theme="transparent" />
        </div>
      ) : (
        <div className={styles.headerSection}>
          <TabsHeader>Walkthroughs</TabsHeader>
          <Button onClick={onRequestClose} className={styles.closeButton} icon="close" theme="transparent" />
        </div>
      )}

      <TutorialPicker className={styles.tutorialPickerSection} onTutorialRequested={onRequestClose} />

      {/* Development helper */}
      {false && (
        <div className={styles.autoPlaySection}>
          <Button theme="black" onClick={handleResetPlayedTutorials}>
            Reset played tutorials
          </Button>
        </div>
      )}

      {false && (
        <div className={styles.autoPlaySection}>
          <div>Automatically play introductions</div>
          <Toggle value={userAllowsAutoPlay} onClick={handleToggleAllowAutoPlay} />
        </div>
      )}

      <div className={styles.knowledgeBaseSection}>
        <Icon id={'information'} />
        <Text theme="description">
          Need any more help? Check out our{' '}
          <LinkButton href="https://support.questionscout.com/" target="_blank">
            Knowledge Base
          </LinkButton>{' '}
          to see if you can find your answer there!
        </Text>
      </div>
    </div>
  );
});

export default HelpCenter;
