import styles from './Card.module.css';

import React from 'react';
import { Spinner } from '../Spinner/Spinner.js';

export const Card = ({ className, children, disabled, loading, ...rest }) => {
  return (
    <div className={[styles.card, className].join(' ')} {...rest}>
      {(disabled || loading) && <div className={styles.cardOverlay}>{loading && <Spinner size={30} />}</div>}
      {children}
    </div>
  );
};
