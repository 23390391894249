import styles from './Profile.module.css';

import React, { useContext, useEffect, useState } from 'react';
import asyncSocketAckEmit from 'helpers/asyncSocketAckEmit.js';

import { MainContext } from 'contexts/main.js';
import { ProfileContext } from 'contexts/profile.js';

import Menu from './Menu/Menu.js';
import Content from './Content/Content.js';

import { useStopScrollPropagationOnElement } from 'helpers';

const Profile = () => {
  const { user, profilePage, setProfilePage } = useContext(MainContext);

  /* ------------ TEMPORARY FIX FOR FORM PREVIEW FULLPAGE SCROLLING ----------- */

  const [container, setContainer] = useState(null);
  const [balance, setBalance] = useState(0);
  const [balanceLoading, setBalanceLoading] = useState(false);

  useStopScrollPropagationOnElement(container);

  const getBalance = async () => {
    if (user?.lifeTime?.enabled) {
      setBalance(user?.lifeTime.balance || 0);
    } else {
      setBalanceLoading(true);

      const { success, data } = await asyncSocketAckEmit('getUserBalance');

      if (success) setBalance(data.balance || 0);
  
      setBalanceLoading(false);
    }
  };

  useEffect(() => {
    getBalance();
  }, [user?.lifeTime?.balance]);

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <ProfileContext.Provider
      value={{
        page: profilePage,
        setPage: setProfilePage,
        balance,
        balanceLoading,
        getBalance
      }}>
      <div className={styles.profile} ref={setContainer}>
        <Menu />
        <Content />
      </div>
    </ProfileContext.Provider>
  );
};

export default Profile;
