import validateFormElementLogic from './validateFormElementLogic';

// Thank you pages are validated like fields (they only can't have calculations)
const validateFormNotifications = ({ notifications, fields, calculationVariables, smtp }) => {
  if (!notifications || !fields || !calculationVariables) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('DEBUG: missing data.');
    }
  }

  const errors = {};

  for (const notification of notifications) {
    const logicErrors = validateFormElementLogic({ element: notification, fields, calculationVariables });
    const notificationErrors = { ...logicErrors };

    if (notification.emails && !smtp) {
      notificationErrors.emails = 'To send emails to external recipients, please enable an SMTP configuration on this form';
    }

    if (Object.keys(notificationErrors).length) {
      errors[notification._id] = notificationErrors;
    }
  }

  return Object.keys(errors).length ? errors : false;
};

export default validateFormNotifications;
