import React from 'react';

const prepareSmtpsOptions = (smtps) => {
  return smtps.map((smtp, i) => {
    const username = smtp.username;
    const name = smtp.name;
    const hostname = smtp.hostname || <span style={{ color: '#F05252', fontWeight: '500' }}>[MISSING HOSTNAME]</span>;
    const port = smtp.port || <span style={{ color: '#F05252', fontWeight: '500' }}>[MISSING PORT]</span>;

    const label = (
      <>
        smtp://{username}:*****@{hostname}:{port}
        {Boolean(name && name !== hostname) && <> ({name})</>}
      </>
    );
    return {
      label: label,
      value: smtp._id
    };
  });
};

export default prepareSmtpsOptions;
