import styles from './ModalContent.module.css';

import React from 'react';

import TabsHeader from '../TabsHeader/TabsHeader';
import SuspenseFallback from '../SuspenseFallback/SuspenseFallback';

const ModalContent = ({ className = '', theme = 'default', style, contentStyle, loading, header, children, buttons }) => {
  return (
    <div className={[className, styles.modalContent, styles[`theme-${theme}`]].join(' ')} style={style}>
      {loading && <SuspenseFallback />}

      {header && (
        <div className={styles.header}>
          <TabsHeader>{header}</TabsHeader>
        </div>
      )}
      {children && (
        <div className={styles.content} style={contentStyle}>
          {children}
        </div>
      )}
      {buttons && <div className={styles.buttons}>{buttons}</div>}
    </div>
  );
};

export default ModalContent;
