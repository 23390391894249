import { useState, useCallback } from 'react';

/*
  Hook for handling file upload within FormEngine inside builder context
*/
const useFormEngineFilesProps = () => {
  /* ---------------------------------- STATE --------------------------------- */

  const [files, setFiles] = useState([]);

  /* -------------------------------- REDUCERS -------------------------------- */

  const deleteFile = useCallback(({ ref, fileId }) => {
    setFiles((files) => {
      const index = files.findIndex((f) => {
        if (ref) return f.ref === ref;
        if (fileId) return f._id === fileId;

        return false;
      });

      if (index !== -1) {
        const filesCopy = [...files];
        filesCopy.splice(index, 1);

        return filesCopy;
      } else {
        return files;
      }
    });
  }, []);

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const handleCancel = useCallback(
    (ref) => {
      return deleteFile({ ref });
    },
    [deleteFile]
  );

  const handleDelete = useCallback(
    (fileId, fieldId) => {
      deleteFile({ fileId });
    },
    [deleteFile]
  );

  const handleUpload = useCallback((newFiles, fieldId) => {
    setFiles((files) => {
      const filesToAdd = [];

      for (let f of newFiles) {
        filesToAdd.push({
          _id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, '')
            .substr(0, 5),
          ref: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, '')
            .substr(0, 5),
          size: f.size,
          loaded: 0,
          name: f.name,
          type: f.type,
          field: fieldId
        });
      }

      return [...files, ...filesToAdd];
    });
  }, []);

  /* ---------------------- PROPS ACCEPTED BY FORM ENGINE --------------------- */

  return {
    files,
    handleCancel,
    handleDelete,
    handleUpload
  };
};

export default useFormEngineFilesProps;
