export const V1_PLAN_PRICES = {
  solo: 49,
  agency: 149,
  business: 249
};

export const V2_PLAN_PRICES = {
  solo: 59,
  agency: 179,
  business: 299
};

export const BUSINESS_PRICE_OPTIONS = {
  0: 0,
  5: 100,
  10: 200,
  20: 300,
  30: 400,
  50: 500,
  70: 600,
  90: 700
};

export const V2_COLLABORATORS_OPTIONS = [
  {
    label: '10 collaborators',
    value: 0,
    price: 0
  },
  {
    label: '15 collaborators (+$100)',
    value: 5,
    price: 100
  },
  {
    label: '20 collaborators (+$200)',
    value: 10,
    price: 200
  },
  {
    label: '30 collaborators (+$300)',
    value: 20,
    price: 300
  },
  {
    label: '40 collaborators (+$400)',
    value: 30,
    price: 400
  },
  {
    label: '60 collaborators (+$500)',
    value: 50,
    price: 500
  },
  {
    label: '80 collaborators (+$600)',
    value: 70,
    price: 600
  },
  {
    label: '100 collaborators (+$700)',
    value: 90,
    price: 700
  }
];

export const V1_COLLABORATORS_OPTIONS = [
  {
    label: '10 Seats',
    value: 0,
    price: 0
  },
  {
    label: '11 Seats (+$20)',
    value: 1,
    price: 20
  },
  {
    label: '12 Seats (+$40)',
    value: 2,
    price: 40
  },
  {
    label: '13 Seats (+$60)',
    value: 3,
    price: 60
  },
  {
    label: '14 Seats (+$80)',
    value: 4,
    price: 80
  },
  {
    label: '15 Seats (+$100)',
    value: 5,
    price: 100
  },
  {
    label: '16 Seats (+$120)',
    value: 6,
    price: 120
  },
  {
    label: '17 Seats (+$140)',
    value: 7,
    price: 140
  },
  {
    label: '18 Seats (+$160)',
    value: 8,
    price: 160
  },
  {
    label: '19 Seats (+$180)',
    value: 9,
    price: 180
  },
  {
    label: '20 Seats (+$200)',
    value: 10,
    price: 200
  }
];
