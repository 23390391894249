import io from 'io.js';
import withTimeout from './withTimeout';

const ioEmitRequest = (event, params, requestConfig = {}) => {
  const { timeout = 5000, showToasts = true } = requestConfig;

  return new Promise((resolve, reject) => {
    if (window.QS?.verbose) {
      console.log(`%cREQUEST: ${event}`, 'color: #4685F1', params);
    }

    const handleResponse = (payload) => {
      if (window.QS?.verbose) {
        console.log(`%cRESPONSE: ${event}`, 'color: #0F9F6E', payload);
      }

      // Temporary
      if (payload?.success === false) {
        const showError = window.QS?.showError;
        const message = payload?.error || payload?.message || 'Something went wrong.';
        showToasts &&
          showError &&
          showError({
            message: message,
            footer: `Event ID: ${event}`
          });
      }

      resolve(payload);
    };

    const handleTimeout = () => {
      if (window.QS?.verbose) {
        console.log(`%cTIMEOUT: ${event}`, 'color: #F05252');
      }
      alert(
        'WARNING:\n\n' +
          "The Server didn't respond.\n" +
          'This is not an expected behavior.\n' +
          'Please check your internet connection and try again.\n\n' +
          `Event: ${event}.`
      );

      reject();
    };

    io.socket.emit(event, params, handleResponse);
  });
};

export default ioEmitRequest;
