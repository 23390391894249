import styles from './Label.module.css';

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Icon, IconAddWithLoader } from 'ui-components';

const Label = React.forwardRef(
  (
    {
      children,
      className,
      style,
      close,
      onClose,
      addButton,
      addButtonLabel,
      addButtonClassName,
      onAddButtonClick,
      actions,
      background = '#F6F5F4',
      borderTop,
      borderBottom,
      description,
      back,
      onBack
    },
    ref
  ) => {
    let classList = [styles.label, className, borderTop ? styles.borderTop : '', borderBottom ? styles.borderBottom : ''];

    return (
      <div ref={ref} className={classList.join(' ')} style={{ ...style, background }}>
        <div className={styles.labelContent}>
          <div className={styles.labelTextTop}>
            <div
              className={[styles.labelTextTopContent, back ? styles.withBack : ''].join(' ')}
              onClick={back && onBack ? onBack : () => {}}>
              {back && (
                <div className={styles.labelBack}>
                  <Icon id="dropdown-closed-thin" />
                </div>
              )}
              <span className={styles.labelText}>{children}</span>
              {close && (
                <div className={styles.labelClose} onClick={onClose}>
                  <Icon id="close" />
                </div>
              )}
            </div>

            {description && <div className={styles.labelDescription}>{description}</div>}
          </div>

          {addButton && (
            <div className={[styles.labelAddButton, addButtonClassName].join(' ')} onClick={onAddButtonClick}>
              <div className={styles.labelAddButtonLabel}>{addButtonLabel}</div>
              <IconAddWithLoader loading={false} />
            </div>
          )}
        </div>

        {actions && <div className={styles.labelActions}>{actions}</div>}
      </div>
    );
  }
);

export default Label;
