import styles from './QSFormStatus.module.css';

import React from 'react';

const QSFormStatus = React.memo(({ className = '', status, theme = 'default' }) => {
  return (
    <div className={[className, styles.formStatus, styles['status-' + status], styles['theme-' + theme]].join(' ')}>
      {String(status || 'unknown')}
    </div>
  );
});

export default QSFormStatus;
