import React from 'react';

const Element = (props) => {
  switch (props.type) {
    case 'mention':
      return <span>______</span>;
    default:
      return props.text;
  }
};

export default function parseMention(value) {
  let nodes = null;
  try {
    // Value is stringified object/array
    nodes = JSON.parse(value);
  } catch (e) {
    // Value was something else (probably text)
    nodes = [{ type: 'paragraph', children: [{ text: String(value) }] }];
  }

  if (!nodes || !Array.isArray(nodes)) return <></>;

  return nodes.map((node) => {
    return node.children.map((props, index) => <Element key={index} {...props} />);
  });
}
