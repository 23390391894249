import _sortBy from 'lodash/sortBy';

// Constants
const SHOW_CREATE_THEME_MODAL = 'theme/SHOW_CREATE_THEME_MODAL';
const HIDE_CREATE_THEME_MODAL = 'theme/HIDE_CREATE_THEME_MODAL';

const SHOW_DELETE_THEME_MODAL = 'theme/SHOW_DELETE_THEME_MODAL';
const HIDE_DELETE_THEME_MODAL = 'theme/HIDE_DELETE_THEME_MODAL';

const UPDATE_DEVICE = 'theme/UPDATE_DEVICE';
const UPDATE_PREVIEW_FORM = 'theme/UPDATE_PREVIEW_FORM';

const UPDATE_FORM_VALUE = 'theme/UPDATE_FORM_VALUE';
const GET_FORM_VALUES = 'theme/GET_FORM_VALUES';

const TOGGLE_MENU_LOADING = 'theme/TOGGLE_MENU_LOADING';

const UPDATE_PREVIEW_PAGE = 'theme/UPDATE_PREVIEW_PAGE';

const IO_UPDATE_THEME_COLORS_SUCCESS = 'io/IO_UPDATE_THEME_COLORS_SUCCESS';
const IO_UPDATE_THEME_COLORS_FAILURE = 'io/IO_UPDATE_THEME_COLORS_FAILURE';

// Initiual State
const initialState = {
  fonts: _sortBy([
    'Work Sans', 'IBM Plex Sans', 'Space Mono', 'Libre Franklin', 'Rubik', 'Cormorant', 'Fira Sans', 'Eczar', 'Alegreya Sans', 'Alegreya', 
    'Chivo', 'Lato', 'Source Sans Pro', 'Source Serif Pro', 'Roboto', 'Roboto Slab', 'BioRhyme', 'Poppins', 'Archivo', 'Libre Baskerville', 
    'Playfair Display', 'Karla', 'Montserrat', 'Proza Libre', 'Spectral', 'Domine', 'Inknut Antiqua', 'PT Sans', 'PT Serif', 
    'Neuton', 'Open Sans', 'Inconsolata', 'Cabin', 'Raleway', 'Anonymous Pro', 'Arvo', 'Merriweather', 'Fauna One', 'Quattrocento', 
    'Fanwood Text', 'Prata', 'Alfa Slab', 'Gentium Book Basic', 'Nixie One', 'Julius Sans One', 'Crimson Text', 'Oswald',
    'Old Standard TT', 'Questrial', 'Slabo', 'Sintony', 'Dancing Script', 'Muli', 'Rufina', 'Oxygen', 'Lora', 'Asap',
    'Teko', 'Anton', 'Alata', 'Lexend Deca', 'Markazi Text', 'Pridi', 'Rozha One', 'Bree Serif',
    'Arbutus Slab', 'Hammersmith One', 'Alef', 'Prompt', 'Inter'
  ].map((font) => {
    return { label: font, value: font };
  }), (font) => font.value),

  device: 'desktop',
  previewForm: null,
  previewPage: 1,
  values: {},
  menuLoading: false,

  createThemeModal: {
    baseTheme: 'default-light',
    _show: false,
    formId: null
  },

  deleteThemeModal: {
    theme: null,
    _show: false
  }
};

// Reducer
export default function reducer(state = JSON.parse(JSON.stringify(initialState)), action = {}) {
  if (action.type === UPDATE_DEVICE) {
    state.device = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_PREVIEW_FORM) {
    state.previewForm = action.payload.value;
    state.previewPage = 1;

    return { ...state };
  }

  // createThemeModal
  if (action.type === SHOW_CREATE_THEME_MODAL) {
    state.createThemeModal._show = true;
    state.createThemeModal.baseTheme = action.payload.baseTheme;
    state.createThemeModal.formId = action.payload.formId;

    return { ...state };
  }

  if (action.type === HIDE_CREATE_THEME_MODAL) {
    state.createThemeModal = { ...initialState.createThemeModal };

    return { ...state };
  }

    // deleteThemeModal
  if (action.type === SHOW_DELETE_THEME_MODAL) {
    state.deleteThemeModal._show = true;
    state.deleteThemeModal.theme = action.payload.theme;

    return { ...state };
  }

  if (action.type === HIDE_DELETE_THEME_MODAL) {
    state.deleteThemeModal._show = false;
    state.deleteThemeModal = { ...initialState.deleteThemeModal };

    return { ...state };
  }

  if (action.type === GET_FORM_VALUES) {
    const values = {};
    const fields = [ ...action.payload.fields ];

    fields.map((field) => {
      values[field._id] = {
        value: field.value || null,
        type: field.type,
        visible: true,
        isLast: false,
        isFirst: false,
        position: field.position,
        hidden: field.hidden,
        section: field.section
      };

      if (field.type === 'checkbox' && field.selectionLimits && field.selectionLimitsMin > field.selectionLimitsMax) {
        field.selectionLimits = false;
      }

      return field;
    });

    for (const [key, obj] of Object.entries(values).sort((a, b) => {
      if (a[1].position > b[1].position) return -1;
      if (a[1].position < b[1].position) return 1;

      return 0;
    })) {
      if (obj.visible && !obj.hidden) {
        values[key].isLast = true;

        break;
      }
    }

    for (const [key, obj] of Object.entries(values).sort((a, b) => {
      if (a[1].position < b[1].position) return -1;
      if (a[1].position > b[1].position) return 1;

      return 0;
    })) {
      if (obj.visible && !obj.hidden) {
        values[key].isFirst = true;

        break;
      }
    }

    state.values = JSON.parse(JSON.stringify(values));

    return { ...state };
  }

  if (action.type === UPDATE_FORM_VALUE) {
    state.values[Object.keys(action.payload)[0]].value = action.payload[Object.keys(action.payload)[0]];
    state.values = JSON.parse(JSON.stringify(state.values));

    return { ...state };
  }

  if (action.type === UPDATE_PREVIEW_PAGE) {
    state.previewPage = action.payload.page;

    return { ...state };
  }

  if (action.type === TOGGLE_MENU_LOADING) {
    state.menuLoading = action.payload.value;

    return { ...state };
  }  

  if (action.type === IO_UPDATE_THEME_COLORS_SUCCESS || action.type === IO_UPDATE_THEME_COLORS_FAILURE) {
    state.menuLoading = false;

    return { ...state };
  }

  return state;
}

// Action Creators
export function toggleMenuLoading(value) {
  return (dispatch) => {
    dispatch({ type: TOGGLE_MENU_LOADING, payload: { value } });
  };
}

export function updatePreviewPage(page) {
  return (dispatch) => {
    dispatch({ type: UPDATE_PREVIEW_PAGE, payload: { page } });
  };
}

export function getFormValues(fields) {
  return (dispatch) => {
    dispatch({ type: GET_FORM_VALUES, payload: { fields } });

    return Promise.resolve();
  };
}

export function updateFormValue(valueObject) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FORM_VALUE, payload: valueObject });

    return Promise.resolve();
  };
}

export function updateDevice(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_DEVICE, payload: { value } });
  };
}

export function updatePreviewForm(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_PREVIEW_FORM, payload: { value } });
  };
}

// createFormsModal
export function showCreateThemeModal(baseTheme, formId) {
  return (dispatch) => {
    dispatch({ type: SHOW_CREATE_THEME_MODAL, payload: { baseTheme, formId } });
  };
}

export function hideCreateThemeModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_CREATE_THEME_MODAL });
  };
}

// deleteThemeModal
export function showDeleteThemeModal(theme) {
  return (dispatch) => {
    dispatch({ type: SHOW_DELETE_THEME_MODAL, payload: { theme } });
  };
}

export function hideDeleteThemeModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_DELETE_THEME_MODAL });
  };
}
