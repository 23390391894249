import styles from './AddEditSmtpPanel.module.css';

import React, { useState, useEffect, useContext, useRef } from 'react';
import io from 'io.js';
import validator from 'validator';

import { Panel, Spinner, Button, Input, Select, Hr, FormRow } from 'ui-components';

import { MainContext } from 'contexts/main.js';

const authenticationMethodOptions = [
  { label: 'PLAIN', value: 'PLAIN' },
  { label: 'LOGIN', value: 'LOGIN' },
  { label: 'CRAM-MD5', value: 'CRAM-MD5' }
];

const portOptions = [
  { label: '587', value: '587' },
  { label: '25', value: '25' },
  { label: '465', value: '465' },
  { label: '2525', value: '2525' }
];

const AddEditSmtpPanel = ({ show, onClose, smtp }) => {
  const { getAndUpdateSmtps } = useContext(MainContext);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [testEmptySenderEmail, setTestEmptySenderEmail] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [testSuccess, setTestSuccess] = useState(null);
  const [testMessage, setTestMessage] = useState(null);

  const [name, setName] = useState('');
  const [hostname, setHostname] = useState('');
  const [port, setPort] = useState('587');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [defaultSenderEmail, setDefaultSenderEmail] = useState('');
  const [authenticationMethod, setAuthenticationMethod] = useState('PLAIN');

  const isEdit = smtp && smtp._id;

  const cancel = () => {
    setLoading(false);
    setSuccess(false);
    setErrorMessage('');

    setTestEmptySenderEmail(false);
    setTestLoading(false);
    setTestSuccess(null);
    setTestMessage(null);

    setName('');
    setHostname('');
    setPort('587');
    setUsername('');
    setPassword('');
    setShowPassword(false);
    setDefaultSenderEmail('');
    setAuthenticationMethod('PLAIN');

    onClose();
  };

  const submit = () => {
    if (loading) return;

    setLoading(true);
    setErrorMessage('');

    const onResponse = (payload) => {
      if (window.QS?.verbose) {
        console.log(`%cRESPONSE: createSmtp/updateSmtp`, 'color: #0F9F6E', payload);
      }

      setLoading(false);

      if (!payload.success) {
        setErrorMessage(payload.message);
        return;
      }

      setSuccess(true);

      setTimeout(() => {
        getAndUpdateSmtps();
        cancel();
      }, 1000);
    };

    let props = { name, hostname, port, username, defaultSenderEmail, authenticationMethod };

    if (smtp && smtp._id) props._id = smtp._id;
    if (showPassword) props.password = password;

    if (!isEdit) io.socket.once('createSmtp', onResponse);
    if (isEdit) io.socket.once('updateSmtp', onResponse);

    if (!isEdit) {
      if (window.QS?.verbose) {
        console.log(`%cREQUEST: createSmtp`, 'color: #4685F1', props);
      }

      io.socket.emit('createSmtp', props);
    }
    if (isEdit) {
      if (window.QS?.verbose) {
        console.log(`%cREQUEST: updateSmtp`, 'color: #4685F1', props);
      }

      io.socket.emit('updateSmtp', props);
    }
  };

  const handleEnterKeyPress = (e) => {
    if (loading || e.key !== 'Enter') return;

    submit();
  };

  const test = async () => {
    if (!validator.isEmail(defaultSenderEmail || '')) return setTestEmptySenderEmail(true);

    setTestLoading(true);

    let props = { _id: smtp ? smtp._id : null, hostname, port, username, defaultSenderEmail, authenticationMethod };

    if (showPassword) props.password = password;

    io.socket.once('testSmtp', (payload) => {
      setTestLoading(false);

      if (payload) {
        setTestSuccess(payload.success);
        setTestMessage(payload.response);
      }
    });

    io.socket.emit('testSmtp', props);
  };

  useEffect(() => {
    setName(smtp ? smtp.name : '');
    setHostname(smtp ? smtp.hostname : '');
    setHostname(smtp && smtp.hostname ? smtp.hostname : '');
    setPort(smtp && smtp.port ? smtp.port : '587');
    setUsername(smtp && smtp.username ? smtp.username : '');
    setPassword('');
    setShowPassword(smtp ? false : true);
    setDefaultSenderEmail(smtp && smtp.defaultSenderEmail ? smtp.defaultSenderEmail : '');
    setAuthenticationMethod(smtp && smtp.authenticationMethod ? smtp.authenticationMethod : 'PLAIN');
  }, [smtp]);

  return (
    <Panel
      mainStyle={{
        height: '90vh'
      }}
      coverStyle={{
        width: '80vw',
        height: '90vh',
        maxWidth: '950px',
        minWidth: '850px',
        left: '-200px'
      }}
      className={styles.main}
      title={isEdit ? 'Edit your SMTP' : 'Add a new SMTP'}
      show={show}
      loading={loading}
      success={success}
      buttons={[
        <Button theme="white" onClick={() => !loading && cancel()}>
          Cancel
        </Button>,
        <Button theme="black" onClick={() => submit()}>
          {isEdit ? 'Edit smtp' : 'Create SMTP'}
        </Button>
      ]}
      onOutsideClick={() => !loading && cancel()}>
      <div className={styles.content}>
        <FormRow label="Name" errorMessage={errorMessage} disabled="Used">
          <Input
            value={name}
            error={errorMessage}
            width={'100%'}
            placeholder="My company SMTP"
            onKeyPress={handleEnterKeyPress}
            onChange={(value) => setName(value)}
          />
        </FormRow>

        <Hr />

        <p>Please refer to your email providers’ DNS documentation to ensure you provide the correct information.</p>

        <FormRow label="SMTP Host" errorMessage={errorMessage}>
          <Input
            value={hostname}
            error={errorMessage}
            width={'100%'}
            placeholder="smtp.example.com"
            onKeyPress={handleEnterKeyPress}
            onChange={(value) => setHostname(value)}
          />
        </FormRow>

        <FormRow label="Port">
          <Select
            width="100%"
            options={portOptions}
            menuPortalTarget={document.body}
            value={port}
            onChange={(selected) => setPort(selected.value)}
          />
        </FormRow>

        <FormRow label="Authentication Method">
          <Select
            width="100%"
            options={authenticationMethodOptions}
            menuPortalTarget={document.body}
            value={authenticationMethod}
            onChange={(selected) => setAuthenticationMethod(selected.value)}
          />
        </FormRow>

        <FormRow label="Username">
          <Input
            value={username}
            placeholder="username"
            autoComplete="off"
            width={'100%'}
            onKeyPress={handleEnterKeyPress}
            onChange={(value) => setUsername(value)}
          />
        </FormRow>

        <FormRow label="Password">
          {showPassword && (
            <Input
              type="password"
              value={password}
              autoComplete="off"
              placeholder="password"
              width={'100%'}
              onKeyPress={handleEnterKeyPress}
              onChange={(value) => setPassword(value)}
            />
          )}
          {!showPassword && (
            <Button theme="white" onClick={() => setShowPassword(true)}>
              Edit password
            </Button>
          )}
        </FormRow>

        <FormRow
          label="Default Sender Email"
          errorMessage={testEmptySenderEmail ? 'Valid sender email is required to test your SMTP settings.' : null}>
          <Input
            value={defaultSenderEmail}
            error={testEmptySenderEmail}
            width={'100%'}
            placeholder="john@example.com"
            onKeyPress={handleEnterKeyPress}
            onChange={(value) => {
              if (value && value.length > 0) setTestEmptySenderEmail(false);
              setDefaultSenderEmail(value);
            }}
          />
        </FormRow>

        <div className={[styles.test, testSuccess === true ? styles.success : '', testSuccess === false ? styles.error : ''].join(' ')}>
          {!testMessage && (
            <>
              <div className={styles.testLabel}>Check this configuration</div>
              <p>Press the “Test configuration” button below to check the validity of your input.</p>
            </>
          )}
          {testMessage && <p>{testMessage}</p>}
          <Button size="small" disabled={testLoading} theme="white" onClick={() => test()} margin="0 0 0 0">
            Test configuration
          </Button>
          {testLoading && (
            <div className={styles.spinner}>
              <Spinner size={20} borderSize={2} speed="0.8s" />
            </div>
          )}
        </div>
      </div>
    </Panel>
  );
};

export default AddEditSmtpPanel;
