import config from '..';
import features from '../features';

export const identifyFeatureByFormPath = (path) => {
  // If feature name directly corresponds to form path
  if (path in features) {
    return path;
  } else {
    switch (path) {
      case 'seo.enabled':
        return 'customSeo';

      case 'domain.enabled':
        return 'customDomain';

      case 'confirmationEmailCustomContent':
      case 'confirmationPaymentEmailCustomContent':
        return 'customEmail';

      default: {
        if (path.indexOf('.redirect') !== -1) {
          return 'redirectOnCompletion';
        } else {
          // This path is not connected to any premium feature
          return null;
        }
      }
    }
  }
};

const identifyFeaturesByFormPaths = (paths) => {
  const featuresSet = new Set();

  for (const path of paths) {
    const featureId = identifyFeatureByFormPath(path);
    if (featureId) {
      featuresSet.add(featureId);
    }
  }

  const featureIds = [...featuresSet];

  if (process.env.NODE_ENV === 'development') {
    for (const featureId of featureIds) {
      if (!(featureId in config.features)) {
        throw new Error('DEBUG: unknown feature id ' + featureId);
      }
    }
  }

  return featureIds;
};

export default identifyFeaturesByFormPaths;
