import styles from './Input.module.css';

import React, { forwardRef, useState, useMemo } from 'react';

import config from 'config.js';

export const Input = forwardRef(
  (
    {
      className,
      style,
      multiline,
      rows,
      fullWidth = true,
      compact = false,

      type = 'text',

      onChange,
      value,
      onFocus,
      onBlur,
      success,
      error,
      disabled,

      placeholder,
      before,
      after,
      prefix,
      suffix,

      selectOnFocus,

      ...rest
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false);

    // Props used both by input and textarea
    const commonInputProps = useMemo(() => {
      return {
        type,
        placeholder,
        className: styles.input,
        disabled,
        onChange: (e) => {
          onChange(e.target.value);
        },
        onFocus: (e) => {
          if (selectOnFocus) {
            e.target.select();
          }
          setFocused(true);
          if (onFocus) onFocus(e);
        },
        onBlur: (e) => {
          setFocused(false);
          if (onBlur) onBlur(e);
        }
      };
    }, [type, disabled, placeholder, selectOnFocus, onFocus, onBlur, onChange]);

    // Styling props for wrapper
    const wrapperProps = useMemo(() => {
      return {
        style: style,
        className: [
          className,
          ['password', 'email'].indexOf(type) !== -1 ? config.fullStoryExcludeClass : '',
          styles.inputWrapper,
          disabled ? styles.disabled : '',
          compact ? styles.compact : '',
          focused ? styles.focused : '',
          success ? styles.success : '',
          error ? styles.error : '',
          fullWidth ? styles.fullWidth : '',
          multiline ? styles.multiline : ''
        ].join(' ')
      };
    }, [style, type, className, disabled, focused, error, success, fullWidth, compact, multiline]);

    /* ----------------------------------- JSX ---------------------------------- */

    return (
      <label {...wrapperProps}>
        {suffix && <div className={styles.affix}>{suffix}</div>}
        <div className={styles.innerWrapper}>
          {before && <span>{before}</span>}
          {multiline ? (
            <textarea value={!value && value !== 0 ? '' : value} {...commonInputProps} rows={rows} {...rest} ref={ref} />
          ) : (
            <input value={!value && value !== 0 ? '' : value} {...commonInputProps} {...rest} ref={ref} />
          )}
          {after && <span>{after}</span>}
        </div>
        {prefix && <div className={styles.affix}>{prefix}</div>}
      </label>
    );
  }
);
