const fields = [
  /* --------------------------------- INPUTS --------------------------------- */

  {
    _id: 'shortText',
    name: 'Short Answer',
    category: 'inputs',
    icon: require('assets/images/fields-v3/shortText.svg'),
    type: 'shortText',
    valueMaxLength: 1000,
    custom: {}
  },
  {
    _id: 'longText',
    name: 'Long Answer',
    category: 'inputs',
    icon: require('assets/images/fields-v3/longText.svg'),
    type: 'longText',
    valueMaxLength: 5000,
    custom: {}
  },
  {
    _id: 'shortTextemail',
    name: 'Email Address',
    category: 'inputs',
    icon: require('assets/images/fields-v3/shortTextemail.svg'),
    type: 'shortText',
    valueMaxLength: 1000,
    custom: {
      format: 'email'
    }
  },
  {
    _id: 'shortTextphone',
    name: 'Phone Number',
    category: 'inputs',
    icon: require('assets/images/fields-v3/shortTextphone.svg'),
    type: 'shortText',
    valueMaxLength: 1000,
    custom: {
      format: 'phone'
    }
  },
  {
    _id: 'datetime',
    name: 'Date/Time',
    category: 'inputs',
    icon: require('assets/images/fields-v3/datetime.svg'),
    type: 'datetime',
    custom: {
      dateTimeMode: 'datetime',
      dateTimeFormat: 'day.month.year hour:minute',
      dateTimeDateSeparator: '.',
      dateTimeHourMode: '12',
      dateTimeTimeIncrement: '5'
    }
  },
  {
    _id: 'shortTextnumber',
    name: 'Number',
    category: 'inputs',
    icon: require('assets/images/fields-v3/shortTextnumber.svg'),
    type: 'shortText',
    valueMaxLength: 1000,
    custom: {
      format: 'number'
    }
  },

  /* ------------------------------- SELECTIONS ------------------------------- */

  {
    _id: 'checkbox',
    name: 'Multiple Choice',
    category: 'selections',
    icon: require('assets/images/fields-v3/checkbox.svg'),
    type: 'checkbox',
    valueMaxLength: 300,
    options: [
      {
        value: 'Option 1',
        label: null
      },
      {
        value: 'Option 2',
        label: null
      },
      {
        value: 'Option 3',
        label: null
      }
    ],
    custom: {
      value: JSON.stringify({ values: [], other: null })
    }
  },
  {
    _id: 'radio',
    name: 'Single Choice',
    category: 'selections',
    icon: require('assets/images/fields-v3/radio.svg'),
    type: 'radio',
    options: [
      {
        value: 'Option 1',
        label: null
      },
      {
        value: 'Option 2',
        label: null
      },
      {
        value: 'Option 3',
        label: null
      }
    ],
    custom: {
      value: JSON.stringify({ value: null, other: null })
    }
  },
  {
    _id: 'imageChoice',
    name: 'Image Choice',
    category: 'selections',
    icon: require('assets/images/fields-v3/imageChoice.svg'),
    type: 'imageChoice',
    options: [
      {
        url: null,
        text: null
      }
    ],
    custom: {
      imageChoiceRows: 4
    }
  },
  {
    _id: 'dropdown',
    name: 'Dropdown',
    category: 'selections',
    icon: require('assets/images/fields-v3/dropdown.svg'),
    type: 'dropdown',
    options: [
      {
        value: 'Option 1',
        label: null
      },
      {
        value: 'Option 2',
        label: null
      },
      {
        value: 'Option 3',
        label: null
      }
    ],
    custom: {}
  },
  {
    _id: 'scale',
    name: 'Scale',
    category: 'selections',
    icon: require('assets/images/fields-v3/scale.svg'),
    type: 'scale',
    custom: {
      scaleRange: [0, 10],
      scaleTextLeft: 'Least Likely',
      scaleTextCenter: '',
      scaleTextRight: 'Most Likely'
    }
  },
  {
    _id: 'matrix',
    name: 'Matrix',
    category: 'selections',
    icon: require('assets/images/fields-v3/matrix.svg'),
    type: 'matrix',
    options: [
      {
        value: 'Row one',
        label: null
      },
      {
        value: 'Row two',
        label: null
      }
    ],
    additionalOptions: [
      {
        value: 'Negative',
        label: null
      },
      {
        value: 'Neutral',
        label: null
      },
      {
        value: 'Positive',
        label: null
      }
    ],
    custom: {
      value: JSON.stringify([]),
      selectionLimitsMax: 1
    }
  },

  /* ---------------------------------- MEDIA --------------------------------- */

  {
    _id: 'fileUpload',
    name: 'File Upload',
    category: 'media',
    icon: require('assets/images/fields-v3/fileUpload.svg'),
    type: 'fileUpload',
    custom: {
      value: JSON.stringify([]),
      fileUploadExtensions: [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
        'application/rtf',
        'application/vnd.oasis.opendocument.text',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.oasis.opendocument.presentation',
        'application/vnd.oasis.opendocument.spreadsheet',
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/x-iwork-numbers-sffnumbers',
        'application/x-iwork-keynote-sffke',
        'image/png',
        'image/jpeg',
        'image/gif',
        'application/json',
        'text/xml',
        'application/zip',
        'application/x-rar-compressed',
        'audio/mpeg',
        'audio/wav',
        'audio/aiff',
        'application/pbix'
      ],
      fileUploadMaxFiles: 1
    }
  },
  {
    _id: 'signature',
    name: 'Signature',
    category: 'media',
    icon: require('assets/images/fields-v3/signature.svg'),
    type: 'signature',
    custom: {
      signatureAllowTyping: true
    }
  },

  /* ------------------------------- STRUCTURAL ------------------------------- */

  {
    _id: 'title',
    name: 'Title',
    category: 'structural',
    icon: require('assets/images/fields-v3/title.svg'),
    type: 'title',
    custom: {}
  },
  {
    _id: 'description',
    name: 'Description',
    category: 'structural',
    icon: require('assets/images/fields-v3/description.svg'),
    type: 'description',
    value: 'This is a <strong>Description</strong> field',
    custom: {}
  },
  {
    _id: 'image',
    name: 'Image',
    category: 'structural',
    icon: require('assets/images/fields-v3/image.svg'),
    type: 'image',
    custom: {
      imageAlign: 'center',
      imageScale: 100
    }
  },
  {
    _id: 'section',
    name: 'Section',
    category: 'structural',
    icon: require('assets/images/fields-v3/section.svg'),
    type: 'section',
    custom: {}
  },
  {
    _id: 'divider',
    name: 'Divider',
    category: 'structural',
    icon: require('assets/images/fields-v3/divider.svg'),
    type: 'divider',
    custom: {}
  },
  {
    _id: 'pageBreak',
    name: 'Page Break',
    category: 'structural',
    icon: require('assets/images/fields-v3/pageBreak.svg'),
    type: 'pageBreak',
    custom: {}
  },
  {
    _id: 'welcomePage',
    name: 'Welcome Page',
    category: 'pages',
    icon: require('assets/images/fields-v3/welcomePage.svg'),
    custom: {}
  },
  {
    _id: 'thankYouPage',
    name: 'Thank You Page',
    category: 'pages',
    icon: require('assets/images/fields-v3/thankYouPage.svg'),
    custom: {}
  }
];

export default fields;
