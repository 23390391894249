import styles from './CheckLabel.module.css';

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Checkbox } from '../../Checkbox/Checkbox.js';
import Hint from '../../Hint/Hint';

const CheckLabel = React.forwardRef(({ label, error, style, className, value, onClick, disabled, borderTop, borderBottom }, ref) => {
  let classList = [
    styles.checkLabel,
    className,
    borderTop ? styles.borderTop : '',
    borderBottom ? styles.borderBottom : '',
    disabled ? styles.disabled : ''
  ];

  const handleClick = () => {
    if (disabled) return;
    onClick(!value);
  };

  return (
    <div ref={ref} style={style} className={classList.join(' ')} onClick={handleClick}>
      {label}
      {error && <Hint icon="warning" children={error} />}
      <Checkbox onClick={() => {}} value={value} style={{ padding: '0 0 0 5px' }} />
    </div>
  );
});

export default CheckLabel;
