import styles from './SubmissionFieldHeader.module.css';

import React, { useState, useCallback } from 'react';
import _memoize from 'lodash/memoize';

import { Popover, OpenCloseIcon, SortingMatrix } from 'ui-components';
import { parseMention } from 'helpers';
import { identifySubmissionColumn } from 'config.js';

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

const getSortingDirectionLabels = _memoize((sortsAs) => {
  const sortingDirectionOptions = SortingMatrix.DIRECTION_OPTIONS_BY_DATA_TYPE[sortsAs];
  const labels = {};
  for (const option of sortingDirectionOptions) {
    labels[option.value] = sortsAs !== 'string' ? option.label.toLowerCase() : option.label;
  }
  return labels;
});

const getColumnLabel = (column) => {
  return parseMention(column.label);
};

/* -------------------------------------------------------------------------- */
/*                             DROPDOWN COMPONENT                             */
/* -------------------------------------------------------------------------- */

const SubmissionFieldHeaderDropdown = React.memo(({ isHidden, isSticky, sortsAs, filtersAs, onOptionClick }) => {
  const directionLabels = sortsAs ? getSortingDirectionLabels(sortsAs) : null;

  return (
    <Popover.Group>
      {sortsAs ? (
        <>
          <Popover.Button icon="sort_down" onClick={() => onOptionClick('sort-asc')}>
            Sort by {directionLabels.asc}
          </Popover.Button>
          <Popover.Button icon="sort_up" onClick={() => onOptionClick('sort-desc')}>
            Sort by {directionLabels.desc}
          </Popover.Button>
        </>
      ) : null}
      {isSticky ? (
        <Popover.Button icon="unfreeze" onClick={() => onOptionClick('unfreeze')}>
          Unfreeze column
        </Popover.Button>
      ) : (
        <Popover.Button icon="freeze" onClick={() => onOptionClick('freeze')}>
          Freeze column
        </Popover.Button>
      )}
      {filtersAs ? (
        <>
          <Popover.Button icon="filter" onClick={() => onOptionClick('filter')}>
            Add to filters
          </Popover.Button>
        </>
      ) : null}
      {isHidden ? (
        <Popover.Button icon="hide" onClick={() => onOptionClick('show')}>
          Show column
        </Popover.Button>
      ) : (
        <Popover.Button icon="hide" onClick={() => onOptionClick('hide')}>
          Hide column
        </Popover.Button>
      )}
    </Popover.Group>
  );
});

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const SubmissionFieldHeader = React.memo(({ theme = 'for-table', column, onAction }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  // Column data and config
  const { _id: columnId, type: columnType, sticky: isSticky, hidden: isHidden } = column;
  const { icon, sortsAs, filtersAs } = identifySubmissionColumn(column);

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const handleOptionClick = useCallback(
    (action) => {
      setIsDropdownVisible(false);
      onAction({ type: action, columnId, columnType });
    },
    [onAction, columnId, columnType]
  );

  /* ----------------------------------- JSX ---------------------------------- */

  const headerContentJsx = (
    <>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.label}>{getColumnLabel(column)}</div>
    </>
  );

  const className = [styles.submissionFieldHeader, styles[`theme-${theme}`]].join(' ');

  if (!onAction) {
    // If no action handler was provided this component only displays icon and label
    return <button className={className}>{headerContentJsx}</button>;
  } else {
    // If there is action handler there will be a dropdown that offers some actions
    return (
      <Popover
        theme="black"
        visible={isDropdownVisible}
        onRequestClose={() => setIsDropdownVisible(false)}
        hideOnScroll={true}
        content={
          <SubmissionFieldHeaderDropdown
            isSticky={isSticky}
            isHidden={isHidden}
            sortsAs={sortsAs}
            filtersAs={filtersAs}
            onOptionClick={handleOptionClick}
          />
        }>
        <button className={className} onClick={() => setIsDropdownVisible(!isDropdownVisible)} style={{ cursor: 'pointer' }}>
          {headerContentJsx}
          <div className={styles.dropdownIcon}>
            <OpenCloseIcon opened={isDropdownVisible} />
          </div>
        </button>
      </Popover>
    );
  }
});

export default SubmissionFieldHeader;
