import { useQuery } from 'react-query';
import { useQueryParam } from '../../providers';
import submissionsQueries from './submissionsQueries';

/*
  Chart should be JSON like:
  {
    groupedByPrimary: string, 
    groupedBySecondary: string | null, 
    accumulator: {as: string, of: string | null}
  }
*/

export const useSubmissionsChartData = (props) => {
  /* --------------------------------- PARAMS --------------------------------- */

  const { chart } = props;

  const formId = useQueryParam('formId', null);

  /* --------------------------------- QUERIES -------------------------------- */

  const { data, error, isLoading } = useQuery({
    queryFn: submissionsQueries.getSubmissionsChartData.fn,
    queryKey: submissionsQueries.getSubmissionsChartData.keyFn({ formId, chart }),
    structuralSharing: false,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    suspense: true
  });

  return {
    error,
    isLoading,
    data
  };
};
