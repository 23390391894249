import styles from './EmptyHint.module.css';

import React from 'react';
import EmptyHint from '../../EmptyHint/EmptyHint.js';

export default React.forwardRef(
  ({ style, className, children, icon, borderTop = false, borderBottom = false, background = '#F6F5F4' }, ref) => {
    const classList = [className, borderTop ? styles.borderTop : '', borderBottom ? styles.borderBottom : ''];

    return (
      <EmptyHint ref={ref} background={background} icon={icon} style={style} className={classList.join(' ')}>
        {children}
      </EmptyHint>
    );
  }
);
