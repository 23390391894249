import styles from './CreditCard.module.css';

import React, { useState, useEffect, Fragment, useRef } from 'react';
import { CardElement } from '@stripe/react-stripe-js';

const CreditCard = ({ error, onChange }) => {
  const [focused, setFocused] = useState(false);

  return (
    <div className={[styles.creditCard, error ? styles.error : ''].join(' ')}>
      <div className={[styles.creditCardInputBackground, focused ? styles.active : ''].join(' ')}></div>
      <div className={[styles.creditCardInput, focused ? styles.active : ''].join(' ')}>
        <CardElement
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={onChange}
          style={{
            base: {
              fontSize: '13px',
              color: '#687680'
            }
          }}
        />
      </div>
    </div>
  );
};

export default CreditCard;
