import axios from 'axios';
import config from 'config.js';

// Constants
const APP_IS_READY = 'main/APP_IS_READY';
const INTERCOM_IS_READY = 'main/INTERCOM_IS_READY';
const UPDATE_LOCATION_WITH_IO = 'main/UPDATE_LOCATION_WITH_IO';
const SET_CHANGE_INDICATOR_STATUS = 'main/SET_CHANGE_INDICATOR_STATUS';

const GET_USER_FAILURE = '_users/GET_USER_FAILURE';

const PING_REQUEST = 'main/PING_REQUEST';
const PING_SUCCESS = 'main/PING_SUCCESS';
const PING_FAILURE = 'main/PING_FAILURE';

const IO_DISCONNECT = 'main/IO_DISCONNECT';

const changeIndicatorActionTypes = [
  'io/IO_ADD_FIELD',
  'io/IO_UPDATE_FIELD',
  'io/IO_REORDER_FIELDS',
  'io/IO_REMOVE_FIELD',
  'io/IO_UPDATE_FORM',
  'io/IO_UPDATE_THEME',
  'io/IO_UPDATE_FORM_URL_PARAMETER',
  'io/IO_UPDATE_THEME_SETTINGS_REQUEST',
  'io/IO_UPDATE_THEME_COLORS_REQUEST',
  'io/IO_UPDATE_THEME_IMAGES_REQUEST',
  'io/IO_UPDATE_THEME_NAME_REQUEST',
  'io/IO_UPDATE_THEME_COLORS_REQUEST',
  '_forms/UPDATE_WORKSPACE_REQUEST',
  '_forms/UPDATE_FORM_REQUEST',
  '_forms/CREATE_FORM_FROM_TEMPLATE_REQUEST'
];

// Initiual State
const initialState = {
  ready: false,
  locationWithIo: false,
  intercomIsReady: false,
  maintenance: false,
  changeIndicatorStatus: 'saved' // saving, saved, fail
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  if (action.type === APP_IS_READY) {
    state.ready = true;

    return { ...state };
  }

  if (action.type === INTERCOM_IS_READY) {
    state.intercomIsReady = true;

    return { ...state };
  }

  if (action.type === UPDATE_LOCATION_WITH_IO) {
    state.locationWithIo = action.payload.value;

    return { ...state };
  }

  if (action.type === SET_CHANGE_INDICATOR_STATUS) {
    state.changeIndicatorStatus = action.payload.value;

    return { ...state };
  }

  if (changeIndicatorActionTypes.indexOf(action.type) !== -1) {
    state.changeIndicatorStatus = 'saving';

    return { ...state };
  }

  if (action.type === GET_USER_FAILURE) {
    if (action.payload.code === 404) state.maintenance = true;

    return { ...state };
  }

  if (action.type === PING_SUCCESS) {
    state.maintenance = false;

    return { ...state };
  }

  if (action.type === PING_FAILURE) {
    state.maintenance = true;

    return { ...state };
  }

  if (action.type === IO_DISCONNECT) {
    if (action.payload.reason === 'transport close') state.maintenance = true;

    return { ...state };
  }

  return state;
}

// Action Creators
export function appIsReady() {
  return (dispatch) => {
    dispatch({ type: APP_IS_READY });
  };
}

export function setIntercomIsReady() {
  return (dispatch) => {
    dispatch({ type: INTERCOM_IS_READY });
  };
}

export function setChangeIndicatorStatus(value) {
  return (dispatch) => {
    dispatch({ type: SET_CHANGE_INDICATOR_STATUS, payload: { value } });
  };
}

export function updateLocationWithIo(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_LOCATION_WITH_IO, payload: { value } });
  };
}

export function ping() {
  const request = () => { return { type: PING_REQUEST } };
  const success = () => { return { type: PING_SUCCESS } };
  const failure = () => { return { type: PING_FAILURE } };

  return async (dispatch) => {
    dispatch(request());

    const response = await services.ping();

    if (response) {
      dispatch(success());
    } else {
      dispatch(failure());
    }
  };
}

const services = {
  ping: async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/ping`
      });

      return response.data;
    } catch (e) {
      return false;
    }
  }
}
