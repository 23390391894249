export default class Storage {
  constructor(prefix) {
    this.prefix = prefix;
  };

  set(key, value) {
    localStorage.setItem(`${this.prefix}_${key}`, value);
  }

  get(key) {
    return localStorage.getItem(`${this.prefix}_${key}`)
  }

  remove(key) {
    localStorage.removeItem(`${this.prefix}_${key}`);
  }

  clear() {
    localStorage.clear();
  }
}
