export const INITIAL_CHART_PARAMS = Object.freeze({
  // Data required for chart data creation
  groupByPrimary: null,
  groupBySecondary: null,
  accumulator: Object.freeze({
    as: 'count',
    of: null
  }),

  // Data required for display
  displayAs: null,
  horizontalSize: '1/2'
});
