import styles from './SortableTabs.module.css';

import React, { useCallback, useEffect, useRef } from 'react';
// import scrollIntoView from 'scroll-into-view';

import SortableList from '../SortableList/SortableList';
import { Icon } from '../Icon/Icon';
import Color from '../Color/Color';

import { mergeRefs } from 'helpers';

/* -------------------------------------------------------------------------- */
/*                                TAB COMPONENT                               */
/* -------------------------------------------------------------------------- */

const Tab = React.memo(
  React.forwardRef(
    (
      {
        className,
        // Content
        label,
        icon,
        color,
        // Config
        active,
        scrollIntoViewIfActive = false,
        ...rest
      },
      ref
    ) => {
      const tabRef = useRef();

      useEffect(() => {
        // react-sortful doesn't provide api for item wrappers,
        // so this a workaround that allows adding separators that depend on hover/active state
        if (tabRef.current) {
          const parentNode = tabRef.current.parentNode;
          parentNode.classList.add(styles.tabWrapper);

          if (active) {
            parentNode.classList.add(styles.active);
          } else {
            parentNode.classList.remove(styles.active);
          }
        }
      }, [tabRef.current, active]);

      /*
      useEffect(() => {
        if (active && scrollIntoViewIfActive) {
          scrollIntoView(tabRef.current);
        }
      }, [active, scrollIntoViewIfActive]);
      */

      return (
        <button
          title={label}
          className={[className, styles.tab, active ? styles.active : ''].join(' ')}
          {...rest}
          ref={mergeRefs(ref, tabRef)}>
          {icon && <Icon id={icon} />}
          {color && <Color value={color} />}
          <span className={styles.label}>{label}</span>
        </button>
      );
    }
  )
);

/* -------------------------------------------------------------------------- */
/*                              TAB LIST COMPONENT                            */
/* -------------------------------------------------------------------------- */

const classes = {
  list: styles.tabsSortableList
};

const SortableTabs = ({
  className,
  // Tabs
  data,
  value,
  onChange,
  // Coverts rows from data to props passed to Tab component
  mapDataToProps = null,
  // Sorting
  onSort,
  ...rest
}) => {
  const renderItem = useCallback(
    ({ item, itemRole, injectedProps }) => {
      const tabProps = mapDataToProps ? mapDataToProps(item) : item;

      if (process.env.NODE_ENV === 'development') {
        if (!('value' in tabProps && 'label' in tabProps)) {
          throw new Error('DEBUG: tab data missing value or label prop.');
        }
      }

      return (
        <Tab
          {...injectedProps}
          ref={injectedProps.ref}
          // Tab config
          {...tabProps}
          active={tabProps.value === value}
          onClick={() => onChange(tabProps.value)}
          // Prevents scrolling to placeholder/ghost items
          scrollIntoViewIfActive={itemRole === 'item'}
        />
      );
    },
    [value, onChange, mapDataToProps]
  );

  return (
    <SortableList
      // Content
      items={data}
      onSort={onSort}
      // Rendering
      horizontal
      scrollable={false}
      shrinkItems={true}
      itemSpacing={0}
      className={[className, styles.tabs].join(' ')}
      classes={classes}
      renderItem={renderItem}
      {...rest}
    />
  );
};

export default SortableTabs;
