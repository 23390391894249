import { useRef, useEffect } from 'react';

// Returns time in ms of previous render, or null if rendering time wasn't measured yet
const useLastRenderDuration = () => {
  const lastRenderDurationRef = useRef(null);

  const lastRenderStartTime = performance.now();
  useEffect(() => {
    // Called on every render
    lastRenderDurationRef.current = performance.now() - lastRenderStartTime;
  });

  return lastRenderDurationRef.current;
};

export default useLastRenderDuration;
