import _isString from 'lodash/isString';

const FALLBACK_COLOR = 'transparent';

// Returns theme settings with color/image variables ids baked into colors or urls
const getThemeSettings = (theme) => {
  if (!theme) return null;

  const settingsCopy = { ...theme?.settings };
  const colors = theme?.colors || [];
  const images = theme?.images || {};

  // fill colors
  for (const key in settingsCopy) {
    if (!_isString(settingsCopy[key])) continue;

    // Replaces color variables with colors or with fallback color
    const isColorVariable = settingsCopy[key].indexOf('$color:') === 0;

    if (isColorVariable) {
      const colorId = settingsCopy[key].replace('$color:', '');
      const colorObject = colors.find((c) => c._id === colorId);

      if (colorObject) {
        settingsCopy[key] = colorObject.value;
      } else {
        // If color was removed
        settingsCopy[key] = FALLBACK_COLOR;
      }
    }

    // Replaces image variables with images or with null
    const isImageVariable = settingsCopy[key].indexOf('$image:') === 0;

    if (isImageVariable) {
      const imageId = settingsCopy[key].replace('$image:', '');
      const imageObject = images[imageId];

      if (imageObject) {
        settingsCopy[key] = images[imageId].url;
      } else {
        // Image was removed
        settingsCopy[key] = null;
      }
    }
  }

  return settingsCopy;
};

export default getThemeSettings;
