import styles from './TextPreview.module.css';

import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { Icon } from 'ui-components';

import EmptyPreview from './EmptyPreview';

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

// Predictions format: [{lab: 'positive', prob: 0.9}, {lab: 'negative', prob: .9}] sum ~= 1
// Takes predictions and returns label of the best one.
const pickBestPrediction = (predictions) => {
  if (!Array.isArray(predictions)) return null;

  let bestPredictionLabel = null;
  let bestPredictionScore = null;

  for (const prediction of predictions) {
    const label = prediction?.lab;
    const score = prediction?.prob;

    // For equal probability (very low chance)
    // will just pick the first matched one
    if (score > bestPredictionScore) {
      bestPredictionScore = score;
      bestPredictionLabel = label;
    }
  }

  return bestPredictionLabel;
};

/* -------------------------------------------------------------------------- */
/*                          HELPER LAYOUT COMPONENTS                          */
/* -------------------------------------------------------------------------- */

const IntentPrediction = ({ children }) => {
  return <div className={styles.intentPrediction}>{children}</div>;
};

const IntentPredictionGroup = ({ children }) => {
  return <div className={styles.intentPredictionGroup}>{children}</div>;
};

/* -------------------------------------------------------------------------- */
/*                               TEXT SENTIMENT                               */
/* -------------------------------------------------------------------------- */

const sentimentLabel2Emoji = (label) => {
  if (label === 'positive') {
    return <Icon id="thumbs_up" />;
  } else if (label === 'negative') {
    return <Icon id="thumbs_down" />;
  } else if (label === 'neutral') {
    return null;
  } else {
    return null;
  }
};

const Sentiment = ({ sentimentPredictions }) => {
  if (!Array.isArray(sentimentPredictions)) return null;

  const bestLabel = pickBestPrediction(sentimentPredictions);
  const emoji = sentimentLabel2Emoji(bestLabel);

  if (emoji) {
    return (
      <IntentPrediction>
        <div className={styles.sentiment} title={(bestLabel || '').toUpperCase()}>
          {emoji}
        </div>
      </IntentPrediction>
    );
  } else {
    return null;
  }
};

/* -------------------------------------------------------------------------- */
/*                                TEXT EMOTIONS                               */
/* -------------------------------------------------------------------------- */

/* --------------------------- HELPERS AND CONFIG --------------------------- */

const EMOTIONS = {
  // Positive
  joy: { emoji: <Icon id="emotion_joy" />, type: 'positive' },
  love: { emoji: <Icon id="emotion_love" />, type: 'positive' },
  surprise: { emoji: <Icon id="emotion_surprise" />, type: 'positive' },

  // Negative
  anger: { emoji: <Icon id="emotion_anger" />, type: 'negative' },
  fear: { emoji: <Icon id="emotion_fear" />, type: 'negative' },
  sadness: { emoji: <Icon id="emotion_sadness" />, type: 'negative' }
};

const parseEmotionName = (emotion = '') => {
  return (
    EMOTIONS[emotion.toLowerCase()] || {
      emoji: `[Missing config for ${emotion} emotion]`,
      type: 'negative'
    }
  );
};

/* ------------------------------- COMPONENTS ------------------------------- */

const EmotionProgressCircle = ({ className = '', emotion = '', value = 0, onlyEmoji = false }) => {
  const color = '#807E7C';
  const title = `${emotion.toUpperCase()} : ${parseInt(value)}%`;
  const { emoji } = parseEmotionName(emotion);

  return (
    <div className={[className, styles.emotionProgressCircle, onlyEmoji ? styles.onlyEmoji : ''].join(' ')} title={title}>
      {!onlyEmoji && (
        <CircularProgressbar
          value={value}
          strokeWidth={8}
          styles={buildStyles({
            pathColor: color,
            trailColor: '#EAE8E4',
            pathTransitionDuration: 1
          })}
        />
      )}
      <div className={styles.emotionProgressCircleEmoji}>{emoji}</div>
    </div>
  );
};

const Emotions = ({ emotionPredictions }) => {
  if (!Array.isArray(emotionPredictions)) return null;

  // Assuming that total probability is 1,
  // there will always be at least one emotion with probability higher or equal than
  // 1 / number_of_labels. +1 is to compensate for rounding.
  const visibilityThreshold = 1 / (emotionPredictions.length + 1);

  const filteredEmotionPredictions = emotionPredictions.filter((emotion) => emotion?.prob > visibilityThreshold);

  return (
    <IntentPrediction>
      <div className={[styles.emotions, styles[`length-${filteredEmotionPredictions.length}`]].join(' ')}>
        {filteredEmotionPredictions.map((prediction) => {
          const label = prediction?.lab;
          const percentage = (prediction?.prob || 0) * 100;

          return <EmotionProgressCircle key={prediction?._id || label} emotion={label} value={percentage} />;
        })}
      </div>
    </IntentPrediction>
  );
};

/* -------------------------------------------------------------------------- */
/*                                TEXT PREVIEW                                */
/* -------------------------------------------------------------------------- */

/* --------------------------------- CONFIG --------------------------------- */

const MAX_CHARACTERS_PER_LINE = 30;

/* -------------------------------- COMPONENT ------------------------------- */

const TextPreview = ({ theme, value = null, valueMetadata = null, placeholder }) => {
  if (!value) {
    return <EmptyPreview theme={theme} placeholder={placeholder} />;
  } else {
    let width = undefined;
    if (theme === 'for-table') {
      // Width is based on content but it has to be less than MAX_CHARACTERS_PER_LINE (approximately).
      // With this approach if there is not a lot of content (short answers like 'yes')
      // unnecessary space won't be added (assuming other cells or header won't need it).
      width = `${Math.min(value.length, MAX_CHARACTERS_PER_LINE)}ch`;
    }

    // If provided, these are results of machine learning text classification
    // Format: [{lab: 'label', prob: 0.99, _id}, ...] <-- results for all labels with probability (sum ~= 1)
    const sentimentPredictions = valueMetadata?.sentiment || null;
    const emotionPredictions = valueMetadata?.emotion || null;

    return (
      <div className={[styles.textPreview, styles[`theme-${theme}`]].join(' ')}>
        <div style={{ width }} className={styles.content}>
          {JSON.stringify(value)}
        </div>
        {Boolean(sentimentPredictions || emotionPredictions) && (
          <IntentPredictionGroup>
            <Sentiment sentimentPredictions={sentimentPredictions} />
            <Emotions emotionPredictions={emotionPredictions} />
          </IntentPredictionGroup>
        )}
      </div>
    );
  }
};

export default TextPreview;
