import { useCallback, useContext, useRef } from 'react';

import { useUnmount } from 'helpers';
import { ActivityContext } from 'contexts';

// Hook used for registering activity handles clearing props on umount
const useActivitySetter = (key, clearOnUnmount = true) => {
  const { setActivityProperty } = useContext(ActivityContext);

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line
    const keyRef = useRef(key);

    if (key !== keyRef.current) {
      throw new Error('DEBUG: key in component should be constant.');
    }
  }

  useUnmount(() => {
    if (clearOnUnmount) {
      setActivityProperty(key, null);
    }
  });

  return useCallback(
    (value) => {
      setActivityProperty(key, value);
    },
    [key, setActivityProperty]
  );
};

export default useActivitySetter;
