import WebFont from 'webfontloader';

const loadedGoogleFontsSet = new Set();

/*
  Makes sure that every font family is loaded only once.
  Duplicates passed in the same call are filtered too.
  NOTE: Doesn't interpret and combine batched imports like :400,500
  just treats them as unique families.
*/
const loadGoogleFontsIfNotLoaded = (families) => {
  const newFamilies = families.filter((family) => {
    if (loadedGoogleFontsSet.has(family)) {
      return false;
    } else {
      loadedGoogleFontsSet.add(family);
      return true;
    }
  });

  if (newFamilies.length) {
    console.log('Loading fonts...', newFamilies);

    WebFont.load({
      google: {
        families: newFamilies
      },
      classes: false
    });
  }
};

export default loadGoogleFontsIfNotLoaded;
