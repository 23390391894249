import styles from './Table.module.css';
import React from 'react';

// Table component used in messaging page
// Will be replaced with TableV2 once its done

// Flexbox table
export const Table = ({ className = '', ...rest }) => {
  return <div className={[styles.table, className].join(' ')} {...rest} />;
};

// tr in flexbox table
export const TableRow = ({ className = '', header = false, ...rest }) => {
  return <div className={[styles.row, header ? styles.rowHeader : '', className].join(' ')} {...rest} />;
};

// th in flexbox table
export const TableCell = ({ className = '', style = {}, width, ...rest }) => {
  style.width = width;
  return <div className={[styles.cell, className].join(' ')} style={style} {...rest} />;
};
