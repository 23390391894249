import styles from './AddEditDomainPanel.module.css';

import React, { useState, useEffect, useContext, useRef } from 'react';
import io from 'io.js';

import { Icon, Panel, Button, Input, Label, Select, Hr, IconUpgrade, Avatar, Slider, FormRow } from 'ui-components';

const AddEditDomainPanel = ({ show, onClose, domain }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hostname, setHostname] = useState('');

  const isEdit = domain && domain._id;

  const cancel = () => {
    onClose();
    setLoading(false);
    setSuccess(false);
    setErrorMessage('');
  };

  const submit = () => {
    if (loading) return;

    setLoading(true);
    setErrorMessage('');

    const onResponse = (payload) => {
      setLoading(false);

      if (!payload.success) {
        setErrorMessage(payload.message);
        return;
      }

      setSuccess(true);

      setTimeout(() => cancel(), 1000);
    };

    if (!isEdit) io.socket.once('createDomain', onResponse);
    if (isEdit) io.socket.once('updateDomain', onResponse);

    if (!isEdit) io.socket.emit('createDomain', { hostname });
    if (isEdit) io.socket.emit('updateDomain', { id: domain._id, hostname });
  };

  const handleEnterKeyPress = (e) => {
    if (loading || e.key !== 'Enter') return;

    submit();
  };

  useEffect(() => {
    setHostname(domain ? domain.hostname : '');
  }, [domain]);

  return <Panel mainStyle={{
    height: '90vh'
  }} coverStyle={{
    width: '80vw',
    height: '90vh',
    maxWidth: '950px',
    minWidth: '850px', left: '-200px'
  }}className={styles.main} title={isEdit ? 'Edit your custom domain' : 'Add a new custom domain'} show={show} loading={loading} success={success} buttons={[
    <Button theme="white" onClick={() => !loading && cancel()}>Cancel</Button>,
    <Button theme="black" onClick={() => submit()}>{isEdit ? 'Edit domain' : 'Create domain'}</Button>
  ]} onOutsideClick={() => !loading && cancel()}>
    <div className={styles.content}>
      <p>Access your forms and surveys on your own domain. You will need to <a href="https://support.questionscout.com/article/17-custom-domains" target="_blank" rel="noopener noreferrer">update your DNS</a> with the correct CNAME record pointing to the records shown underneath for this to start working.</p>
      <FormRow label="Domain Name" errorMessage={errorMessage}>
        <Input value={hostname} error={errorMessage} width={'100%'} placeholder="form.yourdomain.com" onKeyPress={handleEnterKeyPress} onChange={(value) => setHostname(value)} />
      </FormRow>

      <Hr />

      <label>Have SSL?<span className={styles.badge}>Best Performance</span></label>
      <p>Point the correct CNAME record to <strong>form.questionscout.com</strong></p>

      <label>Don’t have SSL?</label>
      <p>Point the correct CNAME record to <strong>ssl.form.questionscout.com</strong>. On the first request to your form, we will automatically provision an free SSL certificate, handled by Let's Encrypt. Note that the first open could take slightly longer than normal.</p>

      <label>Not sure if you have SSL?</label>
      <p>Please <span className={styles.link} onClick={() => {
        window.Beacon('open');
        window.Beacon('navigate', '/ask/')
      }}>get in touch with us</span> so we can review your domain for you!</p>
    </div>
  </Panel>;
};

export default AddEditDomainPanel;
