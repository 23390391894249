import { useQuery } from 'react-query';
import { useQueryParam } from '../../providers';
import submissionsQueries from './submissionsQueries';

export const useSubmissionsPropsMetadata = () => {
  /* --------------------------------- PARAMS --------------------------------- */

  const formId = useQueryParam('formId', null);

  /* --------------------------------- QUERIES -------------------------------- */

  const { data, error, isLoading } = useQuery({
    queryFn: submissionsQueries.getSubmissionsPropsMetadata.fn,
    queryKey: submissionsQueries.getSubmissionsPropsMetadata.keyFn({ formId }),
    structuralSharing: false,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    suspense: true
  });

  return {
    error,
    isLoading,
    submissionsPropsMetadata: data?.submissionsPropsMetadata || null,
    submissionsInterpretations: data?.submissionsInterpretations || null,
    submissionsInterpretationsByPath: data?.submissionsInterpretationsByPath || null,
    submissionsInterpretationsOptions: data?.submissionsInterpretationsOptions || null,
    submissionsNumericInterpretationsOptions: data?.submissionsNumericInterpretationsOptions || null
  };
};
