import { useEffect } from 'react';
import { useQuery } from 'react-query';

import usersQueries from './usersQueries';

import io from 'io.js';

import { queryClient } from '../../providers';

export const useUserLatestSubscriptionInvoice = () => {
  const { data, error, isLoading } = useQuery({
    queryFn: usersQueries.getUserLatestSubscriptionInvoice.fn,
    queryKey: usersQueries.getUserLatestSubscriptionInvoice.keyFn(),
    structuralSharing: false,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    suspense: true
  });

  // Requesting refresh when there is possibility that something changed
  useEffect(() => {
    const invalidateQuery = () => {
      queryClient.invalidateQueries(usersQueries.getUserLatestSubscriptionInvoice.keyFn());
    };

    io.socket.on('refreshUser', invalidateQuery);
    return () => {
      io.socket.off('refreshUser', invalidateQuery);
    };
  }, []);

  return {
    error,
    isLoading,
    data
  };
};
