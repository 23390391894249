import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { useQueryParam } from '../../providers';

import chartsQueries from './chartsQueries';
import chartsMutations from './chartsMutations';

import { memoizeOne, objectBy } from 'helpers';

const objectChartsById = memoizeOne((charts) => objectBy(charts, '_id'));

export const useSubmissionsWorkspaceCharts = () => {
  /* --------------------------------- PARAMS --------------------------------- */

  const formId = useQueryParam('formId', null);
  const submissionsWorkspaceId = useQueryParam('submissionsWorkspaceId', null);

  /* --------------------------------- QUERIES -------------------------------- */

  const {
    data: charts,
    error,
    isLoading
  } = useQuery({
    queryFn: chartsQueries.getSubmissionsWorkspaceCharts.fn,
    queryKey: chartsQueries.getSubmissionsWorkspaceCharts.keyFn({ formId, submissionsWorkspaceId }),
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    structuralSharing: false,
    suspense: true
  });

  const chartsById = objectChartsById(charts);

  /* -------------------------------- MUTATIONS ------------------------------- */

  const mutations = useMemo(() => {
    return {
      createSubmissionsWorkspaceChart: async ({ params }) => {
        return await chartsMutations.createSubmissionsWorkspaceChart({
          formId,
          submissionsWorkspaceId,
          params
        });
      },
      orderSubmissionsWorkspaceChart: async ({ chartId, nextIndex }) => {
        return await chartsMutations.orderSubmissionsWorkspaceChart({
          formId,
          submissionsWorkspaceId,
          chartId,
          nextIndex
        });
      },
      forceSubmissionsWorkspaceChartsParams: async ({ expectedNumberOfCharts, onGetRequiredChanges }) => {
        return await chartsMutations.forceSubmissionsWorkspaceChartsParams({
          formId,
          submissionsWorkspaceId,
          expectedNumberOfCharts,
          onGetRequiredChanges
        });
      }
    };
  }, [formId, submissionsWorkspaceId]);

  return { isLoading, error, charts, chartsById, ...mutations };
};
