import _orderBy from 'lodash/orderBy';

import { QueryError, ioEmitValidatedRequest } from '../../helpers';

/* ----------------------------- GETTING CHARTS ----------------------------- */

const getSubmissionsWorkspaceCharts = {
  keyFn: ({ formId, submissionsWorkspaceId }) => {
    return ['getSubmissionsWorkspaceCharts', formId, submissionsWorkspaceId];
  },

  fn: async ({ queryKey }) => {
    const [, formId, submissionsWorkspaceId] = queryKey;

    if (!formId) {
      throw new QueryError('Missing form prop.');
    } else if (!submissionsWorkspaceId) {
      throw new QueryError('Missing submissionsWorkspace prop.');
    }

    const data = await ioEmitValidatedRequest('getSubmissionsWorkspaceCharts', {
      form: formId,
      submissionsWorkspace: submissionsWorkspaceId
    });

    // Returns ordered array
    return _orderBy(data, 'position');
  }
};

/* --------------------------------- EXPORTS -------------------------------- */

export default {
  getSubmissionsWorkspaceCharts
};
