import styles from './InlineHint.module.css';

import React from 'react';
import { Icon } from 'ui-components';

const InlineHint = React.forwardRef(({ style, className, hint, error }, ref) => {
  let classList = [styles.inlineHint, className];

  return (
    <div ref={ref} style={style} className={classList.join(' ')}>
      {error && (
        <div className={styles.error}>
          <Icon id="warning" animation="headShake" />
          {error}
        </div>
      )}

      {hint && (
        <div className={styles.hint}>
          <Icon id="warning" />
          {hint}
        </div>
      )}
    </div>
  );
});

export default InlineHint;
