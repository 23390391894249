import styles from './InlineBadge.module.css';

import React from 'react';

import Tooltip from '../Tooltip/Tooltip';

const InlineBadge = ({ className = '', outlined, theme = 'default', style, tooltip, children = 'Placeholder text' }) => {
  const contentJsx = (
    <div
      className={[
        className,
        styles.inlineBadge,
        styles[`theme-${theme}`],
        tooltip ? styles.hasTooltip : '',
        outlined ? styles.outlined : ''
      ].join(' ')}
      style={style}>
      {children}
    </div>
  );

  if (tooltip) {
    return (
      <Tooltip content={<div className={styles.tooltipContent}>{tooltip}</div>} placement="bottom" delay={0}>
        {contentJsx}
      </Tooltip>
    );
  } else {
    return contentJsx;
  }
};

export default InlineBadge;
