import styles from './IntroTour.module.css';

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { ErrorBoundary } from 'react-error-boundary';
import scrollIntroView from 'scroll-into-view';
import _debounce from 'lodash.debounce';

import { Button } from '../Button/Button';
import Flex from '../Flex/Flex';
import { Spinner } from '../Spinner/Spinner';

import { usePrevious } from 'helpers';

const IntroTourContext = React.createContext(null);

/* -------------------------------------------------------------------------- */
/*                                   CONFIG                                   */
/* -------------------------------------------------------------------------- */

const defaultJoyRideProps = {
  // No padding on highlighted elements
  spotlightPadding: 0,
  disableScrollParentFix: true,
  disableScrolling: true,

  // By default multi step mode
  showProgress: true,
  showSkipButton: true,
  scrollToFirstStep: true,

  // Disable clicks
  disableOverlayClose: true,
  spotlightClicks: false,

  styles: {
    options: {
      spotlightShadow: '0 4px 4px 0 rgba(48, 47, 46, 0.07), 0 8px 8px 0 rgba(48, 47, 46, 0.07), 0 16px 16px 0 rgba(48, 47, 46, 0.07)',
      zIndex: 2000
    }
  },

  floaterProps: {
    disableFlip: true,
    disableAnimation: true
  },

  // Hide red thingy
  beaconComponent: React.forwardRef(() => null)
};

const defaultJoyRideStepProps = {
  target: 'body',
  disableBeacon: true,
  placement: 'center'
};

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

// Checks if element exists and if it is displayed in DOM atm
const isElementVisible = (element) => {
  if (typeof element === 'string') {
    element = document.querySelector(element);
  }

  if (element === document.body) {
    return true;
  } else if (element && document.body.contains(element) && element.offsetParent !== null) {
    return true;
  } else {
    return false;
  }
};

const calculateStepWithQueriedTarget = (step) => {
  const matchedTarget = document.querySelector(step.desiredTarget);

  if (isElementVisible(matchedTarget)) {
    return {
      ...step,
      isDesiredTarget: true,
      target: matchedTarget,
      placement: step.desiredPlacement
    };
  } else {
    return {
      ...step,
      isDesiredTarget: false,
      target: step.fallbackTarget,
      placement: step.fallbackPlacement
    };
  }
};

const useBooleanImpulse = () => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    value && setValue(false);
  }, [value]);

  const requestImpulse = useCallback(() => {
    setValue(true);
  }, []);

  return { value, requestImpulse };
};

/* -------------------------------------------------------------------------- */
/*                                STEP RENDERER                               */
/* -------------------------------------------------------------------------- */

const Tooltip = React.memo(
  ({
    isLoaderMode = false,
    isLastStep,
    index, // Current step index
    size, // Number of steps
    step, // Current step data

    // Elements props
    tooltipProps,
    backProps,
    primaryProps
  }) => {
    const { refreshStepTarget, closeTour, closeTourAsCompeted } = useContext(IntroTourContext);

    /* ---------------------------------- STATE --------------------------------- */

    const {
      // Target
      isDesiredTarget,
      target,
      targetIsOptional,

      // Style
      titleSize = 'default',
      style,

      // Content
      title = null,
      titlePrefix = null,
      content = null,

      // Additional
      showProgress = true
    } = step;

    const progressSuffix = showProgress ? ` (${index + 1}/${size})` : '';

    /* ------------------- MAKING SURE THAT TARGET IS MATCHED ------------------- */

    const waitsForDesiredTarget = !isDesiredTarget || !isElementVisible(target);

    useEffect(() => {
      // Waits for element if it's not present yet, and makes sure it's still there afterwards
      const REFRESH_INTERVAL = waitsForDesiredTarget ? 100 : 2000;
      const targetsRefreshTimeout = setInterval(() => refreshStepTarget(index), REFRESH_INTERVAL);
      return () => {
        clearInterval(targetsRefreshTimeout);
      };
    }, [index, waitsForDesiredTarget, refreshStepTarget]);

    /* ------------------- MAKING SURE THAT TARGET IS IN VIEW ------------------- */

    useEffect(() => {
      if (target && !waitsForDesiredTarget) {
        const scrollTargetIntoView = () => scrollIntroView(target, { time: 0 });
        const scrollTargetIntoViewDebounced = _debounce(scrollTargetIntoView, 200);

        scrollTargetIntoView();

        window.addEventListener('resize', scrollTargetIntoViewDebounced);
        return () => {
          window.removeEventListener('resize', scrollTargetIntoViewDebounced);
        };
      }
    }, [target, waitsForDesiredTarget]);

    /* ----------------------------------- JSX ---------------------------------- */

    return (
      <div className={styles.tooltip} {...tooltipProps} style={style}>
        <div className={styles.tooltipContent}>
          {isLoaderMode || (!targetIsOptional && waitsForDesiredTarget) ? (
            <Spinner size={36} style={{ margin: 'auto' }} />
          ) : (
            <>
              {step.title && (
                <div className={[styles.tooltipContentTitle, styles[`size-${titleSize}`]].join(' ')}>
                  {titlePrefix}
                  <span>{title}</span>
                </div>
              )}
              <div className={styles.tooltipContentInner}>{content}</div>
            </>
          )}
        </div>
        <div className={styles.tooltipButtons}>
          {isLoaderMode ? (
            <>
              <Button theme="transparent" onClick={closeTour}>
                Skip tutorial
              </Button>
            </>
          ) : (
            <>
              {!isLastStep && (
                <Button theme="transparent" onClick={closeTour}>
                  Skip tutorial
                </Button>
              )}

              <Flex.Margin />
              {index > 0 && (
                <Button theme="transparent" onClick={backProps.onClick}>
                  Back
                </Button>
              )}
              <Button autoFocus theme="black" onClick={isLastStep ? closeTourAsCompeted : primaryProps.onClick}>
                <>
                  {isLastStep ? 'Close' : `Next`}
                  {progressSuffix}
                </>
              </Button>
            </>
          )}
        </div>
      </div>
    );
  }
);

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const SKIPPED_STATUS = 'skipped';
const COMPLETED_STATUS = 'completed';

const EMPTY_ON_AFTER_CHANGE = () => alert('Empty onAfterChange');

const EMPTY_STEPS_FALLBACK = [
  {
    content: <p>Empty tutorial. There is nothing to display.</p>,
    onAfterChange: () => {}
  }
];

const LOADING_STEPS_FALLBACK = [
  {
    tooltipComponent: (props) => <Tooltip isLoaderMode {...props} />,
    onAfterChange: () => {}
  }
];

const IntroTour = React.memo(({ isOpen = false, loading = false, steps, onRequestClose, onProgress, ...rest }) => {
  if (loading) {
    steps = LOADING_STEPS_FALLBACK;
    onProgress = null; // Disable progress callback.
  }
  if (!steps || (Array.isArray(steps) && !steps.length)) {
    steps = EMPTY_STEPS_FALLBACK;
    onProgress = null; // Disable progress callback.
  }

  /* ------------------------- STEPS DATA AND HELPERS ------------------------- */

  const mappedSteps = useMemo(() => {
    return steps.map((step) => {
      // Injecting defaults
      step = { ...defaultJoyRideStepProps, ...step };

      // Mapping
      const mappedStep = {
        ...step,

        // Target and placement won't be passed directly to JoyRide.
        // It will be used in the middleware function where it will be queried to actual DOM element.
        // This will allow detection of elements that don't exist yet (it is needed for loader).
        desiredTarget: step.target,
        desiredPlacement: step.placement,

        // In case of no match later on body will be used as target. This way at least
        // step will be possible to display and Joyful will be able to start
        // (it will be waiting if there are some nulls). Showing loader
        // is handled later.
        fallbackTarget: document.body,
        fallbackPlacement: 'center'
      };

      delete mappedStep.target;
      delete mappedStep.placement;

      return mappedStep;
    });
  }, [steps]);

  /* ---------------------------------- STATE --------------------------------- */

  const initialStepsWithQueriedTargets = useMemo(() => mappedSteps.map(calculateStepWithQueriedTarget), [mappedSteps]);

  const [stepIndex, setStepIndex] = useState(0);
  const [stepsWithQueriedTargets, setStepsWithQueriedTargets] = useState(initialStepsWithQueriedTargets);

  useEffect(() => {
    setStepsWithQueriedTargets(initialStepsWithQueriedTargets);
  }, [initialStepsWithQueriedTargets]);

  /* ----------------------- REDUCERS AND EVENT HANDLERS ---------------------- */

  // Scheduling Joyride reloads if target node changed (in some cases tooltip will be positioned wrongly)
  const { value: isTemporaryClosed, requestImpulse: temporaryClose } = useBooleanImpulse();

  // To make sure that always the latest callback is used but callbacks are stable
  const callbacksRefs = useRef(null);
  callbacksRefs.current = {
    onRequestClose,
    onProgress
  };

  const closeTour = useCallback(() => {
    const { onRequestClose } = callbacksRefs.current;
    onRequestClose(SKIPPED_STATUS);
  }, []);

  const closeTourAsCompeted = useCallback(() => {
    const { onRequestClose } = callbacksRefs.current;
    onRequestClose(COMPLETED_STATUS);
  }, []);

  const refreshStepTarget = useCallback((refreshIndex) => {
    setStepsWithQueriedTargets((stepsWithQueriedTargets) => {
      // Component is mounting
      if (!stepsWithQueriedTargets) return stepsWithQueriedTargets;

      const nextStepsWithQueriedTargets = stepsWithQueriedTargets.map((step, index) => {
        if (index === refreshIndex) {
          return calculateStepWithQueriedTarget(step);
        } else {
          return step;
        }
      });

      const preRefreshStep = stepsWithQueriedTargets[refreshIndex];
      const postRefreshStep = nextStepsWithQueriedTargets[refreshIndex];
      if (
        preRefreshStep &&
        postRefreshStep &&
        (preRefreshStep.target !== postRefreshStep.target || preRefreshStep.placement !== postRefreshStep.placement)
      ) {
        return nextStepsWithQueriedTargets;
      } else {
        return stepsWithQueriedTargets;
      }
    });
  }, []);

  const handleJoyrideCallback = useCallback(
    (data) => {
      const { action, index, type, size } = data;

      if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].indexOf(type) !== -1) {
        const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

        if (nextStepIndex >= 0 && nextStepIndex < size) {
          refreshStepTarget(nextStepIndex);
          setStepIndex(nextStepIndex);
        } else {
          // Should never happen
          console.error('IntroTour out of range.');
          closeTour();
        }
      }
    },
    [refreshStepTarget, closeTour]
  );

  // Notifying about progress
  const numberOfSteps = stepsWithQueriedTargets.length;
  useEffect(() => {
    const { onProgress } = callbacksRefs.current;

    if (onProgress) {
      const currentStep = stepIndex + 1;
      const totalSteps = numberOfSteps;
      const completionRatio = currentStep / totalSteps;

      onProgress({ currentStep, totalSteps, completionRatio });
    }
  }, [stepIndex, numberOfSteps]);

  /* --------------------------- ACTIVE STEP - DATA --------------------------- */

  const step = stepsWithQueriedTargets?.[stepIndex];
  const stepTarget = step?.target;
  const stepPlacement = step?.placement;

  const previousStepIndex = usePrevious(stepIndex);
  const previousStepTarget = usePrevious(stepTarget);
  const previousStepPlacement = usePrevious(stepPlacement);

  /* --- ACTIVE STEP - MAKING SURE TOOLTIP IS REFRESHED WHEN TARGET CHANGES --- */

  const stepTooltipNeedsReload = Boolean(
    stepIndex === previousStepIndex &&
      previousStepPlacement &&
      previousStepTarget &&
      (previousStepPlacement !== stepPlacement || previousStepTarget !== stepTarget)
  );

  useEffect(() => {
    if (stepTooltipNeedsReload) {
      temporaryClose();
    }
  }, [stepTooltipNeedsReload, temporaryClose]);

  /* ------------------------- ACTIVE STEP - CALLBACKS ------------------------ */

  const onAfterChange = step ? step.onAfterChange || EMPTY_ON_AFTER_CHANGE : null;

  useEffect(() => {
    onAfterChange && onAfterChange();
  }, [onAfterChange]);

  /* --------------------------------- CONTEXT -------------------------------- */

  const context = useMemo(() => {
    return { refreshStepTarget, closeTour, closeTourAsCompeted };
  }, [refreshStepTarget, closeTour, closeTourAsCompeted]);

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <IntroTourContext.Provider value={context}>
      <Joyride
        {...defaultJoyRideProps}
        {...rest}
        run={isOpen && !isTemporaryClosed}
        callback={handleJoyrideCallback}
        stepIndex={stepIndex}
        steps={stepsWithQueriedTargets}
        tooltipComponent={Tooltip}
        disableCloseOnEsc
        continuous
      />
    </IntroTourContext.Provider>
  );
});

/* -------------------------------------------------------------------------- */
/*                               ERROR BOUNDARY                               */
/* -------------------------------------------------------------------------- */

const EMPTY_ON_REQUEST_CLOSE = () => alert('Empty onRequestClose');

const IntroTourWithBoundary = ({ isOpen = false, onRequestClose = EMPTY_ON_REQUEST_CLOSE, ...rest }) => {
  return (
    <ErrorBoundary
      fallbackRender={() => null}
      onError={() => {
        console.error('Joyride crashed.');

        if (isOpen) {
          onRequestClose(SKIPPED_STATUS);
        }
        if (process.env.NODE_ENV === 'development') {
          alert('Joyride crashed.');
        }
      }}>
      <IntroTour isOpen={isOpen} onRequestClose={onRequestClose} {...rest} />
    </ErrorBoundary>
  );
};

export default IntroTourWithBoundary;
