import styles from './Smtps.module.css';

import React, { useState, useContext } from 'react';
import { Button } from 'ui-components';

import { MainContext } from 'contexts/main.js';

import ContentHeader from '../Header.js';
import AddEditSmtpPanel from './panels/AddEditSmtpPanel.js';
import DeleteSmtpPanel from './panels/DeleteSmtpPanel.js';

import { useHelpScout } from 'integrations';

const Smtps = React.memo(() => {
  const { user, smtps, showYouLackPermissions } = useContext(MainContext);

  const helpScoutAPI = useHelpScout();

  const [selectedSmtp, setSelectedSmtp] = useState(null);
  const [showAddEditSmtpPanel, setShowAddEditSmtpPanel] = useState(false);
  const [showDeleteSmtpPanel, setShowDeleteSmtpPanel] = useState(false);

  const userCanModifySmtps = user.role === 'holder' || user.role === 'admin';

  return (
    <>
      <ContentHeader title="SMTP settings" />

      <div className={styles.content}>
        <div className={styles.info}>
          Using SMTP, all emails sent will be sent from your own email servers. This also removes all QuestionScout related messaging in
          your emails.
        </div>

        <div className={styles.buttons}>
          <Button
            theme="black"
            onClick={
              userCanModifySmtps
                ? () => {
                    setSelectedSmtp(null);
                    setShowAddEditSmtpPanel(true);
                  }
                : showYouLackPermissions
            }>
            Add SMTP
          </Button>
          <Button icon="open-external" theme="black-underline" onClick={() => helpScoutAPI.openArticle('smtp')}>
            Learn more
          </Button>
        </div>

        {smtps && smtps.length > 0 && (
          <ul className={styles.smtps}>
            <li className={styles.smtpsHead}>
              <div style={{ width: '20%' }}>Name</div>
              <div style={{ width: 'calc(45% - 120px)' }}>Hostname</div>
              <div style={{ width: '10%' }}>Port</div>
              <div style={{ width: '25%' }}>Default sender email</div>
              <div style={{ width: '120px' }}>Actions</div>
            </li>

            {smtps.map((smtp) => (
              <li className={styles.smtpsBody} key={smtp._id}>
                <div style={{ width: '20%' }} className={styles.value}>
                  {smtp.name}
                </div>
                <div style={{ width: 'calc(45% - 120px)' }} className={styles.value}>
                  {smtp.hostname}
                </div>
                <div style={{ width: '10%' }} className={styles.value}>
                  {smtp.port}
                </div>
                <div style={{ width: '25%' }} className={styles.value}>
                  {smtp.defaultSenderEmail}
                </div>
                <div style={{ width: '120px', display: 'flex' }}>
                  <Button
                    theme="black-underline"
                    onClick={
                      userCanModifySmtps
                        ? () => {
                            setSelectedSmtp(smtp);
                            setShowAddEditSmtpPanel(true);
                          }
                        : showYouLackPermissions
                    }
                    style={{ margin: '0 15px 0 0' }}>
                    Edit
                  </Button>
                  <Button
                    theme="black-underline"
                    onClick={
                      userCanModifySmtps
                        ? () => {
                            setSelectedSmtp(smtp);
                            setShowDeleteSmtpPanel(true);
                          }
                        : showYouLackPermissions
                    }>
                    Delete
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      <AddEditSmtpPanel show={showAddEditSmtpPanel} onClose={() => setShowAddEditSmtpPanel(false)} smtp={selectedSmtp} />
      <DeleteSmtpPanel show={showDeleteSmtpPanel} onClose={() => setShowDeleteSmtpPanel(false)} smtp={selectedSmtp} />
    </>
  );
});

export default Smtps;
