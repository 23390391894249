import { useRef, useEffect } from 'react';

// Will use callback only at component unmount with most recent callback version
const useUnmount = (callback) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    return () => {
      if (callbackRef.current) {
        callbackRef.current();
      }
    };
  }, []);
};

export default useUnmount;
