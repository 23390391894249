import React from 'react';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './react-datepicker-custom.css';

const DatePicker = ({ value, onChange, ...rest }) => {
  return <ReactDatePicker selected={value} onChange={onChange} disabledKeyboardNavigation inline {...rest} />;
};

export default DatePicker;
