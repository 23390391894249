import styles from './DurationPreview.module.css';

import React from 'react';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';

import EmptyPreview from './EmptyPreview';

const TimePreview = ({ value = null, placeholder }) => {
  if (!value) {
    return <EmptyPreview placeholder={placeholder} />;
  } else {
    let duration = 'Unknown';
    let durationInSeconds = null;

    try {
      const seconds = parseInt(value);
      duration = formatDuration(intervalToDuration({ start: new Date(0), end: new Date(seconds * 1000) }));
      durationInSeconds = formatDuration({ seconds });
    } catch (e) {
      console.error(e);

      if (process.env.NODE_ENV === 'development') {
        throw e;
      }
    }

    // Shows duration both in seconds and splitted into minutes/hours
    // Seconds are always shown because filtering in results table is done by seconds
    return (
      <div className={styles.timePreview}>
        {durationInSeconds !== duration ? (
          <>
            <span className={styles.duration}>{duration}</span>
            {durationInSeconds && <span className={styles.seconds}>({durationInSeconds})</span>}
          </>
        ) : (
          <>
            <span className={styles.duration}>{durationInSeconds}</span>
          </>
        )}
      </div>
    );
  }
};

export default TimePreview;
