import io from 'io.js';

const orderSubmissionsWorkspace = (formId, setSubmissionsWorkspaces) => (params) => {
  setSubmissionsWorkspaces((submissionsWorkspaces) => {
    io.socket.emit('orderSubmissionsWorkspace', {
      form: formId,
      params: params
    });

    const nextPositionsById = new Map();
    for (const { _id, position } of params) {
      nextPositionsById.set(_id, position);
    }

    const nextSubmissionsWorkspaces = [];
    for (const workspace of submissionsWorkspaces) {
      const nextPosition = nextPositionsById.get(workspace._id) ?? null;
      nextSubmissionsWorkspaces.push(nextPosition !== null ? { ...workspace, position: nextPosition } : workspace);
    }

    return nextSubmissionsWorkspaces;
  });
};

export default orderSubmissionsWorkspace;
