import React, { useState, useContext, useCallback, useEffect } from 'react';

import { OtpInput, Panel, Button, Input, FormRow, usePopups } from 'ui-components';

import { asyncSocketAckEmit } from 'helpers';

import config from 'config.js';
import { MainContext } from 'contexts';

const INITIAL_BACKEND_ERRORS = {};
const INITIAL_VALUES = { otpCode: '', password: '', newPassword1: '', newPassword2: '' };

const PasswordChangePanel = ({ show, onClose }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const popups = usePopups();

  const { user } = useContext(MainContext);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState(INITIAL_VALUES);
  const [backendErrors, setBackendErrors] = useState(INITIAL_BACKEND_ERRORS);
  const [errorsAreVisible, setErrorsAreVisible] = useState(false);

  // Clear state
  useEffect(() => {
    if (!show) {
      setLoading(false);
      setValues(INITIAL_VALUES);
      setBackendErrors(INITIAL_BACKEND_ERRORS);
      setErrorsAreVisible(false);
      setSuccess(false);
    }
  }, [show]);

  /* --------------------------------- HELPERS -------------------------------- */

  const updateValue = useCallback((key, nextValue) => {
    setValues((values) => ({ ...values, [key]: nextValue }));
    setBackendErrors((errors) => ({ ...errors, [key]: false }));
  }, []);

  const computeLocalErrors = () => {
    const localErrors = {};

    // otpCode
    if (user.otp.enabled && values.otpCode.length !== 6) {
      localErrors.otpCode = 'This field is required';
    }
    // password
    if (!values.password.length) {
      localErrors.password = 'This field is required';
    }
    // newPassword1
    if (!values.newPassword1.length) {
      localErrors.newPassword1 = 'This field is required';
    }
    // newPassword2
    if (values.newPassword2 !== values.newPassword1) {
      localErrors.newPassword1 = "Passwords don't match";
      localErrors.newPassword2 = "Passwords don't match";
    }

    return localErrors;
  };

  /* --------------------------------- ERRORS --------------------------------- */

  const localErrors = computeLocalErrors();

  const errors = {
    otpCode: backendErrors.otpCode || localErrors.otpCode,
    password: backendErrors.password || localErrors.password,
    newPassword1: backendErrors.newPassword1 || localErrors.newPassword1,
    newPassword2: backendErrors.newPassword2 || localErrors.newPassword2
  };

  const thereAreSomeErrors = Object.values(errors).filter(Boolean).length > 0;

  /* --------------------------------- REDUCER -------------------------------- */

  const sendUpdateRequest = async () => {
    setErrorsAreVisible(true);

    if (loading || thereAreSomeErrors) {
      return;
    }

    setLoading(true);

    const { success, message, data } = await asyncSocketAckEmit(
      'updateUserPassword',
      { otpCode: values.otpCode, password: values.password, newPassword1: values.newPassword1, newPassword2: values.newPassword2 },
      { showToasts: false }
    );

    setLoading(false);

    if (success) {
      setSuccess(true);
      setTimeout(() => onClose(), 1000);
    } else {
      if (message) {
        popups.showErrorSnackbar(message);
      }

      setBackendErrors(data?.errors || {});
    }
  };

  /* ----------------------- EVENT HANDLERS AND HELPERS ----------------------- */

  const handleClickOutside = () => {
    !loading && onClose();
  };

  const getInputProps = (key) => {
    return {
      disabled: loading,
      value: values[key],
      error: Boolean(errorsAreVisible && errors[key]),
      onChange: (value) => updateValue(key, value),
      onKeyPress: (e) => {
        if (e.key === 'Enter') sendUpdateRequest();
      }
    };
  };

  const getRowProps = (key) => {
    return {
      errorMessage: errorsAreVisible && errors[key]
    };
  };

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <Panel
      mainStyle={{ height: '90vh' }}
      coverStyle={{ width: '80vw', height: '90vh', maxWidth: '950px', minWidth: '850px', left: '-200px' }}
      title="Change email address"
      show={show}
      loading={loading}
      success={success}
      buttons={[
        <Button theme="white" onClick={onClose}>
          Cancel
        </Button>,
        <Button theme="black" disabled={loading || (errorsAreVisible && thereAreSomeErrors)} onClick={sendUpdateRequest}>
          Update email address
        </Button>
      ]}
      onOutsideClick={handleClickOutside}>
      <div className={config.fullStoryExcludeClass}>
        <FormRow label="New password" {...getRowProps('newPassword1')}>
          <Input type="password" {...getInputProps('newPassword1')} />
        </FormRow>

        <FormRow label="Confirm new password" {...getRowProps('newPassword2')}>
          <Input type="password" {...getInputProps('newPassword2')} />
        </FormRow>

        <FormRow label="Current password" {...getRowProps('password')}>
          <Input type="password" {...getInputProps('password')} />
        </FormRow>

        {user.otp.enabled && (
          <FormRow label="Two-factor authentication code" {...getRowProps('otpCode')}>
            <OtpInput {...getInputProps('otpCode')} />
          </FormRow>
        )}
      </div>
    </Panel>
  );
};

export default PasswordChangePanel;
