import styles from './AvatarChangePanel.module.css';

import React, { useState, useContext, useRef } from 'react';
import io from 'io.js';

import AvatarEditor from 'react-avatar-editor';
import pica from 'pica';
import { Panel, Button, Slider } from 'ui-components';
import { MainContext } from 'contexts/main.js';

import config from 'config.js';

const SizeImage = ({ className }) => (
  <svg className={className} width="18" height="16">
    <path
      d="M2.732.643c-1.154 0-2.09.904-2.09 2.02v10.102c0 1.116.936 2.02 2.09 2.02h12.536c1.154 0 2.09-.904 2.09-2.02V2.663c0-1.116-.936-2.02-2.09-2.02H2.732zm12.536 12.122H2.732l4.179-8.081 3.134 6.06 2.089-4.04 3.134 6.061z"
      fill="#5D5D5C"
      fillRule="evenodd"
    />
  </svg>
);

const calculateScale = (scale, delta) => {
  const zoomFactor = Math.max(Math.abs(delta) / 1000, 0.1);
  const nextScale = delta > 0 ? scale - zoomFactor : scale + zoomFactor;
  const clamped = Math.min(3, Math.max(1, nextScale));

  return clamped;
};

const AvatarChangePanel = ({ image, setImage }) => {
  const { getAndUpdateUser } = useContext(MainContext);

  const editor = useRef();

  const [scale, setScale] = useState(1);
  const [loading, setLoading] = useState(false);

  const changeScale = (e) => {
    setScale(calculateScale(scale, e.deltaY));
  };

  const save = async () => {
    setLoading(true);

    const canvas = editor.current.getImage();

    const offScreenCanvas = document.createElement('canvas');

    offScreenCanvas.width = 100;
    offScreenCanvas.height = 100;

    const picaCanvas = await pica().resize(canvas, offScreenCanvas, { alpha: true });
    const dataUrl = picaCanvas.toDataURL('image/png', 1);

    io.socket.once('updateUserAvatar', () => {
      getAndUpdateUser();

      setLoading(false);
      setImage(null);
    });

    io.socket.emit('updateUserAvatar', dataUrl);
  };

  return (
    <Panel
      mainStyle={{
        height: '90vh'
      }}
      coverStyle={{
        width: '80vw',
        height: '90vh',
        maxWidth: '950px',
        minWidth: '850px',
        left: '-200px'
      }}
      className={styles.main}
      title="Cropping profile picture"
      show={image}
      loading={loading}
      buttons={[
        <Button theme="white" onClick={() => !loading && setImage(null)}>
          Cancel
        </Button>,
        <Button theme="black" onClick={save}>
          Save avatar
        </Button>
      ]}
      onOutsideClick={() => !loading && setImage(null)}>
      <div className={styles.content}>
        <div className={config.fullStoryHardExcludeClass} onWheel={(e) => changeScale(e)}>
          <AvatarEditor width={150} height={150} borderRadius={8} image={image} scale={scale} ref={editor} />
        </div>

        <div className={styles.scaleSlider}>
          <SizeImage className={[styles.sizeImage, styles.small].join(' ')} />
          <Slider className={styles.slider} step={0.01} min={1} max={3} onChange={(val) => setScale(parseFloat(val))} value={scale} />
          <SizeImage className={[styles.sizeImage, styles.big].join(' ')} />
        </div>
      </div>
    </Panel>
  );
};

export default AvatarChangePanel;
