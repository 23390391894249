import ObjectID from 'bson-objectid';
import arrayMove from 'array-move';
import _orderBy from 'lodash/orderBy';

import { queryClient } from '../../providers';
import { QueryError, QueryCacheModifier, ioEmitValidatedRequest } from '../../helpers';
import { INITIAL_CHART_PARAMS } from '../../constants';

import chartsQueries from './chartsQueries';

import fastDeepEqual from 'fast-deep-equal';

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

// Expects to get position with elements that have at least {_id, position} props.
// Will return ordered array and objet with position changes by element ids.
const handlePositionedDocumentElementReorder = ({ doc, elementId, nextIndex }) => {
  // Clamping index
  nextIndex = Math.min(Math.max(nextIndex, 0), doc.length - 1) || 0;

  // Checking current charts order
  const orderedDoc = _orderBy(doc, 'position');

  const currentIndex = orderedDoc.findIndex((element) => element._id === elementId);
  if (currentIndex === -1) {
    throw new QueryError('Attempted to move not existing element.');
  }

  // Creating new charts order
  const nextOrderedDoc =
    currentIndex === nextIndex
      ? orderedDoc
      : arrayMove(orderedDoc, currentIndex, nextIndex).map((element, i) => {
          return { ...element, position: i + 1 };
        });

  // Getting parameters for backend request
  const previousPositions = {};
  for (const element of orderedDoc) {
    previousPositions[element._id] = element.position;
  }

  const positionChangesById = {};
  for (const element of nextOrderedDoc) {
    if (element.position !== previousPositions[element._id]) {
      positionChangesById[element._id] = element.position;
    }
  }

  return {
    nextOrderedDoc,
    positionChangesById
  };
};

/* -------------------------------------------------------------------------- */
/*                                  REDUCERS                                  */
/* -------------------------------------------------------------------------- */

/* ------------------------------ BATCH UPDATES ----------------------------- */

const assertChartChanges = (changes) => {
  for (const key in changes) {
    if (key === '_id' || key === 'position') {
      throw new Error(`DEBUG: Tried to update '${key}' chart prop that can't be forced this way.`);
    }
    if (!(key in INITIAL_CHART_PARAMS)) {
      throw new Error(`DEBUG: Tried to update '${key}' chart prop that is not in a initial chart config.`);
    }
  }
};

const forceSubmissionsWorkspaceChartsParams = async (props) => {
  const { formId, submissionsWorkspaceId, expectedNumberOfCharts, onGetRequiredChanges } = props;

  if (typeof expectedNumberOfCharts !== 'number' || isNaN(expectedNumberOfCharts)) {
    throw new Error('DEBUG: Invalid expectedNumberOfCharts param.');
  }
  if (typeof onGetRequiredChanges !== 'function') {
    throw new Error('DEBUG: Invalid forcedChartsParams param.');
  }

  const cacheModifier = new QueryCacheModifier({ queryClient });

  const assertChangesAndLeaveOnlyOnesThatChangeSomething = (changes, chart) => {
    if (!changes) return;

    assertChartChanges(changes);

    for (const key in changes) {
      if (fastDeepEqual(changes[key], chart[key])) {
        delete changes[key];
      }
    }
  };

  try {
    const chartsQueryKey = chartsQueries.getSubmissionsWorkspaceCharts.keyFn({ formId, submissionsWorkspaceId });
    const charts = queryClient.getQueryData(chartsQueryKey);
    if (!charts) {
      throw new QueryError('Attempted to force charts before existing ones were fetched.');
    }

    const idsOfChartsToDelete = [];
    const chartsToUpdateById = {};
    const paramsOfChartsToCreate = [];
    const chartsPositionsChangesById = {};

    let index = 0;

    // Detecting required charts update/delete operations
    while (index < charts.length) {
      const chartParams = charts[index];
      const chartId = chartParams._id;

      // For these charts required changes will be detected
      if (index < expectedNumberOfCharts) {
        // Saving expected position
        const expectedPosition = index + 1;
        if (chartParams.position !== expectedPosition) {
          chartsPositionsChangesById[chartId] = expectedPosition;
        }

        // Using callback to get required changes
        const requiredChanges = onGetRequiredChanges(chartParams, index) || {};
        assertChangesAndLeaveOnlyOnesThatChangeSomething(requiredChanges, chartParams);
        if (Object.keys(requiredChanges).length) {
          chartsToUpdateById[chartId] = requiredChanges;
        }
      }
      // These charts exceed forcedCharts array so they will be removed
      else {
        idsOfChartsToDelete.push(chartId);
      }

      index++;
    }

    // Detecting required charts create operations
    while (index < expectedNumberOfCharts) {
      // Preparing default new chart data
      const newChartId = ObjectID().toHexString();
      const newChartParams = {
        ...INITIAL_CHART_PARAMS,
        _id: newChartId,
        // backend always adds new charts at the end
        position: charts.length + 1
      };

      // Saving expected position
      const expectedPosition = index + 1;
      if (newChartParams.position !== expectedPosition) {
        chartsPositionsChangesById[newChartId] = expectedPosition;
      }

      // Using callback to get required changes compared to initial chart config
      // and new chart data with these changes
      const requiredChanges = onGetRequiredChanges(newChartParams, index) || {};
      assertChangesAndLeaveOnlyOnesThatChangeSomething(requiredChanges, newChartParams);
      if (Object.keys(requiredChanges).length) {
        Object.assign(newChartParams, requiredChanges);
      }

      paramsOfChartsToCreate.push(newChartParams);

      index++;
    }

    if (
      !idsOfChartsToDelete.length &&
      !Object.keys(chartsToUpdateById).length &&
      !paramsOfChartsToCreate.length &&
      !Object.keys(chartsPositionsChangesById).length
    ) {
      if (window.QS?.verbose) {
        console.log('👍 FORCING CHARTS SKIPPED 👍');
      }

      // IMPORTANT:
      // No need to do anything since everything already looks as it should look.
      // This will prevent infinite loops when called from within useEffect that depends on charts array.
      return;
    }

    if (window.QS?.verbose) {
      console.log('🔥🔥🔥 FORCING CHARTS 🔥🔥🔥');
    }

    // Calculating new array for optimistic update
    const idsOfChartsToDeleteSet = new Set(idsOfChartsToDelete);

    const nextCharts = [...charts, ...paramsOfChartsToCreate]
      .filter((chart) => !idsOfChartsToDeleteSet.has(chart._id))
      .map((chart) => {
        const chartId = chart._id;

        const changes = {
          ...chartsToUpdateById[chartId]
        };
        if (chartId in chartsPositionsChangesById) {
          changes.position = chartsPositionsChangesById[chartId];
        }

        if (Object.keys(changes).length) {
          return { ...chart, ...changes };
        } else {
          return chart;
        }
      });
    const nextOrderedCharts = _orderBy(nextCharts, 'position');

    // Optimistic update
    await cacheModifier.setQueryDataAsync(chartsQueryKey, nextOrderedCharts);

    // Backend requests
    if (paramsOfChartsToCreate.length) {
      await ioEmitValidatedRequest('createSubmissionsWorkspaceCharts', {
        form: formId,
        submissionsWorkspace: submissionsWorkspaceId,
        arrayOfParams: paramsOfChartsToCreate
      });
    }
    if (idsOfChartsToDelete.length) {
      await ioEmitValidatedRequest('deleteSubmissionsWorkspaceCharts', {
        form: formId,
        submissionsWorkspace: submissionsWorkspaceId,
        charts: idsOfChartsToDelete
      });
    }
    if (Object.keys(chartsToUpdateById).length) {
      await ioEmitValidatedRequest('updateSubmissionsWorkspaceCharts', {
        form: formId,
        submissionsWorkspace: submissionsWorkspaceId,
        changes: chartsToUpdateById
      });
    }
    await ioEmitValidatedRequest('updateSubmissionsWorkspaceChartsPositions', {
      form: formId,
      submissionsWorkspace: submissionsWorkspaceId,
      changes: chartsPositionsChangesById
    });
  } catch (e) {
    console.error(e);
    await cacheModifier.restoreAllModifiedAsync();
  }
};

/* ----------------------------- ORDERING FIELDS ---------------------------- */

const orderSubmissionsWorkspaceChart = async ({ formId, submissionsWorkspaceId, chartId, nextIndex }) => {
  const cacheModifier = new QueryCacheModifier({ queryClient });

  try {
    const chartsQueryKey = chartsQueries.getSubmissionsWorkspaceCharts.keyFn({ formId, submissionsWorkspaceId });
    const charts = queryClient.getQueryData(chartsQueryKey);
    if (!charts) {
      throw new QueryError('Attempted to reorder charts before existing ones were fetched.');
    }

    // Processing data
    const { nextOrderedDoc: nextOrderedCharts, positionChangesById } = handlePositionedDocumentElementReorder({
      doc: charts,
      elementId: chartId,
      nextIndex: nextIndex
    });

    // Optimistic update
    await cacheModifier.setQueryDataAsync(chartsQueryKey, nextOrderedCharts);

    // Backend request
    await ioEmitValidatedRequest('updateSubmissionsWorkspaceChartsPositions', {
      form: formId,
      submissionsWorkspace: submissionsWorkspaceId,
      changes: positionChangesById
    });
  } catch (e) {
    console.error(e);
    await cacheModifier.restoreAllModifiedAsync();
  }
};

/* ----------------------------- CREATING CHARTS ---------------------------- */

const createSubmissionsWorkspaceChart = async ({ formId, submissionsWorkspaceId, params }) => {
  const cacheModifier = new QueryCacheModifier({ queryClient });

  try {
    const chartId = ObjectID().toHexString();
    const requestedPosition = params.position ?? null;

    const chartsQueryKey = chartsQueries.getSubmissionsWorkspaceCharts.keyFn({ formId, submissionsWorkspaceId });
    const currentCharts = queryClient.getQueryData(chartsQueryKey);
    if (!currentCharts) {
      throw new QueryError('Attempted to do add new chart before existing ones were fetched.');
    }

    // Initially adds chart at the end
    const initialPosition = currentCharts.length + 1;

    const newChart = {
      ...INITIAL_CHART_PARAMS,
      ...params,
      _id: chartId,
      position: initialPosition
    };

    // Computing next charts
    let nextCharts = [...currentCharts, newChart];
    let positionChangesById = null;

    // If custom position was requested
    if (requestedPosition > 1) {
      const result = handlePositionedDocumentElementReorder({
        doc: nextCharts,
        elementId: chartId,
        nextIndex: requestedPosition - 1
      });

      nextCharts = result.nextOrderedDoc;
      positionChangesById = result.positionChangesById;
    }

    // Optimistic charts update
    await cacheModifier.setQueryDataAsync(chartsQueryKey, nextCharts);

    // Backend requests
    await ioEmitValidatedRequest('createSubmissionsWorkspaceCharts', {
      form: formId,
      submissionsWorkspace: submissionsWorkspaceId,
      arrayOfParams: [newChart]
    });
    if (positionChangesById) {
      await ioEmitValidatedRequest('updateSubmissionsWorkspaceChartsPositions', {
        form: formId,
        submissionsWorkspace: submissionsWorkspaceId,
        changes: positionChangesById
      });
    }
  } catch (e) {
    console.error(e);
    await cacheModifier.restoreAllModifiedAsync();
  }
};

/* ----------------------------- DELETING CHARTS ---------------------------- */

const deleteSubmissionsWorkspaceChart = async ({ formId, submissionsWorkspaceId, chartId }) => {
  const cacheModifier = new QueryCacheModifier({ queryClient });

  try {
    const chartsQueryKey = chartsQueries.getSubmissionsWorkspaceCharts.keyFn({ formId, submissionsWorkspaceId });
    const charts = queryClient.getQueryData(chartsQueryKey);
    if (!charts) {
      throw new QueryError('Attempted to delete chart before existing ones were fetched.');
    }

    // Optimistic charts update
    await cacheModifier.setQueryDataAsync(
      chartsQueryKey,
      charts.filter((chart) => chart._id !== chartId)
    );

    // Backend request
    await ioEmitValidatedRequest('deleteSubmissionsWorkspaceCharts', {
      form: formId,
      submissionsWorkspace: submissionsWorkspaceId,
      charts: [chartId]
    });
  } catch (e) {
    console.error(e);
    await cacheModifier.restoreAllModifiedAsync();
  }
};

/* ----------------------------- UPDATING CHARTS ---------------------------- */

const updateSubmissionsWorkspaceChart = async ({ formId, submissionsWorkspaceId, chartId, changes }) => {
  const cacheModifier = new QueryCacheModifier({ queryClient });

  try {
    assertChartChanges(changes);

    const chartsQueryKey = chartsQueries.getSubmissionsWorkspaceCharts.keyFn({ formId, submissionsWorkspaceId });
    const charts = queryClient.getQueryData(chartsQueryKey);
    if (!charts) {
      throw new QueryError('Attempted to update chart before existing ones were fetched.');
    }

    // Optimistic new chart data
    await cacheModifier.setQueryDataAsync(
      chartsQueryKey,
      charts.map((chart) => {
        if (chart._id === chartId) {
          return { ...chart, ...changes };
        } else {
          return chart;
        }
      })
    );

    await ioEmitValidatedRequest('updateSubmissionsWorkspaceCharts', {
      form: formId,
      submissionsWorkspace: submissionsWorkspaceId,
      changes: { [chartId]: changes }
    });
  } catch (e) {
    console.error(e);
    await cacheModifier.restoreAllModifiedAsync();
  }
};

/* --------------------------- DUPLICATING CHARTS --------------------------- */

const duplicateSubmissionsWorkspaceChart = async ({ formId, submissionsWorkspaceId, chartId }) => {
  try {
    const chartsQueryKey = chartsQueries.getSubmissionsWorkspaceCharts.keyFn({ formId, submissionsWorkspaceId });
    const charts = queryClient.getQueryData(chartsQueryKey);
    if (!charts) {
      throw new QueryError('Attempted to duplicate chart before existing ones were fetched.');
    }

    const sourceChart = charts.find((chart) => chart._id === chartId);
    if (!sourceChart) {
      throw new QueryError('Attempted to duplicate not existing chart.');
    }

    const newChart = { ...sourceChart };
    delete newChart._id;
    newChart.position = newChart.position + 1;

    await createSubmissionsWorkspaceChart({
      formId,
      submissionsWorkspaceId,
      params: newChart
    });
  } catch (e) {
    console.error(e);
  }
};

/* --------------------------------- EXPORT --------------------------------- */

export default {
  createSubmissionsWorkspaceChart,
  updateSubmissionsWorkspaceChart,
  duplicateSubmissionsWorkspaceChart,
  deleteSubmissionsWorkspaceChart,
  orderSubmissionsWorkspaceChart,
  forceSubmissionsWorkspaceChartsParams
};
