import mapBy from './mapBy';

import { calculateFieldsChildrenMap } from './calculateFieldsUtilityProperties';

// Looks at sections and positions of all fields, and determines what positions and children
// should be updated so that there is no gaps and so that children reflect what position/section pairs indicate.
const calculateFieldsToUpdate = (fields) => {
  const fieldsToUpdate = {
    positions: {},
    childrens: {},
    sections: {}
  };

  const fieldsMap = mapBy(fields, '_id');
  const sectionsChildrenMap = calculateFieldsChildrenMap(fields);

  for (const sectionId of sectionsChildrenMap.keys()) {
    const children = sectionsChildrenMap.get(sectionId);

    // Detecting position and section changes
    let childrenChanged = false;
    for (let position = 1; position <= children.length; position++) {
      const fieldId = children[position - 1];
      const field = fieldsMap.get(fieldId);

      if (field.position !== position) {
        fieldsToUpdate.positions[fieldId] = position;
        childrenChanged = true;
      }
      if (field.section !== sectionId) {
        fieldsToUpdate.sections[fieldId] = sectionId;
        childrenChanged = true;
      }
    }

    // Detecting children changes
    if (sectionId !== 'root' && childrenChanged) {
      fieldsToUpdate.childrens[sectionId] = children;
    }
  }

  return fieldsToUpdate;
};

export default calculateFieldsToUpdate;
