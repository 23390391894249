const permissions = {
  accessBilling: ['holder'],
  accessUsers: ['holder', 'admin'],
  accessDomains: ['holder', 'admin', 'mod'],
  accessConnectedServices: ['holder', 'admin', 'mod', 'user'],
  smtpSettings: ['holder', 'admin'],

  generateApiKey: ['holder'],
  editCompanyName: ['holder'],
  editWorkspace: ['holder', 'admin', 'mod'],
  createWorkspace: ['holder', 'admin', 'mod'],
  manageWorkspaceUsers: ['holder', 'admin', 'mod']
};

export default permissions;
