import styles from './Menu.module.css';

import React, { useContext } from 'react';
import { Avatar, Icon, Spinner } from 'ui-components';

import { MainContext } from 'contexts/main.js';
import { ProfileContext } from 'contexts/profile.js';

import { LogsErrorsIcon } from 'components/shared';

import config from 'config.js';

const Menu = () => {
  const { user } = useContext(MainContext);

  const { page, setPage, balanceLoading, balance } = useContext(ProfileContext);

  const logout = () => {
    const adminToken = window.localStorage.getItem('adminToken');

    if (adminToken) {
      window.localStorage.setItem('token', adminToken);
      window.localStorage.removeItem('adminToken');
    } else {
      window.localStorage.removeItem('token');
    }

    window.location.replace(window.location.origin);
  };

  return (
    <div className={styles.main}>
      <div className={styles.list}>
        <div>
          <div className={styles.head} style={{ border: 'none' }}>
            Your profile
          </div>

          <ul>
            <li onClick={() => setPage('profile')} className={page === 'profile' ? styles.active : ''}>
              <Avatar className={styles.avatar} user={user} size="medium" />
              <div className={[styles.label, config.fullStoryExcludeClass].join(' ')}>{user.email}</div>
            </li>
            {user.billing.plan !== null && (user.role === 'holder' || user.role === 'admin') && (
              <li onClick={() => setPage('affiliate')} className={page === 'affiliate' ? styles.active : ''}>
                <div className={styles.icon}>
                  <Icon id="earn" />
                </div>
                <div className={styles.label}>
                  <div className={styles.balance}>
                    Balance:
                    <div className={styles.amount}>
                      {!balanceLoading ? `$${balance}` : <Spinner size={8} borderSize={0.2} speed="0.8s" className={styles.spinner} />}
                    </div>
                  </div>
                  <span className={styles.badge}>EARN</span>
                </div>
              </li>
            )}
          </ul>

          <div className={styles.head}>Account settings</div>

          <ul>
            {user.role === 'holder' && user.lifeTime && user.lifeTime.enabled && user.lifeTime.paid && (
              <li onClick={() => setPage('ltd')} className={page === 'ltd' ? styles.active : ''}>
                <div className={styles.icon}>
                  <Icon id="billing" />
                </div>
                <div className={styles.label}>LTD Plan</div>
              </li>
            )}
            {user.role === 'holder' && !user.lifeTime && (
              <li onClick={() => setPage('upgrade')} className={page === 'upgrade' ? styles.active : ''}>
                <div className={styles.icon}>
                  <Icon id="upgrade" />
                </div>
                <div className={styles.label}>Upgrade</div>
              </li>
            )}
            {user.role === 'holder' && user.billing.plan !== null && user.billing.plan !== 'trial' && !user.lifeTime && (
              <li onClick={() => setPage('billing')} className={page === 'billing' ? styles.active : ''}>
                <div className={styles.icon}>
                  <Icon id="billing" />
                </div>
                <div className={styles.label}>Billing</div>
              </li>
            )}
            {user.billing.plan !== null && (
              <li onClick={() => setPage('usage')} className={page === 'usage' ? styles.active : ''}>
                <div className={styles.icon}>
                  <Icon id="trending-up" />
                </div>
                <div className={styles.label}>Plan usage</div>
              </li>
            )}
            {(user.role === 'holder' || user.role === 'admin') && (
              <li onClick={() => setPage('members')} className={page === 'members' ? styles.active : ''}>
                <div className={styles.icon}>
                  <Icon id="membermanagement" />
                </div>
                <div className={styles.label}>Manage members</div>
              </li>
            )}
            {user.billing.plan !== null && (
              <>
                <li onClick={() => setPage('apps')} className={page === 'apps' ? styles.active : ''}>
                  <div className={styles.icon}>
                    <Icon id="external" />
                  </div>
                  <div className={styles.label}>Connected apps</div>
                </li>
                <li onClick={() => setPage('domains')} className={page === 'domains' ? styles.active : ''}>
                  <div className={styles.icon}>
                    <Icon id="domain" />
                  </div>
                  <div className={styles.label}>Custom domains</div>
                </li>
                <li onClick={() => setPage('smtps')} className={page === 'smtps' ? styles.active : ''}>
                  <div className={styles.icon}>
                    <Icon id="smtp" />
                  </div>
                  <div className={styles.label}>SMTP settings</div>
                </li>
              </>
            )}
            {user.billing.plan !== null && (user.role === 'holder' || user.role === 'admin') && (
              <>
                <li onClick={() => setPage('logs')} className={page === 'logs' ? styles.active : ''}>
                  <div className={styles.icon}>
                    <Icon id="logs" />
                  </div>
                  <div className={styles.label}>Logs</div>
                  <LogsErrorsIcon />
                </li>
                <li onClick={() => setPage('zapier')} className={page === 'zapier' ? styles.active : ''}>
                  <div className={styles.icon}>
                    <Icon id="services" />
                  </div>
                  <div className={styles.label}>Zapier/API Key</div>
                </li>
              </>
            )}
          </ul>
        </div>

        <div style={{ marginBottom: 10 }}>
          <ul>
            <li onClick={logout}>
              <div className={styles.icon}>
                <Icon id="logout" />
              </div>
              <span>Sign out</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Menu;
