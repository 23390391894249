import styles from './NavigatorEntry.module.css';
import { forceCheck } from 'react-lazyload';

import React, { useEffect, useState } from 'react';

import Hint from '../Hint/Hint';
import { Icon } from '../Icon/Icon';
import DropdownIcon from '../DropdownIcon/DropdownIcon';

/* -------------------------------------------------------------------------- */
/*                                   CONFIG                                   */
/* -------------------------------------------------------------------------- */

const INDENT_LEVEL_SIZE_PX = 23;
const ZERO_INDENT_SIZE_PX = 15;

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */

/*
  Only main content
*/
const NavigatorEntryContent = React.forwardRef(
  (
    { className = '', icon = null, index = null, label = null, prefix = null, suffix = null, enableFloatingSuffix = true, ...rest },
    ref
  ) => {
    return (
      <div className={[className, styles.content].join(' ')} {...rest} ref={ref}>
        {prefix}

        {icon && <div className={styles.icon}>{icon}</div>}
        {label && (
          <div className={styles.label}>
            {index && <span className={styles.labelIndex}>{index}</span>}
            {label}
          </div>
        )}

        {/* Woks both as a floating suffix and as a fade out for too long text */}
        {enableFloatingSuffix && <div className={styles.floatingSuffix}>{suffix}</div>}
        {/* Woks only as suffix */}
        {!enableFloatingSuffix && suffix && <div className={styles.inlineSuffix}>{suffix}</div>}
      </div>
    );
  }
);

/*
  Main content + everything around
*/
const NavigatorEntry = React.memo(
  React.forwardRef(
    (
      {
        // Styling
        className,
        style,
        indentLevel = 0,

        // Content
        index = null,
        label = '[NAVIGATOR ENTRY]',
        icon = null,
        suffix,

        // State
        active = false,
        error = false,

        // Events
        onClick,
        onMouseDown,

        children,

        borderTop = false,
        borderBottom = false,
        dimmedBackground = true,
        enableFloatingSuffix = true,

        ...rest
      },
      ref
    ) => {
      /* ---------------------------------- STATE --------------------------------- */

      const [isExpanded, setIsExpanded] = useState(true);

      useEffect(() => {
        // Forces lazyload check when item collapses (more items may be visible now)
        if (!isExpanded) {
          forceCheck();
        }
      }, [isExpanded]);

      /* --------------------------------- SPACING -------------------------------- */

      /*
    Margins are added to children because when used inside react-sortful List, Item component parent left offset 
    relative to List component (probably that's how it works) is detected and dropline is placed accordingly.

    Negative margins are then used on items to locally remove margin (allows full width active state),
    and padding is used to add that margin back visually.
  */

      const childrenMarginLeft = INDENT_LEVEL_SIZE_PX;
      const contentMarginLeft = -indentLevel * INDENT_LEVEL_SIZE_PX;
      let contentPaddingLeft = 0;
      if (indentLevel === 0) {
        contentPaddingLeft = ZERO_INDENT_SIZE_PX;
      } else {
        contentPaddingLeft = indentLevel * INDENT_LEVEL_SIZE_PX + ZERO_INDENT_SIZE_PX;
      }

      /* ----------------------------------- JSX ---------------------------------- */

      let iconJsx = null;
      if (error) {
        iconJsx = error === true ? <Icon id="warning" /> : <Hint icon="warning">{error}</Hint>;
      } else if (icon) {
        iconJsx = icon;
      }

      const classString = [
        className,
        styles.navigatorEntry,
        borderTop ? styles.borderTop : '',
        borderBottom ? styles.borderBottom : '',
        dimmedBackground ? styles.dimmedBackground : ''
      ].join(' ');

      return (
        <div className={classString} style={style} ref={ref} {...rest}>
          <NavigatorEntryContent
            className={[styles.reactiveContent, active ? styles.active : '', error ? styles.invalid : ''].join(' ')}
            style={{
              marginLeft: contentMarginLeft,
              paddingLeft: contentPaddingLeft,
              paddingRight: 10
            }}
            // Config
            enableFloatingSuffix={enableFloatingSuffix}
            // Events
            onClick={onClick}
            onMouseDown={onMouseDown}
            // Content
            prefix={children && <DropdownIcon open={isExpanded} onChange={setIsExpanded} />}
            icon={iconJsx}
            index={index}
            label={label}
            suffix={suffix}
          />

          {isExpanded && children && (
            <div className={styles.children} style={{ marginLeft: childrenMarginLeft }}>
              {children}
            </div>
          )}
        </div>
      );
    }
  )
);

NavigatorEntry.Content = NavigatorEntryContent;

export default NavigatorEntry;
