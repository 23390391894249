function UserModel({ _id, firstName, lastName, email, owner, avatar, role, createdAt, verified, active }) {
  this._id = _id;
  this.email = email;
  this.role = role;
  this.firstName = firstName;
  this.lastName = lastName;
  this.owner = owner;
  this.avatar = avatar;
  this.createdAt = createdAt;
  this.verified = verified;
  this.active = active;
}

UserModel.prototype.getName = function () {
  return (this.firstName || this.lastName) ? `${this.firstName} ${this.lastName}` : '';
};

UserModel.prototype.getNameOrEmail = function () {
  return (this.firstName || this.lastName) ? `${this.firstName} ${this.lastName}` : this.email;
};

export default UserModel;
