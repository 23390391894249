import ioClient from 'socket.io-client';
import config from 'config.js';

import Emitter from 'component-emitter';

const logEvent = (name, ...args) => {
  if (window.QS?.verbose) {
    console.log(`%cio.socket : ${name}`, 'color: #555', ...args);
  }
};

const DEFAULT_STATE = {
  // Count of all attempts (good and failed) to establish connection since last manual connect
  totalAuthenticationAttempts: 0,

  // Connect/reconnect requests are currently being send
  authenticating: false,

  // Count of connection errors in a row
  subsequentConnectionErrors: 0,

  // Server responded to connect/reconnect request and token was vailid
  authenticated: false,

  // Counts how many times 'connect' event was authenticated
  authenticatedCount: 0
};

class SocketIo {
  /* -------------------------------------------------------------------------- */
  /*                               PRIVATE FIELDS                               */
  /* -------------------------------------------------------------------------- */

  // io socket
  #SOCKET = null;

  // Connection state
  #STATE = { ...DEFAULT_STATE };

  #EMITTER = new Emitter();

  /* -------------------------------------------------------------------------- */
  /*                               PRIVATE METHODS                              */
  /* -------------------------------------------------------------------------- */

  #EMIT_STATE_UPDATE = () => {
    this.#EMITTER.emit('state_change', this.#STATE);
  };

  #BIND_EVENTS_AND_CONNECT = (socket) => {
    /* -------------------------- CUSTOM BACKEND EVENTS ------------------------- */

    // Connection/reconnection succeeded and user session is ready in backend
    socket.on('connected', () => {
      this.#STATE.totalAuthenticationAttempts++;

      this.#STATE.authenticating = false;
      this.#STATE.subsequentConnectionErrors = 0;

      this.#STATE.authenticatedCount++;
      this.#STATE.authenticated = true;

      logEvent('connected (custom backend event)');

      this.#EMIT_STATE_UPDATE();
    });

    /* ------------------------------ SOCKET EVENTS ----------------------------- */

    // https://socket.io/docs/v3/client-api/index.html#Event-%E2%80%98connect%E2%80%99

    // Connection/reconnection succeeded
    socket.on('connect', () => {
      // Just for logging (connected is used to add waiting for user session)
      logEvent('connect');
    });

    // Server responded but couldn't made a connection (probably a bad token)
    socket.on('connect_error', (error) => {
      this.#STATE.totalAuthenticationAttempts++;

      this.#STATE.authenticating = false;
      this.#STATE.subsequentConnectionErrors = 0;

      this.#STATE.authenticated = false;

      logEvent('connect_error', error.message);

      this.#EMIT_STATE_UPDATE();
    });

    // Connection was lost
    socket.on('disconnect', (reason) => {
      this.#STATE.authenticated = false;

      if (reason === 'io server disconnect' || reason === 'io client disconnect') {
        // In these two cases automatic reconnect won't be triggered
        // https://socket.io/docs/v3/client-api/index.html#Event-%E2%80%98disconnect%E2%80%99
        this.#STATE.authenticating = false;
      } else {
        this.#STATE.authenticating = true;
      }

      logEvent('disconnect', reason);

      this.#EMIT_STATE_UPDATE();
    });

    /* -------------------------- SOCKET MANAGER EVENTS ------------------------- */

    // https://socket.io/docs/v3/client-api/index.html#Event-%E2%80%98error%E2%80%99

    // Emitted after connect/reconnect error (for example when server is down)
    socket.io.on('error', (error) => {
      this.#STATE.totalAuthenticationAttempts++;
      this.#STATE.subsequentConnectionErrors++;

      logEvent('error', error.message);

      this.#EMIT_STATE_UPDATE();
    });

    socket.io.on('reconnect_attempt', (attempt) => {
      this.#STATE.authenticating = true;

      logEvent('reconnect_attempt', attempt);

      this.#EMIT_STATE_UPDATE();
    });

    socket.io.on('reconnect_failed', () => {
      this.#STATE.authenticating = false;

      logEvent('reconnect_failed');

      this.#EMIT_STATE_UPDATE();
    });

    /* --------------------------- REQUEST CONNECTION --------------------------- */

    this.#STATE = {
      ...DEFAULT_STATE,
      authenticating: true
    };
    this.#EMIT_STATE_UPDATE();

    socket.connect();
  };

  /* -------------------------------------------------------------------------- */
  /*                                     API                                    */
  /* -------------------------------------------------------------------------- */

  get socket() {
    return this.#SOCKET;
  }

  get state() {
    return this.#STATE;
  }

  get connected() {
    if (this.#SOCKET) {
      return this.#SOCKET.connected;
    } else {
      return false;
    }
  }

  disconnect() {
    if (this.#SOCKET) {
      this.#SOCKET.disconnect();
      this.#SOCKET = null;
    }
  }

  connect() {
    this.disconnect();

    const socket = ioClient(config.socketUrl, {
      autoConnect: false,
      query: {
        token: window.localStorage.getItem('token')
      }
    });

    this.#BIND_EVENTS_AND_CONNECT(socket);
    this.#SOCKET = socket;
  }

  on(...args) {
    this.#EMITTER.on(...args);
  }

  off(...args) {
    this.#EMITTER.off(...args);
  }
}

export default new SocketIo();
