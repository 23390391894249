import styles from './OtpRemovePanel.module.css';

import React, { useState, useEffect, useContext } from 'react';
import io from 'io.js';

import { OtpInput, Panel, Button } from 'ui-components';

import { MainContext } from 'contexts/main.js';

import config from 'config.js';

const OtpRemovePanel = ({ show, onClose }) => {
  const { getAndUpdateUser } = useContext(MainContext);

  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const cancel = () => {
    onClose();
    setError(null);
    setLoading(false);
    setSuccess(false);
  };

  const save = async (value) => {
    setError(null);
    setLoading(true);

    io.socket.once('disableUserOtp', (payload) => {
      getAndUpdateUser();
      setLoading(false);

      if (!payload.success) {
        setError(payload.message);
        return;
      }

      setSuccess(true);

      setTimeout(() => cancel(), 1000);
    });

    io.socket.emit('disableUserOtp', {
      code: value
    });
  };

  return (
    <Panel
      mainStyle={{
        height: '90vh'
      }}
      coverStyle={{
        width: '80vw',
        height: '90vh',
        maxWidth: '950px',
        minWidth: '850px',
        left: '-200px'
      }}
      className={styles.main}
      title="Disabling two-factor authentication"
      show={show}
      loading={loading}
      success={success}
      buttons={[
        <Button size="small" theme="white" onClick={cancel}>
          Cancel
        </Button>,
        <Button size="small" theme="black" onClick={() => save(code)} disabled={loading || code.length !== 6}>
          Disable
        </Button>
      ]}
      onOutsideClick={() => !loading && cancel()}>
      <div className={[styles.content, config.fullStoryExcludeClass].join(' ')}>
        <div className={styles.digitsTitle}>Enter your authentication code to confirm</div>
        <OtpInput style={{ margin: '10px auto 10px auto' }} onChange={(value) => setCode(value)} />
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </Panel>
  );
};

export default OtpRemovePanel;
