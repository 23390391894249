export class BackendError extends Error {
  constructor(message) {
    if (Array.isArray(message)) message = message.join('\n');

    super(message);
    this.name = 'BackendError';
  }
}

export class QueryError extends Error {
  constructor(message) {
    if (Array.isArray(message)) message = message.join('\n');

    super(message);
    this.name = 'QueryError';
  }
}
