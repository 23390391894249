import React, { useContext, useMemo, useState, useEffect, useCallback } from 'react';
import _debounce from 'lodash.debounce';

import { asyncSocketAckEmit, useSearchParams } from 'helpers';

import { MainContext } from 'contexts';

import TutorialsContext from './tutorialsContext';
import { Modal } from 'ui-components';

import HelpCenter from './components/HelpCenter';
import TutorialSkipper from './components/TutorialSkipper';

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */

const TutorialsContextProvider = React.memo(({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams({
    modal: null
  });

  /* ---------------------------- DATA FROM BACKEND --------------------------- */

  const { user, showHello, updateUser } = useContext(MainContext);

  const userAllowsAutoPlay = user?.allowAutoPlayTutorials ?? false;

  /*
    Played tutorials will be taken from backend only on first load, and then will be managed locally
    (updates are always send to backend but they are not refreshed). This is to make sure
    that older data won't overwrite newer data which could for example cause that user would see the same
    tutorial after skipping (very bad first impression). Things like this can happen because user updates are not
    implemented on callbacks and are scatter all over the app so there is a lot of time hazards atm.
  */
  const [playedTutorials, setPlayedTutorials] = useState(user?.playedTutorials || []);

  /* ------------------------------ MODALS STATE ------------------------------ */

  // Additional flag that makes sure that a message after tutorial skipping is showed
  // only once per session (or until user refreshes a page). This will make skipping
  // few tutorials in a row less annoying.
  const [tutorialSkipperWasShowed, setTutorialSkipperWasShowed] = useState(false);

  const WALKTHROUGHS_MODAL_NAME = 'walkthroughs';
  const isHelpCenterOpen = searchParams.modal === WALKTHROUGHS_MODAL_NAME;

  const TUTORIAL_FINISHER_MODAL_NAME = 'walkthrough_done';
  const isTutorialFinisherOpen = searchParams.modal === TUTORIAL_FINISHER_MODAL_NAME;

  const TUTORIAL_SKIPPER_MODAL_NAME = 'walkthrough_skip';
  const isTutorialSkipper = searchParams.modal === TUTORIAL_SKIPPER_MODAL_NAME;

  const showHelpCenter = useCallback(() => {
    setSearchParams({ modal: WALKTHROUGHS_MODAL_NAME });
  }, [setSearchParams]);

  const showTutorialFinisher = useCallback(() => {
    setSearchParams({ modal: TUTORIAL_FINISHER_MODAL_NAME });
  }, [setSearchParams]);

  const showTutorialSkipper = useCallback(() => {
    if (tutorialSkipperWasShowed) return;

    setTutorialSkipperWasShowed(true);
    setSearchParams({ modal: TUTORIAL_SKIPPER_MODAL_NAME });
  }, [tutorialSkipperWasShowed, setSearchParams]);

  const hideModal = useCallback(() => {
    setSearchParams({ modal: null });
  }, [setSearchParams]);

  /* ------------------------- DETECTING SPECIAL CASES ------------------------ */

  // Flag that will temporary prevent tutorials from being triggered or showed
  const preventTutorials = Boolean(
    // Don't interrupt onboarding or a welcome message
    user?.onboarding ||
      showHello ||
      // Currently user is in help center so tutorial shouldn't cover it
      isHelpCenterOpen ||
      // User plan expired, he should see an upgrade modal
      !user?.billing?.plan
  );

  /* -------------------------- REQUESTING TUTORIALS -------------------------- */

  // Manually marks tutorials by key as 'should be played'
  const [requestedTutorial, setRequestedTutorial] = useState(null);

  useEffect(() => {
    // In case something will go wrong during redirecting flag will be cleared
    // after a while automatically so user won't get an unexpected tutorial later
    const timeout = setTimeout(() => setRequestedTutorial(null), 30 * 1000);
    return () => clearTimeout(timeout);
  }, [requestedTutorial]);

  /* -------------------------------- REDUCERS -------------------------------- */

  // Development mode helper, won't modify backend
  const clearPlayedTutorials = useCallback(() => {
    setPlayedTutorials([]);
  }, []);

  const updateAllowAutoPlayTutorials = useCallback(
    (nextValue) => {
      updateUser({
        allowAutoPlayTutorials: Boolean(nextValue)
      });
    },
    [updateUser]
  );

  const updateTutorialStatus = useMemo(() => {
    let updateTutorialStatus_previousTutorialKey = null;

    const updateTutorialStatus_debouncedIoEmit = _debounce((params) => {
      asyncSocketAckEmit('updateTutorialStatus', params);
    }, 1000);

    return (params) => {
      const { tutorialKey, changes = {} } = params;

      // Local state update
      setPlayedTutorials((playedTutorials) => {
        const nextPlayedTutorials = [...playedTutorials];
        const tutorialIndex = nextPlayedTutorials.findIndex((t) => t.key === tutorialKey);

        // Update
        if (tutorialIndex !== -1) {
          nextPlayedTutorials[tutorialIndex] = {
            ...nextPlayedTutorials[tutorialIndex],
            ...changes
          };
        }
        // First visit
        else {
          nextPlayedTutorials.push({
            key: tutorialKey,
            progress: 0,
            ...changes
          });
        }

        return nextPlayedTutorials;
      });

      // Backend update
      if (updateTutorialStatus_previousTutorialKey !== tutorialKey) {
        updateTutorialStatus_debouncedIoEmit.flush();
      }

      updateTutorialStatus_debouncedIoEmit(params);

      updateTutorialStatus_previousTutorialKey = tutorialKey;
    };
  }, []);

  /* --------------------------------- CONTEXT -------------------------------- */

  const context = useMemo(() => {
    return {
      preventTutorials,
      userAllowsAutoPlay,
      requestedTutorial,
      playedTutorials,
      clearPlayedTutorials,
      updateAllowAutoPlayTutorials,
      showTutorialSkipper,
      showTutorialFinisher,
      showHelpCenter,
      setRequestedTutorial,
      updateTutorialStatus
    };
  }, [
    preventTutorials,
    userAllowsAutoPlay,
    requestedTutorial,
    playedTutorials,
    clearPlayedTutorials,
    updateAllowAutoPlayTutorials,
    showTutorialSkipper,
    showTutorialFinisher,
    showHelpCenter,
    setRequestedTutorial,
    updateTutorialStatus
  ]);

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <>
      <TutorialsContext.Provider value={context}>
        <Modal isOpen={isHelpCenterOpen || isTutorialFinisherOpen} onRequestClose={hideModal} theme="tight">
          <HelpCenter postTutorialMode={isTutorialFinisherOpen} onRequestClose={hideModal} />
        </Modal>
        <Modal isOpen={isTutorialSkipper} onRequestClose={hideModal}>
          <TutorialSkipper onRequestClose={hideModal} />
        </Modal>
        {children}
      </TutorialsContext.Provider>
    </>
  );
});

export default TutorialsContextProvider;
