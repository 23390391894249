import styles from './NavMenu.module.css';

import React, { Component } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Button } from '../Button/Button';

const NavMenu = ({ className, ...rest }) => {
  return <div className={[styles.nav, className].join(' ')} {...rest} />;
};

const NavMenuButton = ({ link, routeMatch, validateRouteMatchParams, ...rest }) => {
  const match = useRouteMatch(routeMatch || link);
  const isRouteActive = match ? (validateRouteMatchParams ? validateRouteMatchParams(match.params) : true) : false;

  return <Button active={isRouteActive} link={link} size="default-thin" theme="transparent" noPadding={false} {...rest} />;
};

NavMenu.Button = NavMenuButton;

export default NavMenu;
