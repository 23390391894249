const PRESETS = {
  percentage: {
    type: 'float',
    min: 0,
    max: 100
  },
  int: {
    type: 'int'
  },
  positive: {
    type: 'float',
    min: 0,
    minExclusive: true
  },
  nonnegative: {
    type: 'float',
    min: 0,
    minExclusive: false
  }
};

// If number meets requirements returns the number if not returns null
const _isNumber = (v, settings) => {
  const {
    type = 'float', // float/int
    min = -Infinity,
    minExclusive = false,
    max = Infinity,
    maxExclusive = false,
    step = null
  } = typeof settings === 'string' ? PRESETS[settings] : settings;

  const float = parseFloat(v);

  if (isNaN(float)) return null;
  if (type === 'int' && Math.round(float) !== float) return null;
  if (minExclusive ? float <= min : float < min) return null;
  if (maxExclusive ? float >= max : float > max) return null;
  if (step && float % step) return null;
  return float;
};
const isNumber = (v, settings = 'nonnegative') => {
  if (typeof settings === 'string') {
    for (const preset of settings.split('-')) v = _isNumber(v, preset);
    return v;
  } else {
    return _isNumber(v);
  }
};

export default isNumber;
