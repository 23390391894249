import styles from './SettingsHeader.module.css';
import React, { useState, useEffect } from 'react';

import { Button } from '../Button/Button.js';
import { Icon } from '../Icon/Icon.js';
import Text from '../Text/Text.js';
import Tooltip from '../Tooltip/Tooltip.js';

// Header with description visible above groups of settings
const SettingsHeader = ({ title, afterTitle, description, children, error, style }) => {
  // Used as tooltip trigger
  const [headerRef, setHeaderRef] = useState(null);

  // Title with error functionality
  let tooltipProps = null;
  if (error && error !== true) {
    tooltipProps = {
      content: error,
      trigger: 'mouseenter'
    };
  }
  return (
    <div className={styles.header} style={style}>
      <div className={styles.title}>
        <Text className={styles.description} theme={error ? 'error' : 'header'} size="medium" weight="medium" ref={setHeaderRef}>
          {title || children}
        </Text>
        <Tooltip disabled={!tooltipProps} {...tooltipProps} triggerTarget={headerRef} placement="top">
          <Icon id="warning" style={{ display: error ? 'block' : 'none' }} />
        </Tooltip>
        {afterTitle}
      </div>
      {description && (
        <Text className={styles.description} theme="description" weight="medium">
          {description}
        </Text>
      )}
    </div>
  );
};

export default SettingsHeader;
