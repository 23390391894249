/*
    NOTE:
    Copied directly from backend only for 'auto deletion rules' UI.
    It's a temporary solution. Making it right would require
    some results table related APIs changes.

    In the future submissionWorkspace columns could be handled a
    bit differently to make them reusable in different parts of app.
    Currently results page always fetches form data so there
    is no need to calculate them in the backend. If there will be
    a reason to calculate them in backend they should probably
    be extracted from submissionsWorkspaces into separate API
    since each submissionsWorkspaces uses same columns anyway.
*/

import getFieldLabel from './getFieldLabel';
import orderFields from './orderFields';

const STATIC_FIELDS = [
  {
    _id: 'submitted',
    label: 'Submitted'
  },
  {
    _id: 'active',
    label: 'Active'
  },
  {
    _id: 'referrer',
    label: 'Referrer'
  },
  {
    _id: 'ip',
    label: 'IP'
  },
  {
    _id: 'country',
    label: 'Country'
  },
  {
    _id: 'userAgent',
    label: 'User Agent'
  },
  {
    _id: 'completionTime',
    label: 'Completion Time'
  },
  {
    _id: 'createdAt',
    label: 'Created At'
  },
  {
    _id: 'updatedAt',
    label: 'Updated At'
  },
  {
    _id: 'autoDeletionAt',
    label: 'Auto Deletion At'
  }
];

const IGNORED_FIELDS = ['pageBreak', 'section', 'image', 'divider'];

const getColumns = ({
  fields,
  stickyColumns = [],
  hiddenColumns = [],
  hasUrlParams = false,
  hasCalculationVariables = false,
  hasPayment = false
}) => {
  try {
    let dynamicFields = [];

    if (hasUrlParams) dynamicFields.push({ _id: 'urlParams', label: 'Url Params' });
    if (hasCalculationVariables) dynamicFields.push({ _id: 'calculationVariables', label: 'Calculation Variables' });
    if (hasPayment) dynamicFields.push({ _id: 'payment', label: 'Payment' });

    const columns = [
      {
        _id: 'number',
        label: 'Number',
        position: 0,
        format: null,
        type: 'static',
        sticky: stickyColumns.indexOf('number') !== -1,
        hidden: false
      }
    ];

    fields = orderFields(fields);

    stickyColumns.map((_id) => String(_id));
    hiddenColumns.map((_id) => String(_id));

    for (let field of fields) {
      if (IGNORED_FIELDS.indexOf(field.type) !== -1) continue;

      columns.push({
        _id: field._id,
        label: getFieldLabel(field.label || field.placeholder || field.type),
        position: field.position,
        format: field.format,
        type: field.type,
        sticky: stickyColumns.indexOf(String(field._id)) !== -1,
        hidden: hiddenColumns.indexOf(String(field._id)) !== -1
      });
    }

    for (let staticField of STATIC_FIELDS) {
      const maxPosition = Math.max(...columns.map((o) => o.position), 0);

      columns.push({
        _id: staticField._id,
        label: staticField.label,
        position: maxPosition + 1,
        format: null,
        type: 'static',
        sticky: stickyColumns.indexOf(staticField._id) !== -1,
        hidden: hiddenColumns.indexOf(staticField._id) !== -1
      });
    }

    for (let dynamic of dynamicFields) {
      const maxPosition = Math.max(...columns.map((o) => o.position), 0);

      columns.push({
        _id: dynamic._id,
        label: dynamic.label,
        position: maxPosition + 1,
        format: null,
        type: 'dynamic',
        sticky: stickyColumns.indexOf(dynamic._id) !== -1,
        hidden: hiddenColumns.indexOf(dynamic._id) !== -1
      });
    }

    return columns;
  } catch (e) {
    return [];
  }
};

export default getColumns;
