import React from 'react';

const prepareDomainsOptions = (domains) => {
  return domains.map((domain) => {
    const hostname = domain.hostname || <span style={{ color: '#F05252', fontWeight: '500' }}>[MISSING HOSTNAME]</span>;

    const label = hostname;

    return {
      label: label,
      value: domain._id
    };
  });
};

export default prepareDomainsOptions;
