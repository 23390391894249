import { calculateFieldParentPageNumber } from './calculateFieldsUtilityProperties';

/*
  These validators return false if everything is correct or object with errors (shape similar to field object)

  For future reference:
    conditions - created within logic tab when other field is used as a trigger
    calculationConditions - created within logic tab when a variable is used as a trigger
    calculations - created in calculation tab (field option affects selected variable)
*/

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

// Checks if variable exists
const _validateVariableId = (variableId, calculationVariables) => {
  if (!calculationVariables) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('DEBUG: missing data.');
    }
    return '[Variable validation failed]';
  }

  if (!variableId) {
    return 'Please select a variable.';
  }
  if (calculationVariables.findIndex((v) => v._id === variableId) === -1) {
    return 'Selected variable was removed or never existed.';
  }

  return false;
};

// Validates a number (may be in a string form)
const _validateNumericValue = (value) => {
  if (value !== 0 && !value) {
    return 'Value is required.';
  } else if (isNaN(value) || value === true || value === false) {
    return 'Value has to be a number.';
  }

  return false;
};

// Checks if value can be used as scale field value or is one of field options (dropdown/checkbox/...)
const _validateTriggerFieldValue = (value, triggerField) => {
  if (!triggerField) {
    return 'Value is referencing a field that was removed or that never existed.';
  }

  switch (triggerField.type) {
    case 'radio':
    case 'dropdown':
    case 'checkbox':
    case 'imageChoice': {
      const triggerFieldOptions = triggerField.options || [];

      if (!value) {
        return 'Please select an option.';
      }

      const option =
        triggerFieldOptions.find((o) => o._id === value) || // For new forms
        triggerFieldOptions.find((o) => o.ref === value); // For legacy forms

      if (!option) {
        return 'Selected option was removed or never existed.';
      }

      if (!option?.value) {
        return "Option without a label won't be visible in a form so it shouldn't be used for logic and/or calculations.";
      }

      break;
    }

    case 'scale': {
      const scaleMin = parseInt(triggerField?.scaleRange?.['0']);
      const scaleMax = parseInt(triggerField?.scaleRange?.['1']);
      const parsedValue = parseInt(value);

      if (isNaN(scaleMin) || isNaN(scaleMax)) {
        return 'Unexpected error in scale field range configuration.';
      } else if (isNaN(parsedValue % 1)) {
        return 'Value is required to be an integer.';
      } else if (parsedValue > scaleMax || parsedValue < scaleMin) {
        return `Value ${parsedValue} exceeds given scale range: [${scaleMin}, ${scaleMax}].`;
      }

      break;
    }

    case 'shortText': {
      switch (triggerField.format) {
        case 'number': {
          return _validateNumericValue(value);
        }

        default: {
          if (process.env.NODE_ENV === 'development') {
            throw new Error(`DEBUG: unexpected shortText format ${triggerField.format} - this field should not be used as a trigger.`);
          }
          return '[Value validation failed]';
        }
      }
    }

    default: {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`DEBUG: unexpected field type ${triggerField.type} - this field should not be used as a trigger.`);
      }
      return '[Value validation failed]';
    }
  }

  if (value === null || typeof value === 'undefined') {
    return 'Value is required.';
  }

  return false;
};

/* -------------------------------------------------------------------------- */
/*                       FIELD TO FIELD LOGIC VALIDATION                      */
/* -------------------------------------------------------------------------- */

const validateCondition = ({ condition, fields }) => {
  if (!condition || !fields) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('DEBUG: missing data.');
    }
  }
  /* ---------------------------------- DATA ---------------------------------- */

  const errors = {};

  const value = condition?.value;
  const triggerFieldId = condition?.triggerField;

  /* ------------------------------- VALIDATION ------------------------------- */

  // triggerField
  const triggerField = fields?.find?.(
    (f) =>
      f._id === triggerFieldId || // For new forms
      f.ref === triggerFieldId // For legacy forms
  );
  if (!triggerField) {
    errors.triggerField = 'Selected field was removed or never existed.';
  }

  // value
  const valueError = _validateTriggerFieldValue(value, triggerField);
  if (valueError) {
    errors.value = valueError;
  }

  return Object.keys(errors).length ? errors : false;
};

/* -------------------------------------------------------------------------- */
/*                      VARIABLE TO FIELD LOGIC VALIDATION                    */
/* -------------------------------------------------------------------------- */

const ALLOWED_CALCULATION_CONDITION_TYPES = ['=', '!=', '<', '>', '>=', '<='];
const ALLOWED_CALCULATION_CONDITION_COMPARES = ['number', 'variable'];

const validateCalculationCondition = ({ calculationCondition, calculationVariables }) => {
  if (!calculationCondition || !calculationVariables) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('DEBUG: missing data.');
    }
  }

  /* ---------------------------------- DATA ---------------------------------- */

  const errors = {};

  const type = calculationCondition?.type;
  const compare = calculationCondition?.compare;
  const value = calculationCondition?.value;
  const variableAId = calculationCondition?.variableA;
  const variableBId = calculationCondition?.variableB;

  /* ------------------------------- VALIDATION ------------------------------- */

  // type
  if (ALLOWED_CALCULATION_CONDITION_TYPES.indexOf(type) === -1) {
    errors.type = 'Invalid calculation condition type property.';
  }

  // compare
  if (ALLOWED_CALCULATION_CONDITION_COMPARES.indexOf(compare) === -1) {
    errors.compare = 'Invalid calculation condition compare property.';
  }

  // variable A
  const variableAError = _validateVariableId(variableAId, calculationVariables);
  if (variableAError) {
    errors.variableA = variableAError;
  }

  // variable B
  if (compare === 'variable') {
    const variableBError = _validateVariableId(variableBId, calculationVariables);
    if (variableBError) {
      errors.variableB = variableBError;
    }
    if (variableBId === variableAId) {
      errors.variableB = 'Variable should not be compared with itself.';
    }
  }

  // value
  if (compare === 'number') {
    const valueError = _validateNumericValue(value);
    if (valueError) {
      errors.value = valueError;
    }
  }

  return Object.keys(errors).length ? errors : false;
};

/* -------------------------------------------------------------------------- */
/*                           CALCULATIONS VALIDATION                          */
/* -------------------------------------------------------------------------- */

const ALLOWED_CALCULATION_ACTIONS = ['+', '-', '*', '/'];

const validateCalculation = ({ calculation, field, calculationVariables }) => {
  if (!calculation || !field || !calculationVariables) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('DEBUG: missing data.');
    }
  }

  /* ---------------------------------- DATA ---------------------------------- */

  const errors = {};

  const action = calculation?.action; // How variable will be changed
  const target = calculation?.target; // Variable id
  const option = calculation?.option; // What option causes calculation
  const value = calculation?.value; // How much variable will be changed

  /* ------------------------------- VALIDATION ------------------------------- */

  // action
  if (ALLOWED_CALCULATION_ACTIONS.indexOf(action) === -1) {
    errors.compare = 'Invalid calculation action property.';
  }

  // target
  const targetError = _validateVariableId(target, calculationVariables);
  if (targetError) {
    errors.target = targetError;
  }

  // option
  if (field.type === 'shortText' && field.format === 'number') {
    // Number fields are a special case,
    // it always will trigger calculations so option always should be null.
    if (option) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error('DEBUG: number field should not provide option property inside calculations.');
      }
      errors.option = '[Option validation failed]';
    }
  } else if (field.type === 'scale' && calculation.type === 'use-value') {
    // scale can have option, leftovers from is-in
  } else {
    const optionError = _validateTriggerFieldValue(option, field);

    if (optionError) {
      errors.option = optionError;
    }
  }

  // value
  const valueError = _validateNumericValue(value);
  if (valueError) {
    errors.value = valueError;
  }

  return Object.keys(errors).length ? errors : false;
};

/* -------------------------------------------------------------------------- */
/*                           SINGLE FIELD VALIDATION                          */
/* -------------------------------------------------------------------------- */

const ALLOWED_CONDITION_TYPES = ['any', 'all'];

// Validates calculations, conditions, calculationConditions and conditionsType props of passed element.
const validateFormElementLogic = ({ element, fields, calculationVariables }) => {
  if (!element || !fields || !calculationVariables) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('DEBUG: missing data.');
    }
  }

  /* ----------------------------- PREPARING DATA ----------------------------- */

  const errors = {};

  const elementType = element?.type;

  const conditionsPage = element?.conditionsPage;
  const conditionsType = element?.conditionsType;
  const conditions = element?.conditions;
  const calculationConditions = element?.calculationConditions;
  const calculations = element?.calculations;

  /* ----------------------- TOP LEVEL PROPS VALIDATION ----------------------- */

  if (ALLOWED_CONDITION_TYPES.indexOf(conditionsType) === -1) {
    errors.conditionsType = 'Invalid condition type.';
  }

  if (elementType === 'pageBreak') {
    const elementPage = calculateFieldParentPageNumber({ fieldId: element._id, fields });

    if (conditionsPage === null) {
      // This value was not set yet, and defaults to no skip
    } else if (!(conditionsPage >= elementPage)) {
      errors.conditionsPage = `Page break logic doesn't allow skipping backwards. Current value (${conditionsPage}) has to be number equal or bigger than ${elementType}).`;
    }
  }

  /* ---------------------------- LOGIC VALIDATION ---------------------------- */

  // Field based logic
  if (conditions?.length > 0) {
    const logicErrors = {};

    for (const condition of conditions) {
      const error = validateCondition({ condition, fields });
      if (error) {
        logicErrors[condition._id] = error;
      }
    }

    if (Object.keys(logicErrors).length) {
      errors.conditions = logicErrors;
    }
  }

  // Variable based logic
  if (calculationConditions?.length > 0) {
    const logicErrors = {};

    for (const calculationCondition of calculationConditions) {
      const error = validateCalculationCondition({ calculationCondition, calculationVariables });
      if (error) {
        logicErrors[calculationCondition._id] = error;
      }
    }

    if (Object.keys(logicErrors).length) {
      errors.calculationConditions = logicErrors;
    }
  }

  /* ------------------------- CALCULATIONS VALIDATION ------------------------ */

  if (calculations?.length > 0) {
    const calculationsErrors = {};

    for (const calculation of calculations) {
      const error = validateCalculation({ calculation, field: element, calculationVariables });
      if (error) {
        calculationsErrors[calculation._id] = error;
      }
    }

    if (Object.keys(calculationsErrors).length) {
      errors.calculations = calculationsErrors;
    }
  }

  return Object.keys(errors).length ? errors : false;
};

export default validateFormElementLogic;
