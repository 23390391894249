import axios from 'axios';
import { v4 as uuid } from 'uuid';
import ObjectID from 'bson-objectid';
import _sortBy from 'lodash/sortBy';
import { isJsonString } from 'misc/helpers.js';
import sortFieldsByPosition from 'helpers/sortFieldsByPosition.js';
import ioTasks from 'helpers/ioTasks.js';
import addressSectionFields from 'assets/addressSectionFields.json';

import config from 'config.js';

// Constants
const BUILDER_READY = 'builder/BUILDER_READY';
const BUILDER_RELOADING = 'builder/BUILDER_RELOADING';
const CLEAR = 'builder/CLEAR';
const PING_UNSPLASH_REQUEST = 'builder/PING_UNSPLASH_REQUEST';
const UPDATE_DESIGN_FORM_HEIGHT = 'builder/UPDATE_DESIGN_FORM_HEIGHT';
const TOGGLE_FORM_PREVIEW = 'builder/TOGGLE_FORM_PREVIEW';

const UPDATE_SELECTED_EMPTY_BOX = 'builder/UPDATE_SELECTED_EMPTY_BOX';
const UPDATE_SELECTED_FIELD_OPTION = 'builder/UPDATE_SELECTED_FIELD_OPTION';
const UPDATE_FIRST_HOVER_ON_FIELD_OPTION = 'builder/UPDATE_FIRST_HOVER_ON_FIELD_OPTION';
const UPDATE_ACTIVE_BUTTON_HOVER = 'builder/UPDATE_ACTIVE_BUTTON_HOVER';
const UPDATE_FIELDS = 'builder/UPDATE_FIELDS';
const UPDATE_FIELDS_LIST_POSITION = 'builder/UPDATE_FIELDS_LIST_POSITION';
const UPDATE_EMPTY_BOX_ANIMATION = 'builder/UPDATE_EMPTY_BOX_ANIMATION';
const UPDATE_INPUT_BOX_ANIMATION = 'builder/UPDATE_INPUT_BOX_ANIMATION';
const UPDATE_DRAGGED_FIELD_REF = 'builder/UPDATE_DRAGGED_FIELD_REF';
const UPDATE_EDITED_FIELD_REF = 'builder/UPDATE_EDITED_FIELD_REF';
const UPDATE_EDITED_FIELD_TYPE = 'builder/UPDATE_EDITED_FIELD_TYPE';
const UPDATE_FIELD = 'builder/UPDATE_FIELD';
const UPDATE_FORM = 'builder/UPDATE_FORM';
const UPDATE_FORM_ID = 'builder/UPDATE_FORM_ID';
const UPDATE_THEME = 'builder/UPDATE_THEME';
const UPDATE_IMAGES_PAGE = 'builder/UPDATE_IMAGES_PAGE';
const UPDATE_IMAGES_QUERY = 'builder/UPDATE_IMAGES_QUERY';
const UPDATE_LINK_COPIED = 'builder/UPDATE_LINK_COPIED';
const UPDATE_EMBED_SETTINGS = 'builder/UPDATE_EMBED_SETTINGS';
const COPY_FORM_MESSAGING_REQUEST = 'builder/COPY_FORM_MESSAGING_REQUEST'
const COPY_FORM_MESSAGING_SUCCESS = 'builder/COPY_FORM_MESSAGING_SUCCESS'
const COPY_FORM_MESSAGING_FAILURE = 'builder/COPY_FORM_MESSAGING_FAILURE'

const GET_FORM_REQUEST = 'builder/GET_FORM_REQUEST';
const GET_FORM_SUCCESS = 'builder/GET_FORM_SUCCESS';
const GET_FORM_FAILURE = 'builder/GET_FORM_FAILURE';

const GET_THEME_REQUEST = 'builder/GET_THEME_REQUEST';
const GET_THEME_SUCCESS = 'builder/GET_THEME_SUCCESS';
const GET_THEME_FAILURE = 'builder/GET_THEME_FAILURE';

const GET_FORM_FIELDS_REQUEST = 'builder/GET_FORM_FIELDS_REQUEST';
const GET_FORM_FIELDS_SUCCESS = 'builder/GET_FORM_FIELDS_SUCCESS';
const GET_FORM_FIELDS_FAILURE = 'builder/GET_FORM_FIELDS_FAILURE';

const GET_IMAGES_REQUEST = 'builder/GET_IMAGES_REQUEST';
const GET_IMAGES_SUCCESS = 'builder/GET_IMAGES_SUCCESS';
const GET_IMAGES_FAILURE = 'builder/GET_IMAGES_FAILURE';

const IO_ADD_FIELD = 'io/IO_ADD_FIELD';
const IO_ADD_FIELD_SUCCESS = 'io/IO_ADD_FIELD_SUCCESS';
const IO_UPDATE_FIELD = 'io/IO_UPDATE_FIELD';
const IO_UPDATE_FIELD_SUCCESS = 'io/IO_UPDATE_FIELD_SUCCESS';
const IO_REORDER_FIELDS = 'io/IO_REORDER_FIELDS';
const IO_REORDER_FIELDS_SUCCESS = 'io/IO_REORDER_FIELDS_SUCCESS';
const IO_REMOVE_FIELD = 'io/IO_REMOVE_FIELD';
const IO_UPDATE_FORM = 'io/IO_UPDATE_FORM';
const IO_UPDATE_THEME = 'io/IO_UPDATE_THEME';
const IO_UPDATE_FORM_URL_PARAMETER = 'io/IO_UPDATE_FORM_URL_PARAMETER';
const IO_COPY_FIELD_FILE = 'io/IO_COPY_FIELD_FILE';
const IO_COPY_FIELD_FILE_SUCCESS = 'io/IO_COPY_FIELD_FILE_SUCCESS';

const FILES_UPLOAD_REQUEST = 'builder/FILES_UPLOAD_REQUEST';
const FILES_UPLOAD_SUCCESS = 'builder/FILES_UPLOAD_SUCCESS';
const FILES_UPLOAD_PROGRESS = 'builder/FILES_UPLOAD_PROGRESS';
const FILES_UPLOAD_CANCEL = 'builder/FILES_UPLOAD_CANCEL';
const FILES_UPLOAD_FAILURE = 'builder/FILES_UPLOAD_FAILURE';

const REMOVE_IMAGE_REQUEST = 'builder/REMOVE_IMAGE_REQUEST';
const REMOVE_IMAGE_SUCCESS = 'builder/REMOVE_IMAGE_SUCCESS';
const REMOVE_IMAGE_FAILURE = 'builder/REMOVE_IMAGE_FAILURE';

const DELETE_WEBHOOK_REQUEST = 'settings/DELETE_WEBHOOK_REQUEST';
const ADD_URL_PARAM_SUCCESS = 'settings/ADD_URL_PARAM_SUCCESS';
const DELETE_URL_PARAM_REQUEST = 'settings/DELETE_URL_PARAM_REQUEST';

const DELETE_SELECTED_SUBMISSIONS_SUCCESS = 'results/DELETE_SELECTED_SUBMISSIONS_SUCCESS';

const UPDATE_FORM_URL_PARAMETER = 'builder/UPDATE_FORM_URL_PARAMETER';

const UPDATE_PREVIEW_DEVICE = 'builder/UPDATE_PREVIEW_DEVICE';

// Initial State
const initialState = {
  ready: false,

  formId: null,

  form: {},
  fields: [],
  theme: {},
  // fonts: [],
  values: {},
  images: [],

  embedSettings: {
    width: 600,
    height: 800,
    sizeType: 'responsive' // responsive, fixed
  },

  imagesQuery: '',
  imagesLoading: false,
  imagesPage: 1,
  imagesTotalPages: 0,

  selectedEmptyBox: null,
  activeButtonHover: null,
  activeInputBoxHover: null,

  emptyBoxAnimation: true,
  inputBoxAnimation: true,

  editedFieldRef: null,
  editedFieldType: null,

  draggedFieldRef: null,
  reorderInProgress: false,

  linkCopied: false,

  showPreview: false,

  fieldsListPosition: {
    top: 0,
    left: 0
  },

  previewDevice: 'desktop',

  fonts: _sortBy([
    'Work Sans', 'IBM Plex Sans', 'Space Mono', 'Libre Franklin', 'Rubik', 'Cormorant', 'Fira Sans', 'Eczar', 'Alegreya Sans', 'Alegreya', 
    'Chivo', 'Lato', 'Source Sans Pro', 'Source Serif Pro', 'Roboto', 'Roboto Slab', 'BioRhyme', 'Poppins', 'Archivo', 'Libre Baskerville', 
    'Playfair Display', 'Karla', 'Montserrat', 'Proza Libre', 'Spectral', 'Domine', 'Inknut Antiqua', 'PT Sans', 'PT Serif', 
    'Neuton', 'Open Sans', 'Inconsolata', 'Cabin', 'Raleway', 'Anonymous Pro', 'Arvo', 'Merriweather', 'Fauna One', 'Quattrocento', 
    'Fanwood Text', 'Prata', 'Alfa Slab', 'Gentium Book Basic', 'Nixie One', 'Julius Sans One', 'Crimson Text', 'Oswald',
    'Old Standard TT', 'Questrial', 'Slabo', 'Sintony', 'Dancing Script', 'Muli', 'Rufina', 'Oxygen', 'Lora', 'Asap',
    'Teko', 'Anton', 'Alata', 'Lexend Deca', 'Questrial', 'Markazi Text', 'Pridi', 'Rozha One', 'Bree Serif',
    'Arbutus Slab', 'Hammersmith One', 'Alef'
  ].map((font) => {
    return { label: font, value: font };
  }), (font) => font.value),

  fieldOptions: [{
    label: 'Short Answer',
    title: 'Short Answer',
    desc: 'Best used for shorter, open-ended answers such as names.',
    tip: 'For longer, multiple line answers we recommend using the Long Answer question type.',
    type: 'shortText',
    backgroundColor: '#DC9749',
    valueMaxLength: 1000,
    height: 60,
    custom: {
      format: null
    }
  }, {
    label: 'Long Answer',
    title: 'Long Answer',
    desc: 'Best used for longer, open-ended answers such as opinions.',
    tip: 'For shorter, single line answers we recommend using the Short Answer question type.',
    type: 'longText',
    valueMaxLength: 5000,
    backgroundColor: '#DC6549',
    height: 99,
    custom: {}
  }, {
    label: 'Email Address',
    title: 'Email Address',
    desc: 'Used to collect email addresses.',
    tip: 'This field only accepts valid email inputs (such as john@acme.com).',
    type: 'shortText',
    backgroundColor: '#BA6D6D',
    valueMaxLength: 1000,
    height: 60,
    custom: {
      format: 'email'
    }
  }, {
    label: 'Phone Number',
    title: 'Phone Number',
    desc: 'Used to collect phone numbers, and displays country flags.',
    tip: 'This field only accepts valid phone number inputs.',
    type: 'shortText',
    backgroundColor: '#AA3C58',
    valueMaxLength: 1000,
    height: 60,
    custom: {
      format: 'phone'
    }
  }, {
    label: 'Number',
    title: 'Number',
    desc: 'Used to collect numbers.',
    tip: 'This field only accepts valid number inputs.',
    type: 'shortText',
    backgroundColor: '#6A59AF',
    valueMaxLength: 1000,
    height: 60,
    custom: {
      format: 'number'
    }
  }, {
    label: 'Multiple Choice',
    title: 'Multiple Choice',
    desc: 'Have your respondents select multiple options from a list of options.',
    tip: 'To have your respondents select only a single option, use the Single Choice question type.',
    type: 'checkbox',
    valueMaxLength: 300,
    options: [{
      value: 'Option 1'
    }, {
      value: 'Option 2'
    }, {
      value: 'Option 3'
    }],
    backgroundColor: '#619BC0',
    height: 106,
    custom: {
      value: JSON.stringify({values: [], other: null})
    }
  }, {
    label: 'Single Choice',
    title: 'Single Choice',
    desc: 'Have your respondents select a single options from a list of options.',
    tip: 'To have your respondents select multiple options, use the Multiple Choice question type.',
    valueMaxLength: 300,
    type: 'radio',
    options: [{
      value: 'Option 1'
    }, {
      value: 'Option 2'
    }, {
      value: 'Option 3'
    }],
    backgroundColor: '#61C0BF',
    height: 106,
    custom: {
      value: JSON.stringify({value: null, other: null})
    }
  }, {
    label: 'Dropdown',
    title: 'Dropdown',
    desc: 'Provide a list of options to choose from.',
    tip: 'This question type is best used for larger option lists.',
    valueMaxLength: 300,
    type: 'dropdown',
    options: [{
      value: 'Option 1'
    }, {
      value: 'Option 2'
    }, {
      value: 'Option 3'
    }],
    backgroundColor: '#6275CE',
    height: 60,
    custom: {}
  }, {
    label: 'File Upload',
    title: 'File Upload',
    desc: 'Collect any type of file up to 50MB, such as photos or resumes. Up to you!',
    tip: null,
    type: 'fileUpload',
    backgroundColor: '#43BB57',
    height: 153,
    custom: {
      value: JSON.stringify([]),
      fileUploadExtensions: [
        'application/pdf', 
        'application/msword', 
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
        'text/plain', 
        'application/rtf', 
        'application/vnd.oasis.opendocument.text', 
        'application/vnd.ms-powerpoint', 
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.oasis.opendocument.presentation',
        'application/vnd.oasis.opendocument.spreadsheet',
        'text/csv', 
        'application/vnd.ms-excel', 
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
        'application/x-iwork-numbers-sffnumbers', 
        'application/x-iwork-keynote-sffke',
        'image/png', 
        'image/jpeg', 
        'image/gif',
        'application/json',
        'text/xml',
        'application/zip',
        'application/x-rar-compressed',
        'audio/mpeg',
        'audio/wav',
        'audio/aiff',
        'application/pbix'
      ],
      fileUploadMaxFiles: 1
    }
  }, {
    label: 'Title',
    title: 'Title',
    desc: 'Add a title to the structure of your form or survey. Perfect to separate sections of questions.',
    tip: null,
    type: 'title',
    backgroundColor: '#548AE2',
    height: 39,
    custom: {}
  }, {
    label: 'Image',
    title: 'Image',
    desc: 'Add an image to your form or survey. Could be your logo or any other image you want.',
    tip: null,
    type: 'image',
    backgroundColor: '#43AABA',
    height: 60,
    custom: {
      imageAlign: 'center',
      imageScale: 100
    }
  }, {
    label: 'Description',
    title: 'Description',
    desc: 'Add a description to the structure of your form or survey. Perfect to provide additional information.',
    tip: null,
    type: 'description',
    value: 'This is a <strong>Description</strong> field',
    backgroundColor: '#548AE2',
    height: 16,
    custom: {}
  }, {
    label: 'Date-Time',
    title: 'Date-Time',
    desc: 'Collect calendar dates or times.',
    tip: 'You can choose the format of the dates and/or times you would like to collect.',
    type: 'datetime',
    backgroundColor: '#C061A3',
    height: 60,
    custom: {
      dateTimeMode: 'datetime',
      dateTimeFormat: 'day.month.year hour:minute',
      dateTimeDateSeparator: '.',
      dateTimeHourMode: '12',
      dateTimeTimeIncrement: '5'
    }
  }, {
    label: 'Signature',
    title: 'Signature',
    desc: 'Have your respondents sign their responses.',
    tip: 'You can collect both drawn and written signatures with this question type.',
    type: 'signature',
    backgroundColor: '#24B47E',
    height: 285,
    custom: {
      signatureAllowTyping: true
    }
  }, {
    label: 'Section',
    title: 'Section',
    desc: 'Combine multiple fields in single or multi-column sections.',
    tip: null,
    type: 'section',
    backgroundColor: '#6A87C2',
    height: 63,
    custom: {}
  }, {
    label: 'Divider',
    title: 'Divider',
    desc: 'Add a divider to the structure of your form or survey. Perfect to separate sections of questions.',
    tip: null,
    type: 'divider',
    backgroundColor: '#6A87C2',
    height: 12,
    custom: {}
  }, {
    label: 'Page Break',
    title: 'Page Break',
    desc: 'Adds additional pages to your form or survey.',
    tip: null,
    type: 'pageBreak',
    backgroundColor: '#49AEFF',
    height: 112,
    custom: {}
  }, {
    label: 'Scale',
    title: 'Scale',
    desc: 'Give your respondents a numbered scale to choose from.',
    tip: 'You can customize the range of the scale, if you’re interested in that!',
    type: 'scale',
    backgroundColor: '#3D7D96',
    height: 91,
    custom: {
      scaleRange: [0, 10],
      scaleTextLeft: 'Least Likely',
      scaleTextCenter: '',
      scaleTextRight: 'Most Likely',
    }
  }, {
    label: 'Image Choice',
    title: 'Image Choice',
    desc: 'Have your respondents select from one of multiple images.',
    tip: 'Spice up your survey with GIFs or use this to collect a NPS score.',
    type: 'imageChoice',
    backgroundColor: '#3D7D96',
    height: 70,
    custom: {
      imageChoiceRows: 4
    },
    options: [{
      url: null,
      text: null
    }]
  }, {
    label: 'Address',
    title: 'Address',
    desc: 'A collection of fields used to collect address information.',
    tip: null,
    type: 'addressSection',
    backgroundColor: '#6A87C2',
    height: 656,
    custom: {},
    fields: addressSectionFields
  }],

  fontWeightOptions: [{
    label: 'Bold',
    value: 'bold'
  }, {
    label: 'Regular',
    value: 'regular'
  }],

  selectedFieldOption: null,
  firstHoverOnFieldOption: true,
  designFormHeight: 0,

  uploads: []
};

const addPagesToFields = (fields) => {
  let page = 1;

  for (let index in fields) {
    fields[index].page = page;

    if (fields[index].type === 'pageBreak') {
      page = page + 1;
    }
  }

  return {
    fields,
    pages: page
  };
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  if (action.type === CLEAR) {
    state = { ...initialState };

    state.formId = null;

    return { ...state };
  }

  if (action.type === BUILDER_READY) {
    state.ready = true;

    return { ...state };
  }

  if (action.type === BUILDER_RELOADING) {
    state.ready = false;

    return { ...state };
  }

  if (action.type === UPDATE_FORM_ID) {
    state.formId = action.payload.id;

    return { ...state };
  }

  if (action.type === UPDATE_LINK_COPIED) {
    state.linkCopied = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_DESIGN_FORM_HEIGHT) {
    state.designFormHeight = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_IMAGES_PAGE) {
    state.imagesPage = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_EMBED_SETTINGS) {
    state.embedSettings = { ...state.embedSettings, ...action.payload.obj };

    return { ...state };
  }
  
  if (action.type === UPDATE_IMAGES_QUERY) {
    state.imagesQuery = action.payload.value;

    return { ...state };
  }

  if (action.type === GET_IMAGES_REQUEST) {
    state.imagesLoading = true;

    return { ...state };
  }

  if (action.type === GET_IMAGES_SUCCESS) {
    state.imagesTotalPages = action.payload.total_pages;
    state.images = action.payload.results.map((image) => {
      return {
        id: image.id,
        thumb: image.urls.thumb,
        url: image.urls.full,
        user: image.user,
        links: image.links
      };
    });

    state.imagesLoading = false;

    return { ...state };
  }

  if (action.type === GET_IMAGES_FAILURE) {
    state.imagesLoading = false;
    state.images = [];

    return { ...state };
  }

  if (action.type === GET_THEME_REQUEST) {
    return { ...state };
  }

  if (action.type === GET_THEME_SUCCESS) {
    state.theme = action.payload;

    return { ...state };
  }

  if (action.type === GET_THEME_FAILURE) {
    state.theme = {};

    return { ...state };
  }

  if (action.type === GET_FORM_REQUEST) {
    return { ...state };
  }

  if (action.type === GET_FORM_SUCCESS) {
    const { pages, fields } = addPagesToFields(sortFieldsByPosition(action.payload.fields));
    let form = { ...action.payload };

    form.fields = fields;
    form.pages = form.type === 'classic' ? pages : 1;
    state.values = {};

    form.fields.map((field) => {
      state.values[field._id] = {
        value: field.value || null,
        type: field.type,
        position: field.position,
        hidden: field.hidden,
        section: field.section
      };

      return field;
    });

    state.form = JSON.parse(JSON.stringify(form));

    return { ...state };
  }

  if (action.type === GET_FORM_FAILURE) {
    state.form = {};

    return { ...state };
  }

  if (action.type === TOGGLE_FORM_PREVIEW) {
    state.showPreview = action.payload.value;

    return { ...state };
  }

  if (action.type === GET_FORM_FIELDS_REQUEST) {
    return { ...state };
  }

  if (action.type === GET_FORM_FIELDS_SUCCESS || action.type === UPDATE_FIELDS) {
    const { pages, fields } = addPagesToFields(sortFieldsByPosition(action.payload));

    state.form.fields = [ ...fields ];
    state.form.pages = state.form.type === 'classic' ? pages : 1;
    state.form = JSON.parse(JSON.stringify(state.form));
    state.fields = JSON.parse(JSON.stringify(fields));
    state.values = {};

    state.fields.map((field) => {
      state.values[field._id] = {
        value: field.value || null,
        type: field.type,
        position: field.position,
        hidden: field.hidden,
        section: field.section
      };

      return field;
    });

    return { ...state };
  }

  if (action.type === GET_FORM_FIELDS_FAILURE) {
    state.fields = [];

    return { ...state };
  }

  if (action.type === UPDATE_FIELDS_LIST_POSITION) {
    state.fieldsListPosition = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_ACTIVE_BUTTON_HOVER) {
    state.activeButtonHover = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_SELECTED_EMPTY_BOX) {
    state.selectedEmptyBox = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_SELECTED_FIELD_OPTION) {
    state.selectedFieldOption = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_FIRST_HOVER_ON_FIELD_OPTION) {
    state.firstHoverOnFieldOption = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_THEME) {
    state.theme = { ...state.theme, ...action.payload.params };

    return { ...state };
  }

  if (action.type === UPDATE_FORM) {
    state.form = { ...state.form, ...action.payload.params };

    return { ...state };
  }

  if (action.type === UPDATE_EMPTY_BOX_ANIMATION) {
    state.emptyBoxAnimation = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_INPUT_BOX_ANIMATION) {
    state.inputBoxAnimation = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_DRAGGED_FIELD_REF) {
    state.draggedFieldRef = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_EDITED_FIELD_REF) {
    state.editedFieldRef = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_EDITED_FIELD_TYPE) {
    state.editedFieldType = action.payload.type;

    return { ...state };
  }

  if (action.type === IO_ADD_FIELD_SUCCESS) {
    const index = state.fields.findIndex((field) => field.ref === action.payload.ref);

    if (index !== -1) {
      state.fields[index] = { ...state.fields[index], ...action.payload };
      state.fields = JSON.parse(JSON.stringify(state.fields));

      state.form.fields = [ ...state.fields ];

      state.form = JSON.parse(JSON.stringify(state.form));
      
      state.values = {};
      state.fields.map((field) => {
        state.values[field._id] = {
          value: field.value || null,
          type: field.type,
          position: field.position,
          hidden: field.hidden,
          section: field.section
        };
  
        return field;
      });
    }

    ioTasks.remove(action.taskId);

    return { ...state };
  }

  if (action.type === IO_UPDATE_FIELD_SUCCESS) {
    ioTasks.remove(action.taskId);
  }

  if (action.type === IO_REORDER_FIELDS) {
    state.reorderInProgress = true;

    return { ...state };
  }

  if (action.type === IO_REORDER_FIELDS_SUCCESS) {
    state.reorderInProgress = false;

    return { ...state };
  }

  if (action.type === UPDATE_FIELD) {
    const index = state.fields.findIndex((field) => field.ref === action.payload.ref);

    if (index >= 0) {
      state.fields[index] = { ...state.fields[index], ...action.payload.params };
    }

    state.form.fields = [ ...state.fields ];

    state.fields = JSON.parse(JSON.stringify(state.fields));
    state.form.fields = JSON.parse(JSON.stringify(state.fields));

    state.values = {};
    state.fields.map((field) => {
      state.values[field._id] = {
        value: field.value || null,
        type: field.type,
        position: field.position,
        hidden: field.hidden,
        section: field.section
      };

      return field;
    });

    return { ...state };
  }

  if (action.type === DELETE_SELECTED_SUBMISSIONS_SUCCESS) {
    state.form.submissionsCount = state.form.submissionsCount - action.payload.count;

    if (action.payload.deleteAll) {
      state.form.submissionsCount = 0;
    }

    state.form = { ...state.form };

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_REQUEST) {
    state.uploads = [...state.uploads, action.payload.file];

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_PROGRESS) {
    if (state.uploads.find((file) => file.ref === action.payload.ref)) {
      state.uploads.find((file) => file.ref === action.payload.ref).loaded = action.payload.percentage;

      state.uploads = [...state.uploads];
    }

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_SUCCESS) {
    state.uploads.find((file) => file.ref === action.payload.ref).loaded = true;
    state.uploads.find((file) => file.ref === action.payload.ref).url = action.payload.url;
    state.uploads.find((file) => file.ref === action.payload.ref).cancel = undefined;

    state.uploads = [...state.uploads];

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_FAILURE) {
    state.uploads.find((file) => file.ref === action.payload.ref).cancel = undefined;

    state.uploads = [...state.uploads];

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_CANCEL) {
    state.uploads.find((file) => file.ref === action.payload.ref).cancel = action.payload.cancel;

    state.uploads = [...state.uploads];

    return { ...state };
  }

  if (action.type === FILES_UPLOAD_CANCEL) {
    state.uploads.find((file) => file.ref === action.payload.ref).cancel();
    state.uploads.splice(state.uploads.findIndex((file) => file.ref === action.payload.ref), 1);

    state.uploads = [...state.uploads];

    return { ...state };
  }

  if (action.type === REMOVE_IMAGE_REQUEST) {
    const index = state.uploads.findIndex((file) => file.ref === action.payload.ref);

    if (index !== -1) {
      state.uploads.splice(index, 1);
      state.uploads = [...state.uploads];
    }

    return { ...state };
  }

  if (action.type === DELETE_WEBHOOK_REQUEST) {
    const index = state.form.webHooks.findIndex((hook) => hook._id === action.payload.id);

    if (index !== -1) {
      state.form.webHooks.splice(index, 1);
      state.form.webHooks = [...state.form.webHooks];
      state.form = {...state.form};
    }

    return { ...state };
  }

  if (action.type === ADD_URL_PARAM_SUCCESS) {
    state.form.urlParams.push(action.payload);

    state.form.urlParams = [...state.form.urlParams];
    state.form = {...state.form};

    return { ...state };
  }

  if (action.type === DELETE_URL_PARAM_REQUEST) {
    const index = state.form.urlParams.findIndex((param) => param._id === action.payload.id);

    if (index !== -1) {
      state.form.urlParams.splice(index, 1);
      state.form.urlParams = [...state.form.urlParams];
      state.form = {...state.form};
    }

    return { ...state };
  }

  if (action.type === UPDATE_FORM_URL_PARAMETER) {
    const index = state.form.urlParams.findIndex((param) => param._id === action.payload.params.id);

    if (index !== -1) {
      state.form.urlParams[index].key = action.payload.params.key;
      state.form.urlParams[index].value = action.payload.params.value;

      state.form.urlParams = [...state.form.urlParams];
      state.form = {...state.form};
    }

    return { ...state };
  }

  if (action.type === UPDATE_PREVIEW_DEVICE) {
    state.previewDevice = action.payload.value;

    return { ...state };
  }

  return state;
}

// Action Creators
export function clear() {
  return (dispatch) => {
    dispatch({ type: CLEAR });
  };
}

export function toggleFormPreview(value) {
  return (dispatch) => {
    dispatch({ type: TOGGLE_FORM_PREVIEW, payload: { value } });
  };
}

export function updatePreviewDevice(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_PREVIEW_DEVICE, payload: { value } });
  };
}

export function updateSelectedEmptyBox(value) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_SELECTED_EMPTY_BOX, payload: { value } });
  };
}

export function updateDesignFormHeight(value) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_DESIGN_FORM_HEIGHT, payload: { value } });
  };
}

export function updateLinkCopied(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_LINK_COPIED, payload: { value } });
  };
}

export function updateSelectedFieldOption(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_SELECTED_FIELD_OPTION, payload: { value } });
  };
}

export function updateFirstHoverOnFieldOption(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FIRST_HOVER_ON_FIELD_OPTION, payload: { value } });
  };
}

export function updateFields(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FIELDS, payload: value });
  };
}

export function updateActiveButtonHover(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_ACTIVE_BUTTON_HOVER, payload: { value } });
  };
}

export function updateFieldsListPosition(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FIELDS_LIST_POSITION, payload: { value } });
  };
}

export function updateEmptyBoxAnimation(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_EMPTY_BOX_ANIMATION, payload: { value } });
  };
}

export function updateInputBoxAnimation(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_INPUT_BOX_ANIMATION, payload: { value } });
  };
}

export function updateDraggedFieldRef(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_DRAGGED_FIELD_REF, payload: { value } });
  };
}


export function updateImagesPage(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_IMAGES_PAGE, payload: { value } });
  };
}

export function updateImagesQuery(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_IMAGES_QUERY, payload: { value } });
  };
}

export function updateField(ref, params) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FIELD, payload: { ref, params } });
  };
}

export function updateTheme(params) {
  return (dispatch) => {
    dispatch({ type: UPDATE_THEME, payload: { params } });
  };
}

export function updateForm(params) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_FORM, payload: { params } });
  };
}

export function updateFormUrlParameter(params) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FORM_URL_PARAMETER, payload: { params } });
  };
}

export function updateFormId(id) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_FORM_ID, payload: { id } });
  };
}

export function updateEmbedSettings(obj) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_EMBED_SETTINGS, payload: { obj } });
  };
}

export function builderLoaded() {
  return async (dispatch) => {
    dispatch({ type: BUILDER_READY });
  };
}

export function builderReloading() {
  return async (dispatch) => {
    dispatch({ type: BUILDER_RELOADING });
  };
}

export function getImages() {
  const request = () => { return { type: GET_IMAGES_REQUEST } };
  const success = (images) => { return { type: GET_IMAGES_SUCCESS, payload: images } };
  const failure = () => { return { type: GET_IMAGES_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());
    const state = getState();

    const response = await services.getImages(state.builder.imagesQuery, state.builder.imagesPage);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function removeImage(ref, url) {
  const request = (ref) => { return { type: REMOVE_IMAGE_REQUEST, payload: { ref } } };
  const success = () => { return { type: REMOVE_IMAGE_SUCCESS } };
  const failure = () => { return { type: REMOVE_IMAGE_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request(ref));

    const state = getState();
    const response = await services.removeImage(state._users.token, state._users.user.permissions, state.builder.formId, url);

    if (response) {
      dispatch(success());
    } else {
      dispatch(failure());
    }
  };
}

export function uploadImage(ref, files, field) {
  const request = (file) => { return { type: FILES_UPLOAD_REQUEST, payload: { file } } };
  const success = (ref, url) => { return { type: FILES_UPLOAD_SUCCESS, payload: { ref, url } } };
  const progress = (ref, percentage) => { return { type: FILES_UPLOAD_PROGRESS, payload: { ref, percentage } } };
  const cancel = (ref, cancel) => { return { type: FILES_UPLOAD_CANCEL, payload: { ref, cancel } } };
  const failure = (ref) => { return { type: FILES_UPLOAD_FAILURE, payload: { ref } } };

  return async (dispatch, getState) => {
    const uploadProgress = (ref) => (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentage = Math.floor((loaded * 100) / total);

      dispatch(progress(ref, percentage));
    }

    const uploadSuccess = (ref, field, state) => (respose) => {
      dispatch(success(ref, respose.data.url));

      let fieldOptions, optionIndex, currentValue;

      if (field.type === 'welcome') {
        dispatch(updateForm({
          welcomePage: { ...state.builder.form.welcomePage, image: respose.data.url } 
        }));

        dispatch(ioUpdateForm({
          welcomePage: { ...state.builder.form.welcomePage, image: respose.data.url } 
        })); 
      }

      if (field.type === 'favicon') {
        dispatch(updateForm({
          favicon: { ...state.builder.form.favicon, value: respose.data.url } 
        }));

        dispatch(ioUpdateForm({
          favicon: { ...state.builder.form.favicon, value: respose.data.url } 
        })); 
      }

      if (field.type === 'image') {
        dispatch(updateField(field.ref, {
          value: respose.data.url
        }));

        dispatch(ioUpdateField(field.ref, {
          value: respose.data.url
        }));
      }

      if (field.type === 'ogimage') {
        dispatch(updateForm({
          seo: { ...state.builder.form.seo, image: respose.data.url } 
        }));

        dispatch(ioUpdateForm({
          seo: { ...state.builder.form.seo, image: respose.data.url } 
        })); 
      }

      if (field.type === 'imageChoice') {
        fieldOptions = [ ...field.options ];

        optionIndex = fieldOptions.findIndex((option) => option.ref === ref);
        if (optionIndex === -1) return state;
    
        currentValue = isJsonString(fieldOptions[optionIndex].value) ? JSON.parse(fieldOptions[optionIndex].value) : { text: '', url: null };
    
        fieldOptions[optionIndex].value = JSON.stringify({ ...currentValue, ...{ url: respose.data.url } });
    
        dispatch(updateField(field.ref, {
          options: fieldOptions
        }));

        dispatch(ioUpdateField(field.ref, {
          options: fieldOptions
        }));
      }
    }

    const uploadFailure = (ref) => (respose) => {
      dispatch(failure(ref, respose.data));
    }

    const state = getState();

    let newFile = {};
    let data;
    let source;

    for (let file of files) {
      data = new FormData();
      source = axios.CancelToken.source();

      newFile = {
        ref,
        size: file.size,
        loaded: 0,
        name: file.name,
        type: file.type
      };

      data.append(newFile.ref, file);

      dispatch(request(newFile));
      dispatch(cancel(newFile.ref, source.cancel));

      axios({
        method: 'POST',
        url: `${config.apiUrl}/forms/${state.builder.formId}/images`,
        data,
        cancelToken: source.token,
        onUploadProgress: uploadProgress(newFile.ref),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${state._users.token}`,
          'Permissions': state._users.user.permissions
        }
      }).then(uploadSuccess(newFile.ref, field, state)).catch((thrown) => {
        if (!axios.isCancel(thrown)) {
          uploadFailure(newFile.ref);
        }
      });
    }
  };
}

export function copyFormMessaging(from) {
  const request = () => { return { type: COPY_FORM_MESSAGING_REQUEST } };
  const success = () => { return { type: COPY_FORM_MESSAGING_SUCCESS } };
  const failure = () => { return { type: COPY_FORM_MESSAGING_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.copyFormMessaging(state._users.token, state._users.user.permissions, state.builder.formId, { from });

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}

export function getForm(id) {
  const request = () => { return { type: GET_FORM_REQUEST } };
  const success = (form) => { return { type: GET_FORM_SUCCESS, payload: form } };
  const failure = () => { return { type: GET_FORM_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getForm(state._users.token, state._users.user.permissions, id || state.builder.formId);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}

export function getTheme(id) {
  const request = () => { return { type: GET_THEME_REQUEST } };
  const success = (form) => { return { type: GET_THEME_SUCCESS, payload: form } };
  const failure = () => { return { type: GET_THEME_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getTheme(state._users.token, state._users.user.permissions, id || state.builder.formId);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}

export function getFields(id) {
  const request = () => { return { type: GET_FORM_FIELDS_REQUEST } };
  const success = (form) => { return { type: GET_FORM_FIELDS_SUCCESS, payload: form } };
  const failure = () => { return { type: GET_FORM_FIELDS_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getFields(state._users.token, state._users.user.permissions, id || state.builder.formId);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}

export function pingUnsplash(url) {
  const request = () => { return { type: PING_UNSPLASH_REQUEST } };

  return async (dispatch) => {
    dispatch(request());

    await services.pingUnsplash(url);
  };
}

export function updateEditedFieldRef(value) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_EDITED_FIELD_REF, payload: { value } });
  };
}

export function updateEditedFieldType(type) {
  return (dispatch) => {
    dispatch({ type: UPDATE_EDITED_FIELD_TYPE, payload: { type } });
  };
}

export function ioAddField(params, options) {
  return async (dispatch, getState) => {
    const state = getState();
    const taskId = String(ObjectID());

    ioTasks.add(taskId);

    return dispatch({
      type: IO_ADD_FIELD, payload: {
        formId: state.builder.formId,
        permissions: state._users.user.permissions,
        params,
        options
      }, taskId
    });
  };
}

export function ioUpdateField(ref, params) {
  return (dispatch, getState) => {
    const state = getState();
    const taskId = String(ObjectID());

    ioTasks.add(taskId);

    return dispatch({
      type: IO_UPDATE_FIELD, payload: {
        formId: state.builder.formId,
        permissions: state._users.user.permissions,
        ref, params
      }, taskId
    });
  };
}

export function ioUpdateTheme(params) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: IO_UPDATE_THEME, payload: {
        formId: state.builder.formId,
        permissions: state._users.user.permissions,
        params
      }
    });
  };
}

export function ioUpdateForm(params) {
  return async (dispatch, getState) => {
    const state = getState();

    return dispatch({
      type: IO_UPDATE_FORM, payload: {
        formId: state.builder.formId,
        permissions: state._users.user.permissions,
        params
      }
    });
  };
}

export function ioCopyFieldFile({ fieldRef, type, optionRef, oldUrl, newUrl }) {
  return async (dispatch, getState) => {
    return dispatch({
      type: IO_COPY_FIELD_FILE, payload: {
        fieldRef, type, optionRef, oldUrl, newUrl
      }
    })
  };
}

export function ioUpdateFormUrlParameter(params) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: IO_UPDATE_FORM_URL_PARAMETER, payload: {
        formId: state.builder.formId,
        permissions: state._users.user.permissions,
        params
      }
    });
  };
}

export function ioRemoveField(ref) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: IO_REMOVE_FIELD, payload: {
        formId: state.builder.formId,
        permissions: state._users.user.permissions,
        ref
      }
    });
  };
}

export function ioReorderFields(params) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: IO_REORDER_FIELDS, payload: {
        formId: state.builder.formId,
        permissions: state._users.user.permissions,
        params
      }
    });
  };
}

// Side effects
const services = {
  copyFormMessaging: async (token, permissions, formId, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/forms/${formId}/copy/messaging`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        },
        data
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  getForm: async (token, permissions, formId) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/forms/${formId}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  getFields: async (token, permissions, formId) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/forms/${formId}/fields`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  getTheme: async (token, permissions, formId) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/forms/${formId}/theme`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  removeImage: async (token, permissions, formId, url) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${config.apiUrl}/forms/${formId}/images`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        },
        data: {
          url
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },  
  getImages: async (query, page) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `https://api.unsplash.com/search/photos?order_by=popular&page=${page || 1}&per_page=6&orientation=landscape&query=${query || 'pattern'}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Client-ID ${config.unsplashApiKey}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  pingUnsplash: async (url) => {
    try {
      const response = await axios({
        method: 'GET',
        url,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Client-ID ${config.unsplashApiKey}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
};
