import styles from './TutorialPicker.module.css';

import React, { useContext, useCallback } from 'react';

import { useRouteChanger } from 'helpers';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { Icon, TabsHeader, SuspenseFallback, ErrorFallback } from 'ui-components';

import TutorialsContext from '../tutorialsContext';

import { withQueryBoundary } from 'api';

import useAvailableTutorials from '../hooks/useAvailableTutorials';

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */

const TutorialPicker = React.memo(({ className = '', style = null, label = null, onTutorialRequested }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const { push: navigateInApp } = useRouteChanger();
  const { setRequestedTutorial } = useContext(TutorialsContext);

  const availableTutorials = useAvailableTutorials();

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const handleRequestPlayTutorial = useCallback(
    (tutorial) => {
      const { key, route, blocker } = tutorial;
      if (blocker) return;

      onTutorialRequested();

      // Gives some time for modal to hide
      setTimeout(() => {
        setRequestedTutorial(key);
        navigateInApp(route);
      }, 250);
    },
    [setRequestedTutorial, navigateInApp, onTutorialRequested]
  );

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <div className={[className, styles.tutorialPicker].join(' ')} style={style}>
      {label && <TabsHeader>{label}</TabsHeader>}

      <div className={styles.tutorials}>
        {!availableTutorials.length ? (
          <>
            <div className={styles.placeholder}>Unfortunately there are no tutorials that are available to you.</div>
          </>
        ) : (
          availableTutorials.map((tutorial) => {
            const { key, title, description, completionRatio, blocker } = tutorial;
            const wholePercent = Math.round(completionRatio * 100);

            let titleBadgeJsx = null;
            if (blocker) {
              titleBadgeJsx = <span className={styles.notStartedBadge}>Not available</span>;
            } else if (wholePercent === 0) {
              titleBadgeJsx = <span className={styles.notStartedBadge}>Not started</span>;
            }

            return (
              <button
                disabled={Boolean(blocker)}
                className={[styles.tutorial, blocker ? styles.blocker : ''].join(' ')}
                key={key}
                onClick={() => handleRequestPlayTutorial(tutorial)}>
                <div className={styles.tutorialProgress}>
                  <CircularProgressbarWithChildren
                    value={wholePercent}
                    strokeWidth={8}
                    styles={buildStyles({
                      pathColor: wholePercent === 100 ? '#0F9F6E' : '#2675D1',
                      trailColor: '#EAE8E4',
                      pathTransitionDuration: 1
                    })}>
                    <strong className={styles.tutorialProgressText}>{wholePercent}%</strong>
                  </CircularProgressbarWithChildren>
                </div>
                <div className={styles.tutorialLabel}>
                  <div className={styles.tutorialTitle}>
                    <span>{title}</span>
                    {titleBadgeJsx}
                  </div>
                  {blocker ? (
                    <div className={styles.tutorialBlocker}>{blocker}</div>
                  ) : (
                    <div className={styles.tutorialDescription}>{description}</div>
                  )}
                </div>
                {!blocker && (
                  <div className={styles.tutorialArrow}>
                    <Icon id="manage-arrow" />
                  </div>
                )}
              </button>
            );
          })
        )}
      </div>
    </div>
  );
});

/* -------------------------------------------------------------------------- */
/*                                  FALLBACKS                                 */
/* -------------------------------------------------------------------------- */

export default withQueryBoundary(TutorialPicker, {
  SuspenseFallbackComponent: () => (
    <div style={{ margin: 10 }}>
      <SuspenseFallback inline />
    </div>
  ),
  ErrorFallbackComponent: ({ error }) => (
    <div style={{ margin: 10 }}>
      <ErrorFallback error={error} />
    </div>
  )
});
