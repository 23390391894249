export default function isFieldEmpty(value, type) {
  const types = {
    shortText: () => {
      return !value;
    },
    longText: () => {
      return !value;
    },
    dropdown: () => {
      return !value;
    },
    datetime: () => {
      if (value === null) return true;

      const entries = Object.entries(JSON.parse(value));

      for (let entry of entries) {
        if (entry[1] !== '') {
          return false;
        }
      }

      return true;
    },
    radio: () => {
      return !value || value === '{"value":null,"other":null}' || value === '{"value":null,"other":""}';
    },
    checkbox: () => {
      return !value || value === '{"values":[],"other":null}' || value === '{"values":[],"other":""}';
    },
    fileUpload: () => {
      return !value || value === '[]';
    },
    signature: () => {
      return !value;
    },
    scale: () => {
      return !/^\d+$/.test(value);
    },
  };

  if (typeof types[type] === 'undefined') return false;

  return types[type]();
};
