import styles from './LabeledListPreview.module.css';

import React from 'react';

import { Expandible } from 'ui-components';

import EmptyPreview from './EmptyPreview';

/* ---------------------------- HELPER COMPONENTS --------------------------- */

const TransparentTable = ({ data = [], bullet = null }) => {
  return (
    <table className={styles.table}>
      <tbody>
        {data.map((row = {}, i) => {
          const label = row.label || row.name || row.key;
          const value = row.value;
          return (
            <tr key={String(label) + i}>
              {bullet && <td className={styles.bullet}>{bullet}</td>}
              <td className={styles.label}>{String(label)}</td>
              <td className={styles.value}>{String(value)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

/* ----------------------------- MAIN COMPONENT ----------------------------- */

const LabeledListPreview = ({ theme, value = null, bullet = null, limit, allowCollapsing, placeholder }) => {
  if (value && !Array.isArray(value)) value = [value];

  if (!value?.length) {
    return <EmptyPreview theme={theme} placeholder={placeholder} />;
  } else {
    return (
      <div className={[styles.labeledListPreview, styles[`theme-${theme}`]].join(' ')}>
        <Expandible
          data={value}
          allowCollapsing={allowCollapsing}
          limit={limit}
          renderData={(data) => <TransparentTable data={data} bullet={bullet} />}
        />
      </div>
    );
  }
};

export default LabeledListPreview;
