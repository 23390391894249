import fields from './fields';
import permissions from './permissions';
import sections from './sections';
import services from './services';
import plans from './plans';
import features from './features';
import googleFonts from './googleFonts';
import euCountries from './euCountries';
import responsesOptions from './responsesOptions';

import getFieldConfig from './helpers/getFieldConfig';
import getFieldIcon from './helpers/getFieldIcon';
import identifySubmissionField from './helpers/identifySubmissionField';
import identifySubmissionColumn from './helpers/identifySubmissionColumn';
import identifyFeaturesByFormPaths, { identifyFeatureByFormPath } from './helpers/identifyFeaturesByFormPaths';

import generateRequiredFieldData from './helpers/generateRequiredFieldData';
import generateFieldData from './helpers/generateFieldData';
import generatePrebuiltSectionData from './helpers/generatePrebuiltSectionData';
import generateSectionDataFromExample from './helpers/generateSectionDataFromExample';
import cloneField from './helpers/cloneField';

const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  formUrl: process.env.REACT_APP_FORM_URL,
  socketUrl: process.env.REACT_APP_SOCKET_URL,
  unsplashApiKey: process.env.REACT_APP_UNSPLASH_API_KEY,
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  fullpageLicense: process.env.REACT_APP_FULLPAGE_LICENSE,
  paypalClientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  fullStoryExcludeClass: 'full-story-no-record',
  fullStoryHardExcludeClass: 'full-story-no-record-hard',

  // TMP FIX:
  reCaptcha: process.env.NODE_ENV === 'development' ? null : '6LelHqkUAAAAAEZ8OOKqrVEZenT2Jo-mt1Dcj97r',
  ltdf2EndDate: process.env.REACT_APP_LTDF_2_END_DATE,

  socketThrottleMs: 300,

  features,
  plans,
  permissions,
  services,
  sections,
  fields,
  googleFonts,
  euCountries,
  responsesOptions
};

export default config;
export {
  generateRequiredFieldData,
  generateFieldData,
  generatePrebuiltSectionData,
  generateSectionDataFromExample,
  getFieldConfig,
  getFieldIcon,
  cloneField,
  identifySubmissionField,
  identifySubmissionColumn,
  identifyFeatureByFormPath,
  identifyFeaturesByFormPaths
};
