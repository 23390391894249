import React, { useMemo, useContext } from 'react';
import _orderBy from 'lodash/orderBy';

import { useRouteChanger } from 'helpers';
import { BuilderContext } from 'contexts';

import TutorialImg from '../components/TutorialImg';
import useSubmissionsWorkspaces from '../../Builder2/useSubmissionsWorkspaces';

/* -------------------------------------------------------------------------- */
/*                                  CALLBACK                                  */
/* -------------------------------------------------------------------------- */

const createResultsIntroductionSteps = (params) => {
  /* --------------------- PARAM VALIDATION AND CALLBACKS --------------------- */

  const { formId, exampleTableTabId, exampleChartTabId, navigateInApp } = params;

  if (!formId || !exampleTableTabId || !exampleChartTabId || !navigateInApp) return null;

  const navigateInForm = (route = '', urlParams = {}) => navigateInApp(`/form/${formId}${route}`, urlParams);

  const navigateToTable = () => navigateInForm(`/results/${exampleTableTabId}`);
  const navigateToCharts = () => navigateInForm(`/results/${exampleChartTabId}`);

  /* ---------------------------------- STEPS --------------------------------- */

  const PART_1_START = [
    {
      titleSize: 'large',
      onAfterChange: navigateToTable,

      title: 'Responses view introduction',
      content: (
        <>
          <p>
            Welcome to the <b>responses view</b>!
          </p>
          <p>
            This is where you'll find every single collected response on this form. Since forms are just a tool for getting data, so this is
            probably a place where you'll spend most of your time. Note that if you have enabled the disable collecting responses feature,
            responses will not appear here.
          </p>
          <small>🕐 You'll need approx. 2 minutes to finish this tutorial.</small>
        </>
      )
    },
    {
      target: '.intro-top-bar',
      placement: 'bottom',
      spotlightPadding: 0,
      onAfterChange: navigateToTable,

      title: 'Top bar',
      content: (
        <p>
          As you can see, the top bar looks exactly the same as it looks in the <b>create view</b>, therefore you should be quite familiar
          with it by now (if you're not you can learn more about it in the form editor introduction).
        </p>
      )
    },
    {
      target: '.intro-top-bar-navigation',
      placement: 'bottom',
      spotlightPadding: 6,
      onAfterChange: navigateToTable,

      title: 'Results view',
      content: (
        <>
          <p>
            The only thing that is worth mentioning, is that currently we are in the <b>results view</b>.
          </p>
          <p>
            You can navigate back to the <b>form editor</b> by clicking the <dfn>Create</dfn> button.
          </p>
        </>
      )
    },
    {
      target: '.intro-results-tabs',
      placement: 'bottom',
      spotlightPadding: 3,
      onAfterChange: navigateToTable,

      title: 'Managing responses with tabs [1/3]',
      content: (
        <>
          <p>
            Data can be analyzed in many ways. Sometimes you'll want to read your responses one by one, but usually you should be able to
            process them far more efficiently, by focusing on some smaller parts individually.
          </p>
          <p>
            This is exactly what our tabs system is for. You can create as many tabs as you'd like, and customize the filters on each tab
            for more detailed segmentation. We'll get to that in a moment.
          </p>
        </>
      )
    },
    {
      target: '.intro-results-tabs',
      placement: 'bottom',
      spotlightPadding: 3,
      onAfterChange: navigateToTable,

      title: 'Managing responses with tabs [2/3]',
      content: (
        <>
          <p>For example, you can create tabs that:</p>
          <ul>
            <li>don't show all fields,</li>
            <li>display filtered responses,</li>
            <li>sorts responses by a particular order,</li>
            <li>displays charts,</li>
            <li>and more.</li>
          </ul>
          <p>
            The most important thing to remember is that{' '}
            <u>
              every tab <b>can</b> show each and every collected response, but doesn't have to.
            </u>
          </p>
        </>
      )
    },
    {
      target: '.intro-results-add-tab',
      placement: 'bottom',
      spotlightPadding: 2,
      onAfterChange: navigateToTable,

      title: 'Managing responses with tabs [3/3]',
      content: (
        <>
          <p>
            There are two primary types of tabs: <b>table tabs</b> and <b>charts tabs</b>. We will make sure that there is always at least
            one tab of each type present, however you can create as many tabs as you'd like on top of that. Just click the <dfn>+</dfn>{' '}
            button and follow the instructions.
          </p>
          <p>Now let's a quick look at both tab types.</p>
        </>
      )
    }
  ];

  const PART_2_TABLE = [
    {
      target: `.intro-results-tab-${exampleTableTabId}`,
      placement: 'bottom',
      spotlightPadding: 0,
      onAfterChange: navigateToTable,

      title: 'Table tabs',
      content: (
        <>
          <p>
            A table tab is pretty much a customizable, user friendly spreadsheet. Every column corresponds to a field on your form, or to
            one of the additional response properties (Such as creation date), whereas every row always corresponds to one response.
          </p>
          <p>This makes reading responses quick and easy. You can also delete responses as you see fit.</p>
          <TutorialImg name="results_table" />
        </>
      )
    },
    {
      target: `.intro-results-table-toolbar-buttons`,
      placement: 'bottom',
      spotlightPadding: 8,
      onAfterChange: navigateToTable,

      title: 'Table tabs - customization [1/2]',
      content: (
        <>
          <p>
            All newly created table tabs will display all responses by default. In this area you'll find options that you can use to narrow
            down and sort your data as mentioned before.
          </p>
          <p>
            To open a desired dropdown, simply click one of the highlighted buttons. When you're done, remember to confirm the changes by
            clicking the <dfn>Apply changes</dfn> button.
          </p>
          <TutorialImg name="results_table_filters" />
        </>
      )
    },
    {
      onAfterChange: navigateToTable,

      title: 'Table tabs - customization [2/2]',
      content: (
        <>
          <p>You can also access customization features by clicking on a column header.</p>
          <p>
            It's worth pointing out, because this is the only place where you'll an option to <b>freeze a column</b>. This "glues" a column
            to the left-hand side of the table.{' '}
          </p>
          <TutorialImg name="results_table_column_dropdown" />
        </>
      )
    },
    {
      onAfterChange: navigateToTable,

      title: 'Table tabs - single response view [1/2]',
      content: (
        <>
          <p>
            In some cases you may prefer to view responses one by one, to get a more natural reading experience. To open a single response
            view, just double click any cell, or click the link icon under the submission number.
          </p>
          <p>
            Keep in mind that <u>this view uses filtering and sorting of a currently opened table</u>.
          </p>
          <TutorialImg name="results_single_response" />
        </>
      )
    },
    {
      onAfterChange: navigateToTable,

      title: 'Table tabs - single response view [2/2]',
      content: (
        <>
          <p>
            The single response view also has a built-in <b>comment section</b>. You may find it useful if you need to leave some notes for
            yourself or your collaborators.
          </p>
          <TutorialImg name="results_comments" />
        </>
      )
    }
  ];

  const PART_3_CHARTS = [
    {
      target: `.intro-results-tab-${exampleChartTabId}`,
      placement: 'bottom',
      onAfterChange: navigateToCharts,

      title: 'Charts tabs [1/2]',
      content: (
        <>
          <p>Charts tabs are useful if you need to produce some statistics based on your data.</p>
          <p>
            Whether you need to find out answers to more basic questions such as <i>'what is the most popular option on field A'</i>, or to
            some more advanced ones like <i>'what is the sum of all numbers submitted by users in field B grouped by option in field A'</i>,
            charts tab will be able to help you.
          </p>
          <TutorialImg name="results_charts" />
        </>
      )
    },
    {
      target: `.intro-results-tab-${exampleChartTabId}`,
      placement: 'bottom',
      onAfterChange: navigateToCharts,

      title: 'Charts tabs [2/2]',
      content: (
        <>
          <p>
            There are two types of charts tabs: <b>regular</b> and <b>automated</b> ones. When you don't have any charts tab yet, an
            automated one is added by default.
          </p>
          <p>Now we'll explain what is the difference between these two types of charts tabs.</p>
        </>
      )
    },
    {
      onAfterChange: navigateToCharts,

      title: 'Charts tabs - automated mode',
      content: (
        <>
          <p>
            Automated tabs were designed so that there is little to no manual tweaking required. As you add new fields to your form, new
            charts are created. It's as simple as that.
          </p>
          <p>
            At most, if you'll ever feel that some data is missing, you can try to look for some additional kinds of charts inside the{' '}
            <dfn>Manage automated charts</dfn> dropdown.
          </p>
          <TutorialImg name="results_automated_charts" />
        </>
      )
    },
    {
      onAfterChange: navigateToCharts,

      title: 'Charts tabs - regular mode',
      content: (
        <>
          <p>
            Regular charts tabs, as you probably guessed, work the other way around. You'll need to create every chart yourself, however
            you'll get a lot more flexibility in return.
          </p>
          <p>
            You'll be able to create complex charts that are <u>not supported in automated mode</u>, and rearrange them however you wish.
          </p>
          <TutorialImg name="results_creating_charts" />
        </>
      )
    }
  ];

  return [...PART_1_START, ...PART_2_TABLE, ...PART_3_CHARTS];
};

/* -------------------------------------------------------------------------- */
/*                                    HOOK                                    */
/* -------------------------------------------------------------------------- */

const useResultsIntroductionSteps = () => {
  const { replace: navigateInApp } = useRouteChanger();

  // Required data
  const { form } = useContext(BuilderContext);
  const formId = form?._id;
  const { submissionsWorkspaces } = useSubmissionsWorkspaces();

  // Looking for example tabs
  const { exampleTableTabId, exampleChartTabId } = useMemo(() => {
    const orderedSubmissionsWorkspaces = _orderBy(Array.isArray(submissionsWorkspaces) ? submissionsWorkspaces : [], 'position');
    return {
      exampleTableTabId: orderedSubmissionsWorkspaces.find((sw) => sw.type === 'table')?._id || null,
      exampleChartTabId: orderedSubmissionsWorkspaces.find((sw) => sw.type === 'chart')?._id || null
    };
  }, [submissionsWorkspaces]);

  // Steps
  return useMemo(
    () =>
      createResultsIntroductionSteps({
        formId,
        exampleTableTabId,
        exampleChartTabId,
        navigateInApp
      }),
    [formId, exampleTableTabId, exampleChartTabId, navigateInApp]
  );
};

export default useResultsIntroductionSteps;
