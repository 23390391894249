import styles from './WhoIsHere.module.css';

import React, { useCallback, useRef, useContext, useMemo } from 'react';
import { matchPath, useHistory } from 'react-router-dom';

import { Icon, Avatar, Tooltip } from 'ui-components';

import { MainContext } from 'contexts';
import { useActivity, parseActivityIntoStatus } from 'activity';

import config from 'config.js';

/* -------------------------------------------------------------------------- */
/*                              HELPER COMPONENTS                             */
/* -------------------------------------------------------------------------- */

/* ---------------------------------- SELF ---------------------------------- */

const SelfUser = React.memo(({ user, size, active }) => {
  const { setProfilePage } = useContext(MainContext);

  const handleClick = () => setProfilePage('profile');

  return (
    <Tooltip
      interactive
      hideOnClickInside
      delay={0}
      content={
        <div className={styles.activityStatus} onClick={handleClick}>
          <div className={styles.message}>
            (You) <br /> <strong>Click to open profile </strong>
          </div>
        </div>
      }
      placement="bottom">
      <Avatar
        className={[styles.avatar, !active ? styles.notActive : ''].join(' ')}
        size={size}
        user={user}
        active={active}
        onClick={handleClick}
      />
    </Tooltip>
  );
});

/* ------------------------------- OTHER USERS ------------------------------ */

const User = React.memo(({ user, size, active, status }) => {
  const history = useHistory();
  const historyRef = useRef(null);
  historyRef.current = history;

  const { location, activeElement, pathname = '/', search = '' } = status || {};

  const userDisplayName = user.getNameOrEmail();

  const statusJsx = useMemo(() => {
    if (!location) {
      return (
        <div className={styles.activityStatus}>
          <div className={[styles.message, config.fullStoryExcludeClass].join(' ')}>{userDisplayName}</div>
        </div>
      );
    }

    return (
      <div
        className={styles.activityStatus}
        onClick={() => {
          // Redirects to the location of the user
          historyRef.current.push(pathname + search);
        }}>
        <div className={[styles.message, config.fullStoryExcludeClass].join(' ')}>
          {userDisplayName}, <br />
          viewing <strong>{location}</strong>
          {activeElement ? (
            <>
              <br /> on <strong>{activeElement}</strong>
            </>
          ) : null}
        </div>
        <div className={styles.icon}>
          <Icon id="open-external-white" />
        </div>
      </div>
    );
  }, [location, activeElement, pathname, search, userDisplayName]);

  return useMemo(() => {
    return (
      <Tooltip interactive hideOnClickInside content={statusJsx} delay={0} placement="bottom">
        <Avatar className={[styles.avatar, !active ? styles.notActive : ''].join(' ')} size={size} user={user} active={active} />
      </Tooltip>
    );
  }, [statusJsx, active, user, size]);
});

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const WhoIsHere = React.memo(
  ({
    className = '',
    style,
    theme = 'default',

    // Affects status messages
    povPath,

    // USER FILTERING
    // Allow showing only specific users (by default all active)
    allowedUsers = null,

    // ACTIVE USER DETECTION - mode 1:
    // Manual mode - only these should be marked as active
    activeUsers = null,

    // ACTIVE USER DETECTION - mode 2:
    // Auto mode (activity is used to determine that) - users that are that currently are in a route that matches passed path will be marked as active
    path = null, // same format as in react-router
    exact = false,
    strict = false,

    // Determines if users not marked as active should be shown as grayed out
    showNonActiveUsers = false,

    // Placeholder when there is no user to show
    placeholder = null,

    // Styling
    size,
    max = 7 // max items (+1 count as item too)
  }) => {
    /* ------------------------------ FETCHED DATA ------------------------------ */

    const { user: localUser, users } = useContext(MainContext);
    const activities = useActivity();

    /* -------------------- FILTERING DATA AND PARSING STATUS ------------------- */

    const data = useMemo(() => {
      // Filtering users by id:
      const filteredUsers = allowedUsers
        ? users.filter((u) => {
            return allowedUsers.indexOf(u._id) !== -1;
          })
        : users;

      const data = [];
      for (const user of filteredUsers) {
        // Shouldn't happen
        if (!user) continue;

        const userActivity = activities.find((a) => a.user === user._id)?.activity;
        const status = parseActivityIntoStatus(userActivity, povPath);

        let isActive = false;

        // Filtering users by active route,
        // Status is required to be known, if it's not it means that it couldn't be parsed with passed data
        // (user may be in 404 state or in a place he couldn't access)
        if (status && path) {
          // User in not in a route that matched passed path
          const userIsNotInPath = !userActivity || !matchPath(userActivity?.pathname, { path, exact, strict });

          isActive = !userIsNotInPath;
        }
        // Manually forcing active state on some users
        else if (activeUsers) {
          isActive = activeUsers?.findIndex((userId) => userId === user._id) !== -1;
        }

        if (isActive || showNonActiveUsers) {
          data.push({
            active: isActive,
            user: user,
            status: status
          });
        }
      }

      return data;
    }, [users, activities, allowedUsers, activeUsers, showNonActiveUsers, povPath, path, exact, strict]);

    /* --------------- SORTING DATA BY USER STATUS AND LIMITING IT -------------- */

    // Smaller value = more important
    const determineDataImportance = useCallback(
      (data) => {
        const isLocal = data.user._id === localUser._id;
        const isActive = data.active;

        if (isLocal && isActive) return 0;
        if (isActive) return 1;
        if (isLocal) return 2;
        return 3;
      },
      [localUser._id]
    );

    // Sorts user in order: self, active, non active
    const sortedData = data.sort((d1, d2) => {
      return determineDataImportance(d1) - determineDataImportance(d2);
    });

    max = max - 1;
    const aboveMaxCount = Math.abs(max - sortedData.length);
    const visibleData = aboveMaxCount > 1 ? sortedData.slice(0, max) : sortedData;
    const hiddenUsersCount = sortedData.length - visibleData.length;

    /* ----------------------------------- JSX ---------------------------------- */

    return (
      <div className={[className, styles.whoIsHere, styles['theme-' + theme], styles['size-' + size]].join(' ')} style={style}>
        {visibleData.map(({ user, active, status }) => {
          if (user._id === localUser._id) {
            return <SelfUser key={user._id} user={user} size={size} active={active} />;
          } else {
            return <User key={user._id} user={user} size={size} active={active} status={status} />;
          }
        })}
        {!visibleData.length && <div className={styles.placeholder}>{placeholder}</div>}
        {hiddenUsersCount ? <div className={styles.hiddenUsersCount}>+ {hiddenUsersCount}</div> : null}
      </div>
    );
  }
);

export default WhoIsHere;
