import validateUrl from './validateUrl';

const validateUrlParam = (key, value) => {
  if (!key) return false;

  // To prevent passing multiple parameters hidden in one parameter
  if (/[&=\?]/g.test(key + value)) return false;

  // Validation
  return value ? validateUrl(`a.com?${key}=${value}`) : validateUrl(`a.com?${key}`);
};

export default validateUrlParam;
