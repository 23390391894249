import React from 'react';
import { useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { Container, Body, Header, Footer, Row, Cell, IndexCell } from './GridLayout';
// import {Container, Body, Header, Footer, Row, Cell} from './FlexLayout';

import { TableCellContextManager } from './TableCellContext';
import { TableContentContextManager, useTableContentContext } from './TableContentContext';

/* -------------------------------------------------------------------------- */
/*                                   CONFIG                                   */
/* -------------------------------------------------------------------------- */

// If there is no data show one empty row
const EMPTY_TABLE_DATA = [{}];

const DEFAULT_COLUMN = {
  minWidth: 150
};

/* -------------------------------------------------------------------------- */
/*                         REACT-TABLE CUSTOM PLUGINS                         */
/* -------------------------------------------------------------------------- */

/* ----------------------------- CELL MIN-WIDTHS ---------------------------- */

// Injects min-width as inline style in cells
const useInlineMinWidth = (hooks) => {
  /* 
    These two functions make sure that:
    - columns will be at least wide enough to fit the widest content
  */
  const useGetCellProps = (props, { instance, cell }) => {
    return [
      props,
      {
        style: { ...props.style, minWidth: 'fit-content' }
      }
    ];
  };

  const useGetHeaderProps = (props) => {
    return [
      props,
      {
        style: { ...props.style, minWidth: 'fit-content' }
      }
    ];
  };

  hooks.getCellProps.push(useGetCellProps);
  hooks.getHeaderProps.push(useGetHeaderProps);
  hooks.getFooterProps.push(useGetHeaderProps);
};

/* ------------------------ FOCUSABLE/CLICKABLE CELLS ----------------------- */

const useInteractiveCells = (hooks) => {
  // Injects additional props for focusable/clickable cells
  const useGetCellProps = (props, { instance, cell }) => {
    if (!cell.column.interactive) return props;

    return [props, { tabIndex: '0', 'data-interactive-td': true }];
  };

  hooks.getCellProps.push(useGetCellProps);
};

/* ------------------------- INDEX/ROW SELECT COLUMN ------------------------ */

const useRowSelectColumn = (hooks) => {
  const COLUMN_ID = '__index_col__';
  const isHookEnabled = (instance) => Boolean(instance.hasRowSelection);

  // Injects a column with checkboxes/indexes
  const useGetColumns = (columns, { instance }) => {
    if (!isHookEnabled(instance)) return columns;

    const indexColumn = {
      id: COLUMN_ID,
      sticky: 'left',
      minWidth: 'fit-content',

      isInjectedColumn: true,

      Header: () => {
        const { areAllRowsAreSelected, toggleAllRowsSelected } = useTableContentContext();

        return <IndexCell selected={areAllRowsAreSelected} onToggleSelected={toggleAllRowsSelected} />;
      },
      Cell: ({ row }) => {
        const { isRowSelected, toggleRowSelected } = useTableContentContext();
        const { _row_id, _index, _indexColumnContent, _indexColumnHoverContent } = row.original; // Treated as special row properties but could be anything

        if (typeof _row_id === 'undefined' || typeof _index === 'undefined') return null;

        return (
          <IndexCell
            label={_index}
            selected={isRowSelected(_row_id)}
            onToggleSelected={() => toggleRowSelected(_row_id)}
            content={_indexColumnContent}
            hoverContent={_indexColumnHoverContent}
          />
        );
      }
    };

    return [indexColumn, ...columns];
  };

  const useGetCellProps = (props, { instance, cell, column }) => {
    const cellColumn = column || cell.column;
    if (!isHookEnabled(instance) || cellColumn.id !== COLUMN_ID) return props;

    const columnIndex = instance.visibleColumns.findIndex(({ id }) => id === COLUMN_ID);
    if (instance.visibleColumns[columnIndex + 1]?.sticky) {
      delete props['data-sticky-last-left-td'];
    }

    return props;
  };

  hooks.visibleColumns.push(useGetColumns);
  hooks.getCellProps.push(useGetCellProps);
  hooks.getHeaderProps.push(useGetCellProps);
};

/* ------------------------- COLUMN CREATION COLUMN ------------------------- */

// Column creation was dropped for v2
/*
const useCreatorColumn = (hooks) => {
  const COLUMN_ID = '__creator_col__';
  const isHookEnabled = (instance) => Boolean(instance.hasColumnCreation);

  // Injects a column with add buttons
  const useGetColumns = (columns, { instance }) => {
    if (!isHookEnabled(instance)) return columns;

    const creatorColumn = {
      id: COLUMN_ID,
      minWidth: 'fit-content',
      isInjectedColumn: true,

      Header: (props) => {
        return <Button theme="icon" size="small" icon="plus" />;
      },
      Cell: ({ row }) => {
        return <Button theme="icon" size="small" icon="plus" />;
      }
    };

    return [...columns, creatorColumn];
  };

  const useGetCellProps = (props, { instance, column, cell }) => {
    const cellColumn = column || cell.column;
    if (!isHookEnabled(instance) || cellColumn.id !== COLUMN_ID) return props;

    const injectedProps = {};
    // const injectedProps = {style: { ...props.style, minWidth: 'fit-content' }};

    return [props, injectedProps];
  };

  hooks.visibleColumns.push(useGetColumns);
  hooks.getCellProps.push(useGetCellProps);
  hooks.getHeaderProps.push(useGetCellProps);
};
*/

/* -------------------- FIX FOR REACT-TABLE-STICKY PLUGIN ------------------- */

const useStickyReorderFix = (hooks) => {
  const getColumnOrderingValue = (column) => {
    if (column.sticky === 'left') return 0;
    else if (column.sticky === 'right') return 2;
    else return 1;
  };

  // Reorders columns so that left/right sticky columns can't be somewhere in the middle
  const useGetColumns = (columns) => {
    return columns.sort((col1, col2) => getColumnOrderingValue(col1) - getColumnOrderingValue(col2));
  };

  hooks.visibleColumns.push(useGetColumns);
};

/* ------------------------ CUSTOM GRID-LAYOUT PLUGIN ----------------------- */

// Smaller version of original plugin from here:
// https://github.com/tannerlinsley/react-table/blob/master/src/plugin-hooks/useGridLayout.js
// Doesn't support column resizing (could be added if needed) but causes way less issues in other areas
function useGridLayoutFixed(hooks) {
  const useGetTableProps = (props, { instance }) => {
    const visibleColumns = instance.visibleColumns;

    const style = {
      display: `grid`,
      gridTemplateColumns: visibleColumns.map(() => 'auto').join(' ')
    };

    return [props, { style }];
  };

  hooks.getTableProps.push(useGetTableProps);
}

/* -------------------------------------------------------------------------- */
/*                             RENDERER COMPONENT                             */
/* -------------------------------------------------------------------------- */

const TableRenderer = React.memo(
  ({
    className = '',
    loading,

    // Data
    columns,
    data,

    // Additional config
    showFooter = false,
    stickyHeader = true,
    stickyFooter = true,
    hasColumnCreation = false,
    hasRowSelection = true
  }) => {
    /* --------------------------- REACT-TABLE CONFIG --------------------------- */

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows, // row data for given page
      prepareRow,
      visibleColumns
    } = useTable(
      {
        columns,
        data: data?.length ? data : EMPTY_TABLE_DATA,

        defaultColumn: DEFAULT_COLUMN,

        autoResetSelectedRows: false,

        // Additional functionality
        hasRowSelection,
        hasColumnCreation
      },
      // Layout hooks
      useGridLayoutFixed,
      useSticky,
      // Custom hooks
      useInlineMinWidth,
      useStickyReorderFix,
      useRowSelectColumn,
      // useCreatorColumn,
      useInteractiveCells
    );

    /* ----------------------------------- JSX ---------------------------------- */

    return (
      <TableCellContextManager visibleColumns={visibleColumns}>
        <Container className={className} loading={loading} {...getTableProps()}>
          {/* Additional non-sticky invisible row that is used to measure left offset of sticky columns,
        sticky headers can't be used as once their offset is hardcoded via inline style, measuring changes is not possible.
        This row is also used to provide a minWidth from column settings to grid layout.
        */}
          <Row>
            {visibleColumns.map((column, i) => {
              const injectedProps = column.getHeaderProps();
              return (
                <Cell
                  key={'__op' + i}
                  columnIndex={i}
                  columnId={column.id}
                  {...injectedProps}
                  style={{ ...injectedProps.style, minWidth: column.minWidth, padding: 0, border: 'none', visibility: 'hidden' }}
                  role="offset-provider"
                />
              );
            })}
          </Row>

          <Header sticky={stickyHeader}>
            {headerGroups.map((headerGroup) => (
              <Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <Cell columnIndex={i} columnId={column.id} {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </Cell>
                ))}
              </Row>
            ))}
          </Header>

          <Body {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <Row {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <Cell columnIndex={i} columnId={cell.column.id} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Cell>
                    );
                  })}
                </Row>
              );
            })}
          </Body>

          {showFooter && (
            <Footer sticky={stickyFooter}>
              {footerGroups.map((footerGroup) => (
                <Row {...footerGroup.getFooterGroupProps()}>
                  {footerGroup.headers.map((column, i) => (
                    <Cell columnIndex={i} columnId={column.id} {...column.getFooterProps()}>
                      {column.render('Footer')}
                    </Cell>
                  ))}
                </Row>
              ))}
            </Footer>
          )}
        </Container>
      </TableCellContextManager>
    );
  }
);

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const TableV2 = React.memo(
  ({
    selectedRowsIds,
    allRowsIds,
    onSelectedRowsChange,

    ...rest
  }) => {
    return (
      <TableContentContextManager selectedRowsIds={selectedRowsIds} allRowsIds={allRowsIds} onSelectedRowsChange={onSelectedRowsChange}>
        <TableRenderer {...rest} />
      </TableContentContextManager>
    );
  }
);

export default TableV2;
