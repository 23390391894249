import styles from './ContentPreview.module.css';

import React, { useMemo } from 'react';

// Rendering components
import FilesUploadPreview from './Components/FilesUploadPreview';
import PaymentPreview from './Components/PaymentPreview';
import LabeledListPreview from './Components/LabeledListPreview';
import ListPreview from './Components/ListPreview';
import TitlePreview from './Components/TitlePreview';
import BooleanPreview from './Components/BooleanPreview';
import DurationPreview from './Components/DurationPreview';
import UserAgentPreview from './Components/UserAgentPreview';
import EmptyPreview from './Components/EmptyPreview';
import TimestampPreview from './Components/TimestampPreview';
import TextPreview from './Components/TextPreview';
import ImagesPreview from './Components/ImagesPreview';
import MatrixListPreview from './Components/MatrixListPreview';
import UrlPreview from './Components/UrlPreview';

// Randers appropriate preview based on type/columnId/format/value
const ContentPreview = React.memo(
  ({ className, theme, type, value, valueMetadata, additionalValue, onDoubleClick, ...otherContentProps }) => {
    /* ------------------------------- VALIDATION ------------------------------- */

    if (process.env.NODE_ENV === 'development') {
      if (!['for-table', 'for-rows'].includes(theme)) {
        throw new Error('DEBUG: provided theme is not supported.');
      }
    }

    /* ----------------------------- EVENT HANDLERS ----------------------------- */

    /* ----------------------------------- JSX ---------------------------------- */

    const contentJsx = useMemo(() => {
      // Rendering with appropriate component
      const injectedProps = {
        theme,
        value,
        valueMetadata,
        additionalValue,
        ...otherContentProps
      };

      switch (type) {
        // Utility data types
        case 'n/a':
          return <EmptyPreview placeholder="N/A" {...injectedProps} />;
        case 'text':
          return <TextPreview {...injectedProps} />;
        case 'url':
          return <UrlPreview {...injectedProps} />;
        case 'timestamp':
          return <TimestampPreview {...injectedProps} />;
        case 'duration':
          return <DurationPreview {...injectedProps} />;
        case 'boolean':
          return <BooleanPreview {...injectedProps} />;
        case 'list':
          return <ListPreview {...injectedProps} />;
        case 'matrixList':
          return <MatrixListPreview {...injectedProps} />;
        case 'labeledList':
          return <LabeledListPreview {...injectedProps} />;
        case 'userAgent':
          return <UserAgentPreview {...injectedProps} />;
        case 'image':
          return <ImagesPreview {...injectedProps} />;

        // Some more unique data types:
        case 'title':
          return <TitlePreview {...injectedProps} />;
        case 'payment':
          return <PaymentPreview {...injectedProps} />;
        case 'fileUpload':
          return <FilesUploadPreview {...injectedProps} />;

        default: {
          throw new Error(`DEBUG: unexpected content type: ${JSON.stringify({ type, value, valueMetadata }, 0, 2)}`);
        }
      }
    }, [theme, type, value, valueMetadata, additionalValue, otherContentProps]);

    return (
      <div className={[className, styles.contentPreview, styles[`theme-${theme}`]].join(' ')} onDoubleClick={onDoubleClick}>
        {contentJsx}
      </div>
    );
  }
);

export default ContentPreview;
