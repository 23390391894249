import styles from './Expandible.module.css';

import React, { useState } from 'react';

// Used to render lists with "show more" button
const Expandible = ({ className = '', data = [], limit = 2, allowCollapsing = false, renderData }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Elements wont be collapsed if limit is exceeded only by one item
  const fitsAll = data.length - limit <= 1;

  const visibleData = isExpanded || fitsAll ? data : data.slice(0, limit);
  const howManyCollapse = data.length - limit;

  const isButtonVisible = !fitsAll && !(isExpanded && !allowCollapsing);

  return (
    <div className={[className, styles.expandible].join(' ')}>
      {renderData(visibleData)}
      {isButtonVisible && (
        <div className={styles.button} onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? `Hide ${howManyCollapse}` : `Show ${howManyCollapse} more`}
        </div>
      )}
    </div>
  );
};

export default Expandible;
