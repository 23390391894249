import styles from './Button.module.css';

import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import useLongPress from 'helpers/useLongPress.js';

import { Spinner } from '../Spinner/Spinner';
import Tooltip from '../Tooltip/Tooltip.js';

const DEFAULT_LONG_PRESS_DELAY = 1500;
const DEFAULT_COOLDOWN = 1000;

// Used in /ui page
export const BUTTON_THEMES = [
  // Filled
  'blue',
  'black',
  'orange',
  // Outlined
  'black-outlined',
  'red',
  'white',
  // Clean
  'black-underline',
  'inherit', // can be used to use Button component only as a logic provider
  'transparent',
  'transparent-blue',
  'transparent-purple',
  'transparent-orange'
];
export const BUTTON_SIZES = [
  'small', // 12 px
  'default', // 13 px
  'default-thin', // 13px but less height
  'medium', // 14 px
  'large' // 16 px
];

const isPromise = (p) => {
  if (typeof p === 'object' && typeof p.then === 'function') {
    return true;
  }
  return false;
};

export const Button = React.forwardRef(
  (
    {
      children,
      delay,
      autoFocus,
      cooldown,
      tooltip,
      tooltipPlacement = 'top',
      tooltipzIndex,
      onClick,
      disabled,
      active,
      noPadding,
      loading,
      theme = '',
      fullWidth,
      icon,
      iconPlacement = 'left',
      width,
      className,
      size = 'default',
      type,
      link,
      href,
      target,
      rel,
      style,
      longPressHint: hint
    },
    ref
  ) => {
    if (delay === true) {
      delay = DEFAULT_LONG_PRESS_DELAY;
    }
    if (cooldown === true) {
      cooldown = DEFAULT_COOLDOWN;
    }

    /* ---------------------------- COOLDOWN SUPPORT ---------------------------- */

    const [isOnCooldown, setIsOnCooldown] = useState(false);
    const isOnCooldownRef = useRef(false);

    const [isWaitingForPromise, setIsWaitingForPromise] = useState(false);
    const isWaitingForPromiseRef = useRef(false);

    const handleOnClick = useCallback(
      (...args) => {
        const processClick = async () => {
          if (disabled) return;
          if (isOnCooldownRef.current) return;
          if (isWaitingForPromiseRef.current) return;

          if (onClick) {
            const resultOrPromise = onClick(...args);

            if (isPromise(resultOrPromise)) {
              setIsWaitingForPromise(true);
              isWaitingForPromiseRef.current = true;

              await resultOrPromise;

              setIsWaitingForPromise(false);
              isWaitingForPromiseRef.current = false;
            }
          }

          if (cooldown) {
            setIsOnCooldown(true);
            isOnCooldownRef.current = true;

            setTimeout(() => {
              setIsOnCooldown(false);
              isOnCooldownRef.current = false;
            }, cooldown);
          }
        };

        processClick();
      },
      [disabled, cooldown, onClick]
    );

    loading = loading || isOnCooldown || isWaitingForPromise;

    /* --------------------------- LONG PRESS SUPPORT --------------------------- */

    const { eventHandlers, tooltipAttrs, indicator } = useLongPress({
      onClick: handleOnClick,
      icon,
      delay,
      tooltip,
      hint
    });

    /* ------------------------------- ATTRIBUTES ------------------------------- */

    // Common attributes - they don't change often
    const commonAttributes = useMemo(() => {
      // Conditional styling
      const classList = [styles.button, styles[theme], className, theme !== 'inherit' ? styles[`size-${size}`] : ''];

      const isVisuallyDisabled = disabled || loading;

      if (noPadding || (noPadding !== false && theme.indexOf('transparent') === 0)) {
        classList.push(styles.noPadding);
      }
      if (fullWidth) {
        classList.push(styles.fullWidth);
      }
      if (!children) {
        classList.push(styles.onlyIcon);
      }
      if (isVisuallyDisabled) {
        classList.push(styles.disabled);
      }
      if (active) {
        classList.push(styles.active);
      }
      if (loading) {
        classList.push(styles.loading);
      }

      return {
        // Event handler won't process clicks but button won't have a disabled prop
        // because in some cases dealing with it can be annoying (for example when
        // there is a tooltip/popover. If button is disabled or temporarily disabled,
        // event handlers required for these elements won't work)
        disabled: false,
        // Will mark as disabled this way though
        'aria-disabled': isVisuallyDisabled,

        autoFocus,
        className: classList.join(' '),
        style: { width, ...style },
        'data-component': 'button',
        ...eventHandlers
      };
    }, [className, autoFocus, loading, theme, size, style, width, fullWidth, children, disabled, active, noPadding, eventHandlers]);

    /* ----------------------------------- JSX ---------------------------------- */

    const iconJsx = (delay || icon) && <div className={styles.icon}>{indicator}</div>;
    const contentJsx = (
      <>
        {loading && (
          <div className={styles.loader}>
            <Spinner />
          </div>
        )}
        {iconPlacement === 'left' && iconJsx}
        {children && <span>{children}</span>}
        {iconPlacement === 'right' && iconJsx}
      </>
    );

    return (
      <>
        {!link && !href && (
          <Tooltip {...tooltipAttrs} placement={tooltipPlacement} zIndex={tooltipzIndex}>
            <button {...commonAttributes} type={type || 'button'} ref={ref}>
              {contentJsx}
            </button>
          </Tooltip>
        )}

        {link && (
          <Tooltip {...tooltipAttrs} placement={tooltipPlacement} zIndex={tooltipzIndex}>
            <Link {...commonAttributes} to={link} ref={ref}>
              {contentJsx}
            </Link>
          </Tooltip>
        )}

        {href && (
          <Tooltip {...tooltipAttrs} zIndex={tooltipzIndex}>
            <a {...commonAttributes} href={href} target={target} rel={rel} ref={ref}>
              {contentJsx}
            </a>
          </Tooltip>
        )}
      </>
    );
  }
);
