import styles from './Badge.module.css';

import React from 'react';

import { Icon } from '../Icon/Icon';

import {} from 'helpers';

const Badge = ({ className = '', icon = null, text = null, theme = 'black', children = null }) => {
  return (
    <div className={[className, styles.badgeWrapper].join(' ')}>
      <div className={[styles.badge, text ? styles.textBadge : '', text ? styles[`theme-${theme}`] : ''].join(' ')}>
        {typeof icon === 'string' ? <Icon id={icon} /> : icon}
        {text && <span>{text}</span>}
      </div>
      {children}
    </div>
  );
};

export default Badge;
