import validateUrlParam from './validateUrlParam';

// Payments error format: { [_id]: '', ... }
const validateFormUrlParams = (urlParams) => {
  const errors = {};

  if (urlParams) {
    const keys = urlParams.map((obj) => obj.key);
    const duplicatedKeys = new Set(keys.filter((v1, i) => keys.findIndex((v2, j) => v1 === v2 && i !== j) !== -1));

    for (const i in urlParams) {
      const error = {};
      const { _id, key, value } = urlParams[i];

      if (!key) error.key = 'Url parameter key is required';
      else if (duplicatedKeys.has(key)) error.key = 'This url parameter key is used multiple times';
      else if (!validateUrlParam(key)) error.key = 'This url parameter key is invalid';
      if (!validateUrlParam('key', value)) error.value = 'This url parameter value is invalid';

      if (Object.keys(error).length) errors[_id] = error;
    }
  }

  return Object.keys(errors).length ? errors : false;
};

export default validateFormUrlParams;
