import styles from './UpdateLifeTimePlan.module.css';

import React, { useState, useEffect, useContext, useMemo } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import { Panel, Button, Hr, Select, Header, FormRow } from 'ui-components';
import asyncSocketAckEmit from 'helpers/asyncSocketAckEmit.js';
import io from 'io.js';
import * as Sentry from '@sentry/browser';
import ObjectID from 'bson-objectid';

import { V1_PLAN_PRICES, V2_PLAN_PRICES, V2_COLLABORATORS_OPTIONS, BUSINESS_PRICE_OPTIONS } from 'components/Ltdf/config.js';

import { MainContext } from 'contexts/main.js';

const PRICES = {
  1: V1_PLAN_PRICES,
  2: V2_PLAN_PRICES
};

const parsePrice = (num) => {
  if (num === 0) return '0';

  num = ((num + Number.EPSILON) * 100) / 100;

  const dec = Number(num).toFixed(2).split('.');

  if (dec.length === 1) return `${num}.00`;
  if (dec[1].length === 1) return `${num}0`;

  return Number(num).toFixed(2);
};

const round = (value) => Math.round((value + Number.EPSILON) * 100) / 100;

const UpdateLifeTimePlan = ({ onClose }) => {
  const { user } = useContext(MainContext);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [vatRate, setVatRate] = useState(0);
  const [showPayment, setShowPayment] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [extraCollaborators, setExtraCollaborators] = useState(0);
  const [orderId, setOrderId] = useState(null);

  const cancel = () => {
    if (loading) return;

    if (showPayment) {
      setShowPayment(false);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    io.socket.emit('getUserLifeTimeTax', {}, (payload) => {
      if (!Number.isNaN(payload.rate)) setVatRate(payload.rate);
    });
  }, [showPayment]);

  useEffect(() => {
    setExtraCollaborators(user.lifeTime.users);
  }, [user.lifeTime.users]);

  const agencyPrice = useMemo(() => {
    const currentPrice = PRICES[user.lifeTime.version][user.lifeTime.plan];
    const upgradePrice = PRICES[2].agency;

    return round(upgradePrice - currentPrice);
  }, [user.lifeTime.plan, user.lifeTime.version]);

  const alreadyPaidForExtraUsers = useMemo(() => {
    let alreadyPaid = 0;

    if (user.lifeTime.plan !== 'business') return alreadyPaid;

    if (user.lifeTime.version === 2) {
      alreadyPaid = V2_COLLABORATORS_OPTIONS.find((option) => option.value === user.lifeTime.users).price;
    } else {
      alreadyPaid = 20 * user.lifeTime.users;
    }

    return alreadyPaid;
  }, [user.lifeTime.plan, user.lifeTime.users, user.lifeTime.version]);

  const collabolatorOptions = useMemo(() => {
    let options = [];

    if (user.lifeTime.plan !== 'business') {
      options.push({
        label: '10 collaborators',
        value: 0,
        price: 0
      });
    }

    for (let option of V2_COLLABORATORS_OPTIONS) {
      if (option.value > user.lifeTime.users) {
        options.push({
          value: option.value,
          label: `${option.value + 10} collaborators (${user.lifeTime.plan !== 'business' ? '+' : ''}$${
            option.price - alreadyPaidForExtraUsers
          })`
        });
      }
    }

    return options;
  }, [alreadyPaidForExtraUsers, user.lifeTime.plan, user.lifeTime.users]);

  const businessPrice = useMemo(() => {
    let result = 0;

    if (user.lifeTime.plan !== 'business') {
      const currentPrice = PRICES[user.lifeTime.version][user.lifeTime.plan];
      const upgradePrice = PRICES[2].business;

      result = round(upgradePrice - currentPrice);
    }

    const additionalUsersPrice = round((BUSINESS_PRICE_OPTIONS[extraCollaborators] || 0) - alreadyPaidForExtraUsers);

    return round(result + additionalUsersPrice);
  }, [alreadyPaidForExtraUsers, extraCollaborators, user.lifeTime.plan, user.lifeTime.version]);

  const prices = useMemo(() => {
    let result = {};

    if (!selectedPlan) return result;

    result.net = round(selectedPlan === 'agency' ? agencyPrice : businessPrice);
    result.gross = round(result.net * ((vatRate || 0) / 100 + 1));
    result.vat = round(result.gross - result.net);

    return result;
  }, [agencyPrice, businessPrice, selectedPlan, vatRate]);

  const createOrder = async (data, actions) => {
    setError(null);

    if (!prices.gross) return;

    const orderId = String(ObjectID());
    const rand = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(0, 5);

    setOrderId(orderId);

    try {
      const orderID = await actions.order.create({
        purchase_units: [
          {
            custom_id: user._id,
            invoice_id: `${selectedPlan}|${extraCollaborators}|${vatRate}|0|${orderId}|${rand}`,
            amount: {
              value: parsePrice(prices.gross),
              currency_code: 'USD',
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: parsePrice(prices.net)
                },
                tax_total: {
                  currency_code: 'USD',
                  value: parsePrice(prices.vat)
                }
              }
            }
          }
        ],
        application_context: {
          brand_namestring: 'QuestionScout',
          shipping_preference: 'NO_SHIPPING'
        }
      });

      return orderID;
    } catch (e) {
      console.log(e);
    }
  };

  const onApprove = async (data, actions) => {
    setLoading(true);

    return actions.order.capture().then(async (details) => {
      return Promise.resolve();
    });
  };

  const onSuccess = async (details, data) => {
    const vatAmount =
      details.purchase_units[0].amount &&
      details.purchase_units[0].amount.breakdown &&
      details.purchase_units[0].amount.breakdown.tax_total &&
      details.purchase_units[0].amount.breakdown.tax_total.value
        ? details.purchase_units[0].amount.breakdown.tax_total.value
        : null;

    Sentry.captureException({
      _id: orderId,
      user: user._id,
      plan: selectedPlan,
      vatRate,
      collaborators: extraCollaborators,
      order: {
        id: data.orderID,
        amount: details.purchase_units[0].amount.value,
        vatAmount: vatAmount, // how much $ tax is in total amount
        status: details.status,
        createdAt: new Date(details.create_time)
      }
    });

    await asyncSocketAckEmit('updateUserLifeTimePlan', {
      _id: orderId,
      plan: selectedPlan,
      vatRate,
      collaborators: extraCollaborators,
      order: {
        id: data.orderID,
        amount: details.purchase_units[0].amount.value,
        vatAmount: vatAmount, // how much $ tax is in total amount
        status: details.status,
        createdAt: new Date(details.create_time)
      }
    });

    setLoading(false);
    setSuccess(true);

    // setTimeout(() => window.location.replace('/?modal=profile&profileTab=ltd'), 1000);
  };

  const onError = (err) => {
    console.log(err);
    setError(err.toString());
    Sentry.captureException(err.toString());
  };

  const vatJsx = (
    <>
      {vatRate > 0 && (
        <>
          {' '}
          (<span className={styles.smallPrice}>{vatRate}%</span> VAT included)
        </>
      )}
    </>
  );

  // disable LTD
  window.location.replace('/');
  return <></>;

  return (
    <Panel
      mainStyle={{
        height: '90vh'
      }}
      coverStyle={{
        width: '80vw',
        height: '90vh',
        maxWidth: '950px',
        minWidth: '850px',
        left: '-200px'
      }}
      className={styles.main}
      title="Upgrade your LTDF deal"
      show={true}
      loading={loading}
      success={success}
      buttons={[
        <Button theme="white" onClick={cancel}>
          {showPayment ? 'Back' : 'Cancel'}
        </Button>
      ]}
      onOutsideClick={cancel}>
      <div className={styles.content}>
        {!showPayment && !success && (
          <>
            <div className={styles.description}>
              You are currently on a {user.lifeTime.plan} plan
              {user.lifeTime.plan === 'business' ? ` with ${user.lifeTime.users + 10} collaborators` : ''}.
            </div>

            {user.lifeTime.plan === 'solo' && (
              <>
                <Header style={{ margin: '20px 0 5px 0' }}>Agency</Header>
                <div className={styles.description}>Upgrade to Agency for ${agencyPrice}</div>
                <div className={styles.actions}>
                  <Button
                    theme="black"
                    onClick={() => {
                      setSelectedPlan('agency');
                      setShowPayment(true);
                    }}>
                    Upgrade to Agency
                  </Button>
                </div>
              </>
            )}

            {(user.lifeTime.plan === 'solo' || user.lifeTime.plan === 'agency') && (
              <>
                <Header style={{ margin: '30px 0 2px 0' }}>Business</Header>
                <div className={styles.description}>Upgrade to Business for ${businessPrice}</div>

                <div className={styles.actions}>
                  <Select
                    options={collabolatorOptions}
                    value={extraCollaborators}
                    onChange={(selected) => setExtraCollaborators(selected.value)}
                    style={{ margin: '0 10px 0 0' }}
                  />
                  <Button
                    theme="black"
                    onClick={() => {
                      setSelectedPlan('business');
                      setShowPayment(true);
                    }}>
                    Upgrade to Business
                  </Button>
                </div>
              </>
            )}

            {user.lifeTime.plan === 'business' && user.lifeTime.users < 90 && (
              <>
                <Header style={{ margin: '30px 0 2px 0' }}>Business</Header>
                <div className={styles.description}>
                  Upgrade your Business plan with more collaborators{businessPrice > 0 && ` for $${businessPrice}`}.
                </div>

                <div className={styles.actions}>
                  <Select
                    options={collabolatorOptions}
                    value={extraCollaborators}
                    onChange={(selected) => setExtraCollaborators(selected.value)}
                    style={{ margin: '0 10px 0 0' }}
                  />
                  <Button
                    theme="black"
                    disabled={businessPrice === 0}
                    onClick={() => {
                      setSelectedPlan('business');
                      setShowPayment(true);
                    }}>
                    Upgrade
                  </Button>
                </div>
              </>
            )}
          </>
        )}

        {showPayment && selectedPlan && !success && (
          <>
            <div className={styles.description}>
              To upgrade your lifetime deal, you’re required to pay <span className={styles.price}>${parsePrice(prices.gross)}</span>
              {vatJsx}. Click one of the payment buttons below to continue.
            </div>

            <Hr />

            {error && <div className={styles.error}>{error}</div>}

            <div
              style={{
                opacity: loading ? 0.5 : 1
              }}>
              <PayPalButton
                amount={prices.gross}
                currency="USD"
                shippingPreference="NO_SHIPPING"
                onApprove={onApprove}
                onSuccess={onSuccess}
                createOrder={createOrder}
                onError={onError}
                onCancel={() => setLoading(false)}
                className={styles.paypal}
                options={{
                  // clientId: 'sb',
                  clientId: 'ATCZZqDSpBq_I_YaizB9dV-IcE99X8lopKAziRKECtDMLgkdRrzUHQmwS8j3fRbiBN3roMXuXdaV5kFj',
                  currency: 'USD'
                }}
              />
            </div>
          </>
        )}
      </div>
    </Panel>
  );
};

export default UpdateLifeTimePlan;
