import React from 'react';

import { calculateFieldsNumbering } from './calculateFieldsUtilityProperties';
import { getFieldIcon } from 'config.js';
import getFieldLabel from './getFieldLabel';

const MENTIONABLE_FIELDS = [
  'longText',
  'shortText',
  'scale',
  'radio',
  'checkbox',
  'dropdown'
  // 'datetime'
];

const getMentionsData = ({ fields, calculationVariables, urlParams }) => {
  const mentionsData = [];

  if (fields?.length) {
    const fieldsNumbering = calculateFieldsNumbering(fields);

    const fieldsMentionsData = fields
      .filter((f) => MENTIONABLE_FIELDS.indexOf(f.type) !== -1)
      .map((f) => ({
        _id: f._id,
        type: f.type,
        format: f.format,
        icon: getFieldIcon(f.type, f.format),
        index: fieldsNumbering[f._id],
        label: f.label,
        parsedLabel: getFieldLabel(f.label) || <span style={{ opacity: 0.5 }}>Unlabeled field</span>
      }))
      .sort((a, b) => {
        const aI = a.index;
        const bI = b.index;
        return aI && bI ? aI.localeCompare(bI) : 0;
      });

    mentionsData.push(...fieldsMentionsData);
  }

  if (calculationVariables?.length) {
    const calculationVariablesMentionsData = calculationVariables.map((variable) => ({
      _id: variable._id,
      type: 'variable',
      format: null,
      icon: <img src={require('assets/images/icons/variable.svg')} alt="" />,
      label: variable.name,
      parsedLabel: String(variable.name) || <span style={{ opacity: 0.5 }}>Untitled variable</span>
    }));

    mentionsData.push(...calculationVariablesMentionsData);
  }

  if (urlParams?.length) {
    const urlParamsMentionsData = urlParams.map((param) => ({
      _id: param._id,
      type: 'urlParam',
      format: null,
      icon: <img src={require('assets/images/urlParam.svg')} alt="" />,
      label: param.key,
      parsedLabel: String(param.key) || <span style={{ opacity: 0.5 }}>Untitled url parameter</span>
    }));

    mentionsData.push(...urlParamsMentionsData);
  }

  return mentionsData;
};

export default getMentionsData;
