import io from 'io.js';
import ObjectID from 'bson-objectid';

const ioRequestPayload = (event, params = {}) => {
  return new Promise((resolve) => {
    console.log(`REQUEST: ${event}`, params);

    const requestId = ObjectID().toHexString();

    const handleResponse = (payload) => {
      // For get methods that don't support requestId yet
      if (!payload?.requestId) {
        io.socket.off(event, handleResponse);
        resolve(payload);
      }

      if (payload?.requestId === requestId) {
        io.socket.off(event, handleResponse);
        delete payload.requestId;
        resolve(payload);
      }
    };

    io.socket.on(event, handleResponse);
    io.socket.emit(event, { requestId, ...params });
  });
};

export default ioRequestPayload;
