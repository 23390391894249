// Names of Google Fonts that will be selectable in Themes/Typography tab

const GOOGLE_FONTS = [
  'Work Sans',
  'IBM Plex Sans',
  'Space Mono',
  'Libre Franklin',
  'Rubik',
  'Lobster',
  'Cormorant',
  'Fira Sans',
  'Eczar',
  'Alegreya Sans',
  'Alegreya',
  'Chivo',
  'Lato',
  'Source Sans Pro',
  'Source Serif Pro',
  'Roboto',
  'Roboto Slab',
  'Roboto Condensed',
  'BioRhyme',
  'Poppins',
  'Archivo',
  'Libre Baskerville',
  'Playfair Display',
  'Karla',
  'Montserrat',
  'Proza Libre',
  'Spectral',
  'Domine',
  'Inknut Antiqua',
  'PT Sans',
  'PT Serif',
  'Neuton',
  'Open Sans',
  'Inconsolata',
  'Cabin',
  'Raleway',
  'Anonymous Pro',
  'Arvo',
  'Merriweather',
  'Fauna One',
  'Quattrocento',
  'Fanwood Text',
  'Prata',
  'Alfa Slab',
  'Gentium Book Basic',
  'Nixie One',
  'Julius Sans One',
  'Crimson Text',
  'Oswald',
  'Old Standard TT',
  'Questrial',
  'Slabo',
  'Sintony',
  'Dancing Script',
  'Muli',
  'Rufina',
  'Oxygen',
  'Lora',
  'Asap',
  'Teko',
  'Anton',
  'Alata',
  'Lexend Deca',
  'Markazi Text',
  'Pridi',
  'Rozha One',
  'Bree Serif',
  'Arbutus Slab',
  'Hammersmith One',
  'Alef',
  'Prompt',
  'Inter'
];

export default GOOGLE_FONTS;
