import { useMemo, useContext } from 'react';

import TutorialsContext from '../tutorialsContext';
import { MainContext } from 'contexts';

import { useUserExampleItems } from 'api';

const useAvailableTutorials = () => {
  const { user } = useContext(MainContext);
  const { playedTutorials } = useContext(TutorialsContext);
  const { data: exampleItems } = useUserExampleItems();

  // Ids of elements that current user can access
  const exampleWorkspaceId = exampleItems.workspace?._id;
  const exampleFormWithBuilderPageId = exampleItems.formWithBuilderPage?._id;
  const exampleFormWithResponsesPageId = exampleItems.formWithResponsesPage?._id;
  const exampleCustomThemeId = exampleItems.customTheme?._id;

  /* --------------------------------- CONFIG --------------------------------- */

  return useMemo(() => {
    const availableTutorialsWithoutBackendData = [
      {
        key: 'dashboard-introduction',
        title: 'Dashboard walkthrough',
        description: 'Starting off with the basics',
        route: `/workspace/${exampleWorkspaceId}`,
        blocker: !exampleWorkspaceId ? 'This tutorial requires a workspace that you could access.' : false
      },
      {
        key: 'builder-introduction',
        title: 'An introduction to the form builder',
        description: 'A crash course on how to get started building forms',
        route: `/form/${exampleFormWithBuilderPageId}/build`,
        blocker: !exampleFormWithBuilderPageId ? 'This tutorial requires a form that you could open in an edit mode.' : false
      },

      {
        key: 'results-introduction',
        title: 'Responses, segmentation & visualization',
        description: 'Learn more on how to effectively read your responses',
        route: `/form/${exampleFormWithResponsesPageId}/results`,
        blocker: !exampleFormWithResponsesPageId ? 'This tutorial requires a form that you could open in a read mode.' : false
      },
      // Guests can't edit themes
      user?.role !== 'guest' && {
        key: 'themes-introduction',
        title: 'Style your forms & surveys',
        description: 'Get your forms on-brand in minutes',
        route: exampleCustomThemeId ? `/themes/custom/${exampleCustomThemeId}` : `/themes/stock/light`
      }
    ];

    const availableTutorials = availableTutorialsWithoutBackendData.filter(Boolean).map((tutorial) => {
      const tutorialStatus = playedTutorials.find((ts) => ts.key === tutorial.key);
      return {
        ...tutorial,
        completionRatio: Number(tutorialStatus?.completionRatio) || 0
      };
    });

    return availableTutorials;
  }, [user?.role, playedTutorials, exampleWorkspaceId, exampleFormWithBuilderPageId, exampleFormWithResponsesPageId, exampleCustomThemeId]);
};

export default useAvailableTutorials;
