import { ioEmitValidatedRequest } from '../../helpers';

const getUserExampleItems = {
  keyFn: () => ['getUserExampleItems'],
  fn: async () => await ioEmitValidatedRequest('getUserExampleItems')
};

const getUserLatestSubscriptionInvoice = {
  keyFn: () => ['getUserLatestSubscriptionInvoice'],
  fn: async () => await ioEmitValidatedRequest('getUserLatestSubscriptionInvoice')
};

const getUserLogs = {
  keyFn: ({
    page,
    pageSize,
    types = null,
    startDate = null,
    endDate = null,
    smtp = null,
    form = null,
    user = null,
    markLogsAsViewed = false
  }) => ['getUserLogs', { page, pageSize, types, startDate, endDate, smtp, form, user, markLogsAsViewed }],
  fn: async ({ queryKey }) => {
    const [, params] = queryKey;
    return await ioEmitValidatedRequest('getUserLogs', params);
  }
};

export default {
  getUserLogs,
  getUserExampleItems,
  getUserLatestSubscriptionInvoice
};
