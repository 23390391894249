import React, { useRef, useEffect, useCallback } from 'react';
import _debounce from 'lodash/debounce';

import { Icon } from '../Icon/Icon.js';
import Tooltip from '../Tooltip/Tooltip.js';

import styles from './Tabs.module.css';

const valueToKey = (value) => {
  if (value && typeof value === 'object') {
    try {
      return JSON.stringify(value);
    } catch (e) {}
  }

  return String(value);
};

const Tabs = ({ className = '', value: currentValue = null, disabled: globalDisabled, theme = 'underline', onChange, data }) => {
  const activeItemRef = useRef(null);
  const indicatorRef = useRef(null);

  const updateIndicator = useCallback(() => {
    // Setting indicator size based on currentRef dom width/posX
    const activeItem = activeItemRef.current;
    const indicator = indicatorRef.current;

    if (!indicator) return;

    if (!activeItem) {
      indicator.style.width = 0;
      return;
    }

    // Animation starts after first drawing
    if (indicator.style.width) {
      indicator.style.transition = 'transform 0.2s ease-in-out';
    }
    indicator.style.width = '100px';
    indicator.style.transform = `translateX(${activeItem.offsetLeft}px) scaleX(${activeItem.offsetWidth / 100})`;
  }, []);

  /* Indicator movement on tab change */
  useEffect(() => {
    updateIndicator();
  }, [currentValue, data, updateIndicator]);

  /* Indicator fix on window resize */
  useEffect(() => {
    const debouncedUpdateIndicator = _debounce(updateIndicator, 200);
    window.addEventListener('resize', debouncedUpdateIndicator);
    return () => {
      window.removeEventListener('resize', debouncedUpdateIndicator);
    };
  }, [updateIndicator]);

  return (
    <div className={[styles.container, globalDisabled ? styles.disabled : '', styles['theme-' + theme], className].join(' ')}>
      {data.map(({ className, label, value, icon, error, disabled, disabledMessage, tooltip }) => {
        let infoIcon = null;
        let tooltipProps = null;
        if (disabled && disabledMessage) {
          infoIcon = <Icon id="help" />;
          tooltipProps = {
            content: disabledMessage,
            trigger: 'click',
            delay: [0, 0]
          };
        } else if (error && error !== true) {
          infoIcon = <Icon id="warning" />;
          tooltipProps = {
            content: error,
            trigger: 'mouseenter'
          };
        } else if (tooltip) {
          infoIcon = <Icon id="help" />;
          tooltipProps = {
            content: tooltip,
            trigger: 'mouseenter'
          };
        }

        // Labels in tab selector
        return (
          <Tooltip key={valueToKey(value)} disabled={!tooltipProps} {...tooltipProps}>
            <span className={className}>
              <button
                ref={activeItemRef}
                disabled={globalDisabled || disabled}
                onClick={() => onChange(value)}
                // Different props when label is active
                {...(value === currentValue
                  ? {
                      // When active
                      ref: activeItemRef,
                      className: [styles.item, styles.active].join(' ')
                    }
                  : {
                      // Default
                      ref: null,
                      className: styles.item
                    })}>
                <span className={styles.itemInner}>
                  {infoIcon || (icon && typeof icon === 'string' ? <Icon id={icon} /> : icon)}
                  {label}
                </span>
              </button>
            </span>
          </Tooltip>
        );
      })}
      <div className={styles.separator}></div>
      <div className={styles.indicator} ref={indicatorRef}></div>
    </div>
  );
};

export default Tabs;
