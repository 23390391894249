import React, { useState, useCallback } from 'react';

import Flex from '../Flex/Flex';
import { Button } from '../Button/Button';
import { Input } from '../Input/Input';
import TabsHeader from '../TabsHeader/TabsHeader';
import SettingRow from '../SettingRow/SettingRow';

const InputModalContent = ({
  // Content
  header = '[HEADER]',
  placeholder = '',
  label = '[Label]',
  confirm = 'Ok',
  cancel = 'Cancel',

  // Value and handler
  defaultValue,
  onConfirm,
  onRequestClose
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        onConfirm(e.target.value);
        onRequestClose();
      }
    },
    [onConfirm, onRequestClose]
  );

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <Flex vertical style={{ width: 300 }} gap={2}>
      <TabsHeader>{header}</TabsHeader>
      <SettingRow theme="minor" title={label}>
        <Input placeholder={placeholder} autoFocus value={value} onChange={setValue} onKeyDown={handleKeyDown} />
      </SettingRow>
      <Flex gap={2}>
        <Flex.Margin />
        <Button
          theme="transparent"
          onClick={() => {
            onRequestClose();
          }}>
          {cancel}
        </Button>
        <Button
          theme="black"
          onClick={() => {
            onConfirm(value);
            onRequestClose();
          }}>
          {confirm}
        </Button>
      </Flex>
    </Flex>
  );
};

export default InputModalContent;
