import styles from './LogsErrorsIcon.module.css';

import React, { useContext, useCallback } from 'react';

import { useUserLogs, withQueryBoundary } from 'api';
import { Icon } from 'ui-components';
import { MainContext } from 'contexts';

/*
    Bell icon with number of errors since last logs viewing.
*/

const TYPES_OF_ERROR_LOGS = ['emailError'];

const LogsErrorsIconInner = React.memo(({ logsViewedAt }) => {
  /* --------------------------------- POPUPS --------------------------------- */

  const { data } = useUserLogs({
    types: TYPES_OF_ERROR_LOGS,
    page: 1,
    pageSize: 0, // Only total count of items is needed
    startDate: logsViewedAt, // Only count since last logs page visit
    refetchInterval: 10 * 60 * 1000 // Check if there are some errors every 10 minutes
  });

  const { totalItems } = data;

  if (totalItems > 0) {
    const countJsx = totalItems > 99 ? '99+' : totalItems;

    return (
      <div className={styles.notification}>
        <Icon id="notification" color="inherit" />
        <span>{countJsx}</span>
      </div>
    );
  } else {
    return null;
  }
});

const LogsErrorsIcon = React.memo(() => {
  const { user } = useContext(MainContext);

  const logsViewedAt = user.logsViewedAt;
  const isAdminOrHolder = user.role === 'admin' || user.role === 'holder';

  return isAdminOrHolder && <LogsErrorsIconInner logsViewedAt={logsViewedAt} />;
});

export default withQueryBoundary(LogsErrorsIcon, {
  SuspenseFallbackComponent: () => null,
  ErrorFallbackComponent: () => null
});
