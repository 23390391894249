import styles from './Toggle.module.css';
import './Toggle.scss';

import React, { forwardRef } from 'react';
import Switch from 'react-switch';

export const Toggle = forwardRef(({
  onClick, value, disabled, children, className, style, display
}, ref) => {
  const classList = [
    styles.toggle,
    className,
    disabled ? styles.disabled : '',
    styles[display || 'block'] // inline | block
  ];

  return <div ref={ref} onClick={onClick} className={classList.join(' ')} style={style}>
    <Switch onChange={() => { }}
      disabled={disabled}
      checked={value || false}
      height={16}
      width={28}
      onColor="#454442"
      offColor="#B4B2AF"
      handleDiameter={12}
      uncheckedIcon={false}
      activeBoxShadow=""
      checkedIcon={false} />
    {children && <div className={styles.label}>{children}</div>}
  </div>;
});
