import fields from '../fields';
import ObjectID from 'bson-objectid';

import generateRequiredFieldData from './generateRequiredFieldData';

// Uses data from config to create a brand new field data that can be send to backend
const generateFieldData = (fieldConfigId) => {
  const config = fields.find((f) => f._id === fieldConfigId);
  if (!config) {
    throw new Error('DEBUG: field of this type in unknown.');
  }

  return {
    ...generateRequiredFieldData(),

    type: config.type,
    label: config.name,
    value: config.value ?? null,
    options: config?.options?.length
      ? config.options.map((option) => {
          return { ...option, _id: ObjectID().toHexString() };
        })
      : [],
    additionalOptions: config?.additionalOptions?.length
      ? config.additionalOptions.map((option) => {
          return { ...option, _id: ObjectID().toHexString() };
        })
      : [],
    ...config.custom
  };
};

export default generateFieldData;
