import styles from './Breadcrumbs.module.css';

import React, { useState, useEffect, useCallback, useMemo } from 'react';

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

// Adds . at the end of string if it's not there
const normalizeIndex = (index) => {
  // Adds . at the end of string if it's not there
  const trimmedIndex = String(index || '').trim();
  return !trimmedIndex || trimmedIndex.slice(-1) === '.' ? trimmedIndex : trimmedIndex + '.';
};

/* -------------------------------------------------------------------------- */
/*                              HELPER COMPONENTS                             */
/* -------------------------------------------------------------------------- */

const BreadcrumbsText = React.memo(({ className = '', style, children, shrink = false }) => {
  return (
    <div className={[className, styles.breadcrumbsText, shrink ? styles.shrinkable : ''].join(' ')} style={style}>
      {children}
    </div>
  );
});

const BreadcrumbsItem = React.memo(({ className, icon, index, label, onClick }) => {
  return (
    <div className={[className, styles.breadcrumbsItem, onClick ? styles.clickable : ''].join(' ')} onClick={onClick}>
      {icon && <div className={styles.icon}>{icon}</div>}
      {(index || label) && (
        <BreadcrumbsText shrink>
          {index && <strong>{normalizeIndex(index)}</strong>}
          {label && <span>{label}</span>}
        </BreadcrumbsText>
      )}
    </div>
  );
});

const BreadcrumbsSlash = React.memo(() => {
  return <div className={styles.breadcrumbsSlash} />;
});

const BreadcrumbsContainer = React.memo(({ className = '', style, suffix, children }) => {
  return (
    <div className={[className, styles.breadcrumbsContainer].join(' ')} style={style}>
      <div className={styles.breadcrumbsContainerInner}>
        {children}
        <div className={styles.breadcrumbsFloatingSuffix}>{suffix}</div>
      </div>
    </div>
  );
});

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const Breadcrumbs = React.memo(({ className = '', style, data = [], suffix }) => {
  return (
    <BreadcrumbsContainer className={className} suffix={suffix} style={style}>
      {data.map((item, i) => {
        const isLast = i === data.length - 1;

        const key = item.key || JSON.stringify({ index: item.index, label: item.label });
        return (
          <React.Fragment key={key}>
            <BreadcrumbsItem {...item} />
            {!isLast && <BreadcrumbsSlash />}
          </React.Fragment>
        );
      })}
    </BreadcrumbsContainer>
  );
});

Breadcrumbs.Text = BreadcrumbsText;
Breadcrumbs.Item = BreadcrumbsItem;
Breadcrumbs.Slash = BreadcrumbsSlash;
Breadcrumbs.Container = BreadcrumbsContainer;

export default Breadcrumbs;
