import styles from './GoogleFontSelect.module.css';

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Select } from '../Select/Select';
import { Spinner } from '../Spinner/Spinner';
import { Icon } from '../Icon/Icon';

/* -------------------------------------------------------------------------- */
/*                                     API                                    */
/* -------------------------------------------------------------------------- */

let cachedGoogleFontsFamiliesOptions = null;
let googleFontsFamiliesPromiseOptions = null;

const fetchGoogleFontsFamiliesOptions = () => {
  const getFontsPromise = async () => {
    try {
      const GOOGLE_API_KEY = 'AIzaSyAseELtVhniiB8S9CG-B2Ou4oWMrTBblas';

      const result = await axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${GOOGLE_API_KEY}`);

      const fonts = result.data.items;
      const families = fonts.map((f) => ({
        value: f.family,
        label: f.family
      }));

      cachedGoogleFontsFamiliesOptions = families;
      googleFontsFamiliesPromiseOptions = null;

      return cachedGoogleFontsFamiliesOptions;
    } catch (e) {
      // Clear promise to allow re-try
      googleFontsFamiliesPromiseOptions = null;

      throw e;
    }
  };

  /*
    These guards serve two porpoises:
    - Fonts will be fetched only once per session (unless fetching fails).
    - Won't allow two requests at the same time.
  */
  if (cachedGoogleFontsFamiliesOptions) {
    return cachedGoogleFontsFamiliesOptions;
  } else if (googleFontsFamiliesPromiseOptions) {
    return googleFontsFamiliesPromiseOptions;
  } else {
    googleFontsFamiliesPromiseOptions = getFontsPromise();
    return googleFontsFamiliesPromiseOptions;
  }
};

/* -------------------------------------------------------------------------- */
/*                                 COMPONENTS                                 */
/* -------------------------------------------------------------------------- */

const GoogleFontSelect = ({ className = '', ...rest }) => {
  /* ----------------------- GETTING FONT FAMILIES NAMES ---------------------- */

  const [googleFontsFamiliesOptions, setGoogleFontsFamiliesOptions] = useState(cachedGoogleFontsFamiliesOptions);
  const [didFetchingFailed, setDidFetchingFailed] = useState(false);

  const isFetching = !googleFontsFamiliesOptions && !didFetchingFailed;

  useEffect(() => {
    if (!googleFontsFamiliesOptions) {
      const handleFetching = async () => {
        try {
          const result = await fetchGoogleFontsFamiliesOptions();
          // const result = TEMP_FONTS;

          setGoogleFontsFamiliesOptions(result);
        } catch (e) {
          console.error(e);
          setDidFetchingFailed(true);
        }
      };

      handleFetching();
    }
  }, [googleFontsFamiliesOptions]);

  /* ----------------------------------- JSX ---------------------------------- */

  if (isFetching) {
    return (
      <Select
        className={className}
        disabled
        placeholder={
          <div className={styles.placeholder}>
            <Spinner />
            <span>Getting list of Google fonts...</span>
          </div>
        }
      />
    );
  } else if (didFetchingFailed) {
    return (
      <Select
        className={className}
        error
        disabled
        placeholder={
          <div className={styles.error}>
            <Icon id="warning" />
            <span>Failed to load a list of Google fonts!</span>
          </div>
        }
      />
    );
  } else {
    return (
      <Select
        className={className}
        closeMenuOnSelect={false}
        filter
        tabSelectsValue
        options={googleFontsFamiliesOptions}
        {...rest}
        placeholder="- Select a font -"
      />
    );
  }
};

export default GoogleFontSelect;
