import { useState, useEffect, useMemo, useCallback } from 'react';

import _cloneDeep from 'lodash/cloneDeep';
import _defaults from 'lodash/defaults';

import {
  getThemeSettings,
  getThankYouPage,
  optionsForCountrySelect,
  calculateDeepOrderedFields,
  calculateFieldsMap,
  calculateFieldTopLevelParent,
  calculateFieldParentPageNumber
} from 'helpers';

import useFormEngineValuesAndVariablesProps from './useFormEngineValuesAndVariablesProps';
import useFormEngineFileUploadProps from './useFormEngineFileUploadProps';

// NICE TO HAVE TODO: optimize field related calculations (deep ordering is called here and
// during values processing, it should be called once, this callback itself should be optimized too)

/* -------------------------------------------------------------------------- */
/*                         CONFIG END MEMOIZED VALUES                         */
/* -------------------------------------------------------------------------- */

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const DEFAULT_THANK_YOU_PAGE = {
  conditionsType: 'all',
  content: 'Your form is successfully submitted.'
};

const FORM_ENGINE_PREVIEW_MODE_CONSTANTS = {
  // Constants
  seed: 1024,
  mode: 'preview',
  status: 'live',
  inIframe: false,
  backupExist: false,

  // These callbacks in form preview mode are not necessary
  handleWelcomePageClick: () => {},
  updateFieldHighlighted: () => {},

  handlePartialResponses: () => {},
  handleRestoreBackup: () => {},
  handleClearBackup: () => {},

  submitIsDisabled: () => false,
  handleSubmitAgain: () => {}
};

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

// TODO: combine calculateDeepOrderedFields

// Orders fields by position and removes not supported fields
const calculateDeepOrderedAndFilteredFields = (fields, formType) => {
  let filteredFields = fields || [];

  if (formType === 'conversational') {
    // In conversational forms these fields are not allowed
    filteredFields = filteredFields.filter((f) => ['divider', 'pageBreak'].indexOf(f.type) === -1);
  } else {
    // Page breaks are not allowed inside sections
    filteredFields = filteredFields.filter((f) => !(f.type === 'pageBreak' && !f.section && f.section !== 'root'));
  }

  return calculateDeepOrderedFields(filteredFields);
};

// Takes name of activeElement (field/thankYouPage/welcomePage) and form data,
// and tries to detect in what state form preview needs to be so that
// active element is visible on screen
const parseActiveElementIntoFormPreviewState = ({ activeElementId, formType, fields, thankYouPages }) => {
  // Data
  activeElementId = activeElementId || '';
  fields = fields || [];
  thankYouPages = thankYouPages || [];

  const activeElementIdParts = activeElementId.split('_');

  // Fallback fields states
  let visibleField = null;
  let visiblePage = 1;

  // Fallback welcome page state
  let visibleWelcomePage = false;

  // Fallback thank you pages states
  let submitted = false;
  let visibleThankYouPage = null;

  // No element is active
  if (!activeElementId) {
    // Nothing to do here
  }

  // Next active element will be a welcome page
  else if (activeElementId === 'welcomePage') {
    visibleWelcomePage = true;
  }

  // Next active element will be a thank you page
  else if (activeElementIdParts[0] === 'thankYouPage') {
    const thankYouPageId = activeElementIdParts[1];

    if (thankYouPageId === 'default' || thankYouPages.find((t) => t._id === thankYouPageId)) {
      submitted = true;
      visibleThankYouPage = thankYouPageId;
    }
  }

  // Next active element will be a field
  else {
    const fieldsMap = calculateFieldsMap(fields);
    const field = fieldsMap.get(activeElementId);

    if (field) {
      // In classic form field is focussed and preview is moved to right page
      if (formType === 'classic') {
        const page = calculateFieldParentPageNumber({ fieldId: field._id, fields });
        visiblePage = page;
        visibleField = field._id;
      }

      // In conversational forms top level section is focused (it works like a page)
      else if (formType === 'conversational') {
        const topLevelField = calculateFieldTopLevelParent({ fieldId: field._id, fields });
        visiblePage = 1;
        visibleField = topLevelField._id;
      }
    }
  }

  return {
    submitted, // bool
    visibleField, // id
    visiblePage, // number
    visibleWelcomePage, // bool
    visibleThankYouPage // id
  };
};

/* -------------------------------------------------------------------------- */
/*                                    HOOK                                    */
/* -------------------------------------------------------------------------- */

const useFormEngineMainProps = ({ user, theme, form, previewLogic, activeField, onChangeActiveField }) => {
  /* ------------------------------ USER - STATE ------------------------------ */

  const countryCode = user?.country || null;
  const hideBranding = Boolean(user?.hideBranding);
  const userPlan = user?.billing?.plan;
  const userPlanIsProLike = userPlan === 'trial' || userPlan === 'pro';

  const country = useMemo(() => {
    return {
      code: countryCode,
      name: optionsForCountrySelect.find((c) => c.value === countryCode)?.label || null
    };
  }, [countryCode]);

  const userUiSettings = user?.uiSettings || null;

  /* ------------------------------ THEME - STATE ----------------------------- */

  const themeId = theme?._id || null;
  const themeType = theme?.themeType || null;

  const themeSettings = useMemo(() => getThemeSettings(theme), [theme]);

  /* ------------------------------ FORM - STATE ------------------------------ */

  const formType = form?.type;
  const progressBar = Boolean(form?.progressBar);
  const urlParams = form?.urlParams || EMPTY_ARRAY;
  const welcomePage = form?.welcomePage || EMPTY_OBJECT;
  const thankYouPages = form?.thankYouPages || EMPTY_ARRAY;
  const defaultThankYouPage = form?.defaultThankYouPage || DEFAULT_THANK_YOU_PAGE;

  const [preparedForm, setPreparedForm] = useState(null);
  const fields = preparedForm?.fields || EMPTY_ARRAY;

  // Creates a form object deep copy (in case FormEngine modifies something) and makes sure that
  // - theme in form object matches passed theme
  // - owner is taken from user object if provided
  // - fields are sorted by position, and not supported fields are removed
  const cloneAndPrepareForm = useCallback(
    (form) => {
      if (!form) return null;

      const copy = _cloneDeep(form);

      copy.themeId = themeId;
      copy.themeType = themeType;

      copy.owner = _defaults(form.owner, {
        _id: null,
        hideBranding: hideBranding,
        pro: userPlanIsProLike,
        country: countryCode,
        billing: {
          plan: userPlan
        }
      });

      copy.fields = calculateDeepOrderedAndFilteredFields(copy.fields, copy.type);

      // Updates page number in every field
      // when new fields are added it's possible that this will get messed up
      // and it will result in invalid state until refresh (backend does it too
      // so after refresh good data is returned).
      // Fields are deep ordered and pageBreaks within section were removed
      // so detecting page number is simple as that:
      let page = 1;
      for (const field of copy.fields) {
        field.page = page;
        if (field.type === 'pageBreak') {
          page++;
        }
      }

      return copy;
    },
    [themeId, themeType, hideBranding, countryCode, userPlan, userPlanIsProLike]
  );

  useEffect(() => {
    setPreparedForm(cloneAndPrepareForm(form));
  }, [form, cloneAndPrepareForm]);

  /* -------------------------- FORM - EVENT HANDLERS ------------------------- */

  // Handles quick zoom in / zoom out on selected fields
  const handleHighlightField = useCallback((fieldId, zoomInTime) => {
    const cloneFieldsAndSetSelectedFieldZoom = ({ fields, zoom }) => {
      return fields.map((field) => {
        if (field._id === fieldId) {
          return { ...field, zoom };
        } else {
          return field;
        }
      });
    };

    setPreparedForm((selectedForm) => {
      const nextFields = cloneFieldsAndSetSelectedFieldZoom({
        fields: selectedForm.fields,
        zoom: 1.02
      });

      return {
        ...selectedForm,
        fields: nextFields
      };
    });

    setTimeout(() => {
      setPreparedForm((selectedForm) => {
        const nextFields = cloneFieldsAndSetSelectedFieldZoom({
          fields: selectedForm.fields,
          zoom: null
        });

        return {
          ...selectedForm,
          fields: nextFields
        };
      });
    }, zoomInTime);
  }, []);

  /* ---------- VALUES AND VARIABLES STATE, FLOW CONTROL CALCULATIONS --------- */

  const { values, variables, onChange } = useFormEngineValuesAndVariablesProps({ form: preparedForm, previewLogic });

  const { submitted, visibleField, visiblePage, visibleThankYouPage, visibleWelcomePage } = useMemo(
    () =>
      parseActiveElementIntoFormPreviewState({
        activeElementId: activeField,
        formType,
        fields,
        thankYouPages
      }),
    [activeField, formType, fields, thankYouPages]
  );

  const builderControl = useMemo(() => {
    return {
      scrollPreviewToField: visibleField,
      previewThankYouPage: visibleThankYouPage
    };
  }, [visibleField, visibleThankYouPage]);

  /* ------------------------------ PAGES - STATE ----------------------------- */

  const [currentPreviewPage, setCurrentPreviewPage] = useState(visiblePage);

  useEffect(() => {
    setCurrentPreviewPage(visiblePage);
  }, [visiblePage]);

  const preparedFormWithEnforcedPage = useMemo(() => {
    return preparedForm
      ? {
          ...preparedForm,
          page: currentPreviewPage
        }
      : null;
  }, [preparedForm, currentPreviewPage]);

  /* ------------------------- PAGES - EVENT HANDLERS ------------------------- */

  const handleNextPage = useCallback(() => {
    setCurrentPreviewPage((previewPage) => previewPage + 1);
  }, []);

  const handlePreviousPage = useCallback(() => {
    setCurrentPreviewPage((previewPage) => previewPage - 1);
  }, []);

  /* ------------------------- SUBMIT - EVENT HANDLERS ------------------------ */

  // Checks what thank you page should be opened based on logic and requests active field change
  const handleSubmit = useCallback(() => {
    if (!onChangeActiveField) return;

    const thankYouPage = getThankYouPage(defaultThankYouPage, thankYouPages, preparedForm?.fields, values, variables);
    const nextActiveFieldId = `thankYouPage_${thankYouPage?._id || 'default'}`;
    onChangeActiveField(nextActiveFieldId);
  }, [onChangeActiveField, defaultThankYouPage, thankYouPages, preparedForm?.fields, values, variables]);

  /* ---------------------- PROPS ACCEPTED BY FORM ENGINE --------------------- */

  const fileUploadProps = useFormEngineFileUploadProps();

  return {
    ...FORM_ENGINE_PREVIEW_MODE_CONSTANTS,
    ...fileUploadProps,

    // Various
    country,
    progressBar,
    userUiSettings,

    // State
    values,
    variables,
    urlParams,

    // Configuration
    welcomePage,
    thankYouPages,
    defaultThankYouPage,
    form: preparedFormWithEnforcedPage,
    theme: themeSettings,

    // State control
    submitted,
    showWelcomePage: visibleWelcomePage,
    builderControl,

    // Event handlers
    handleHighlightField,
    onChange,
    handleSubmit,
    handleNextPage,
    handlePreviousPage,
    handleFieldClick: onChangeActiveField
  };
};

export default useFormEngineMainProps;
