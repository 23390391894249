import { useCallback, useEffect, useRef, useState } from 'react';

const useLongPress = (onLongPressStart, onLongPressEnd, onLongPressCancel, { shouldPreventDefault = true, delay = 300 } = {}) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef();
  const target = useRef();

  useEffect(() => {
    return () => {
      if (target.current) {
        clearTimeout(timeout.current);
        target.current.removeEventListener('touchend', preventDefault);
      }

      timeout.current = null;
    };
  }, []);

  const start = useCallback(
    (event) => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false
        });

        target.current = event.target;
      }

      onLongPressStart(event);

      timeout.current = setTimeout(() => {
        // State change moved before event handler, 'cause event can cause unmount
        setLongPressTriggered(true);
        onLongPressEnd(event);
      }, delay);
    },
    [onLongPressStart, onLongPressEnd, delay, shouldPreventDefault]
  );

  const clear = useCallback(
    (event, shouldTriggerCanceEvent = true) => {
      if (timeout.current) clearTimeout(timeout.current);

      // To check
      if (shouldTriggerCanceEvent && !longPressTriggered) onLongPressCancel(event);

      setLongPressTriggered(false);

      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener('touchend', preventDefault);
      }
    },
    [shouldPreventDefault, longPressTriggered, onLongPressCancel]
  );

  return {
    onMouseDown: (e) => start(e),
    onMouseUp: (e) => clear(e),
    onMouseLeave: (e) => clear(e, false),
    onTouchStart: (e) => start(e),
    onTouchEnd: (e) => clear(e),
  };
};

const isTouchEvent = (event) => {
  return 'touches' in event;
};

const preventDefault = (event) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

export default useLongPress;
