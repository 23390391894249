import { ioEmitRequest } from './helpers';

const updateSubmissionsWorkspace = (formId, setSubmissionsWorkspaces) => async (submissionsWorkspaceId, changes) => {
  if (process.env.NODE_ENV === 'development') {
    if ('column' in changes) {
      throw new Error('DEBUG: use hiddenColumn, stickyColumn for fields.');
    }
    if ('lockedAt' in changes || 'lockedBy' in changes) {
      throw new Error('DEBUG: this data is provided by backend.');
    }
    if ('filtersCondition' in changes && !['any', 'all'].includes(changes.filtersCondition)) {
      throw new Error('DEBUG: filtersCondition has to be any or all.');
    }
  }

  const payload = await ioEmitRequest('updateSubmissionsWorkspace', {
    form: formId,
    submissionsWorkspace: submissionsWorkspaceId,
    changes: changes
  });

  if (payload?.success) {
    const changes = payload?.data;

    setSubmissionsWorkspaces((submissionsWorkspaces) => {
      const nextSubmissionsWorkspaces = submissionsWorkspaces.map((data) => {
        if (data._id === submissionsWorkspaceId) {
          // Updating local state of columns if needed
          // It reflects what backend will do in the backend after receiving these props
          if ('hiddenColumns' in changes || 'stickyColumns' in changes) {
            const hiddenColumnsSet = new Set(changes.hiddenColumns);
            const stickyColumnsSet = new Set(changes.stickyColumns);

            const nextColumns = data.columns.map((column) => {
              const { _id, hidden, sticky } = column;

              const nextHidden = hiddenColumnsSet.has(_id);
              const nextSticky = stickyColumnsSet.has(_id);

              if (hidden !== nextHidden || sticky !== nextSticky) {
                return { ...column, hidden: nextHidden, sticky: nextSticky };
              } else {
                return column;
              }
            });

            return { ...data, ...changes, columns: nextColumns, updatedAt: new Date() };
          } else {
            return { ...data, ...changes, updatedAt: new Date() };
          }
        } else return data;
      });

      return nextSubmissionsWorkspaces;
    });
  }

  return payload;
};

export default updateSubmissionsWorkspace;
