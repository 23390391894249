import React, { Component } from 'react';

import _Label from './Label/Label.js';
import _Collapse from './Collapse/Collapse.js';
import _Tabs from './Tabs/Tabs.js';
import _Optional from './Optional/Optional.js';
import _CheckLabel from './CheckLabel/CheckLabel.js';
import _RadioLabel from './RadioLabel/RadioLabel.js';
import _Select from './Select/Select.js';
import _EmptyHint from './EmptyHint/EmptyHint.js';
import _Slider from './Slider/Slider.js';
import _Input from './Input/Input.js';
import _PageTabs from './PageTabs/PageTabs.js';
export default class Smol extends Component {
  static Label = _Label;
  static Collapse = _Collapse;
  static Tabs = _Tabs;
  static Optional = _Optional;
  static CheckLabel = _CheckLabel;
  static RadioLabel = _RadioLabel;
  static Select = _Select;
  static EmptyHint = _EmptyHint;
  static Slider = _Slider;
  static Input = _Input;
  static PageTabs = _PageTabs;

  render() {
    return <div {...this.props} {...this.context} />;
  }
}
