import React from 'react';

// For use in selects as label
const getImageChoiceOptionLabelOrImage = (option) => {
  try {
    const { text, url } = JSON.parse(option.value);

    if (text) {
      return String(text);
    } else {
      return (
        <img src={url} alt={text || 'Option without a value'} style={{ maxWidth: '100%', maxHeight: 50, boxShadow: '0px 1px 3px #0004' }} />
      );
    }
  } catch (e) {
    return String(option?.value || '');
  }
};

export default getImageChoiceOptionLabelOrImage;
