import styles from './Tabs.module.css';

import React, { useState, useEffect, useCallback, useMemo } from 'react';

const Tabs = React.forwardRef(({ className, style, data, active, onChange }, ref) => {
  let classList = [styles.tabs, className];

  return (
    <div ref={ref} style={style} className={classList.join(' ')}>
      {data.map((item) => (
        <div
          key={item.id}
          className={[styles.tabsItem, active === item.id ? styles.active : ''].join(' ')}
          onClick={() => onChange(item.id)}>
          {item.label}
        </div>
      ))}
    </div>
  );
});

export default Tabs;
