import { useQuery } from 'react-query';

import usersQueries from './usersQueries';

export const useUserLogs = ({ refetchInterval = false, ...params }) => {
  const { refetch, data, error, isLoading, isFetching } = useQuery({
    queryFn: usersQueries.getUserLogs.fn,
    queryKey: usersQueries.getUserLogs.keyFn(params),
    structuralSharing: false,
    notifyOnChangeProps: ['data', 'error', 'isLoading', 'isFetching'],
    keepPreviousData: true,
    suspense: true,
    refetchInterval
  });

  return {
    refetch,
    error,
    isLoading,
    isFetching,
    data
  };
};
