import styles from './InlineSelect.module.css';

import React, { useState, useCallback, useEffect } from 'react';

import { Popover } from '../Tooltip/Tooltip';
import OpenCloseIcon from '../OpenCloseIcon/OpenCloseIcon';

const InlineSelectOptions = ({ options, value: currentValue, onChange }) => {
  return (
    <div className={styles.options}>
      {options.map(({ value, label }, i) => (
        <div
          key={value}
          className={[value === currentValue ? styles.active : '', styles.option].join(' ')}
          onClick={() => {
            onChange(value);
          }}>
          {label}
        </div>
      ))}
    </div>
  );
};

const InlineSelect = React.memo(
  ({ className = '', error, disabled, options, placeholder, value: currentValue, onChange, placement = 'bottom-start' }) => {
    const [isOpen, setIsOpen] = useState(false);

    /* ----------------------------- EVENT HANLDERS ----------------------------- */

    const handleContainerClick = useCallback(() => {
      if (!disabled) setIsOpen((isOpen) => !isOpen);
    }, [disabled]);

    const handleRequestClose = useCallback(() => {
      setIsOpen(false);
    }, []);

    const handleChange = useCallback(
      (nextValue) => {
        setIsOpen(false);
        onChange(nextValue);
      },
      [onChange]
    );

    useEffect(() => {
      setIsOpen(false);
    }, [disabled]);

    /* ----------------------------------- JSX ---------------------------------- */

    const currentOption = options.find(({ value }) => value === currentValue);
    const visibleLabel = currentOption ? currentOption.shortLabel || currentOption.label || currentOption.value : currentValue;
    const isPlaceholderVisible = visibleLabel === null || typeof visibleLabel === 'undefined';

    return (
      <div className={[className, styles.inlineSelect, disabled ? styles.disabled : '', error ? styles.error : ''].join(' ')}>
        <Popover
          hideOnScroll={true}
          arrow={false}
          offset={[0, 5]}
          visible={isOpen}
          onRequestClose={handleRequestClose}
          placement={placement}
          content={<InlineSelectOptions options={options} value={currentValue} onChange={handleChange} />}
          backdrop={false}>
          <div className={styles.inlineSelectPreview} onClick={handleContainerClick}>
            <div className={styles.currentOptionLabel}>
              {isPlaceholderVisible ? <span className={styles.placeholder}>{placeholder}</span> : visibleLabel}
            </div>
            <div className={styles.dropdownIcon}>
              <OpenCloseIcon opened={isOpen} />
            </div>
          </div>
        </Popover>
      </div>
    );
  }
);

export default InlineSelect;
