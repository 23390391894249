import React, { useEffect, useState } from 'react';

const withReactAlertTemplatePropsProvider = (Component) => {
  return React.memo(({ id, options, style, close }) => {
    // Gets custom props hidden in options passed by react-alert
    const {
      initialProps = {},
      getPropsSetter = () => alert('DEBUG: Missing getPropsSetter'),
      getPropsUpdater = () => alert('DEBUG: Missing getPropsUpdater')
    } = options;

    const [componentProps, setComponentProps] = useState(initialProps);

    // Returns setter and updater through received callbacks
    useEffect(() => {
      // For complete props overwrite
      const setProps = (nextProps) => {
        setComponentProps(nextProps);
      };

      // For partial props overwrite
      const updateProps = (changes) => {
        setComponentProps((props) => {
          return { ...props, ...changes };
        });
      };

      getPropsSetter(setProps);
      getPropsUpdater(updateProps);
      return () => {
        getPropsSetter(null);
        getPropsUpdater(null);
      };
    }, [getPropsSetter, getPropsUpdater]);

    const computedComponentProps = {
      popupId: id,
      ...componentProps,
      onRequestClose: close,
      style: {
        ...style,
        margin: 'none',
        ...initialProps.style
      }
    };

    return <Component {...computedComponentProps} />;
  });
};

export default withReactAlertTemplatePropsProvider;
