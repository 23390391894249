import styles from './Apps.module.css';

import React, { useState, useEffect, useContext } from 'react';
import { Button, Spinner } from 'ui-components';
import io from 'io.js';
import _debounce from 'lodash.debounce';
import config from 'config.js';

import { MainContext } from 'contexts/main.js';
import AddEditConnectionPanel from './panels/AddEditConnectionPanel.js';
import DeleteConnectionPanel from './panels/DeleteConnectionPanel.js';

import ContentHeader from './../Header.js';

const Apps = () => {
  const { user, connections, users, showYouLackPermissions } = useContext(MainContext);

  const userIsAdminOrHolder = user.role === 'admin' || user.role === 'holder';

  const [loading, setLoading] = useState(false);
  const [showAddEditConnectionPanel, setShowAddEditConnectionPanel] = useState(false);
  const [showDeleteConnectionPanel, setShowDeleteConnectionPanel] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [tabs, setTabs] = useState([
    {
      _id: 'services',
      label: 'Services',
      active: true,
      number: null
    },
    {
      _id: 'connections',
      label: 'Connections',
      active: false,
      number: null
    }
  ]);

  const activeTab = tabs.find((tab) => tab.active);

  useEffect(() => {
    setTabs((tabs) => {
      const tabsCopy = [...tabs];
      const index = tabsCopy.findIndex((tab) => tab._id === 'connections');

      tabsCopy[index].number = connections.length || null;

      return tabsCopy;
    });
  }, [connections]);

  const handleTabClick = (tab) => {
    const tabsCopy = [...tabs];
    const index = tabsCopy.findIndex((t) => t._id === tab._id);

    tabsCopy.map((t) => {
      t.active = false;

      return t;
    });

    tabsCopy[index].active = true;

    setTabs(tabsCopy);
  };

  const handleConnectClick = (service) => {
    setSelectedService(service);
    setShowAddEditConnectionPanel(true);
  };

  const handleEditClick = (connection) => {
    const service = config.services.find((s) => s._id === connection.type);

    setSelectedConnection(connection);
    setSelectedService(service);
    setShowAddEditConnectionPanel(true);
  };

  const handleDeleteClick = (connection) => {
    setSelectedConnection(connection);
    setShowDeleteConnectionPanel(true);
  };

  return (
    <>
      <ContentHeader title="Connected services" tabs={tabs} onTabClick={handleTabClick} />

      <div className={styles.content}>
        {activeTab._id === 'services' && (
          <>
            <ul className={styles.services}>
              {config.services.map((service) => (
                <li key={service._id}>
                  <div className={styles.left}>
                    <img src={service.icon} alt="icon" height="32px" />
                    <div className={styles.name}>
                      <span>{service.name}</span>
                      <p>{service.description}</p>
                    </div>
                  </div>
                  <Button theme="white" onClick={userIsAdminOrHolder ? () => handleConnectClick(service) : showYouLackPermissions}>
                    Connect
                  </Button>
                </li>
              ))}
            </ul>
          </>
        )}

        {activeTab._id === 'connections' && (
          <>
            <ul className={styles.connections}>
              <li className={styles.head}>
                <div style={{ width: '60%' }}>Name</div>
                <div style={{ width: '20%' }}>Service</div>
                {userIsAdminOrHolder && <div style={{ width: '20%' }}>Actions</div>}
              </li>

              {connections.map((connection) => {
                const owner = users.find((s) => s._id === connection.owner);

                return (
                  <li key={connection._id} className={[styles.body, config.fullStoryExcludeClass].join(' ')}>
                    <div style={{ width: '60%' }}>
                      <div className={styles.top}>
                        <div className={styles.name}>{connection.name}</div>
                        <span className={styles.currency}>{connection.currency}</span>
                      </div>
                      <div className={styles.owner}>Added by {owner ? owner.getNameOrEmail() : 'deleted user'}</div>
                    </div>
                    <div style={{ width: '20%' }} className={styles.service}>
                      {config.services.find((s) => s._id === connection.type).name}
                      <span className={[styles.mode, styles[connection.mode]].join(' ')}>{connection.mode}</span>
                    </div>
                    {userIsAdminOrHolder && (
                      <div style={{ width: '20%' }} className={styles.actions}>
                        {connection.canEdit && (
                          <Button theme="black-underline" onClick={() => handleEditClick(connection)}>
                            Edit
                          </Button>
                        )}
                        {connection.canDelete && (
                          <Button theme="black-underline" onClick={() => handleDeleteClick(connection)}>
                            Delete
                          </Button>
                        )}
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </>
        )}

        {loading && (
          <div className={styles.loader}>
            <Spinner size={30} borderSize={2} speed="0.8s" />
          </div>
        )}
      </div>

      {showAddEditConnectionPanel && (
        <AddEditConnectionPanel
          show={showAddEditConnectionPanel}
          onClose={() => setShowAddEditConnectionPanel(false)}
          connections={connections}
          service={selectedService}
          selectedConnection={selectedConnection}
        />
      )}

      {showDeleteConnectionPanel && (
        <DeleteConnectionPanel
          show={showDeleteConnectionPanel}
          onClose={() => setShowDeleteConnectionPanel(false)}
          selectedConnection={selectedConnection}
        />
      )}
    </>
  );
};

export default Apps;
