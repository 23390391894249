import axios from 'axios';
import _remove from 'lodash/remove';

import config from 'config.js';

// Constants
const GET_CHANGELOG_REQUEST = '_themes/GET_CHANGELOG_REQUEST';
const GET_CHANGELOG_SUCCESS = '_themes/GET_CHANGELOG_SUCCESS';
const GET_CHANGELOG_FAILURE = '_themes/GET_CHANGELOG_FAILURE';

const endpoitInitialStatus = {
  loading: false,
  error: false,
  success: false
};

// Initiual State
const initialState = {
  changelog: [],

  getChangelog: { ...endpoitInitialStatus }
};

const getEndpointStatus = (type) => {
  const types = {
    request: {
      loading: true,
      error: false,
      success: false
    },
    success: {
      loading: false,
      error: false,
      success: true
    },
    failure: {
      loading: false,
      error: true,
      success: false
    },
  };

  return types[type];
};

// Reducer
export default function reducer(state = JSON.parse(JSON.stringify(initialState)), action = {}) {
  // getChangelog
  if (action.type === GET_CHANGELOG_REQUEST) {
    state.getChangelog = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === GET_CHANGELOG_SUCCESS) {
    state.getChangelog = getEndpointStatus('success');
    state.changelog = action.payload;

    return { ...state };
  }

  if (action.type === GET_CHANGELOG_FAILURE) {
    state.getChangelog = getEndpointStatus('failure');

    return { ...state };
  }

  return state;
}

// Action Creators
export function getChangelog() {
  const request = () => { return { type: GET_CHANGELOG_REQUEST } };
  const success = (data) => { return { type: GET_CHANGELOG_SUCCESS, payload: data } };
  const failure = () => { return { type: GET_CHANGELOG_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getChangelog(state._users.token);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}

const services = {
  getChangelog: async (token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/changelog`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  }
};
