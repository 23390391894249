import styles from './Checkbox.module.css';

import React, { forwardRef, useEffect, useState } from 'react';
import { Spinner } from './../Spinner/Spinner.js';

let watchedValue = null;

export const Checkbox = forwardRef(
  ({ children, onClick, disabled, value, style, display, loading, autoLoading, className, theme = 'black' }, ref) => {
    const [localLoading, setLocalLoading] = useState(false);

    const classList = [
      styles.checkbox,
      className,
      styles['theme-' + theme] || '',
      disabled ? styles.disabled : '',
      value ? styles.checked : '',
      loading || localLoading ? styles.loading : '',
      disabled ? styles.disabled : '',
      !children ? styles.noText : '',
      styles[display || 'block'] // inline | block
    ];

    const handleClick = () => {
      if (!autoLoading) return;

      watchedValue = value;
      setLocalLoading(true);
    };

    useEffect(() => {
      if (watchedValue !== value) setLocalLoading(false);
    }, [value]);

    return (
      <div
        ref={ref}
        className={classList.join(' ')}
        style={style}
        onClick={(e) => {
          handleClick(e);
          onClick(e);
        }}>
        <div className={styles.square}>
          <div className={styles.check}>
            <svg width="9" height="9">
              <path
                d="M3.088 8.111a.848.848 0 01-.666-.316L.281 5.097a.771.771 0 01.161-1.115.862.862 0 011.171.155l1.41 1.773L6.565.486A.86.86 0 017.717.232a.775.775 0 01.267 1.097L3.798 7.735a.841.841 0 01-.671.375l-.039.001z"
                fillRule="nonzero"
              />
            </svg>
          </div>

          {loading && <Spinner size={8} borderSize={0.2} speed="0.8s" className={styles.spinner} />}
        </div>
        {children}
      </div>
    );
  }
);
