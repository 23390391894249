import styles from './SnackbarTemplate.module.css';

import React, { useEffect, useCallback, useRef, useState } from 'react';

import { Icon } from '../../Icon/Icon';

import withReactAlertTemplatePropsProvider from './withReactAlertTemplatePropsProvider';

/* -------------------------------------------------------------------------- */
/*                                HELPER HOOKS                                */
/* -------------------------------------------------------------------------- */

// For detecting mouse in element
const useIsMouseWithin = () => {
  const elementRef = useRef(null);
  const [isMouseWithin, setIsMouseWithin] = useState(false);

  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      const handleMouseEnter = () => setIsMouseWithin(true);
      const handleMouseLeave = () => setIsMouseWithin(false);

      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);
      return () => {
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, []);

  return {
    elementRef,
    isMouseWithin
  };
};

// For creating count downs that can be paused
const useCountdown = ({ timeout = 1000, isPaused = false, onTimeout = null, onTick = null, ticksPerSecond = 30 }) => {
  // Config and state
  const [elapsed, setElapsed] = useState(false);
  const stateRef = useRef({ elapsedTime: 0, previousTickTime: null });
  const refreshInterval = 1000 / ticksPerSecond;

  // Registering elapsed time
  const tick = useCallback(() => {
    const state = stateRef.current;

    if (state.previousTickTime) {
      state.elapsedTime += performance.now() - state.previousTickTime;
    }

    state.previousTickTime = performance.now();

    const elapsed = state.elapsedTime >= timeout;
    const progress = Math.min(state.elapsedTime / timeout, 1);

    onTick({ progress });

    if (elapsed) {
      setElapsed(true);
      onTimeout();
    }
  }, [timeout, onTimeout, onTick]);

  // Scheduling ticks
  useEffect(() => {
    if (!elapsed && !isPaused) {
      const state = stateRef.current;
      state.previousTickTime = null;

      const interval = setInterval(tick, refreshInterval);
      return () => {
        tick();
        clearInterval(interval);
      };
    }
  }, [elapsed, isPaused, refreshInterval, tick]);

  return elapsed;
};

/* -------------------------------------------------------------------------- */
/*                      COUNTDOWN PROGRESSBAR COMPONENT                      */
/* -------------------------------------------------------------------------- */

const CountdownProgressBar = React.memo(({ timeout = 1000, isPaused = false, onTimeout }) => {
  const laneRef = useRef(null);

  useCountdown({
    timeout,
    isPaused,
    onTimeout,
    onTick: useCallback(({ progress }) => {
      const lane = laneRef.current;
      if (lane) {
        lane.style.transform = `translateX(${-progress * 100}%)`;
      }
    }, [])
  });

  return (
    <div className={styles.countdownProgressBar}>
      <div className={styles.countdownProgressBarLane} ref={laneRef} />
    </div>
  );
});

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const DEFAULT_ICONS_BY_TYPE = {
  success: <Icon id="save" color="inherit" scale={1.2} />,
  info: <Icon id="information" color="inherit" />,
  error: <Icon id="warning" color="inherit" />,
  warning: <Icon id="information" color="inherit" />
};

const Snackbar = React.memo(
  ({
    type = 'info',
    timeout = 10000,
    title = null,
    message = 'Empty snackbar message',
    footer = null,
    icon = null,
    onRequestClose,
    style
  }) => {
    /* ---------------------------------- STATE --------------------------------- */

    const { elementRef, isMouseWithin } = useIsMouseWithin();

    const iconJsx = (typeof icon === 'string' ? <Icon id={icon} color="inherit" /> : icon) || DEFAULT_ICONS_BY_TYPE[type] || null;

    /* ----------------------------------- JSX ---------------------------------- */

    return (
      <div className={styles.snackbarOuterContainer} style={{ ...style, margin: undefined }}>
        <div className={[styles.snackbarContainer, styles[`type-${type}`]].join(' ')} onClick={onRequestClose} ref={elementRef}>
          <div className={styles.contentContainer}>
            <button className={styles.closeButton} onClick={onRequestClose}>
              x
            </button>

            <div className={styles.content}>
              {iconJsx && <div className={styles.icon}>{iconJsx}</div>}
              <div className={styles.message}>
                {title && (
                  <p>
                    <u>
                      <b>{title}</b>
                    </u>
                  </p>
                )}
                {message && <p>{message}</p>}
                {footer && (
                  <p>
                    <small>{footer}</small>
                  </p>
                )}
              </div>
            </div>
          </div>

          {timeout > 0 && <CountdownProgressBar timeout={timeout} isPaused={isMouseWithin} onTimeout={onRequestClose} />}
        </div>
      </div>
    );
  }
);

export default withReactAlertTemplatePropsProvider(Snackbar);
