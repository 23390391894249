import { useContext } from 'react';
import PopupsContext from './PopupsContext';

const usePopups = () => {
  const context = useContext(PopupsContext);

  return context;
};

export default usePopups;
