import styles from './MatrixListPreview.module.css';

import React from 'react';

import { Expandible } from 'ui-components';

import EmptyPreview from './EmptyPreview';

const MatrixListPreview = ({ theme, value = null, limit, allowCollapsing, placeholder }) => {
  if (value && !Array.isArray(value)) value = [value];

  if (!value?.length) {
    return <EmptyPreview theme={theme} placeholder={placeholder} />;
  } else {
    return (
      <div className={[styles.listPreview, styles[`theme-${theme}`]].join(' ')}>
        <Expandible
          data={value}
          allowCollapsing={allowCollapsing}
          limit={limit}
          renderData={(data) => (
            <ul className={styles.list}>
              {data.map((value, i) => {
                return (
                  <li key={value + i} className={styles.listItem}>
                    <span className={styles.question}>{String(value[0])} ➞ </span>
                    {String(value[1].join(', '))}
                  </li>
                );
              })}
            </ul>
          )}
        />
      </div>
    );
  }
};

export default MatrixListPreview;
