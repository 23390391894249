import { useEffect } from 'react';
import io from 'io.js';

/* --------------------------------- HELPERS -------------------------------- */

function isArrayEqualShallow(a, b) {
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }

  return true;
}

/* --------------------------------- STORAGE -------------------------------- */

// Storage for event logs
const LAZY_REFRESH_LOGS = {};

/* ---------------------------------- HOOK ---------------------------------- */

/*
  Hook for fetching data with 'refresh--' and 'get--' events.
  Solves three problems:
  - handles refresh events automatically
  - reduces unnecessary calls to 'get--' events (called from onRefresh callback) to required minimum
  - fixes data races that happen when:
     - state is stored in a global context,
     - but data used to update state is requested in useEffects of multiple components at the same time
*/
const useLazySocketRefreshEffect = (onRefresh, onRefreshArgs, { refreshEvent }) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      if (!Array.isArray(onRefreshArgs)) {
        throw new Error('DEBUG: onRefreshArgs has to be a dependencies array.');
      }
    }

    const isInitialized = refreshEvent in LAZY_REFRESH_LOGS;

    // Called only once in application for a given refreshEventName
    if (!isInitialized) {
      const eventLogs = {
        lastEventId: 0,
        lastHandledEventId: null,
        lastHandledRefreshArgs: onRefreshArgs
      };

      // This handler will listen bof changes but only to register that they happened.
      // Requesting this changes is done later only if they are needed.
      io.socket.on(refreshEvent, () => {
        eventLogs.lastEventId++;
      });

      LAZY_REFRESH_LOGS[refreshEvent] = eventLogs;
    }

    const handleLazyRefresh = () => {
      const eventLogs = LAZY_REFRESH_LOGS[refreshEvent];

      if (process.env.NODE_ENV === 'development') {
        if (eventLogs.lastHandledRefreshArgs.length !== onRefreshArgs.length) {
          throw new Error('DEBUG: onRefreshArgs array has changed length.');
        }
      }

      // New data is required only when:
      // - no requests for this api were done before
      // - refresh event was received but during that time
      //   there was no component that needed that data so in wasn't requested
      // - some arguments passed to onRefresh have changed
      const wasLastRefreshHandled = eventLogs.lastEventId === eventLogs.lastHandledEventId;
      const didRefreshArgsChange = !isArrayEqualShallow(eventLogs.lastHandledRefreshArgs, onRefreshArgs);

      if (!wasLastRefreshHandled || didRefreshArgsChange) {
        eventLogs.lastHandledEventId = eventLogs.lastEventId;
        eventLogs.lastHandledRefreshArgs = onRefreshArgs;

        onRefresh(...onRefreshArgs);
      }
    };

    handleLazyRefresh();

    // Next fetches on changes
    io.socket.on(refreshEvent, handleLazyRefresh);
    return () => {
      io.socket.off(refreshEvent, handleLazyRefresh);
    };
  }, [onRefresh, refreshEvent, onRefreshArgs]);
};

export default useLazySocketRefreshEffect;
