import arrayMove from 'array-move';
import ObjectID from 'bson-objectid';

import generateRequiredFieldData from './generateRequiredFieldData';

const generateSectionDataFromExample = (fields) => {
  const createUID = () => {
    return ObjectID().toHexString();
  };

  /* ------ CREATE MAPS (EXISTING IDENTIFIER -> NEW ONE IN CLONED FIELD) ------ */

  const mappedFieldIds = new Map();
  const mappedConditionIdsByFieldId = new Map();
  const mappedOptionIdsByFieldId = new Map();

  for (const field of fields) {
    if (!field?._id) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error('DEBUG: required field data missing.');
      }
      return [];
    }

    mappedFieldIds.set(field._id, createUID());

    if (field.conditions?.length) {
      const mappedConditions = new Map();

      for (const condition of field.conditions) {
        if (condition?._id) {
          mappedConditions.set(condition._id, createUID());
        }
      }

      mappedConditionIdsByFieldId.set(field._id, mappedConditions);
    }

    if (field?.options?.length) {
      const mappedOptions = new Map();

      for (const option of field.options) {
        if (option?._id) {
          mappedOptions.set(option._id, createUID());
        }
      }

      mappedOptionIdsByFieldId.set(field._id, mappedOptions);
    }
  }

  /* --------------- CREATES A SELF ISOLATED CLONED SECTION DATA -------------- */

  const resultFields = [];

  try {
    // Coverts all properties into a default-like one for a new section of this architecture
    for (const field of fields) {
      const processedField = {
        ...generateRequiredFieldData()
      };

      const processFieldProp = (key, value) => {
        switch (key) {
          // ref is not needed anymore in new fields
          case 'ref':
            return undefined;

          // Returns mapped id of existing field
          case '_id':
            if (!mappedFieldIds.has(value)) {
              throw new Error('DEBUG: this should not be possible.');
            }
            return mappedFieldIds.get(value);

          // Returns mapped id of existing field or 'root'
          case 'section':
            return mappedFieldIds.get(value) ?? 'root';

          // The same goes for children
          case 'childrens':
            return value.map((childId) => {
              // Returns mapped id of existing field
              if (!mappedFieldIds.has(childId)) {
                throw new Error('DEBUG: childrens array contains an unknown field (something is wrong with data).');
              }
              return mappedFieldIds.get(childId);
            });

          // Value is always initialized to null
          case 'value':
            return null;

          // Options are copied with new replaced ids and some spacial treatment for values
          case 'conditions':
            const mappedConditions = mappedConditionIdsByFieldId.get(field._id);

            return value.map(({ _id, triggerField, type, value }) => {
              if (!mappedConditions || !mappedConditions.has(_id)) {
                throw new Error('DEBUG: this should not be possible.');
              }
              if (!mappedFieldIds.has(triggerField)) {
                throw new Error('DEBUG: condition refers an unknown field (something is wrong with data).');
              }

              return {
                type,
                _id: mappedConditions.get(_id),
                triggerField: mappedFieldIds.get(triggerField),

                // Value may be referring an existing field
                // NICE TO HAVE TODO: do that only for dropdown/checkbox with possible throw, for others copy
                value: mappedOptionIdsByFieldId.get(triggerField)?.get?.(value) ?? value
              };
            });

          // Options are copied with new replaced ids
          case 'options':
            const mappedOptionIds = mappedOptionIdsByFieldId.get(field._id);

            return value.map(({ _id, value, label }) => {
              if (!mappedOptionIds || !mappedOptionIds.has(_id)) {
                throw new Error('DEBUG: this should not be possible.');
              }

              return {
                _id: mappedOptionIds.get(_id),
                value,
                label
              };
            });

          // Other properties are copied
          default:
            return value;
        }
      };

      for (const key in field) {
        const processedValue = processFieldProp(key, field[key]);

        if (typeof processedValue !== 'undefined') {
          processedField[key] = processedValue;
        }
      }

      resultFields.push(processedField);
    }

    // Validates crated data
    let rootsFound = 0;
    for (const field of resultFields) {
      if (field.section === 'root') {
        if (field.type !== 'section') {
          throw new Error('DEBUG: array of passed fields contained some unconnected fields.');
        }

        rootsFound++;
      }
    }
    if (rootsFound !== 1) {
      throw new Error("DEBUG: array of passed fields doesn't represent exactly one isolated section.");
    }

    // Makes sure root section is at the start
    return arrayMove(
      resultFields,
      resultFields.findIndex((f) => f.section === 'root'),
      0
    );
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      throw e;
    }
    return [];
  }
};

export default generateSectionDataFromExample;
