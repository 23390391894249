import { ioEmitRequest } from './helpers';

export default (formId) => async () => {
  if (!formId) return null;

  const payload = await ioEmitRequest('getSubmissionsWorkspaces', { form: formId });

  // TEMP FIX: Remove after this is be resolved in backend
  // Temporary fix for backend returning sometimes {success: false} and sometimes [...]
  if (typeof payload === 'object' && payload !== null && 'success' in payload) {
    return payload?.data ?? null;
  } else {
    return payload ?? null;
  }
};
