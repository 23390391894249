import styles from './Usage.module.css';

import React, { useContext, useMemo } from 'react';
import { format } from 'date-fns';
import { Hr } from 'ui-components';

import { MainContext } from 'contexts/main.js';

import ContentHeader from '../Header.js';

const getLabel = (name, plan, usage, unlimitedBoundary) => {
  return (
    <div className={styles.label}>
      {name}{' '}
      <span>
        {usage} out of {plan >= unlimitedBoundary ? '∞' : plan}
      </span>
    </div>
  );
};

const getBar = (plan, usage) => {
  return (
    <div className={styles.track}>
      <div className={styles.bar} style={{ width: `${(100 * usage) / plan}%` }} />
    </div>
  );
};

const Usage = () => {
  const { user } = useContext(MainContext);

  const periodEnd = useMemo(() => {
    if (user.billing.plan === 'trial') {
      return user.billing.trialEnd;
    } else if (user?.planPeriod?.end) {
      return user?.planPeriod?.end;
    } else {
      return null;
    }
  }, [user.billing.plan, user?.planPeriod?.end, user.billing.trialEnd]);

  return (
    <>
      <ContentHeader
        title="Plan usage"
        description={
          <>
            {periodEnd && (
              <>
                This indicates your maximum monthly usage based on your QuestionScout plan. Your account usage resets on a monthly basis,
                and will reset again on <span className={styles.strong}>{format(new Date(periodEnd), 'LLLL d, yyyy')}</span>.
              </>
            )}
          </>
        }
      />

      <div className={styles.content}>
        <div className={styles.usage}>
          {getLabel('Monthly responses', user.plan.submissions, user.planUsage.submissions, 100000)}
          {getBar(user.plan.submissions, user.planUsage.submissions)}
        </div>

        <div className={styles.usage}>
          {getLabel('Monthly file uploads', user.plan.filesStorage, user.planUsage.filesStorage, 10000)}
          {getBar(user.plan.filesStorage, user.planUsage.filesStorage)}
        </div>

        <Hr />

        <div className={styles.usage}>
          {getLabel('Forms', user.plan.forms, user.planUsage.forms, 10000)}
          {getBar(user.plan.forms, user.planUsage.forms)}
        </div>

        <div className={styles.usage}>
          {getLabel('Domains', user.plan.domains, user.planUsage.domains, 1000)}
          {getBar(user.plan.domains, user.planUsage.domains)}
        </div>

        <div className={styles.usage}>
          {getLabel('Collaborators', user.plan.users, user.planUsage.users, 1000)}
          {getBar(user.plan.users, user.planUsage.users)}
        </div>

        <div className={styles.usage}>
          {getLabel('Guests', user.plan.guests, user.planUsage.guests, 1000)}
          {getBar(user.plan.guests, user.planUsage.guests)}
        </div>
      </div>
    </>
  );
};

export default Usage;
