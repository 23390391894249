import styles from './QSNotFound.module.css';

import React from 'react';
import { Icon } from 'ui-components';

const logoJsx = (
  <img alt="QuestionScout-logo" src={require('assets/images/logo-icon-shocked-bw.svg')} className={styles.logo} draggable="false" />
);

const QSNotFound = ({
  title = 'We didn’t find anything on this URL',
  description = 'The page you’re looking for has been deleted, or simply never existed.',
  hint,
  hintPlacement = 'top-start',
  hintDirection = 'top',
  inline = false
}) => {
  return (
    <>
      {hint && (
        <div className={styles.hint} data-placement={hintPlacement} data-direction={hintDirection}>
          <div className={styles.hintArrow}>
            <Icon id="arrow-narrow-left" />
          </div>
          <div className={styles.hintMessage}>{hint}</div>
        </div>
      )}
      <div className={[styles.notFound, inline ? styles.inline : ''].join(' ')}>
        <div className={styles.icon}>{logoJsx}</div>
        <div className={styles.header}>{title}</div>
        <div className={styles.message}>{description}</div>
      </div>
    </>
  );
};

export default QSNotFound;
