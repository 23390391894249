import styles from './Members.module.css';

import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { Button, Avatar, Input, Spinner, Select, FormRow, Header, usePopups } from 'ui-components';
import UpdatePlan from './../Upgrade/panels/UpdatePlan.js';
import asyncSocketAckEmit from 'helpers/asyncSocketAckEmit.js';
import validator from 'validator';

import { MainContext } from 'contexts/main.js';
import { ProfileContext } from 'contexts/profile.js';

import ContentHeader from './../Header.js';

import config from 'config.js';

const USER_ROLES_WITH_IMPLIED_PERMISSIONS = ['holder', 'admin'];

const Members = () => {
  const { user, users } = useContext(MainContext);
  const { setPage } = useContext(ProfileContext);

  const popups = usePopups();

  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState('user');
  const [usersToInviteString, setUsersToInviteString] = useState('');
  const [usersUsage, setUsersUsage] = useState(0);
  const [guestsUsage, setGuestsUsage] = useState(0);
  const [showUpdatePlan, setShowUpdatePlan] = useState(false);

  useEffect(() => {
    setUsersUsage(user.planUsage.users);
    setGuestsUsage(user.planUsage.guests);
  }, [user.planUsage.guests, user.planUsage.users]);

  const parseAndFilterEmailsString = useCallback(
    (str) => {
      // Support for comma separation or white-space separation
      const allEmails = str.replace(/\s+/g, ',').split(',').filter(Boolean);

      const allUniqueEmails = [...new Set(allEmails)];

      const allUniqueValidatedEmails = allUniqueEmails.filter((email) => {
        if (!validator.isEmail(email)) return false;
        if (users.findIndex((u) => u.email === email) !== -1) return false;

        return true;
      });

      return allUniqueValidatedEmails;
    },
    [users]
  );

  const sendInvite = async () => {
    const emails = parseAndFilterEmailsString(usersToInviteString);

    if (role === 'guest' && guestsUsage + emails.length > user.plan.guests) return;
    if (role === 'user' && usersUsage + emails.length > user.plan.users) return;

    setLoading(true);

    const { success } = await asyncSocketAckEmit('inviteUsers', {
      role,
      emails
    });

    if (success) {
      if (role === 'guest') {
        setGuestsUsage(guestsUsage + emails.length);
      } else {
        setUsersUsage(usersUsage + emails.length);
      }

      setUsersToInviteString('');
    }

    setLoading(false);
  };

  const removeUser = async (id, role) => {
    setLoading(true);

    await asyncSocketAckEmit('removeUsers', [id]);

    if (role === 'guest') {
      setGuestsUsage(guestsUsage - 1);
    } else {
      setUsersUsage(usersUsage - 1);
    }

    setLoading(false);
  };

  const updateUserRole = async (id, nextRole) => {
    const user = users.find((u) => u._id === id);
    if (!user) return;

    const previousRole = user.role;

    const hadImpliedPermissions = USER_ROLES_WITH_IMPLIED_PERMISSIONS.indexOf(previousRole) !== -1;
    const willHaveImpliedPermissions = USER_ROLES_WITH_IMPLIED_PERMISSIONS.indexOf(nextRole) !== -1;

    const willLoseImpliedPermissions = hadImpliedPermissions === true && willHaveImpliedPermissions === false;
    const userGainImpliedPermissions = hadImpliedPermissions === false && willHaveImpliedPermissions === true;

    setLoading(true);

    await asyncSocketAckEmit('updateUsers', [{ id, params: { role: nextRole } }]);

    if (nextRole === 'guest') {
      setGuestsUsage(guestsUsage + 1);
      setUsersUsage(usersUsage - 1);
    } else {
      setGuestsUsage(guestsUsage - 1);
      setUsersUsage(usersUsage + 1);
    }

    if (willLoseImpliedPermissions) {
      popups.showSuccessSnackbar('All permissions of this user were cleared.');
    } else if (userGainImpliedPermissions) {
      popups.showSuccessSnackbar('This user gained all permissions in every workspace.');
    } else if (previousRole === 'user' && nextRole === 'guest') {
      popups.showSuccessSnackbar('Non-read permissions of this user were cleared.');
    }

    setLoading(false);
  };

  const roleOptions = useMemo(() => {
    let res = [];

    if (usersUsage < user.plan.users) {
      res = [
        ...res,
        {
          label: 'Collaborator',
          value: 'user'
        },
        {
          label: 'Admin Collaborator',
          value: 'admin'
        }
      ];
    }

    if (guestsUsage < user.plan.guests) {
      res = [
        ...res,
        {
          label: 'Guest',
          value: 'guest'
        }
      ];
    }

    return res;
  }, [guestsUsage, usersUsage, user.plan.users, user.plan.guests]);

  const getUserRoleOptions = useCallback(
    (role) => {
      if (role === 'holder') return [{ label: 'Holder', value: 'holder' }];

      let options = [];

      if (role === 'guest') {
        options = [...options, { label: 'Guest', value: 'guest' }];

        if (usersUsage < user.plan.users) {
          options = [
            ...options,
            {
              label: 'Collaborator',
              value: 'user'
            },
            {
              label: 'Admin Collaborator',
              value: 'admin'
            }
          ];
        }
      } else {
        options = [
          ...options,
          {
            label: 'Collaborator',
            value: 'user'
          },
          {
            label: 'Admin Collaborator',
            value: 'admin'
          }
        ];

        if (guestsUsage < user.plan.guests) {
          options = [...options, { label: 'Guest', value: 'guest' }];
        }
      }

      return options;
    },
    [guestsUsage, usersUsage, user.plan.users, user.plan.guests]
  );

  useEffect(() => {
    setRole(roleOptions[0]?.value);
  }, [roleOptions]);

  useEffect(() => {
    if (user.role !== 'holder' && user.role !== 'admin') setPage('profile');
  }, [setPage, user.role]);

  const sendInviteError = useMemo(() => {
    if ([null, 'personal'].includes(user.billing.plan)) {
      return "Your current plan doesn't allow guests and collaborators.";
    }

    const emails = parseAndFilterEmailsString(usersToInviteString);

    let slotsAvailable = 0;
    let slotsUsed = Infinity;

    if (role === 'guest') {
      slotsAvailable = Math.max(user.plan.guests, 0);
      slotsUsed = Math.min(guestsUsage, Infinity);
    } else if (role === 'user' || role === 'admin') {
      slotsAvailable = Math.max(user.plan.users, 0);
      slotsUsed = Math.min(usersUsage, Infinity);
    }

    const membersWillFit = slotsUsed + emails.length <= slotsAvailable;

    if (membersWillFit) {
      return false;
    } else {
      return `You don't have enough free ${role === 'guest' ? 'guests' : 'collaborators'} seats (${emails.length} ${
        emails.length > 1 ? 'are' : 'is'
      } needed).`;
    }
  }, [
    parseAndFilterEmailsString,
    guestsUsage,
    role,
    user.billing.plan,
    user.plan.guests,
    user.plan.users,
    usersToInviteString,
    usersUsage
  ]);

  return (
    <>
      <ContentHeader title="Manage members" />

      <div className={styles.content}>
        <FormRow label="Invite new member(s)" errorMessage={sendInviteError} style={{ width: '100%' }}>
          <div className={styles.invite}>
            <div className={styles.inviteInput}>
              <Input
                className={config.fullStoryExcludeClass}
                value={usersToInviteString}
                onChange={(value) => setUsersToInviteString(value)}
                placeholder="john@doe.com, jane@doe.com"
                disabled={usersUsage >= user.plan.users && guestsUsage >= user.plan.guests}
                style={{ width: 'calc(100% - 170px)', margin: '0 7px 0 0' }}
              />
              <Select
                options={roleOptions}
                value={role}
                onChange={(option) => setRole(option.value)}
                width="170px"
                disabled={usersUsage >= user.plan.users && guestsUsage >= user.plan.guests}
              />
            </div>
            <Button theme="black" style={{ width: '100px' }} onClick={() => sendInvite()} disabled={Boolean(sendInviteError)}>
              Send invite
            </Button>
          </div>
        </FormRow>

        <Header style={{ padding: '5px 0 0 0' }} className={styles.header}>
          <div className={styles.left}>
            Existing members
            {user.role === 'holder' &&
              ![null, 'personal', 'trial'].includes(user.billing.plan) &&
              (!user.lifeTime || !user.lifeTime.enabled) && (
                <Button theme="black-underline" onClick={() => setShowUpdatePlan(true)} style={{ margin: '0 0 0 15px' }}>
                  Update collaborators limit
                </Button>
              )}
          </div>
          <div className={styles.right}>
            <div>
              {usersUsage > 0 && (
                <>
                  {usersUsage} collaborator{usersUsage > 1 && 's'} seat{usersUsage > 1 && 's'} occupied, <span />
                </>
              )}
              {user.plan.users - usersUsage >= 1000 ? '∞' : user.plan.users - usersUsage} collaborator
              {user.plan.users - usersUsage > 1 && 's'} seat{user.plan.users - usersUsage > 1 && 's'} available
            </div>

            <div>
              {guestsUsage > 0 && (
                <>
                  {guestsUsage} guest{guestsUsage > 1 && 's'} seat{guestsUsage > 1 && 's'} occupied, <span />
                </>
              )}
              {user.plan.guests - guestsUsage >= 1000 ? '∞' : user.plan.guests - guestsUsage} guest
              {user.plan.guests - guestsUsage > 1 && 's'} seat{user.plan.guests - guestsUsage > 1 && 's'} available
            </div>
          </div>
        </Header>

        <ul className={styles.users}>
          <li className={styles.usersHead}>
            <div style={{ width: '55%' }}>Member</div>
            <div style={{ width: '25%' }}>Role</div>
            <div style={{ width: '20%', textAlign: 'center' }}>Actions</div>
          </li>

          {users &&
            users.map((u) => (
              <li className={styles.usersBody} key={u._id}>
                <div
                  className={[styles.info, !u.verified ? styles.notActive : '', config.fullStoryExcludeClass].join(' ')}
                  style={{ width: '55%' }}>
                  <Avatar className={styles.infoAvatar} user={u} size="large" />
                  <div className={styles.infoText}>
                    <p>{u.getName()}</p>
                    <span>{u.email}</span>
                  </div>
                </div>

                <div style={{ width: '25%' }}>
                  <Select
                    options={getUserRoleOptions(u.role)}
                    value={u.role}
                    onChange={(option) => u.role !== option.value && updateUserRole(u._id, option.value)}
                    style={{ width: '100%' }}
                    disabled={u.role === 'holder'}
                  />
                </div>

                <div className={styles.actions} style={{ width: '20%', display: 'flex', justifyContent: 'center' }}>
                  {u.role !== 'holder' && u._id !== user._id && (
                    <>
                      <Button icon="delete" theme="black-underline" delay={1000} onClick={() => removeUser(u._id, u.role)}>
                        Remove
                      </Button>
                    </>
                  )}
                </div>
              </li>
            ))}
        </ul>

        {loading && (
          <div className={styles.loader}>
            <Spinner size={30} borderSize={2} speed="0.8s" />
          </div>
        )}
      </div>

      {showUpdatePlan && (
        <UpdatePlan
          show={showUpdatePlan}
          onClose={() => setShowUpdatePlan(false)}
          selectedPlan={user.billing.plan}
          allowPaymentInformationChange={false}
          allowQuantityUpdate={true}
          onSuccess={() => setShowUpdatePlan(false)}
          planResponses={user.billing.responses}
        />
      )}
    </>
  );
};

export default Members;
