import styles from './DropdownMenu.module.css';

import { Link } from 'react-router-dom';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import useLongPress from 'helpers/useLongPress.js';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { ReactComponent as DropdowArrowSmall } from 'assets/images/dropdow-arrow-small.svg';

const DeleteAction = ({ className, children, onLongPress }) => {
  const [percentage, setPercentage] = useState(0);
  const [showHint, setShowHint] = useState(false);

  const interval = useRef();
  const delay = 3000;

  useEffect(() => {
    return () => {
      if (interval.current) clearInterval(interval.current);

      interval.current = null;
    };
  }, []);

  const start = useCallback(() => {
    let localPercentage = 0;

    setPercentage(0);

    interval.current = setInterval(() => {
      localPercentage = localPercentage + 5;

      if (localPercentage > 100) {
        clear();

        return;
      };

      setPercentage(localPercentage);
    }, delay / 22);
  }, []);

  const clear = useCallback(() => {
    interval.current && clearInterval(interval.current);
  }, []);

  const onLongPressStart = () => {
    setShowHint(false);
    start();
  }

  const onLongPressCancel = () => {
    setShowHint(true);
    clear();
  };

  const onLongPressEnd = (e) => {
    clear();
    onLongPress(e);
  };

  const longPressEvent = useLongPress(onLongPressStart, onLongPressEnd, onLongPressCancel, {
    shouldPreventDefault: true, delay
  });

  return <div {...longPressEvent} className={className}>
    {children}
    <div className={styles.deleteLoader}>
      <CircularProgressbar value={percentage} strokeWidth={10} styles={buildStyles({
        pathColor: '#EAE8E4',
        trailColor: 'transparent',
      })} />
    </div>
    {showHint && <div className={styles.deleteHint}>Hold to delete</div>}
  </div>;
};

const types = {
  label: (item) => {
    return <div className={styles.label}>{item.text}</div>;
  },
  link: (item) => {
    return <>
      {!item.outside && <Link className={styles.link} to={item.link}>{item.text}</Link>}
      {item.outside && <a className={styles.link} href={item.link} target="_blank" rel="noopener noreferrer">{item.text}</a>}
    </>;
  },
  hr: () => {
    return <hr className={styles.hr} />;
  },
  list: (item, setSelectedList) => {
    return <div className={styles.list} onClick={() => setSelectedList(item.id)}><span>{item.text}</span><DropdowArrowSmall /></div>;
  },
  action: (item) => {
    return <div className={[styles.action, styles[item.theme], item.disabled ? styles.disabled : ''].join(' ')} onClick={() => item.onClick(...(item.params || []))}>{item.text}</div>;
  },
  delete: (item) => {
    return <DeleteAction className={[styles.delete, styles[item.theme], item.disabled ? styles.disabled : ''].join(' ')}
      onClick={() => item.onClick(...(item.params || []))}
      onLongPress={() => item.onLongPress(...(item.params || []))}>
      {item.text}
    </DeleteAction>;
  }
};

export const DropdownMenu = ({ items, width, onClose, position }) => {
  const [selectedList, setSelectedList] = useState(null);

  const selectedListObject = selectedList ? items.find((item) => item.id === selectedList) : {};

  return <>
    <div className={styles.cover} onClick={onClose} />
    <div className={styles.main} style={{ width, ...position }}>
      <ul>
        {!selectedList && items.map((item, index) => <li key={index}>
          {types[item.type](item, setSelectedList)}
        </li>)}

        {selectedList && <li className={styles.back} onClick={() => setSelectedList(null)}><DropdowArrowSmall />{selectedListObject.text}</li>}
        {selectedList && <div className={styles.scrollArea} onWheel={(e) => e.stopPropagation()}>
          {selectedListObject.items.map((item) => <li key={item._id}>
            <div className={[styles.action, selectedListObject.selected === item._id ? styles.selected : ''].join(' ')} onClick={() => selectedListObject.onClick(...(selectedListObject.params || []), item._id)}>{item.name}</div>
          </li>)}
        </div>}
      </ul>
    </div>
  </>;
};
