// Converts data fetched by getSubmissionsPropMetadata into format
// that will be used within <NestedPropertySelect/> component.
const getSubmissionsInterpretationsOptions = (submissionsPropsMetadata, config = {}) => {
  const { interpretationsFilter = () => true } = config;

  const nestedOptions = [];
  for (const category of ['fields', 'calculationVariables', 'urlParams', 'properties']) {
    for (const property of submissionsPropsMetadata[category]) {
      // Submission property (ex. field/variable)
      const option = {
        value: property.path,
        icon: property.icon || null,
        index: property.index || null,
        label: property.label || null,

        // Interpretations of given submission property (ex. multiple choice as options or as number of options)
        children: property.interpretations.filter(interpretationsFilter).map((interpretation) => {
          return {
            value: interpretation.path,
            label: interpretation.label
          };
        })
      };

      // Only return properties with some interpretations left after filtering
      // (in <NestedPropertySelect/> lowest level elements will be selectable)
      if (!option.children.length) continue;

      nestedOptions.push(option);
    }
  }

  return nestedOptions;
};

export default getSubmissionsInterpretationsOptions;
