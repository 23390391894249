import styles from './TimestampPreview.module.css';

import React, { useEffect, useState } from 'react';

import EmptyPreview from './EmptyPreview';

import { safeFormatDistance } from 'helpers';

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const TimestampPreview = ({
  theme,
  value = null,
  placeholder,
  // Use instead of distance if date is in the past/future
  pastMessage = null,
  futureMessage = null
}) => {
  /* ---------------------------------- STATE --------------------------------- */

  const [distance, setDistance] = useState(null);

  /* ------------------------------ STATE UPDATES ----------------------------- */

  useEffect(() => {
    if (value) {
      const date = new Date(value);

      const refreshDistance = () => {
        const currentDate = new Date();

        if (pastMessage && isValidDate(date) && date <= currentDate) {
          return setDistance(pastMessage);
        } else if (futureMessage && isValidDate(date) && date > currentDate) {
          return setDistance(futureMessage);
        }

        return setDistance(safeFormatDistance(date, currentDate));
      };

      refreshDistance();

      // Refresh once per minute because date-fns returns distances
      // that can change at most once per 30 seconds
      const interval = setInterval(refreshDistance, 30 * 1000);
      return () => {
        clearInterval(interval);
      };
    } else {
      setDistance(null);
    }
  }, [value, pastMessage, futureMessage]);

  /* ----------------------------------- JSX ---------------------------------- */

  if (!value) {
    return <EmptyPreview theme={theme} placeholder={placeholder} />;
  } else {
    return (
      <div className={[styles.timestampPreview, styles[`theme-${theme}`]].join(' ')}>
        <span className={styles.timestamp}>{value}</span>
        {distance && <span className={styles.distance}>{distance}</span>}
      </div>
    );
  }
};

export default TimestampPreview;
