import styles from './PaginatedLogs.module.css';

import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ErrorFallback, SuspenseFallback, InlineBadge, Button, JSONTreeModal, Pagination } from 'ui-components';

import { useUserLogs, withQueryBoundary } from 'api';
import { safeFormatDate, safeFormatDistance } from 'helpers';

/* -------------------------------------------------------------------------- */
/*                                 SINGLE LOG                                 */
/* -------------------------------------------------------------------------- */

const Log = ({ log }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const history = useHistory();

  const { type, text, smtp, form, value, createdAt } = log;

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const parsedValue = useMemo(() => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }, [value]);

  const detailsJson = { ...log, value: parsedValue };
  delete detailsJson._id; // Id won't mean anything to user

  /* ----------------------------------- JSX ---------------------------------- */

  let logTagsJsx = null;
  let logTextJsx = text;
  let logAdditionalActionJsx = null;

  // If origin form is known add a button to open it
  if (form) {
    logAdditionalActionJsx = (
      <Button icon="open-external" size="small" theme="white" iconPlacement="right" onClick={() => history.push(`/form/${form}`)}>
        Edit form
      </Button>
    );
  }

  // Content of email logs
  if (type.indexOf('email') === 0) {
    logTagsJsx = (
      <>
        <InlineBadge theme="blue" outlined>
          {smtp === true ? 'SMTP Email' : 'QS Email'}
        </InlineBadge>
        {type === 'emailSent' && (
          <InlineBadge theme="green" outlined>
            Success
          </InlineBadge>
        )}
        {type === 'emailError' && (
          <InlineBadge theme="red" outlined>
            Error
          </InlineBadge>
        )}
      </>
    );
  }

  // Submissions action logs
  else if (type.indexOf('submissions') === 0) {
    logTagsJsx = (
      <>
        <InlineBadge theme="blue" outlined>
          Responses
        </InlineBadge>
        {type.indexOf('Updated') !== -1 && (
          <InlineBadge theme="purple" outlined>
            Updated
          </InlineBadge>
        )}
        {type.indexOf('Deleted') !== -1 && (
          <InlineBadge theme="orange" outlined>
            Deleted
          </InlineBadge>
        )}
      </>
    );
  }

  // Form action logs
  else if (type.indexOf('form') === 0) {
    logTagsJsx = (
      <>
        <InlineBadge theme="blue" outlined>
          Form
        </InlineBadge>
      </>
    );
  }

  // User action logs
  else if (type.indexOf('user') === 0) {
    logTagsJsx = (
      <>
        <InlineBadge theme="blue" outlined>
          User
        </InlineBadge>
      </>
    );
  }

  return (
    <>
      <JSONTreeModal
        title="Log details"
        data={detailsJson}
        isOpen={isPreviewOpen}
        onRequestClose={() => setIsPreviewOpen(false)}
        // Expand everything
        shouldExpandNode={() => {
          return true;
        }}
      />

      <div className={styles.log}>
        <div className={styles.logTags}>{logTagsJsx}</div>
        <div className={styles.logContent}>
          <div className={styles.logText}>{logTextJsx}</div>
          <div className={styles.logDate}>
            {safeFormatDate(createdAt)} - {safeFormatDistance(createdAt)}
          </div>
        </div>

        <div className={styles.logActions}>
          <Button theme="white" size="small" onClick={() => setIsPreviewOpen(true)}>
            Show Details
          </Button>
          {logAdditionalActionJsx}
        </div>
      </div>
    </>
  );
};

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const PAGE_SIZE = 100;

const PaginatedLogs = React.memo(({ filters }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const [page, setPage] = useState(1);

  const { data, isFetching } = useUserLogs({
    ...filters,
    page: page,
    pageSize: PAGE_SIZE,
    // Mark logs as checked if user visited first page of 'All' tab
    markLogsAsViewed: page === 1 && !filters?.types
  });

  const { totalItems, logs } = data;

  const pagesTotal = Math.max(Math.ceil(totalItems / PAGE_SIZE), 1);

  const firstLogIndex = 1 + (page - 1) * PAGE_SIZE;
  const lastLogIndex = Math.min(firstLogIndex + PAGE_SIZE - 1, totalItems);

  useEffect(() => {
    if (pagesTotal < page) {
      setPage(pagesTotal);
    }
  }, [page, pagesTotal]);

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <>
      <div className={[styles.logsOuterContainer, isFetching ? styles.loading : ''].join(' ')}>
        {isFetching && <SuspenseFallback />}

        <div className={styles.logsContainer}>
          {logs.map((log, i) => {
            return <Log key={log._id} log={log} />;
          })}
          {!totalItems && !isFetching && <div className={styles.placeholder}>There are no logs to show</div>}
        </div>
      </div>

      <div className={styles.logsControls}>
        <div>
          Showing logs {firstLogIndex}-{lastLogIndex} out of {totalItems} matched
        </div>
        <div>
          {pagesTotal > 1 && page >= 1 && page <= pagesTotal && (
            <>
              <Pagination value={page} onChange={setPage} max={pagesTotal} />
            </>
          )}
        </div>
      </div>
    </>
  );
});

export default withQueryBoundary(PaginatedLogs, {
  SuspenseFallbackComponent: () => (
    <div className={[styles.logsOuterContainer, styles.loading].join(' ')}>
      <SuspenseFallback />
    </div>
  ),
  ErrorFallbackComponent: ({ error }) => <ErrorFallback error={error} />
});
