import styles from './SomethingWentWrongBoundary.module.css';

import React, { useCallback, useEffect } from 'react';
import * as Sentry from '@sentry/browser';

import { Smol, Button, Modal } from 'ui-components';

import { useHelpScout } from 'integrations';

/*
    Error modal with special error boundary for global use as last resort
    for unhandled exceptions. Excepted exceptions (for example from
    queries) will be handled with local error boundaries.
*/

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

const parseError = (error) => {
  if (!error) return '';

  let name = error?.exception?.toString();

  let originUrl = error?.url || '';
  let nativeStack = error?.exception?.stack?.toString() || null;
  let componentStack = error?.componentStack || null;

  let message = name;

  if (originUrl) {
    message += `\nUrl: ${originUrl}`;
  }
  if (nativeStack) {
    nativeStack = nativeStack.replace(name, '');
    message += `\n\nJavaScript stack:${nativeStack}`;
  }
  if (componentStack) {
    componentStack = componentStack.replace(name, '');
    message += `\n\nReact stack:${componentStack}`;
  }

  return message;
};

/* -------------------------------------------------------------------------- */
/*                                MODAL CONTENT                               */
/* -------------------------------------------------------------------------- */

const SomethingWentWrong = ({ error, onRequestClearError }) => {
  const errorMessage = parseError(error);

  const { close: closeHelpScout, openReportIssue } = useHelpScout();

  const handleReportIssue = useCallback(() => {
    openReportIssue(errorMessage);
  }, [errorMessage, openReportIssue]);

  useEffect(
    () => {
      // hide on unmount
      closeHelpScout();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    Sentry.captureException(error.exception);
  }, [error.exception]);

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={styles.title}>This wasn’t supposed to happen</div>
        <div className={styles.description}> If this issue persists, please get in touch with us.</div>
      </div>

      <div className={styles.details}>
        <Smol.Collapse label="Show details">
          <div className={styles.message}>{errorMessage}</div>
        </Smol.Collapse>
      </div>

      <div className={styles.buttons}>
        <Button theme="white" onClick={handleReportIssue}>
          Report an issue
        </Button>
        <Button theme="black" onClick={onRequestClearError}>
          Try again
        </Button>
      </div>
    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                                  BOUNDARY                                  */
/* -------------------------------------------------------------------------- */

/*
    Special error boundary that also captures react context
    and location where error has happen.
*/
class SomethingWentWrongBoundary extends React.Component {
  /* ------------------------------- LIFE CYCLE ------------------------------- */

  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error) {
    return { error: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: {
        ...errorInfo,
        exception: error,
        url: document.location
      }
    });
  }

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  handleRequestClearError = () => {
    this.setState({ error: false });
  };

  /* --------------------------------- RENDER --------------------------------- */

  render() {
    const { children } = this.props;
    const { error } = this.state;

    return (
      <>
        <Modal theme="tight" isOpen={Boolean(error)}>
          <SomethingWentWrong error={error} onRequestClearError={this.handleRequestClearError} />
        </Modal>
        {error ? null : children}
      </>
    );
  }
}

export default SomethingWentWrongBoundary;
