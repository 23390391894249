import styles from './QueryBoundary.module.css';

import React, { useCallback, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';

/* -------------------------------------------------------------------------- */
/*                              HELPER COMPONENTS                             */
/* -------------------------------------------------------------------------- */

const defaultErrorFallbackRenderer = () => <div style={{ color: '#F05252' }}>Something went wrong!</div>;
const defaultSuspenseFallbackRenderer = () => <div style={{ color: '#B4B2AF' }}>Loading...</div>;

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const ErrorBoundaryWithQueryErrorReset = ({ onReset, ...rest }) => {
  const { reset: resetQueryErrors } = useQueryErrorResetBoundary();

  const handleOnResetError = useCallback(
    (...args) => {
      resetQueryErrors();
      onReset && onReset(...args);
    },
    [onReset, resetQueryErrors]
  );

  return <ErrorBoundary onReset={handleOnResetError} {...rest} />;
};

export const QueryBoundary = React.memo(
  ({
    // Fallbacks component/renderers/jsx (can be used interchangeably)
    // Components will be injected with additional props, renderers will receive them
    // and jsx fallback will just be shown
    ErrorFallbackComponent,
    errorFallbackRender,
    errorFallback,
    SuspenseFallbackComponent,
    suspenseFallbackRender,
    suspenseFallback,

    // Other react-error-boundary props
    onError,
    onResetError,
    onResetErrorKeysChange,
    resetErrorKeys,

    // Content
    children
  }) => {
    /* -------------------------------- RENDERERS ------------------------------- */

    let errorBoundaryRenderer = null;

    if (ErrorFallbackComponent) {
      errorBoundaryRenderer = ({ error, resetErrorBoundary }) => {
        return <ErrorFallbackComponent error={error} onResetErrorBoundary={resetErrorBoundary} />;
      };
    } else if (errorFallbackRender) {
      errorBoundaryRenderer = errorFallbackRender;
    } else if (typeof errorFallback !== 'undefined') {
      errorBoundaryRenderer = () => errorFallback;
    }

    let suspenseBoundaryRenderer = null;

    if (SuspenseFallbackComponent) {
      suspenseBoundaryRenderer = () => {
        return <SuspenseFallbackComponent />;
      };
    } else if (suspenseFallbackRender) {
      suspenseBoundaryRenderer = suspenseFallbackRender;
    } else if (typeof suspenseFallback !== 'undefined') {
      suspenseBoundaryRenderer = () => suspenseFallback;
    }

    /* ----------------------------------- JSX ---------------------------------- */

    const suspenseFallbackJsx = (suspenseBoundaryRenderer || defaultSuspenseFallbackRenderer)();

    return (
      <ErrorBoundaryWithQueryErrorReset
        fallbackRender={errorBoundaryRenderer || defaultErrorFallbackRenderer}
        onError={onError}
        onReset={onResetError}
        onResetKeysChange={onResetErrorKeysChange}
        resetKeys={resetErrorKeys}>
        <Suspense fallback={suspenseFallbackJsx}>{children}</Suspense>
      </ErrorBoundaryWithQueryErrorReset>
    );
  }
);

/* -------------------------------------------------------------------------- */
/*                                     HOC                                    */
/* -------------------------------------------------------------------------- */

export const withQueryBoundary = (Component, queryBoundaryProps) => (props) => {
  // By default will reset error when wrapped component props change
  return (
    <QueryBoundary resetErrorKeys={Object.values(props)} {...queryBoundaryProps}>
      <Component {...props} />
    </QueryBoundary>
  );
};
