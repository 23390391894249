import styles from './UrlBar.module.css';
import React from 'react';

import { Icon } from '../Icon/Icon.js';
import MacBar from '../MacBar/MacBar.js';

// Prevent paste and copy
const preventEvent = (e) => {
  e.preventDefault();
};
// Prevent input, only allow scrolling with arrows
const handleKeyDown = (e) => {
  const key = e.key;
  if (key !== 'ArrowRight' && key !== 'ArrowLeft') {
    e.preventDefault();
  }
};

const UrlBar = ({ className = '', children, scrollable = false, ...rest }) => {
  return (
    <MacBar
      className={[className, styles.urlBar].join(' ')}
      left={<Icon id="mac-window" />}
      right={
        <>
          <Icon id="mac-share" />
          <Icon id="mac-plus" />
          <Icon id="mac-stacked" />
        </>
      }
      {...rest}>
      <Icon id="mac-shield" />
      <div className={styles.urlFieldWrapper}>
        <div
          className={styles.urlField}
          contentEditable={scrollable}
          suppressContentEditableWarning={true}
          onPaste={preventEvent}
          onCut={preventEvent}
          onKeyDown={handleKeyDown}>
          {children}
        </div>
      </div>
    </MacBar>
  );
};

export default UrlBar;
