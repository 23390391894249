import styles from './ImagesPreview.module.css';

import React, { useState } from 'react';

import { Modal } from 'ui-components';

import EmptyPreview from './EmptyPreview';

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

const mapImageDataToProps = (value) => {
  if (!value) {
    return null;
  } else if (typeof value === 'string') {
    return { src: value };
  } else {
    return {
      src: value?.src || value?.url,
      alt: value?.alt || value?.label || value?.text,
      label: value?.label || value?.text
    };
  }
};

/* -------------------------------------------------------------------------- */
/*                                SINGLE IMAGE                                */
/* -------------------------------------------------------------------------- */

const ImagePreview = ({ src, alt, label }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        title={label ? <div style={{ textAlign: 'center' }}>{label || undefined}</div> : null}>
        <img className={styles.modalImage} src={src} alt={alt} />
      </Modal>
      <img className={styles.image} src={src} alt={alt} title={label} onClick={() => setIsOpen(true)} />
    </>
  );
};

/* -------------------------------------------------------------------------- */
/*                                 IMAGES LIST                                */
/* -------------------------------------------------------------------------- */

const ImagesPreview = ({ theme, value, placeholder }) => {
  if (value && !Array.isArray(value)) value = [value];

  if (!value?.length) {
    return <EmptyPreview theme={theme} placeholder={placeholder} />;
  } else {
    return (
      // Could be replaced with some carousel
      <div className={[styles.imagesPreview, styles[`theme-${theme}`]].join(' ')}>
        {value.map((data, i) => {
          const props = mapImageDataToProps(data);
          return <ImagePreview key={props.alt || i} {...props} />;
        })}
      </div>
    );
  }
};

export default ImagesPreview;
