import React from 'react';
import { QueryClientProvider } from 'react-query';

import { queryClient } from './queryClient';

/* -------------------------------- PROVIDER -------------------------------- */

export const QueryProvider = React.memo(({ children }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
});
