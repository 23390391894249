const address = [
  {
    _id: '5f916971df69f25306da4ee7',

    type: 'section',
    label: 'Address section',
    value: null,
    conditionsType: 'all',
    conditionsState: 'show',
    childrens: [
      '5f9169a7df69f25306da4eea',
      '5f9169a8df69f25306da4eec',
      '5f9169c8df69f25306da4ef6',
      '5f9169dedf69f25306da4eff',
      '5f916a349d9ac7ab89a1d1d1'
    ],
    conditions: [],
    section: 'root',
    valueMaxLength: 5000,
    options: [],
    position: 1
  },
  {
    _id: '5f9169a7df69f25306da4eea',

    type: 'shortText',
    label: '[{"type":"paragraph","children":[{"text":"Address Line 1"}]}]',
    value: null,
    conditionsType: 'all',
    conditionsState: 'show',
    childrens: [],
    conditions: [],
    section: '5f916971df69f25306da4ee7',
    valueMaxLength: 1000,
    options: [],
    position: 1
  },
  {
    _id: '5f9169d3df69f25306da4efb',

    type: 'shortText',
    label: '[{"type":"paragraph","children":[{"text":"City"}]}]',
    value: null,
    conditionsType: 'all',
    conditionsState: 'show',
    childrens: [],
    conditions: [],
    section: '5f916a349d9ac7ab89a1d1d1',
    valueMaxLength: 1000,
    options: [],
    position: 1
  },
  {
    _id: '5f9169a8df69f25306da4eec',

    type: 'shortText',
    label: '[{"type":"paragraph","children":[{"text":"Address Line 2"}]}]',
    value: null,
    conditionsType: 'all',
    conditionsState: 'show',
    childrens: [],
    conditions: [],
    section: '5f916971df69f25306da4ee7',
    valueMaxLength: 1000,
    options: [],
    position: 2
  },
  {
    _id: '5f916a179d9ac7ab89a1d1cb',

    type: 'shortText',
    label: '[{"type":"paragraph","children":[{"text":"ZIP Code"}]}]',
    value: null,
    conditionsType: 'all',
    conditionsState: 'show',
    childrens: [],
    conditions: [],
    section: '5f916a349d9ac7ab89a1d1d1',
    valueMaxLength: 1000,
    options: [],
    position: 2
  },
  {
    _id: '5f9169c8df69f25306da4ef6',

    type: 'dropdown',
    label: '[{"type":"paragraph","children":[{"text":"Country"}]}]',
    value: null,
    conditionsType: 'all',
    conditionsState: 'show',
    childrens: [],
    conditions: [],
    section: '5f916971df69f25306da4ee7',
    valueMaxLength: 300,
    differentValues: true,
    options: [
      {
        value: 'AF',
        _id: '5f916c7789e92a002daa98bf',
        label: 'Afghanistan'
      },
      {
        value: 'AX',
        _id: '5f916c7789e92a002daa98be',
        label: 'Aland Islands'
      },
      {
        value: 'AL',
        _id: '5f916c7789e92a002daa98bd',
        label: 'Albania'
      },
      {
        value: 'DZ',
        _id: '5f916c7789e92a002daa98bc',
        label: 'Algeria'
      },
      {
        value: 'AS',
        _id: '5f916c7789e92a002daa98bb',
        label: 'American Samoa'
      },
      {
        value: 'AD',
        _id: '5f916c7789e92a002daa98ba',
        label: 'Andorra'
      },
      {
        value: 'AO',
        _id: '5f916c7789e92a002daa98b9',
        label: 'Angola'
      },
      {
        value: 'AI',
        _id: '5f916c7789e92a002daa98b8',
        label: 'Anguilla'
      },
      {
        value: 'AQ',
        _id: '5f916c7789e92a002daa98b7',
        label: 'Antarctica'
      },
      {
        value: 'AG',
        _id: '5f916c7789e92a002daa98b6',
        label: 'Antigua And Barbuda'
      },
      {
        value: 'AR',
        _id: '5f916c7789e92a002daa98b5',
        label: 'Argentina'
      },
      {
        value: 'AM',
        _id: '5f916c7789e92a002daa98b4',
        label: 'Armenia'
      },
      {
        value: 'AW',
        _id: '5f916c7789e92a002daa98b3',
        label: 'Aruba'
      },
      {
        value: 'AU',
        _id: '5f916c7789e92a002daa98b2',
        label: 'Australia'
      },
      {
        value: 'AT',
        _id: '5f916c7789e92a002daa98b1',
        label: 'Austria'
      },
      {
        value: 'AZ',
        _id: '5f916c7789e92a002daa98b0',
        label: 'Azerbaijan'
      },
      {
        value: 'BS',
        _id: '5f916c7789e92a002daa98af',
        label: 'Bahamas'
      },
      {
        value: 'BH',
        _id: '5f916c7789e92a002daa98ae',
        label: 'Bahrain'
      },
      {
        value: 'BD',
        _id: '5f916c7789e92a002daa98ad',
        label: 'Bangladesh'
      },
      {
        value: 'BB',
        _id: '5f916c7789e92a002daa98ac',
        label: 'Barbados'
      },
      {
        value: 'BY',
        _id: '5f916c7789e92a002daa98ab',
        label: 'Belarus'
      },
      {
        value: 'BE',
        _id: '5f916c7789e92a002daa98aa',
        label: 'Belgium'
      },
      {
        value: 'BZ',
        _id: '5f916c7789e92a002daa98a9',
        label: 'Belize'
      },
      {
        value: 'BJ',
        _id: '5f916c7789e92a002daa98a8',
        label: 'Benin'
      },
      {
        value: 'BM',
        _id: '5f916c7789e92a002daa98a7',
        label: 'Bermuda'
      },
      {
        value: 'BT',
        _id: '5f916c7789e92a002daa98a6',
        label: 'Bhutan'
      },
      {
        value: 'BO',
        _id: '5f916c7789e92a002daa98a5',
        label: 'Bolivia'
      },
      {
        value: 'BA',
        _id: '5f916c7789e92a002daa98a4',
        label: 'Bosnia And Herzegovina'
      },
      {
        value: 'BW',
        _id: '5f916c7789e92a002daa98a3',
        label: 'Botswana'
      },
      {
        value: 'BV',
        _id: '5f916c7789e92a002daa98a2',
        label: 'Bouvet Island'
      },
      {
        value: 'BR',
        _id: '5f916c7789e92a002daa98a1',
        label: 'Brazil'
      },
      {
        value: 'IO',
        _id: '5f916c7789e92a002daa98a0',
        label: 'British Indian Ocean Territory'
      },
      {
        value: 'BN',
        _id: '5f916c7789e92a002daa989f',
        label: 'Brunei Darussalam'
      },
      {
        value: 'BG',
        _id: '5f916c7789e92a002daa989e',
        label: 'Bulgaria'
      },
      {
        value: 'BF',
        _id: '5f916c7789e92a002daa989d',
        label: 'Burkina Faso'
      },
      {
        value: 'BI',
        _id: '5f916c7789e92a002daa989c',
        label: 'Burundi'
      },
      {
        value: 'KH',
        _id: '5f916c7789e92a002daa989b',
        label: 'Cambodia'
      },
      {
        value: 'CM',
        _id: '5f916c7789e92a002daa989a',
        label: 'Cameroon'
      },
      {
        value: 'CA',
        _id: '5f916c7789e92a002daa9899',
        label: 'Canada'
      },
      {
        value: 'CV',
        _id: '5f916c7789e92a002daa9898',
        label: 'Cape Verde'
      },
      {
        value: 'KY',
        _id: '5f916c7789e92a002daa9897',
        label: 'Cayman Islands'
      },
      {
        value: 'CF',
        _id: '5f916c7789e92a002daa9896',
        label: 'Central African Republic'
      },
      {
        value: 'TD',
        _id: '5f916c7789e92a002daa9895',
        label: 'Chad'
      },
      {
        value: 'CL',
        _id: '5f916c7789e92a002daa9894',
        label: 'Chile'
      },
      {
        value: 'CN',
        _id: '5f916c7789e92a002daa9893',
        label: 'China'
      },
      {
        value: 'CX',
        _id: '5f916c7789e92a002daa9892',
        label: 'Christmas Island'
      },
      {
        value: 'CC',
        _id: '5f916c7789e92a002daa9891',
        label: 'Cocos (Keeling) Islands'
      },
      {
        value: 'CO',
        _id: '5f916c7789e92a002daa9890',
        label: 'Colombia'
      },
      {
        value: 'KM',
        _id: '5f916c7789e92a002daa988f',
        label: 'Comoros'
      },
      {
        value: 'CG',
        _id: '5f916c7789e92a002daa988e',
        label: 'Congo'
      },
      {
        value: 'CD',
        _id: '5f916c7789e92a002daa988d',
        label: 'Congo, Democratic Republic'
      },
      {
        value: 'CK',
        _id: '5f916c7789e92a002daa988c',
        label: 'Cook Islands'
      },
      {
        value: 'CR',
        _id: '5f916c7789e92a002daa988b',
        label: 'Costa Rica'
      },
      {
        value: 'CI',
        _id: '5f916c7789e92a002daa988a',
        label: "Cote D'Ivoire"
      },
      {
        value: 'HR',
        _id: '5f916c7789e92a002daa9889',
        label: 'Croatia'
      },
      {
        value: 'CU',
        _id: '5f916c7789e92a002daa9888',
        label: 'Cuba'
      },
      {
        value: 'CY',
        _id: '5f916c7789e92a002daa9887',
        label: 'Cyprus'
      },
      {
        value: 'CZ',
        _id: '5f916c7789e92a002daa9886',
        label: 'Czech Republic'
      },
      {
        value: 'DK',
        _id: '5f916c7789e92a002daa9885',
        label: 'Denmark'
      },
      {
        value: 'DJ',
        _id: '5f916c7789e92a002daa9884',
        label: 'Djibouti'
      },
      {
        value: 'DM',
        _id: '5f916c7789e92a002daa9883',
        label: 'Dominica'
      },
      {
        value: 'DO',
        _id: '5f916c7789e92a002daa9882',
        label: 'Dominican Republic'
      },
      {
        value: 'EC',
        _id: '5f916c7789e92a002daa9881',
        label: 'Ecuador'
      },
      {
        value: 'EG',
        _id: '5f916c7789e92a002daa9880',
        label: 'Egypt'
      },
      {
        value: 'SV',
        _id: '5f916c7789e92a002daa987f',
        label: 'El Salvador'
      },
      {
        value: 'GQ',
        _id: '5f916c7789e92a002daa987e',
        label: 'Equatorial Guinea'
      },
      {
        value: 'ER',
        _id: '5f916c7789e92a002daa987d',
        label: 'Eritrea'
      },
      {
        value: 'EE',
        _id: '5f916c7789e92a002daa987c',
        label: 'Estonia'
      },
      {
        value: 'ET',
        _id: '5f916c7789e92a002daa987b',
        label: 'Ethiopia'
      },
      {
        value: 'FK',
        _id: '5f916c7789e92a002daa987a',
        label: 'Falkland Islands (Malvinas)'
      },
      {
        value: 'FO',
        _id: '5f916c7789e92a002daa9879',
        label: 'Faroe Islands'
      },
      {
        value: 'FJ',
        _id: '5f916c7789e92a002daa9878',
        label: 'Fiji'
      },
      {
        value: 'FI',
        _id: '5f916c7789e92a002daa9877',
        label: 'Finland'
      },
      {
        value: 'FR',
        _id: '5f916c7789e92a002daa9876',
        label: 'France'
      },
      {
        value: 'GF',
        _id: '5f916c7789e92a002daa9875',
        label: 'French Guiana'
      },
      {
        value: 'PF',
        _id: '5f916c7789e92a002daa9874',
        label: 'French Polynesia'
      },
      {
        value: 'TF',
        _id: '5f916c7789e92a002daa9873',
        label: 'French Southern Territories'
      },
      {
        value: 'GA',
        _id: '5f916c7789e92a002daa9872',
        label: 'Gabon'
      },
      {
        value: 'GM',
        _id: '5f916c7789e92a002daa9871',
        label: 'Gambia'
      },
      {
        value: 'GE',
        _id: '5f916c7789e92a002daa9870',
        label: 'Georgia'
      },
      {
        value: 'DE',
        _id: '5f916c7789e92a002daa986f',
        label: 'Germany'
      },
      {
        value: 'GH',
        _id: '5f916c7789e92a002daa986e',
        label: 'Ghana'
      },
      {
        value: 'GI',
        _id: '5f916c7789e92a002daa986d',
        label: 'Gibraltar'
      },
      {
        value: 'GR',
        _id: '5f916c7789e92a002daa986c',
        label: 'Greece'
      },
      {
        value: 'GL',
        _id: '5f916c7789e92a002daa986b',
        label: 'Greenland'
      },
      {
        value: 'GD',
        _id: '5f916c7789e92a002daa986a',
        label: 'Grenada'
      },
      {
        value: 'GP',
        _id: '5f916c7789e92a002daa9869',
        label: 'Guadeloupe'
      },
      {
        value: 'GU',
        _id: '5f916c7789e92a002daa9868',
        label: 'Guam'
      },
      {
        value: 'GT',
        _id: '5f916c7789e92a002daa9867',
        label: 'Guatemala'
      },
      {
        value: 'GG',
        _id: '5f916c7789e92a002daa9866',
        label: 'Guernsey'
      },
      {
        value: 'GN',
        _id: '5f916c7789e92a002daa9865',
        label: 'Guinea'
      },
      {
        value: 'GW',
        _id: '5f916c7789e92a002daa9864',
        label: 'Guinea-Bissau'
      },
      {
        value: 'GY',
        _id: '5f916c7789e92a002daa9863',
        label: 'Guyana'
      },
      {
        value: 'HT',
        _id: '5f916c7789e92a002daa9862',
        label: 'Haiti'
      },
      {
        value: 'HM',
        _id: '5f916c7789e92a002daa9861',
        label: 'Heard Island & Mcdonald Islands'
      },
      {
        value: 'VA',
        _id: '5f916c7789e92a002daa9860',
        label: 'Holy See (Vatican City State)'
      },
      {
        value: 'HN',
        _id: '5f916c7789e92a002daa985f',
        label: 'Honduras'
      },
      {
        value: 'HK',
        _id: '5f916c7789e92a002daa985e',
        label: 'Hong Kong'
      },
      {
        value: 'HU',
        _id: '5f916c7789e92a002daa985d',
        label: 'Hungary'
      },
      {
        value: 'IS',
        _id: '5f916c7789e92a002daa985c',
        label: 'Iceland'
      },
      {
        value: 'IN',
        _id: '5f916c7789e92a002daa985b',
        label: 'India'
      },
      {
        value: 'ID',
        _id: '5f916c7789e92a002daa985a',
        label: 'Indonesia'
      },
      {
        value: 'IR',
        _id: '5f916c7789e92a002daa9859',
        label: 'Iran, Islamic Republic Of'
      },
      {
        value: 'IQ',
        _id: '5f916c7789e92a002daa9858',
        label: 'Iraq'
      },
      {
        value: 'IE',
        _id: '5f916c7789e92a002daa9857',
        label: 'Ireland'
      },
      {
        value: 'IM',
        _id: '5f916c7789e92a002daa9856',
        label: 'Isle Of Man'
      },
      {
        value: 'IL',
        _id: '5f916c7789e92a002daa9855',
        label: 'Israel'
      },
      {
        value: 'IT',
        _id: '5f916c7789e92a002daa9854',
        label: 'Italy'
      },
      {
        value: 'JM',
        _id: '5f916c7789e92a002daa9853',
        label: 'Jamaica'
      },
      {
        value: 'JP',
        _id: '5f916c7789e92a002daa9852',
        label: 'Japan'
      },
      {
        value: 'JE',
        _id: '5f916c7789e92a002daa9851',
        label: 'Jersey'
      },
      {
        value: 'JO',
        _id: '5f916c7789e92a002daa9850',
        label: 'Jordan'
      },
      {
        value: 'KZ',
        _id: '5f916c7789e92a002daa984f',
        label: 'Kazakhstan'
      },
      {
        value: 'KE',
        _id: '5f916c7789e92a002daa984e',
        label: 'Kenya'
      },
      {
        value: 'KI',
        _id: '5f916c7789e92a002daa984d',
        label: 'Kiribati'
      },
      {
        value: 'KR',
        _id: '5f916c7789e92a002daa984c',
        label: 'Korea'
      },
      {
        value: 'KW',
        _id: '5f916c7789e92a002daa984b',
        label: 'Kuwait'
      },
      {
        value: 'KG',
        _id: '5f916c7789e92a002daa984a',
        label: 'Kyrgyzstan'
      },
      {
        value: 'LA',
        _id: '5f916c7789e92a002daa9849',
        label: "Lao People's Democratic Republic"
      },
      {
        value: 'LV',
        _id: '5f916c7789e92a002daa9848',
        label: 'Latvia'
      },
      {
        value: 'LB',
        _id: '5f916c7789e92a002daa9847',
        label: 'Lebanon'
      },
      {
        value: 'LS',
        _id: '5f916c7789e92a002daa9846',
        label: 'Lesotho'
      },
      {
        value: 'LR',
        _id: '5f916c7789e92a002daa9845',
        label: 'Liberia'
      },
      {
        value: 'LY',
        _id: '5f916c7789e92a002daa9844',
        label: 'Libyan Arab Jamahiriya'
      },
      {
        value: 'LI',
        _id: '5f916c7789e92a002daa9843',
        label: 'Liechtenstein'
      },
      {
        value: 'LT',
        _id: '5f916c7789e92a002daa9842',
        label: 'Lithuania'
      },
      {
        value: 'LU',
        _id: '5f916c7789e92a002daa9841',
        label: 'Luxembourg'
      },
      {
        value: 'MO',
        _id: '5f916c7789e92a002daa9840',
        label: 'Macao'
      },
      {
        value: 'MK',
        _id: '5f916c7789e92a002daa983f',
        label: 'Macedonia'
      },
      {
        value: 'MG',
        _id: '5f916c7789e92a002daa983e',
        label: 'Madagascar'
      },
      {
        value: 'MW',
        _id: '5f916c7789e92a002daa983d',
        label: 'Malawi'
      },
      {
        value: 'MY',
        _id: '5f916c7789e92a002daa983c',
        label: 'Malaysia'
      },
      {
        value: 'MV',
        _id: '5f916c7789e92a002daa983b',
        label: 'Maldives'
      },
      {
        value: 'ML',
        _id: '5f916c7789e92a002daa983a',
        label: 'Mali'
      },
      {
        value: 'MT',
        _id: '5f916c7789e92a002daa9839',
        label: 'Malta'
      },
      {
        value: 'MH',
        _id: '5f916c7789e92a002daa9838',
        label: 'Marshall Islands'
      },
      {
        value: 'MQ',
        _id: '5f916c7789e92a002daa9837',
        label: 'Martinique'
      },
      {
        value: 'MR',
        _id: '5f916c7789e92a002daa9836',
        label: 'Mauritania'
      },
      {
        value: 'MU',
        _id: '5f916c7789e92a002daa9835',
        label: 'Mauritius'
      },
      {
        value: 'YT',
        _id: '5f916c7789e92a002daa9834',
        label: 'Mayotte'
      },
      {
        value: 'MX',
        _id: '5f916c7789e92a002daa9833',
        label: 'Mexico'
      },
      {
        value: 'FM',
        _id: '5f916c7789e92a002daa9832',
        label: 'Micronesia, Federated States Of'
      },
      {
        value: 'MD',
        _id: '5f916c7789e92a002daa9831',
        label: 'Moldova'
      },
      {
        value: 'MC',
        _id: '5f916c7789e92a002daa9830',
        label: 'Monaco'
      },
      {
        value: 'MN',
        _id: '5f916c7789e92a002daa982f',
        label: 'Mongolia'
      },
      {
        value: 'ME',
        _id: '5f916c7789e92a002daa982e',
        label: 'Montenegro'
      },
      {
        value: 'MS',
        _id: '5f916c7789e92a002daa982d',
        label: 'Montserrat'
      },
      {
        value: 'MA',
        _id: '5f916c7789e92a002daa982c',
        label: 'Morocco'
      },
      {
        value: 'MZ',
        _id: '5f916c7789e92a002daa982b',
        label: 'Mozambique'
      },
      {
        value: 'MM',
        _id: '5f916c7789e92a002daa982a',
        label: 'Myanmar'
      },
      {
        value: 'NA',
        _id: '5f916c7789e92a002daa9829',
        label: 'Namibia'
      },
      {
        value: 'NR',
        _id: '5f916c7789e92a002daa9828',
        label: 'Nauru'
      },
      {
        value: 'NP',
        _id: '5f916c7789e92a002daa9827',
        label: 'Nepal'
      },
      {
        value: 'NL',
        _id: '5f916c7789e92a002daa9826',
        label: 'Netherlands'
      },
      {
        value: 'AN',
        _id: '5f916c7789e92a002daa9825',
        label: 'Netherlands Antilles'
      },
      {
        value: 'NC',
        _id: '5f916c7789e92a002daa9824',
        label: 'New Caledonia'
      },
      {
        value: 'NZ',
        _id: '5f916c7789e92a002daa9823',
        label: 'New Zealand'
      },
      {
        value: 'NI',
        _id: '5f916c7789e92a002daa9822',
        label: 'Nicaragua'
      },
      {
        value: 'NE',
        _id: '5f916c7789e92a002daa9821',
        label: 'Niger'
      },
      {
        value: 'NG',
        _id: '5f916c7789e92a002daa9820',
        label: 'Nigeria'
      },
      {
        value: 'NU',
        _id: '5f916c7789e92a002daa981f',
        label: 'Niue'
      },
      {
        value: 'NF',
        _id: '5f916c7789e92a002daa981e',
        label: 'Norfolk Island'
      },
      {
        value: 'MP',
        _id: '5f916c7789e92a002daa981d',
        label: 'Northern Mariana Islands'
      },
      {
        value: 'NO',
        _id: '5f916c7789e92a002daa981c',
        label: 'Norway'
      },
      {
        value: 'OM',
        _id: '5f916c7789e92a002daa981b',
        label: 'Oman'
      },
      {
        value: 'PK',
        _id: '5f916c7789e92a002daa981a',
        label: 'Pakistan'
      },
      {
        value: 'PW',
        _id: '5f916c7789e92a002daa9819',
        label: 'Palau'
      },
      {
        value: 'PS',
        _id: '5f916c7789e92a002daa9818',
        label: 'Palestinian Territory, Occupied'
      },
      {
        value: 'PA',
        _id: '5f916c7789e92a002daa9817',
        label: 'Panama'
      },
      {
        value: 'PG',
        _id: '5f916c7789e92a002daa9816',
        label: 'Papua New Guinea'
      },
      {
        value: 'PY',
        _id: '5f916c7789e92a002daa9815',
        label: 'Paraguay'
      },
      {
        value: 'PE',
        _id: '5f916c7789e92a002daa9814',
        label: 'Peru'
      },
      {
        value: 'PH',
        _id: '5f916c7789e92a002daa9813',
        label: 'Philippines'
      },
      {
        value: 'PN',
        _id: '5f916c7789e92a002daa9812',
        label: 'Pitcairn'
      },
      {
        value: 'PL',
        _id: '5f916c7789e92a002daa9811',
        label: 'Poland'
      },
      {
        value: 'PT',
        _id: '5f916c7789e92a002daa9810',
        label: 'Portugal'
      },
      {
        value: 'PR',
        _id: '5f916c7789e92a002daa980f',
        label: 'Puerto Rico'
      },
      {
        value: 'QA',
        _id: '5f916c7789e92a002daa980e',
        label: 'Qatar'
      },
      {
        value: 'RE',
        _id: '5f916c7789e92a002daa980d',
        label: 'Reunion'
      },
      {
        value: 'RO',
        _id: '5f916c7789e92a002daa980c',
        label: 'Romania'
      },
      {
        value: 'RU',
        _id: '5f916c7789e92a002daa980b',
        label: 'Russian Federation'
      },
      {
        value: 'RW',
        _id: '5f916c7789e92a002daa980a',
        label: 'Rwanda'
      },
      {
        value: 'BL',
        _id: '5f916c7789e92a002daa9809',
        label: 'Saint Barthelemy'
      },
      {
        value: 'SH',
        _id: '5f916c7789e92a002daa9808',
        label: 'Saint Helena'
      },
      {
        value: 'KN',
        _id: '5f916c7789e92a002daa9807',
        label: 'Saint Kitts And Nevis'
      },
      {
        value: 'LC',
        _id: '5f916c7789e92a002daa9806',
        label: 'Saint Lucia'
      },
      {
        value: 'MF',
        _id: '5f916c7789e92a002daa9805',
        label: 'Saint Martin'
      },
      {
        value: 'PM',
        _id: '5f916c7789e92a002daa9804',
        label: 'Saint Pierre And Miquelon'
      },
      {
        value: 'VC',
        _id: '5f916c7789e92a002daa9803',
        label: 'Saint Vincent And Grenadines'
      },
      {
        value: 'WS',
        _id: '5f916c7789e92a002daa9802',
        label: 'Samoa'
      },
      {
        value: 'SM',
        _id: '5f916c7789e92a002daa9801',
        label: 'San Marino'
      },
      {
        value: 'ST',
        _id: '5f916c7789e92a002daa9800',
        label: 'Sao Tome And Principe'
      },
      {
        value: 'SA',
        _id: '5f916c7789e92a002daa97ff',
        label: 'Saudi Arabia'
      },
      {
        value: 'SN',
        _id: '5f916c7789e92a002daa97fe',
        label: 'Senegal'
      },
      {
        value: 'RS',
        _id: '5f916c7789e92a002daa97fd',
        label: 'Serbia'
      },
      {
        value: 'SC',
        _id: '5f916c7789e92a002daa97fc',
        label: 'Seychelles'
      },
      {
        value: 'SL',
        _id: '5f916c7789e92a002daa97fb',
        label: 'Sierra Leone'
      },
      {
        value: 'SG',
        _id: '5f916c7789e92a002daa97fa',
        label: 'Singapore'
      },
      {
        value: 'SK',
        _id: '5f916c7789e92a002daa97f9',
        label: 'Slovakia'
      },
      {
        value: 'SI',
        _id: '5f916c7789e92a002daa97f8',
        label: 'Slovenia'
      },
      {
        value: 'SB',
        _id: '5f916c7789e92a002daa97f7',
        label: 'Solomon Islands'
      },
      {
        value: 'SO',
        _id: '5f916c7789e92a002daa97f6',
        label: 'Somalia'
      },
      {
        value: 'ZA',
        _id: '5f916c7789e92a002daa97f5',
        label: 'South Africa'
      },
      {
        value: 'GS',
        _id: '5f916c7789e92a002daa97f4',
        label: 'South Georgia And Sandwich Isl.'
      },
      {
        value: 'ES',
        _id: '5f916c7789e92a002daa97f3',
        label: 'Spain'
      },
      {
        value: 'LK',
        _id: '5f916c7789e92a002daa97f2',
        label: 'Sri Lanka'
      },
      {
        value: 'SD',
        _id: '5f916c7789e92a002daa97f1',
        label: 'Sudan'
      },
      {
        value: 'SR',
        _id: '5f916c7789e92a002daa97f0',
        label: 'Suriname'
      },
      {
        value: 'SJ',
        _id: '5f916c7789e92a002daa97ef',
        label: 'Svalbard And Jan Mayen'
      },
      {
        value: 'SZ',
        _id: '5f916c7789e92a002daa97ee',
        label: 'Swaziland'
      },
      {
        value: 'SE',
        _id: '5f916c7789e92a002daa97ed',
        label: 'Sweden'
      },
      {
        value: 'CH',
        _id: '5f916c7789e92a002daa97ec',
        label: 'Switzerland'
      },
      {
        value: 'SY',
        _id: '5f916c7789e92a002daa97eb',
        label: 'Syrian Arab Republic'
      },
      {
        value: 'TW',
        _id: '5f916c7789e92a002daa97ea',
        label: 'Taiwan'
      },
      {
        value: 'TJ',
        _id: '5f916c7789e92a002daa97e9',
        label: 'Tajikistan'
      },
      {
        value: 'TZ',
        _id: '5f916c7789e92a002daa97e8',
        label: 'Tanzania'
      },
      {
        value: 'TH',
        _id: '5f916c7789e92a002daa97e7',
        label: 'Thailand'
      },
      {
        value: 'TL',
        _id: '5f916c7789e92a002daa97e6',
        label: 'Timor-Leste'
      },
      {
        value: 'TG',
        _id: '5f916c7789e92a002daa97e5',
        label: 'Togo'
      },
      {
        value: 'TK',
        _id: '5f916c7789e92a002daa97e4',
        label: 'Tokelau'
      },
      {
        value: 'TO',
        _id: '5f916c7789e92a002daa97e3',
        label: 'Tonga'
      },
      {
        value: 'TT',
        _id: '5f916c7789e92a002daa97e2',
        label: 'Trinidad And Tobago'
      },
      {
        value: 'TN',
        _id: '5f916c7789e92a002daa97e1',
        label: 'Tunisia'
      },
      {
        value: 'TR',
        _id: '5f916c7789e92a002daa97e0',
        label: 'Turkey'
      },
      {
        value: 'TM',
        _id: '5f916c7789e92a002daa97df',
        label: 'Turkmenistan'
      },
      {
        value: 'TC',
        _id: '5f916c7789e92a002daa97de',
        label: 'Turks And Caicos Islands'
      },
      {
        value: 'TV',
        _id: '5f916c7789e92a002daa97dd',
        label: 'Tuvalu'
      },
      {
        value: 'UG',
        _id: '5f916c7789e92a002daa97dc',
        label: 'Uganda'
      },
      {
        value: 'UA',
        _id: '5f916c7789e92a002daa97db',
        label: 'Ukraine'
      },
      {
        value: 'AE',
        _id: '5f916c7789e92a002daa97da',
        label: 'United Arab Emirates'
      },
      {
        value: 'GB',
        _id: '5f916c7789e92a002daa97d9',
        label: 'United Kingdom'
      },
      {
        value: 'US',
        _id: '5f916c7789e92a002daa97d8',
        label: 'United States'
      },
      {
        value: 'UM',
        _id: '5f916c7789e92a002daa97d7',
        label: 'United States Outlying Islands'
      },
      {
        value: 'UY',
        _id: '5f916c7789e92a002daa97d6',
        label: 'Uruguay'
      },
      {
        value: 'UZ',
        _id: '5f916c7789e92a002daa97d5',
        label: 'Uzbekistan'
      },
      {
        value: 'VU',
        _id: '5f916c7789e92a002daa97d4',
        label: 'Vanuatu'
      },
      {
        value: 'VE',
        _id: '5f916c7789e92a002daa97d3',
        label: 'Venezuela'
      },
      {
        value: 'VN',
        _id: '5f916c7789e92a002daa97d2',
        label: 'Viet Nam'
      },
      {
        value: 'VG',
        _id: '5f916c7789e92a002daa97d1',
        label: 'Virgin Islands, British'
      },
      {
        value: 'VI',
        _id: '5f916c7789e92a002daa97d0',
        label: 'Virgin Islands, U.S.'
      },
      {
        value: 'WF',
        _id: '5f916c7789e92a002daa97cf',
        label: 'Wallis And Futuna'
      },
      {
        value: 'EH',
        _id: '5f916c7789e92a002daa97ce',
        label: 'Western Sahara'
      },
      {
        value: 'YE',
        _id: '5f916c7789e92a002daa97cd',
        label: 'Yemen'
      },
      {
        value: 'ZM',
        _id: '5f916c7789e92a002daa97cc',
        label: 'Zambia'
      },
      {
        value: 'ZW',
        _id: '5f916c7789e92a002daa97cb',
        label: 'Zimbabwe'
      }
    ],
    position: 3
  },
  {
    _id: '5f9169dedf69f25306da4eff',

    type: 'dropdown',
    label: '[{"type":"paragraph","children":[{"text":"State"}]}]',
    value: null,
    conditionsType: 'all',
    conditionsState: 'show',
    childrens: [],
    conditions: [
      {
        _id: '5f916ac29d9ac7ab89a1d1f4',
        triggerField: '5f9169c8df69f25306da4ef6',
        type: 'equal',
        value: '5f916c7789e92a002daa97d8'
      }
    ],
    section: '5f916971df69f25306da4ee7',
    valueMaxLength: 300,
    differentValues: true,
    options: [
      {
        value: 'AL',
        _id: '5f916ab289e92a002daa7b2f',
        label: 'Alabama'
      },
      {
        value: 'AK',
        _id: '5f916ab289e92a002daa7b2e',
        label: 'Alaska'
      },
      {
        value: 'AS',
        _id: '5f916ab289e92a002daa7b2d',
        label: 'American Samoa'
      },
      {
        value: 'AZ',
        _id: '5f916ab289e92a002daa7b2c',
        label: 'Arizona'
      },
      {
        value: 'AR',
        _id: '5f916ab289e92a002daa7b2b',
        label: 'Arkansas'
      },
      {
        value: 'CA',
        _id: '5f916ab289e92a002daa7b2a',
        label: 'California'
      },
      {
        value: 'CO',
        _id: '5f916ab289e92a002daa7b29',
        label: 'Colorado'
      },
      {
        value: 'CT',
        _id: '5f916ab289e92a002daa7b28',
        label: 'Connecticut'
      },
      {
        value: 'DE',
        _id: '5f916ab289e92a002daa7b27',
        label: 'Delaware'
      },
      {
        value: 'DC',
        _id: '5f916ab289e92a002daa7b26',
        label: 'District Of Columbia'
      },
      {
        value: 'FM',
        _id: '5f916ab289e92a002daa7b25',
        label: 'Federated States Of Micronesia'
      },
      {
        value: 'FL',
        _id: '5f916ab289e92a002daa7b24',
        label: 'Florida'
      },
      {
        value: 'GA',
        _id: '5f916ab289e92a002daa7b23',
        label: 'Georgia'
      },
      {
        value: 'GU',
        _id: '5f916ab289e92a002daa7b22',
        label: 'Guam'
      },
      {
        value: 'HI',
        _id: '5f916ab289e92a002daa7b21',
        label: 'Hawaii'
      },
      {
        value: 'ID',
        _id: '5f916ab289e92a002daa7b20',
        label: 'Idaho'
      },
      {
        value: 'IL',
        _id: '5f916ab289e92a002daa7b1f',
        label: 'Illinois'
      },
      {
        value: 'IN',
        _id: '5f916ab289e92a002daa7b1e',
        label: 'Indiana'
      },
      {
        value: 'IA',
        _id: '5f916ab289e92a002daa7b1d',
        label: 'Iowa'
      },
      {
        value: 'KS',
        _id: '5f916ab289e92a002daa7b1c',
        label: 'Kansas'
      },
      {
        value: 'KY',
        _id: '5f916ab289e92a002daa7b1b',
        label: 'Kentucky'
      },
      {
        value: 'LA',
        _id: '5f916ab289e92a002daa7b1a',
        label: 'Louisiana'
      },
      {
        value: 'ME',
        _id: '5f916ab289e92a002daa7b19',
        label: 'Maine'
      },
      {
        value: 'MH',
        _id: '5f916ab289e92a002daa7b18',
        label: 'Marshall Islands'
      },
      {
        value: 'MD',
        _id: '5f916ab289e92a002daa7b17',
        label: 'Maryland'
      },
      {
        value: 'MA',
        _id: '5f916ab289e92a002daa7b16',
        label: 'Massachusetts'
      },
      {
        value: 'MI',
        _id: '5f916ab289e92a002daa7b15',
        label: 'Michigan'
      },
      {
        value: 'MN',
        _id: '5f916ab289e92a002daa7b14',
        label: 'Minnesota'
      },
      {
        value: 'MS',
        _id: '5f916ab289e92a002daa7b13',
        label: 'Mississippi'
      },
      {
        value: 'MO',
        _id: '5f916ab289e92a002daa7b12',
        label: 'Missouri'
      },
      {
        value: 'MT',
        _id: '5f916ab289e92a002daa7b11',
        label: 'Montana'
      },
      {
        value: 'NE',
        _id: '5f916ab289e92a002daa7b10',
        label: 'Nebraska'
      },
      {
        value: 'NV',
        _id: '5f916ab289e92a002daa7b0f',
        label: 'Nevada'
      },
      {
        value: 'NH',
        _id: '5f916ab289e92a002daa7b0e',
        label: 'New Hampshire'
      },
      {
        value: 'NJ',
        _id: '5f916ab289e92a002daa7b0d',
        label: 'New Jersey'
      },
      {
        value: 'NM',
        _id: '5f916ab289e92a002daa7b0c',
        label: 'New Mexico'
      },
      {
        value: 'NY',
        _id: '5f916ab289e92a002daa7b0b',
        label: 'New York'
      },
      {
        value: 'NC',
        _id: '5f916ab289e92a002daa7b0a',
        label: 'North Carolina'
      },
      {
        value: 'ND',
        _id: '5f916ab289e92a002daa7b09',
        label: 'North Dakota'
      },
      {
        value: 'MP',
        _id: '5f916ab289e92a002daa7b08',
        label: 'Northern Mariana Islands'
      },
      {
        value: 'OH',
        _id: '5f916ab289e92a002daa7b07',
        label: 'Ohio'
      },
      {
        value: 'OK',
        _id: '5f916ab289e92a002daa7b06',
        label: 'Oklahoma'
      },
      {
        value: 'OR',
        _id: '5f916ab289e92a002daa7b05',
        label: 'Oregon'
      },
      {
        value: 'PW',
        _id: '5f916ab289e92a002daa7b04',
        label: 'Palau'
      },
      {
        value: 'PA',
        _id: '5f916ab289e92a002daa7b03',
        label: 'Pennsylvania'
      },
      {
        value: 'PR',
        _id: '5f916ab289e92a002daa7b02',
        label: 'Puerto Rico'
      },
      {
        value: 'RI',
        _id: '5f916ab289e92a002daa7b01',
        label: 'Rhode Island'
      },
      {
        value: 'SC',
        _id: '5f916ab289e92a002daa7b00',
        label: 'South Carolina'
      },
      {
        value: 'SD',
        _id: '5f916ab289e92a002daa7aff',
        label: 'South Dakota'
      },
      {
        value: 'TN',
        _id: '5f916ab289e92a002daa7afe',
        label: 'Tennessee'
      },
      {
        value: 'TX',
        _id: '5f916ab289e92a002daa7afd',
        label: 'Texas'
      },
      {
        value: 'UT',
        _id: '5f916ab289e92a002daa7afc',
        label: 'Utah'
      },
      {
        value: 'VT',
        _id: '5f916ab289e92a002daa7afb',
        label: 'Vermont'
      },
      {
        value: 'VI',
        _id: '5f916ab289e92a002daa7afa',
        label: 'Virgin Islands'
      },
      {
        value: 'VA',
        _id: '5f916ab289e92a002daa7af9',
        label: 'Virginia'
      },
      {
        value: 'WA',
        _id: '5f916ab289e92a002daa7af8',
        label: 'Washington'
      },
      {
        value: 'WV',
        _id: '5f916ab289e92a002daa7af7',
        label: 'West Virginia'
      },
      {
        value: 'WI',
        _id: '5f916ab289e92a002daa7af6',
        label: 'Wisconsin'
      },
      {
        value: 'WY',
        _id: '5f916ab289e92a002daa7af5',
        label: 'Wyoming'
      }
    ],
    position: 4
  },
  {
    _id: '5f916a349d9ac7ab89a1d1d1',

    type: 'section',
    label: 'City and ZIP section',
    value: null,
    conditionsType: 'all',
    conditionsState: 'show',
    childrens: ['5f9169d3df69f25306da4efb', '5f916a179d9ac7ab89a1d1cb'],
    conditions: [],
    section: '5f916971df69f25306da4ee7',
    valueMaxLength: 5000,
    options: [],
    position: 5,
    columnSizes: [70, 30],
    columns: 2
  }
];

export default address;
