import styles from './PaymentStatus.module.css';

import React, { useState } from 'react';
import format from 'date-fns/format';

import { Modal } from '../Modal/Modal';

import icoPaypal from 'assets/images/services/paypal.png';
import icoMollie from 'assets/images/services/mollie.png';
import icoSquare from 'assets/images/services/square.png';
import icoStripe from 'assets/images/services/stripe.png';

/* -------------------------------------------------------------------------- */
/*                              HELPER COMPONENTS                             */
/* -------------------------------------------------------------------------- */

const Summary = ({ status, amountTotal, amountCurrency, taxAmount, taxCurrency }) => {
  const parseValue = (value) => Math.round(Number(value) * 100) / 100;
  const parseCurrency = (currency) => String(currency).toUpperCase();

  return (
    <div className={styles.summary}>
      {amountTotal ? (
        <>
          <span className={styles.summaryAmount}>
            {parseValue(amountTotal)} {parseCurrency(amountCurrency)}
          </span>
          {parseValue(taxAmount) > 0 && (
            <span className={styles.summaryTax}>
              (includes {parseValue(taxAmount)} {parseCurrency(taxCurrency)} tax)
            </span>
          )}
        </>
      ) : status ? (
        <span style={{ opacity: 0.5, fontWeight: 600 }}>{String(status)}</span>
      ) : (
        <span style={{ opacity: 0.5, fontWeight: 600, whiteSpace: 'nowrap' }}>[Status unknown]</span>
      )}
    </div>
  );
};

const Details = ({ children }) => {
  return <ul className={styles.details}>{children}</ul>;
};

const DetailsRow = ({ label, value }) => {
  return (
    <li className={styles.detailsRow}>
      <div className={styles.detailsRowLabel}>{label}</div>
      <div className={styles.detailsRowValue}>{value}</div>
    </li>
  );
};

const DetailsHr = () => <li className={styles.detailsHr} />;

const DetailsPaymentProvider = ({ name, icon }) => {
  return (
    <li className={styles.detailsPaymentProviderRow}>
      <img className={styles.detailsRowProviderIcon} src={icon} alt={name + '-icon'} />
      <div className={styles.detailsPaymentProviderText}>
        <div className={styles.detailsRowProviderLabel}>Payment Provider </div>
        <div className={styles.detailsRowProviderName}>{name}</div>
      </div>
    </li>
  );
};

/* -------------------------------------------------------------------------- */
/*                             PROVIDER COMPONENTS                            */
/* -------------------------------------------------------------------------- */

/* --------------------------------- PAYPAL --------------------------------- */

const PayPalDetails = (props) => {
  return (
    <Details>
      <DetailsPaymentProvider name="PayPal" icon={icoPaypal} />
      <DetailsHr />
      <DetailsRow label="Status" value={props.paymentStatus ?? 'Unknown'} />

      {props.pendingReason && <DetailsRow label="Pending Reason" value={props.pendingReason} />}

      {props.mcGross && <DetailsRow label="Gross Payment" value={`${props.mcGross} ${props.mcCurrency}`} />}
      {props.tax && <DetailsRow label="Taxes Paid" value={`${props.tax} ${props.mcCurrency}`} />}
      {props.mcFee && <DetailsRow label="Fee Paid" value={`${props.mcFee} ${props.mcCurrency}`} />}

      {props.payerEmail && <DetailsRow label="Purchaser Email" value={props.payerEmail} />}
    </Details>
  );
};

const PayPalSummary = (props) => {
  return (
    <Summary
      amountTotal={props.mcGross}
      amountCurrency={props.mcCurrency}
      taxAmount={props.tax}
      taxCurrency={props.mcCurrency}
      status={props.paymentStatus}
    />
  );
};

/* --------------------------------- STRIPE --------------------------------- */

const StripeDetails = (props) => {
  return (
    <Details>
      <DetailsPaymentProvider name="Stripe" icon={icoStripe} />
      <DetailsHr />
      <DetailsRow label="Status" value={props.paymentStatus ?? 'Unknown'} />

      {props.paymentIntent && <DetailsRow label="Payment Intent" value={props.paymentIntent} />}

      {props.amountTotal && (
        <DetailsRow label="Gross Payment" value={`${Number(props.amountTotal) / 100} ${String(props.currency).toUpperCase()}`} />
      )}
      {props.tax && <DetailsRow label="Taxes Paid" value={`${Number(props.tax) / 100} ${String(props.currency).toUpperCase()}`} />}

      {props.customerEmail && <DetailsRow label="Customer Email" value={props.customerEmail} />}
      {props.customer && <DetailsRow label="Customer ID" value={props.customer} />}
    </Details>
  );
};

const StripeSummary = (props) => {
  return (
    <Summary
      amountTotal={Number(props.amountTotal) / 100}
      amountCurrency={props.currency}
      taxAmount={Number(props.tax) / 100}
      taxCurrency={props.currency}
      status={props.paymentStatus}
    />
  );
};

/* --------------------------------- MOLLIE --------------------------------- */

const MollieDetails = (props = {}) => {
  const parseDate = (date) => format(new Date(date), 'dd-MM-yyyy hh:mma');
  const parseAmount = (amountObj) => `${amountObj.value} ${String(amountObj.currency).toUpperCase()}`;

  return (
    <Details>
      <DetailsPaymentProvider name="Mollie" icon={icoMollie} />
      <DetailsHr />
      <DetailsRow label="Status" value={props.status ?? 'Unknown'} />

      {props.amount?.value && <DetailsRow label="Amount" value={parseAmount(props.amount)} />}
      {props.taxAmount?.value && <DetailsRow label="Tax Amount" value={parseAmount(props.taxAmount)} />}
      {props.amountRefunded?.value && <DetailsRow label="Refunded Amount" value={parseAmount(props.amountRefunded)} />}
      {props.amountRemaining?.value && <DetailsRow label="Remaining Amount" value={parseAmount(props.amountRemaining)} />}
      {props.settlementAmount?.value && <DetailsRow label="Settlement Amount" value={parseAmount(props.settlementAmount)} />}

      {props.id && <DetailsRow label="Payment ID" value={props.id} />}
      {props.method && <DetailsRow label="Method" value={props.method} />}
      {props.mode && <DetailsRow label="Mode" value={props.mode} />}
      {props.createdAt && <DetailsRow label="Created At" value={parseDate(props.createdAt)} />}
      {props.paidAt && <DetailsRow label="Paid At" value={parseDate(props.paidAt)} />}
    </Details>
  );
};

const MollieSummary = (props) => {
  return (
    <Summary
      amountTotal={props.amount?.value}
      amountCurrency={props.amount?.currency}
      taxAmount={props.taxAmount?.value}
      taxCurrency={props.taxAmount?.currency}
      status={props.status}
    />
  );
};

/* ------------------------------ SQUARE STATUS ----------------------------- */

const SquareDetails = (props) => {
  const parseDate = (date) => format(new Date(date), 'dd-MM-yyyy hh:mma');
  const parseMoney = (moneyObj) => `${parseFloat(moneyObj.amount) / 100} ${String(moneyObj.currency).toUpperCase()}`;

  return (
    <Details>
      <DetailsPaymentProvider name="Square" icon={icoSquare} />
      <DetailsHr />
      <DetailsRow label="Status" value={props.status ?? 'Unknown'} />

      {props.amountMoney?.amount && <DetailsRow label="Amount Money" value={parseMoney(props.amountMoney)} />}
      {props.taxMoney?.amount && <DetailsRow label="Tax Money" value={parseMoney(props.taxMoney)} />}
      {props.totalMoney?.amount && <DetailsRow label="Total Money" value={parseMoney(props.totalMoney)} />}
      {props.refundedMoney?.amount && <DetailsRow label="Refunded Money" value={parseMoney(props.refundedMoney)} />}

      {props.receiptNumber && <DetailsRow label="Receipt Number" value={props.receiptNumber} />}
      {props.sourceType && <DetailsRow label="Source Type" value={props.sourceType} />}
      {props.createdAt && <DetailsRow label="Created At" value={parseDate(props.createdAt)} />}
      {props.updatedAt && <DetailsRow label="Updated At" value={parseDate(props.updatedAt)} />}
    </Details>
  );
};

const SquareSummary = (props) => {
  return (
    <Summary
      amountTotal={props.totalMoney?.amount}
      amountCurrency={props.totalMoney?.currency}
      taxAmount={props.taxMoney?.amount}
      taxCurrency={props.taxMoney?.currency}
      status={props.status}
    />
  );
};

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const PROVIDERS = {
  paypal: {
    SummaryComponent: PayPalSummary,
    DetailsComponent: PayPalDetails
  },
  stripe: {
    SummaryComponent: StripeSummary,
    DetailsComponent: StripeDetails
  },
  mollie: {
    SummaryComponent: MollieSummary,
    DetailsComponent: MollieDetails
  },
  square: {
    SummaryComponent: SquareSummary,
    DetailsComponent: SquareDetails
  }
};

const PaymentStatus = React.memo(({ className = '', provider, data, compact = true, detailsHeader = 'Payment details' }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!PROVIDERS[provider]) {
    return `[Provider '${provider}' not supported]`;
  }

  const { DetailsComponent, SummaryComponent } = PROVIDERS[provider];

  return (
    <div className={[className, styles.paymentStatus].join(' ')}>
      {compact ? (
        <>
          <Modal
            title={<span className={styles.modalTitle}>{detailsHeader}</span>}
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}>
            <div style={{ height: 15 }} />
            <DetailsComponent {...data} />
          </Modal>
          <SummaryComponent {...data} />
          <button className={styles.showDetails} onClick={() => setIsModalOpen(true)}>
            (Show details)
          </button>
        </>
      ) : (
        <DetailsComponent {...data} />
      )}
    </div>
  );
});

export default PaymentStatus;
