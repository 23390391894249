import validateFormElementLogic from './validateFormElementLogic';

/* -------------------------------------------------------------------------- */
/*                                   CONFIG                                   */
/* -------------------------------------------------------------------------- */

// NICE TO HAVE TODO: move these somewhere in config
const CLASSIC_FORM_ONLY_FIELDS = ['divider', 'pageBreak'];
const FIELDS_WITH_SELECTION_LIMITS = ['checkbox', 'imageChoice'];

/* -------------------------------------------------------------------------- */
/*                             PARTIAL VALIDATORS                             */
/* -------------------------------------------------------------------------- */

const validateFieldWithSelectionLimits = ({ field }) => {
  const errors = {};

  if (FIELDS_WITH_SELECTION_LIMITS.indexOf(field.type) !== -1) {
    let { required, selectionLimits, selectionLimitsMin, selectionLimitsMax } = field;

    if (typeof selectionLimitsMin === 'string') selectionLimitsMin = Number(selectionLimitsMin);
    if (typeof selectionLimitsMax === 'string') selectionLimitsMax = Number(selectionLimitsMax);

    if (required && !selectionLimits) {
      errors.selectionLimits = 'You need to define selection limits in a required field.';
    }
    if (required && selectionLimits && !selectionLimitsMin) {
      errors.selectionLimitsMin = 'Lower selection limit has to be greater than zero in a required field.';
    }
    if (!required && selectionLimits && selectionLimitsMin) {
      errors.selectionLimitsMin = 'Lower selection limit has to be equal to zero in a non required field.';
    }

    if (selectionLimits) {
      if (!Number.isInteger(selectionLimitsMin) || selectionLimitsMin < 0) {
        errors.selectionLimitsMin = 'Lower selection limit has to be a valid non negative integer.';
      }
      if (
        selectionLimitsMax !== null &&
        (!Number.isInteger(selectionLimitsMax) || selectionLimitsMax <= 0 || selectionLimitsMax < selectionLimitsMin)
      ) {
        errors.selectionLimitsMax = 'Upper selection limit needs to be empty or a valid positive integer greater than lower limit.';
      }
    }
  }

  return Object.keys(errors).length ? errors : false;
};

/* -------------------------------------------------------------------------- */
/*                               MAIN VALIDATOR                               */
/* -------------------------------------------------------------------------- */

const validateFormFields = ({ formType, fields, calculationVariables }) => {
  if (!fields || !calculationVariables) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('DEBUG: missing data.');
    }
  }

  const errors = {};

  for (const field of fields) {
    // Checks logic and calculations errors
    const fieldErrors = {
      ...validateFormElementLogic({ element: field, fields, calculationVariables }),
      ...validateFieldWithSelectionLimits({ field })
    };

    // Checks if field is allowed in current form type
    if (formType !== 'classic' && CLASSIC_FORM_ONLY_FIELDS.indexOf(field.type) !== -1) {
      fieldErrors.type = 'Field of this type is supported only in traditional forms.';
    }

    // Page breaks can't be used inside sections
    if (field.type === 'pageBreak' && field.section && field.section !== 'root') {
      fieldErrors.type = 'Page Break should not be placed inside a section.';
    }

    if (Object.keys(fieldErrors).length) {
      errors[field._id] = fieldErrors;
    }
  }

  return Object.keys(errors).length ? errors : false;
};

export default validateFormFields;
