import React from 'react';
import getFieldConfig from './getFieldConfig';

const getFieldIcon = (fieldType, fieldFormat) => {
  // Returns result
  const fieldId = fieldType + (fieldFormat || '');
  const icon = getFieldConfig(fieldId)?.icon || null;

  if (process.env.NODE_ENV === 'development') {
    if (!icon) {
      throw new Error('DEBUG: icon for field not found, Provided data: ' + JSON.stringify({ type: fieldType, format: fieldFormat }, 0, 2));
    }
  }

  return <img src={icon} alt={fieldId + '-icon'} height="12px" width="12px" />;
};

export default getFieldIcon;
