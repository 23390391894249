import styles from './Skeleton.module.css';

import React, { useState, useEffect, useCallback, useMemo } from 'react';

import {} from 'helpers';

const Skeleton = ({ className = '', variant = 1, animated = false }) => {
  let skeletonJsx = null;

  switch (variant) {
    case 1:
      skeletonJsx = (
        <>
          <div className={styles.verticalLine} style={{ width: '100%' }} />
          <div className={styles.verticalLine} style={{ width: '30%' }} />
        </>
      );
      break;
    case 2:
      skeletonJsx = (
        <>
          <div className={styles.verticalLine} style={{ width: '50%' }} />
          <div className={styles.verticalLine} style={{ width: '100%' }} />
        </>
      );
      break;

    case 3:
      skeletonJsx = (
        <>
          <div
            className={styles.verticalLineThin}
            data-animated={animated}
            style={{ opacity: 0.6, width: `${Math.random() * 10 + 5}ch` }}
          />
          {Math.random() > 0.3 ? (
            <div
              className={styles.verticalLineThin}
              data-animated={animated}
              style={{ opacity: 0.6, width: `${Math.random() * 10 + 5}ch` }}
            />
          ) : null}
          {Math.random() > 0.8 ? (
            <div
              className={styles.verticalLineThin}
              data-animated={animated}
              style={{ opacity: 0.6, width: `${Math.random() * 10 + 5}ch` }}
            />
          ) : null}
        </>
      );
      break;
    default:
      skeletonJsx = null;
  }

  return <div className={[className, styles.skeleton].join(' ')}>{skeletonJsx}</div>;
};

export default Skeleton;
