import React from 'react';
import getFieldIcon from './getFieldIcon';

import { Icon, FilteringMatrix, SortingMatrix } from 'ui-components';

/* -------------------------------------------------------------------------- */
/*                                 MAIN LOGIC                                 */
/* -------------------------------------------------------------------------- */

const _identifySubmissionField = ({ columnId, type, format }) => {
  if (process.env.NODE_ENV === 'development') {
    if (typeof columnId === 'undefined' || typeof type === 'undefined' || typeof format === 'undefined') {
      throw new Error(`DEBUG: missing arguments. ${columnId}, ${type}, ${format}`);
    }
  }

  // If type is static or dynamic, field is a submission only field that doesn't exist in form
  const isSubmissionOnlyField = type === 'static' || type === 'dynamic';
  // True type of submission only fields is hidden in columnId
  const uniqueType = isSubmissionOnlyField ? columnId : type;

  const commonProps = {
    type: uniqueType,
    icon: isSubmissionOnlyField ? <Icon id="system" /> : getFieldIcon(type, format)
  };

  switch (uniqueType) {
    case '_id':
      return { sortsAs: 'string', filtersAs: 'string', rendersAs: 'text', ...commonProps };

    case 'longText':
    case 'shortText': {
      switch (format) {
        case null:
          return { sortsAs: 'string', filtersAs: 'string', rendersAs: 'text', ...commonProps };

        case 'email':
          return { sortsAs: 'string', filtersAs: 'string', rendersAs: 'text', ...commonProps };

        case 'phone':
          return { sortsAs: 'string', filtersAs: 'string', rendersAs: 'text', ...commonProps };

        case 'number':
          return { sortsAs: 'number', filtersAs: 'number', rendersAs: 'text', ...commonProps };

        default:
          return false; // DEBUG: Something not yet supported
      }
    }

    case 'scale':
      return { sortsAs: 'number', filtersAs: 'number', rendersAs: 'text', ...commonProps };

    case 'datetime':
      return { sortsAs: 'date', filtersAs: 'date', rendersAs: 'timestamp', ...commonProps };

    case 'description':
      return { sortsAs: null, filtersAs: null, rendersAs: 'text', ...commonProps };

    case 'title':
      return { sortsAs: null, filtersAs: null, rendersAs: 'title', ...commonProps };

    case 'fileUpload':
      return { sortsAs: 'countable', filtersAs: 'number', rendersAs: 'fileUpload', ...commonProps };

    case 'signature':
      return {
        sortsAs: 'fillable',
        filtersAs: null,

        rendersAs: 'image',
        rendererProps: {
          minWidth: 200
        },
        ...commonProps
      };

    case 'radio':
      return {
        sortsAs: 'string',
        filtersAs: 'option',

        rendersAs: 'list',
        rendererProps: {
          placeholder: 'None'
        },
        ...commonProps
      };

    case 'checkbox':
      return {
        sortsAs: null,
        filtersAs: 'option',

        rendersAs: 'list',
        rendererProps: {
          placeholder: 'None'
        },
        ...commonProps
      };

    case 'matrix':
      return {
        sortsAs: null,
        filtersAs: null,

        rendersAs: 'matrixList',
        rendererProps: {
          placeholder: 'None'
        },
        ...commonProps
      };

    case 'dropdown':
      return {
        sortsAs: 'string',
        filtersAs: 'option',

        rendersAs: 'list',
        rendererProps: {
          placeholder: 'None'
        },
        ...commonProps
      };

    case 'imageChoice':
      return {
        sortsAs: null,
        filtersAs: 'option',

        rendersAs: 'image',
        rendererProps: {
          placeholder: 'None'
        },
        ...commonProps
      };

    case 'createdAt':
    case 'updatedAt':
      return {
        sortsAs: 'date',
        filtersAs: 'date',

        rendersAs: 'timestamp',
        rendererProps: {
          placeholder: 'Unknown'
        },
        ...commonProps
      };

    case 'autoDeletionAt':
      return {
        sortsAs: 'date',
        filtersAs: 'date',

        rendersAs: 'timestamp',
        rendererProps: {
          placeholder: 'Never',
          // Deletion worker deletes responses in intervals
          // so it's possible that there will be some responses
          // that should be deleted but are not yet deleted.
          // For these responses instead of a message like
          // 'about 5 minutes ago' this will be displayed:
          pastMessage: 'About to be deleted!'
        },
        ...commonProps
      };

    case 'completionTime':
      return {
        sortsAs: 'number',
        filtersAs: 'number',

        rendersAs: 'duration',
        rendererProps: {
          placeholder: 'Unknown'
        },
        ...commonProps
      };

    case 'userAgent':
      return {
        sortsAs: null,
        filtersAs: 'string',

        rendersAs: 'userAgent',
        rendererProps: {
          placeholder: 'Unknown'
        },
        ...commonProps
      };

    case 'referrer':
      return {
        sortsAs: null,
        filtersAs: 'string',

        rendersAs: 'url',
        rendererProps: {
          placeholder: 'Unknown'
        },
        ...commonProps
      };

    case 'ip':
      return {
        sortsAs: null,
        filtersAs: 'string',

        rendersAs: 'text',
        rendererProps: {
          placeholder: 'Unknown'
        },
        ...commonProps
      };

    case 'country':
      return {
        sortsAs: null,
        filtersAs: 'string',

        rendersAs: 'text',
        rendererProps: {
          placeholder: 'Unknown'
        },
        ...commonProps
      };

    case 'submitted':
      return {
        sortsAs: 'boolean',
        filtersAs: null,

        rendersAs: 'boolean',
        rendererProps: {
          placeholder: 'Unknown',
          trueMessage: 'Submitted',
          falseMessage: 'Not submitted'
        },
        ...commonProps
      };

    case 'active':
      return {
        sortsAs: 'boolean',
        filtersAs: null,

        rendersAs: 'boolean',
        rendererProps: {
          placeholder: 'Unknown',
          trueMessage: (
            <>
              <Icon id="open" />
              <span>Active</span>
            </>
          ),
          falseMessage: (
            <>
              <Icon id="locked" />
              <span>Active</span>
            </>
          )
        },
        ...commonProps
      };

    case 'urlParams':
      return {
        sortsAs: null,
        filtersAs: null,

        rendersAs: 'labeledList',
        rendererProps: {
          placeholder: 'None',
          bullet: <Icon id="dropdown-closed" />
        },
        ...commonProps
      };

    case 'calculationVariables':
      return {
        sortsAs: null,
        filtersAs: null,

        rendersAs: 'labeledList',
        rendererProps: {
          placeholder: 'None',
          bullet: <Icon id="variable" />
        },
        ...commonProps
      };

    case 'payment':
      return {
        sortsAs: null,
        filtersAs: null,

        rendersAs: 'payment',
        rendererProps: {
          placeholder: 'None'
        },
        ...commonProps
      };
    default:
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`DEBUG: Backend returned unexpected field type '${uniqueType}'.`, columnId, type, format);
      }

      return false; // DEBUG: Something not yet supported
  }
};

/* -------------------------------------------------------------------------- */
/*                               CACHED FUNCTION                              */
/* -------------------------------------------------------------------------- */

// Computed configs are cashed because there is going to be only finite number of them,
// and there is no need to store unnecessary object duplicates in memory
export const identifySubmissionField = ({ columnId, type, format = null }) => {
  const storage = identifySubmissionField.storage;
  const key = String(columnId) + String(type) + String(format);

  if (storage.has(key)) {
    // Getting cached configuration
    return storage.get(key);
  } else {
    // Computing configuration for the first time
    const config = _identifySubmissionField({ columnId, type, format });

    if (process.env.NODE_ENV === 'development') {
      const SORTABLE_TYPES = [
        '_id',
        'shortText',
        'longText',
        'dropdown',
        'radio',
        'datetime',
        'fileUpload',
        'signature',
        'scale',
        'submitted',
        'active',
        'completionTime',
        'createdAt',
        'updatedAt',
        'autoDeletionAt'
      ];

      const FILTERABLE_TYPES = [
        '_id',
        'shortText',
        'longText',
        'dropdown',
        'checkbox',
        'radio',
        'datetime',
        'fileUpload',
        'scale',
        'imageChoice',
        // Static types
        'country',
        'createdAt',
        'updatedAt',
        'autoDeletionAt',
        'referrer',
        'ip',
        'userAgent',
        'completionTime'
      ];

      if (!config || !('sortsAs' in config && 'filtersAs' in config && 'rendersAs' in config && 'type' in config && 'icon' in config)) {
        throw new Error(
          `DEBUG: Missing or incomplete configuration for received data: ${JSON.stringify({ columnId, type, format }, 0, 2)}`
        );
      }
      if (FILTERABLE_TYPES.includes(config.type) !== Boolean(config.filtersAs)) {
        throw new Error(`DEBUG: '${config.type}' does not have right filtersAs config, is '${config.filtersAs}'.`);
      }
      if (SORTABLE_TYPES.includes(config.type) !== Boolean(config.sortsAs)) {
        throw new Error(`DEBUG: '${config.type}' does not have right sortAs config.`);
      }
      if (![null, ...SortingMatrix.SUPPORTED_DATA_TYPES].includes(config.sortsAs)) {
        throw new Error(`DEBUG: unknown sorting type '${config.sortsAs}'.`);
      }
      if (![null, ...FilteringMatrix.SUPPORTED_DATA_TYPES].includes(config.filtersAs)) {
        throw new Error(`DEBUG: unknown filter type '${config.filtersAs}'.`);
      }
    }

    storage.set(key, config);
    return config;
  }
};
identifySubmissionField.storage = new Map();

export default identifySubmissionField;
