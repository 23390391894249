import { combineReducers } from 'redux';

import main from './main.js';
import user from './user.js';
import builder from './builder.js';
import dashboard from './dashboard.js';
import results from './results.js';
import settings from './settings.js';
import statistics from './statistics.js';
import plan from './plan.js';
import theme from './theme.js';

import _workspaces from './_workspaces.js';
import _forms from './_forms.js';
import _users from './_users.js';
import _themes from './_themes.js';
import _templates from './_templates.js';
import _domains from './_domains.js';
import _changelog from './_changelog.js';
import _connections from './_connections.js';
import _smtps from './_smtps.js';

const rootReducer = combineReducers({
  main, user, builder, dashboard, results, settings, statistics, plan, theme, _smtps,
  _workspaces, _forms, _users, _themes, _templates, _domains, _changelog, _connections
});

export default rootReducer;
