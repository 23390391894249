import styles from './Pagination.module.css';

import React, { useState, useCallback, useContext } from 'react';

import { Input } from '../Input/Input';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { Popover } from '../Tooltip/Tooltip';

import InlineSelect from '../InlineSelect/InlineSelect';

import { parseNumber } from 'helpers';

/* -------------------------------------------------------------------------- */
/*                              HELPER COMPONENTS                             */
/* -------------------------------------------------------------------------- */

const PaginationButton = React.forwardRef(({ className, active, label, disabled, onClick, ...rest }, ref) => {
  return (
    <button
      {...rest}
      className={[className, active ? styles.active : '', styles.paginationButton].join(' ')}
      onClick={onClick}
      disabled={disabled}
      ref={ref}>
      {label}
    </button>
  );
});

const PaginationDotsPopover = ({ onGoTo, min = 1, max }) => {
  const { tippyInstance } = useContext(Popover.Context);

  min = parseInt(min);
  max = parseInt(max);
  const [pageText, setPageText] = useState(1);
  const [touched, setTouched] = useState(false);

  const pageNumber = parseNumber(pageText, 'positive-int');
  const isVailid = pageNumber !== null;

  const handleGo = () => {
    if (!isVailid) {
      setTouched(true);
      return;
    }

    tippyInstance.hide();
    onGoTo(pageNumber);
  };

  return (
    <div className={styles.goToPopover}>
      <span>Jump to page</span>
      <Input style={{ width: '4em' }} min="1" type="number" compact value={pageText} onChange={setPageText} error={touched && !isVailid} />
      <Button size="default-thin" theme="black-underline" onClick={handleGo}>
        Go
      </Button>
    </div>
  );
};

const PaginationDots = ({ onGoTo, ...rest }) => {
  return (
    <Popover placement="top" content={<PaginationDotsPopover onGoTo={onGoTo} />} backdrop={false}>
      <PaginationButton className={styles.dots} {...rest} label={<Icon id="more-horizontal" />} />
    </Popover>
  );
};

/* -------------------------------------------------------------------------- */
/*                                 PAGINATION                                 */
/* -------------------------------------------------------------------------- */

const Pagination = ({ className = '', value, max, sidePadding: padding = 1, onChange }) => {
  // Casing data for math operations
  const lastPage = parseInt(max);
  const currentPage = parseInt(value);
  padding = parseInt(padding);

  if (process.env.NODE_ENV === 'development') {
    if (isNaN(lastPage) || isNaN(currentPage)) {
      throw new Error('DEBUG: expected numbers');
    }
    if (value < 1 || value > lastPage) {
      throw new Error('DEBUG: value not in [1, max]');
    }
  }

  /*
   Possible arrangements:
   Start:  PADDING 4xPAGE DOTS PADDING 
   Middle: PADDING DOTS 3xPAGE DOTS PADDING 
   End:    PADDING DOTS 4xPAGE PADDING
  */
  const pagesBefore = currentPage - 1;
  const pagesAfter = lastPage - currentPage;
  const isAtStart = pagesBefore <= padding + 2;
  const isAtEnd = pagesAfter <= padding + 2;
  const fitsAll = lastPage <= 2 * padding + 2 + 2 + 1;

  const handlePageChange = useCallback(
    (nextPage) => {
      nextPage = parseInt(nextPage);
      if (nextPage < 1) nextPage = 1;
      else if (nextPage > lastPage) nextPage = lastPage;
      onChange(nextPage || 1);
    },
    [onChange, lastPage]
  );

  /* ----------------------------------- JSX ---------------------------------- */

  const buttonsJsx = [];

  const pushPageButtons = (begIndex, endIndex) => {
    for (let page = begIndex; page <= endIndex; page++) {
      if (page > lastPage) return;
      buttonsJsx.push(<PaginationButton key={page} label={page} active={page === currentPage} onClick={() => handlePageChange(page)} />);
    }
  };

  // Simple case where all page numbers can be displayed because there is not many of them
  if (fitsAll) {
    pushPageButtons(1, lastPage);
  } else {
    // Page buttons that are always at the start
    pushPageButtons(1, padding);

    if (isAtStart) {
      pushPageButtons(padding + 1, padding + 4);
      buttonsJsx.push(<PaginationDots key="dots" onGoTo={handlePageChange} />);
    } else if (isAtEnd) {
      buttonsJsx.push(<PaginationDots key="dots" onGoTo={handlePageChange} />);
      pushPageButtons(lastPage - padding - 3, lastPage - padding);
    } else {
      buttonsJsx.push(<PaginationDots key="dots-before" onGoTo={handlePageChange} />);
      pushPageButtons(currentPage - 1, currentPage + 1);
      buttonsJsx.push(<PaginationDots key="dots-after" onGoTo={handlePageChange} />);
    }

    // Page buttons that are always at the end
    pushPageButtons(lastPage - padding + 1, lastPage);
  }

  return (
    <div className={[className, styles.pagination].join(' ')}>
      <PaginationButton
        className={styles.previous}
        label={<Icon id="arrow-narrow-left" />}
        onClick={() => onChange(currentPage - 1)}
        disabled={pagesBefore <= 0}
      />
      {buttonsJsx}
      <PaginationButton
        className={styles.next}
        label={<Icon id="arrow-narrow-left" />}
        onClick={() => onChange(currentPage + 1)}
        disabled={pagesAfter <= 0}
      />
    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                                 PAGE SELECT                                */
/* -------------------------------------------------------------------------- */

const pageSizeOptions = [
  { value: 25, label: '25 rows', shortLabel: '25' },
  { value: 50, label: '50 rows', shortLabel: '50' },
  { value: 75, label: '75 rows', shortLabel: '75' },
  { value: 100, label: '100 rows', shortLabel: '100' }
];

const PageSize = ({ value, onChange }) => {
  return (
    <div className={styles.pageSize}>
      <span>Rows per page:</span>
      <InlineSelect placement="top-end" options={pageSizeOptions} value={value} onChange={onChange} />
    </div>
  );
};

Pagination.PageSize = PageSize;

export default Pagination;
