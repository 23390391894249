import styles from './Billing.module.css';

import React, { useContext, useState, useEffect } from 'react';
import asyncSocketAckEmit from 'helpers/asyncSocketAckEmit.js';
import config from 'config.js';
import { format } from 'date-fns';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

import { MainContext } from 'contexts/main.js';
import { ProfileContext } from 'contexts/profile.js';

import ContentHeader from '../Header.js';
import CancelPlan from './panels/CancelPlan.js';
import UpdateInvoiceInformation from '../Upgrade/panels/UpdateInvoiceInformation.js';

import { Button, Header, FormRow, CreditCard, Popover, Spinner } from 'ui-components';

const CcDropdownContent = React.memo(({ onMakePrimary, onDelete }) => {
  return (
    <Popover.Group>
      <Popover.Button icon="save" onClick={onMakePrimary}>
        Make primary
      </Popover.Button>
      <Popover.Button icon="delete" delay={600} tooltipzIndex={2048} tooltipPlacement="right" onClick={onDelete}>
        Delete
      </Popover.Button>
    </Popover.Group>
  );
});

const AddCCForm = ({ onSuccess, onCancel }) => {
  const [ccError, setCcError] = useState(null);
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setCcError(null);

    const newPaymentMethod = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)
    });

    if (newPaymentMethod.error) {
      setCcError(newPaymentMethod.error.message);
      setLoading(false);
      return;
    }

    const paymentMethod = newPaymentMethod.paymentMethod.id;

    const { success, data } = await asyncSocketAckEmit('createUserPaymentMethod', {
      paymentMethodId: paymentMethod
    });

    if (success) {
      await onSuccess();
      setLoading(false);
    } else {
      setLoading(false);
      setCcError(data.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.ccForm}>
      <FormRow label="Card information" required={true} errorMessage={ccError}>
        <CreditCard />
      </FormRow>

      <div className={styles.ccFormButtons}>
        <Button theme="white" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button theme="black" type="submit" disabled={!stripe} style={{ margin: '0 0 0 10px' }}>
          Save
        </Button>
      </div>
      {loading && (
        <div className={styles.spinner}>
          <Spinner size={20} borderSize={2} speed="0.8s" />
        </div>
      )}
    </form>
  );
};

const Billing = () => {
  const { user } = useContext(MainContext);
  const { setPage } = useContext(ProfileContext);

  const stripePromise = loadStripe(config.stripePublicKey);

  const [showNewCC, setShowNewCC] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showCancelPlan, setShowCancelPlan] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [upcomingInvoice, setUpcomingInvoice] = useState({});
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);
  const [showUpdateInvoiceInformation, setShowUpdateInvoiceInformation] = useState(false);

  const getData = async () => {
    setLoading(true);

    const subscriptionInformations = await asyncSocketAckEmit('getUserSubscriptionInformations', {
      paymentMethods: true,
      upcomingInvoice: true,
      invoices: true
    });

    setPaymentMethods(subscriptionInformations.paymentMethods);
    setUpcomingInvoice(subscriptionInformations.upcomingInvoice);
    setInvoices(subscriptionInformations.invoices);

    setLoading(false);
  };

  const handleMakePrimaryCC = async () => {
    setSelectedPaymentMethodId(null);
    setLoading(true);

    await asyncSocketAckEmit('updateUserPrimaryPaymentMethod', {
      paymentMethodId: selectedPaymentMethodId
    });
    await getData();

    setSelectedPaymentMethodId(null);
  };

  const handleDeleteCC = async () => {
    setSelectedPaymentMethodId(null);
    setLoading(true);

    await asyncSocketAckEmit('deleteUserPaymentMethod', {
      paymentMethodId: selectedPaymentMethodId
    });
    await getData();

    setSelectedPaymentMethodId(null);
  };

  const handleAddingCcSuccess = async () => {
    await getData();
    setShowNewCC(false);

    return true;
  };

  const handleAddingCcCancel = () => {
    setShowNewCC(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const onUpdateInvoiceInformationSuccess = () => {
    setTimeout(() => setShowUpdateInvoiceInformation(false), 600);
  };

  const primaryMethods = (paymentMethods || []).filter((o) => o.default);
  const otherMethods = (paymentMethods || []).filter((o) => !o.default);

  useEffect(() => {
    if (user.role !== 'holder') setPage('profile');
  }, [user.role]);

  return (
    <div className={[styles.main, config.fullStoryExcludeClass].join(' ')}>
      <ContentHeader title="Billing" />

      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.left}>
            <Header style={{ margin: '0 0 15px 0' }}>Subscription information</Header>

            {/* {upcomingInvoice && (
              <div className={styles.description}>
                You are on a QuestionScout <span className={styles[user.billing.plan]}>{user.billing.plan}</span> subscription. Your next
                invoice for <span className={styles.bold}>${upcomingInvoice.total}</span>{' '}
                {upcomingInvoice.tax ? (
                  <>
                    (including <span className={styles.bold}>${upcomingInvoice.tax || 0}</span> in taxes)
                  </>
                ) : (
                  ''
                )}{' '}
                will be sent on{' '}
                <span className={styles.bold}>
                  {upcomingInvoice.nextPaymentAttempt && format(new Date(upcomingInvoice.nextPaymentAttempt), 'do LLLL')}
                </span>
                {upcomingInvoice && upcomingInvoice.subtotal > upcomingInvoice.subtotal ? (
                  <>
                    {' '}
                    for <span className={styles.bold}>${upcomingInvoice.subtotal}</span>, excluding{' '}
                    <span className={styles.bold}>{upcomingInvoice.taxPercent}%</span> in taxes. This amount is higher due to changes made
                    to your subscription during your current subscription period.
                  </>
                ) : (
                  <>.</>
                )}
              </div>
            )} */}

            <Header style={{ margin: '30px 0 15px 0' }}>Payment methods</Header>

            {!showNewCC && (
              <>
                <div className={styles.ccLabel}>Primary method</div>
                <ul className={styles.ccList}>
                  {primaryMethods.map((paymentMethod) => (
                    <li key={paymentMethod._id}>
                      <div>
                        <span className={styles.ccBrand}>{paymentMethod.brand}</span> ending with{' '}
                        <span className={styles.ccLast4}>{paymentMethod.last4}</span>
                      </div>
                    </li>
                  ))}
                </ul>

                {otherMethods.length > 0 && (
                  <>
                    <div className={styles.ccLabel}>Other methods</div>
                    <ul className={styles.ccList}>
                      {otherMethods.map((paymentMethod) => (
                        <li key={paymentMethod._id} className={styles.paymentMethod}>
                          <div>
                            <span className={styles.ccBrand}>{paymentMethod.brand}</span> ending with{' '}
                            <span className={styles.ccLast4}>{paymentMethod.last4}</span>
                          </div>

                          <Popover
                            theme="black"
                            zIndex={2048}
                            placement="bottom"
                            onRequestClose={() => setSelectedPaymentMethodId(null)}
                            visible={selectedPaymentMethodId === paymentMethod._id}
                            content={<CcDropdownContent onMakePrimary={handleMakePrimaryCC} onDelete={handleDeleteCC} />}>
                            <div className={styles.more} />
                          </Popover>

                          <Button icon="more-vertical" onClick={() => setSelectedPaymentMethodId(paymentMethod._id)} />
                        </li>
                      ))}
                    </ul>
                  </>
                )}

                <Button theme="white" onClick={() => setShowNewCC(true)}>
                  Add additional payment method
                </Button>
              </>
            )}

            {showNewCC && (
              <>
                <Elements stripe={stripePromise} options={{ locale: 'en' }}>
                  <AddCCForm onSuccess={handleAddingCcSuccess} onCancel={handleAddingCcCancel} />
                </Elements>
              </>
            )}
          </div>

          <div className={styles.right}>
            <Header style={{ margin: '0 0 15px 0' }}>
              Billing information
              <Button theme="white" style={{ margin: '0 0 0 10px' }} onClick={() => setShowUpdateInvoiceInformation(true)}>
                Edit
              </Button>
            </Header>

            <ul className={styles.billingInformation}>
              <li>
                <label>Full name or company name</label>
                {user.billing.address.name || <span className={styles.empty}>empty</span>}
              </li>
              <li>
                <label>Address line 1</label>
                {user.billing.address.address1 || <span className={styles.empty}>empty</span>}
              </li>
              <li>
                <label>Address line 2</label>
                {user.billing.address.address2 || <span className={styles.empty}>empty</span>}
              </li>
              <li>
                <label>City</label>
                {user.billing.address.city || <span className={styles.empty}>empty</span>}
              </li>
              <li>
                <label>Postal/ZIP Code</label>
                {user.billing.address.postal || <span className={styles.empty}>empty</span>}
              </li>
              <li>
                <label>Country</label>
                {user.billing.address.country || <span className={styles.empty}>empty</span>}
              </li>
              {user.billing.address.type === 'business' && config.euCountries.indexOf(user.billing.address.country) !== -1 && (
                <li>
                  <label>Vat EU number</label>
                  {user.billing.address.taxNumber || <span className={styles.empty}>empty</span>}
                </li>
              )}
              <li>
                <label>Billing email</label>
                {user.billing.email || user.email}
              </li>
            </ul>
          </div>
        </div>

        <Header style={{ margin: '30px 0 0 0' }}>Invoices</Header>

        <ul className={styles.invoices}>
          <li className={styles.invoicesHead}>
            <div style={{ width: '25%' }}>Invoice date</div>
            <div style={{ width: '30%' }}>Details</div>
            <div style={{ width: '45%', textAlign: 'right' }}>Actions</div>
          </li>

          {invoices &&
            invoices.map((invoice) => (
              <li className={styles.invoicesBody} key={invoice.number}>
                <div style={{ width: '25%' }}>{format(new Date(invoice.created * 1000), 'd LLLL yyyy')}</div>

                <div style={{ width: '30%' }}>
                  {invoice.currency === 'eur' ? '€' : '$'}
                  {invoice.total / 100}
                  <span className={styles[invoice.status]}>{invoice.status}</span>
                </div>

                <div style={{ width: '45%', display: 'flex', justifyContent: 'flex-end' }}>
                  {invoice.pdfUrl && (
                    <Button theme="black-underline" href={invoice.pdfUrl}>
                      Download
                    </Button>
                  )}{' '}
                  {invoice.payUrl && !invoice.paid && (
                    <Button theme="black-underline" href={invoice.payUrl} target="_blank">
                      Pay
                    </Button>
                  )}
                </div>
              </li>
            ))}
        </ul>

        <Button className={styles.cancelSubscription} theme="black-underline" onClick={() => setShowCancelPlan(true)}>
          Cancel your subscription
        </Button>
      </div>

      {showCancelPlan && <CancelPlan show={showCancelPlan} onClose={() => setShowCancelPlan(false)} />}
      {showUpdateInvoiceInformation && (
        <UpdateInvoiceInformation
          show={showUpdateInvoiceInformation}
          onClose={() => setShowUpdateInvoiceInformation(false)}
          showCollaboratorsSelect={false}
          onSuccess={onUpdateInvoiceInformationSuccess}
          submitText="Save"
        />
      )}

      {loading && (
        <div className={styles.spinner}>
          <Spinner size={30} borderSize={2} speed="0.8s" />
        </div>
      )}
    </div>
  );
};

export default Billing;
