import styles from './ColorInput.module.css';

import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Input } from '../Input/Input';
import Color from '../Color/Color';
import inputStyles from '../Input/Input.module.css';
import ColorPicker from '../ColorPicker/ColorPicker';
import OpenCloseIcon from '../OpenCloseIcon/OpenCloseIcon';

import { Popover } from '../Tooltip/Tooltip';

import {} from 'helpers';

const ColorInput = React.memo(({ className = '', value, disabled, onChange }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  useEffect(() => {
    if (disabled) {
      setIsPickerOpen(false);
    }
  }, [disabled]);

  return (
    <div className={[className, styles.colorInput].join(' ')}>
      <Popover
        visible={isPickerOpen}
        arrow={false}
        backdrop={false}
        sticky={false}
        hideOnScroll={true}
        offset={[0, 6]}
        onRequestClose={() => setIsPickerOpen(false)}
        content={<ColorPicker value={value} onChange={onChange} />}>
        <div onClick={() => (disabled ? null : setIsPickerOpen(true))}>
          <Input
            disabled={disabled}
            className={[styles.input, isPickerOpen ? inputStyles.focused : ''].join(' ')}
            readOnly
            value={value}
            before={<Color value={value} shape="square" glow />}
            after={<OpenCloseIcon opened={isPickerOpen} />}
          />
        </div>
      </Popover>
    </div>
  );
});

export default ColorInput;
