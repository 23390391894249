import React from 'react';

import { getFieldIcon } from 'config.js';

import { Icon } from 'ui-components';

const GET_PROPERTY_ICON_BY_CATEGORY = {
  properties: () => {
    return <Icon id="system" />;
  },
  fields: (property) => {
    return getFieldIcon(property.fieldType, property.fieldFormat);
  },
  urlParams: () => {
    return <img src={require('assets/images/urlParam.svg')} alt="" />;
  },
  calculationVariables: () => {
    return <img src={require('assets/images/icons/variable.svg')} alt="" />;
  }
};

// Modifies passed object by injecting icons
const populateSubmissionsPropsMetadataWithIcons = (submissionsPropsMetadata) => {
  for (const category in submissionsPropsMetadata) {
    for (const property of submissionsPropsMetadata[category]) {
      const iconGetter = GET_PROPERTY_ICON_BY_CATEGORY[category] || GET_PROPERTY_ICON_BY_CATEGORY.properties;

      property.icon = iconGetter(property) || null;
    }
  }
};

export default populateSubmissionsPropsMetadataWithIcons;
