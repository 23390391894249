import styles from './TransparentInput.module.css';

import React from 'react';

import AutosizeInput from 'react-input-autosize';

const TransparentInput = React.memo(({ className = '', blurOnEnter, onKeyDown, ...rest }) => {
  const handleKeyDown = (e) => {
    if (blurOnEnter && e.key === 'Enter') {
      e.target.blur();
    }

    if (onKeyDown) onKeyDown(e);
  };

  return <AutosizeInput className={[className, styles.transparentInput].join(' ')} onKeyDown={handleKeyDown} {...rest} />;
});

export default TransparentInput;
