import { useMemo } from 'react';

import { useQueryParam } from '../../providers';
import { QueryError } from '../../helpers';

import chartsMutations from './chartsMutations';

import { useSubmissionsWorkspaceCharts } from './useSubmissionsWorkspaceCharts';

export const useSubmissionsWorkspaceChart = (props) => {
  /* --------------------------------- PARAMS --------------------------------- */

  const { chartId } = props;
  const formId = useQueryParam('formId', null);
  const submissionsWorkspaceId = useQueryParam('submissionsWorkspaceId', null);

  /* --------------------------------- QUERIES -------------------------------- */

  const { isLoading, error, chartsById } = useSubmissionsWorkspaceCharts();

  const chart = chartsById[chartId] || null;
  if (!chartId) {
    throw new QueryError(`Chart '${chart}' doesn't exist or was removed.`);
  }

  /* -------------------------------- MUTATIONS ------------------------------- */

  const mutations = useMemo(() => {
    return {
      updateSubmissionsWorkspaceChart: async ({ changes }) => {
        return await chartsMutations.updateSubmissionsWorkspaceChart({ formId, submissionsWorkspaceId, chartId, changes });
      },
      duplicateSubmissionsWorkspaceChart: async () => {
        return await chartsMutations.duplicateSubmissionsWorkspaceChart({ formId, submissionsWorkspaceId, chartId });
      },
      deleteSubmissionsWorkspaceChart: async () => {
        return await chartsMutations.deleteSubmissionsWorkspaceChart({ formId, submissionsWorkspaceId, chartId });
      }
    };
  }, [formId, submissionsWorkspaceId, chartId]);

  return { isLoading, error, chart, ...mutations };
};
