import { useEffect } from 'react';
import { useQuery } from 'react-query';

import usersQueries from './usersQueries';

import io from 'io.js';

import { queryClient } from '../../providers';

export const useUserExampleItems = () => {
  const { data, error, isLoading } = useQuery({
    queryFn: usersQueries.getUserExampleItems.fn,
    queryKey: usersQueries.getUserExampleItems.keyFn(),
    structuralSharing: false,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    suspense: true
  });

  // Requesting refresh when there is possibility that something changed
  useEffect(() => {
    const handleRefreshExampleItems = () => {
      queryClient.invalidateQueries(usersQueries.getUserExampleItems.keyFn());
    };

    io.socket.on('refreshWorkspaces', handleRefreshExampleItems);
    io.socket.on('refreshForms', handleRefreshExampleItems);
    io.socket.on('refreshThemes', handleRefreshExampleItems);
    return () => {
      io.socket.off('refreshWorkspaces', handleRefreshExampleItems);
      io.socket.off('refreshForms', handleRefreshExampleItems);
      io.socket.off('refreshThemes', handleRefreshExampleItems);
    };
  }, []);

  return {
    error,
    isLoading,
    data
  };
};
