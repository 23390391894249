import React, { useState, useMemo } from 'react';
import { useLazySocketRefreshEffect } from 'helpers';

import useFormId from './useFormId';

// State storage context
import { useStorageContextSelector } from 'contexts/storage';

// Reducers
import getSubmissionsWorkspacesReducer from './reducers/getSubmissionsWorkspaces';
import createSubmissionsWorkspaceReducer from './reducers/createSubmissionsWorkspace';
import deleteSubmissionsWorkspaceReducer from './reducers/deleteSubmissionsWorkspace';
import orderSubmissionsWorkspaceReducer from './reducers/orderSubmissionsWorkspace';
import updateSubmissionsWorkspaceReducer from './reducers/updateSubmissionsWorkspace';
import scheduleSubmissionsExportReducer from './reducers/scheduleSubmissionsExport';

/* ------------------------- HOOK THAT RETURNS APIS ------------------------- */

export const useSubmissionsWorkspacesReducers = () => {
  const formId = useFormId();
  const setState = useStorageContextSelector((context) => context.setSubmissionsWorkspaces);
  const setIsLoading = useStorageContextSelector((context) => context.setIsSubmissionsWorkspacesLoading);

  /* -------------------------------- REDUCERS -------------------------------- */

  const reducers = useMemo(() => {
    return {
      createSubmissionsWorkspace: createSubmissionsWorkspaceReducer(formId, setState),
      deleteSubmissionsWorkspace: deleteSubmissionsWorkspaceReducer(formId, setState),
      orderSubmissionsWorkspace: orderSubmissionsWorkspaceReducer(formId, setState),
      updateSubmissionsWorkspace: updateSubmissionsWorkspaceReducer(formId, setState),
      scheduleSubmissionsExport: scheduleSubmissionsExportReducer(formId)
    };
  }, [formId, setState]);

  /* ----------------------- LOADING AND RELOADING DATA ----------------------- */

  const refreshEffectData = useMemo(
    () => [
      async (formId, setState) => {
        if (formId) {
          setIsLoading(true);
          const workspaces = await getSubmissionsWorkspacesReducer(formId)();
          setIsLoading(false);

          setState(workspaces);
        } else {
          setState(null);
          setIsLoading(false);
        }
      },
      [formId, setState],
      {
        refreshEvent: 'refreshSubmissionsWorkspace'
      }
    ],
    [formId, setState, setIsLoading]
  );

  useLazySocketRefreshEffect(...refreshEffectData);

  return reducers;
};

/* ----------------- HOOKS THAT RETURNS APIS AND SOME STATE ----------------- */

// API hook
// submissionWorkspaceId determines what submissionWorkspaces are subscribed.
// Only when one of subscribed submissionWorkspaces change, context will trigger a re-render.
// null - all
// _id - specified one
const useSubmissionsWorkspaces = (submissionWorkspaceId = null) => {
  const reducers = useSubmissionsWorkspacesReducers();
  const isLoading = useStorageContextSelector((context) => context.isSubmissionsWorkspacesLoading);
  const state = useStorageContextSelector((context) => {
    if (!submissionWorkspaceId) {
      return context.submissionsWorkspaces;
    } else {
      return context.submissionsWorkspacesById[submissionWorkspaceId];
    }
  });

  if (!submissionWorkspaceId) {
    return { submissionsWorkspaces: state, isLoading, ...reducers };
  } else {
    return { submissionsWorkspace: state, isLoading, ...reducers };
  }
};

export default useSubmissionsWorkspaces;
