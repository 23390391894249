// Prepares data that will be used to prepare headers of charts
const getSubmissionsInterpretations = (submissionsPropsMetadata) => {
  const submissionsInterpretations = [];

  for (const category in submissionsPropsMetadata) {
    for (const property of submissionsPropsMetadata[category]) {
      const interpretations = property.interpretations || [];

      const propertyWithoutInterpretations = { ...property };
      delete propertyWithoutInterpretations.interpretations;

      for (const interpretation of interpretations) {
        if (!interpretation.path) {
          if (process.env.NODE_ENV === 'development') {
            throw new Error('DEBUG: Missing property interpretation path in: ' + JSON.stringify(property, 0, 2));
          }
          continue;
        }

        submissionsInterpretations.push({
          ...interpretation,
          parentProperty: propertyWithoutInterpretations
        });
      }
    }
  }

  return submissionsInterpretations;
};

export default getSubmissionsInterpretations;
