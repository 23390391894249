import styles from './Affiliate.module.css';

import React, { useContext, useEffect, useState } from 'react';
import { CopyField, FormRow, Header, Button, Spinner } from 'ui-components';
import asyncSocketAckEmit from 'helpers/asyncSocketAckEmit.js';
import { customAlphabet } from 'nanoid';
import { format } from 'date-fns';

import { MainContext } from 'contexts/main.js';
import { ProfileContext } from 'contexts/profile.js';

import ContentHeader from '../Header.js';

const nanoid = customAlphabet('346789ABCDEFGHJKLMNPQRTUVWXYabcdefghijkmnpqrtwxyz', 8);

const Affiliate = () => {
  const { user } = useContext(MainContext);
  const { balance } = useContext(ProfileContext);

  const [addingUserLoading, setAddingUserLoading] = useState(false);

  useEffect(() => {
    if (user && !user?.affiliate?.key)
      asyncSocketAckEmit('updateUserAffiliate', {
        changes: {
          key: nanoid()
        }
      });
  }, [user?.affiliate?.key]);

  const addLtdfCollaborator = async () => {
    setAddingUserLoading(true);
    await asyncSocketAckEmit('useUserLifeTimeBalance');
    setTimeout(() => setAddingUserLoading(false), 2000);
  };

  return (
    <>
      <ContentHeader
        title="Earn with Refer-a-Friend"
        description="The QuestionScout Refer-a-Friend program allows you to invite friends, colleagues, family, or anyone else with a discount of 50% for two months and allows you to save money on your monthly plan!"
      />

      <div className={styles.content}>
        <FormRow
          label="Your refer-a-friend link"
          description="This is your personalized refer-a-friend link. Whoever starts a paid plan using this link will be added to your balance.">
          <CopyField value={`${window.location.origin}/signup?invite=${user?.affiliate?.key}`} />
        </FormRow>

        <Header style={{ margin: '30px 0 10px 0' }}>The benefits you and your friends get</Header>

        <div className={styles.table}>
          <div className={styles.body}>
            <div className={styles.row}>
              <div className={styles.bold}>If they start a</div>
              <div className={styles.blue}>Personal plan</div>
              <div className={styles.purple}>Starter plan</div>
              <div className={styles.orange}>Pro plan</div>
            </div>
            <div className={styles.row}>
              <div className={styles.bold}>You get</div>
              <div>$2.50 balance*</div>
              <div>$5 balance*</div>
              <div>$10 balance*</div>
            </div>
            <div className={styles.row}>
              <div className={styles.bold}>Your referrals get</div>
              <div>50% off for 2 months</div>
              <div>50% off for 2 months</div>
              <div>50% off for 2 months</div>
            </div>
          </div>
        </div>

        {user?.lifeTime?.enabled ? (
          <div className={styles.description}>* Your balance can be used to purchase additional collaborators.</div>
        ) : (
          <div className={styles.description}>* Your balance will automatically be used to reduce your monthly bill up to 100%.</div>
        )}

        {user?.lifeTime?.enabled && user?.lifeTime?.paid && (
          <>
            <Header style={{ margin: '30px 0 10px 0' }}>Use your balance</Header>

            <div className={styles.addCollaborator}>
              <Button
                theme="black"
                disabled={balance < 25 || addingUserLoading}
                onClick={() => addLtdfCollaborator()}
                style={{
                  margin: '0 10px 0 0'
                }}>
                Add collaborator seat to your account
              </Button>

              {addingUserLoading && <Spinner size={16} borderSize={0.4} speed="0.8s" />}
            </div>

            <div className={styles.description}>
              One collaborator seat costs $25
              {balance !== 0 && balance < 25 && <>, you are still missing ${25 - balance}</>}.
            </div>
          </>
        )}

        {user.affiliate.logs.length > 0 && (
          <>
            <Header style={{ margin: '30px 0 10px 0' }}>Your referrals</Header>

            <div className={styles.logs}>
              {user.affiliate.logs.map((log) => (
                <div className={styles.log}>
                  <div className={styles.date}>{format(new Date(log.createdAt), 'dd MMM yyyy')}</div>
                  <div className={styles.text}>
                    One of your friends has started a {log.plan} plan, ${log.amount} was added to your balance!
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Affiliate;
