const validateFormSmtp = ({ smtp, smtps, workspace }) => {
  const activeSmtp = smtp;
  const enforcedSmtp = workspace?.smtp || null;

  let domainError = false;

  if (activeSmtp && !smtps?.find((s) => s._id === activeSmtp)) {
    domainError = 'Selected SMTP was removed from account settings';
  } else if (enforcedSmtp && activeSmtp !== enforcedSmtp) {
    domainError = 'Selected SMTP is not equal to a SMTP enforced by a workspace setting.';
  }

  return domainError;
};

export default validateFormSmtp;
