import React, { useMemo, useState, useRef, useCallback } from 'react';
import { createContext, useContextSelector } from '@fluentui/react-context-selector';
import _memoize from 'lodash/memoize';

const StorageContext = createContext(null);

export const StorageContextProvider = React.memo(({ children }) => {
  /* ---------------------------------- STATE --------------------------------- */

  const [domains, setDomains] = useState(null);
  const [submissionsWorkspaces, setSubmissionsWorkspaces] = useState(null);
  const [isSubmissionsWorkspacesLoading, setIsSubmissionsWorkspacesLoading] = useState(true);

  /* --------------------------- PRE-PROCESSED STATE -------------------------- */

  const submissionsWorkspacesById = useMemo(() => {
    if (!submissionsWorkspaces) return null;

    const submissionsWorkspacesById = {};

    for (const submissionsWorkspace of submissionsWorkspaces) {
      submissionsWorkspacesById[submissionsWorkspace._id] = submissionsWorkspace;
    }

    return submissionsWorkspacesById;
  }, [submissionsWorkspaces]);

  /* ------------------------ UNCONTROLLED POPOVERS API ----------------------- */

  const uncontrolledPopoversInstancesRef = useRef({});

  // Always returns the same function for given name
  const registerUncontrolledPopover = useMemo(() => {
    return _memoize((name) => {
      return {
        onCreate: (instance) => (uncontrolledPopoversInstancesRef.current[name] = instance),
        onDestroy: () => (uncontrolledPopoversInstancesRef.current[name] = null)
      };
    });
  }, []);

  // Used to trigger uncontrolled popovers programmatically
  const showUncontrolledPopover = useCallback((name) => {
    const tippyInstance = uncontrolledPopoversInstancesRef.current[name];
    if (tippyInstance) {
      tippyInstance.show();
    } else if (tippyInstance === null) {
      console.error('DEBUG: This popover was destroyed.', name);
    } else {
      console.error("DEBUG: This popover wasn't registered yet.", name);
    }
  }, []);

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <StorageContext.Provider
      value={{
        // Domains fetched data
        domains,
        setDomains,
        // SubmissionsWorkspaces fetched data
        isSubmissionsWorkspacesLoading,
        submissionsWorkspaces,
        submissionsWorkspacesById,
        setSubmissionsWorkspaces,
        setIsSubmissionsWorkspacesLoading,
        // Shared apis
        registerUncontrolledPopover,
        showUncontrolledPopover
      }}>
      {children}
    </StorageContext.Provider>
  );
});

export const useStorageContextSelector = (selector) => {
  return useContextSelector(StorageContext, selector);
};
