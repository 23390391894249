import styles from './Select.module.css';

import React from 'react';
import { Icon, Select as MainSelect } from 'ui-components';

// Temporary solution, props lie this fix some issues but introduce other.
// There seems to be a bug with react select placement
// https://github.com/JedWatson/react-select/issues/3646
const FixedSelect = React.memo((props) => {
  return (
    <MainSelect
      {...props}
      // Placement options
      menuPosition="fixed"
      menuPlacement="auto"
      menuPortalTarget={document.body}
    />
  );
});

const Select = React.forwardRef(
  (
    {
      label,
      style,
      className,
      value = null,
      onChange,
      disabled = false,
      options,
      hint,
      borderTop,
      borderBottom,
      isClearable,
      fixed = false,
      error
    },
    ref
  ) => {
    let classList = [styles.select, className, borderTop ? styles.borderTop : '', borderBottom ? styles.borderBottom : ''];

    const SelectComponent = fixed ? FixedSelect : MainSelect;

    return (
      <div ref={ref} style={style} className={classList.join(' ')}>
        {label && <div className={styles.selectLabel}>{label}</div>}

        <SelectComponent
          error={Boolean(error)}
          options={options}
          value={value}
          onChange={onChange}
          disabled={disabled}
          isClearable={isClearable}
        />

        {error && (
          <div className={styles.selectError}>
            <Icon id="warning" animation="headShake" />
            {error}
          </div>
        )}

        {hint && (
          <div className={styles.selectHint}>
            <Icon id="warning" />
            {hint}
          </div>
        )}
      </div>
    );
  }
);

export default Select;
