import styles from './BuilderFormPreview.module.css';

import React, { useState, useMemo, useEffect, useRef } from 'react';

import { Toggle, Spinner } from 'ui-components';

import { useTimeout } from 'helpers';

import FormPreview from './FormPreview';

/* -------------------------------------------------------------------------- */
/*                               HELPERS - HOOKS                              */
/* -------------------------------------------------------------------------- */

// Applies debounce to passed value with given time
const useDebounce = (value, debounceTime = 250) => {
  const debounceTimeRef = useRef(debounceTime);
  debounceTimeRef.current = debounceTime;

  const setUpdatePropsTimeout = useTimeout();

  // State for storing delayed props
  const [debouncedValue, setDebouncedValue] = useState(null);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    setUpdatePropsTimeout(null);

    const updateProps = () => {
      setDebouncedValue(value);
      setIsPending(false);
    };

    if (debounceTimeRef.current) {
      setIsPending(true);
      setUpdatePropsTimeout(updateProps, debounceTimeRef.current);
    } else {
      updateProps();
    }
  }, [value, setUpdatePropsTimeout]);

  return {
    isPending,
    data: debouncedValue
  };
};

// useDebounce with debounceTime that changes based on form size
const useDebounceFormPreviewProps = (formPreviewProps) => {
  let debounceTime = 250;

  // TODO: measuring time + moving average should give quite reliable results on various cpus
  const fields = formPreviewProps?.form?.fields;
  if (fields) {
    const MIN_DEBOUNCE = 300;
    const MAX_DEBOUNCE = 500;
    const FIELDS_COUNT_REQUIRED_FOR_MAX_DEBOUNCE = 70;

    const estimation = fields.length * (MAX_DEBOUNCE / FIELDS_COUNT_REQUIRED_FOR_MAX_DEBOUNCE);
    debounceTime = Math.max(Math.min(estimation, MAX_DEBOUNCE), MIN_DEBOUNCE) || MIN_DEBOUNCE;
  }

  return useDebounce(formPreviewProps, debounceTime);
};

/* -------------------------------------------------------------------------- */
/*                            FORM PREVIEW TOP BAR                            */
/* -------------------------------------------------------------------------- */

const FormPreviewTopBar = ({ loading, theme, form, previewLogic, onChangePreviewLogic }) => {
  return (
    <div className={styles.topBar}>
      <label>
        <span>{!(theme?._id === form?.themeId) ? 'Theme preview (apply to save changes)' : 'Form preview'}</span>
        {loading && <Spinner />}
      </label>

      <label className="intro-form-logic-toggle" style={{ cursor: 'pointer' }}>
        <span>Preview logic</span>
        <Toggle value={previewLogic} onClick={() => onChangePreviewLogic(!previewLogic)} />
      </label>
    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                           CONTROLLED FORM PREVIEW                          */
/* -------------------------------------------------------------------------- */

const ControlledFormPreview = React.memo(
  ({
    // === Styling
    className = '',
    style = null,

    // === Form and user data
    user = null,
    theme = null,
    form = null,

    // === Controlled state
    activeField = null, // Field/welcomePage/thankYouPage that should be highlighted
    previewLogic = false, // Is logic preview is enabled

    // === Other state
    loading = true,

    // === Callbacks
    onChangeActiveField,
    onChangePreviewLogic
  }) => {
    /* ---------------------------------- STATE --------------------------------- */

    // Used for handling a situation where a form type wasn't selected for some reason
    const formType = form?.type ?? null;

    // Requests deselecting active field when no form type is selected (so that form type select is visible)
    useEffect(() => {
      if (!loading && !formType && activeField) {
        onChangeActiveField(null);
      }
    }, [loading, formType, activeField, onChangeActiveField]);

    /* ----------------------------- DEBOUNCED STATE ---------------------------- */

    const formPreviewProps = useMemo(() => {
      return {
        user,
        theme,
        form,
        previewLogic,
        activeField,
        onChangeActiveField
      };
    }, [user, form, theme, previewLogic, activeField, onChangeActiveField]);

    const { data: debouncedProps, isPending: isNewStatePending } = useDebounceFormPreviewProps(formPreviewProps);

    /* ----------------------------------- JSX ---------------------------------- */

    return (
      <div className={[className, styles.formPreview].join(' ')} style={style}>
        <FormPreviewTopBar
          loading={loading || isNewStatePending}
          form={form}
          theme={theme}
          previewLogic={previewLogic}
          onChangePreviewLogic={onChangePreviewLogic}
        />
        <FormPreview
          className={[styles.form, 'intro-form-preview'].join(' ')}
          loading={loading || !debouncedProps}
          placement="builder"
          {...debouncedProps}
        />
      </div>
    );
  }
);

export default ControlledFormPreview;
