import styles from './Text.module.css';
import React, { useState, useEffect, useMemo } from 'react';

// Main text component
const Text = React.forwardRef(({ className, theme = 'default', size = 'regular', weight = 'regular', ...rest }, ref) => {
  const classNames = useMemo(() => {
    return [styles.text, styles['theme-' + theme], styles['size-' + size], styles['weight-' + weight], className || ''].join(' ');
  }, [className, theme, size, weight]);

  return <span className={classNames} {...rest} ref={ref} />;
});

export default Text;
