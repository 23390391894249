import styles from './InitializePlan.module.css';

import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import io from 'io.js';
import config from 'config.js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { format } from 'date-fns';
import asyncSocketAckEmit from 'helpers/asyncSocketAckEmit.js';

import { Panel, Button, CreditCard, Checkbox, Header, FormRow, Input } from 'ui-components';

import { MainContext } from 'contexts/main.js';

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const plansPrice = {
  trial: 0,
  personal: 5,
  starter: 10,
  pro: 20
};

const responsesPrice = {
  0: 0,
  500: 0,
  2500: 0,
  10000: 0,
  20000: 25,
  30000: 50,
  40000: 75,
  50000: 100,
  100000: 200,
  200000: 300
};

const CheckoutForm = ({ selectedPlan, selectedUsersNumber, planResponses, setLoading, setSuccess, onSuccess }) => {
  const { user, users } = useContext(MainContext);

  const guests = useMemo(
    () => users.filter((u) => u.role === 'guest').sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt)),
    [users]
  );
  const collaborators = useMemo(
    () => users.filter((u) => u.role === 'user' || u.role === 'admin').sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt)),
    [users]
  );

  const stripe = useStripe();
  const elements = useElements();

  const [coupon, setCoupon] = useState(null);
  const [ccError, setCcError] = useState(null);
  const [discountCodeError, setDiscountCodeError] = useState(null);
  const [summary, setSummary] = useState({});
  const [usersToRemove, setUsersToRemove] = useState([]);
  const [showDiscountCode, setShowDiscountCode] = useState(false);
  const [discountCode, setDiscountCode] = useState('');

  useEffect(() => {
    if (selectedPlan === 'personal') {
      setUsersToRemove(users.filter((u) => u.role !== 'holder').map((u) => u._id));
    } else {
      let toRemove = [];

      if (selectedPlan === 'starter') toRemove = [...toRemove, ...guests.slice(100).map((u) => u._id)];
      toRemove = [...toRemove, ...collaborators.slice(selectedUsersNumber).map((u) => u._id)];

      setUsersToRemove(toRemove);
    }
  }, [guests, collaborators, users, selectedUsersNumber, selectedPlan]);

  useEffect(() => {
    async function getDiscountCodeFromLocalStorage() {
      const discountCode = window.localStorage.getItem('coupon');
      if (!discountCode) return;

      const { data, success } = await asyncSocketAckEmit('getStripeCoupon', {
        code: discountCode
      });
      if (!success) return;

      setCoupon(data);
      setShowDiscountCode(true);
    }

    getDiscountCodeFromLocalStorage();
  }, []);

  useEffect(() => {
    io.socket.emit(
      'getUserSubscriptionProposition',
      {
        plan: selectedPlan,
        users: selectedUsersNumber,
        responses: planResponses,
        coupon
      },
      setSummary
    );
  }, [selectedPlan, selectedUsersNumber, planResponses, coupon]);

  const sendInitSubscriptionSuccess = async ({ subscriptionId }) => {
    const { success, data } = await asyncSocketAckEmit('initUserSubscriptionSuccess', {
      subscriptionId: subscriptionId,
      plan: selectedPlan,
      quantity: selectedUsersNumber,
      responses: planResponses
    });

    if (success) {
      const invoiceUrl = data.invoiceUrl;

      window.localStorage.removeItem('coupon');
      setLoading(false);
      setSuccess(true);
      onSuccess({ invoiceUrl });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setCcError(null);

    let paymentMethod;

    const newPaymentMethod = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)
    });

    if (newPaymentMethod.error) {
      setCcError(newPaymentMethod?.error?.message);
      setLoading(false);
      return;
    }

    paymentMethod = newPaymentMethod.paymentMethod.id;

    const { success, data } = await asyncSocketAckEmit(
      'initUserSubscription',
      {
        paymentMethodId: paymentMethod,
        plan: selectedPlan,
        usersToRemove,
        quantity: selectedUsersNumber,
        responses: planResponses,
        couponId: coupon?.id
      },
      {
        // Error will be displayed with state
        showToasts: false
      }
    );

    if (success) {
      const subscriptionId = data.subscriptionId;

      if (data.requiresAction) {
        const cardAction = await stripe.confirmCardPayment(data.paymentIntentClientSecret);

        if (cardAction.error) {
          await asyncSocketAckEmit('cancelUserSubscription', { subscriptionId });

          setCcError(cardAction.error.message);
          setLoading(false);
          return;
        } else {
          sendInitSubscriptionSuccess({ subscriptionId });
        }
      } else {
        sendInitSubscriptionSuccess({ subscriptionId });
      }
    } else {
      setCcError(data?.message);
      setLoading(false);
      return;
    }
  };

  const toggleUserToRemove = (type, _id) => {
    let usersToRemoveCopy = [...usersToRemove];
    const index = usersToRemove.indexOf(_id);

    if (index === -1) {
      usersToRemoveCopy.push(_id);
    } else {
      let countSelected = 0;

      if (type === 'collaborators') {
        countSelected = collaborators.filter((collaborator) => usersToRemove.indexOf(collaborator._id) === -1).length;
      } else {
        countSelected = guests.filter((guest) => usersToRemove.indexOf(guest._id) === -1).length;
      }

      if (countSelected >= selectedUsersNumber) return;

      usersToRemoveCopy.splice(index, 1);
    }

    setUsersToRemove(usersToRemoveCopy);
  };

  const applyDiscountCode = async () => {
    setDiscountCodeError(null);

    const { data, success, message } = await asyncSocketAckEmit(
      'getStripeCoupon',
      {
        code: discountCode
      },
      {
        // There is a dedicated ui element that shows these errors
        // so toast is not needed
        showToasts: false
      }
    );

    if (!success) return setDiscountCodeError(message);

    setCoupon(data);
  };

  return (
    <form onSubmit={handleSubmit}>
      {selectedPlan === 'personal' && (collaborators.length > 0 || guests.length > 0) && (
        <>
          <Header style={{ margin: '0 0 15px 0' }}>Important information</Header>

          <div className={styles.description} style={{ margin: '0 0 20px 0' }}>
            The Personal plan does not allow you to work together with collaborators or guests. This means that switching from{' '}
            <span className={styles[user.billing.plan || 'trial']}>{user.billing.plan || 'Trial'}</span> to{' '}
            <span className={styles[selectedPlan]}>{selectedPlan}</span>{' '}
            <span className={styles.smallPrice}>will remove all collaborators and guests</span> from your account.
          </div>
        </>
      )}

      {((selectedPlan === 'starter' && guests.length > 10) ||
        (selectedPlan !== 'personal' && collaborators.length > selectedUsersNumber && selectedUsersNumber > 0) ||
        (selectedPlan !== 'personal' && collaborators.length > 0 && selectedUsersNumber === 0)) && (
        <Header style={{ margin: '0 0 15px 0' }}>Important information</Header>
      )}

      {selectedPlan === 'starter' && guests.length > 10 && (
        <>
          <div className={styles.description} style={{ margin: '0 0 5px 0' }}>
            Please select which guests you would like to keep, up to 10:
          </div>
          <ul className={styles.usersList}>
            {guests.map((guest) => (
              <li>
                <Checkbox
                  value={usersToRemove.indexOf(guest._id) === -1}
                  style={{ width: '100%' }}
                  onChange={() => {}}
                  onClick={() => toggleUserToRemove('guests', guest._id)}>
                  {guest.email}
                </Checkbox>
              </li>
            ))}
          </ul>
        </>
      )}

      {selectedPlan !== 'personal' && collaborators.length > selectedUsersNumber && selectedUsersNumber > 0 && (
        <>
          <div className={styles.description} style={{ margin: '0 0 5px 0' }}>
            Please select which collaborators you would like to keep, up to {selectedUsersNumber}:
          </div>
          <ul className={styles.usersList}>
            {collaborators.map((collaborator) => (
              <li>
                <Checkbox
                  value={usersToRemove.indexOf(collaborator._id) === -1}
                  style={{ width: '100%' }}
                  onChange={() => {}}
                  onClick={() => toggleUserToRemove('collaborators', collaborator._id)}>
                  {collaborator.email}
                </Checkbox>
              </li>
            ))}
          </ul>
        </>
      )}

      {selectedPlan !== 'personal' && collaborators.length > 0 && selectedUsersNumber === 0 && (
        <>
          <div className={styles.description} style={{ margin: '0 0 20px 0' }}>
            You have not added any additional collaborator seats to your plan, even though you already have invited some collaborators to
            your account. This means that <span className={styles.smallPrice}>we’ll have to remove all collaborators</span> from your
            account.
          </div>
        </>
      )}

      <Header style={{ margin: '0 0 15px 0' }}>Payment Information</Header>

      <FormRow label="Card information" required={true} errorMessage={ccError} style={{ margin: '0 0 5px 0' }}>
        <CreditCard />
      </FormRow>

      {!showDiscountCode && (
        <Button theme="black-underline" onClick={() => setShowDiscountCode(true)}>
          Enter a discount code
        </Button>
      )}

      {showDiscountCode && (
        <FormRow label="Discount code" style={{ margin: '15px 0 15px 0' }} errorMessage={discountCodeError}>
          {!coupon && (
            <div className={styles.discountCode}>
              <Input value={discountCode} onChange={(value) => setDiscountCode(value)} autoFocus={true} />
              <Button theme="white" style={{ margin: '0 0 0 10px' }} onClick={applyDiscountCode}>
                Apply
              </Button>
            </div>
          )}

          {coupon && (
            <div className={styles.discountCodeOn}>
              <div className={styles.info}>
                <strong>{coupon.code}</strong> / {coupon.percentOff}% off{' '}
                {coupon.durationInMonths && (
                  <>
                    for {coupon.durationInMonths} month{coupon.durationInMonths > 1 ? 's' : ''}
                  </>
                )}
              </div>
              <Button
                icon="delete"
                style={{ margin: '0 0 0 10px' }}
                onClick={() => {
                  setCoupon(null);
                  setDiscountCode('');
                }}
              />
            </div>
          )}
        </FormRow>
      )}

      <Header style={{ margin: '30px 0 15px 0' }}>Payment Summary</Header>

      <div className={styles.description}>
        QuestionScout will charge you for <strong className={styles.price}>${summary.priceNet}</strong>.
        {selectedPlan !== 'personal' && (
          <>
            <ul className={styles.priceInclude}>
              <li>
                <span className={styles.smallPrice}>${plansPrice[selectedPlan]}</span> for your monthly subscription.
              </li>
              {selectedPlan === 'pro' && planResponses > 10000 && (
                <li>
                  <span className={styles.smallPrice}>${responsesPrice[planResponses]}</span> for {planResponses} monthly responses.
                </li>
              )}
              {summary.additionalUsers > 0 && (
                <li>
                  <>
                    <span className={styles.smallPrice}>${summary.additionalUsersPrice}</span> for {summary.additionalUsers} additional
                    collaborator{summary.additionalUsers === 1 ? '' : 's'}.
                  </>
                </li>
              )}
            </ul>
          </>
        )}{' '}
        Afterwards, your <span className={styles[selectedPlan]}>{selectedPlan}</span> subscription will be billed monthly and will
        automatically renew on the{' '}
        <span className={styles.smallPrice}>{summary.renewDate ? format(new Date(summary.renewDate), 'do LLLL') : ''}</span>.
      </div>

      {summary.taxRate > 0 && (
        <div className={styles.description}>
          You will be charged <span className={styles.smallPrice}>{summary.taxRate}%</span> VAT.
        </div>
      )}

      <Button theme="black" type="submit" disabled={!stripe} fullWidth className={styles.submit}>
        Start {capitalize(selectedPlan || '')} Plan
      </Button>
    </form>
  );
};

const InitializePlan = ({ show, onClose, selectedPlan, selectedUsersNumber, planResponses }) => {
  const { setProfilePage } = useContext(MainContext);

  const stripePromise = useMemo(() => loadStripe(config.stripePublicKey), []);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState(null);

  const cancel = () => {
    setLoading(false);
    setSuccess(false);
    onClose();
  };

  const onSuccess = ({ invoiceUrl }) => {
    setInvoiceUrl(invoiceUrl);

    setTimeout(() => {
      setSuccess(false);
      setShowSuccessScreen(true);
      setProfilePage('billing');
    }, 300);
  };

  return (
    <Panel
      mainStyle={{
        height: '90vh'
      }}
      coverStyle={{
        width: '80vw',
        height: '90vh',
        maxWidth: '950px',
        minWidth: '850px',
        left: '-200px'
      }}
      className={styles.main}
      title={
        <div>
          Upgrading to <span className={styles[selectedPlan]}>{selectedPlan}</span>
        </div>
      }
      show={show}
      loading={loading}
      success={success}
      onOutsideClick={cancel}>
      <div className={styles.content}>
        {!showSuccessScreen && (
          <Elements stripe={stripePromise} options={{ locale: 'en' }}>
            <CheckoutForm
              selectedPlan={selectedPlan}
              setLoading={setLoading}
              setSuccess={setSuccess}
              onSuccess={onSuccess}
              selectedUsersNumber={selectedUsersNumber}
              planResponses={planResponses}
            />
          </Elements>
        )}

        {showSuccessScreen && (
          <>
            <p>Success</p>
            <a href={invoiceUrl} target="_blank" rel="noopener noreferrer">
              Invoice Url
            </a>
          </>
        )}
      </div>
    </Panel>
  );
};

export default InitializePlan;
