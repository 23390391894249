import styles from './UrlPreview.module.css';

import React from 'react';

import EmptyPreview from './EmptyPreview';

const UrlPreview = ({ theme, value = null, placeholder }) => {
  if (!value) {
    return <EmptyPreview theme={theme} placeholder={placeholder} />;
  } else {
    return (
      <div className={[styles.urlPreview, styles[`theme-${theme}`]].join(' ')}>
        <a href={value}>{value}</a>
      </div>
    );
  }
};

export default UrlPreview;
