import { QueryClient } from 'react-query';

/* --------------------------------- CLIENT --------------------------------- */

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Data will never be considered as stale (old) unless it's programticly invalidated
      staleTime: Infinity,

      // Refetch on mount and window focus but only id data is stale
      refetchOnMount: true,
      refetchOnWindowFocus: true,

      // Always refetch everything on reconnect
      refetchOnReconnect: 'always',

      // Garbage collect query data after 5 seconds of it not being used anywhere
      cacheTime: 5 * 1000,

      // Silent retry fetch on failure 2 times
      retry: 2
    }
  }
});
