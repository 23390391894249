import { useRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

const createRouteWithUrlParams = (route = '', urlParams = {}) => {
  const searchParams = new URLSearchParams('');
  for (const key in urlParams) {
    searchParams.set(key, urlParams[key]);
  }
  const searchParamsString = searchParams.toString();

  return searchParamsString ? route + '?' + searchParamsString : route;
};

const useRouteChanger = () => {
  const history = useHistory();
  const historyRef = useRef(null);
  historyRef.current = history;

  return useMemo(() => {
    return {
      push: (...urlArgs) => {
        const history = historyRef.current;
        const url = createRouteWithUrlParams(...urlArgs);
        history.push(url);
      },
      replace: (...urlArgs) => {
        const history = historyRef.current;
        const url = createRouteWithUrlParams(...urlArgs);
        history.replace(url);
      }
    };
  }, []);
};

export default useRouteChanger;
