import styles from './Domains.module.css';

import React, { useState, useEffect, useContext } from 'react';
import { Button, Spinner } from 'ui-components';
import io from 'io.js';

import { MainContext } from 'contexts/main.js';

import ContentHeader from './../Header.js';
import AddEditDomainPanel from './panels/AddEditDomainPanel.js';
import DeleteDomainPanel from './panels/DeleteDomainPanel.js';

import { useHelpScout } from 'integrations';

const Domains = React.memo(() => {
  const { user, showYouLackPermissions } = useContext(MainContext);

  const helpScoutAPI = useHelpScout();

  const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [showAddEditDomainPanel, setShowAddEditDomainPanel] = useState(false);
  const [showDeleteDomainPanel, setShowDeleteDomainPanel] = useState(false);

  const userCanModifyDomains = user.role === 'holder' || user.role === 'admin';

  useEffect(() => {
    setLoading(true);

    io.socket.on('refreshDomains', () => {
      setLoading(true);
      io.socket.emit('getDomains');
    });

    io.socket.on('getDomains', (payload) => {
      setDomains(payload);
      setLoading(false);
    });

    io.socket.emit('getDomains');
  }, []);

  return (
    <>
      <ContentHeader title="Custom domains" />

      <div className={styles.content}>
        <div className={styles.info}>
          Using Custom Domains, you can connect your forms to your own domain. As an example, your forms will be available on{' '}
          <strong>form.yourdomain.com</strong> instead of form.questionscout.com.
        </div>

        <div className={styles.buttons}>
          <Button
            theme="black"
            onClick={
              userCanModifyDomains
                ? () => {
                    setSelectedDomain(null);
                    setShowAddEditDomainPanel(true);
                  }
                : showYouLackPermissions
            }>
            Add a new custom domain
          </Button>
          <Button icon="open-external" theme="black-underline" onClick={() => helpScoutAPI.openArticle('customDomains')}>
            Learn more
          </Button>
        </div>

        {domains && domains.length > 0 && (
          <ul className={styles.domains}>
            <li className={styles.domainsHead}>
              <div style={{ width: 'calc(100% - 120px)' }}>Hostname</div>
              <div style={{ width: '120px' }}>Actions</div>
            </li>

            {domains.map((domain) => (
              <li className={styles.domainsBody} key={domain._id}>
                <div style={{ width: 'calc(100% - 120px)' }}>{domain.hostname}</div>

                <div style={{ width: '120px', display: 'flex' }}>
                  <Button
                    theme="black-underline"
                    onClick={
                      userCanModifyDomains
                        ? () => {
                            setSelectedDomain(domain);
                            setShowAddEditDomainPanel(true);
                          }
                        : showYouLackPermissions
                    }
                    style={{ margin: '0 15px 0 0' }}>
                    Edit
                  </Button>
                  <Button
                    theme="black-underline"
                    onClick={
                      userCanModifyDomains
                        ? () => {
                            setSelectedDomain(domain);
                            setShowDeleteDomainPanel(true);
                          }
                        : showYouLackPermissions
                    }>
                    Delete
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        )}

        {loading && (
          <div className={styles.loader}>
            <Spinner size={30} borderSize={2} speed="0.8s" />
          </div>
        )}
      </div>

      <AddEditDomainPanel show={showAddEditDomainPanel} onClose={() => setShowAddEditDomainPanel(false)} domain={selectedDomain} />
      <DeleteDomainPanel show={showDeleteDomainPanel} onClose={() => setShowDeleteDomainPanel(false)} domain={selectedDomain} />
    </>
  );
});

export default Domains;
