import styles from './Slider.module.css';

import React, { useCallback } from 'react';
import { Range, getTrackBackground } from 'react-range';

/* --------------------------------- HELPERS -------------------------------- */

const renderThumbCallback = ({ props, isDragged }) => (
  <div
    {...props}
    style={{
      ...props.style,
      background: isDragged ? '#151413' : '#454442',
      borderRadius: '100%',
      width: '12px',
      height: '12px',
      outline: 'none',
      transition: 'background 0.3s ease-in-out'
    }}
  />
);

const renderTrackCallback = (background) => ({ props, children }) => (
  <div
    onMouseDown={props.onMouseDown}
    onTouchStart={props.onTouchStart}
    style={{
      ...props.style,
      height: '16px',
      display: 'flex',
      width: 'calc(100% - 12px)',
      padding: '0 6px'
    }}>
    <div
      ref={props.ref}
      style={{
        height: '2px',
        width: '100%',
        borderRadius: '0px',
        background,
        alignSelf: 'center'
      }}>
      {children}
    </div>
  </div>
);

/* -------------------------------- COMPONENT ------------------------------- */

export const Slider = React.memo(
  ({
    className,
    style,

    // Slider options
    step = 1,
    min = 0,
    max = 100,
    colors,
    value,
    onChange,
    disabled,

    // Values text
    showValues = false,
    unit,

    ...rest
  }) => {
    if (process.env.NODE_ENV === 'development') {
      if (rest.values) {
        throw new Error('DEBUG: value prop is used instead of values prop');
      }
    }

    const isNumber = !isNaN(value);
    const values = isNumber ? [value] : value;

    return (
      <div className={[styles.sliderWrapper, className, disabled ? styles.disabled : ''].join(' ')} style={style}>
        <Range
          disabled={disabled}
          step={step}
          min={min}
          max={max}
          values={values}
          onChange={(values) => (isNumber ? onChange(values[0]) : onChange(values))}
          renderThumb={renderThumbCallback}
          renderTrack={renderTrackCallback(
            getTrackBackground({
              values: values,
              colors: colors || (values.length > 1 ? ['#DDDBD7', '#454442', '#DDDBD7'] : ['#454442', '#DDDBD7']),
              min,
              max
            })
          )}
          {...rest}
        />
        {showValues && (
          <div className={styles.sliderValues}>
            {values.length > 1 ? values.join('-') : values[0]}
            {unit}
          </div>
        )}
      </div>
    );
  }
);
