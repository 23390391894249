const isValidDate = (d) => {
  d = new Date(d);
  return d instanceof Date && !isNaN(d);
};

// Pausing Conditions error format: { submissionsLimit, beforeDate, afterDate }
const validateFormScheduledUnpauseDate = (scheduledUnpauseDate) => {
  const currentDate = new Date();

  if (scheduledUnpauseDate !== null) {
    if (!isValidDate(scheduledUnpauseDate)) {
      return 'Enter a valid date';
    }
    if (new Date(scheduledUnpauseDate) <= currentDate) {
      return 'Pick a date in the future';
    }
  }

  return false;
};

export default validateFormScheduledUnpauseDate;
