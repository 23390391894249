const NOLT_CONFIG = {
  scriptUrl: 'https://cdn.nolt.io/widgets.js',

  // Main url to nolt board
  boardUrl: 'https://questionscout.nolt.io',

  // Endpoints of various pages in board
  pages: {
    feedback: '/new-post',
    mostVoted: '/top',
    roadmap: '/roadmap'
  }
};

export default NOLT_CONFIG;
