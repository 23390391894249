const withTimeout = (onResponse, onTimeout, TIMEOUT_MS = 50000) => {
  let called = false;

  const timer = setTimeout(() => {
    if (called) return;

    called = true;
    onTimeout();
  }, TIMEOUT_MS);

  return (...args) => {
    if (called) return;

    called = true;
    clearTimeout(timer);
    onResponse(...args);
  };
};

export default withTimeout;
