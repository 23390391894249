// List of features available only in plans better than personal
const features = {
  /* -------------------------------- SETTINGS -------------------------------- */

  // General
  partialResponses: {
    requiredPlan: 'pro',
    name: 'Partial Responses',
    path: '/settings/general'
  },

  // Advanced
  disableSave: {
    requiredPlan: 'pro',
    name: 'Disable saving responses in QuestionScout',
    path: '/settings/advanced'
  },
  autoSubmissionDeletion: {
    requiredPlan: 'pro',
    name: 'Delete responses after amount of time',
    path: '/settings/advanced'
  },

  // Url Params
  urlParams: {
    requiredPlan: 'starter',
    name: 'URL parameters',
    path: '/settings/url'
  },

  // Custom Code
  customCode: {
    requiredPlan: 'pro',
    notAvailableDuringTrial: true,
    name: 'Custom Code',
    path: '/settings/code'
  },

  /* ------------------------------- PUBLISHING ------------------------------- */

  // Availibility
  respondentLimits: {
    requiredPlan: 'pro',
    name: 'Respondent limits',
    path: '/settings/general?modal=publishingFlow&publishingFlowTab=availability'
  },
  pausingConditions: {
    requiredPlan: 'pro',
    name: 'Pausing Conditions',
    path: '/settings/general?modal=publishingFlow&publishingFlowTab=availability'
  },

  // Link options
  customSeo: {
    requiredPlan: 'starter',
    name: 'Custom SEO settings',
    path: '/settings/general?modal=publishingFlow&publishingFlowTab=link'
  },
  customDomain: {
    requiredPlan: 'pro',
    name: 'Custom domains',
    path: '/settings/general?modal=publishingFlow&publishingFlowTab=link'
  },

  /* ------------------------------ NOTIFICATIONS ----------------------------- */

  customEmail: {
    requiredPlan: 'starter',
    name: 'Custom email content',
    path: '/notifications'
  },
  smtp: {
    requiredPlan: 'pro',
    name: 'Simple Mail Transfer Protocol (SMTP)',
    path: '/notifications'
  },
  customNotificationRecipients: {
    requiredPlan: 'pro',
    name: 'Custom notification recipients (comma separated list of email addresses)',
    path: '/notifications'
  },

  /* ----------------------------- THANK YOU PAGES ---------------------------- */

  redirectOnCompletion: {
    requiredPlan: 'starter',
    name: 'Redirect on completion',
    path: '/build'
  },

  /* ---------------------------------- OTHER --------------------------------- */

  teamwork: {
    requiredPlan: 'starter',
    name: 'Teamwork'
  },

  liveChatSupport: {
    requiredPlan: 'pro',
    disableIfLTD: true,
    name: 'Live chat support'
  },

  collaborators: {
    requiredPlan: 'starter',
    name: 'Collaborators'
  },

  unlimitedForms: {
    requiredPlan: 'starter',
    name: 'Unlimited forms'
  },

  moreResponses: {
    requiredPlan: 'pro',
    name: 'More responses'
  },

  removeBranding: {
    requiredPlan: 'pro',
    name: 'QuestionScout branding removal'
  },

  ai: {
    requiredPlan: 'pro',
    name: 'AI sentiment analysis'
  }
};

export default features;
