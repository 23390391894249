import { ioEmitRequest } from './helpers';

const deleteSubmissionsWorkspace = (formId, setSubmissionsWorkspaces) => (submissionsWorkspaceId, params) => {
  ioEmitRequest('deleteSubmissionsWorkspace', {
    form: formId,
    submissionsWorkspace: submissionsWorkspaceId,
    params: params
  });

  setSubmissionsWorkspaces((submissionsWorkspaces) => {
    return submissionsWorkspaces.filter(({ _id }) => _id !== submissionsWorkspaceId);
  });
};

export default deleteSubmissionsWorkspace;
