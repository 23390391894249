import styles from './SwitchableFeature.module.css';

import React from 'react';

import Vr from '../Vr/Vr.js';
import { Toggle } from '../Toggle/Toggle.js';
import { Button } from '../Button/Button.js';
import { Icon } from '../Icon/Icon.js';
import Tooltip from '../Tooltip/Tooltip.js';
import Hint from '../Hint/Hint';

const SwitchableFeature = ({
  type,
  links,
  value,
  disabled,
  disabledMessage,
  title,
  afterTitle,
  description,
  tags,
  logo,
  error,
  onChange,
  onEdit,
  onTagClick
}) => {
  const isClickable = !disabled;
  const cursor = isClickable ? 'pointer' : 'default';

  let tooltipProps = null;
  if (disabled && disabledMessage) {
    tooltipProps = {
      content: disabledMessage,
      trigger: 'click',
      delay: [0, 0]
    };
  }

  const handleClick = () => {
    if (isClickable) onChange(!value);
  };

  return (
    <div className={styles.feature}>
      <div className={styles.content}>
        <div className={styles.header}>
          {type === 'dynamic' && (
            <Tooltip disabled={!tooltipProps} {...tooltipProps}>
              <span>
                <Toggle disabled={disabled} value={value} onClick={handleClick} />
              </span>
            </Tooltip>
          )}
          <div onClick={handleClick} style={{ cursor }} className={styles.title}>
            {title}
          </div>
          {error && <Hint icon="warning">{error}</Hint>}
          {afterTitle}
          {onEdit && (
            <>
              <Vr />
              <Button icon="settings" theme="transparent" onClick={onEdit}>
                Edit
              </Button>
            </>
          )}
        </div>
        <div className={styles.description}>{description}</div>

        {links && (
          <div className={styles.links}>
            {links.map((link) => (
              <>
                {link.type === 'external' && (
                  <Button href={link.href} icon="open-external" theme="black-underline" iconPlacement="right" target="_blank" style={{ margin: '0 15px 0 0'}}>
                    {link.label}
                  </Button>
                )}

                {link.type === 'internal' && (
                  <Button href={link.href} theme="white" style={{ margin: '0 15px 0 0'}}>
                    {link.label}
                  </Button>
                )}
              </>
            ))}
          </div>
        )}

        <div className={styles.tags}>
          {tags.map((tag) => (
            <div key={tag} className={styles.tag} onClick={() => onTagClick(tag)}>
              <Icon className={styles.tagIcon} id="tag" />
              {tag}
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className={styles.logo}>{typeof logo === 'string' ? <img alt="logo" src={logo} /> : logo}</div>
      </div>
    </div>
  );
};

export default SwitchableFeature;
