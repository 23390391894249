import styles from './Collapse.module.css';

import React, { useState, useEffect, useRef } from 'react';
import { Icon, IconAddWithLoader } from 'ui-components';

const Collapse = React.forwardRef(
  (
    {
      children,
      label,
      show,
      defaultShow = false,
      style,
      className,
      count,
      addButton,
      addButtonLabel,
      onAddButtonClick,
      onLabelClick,
      borderTop = false,
      borderBottom = false,
      hideLabelBorderWhenCollapsed = false,
      actionsBorderTop = false,
      actionsBorderBottom = false,
      description,
      actions
    },
    ref
  ) => {
    const [visible, setVisible] = useState(show ?? defaultShow);
    const [height, setHeight] = useState(visible ? 'auto' : 0);
    const childrenRef = useRef(null);

    const classList = [
      className,
      styles.collapse,
      visible ? styles.open : '',
      borderTop ? styles.borderTop : '',
      borderBottom ? styles.borderBottom : ''
    ];

    useEffect(() => {
      if (typeof show !== 'undefined') {
        // Controlled mode
        setVisible(Boolean(show));
      }
    }, [show]);

    const handleLabelClick =
      !onLabelClick && typeof show === 'undefined'
        ? // Uncontrolled mode
          () => setVisible((visible) => !visible)
        : // Controlled mode
          onLabelClick;

    useEffect(() => {
      if (visible) {
        setHeight((height) => {
          if (height === 'auto') {
            return height;
          } else {
            const newHeight = childrenRef.current.offsetHeight;
            setTimeout(() => setHeight('auto'), 300);

            return newHeight;
          }
        });
      } else {
        setHeight(0);
      }
    }, [visible]);

    return (
      <div ref={ref} style={style} className={classList.join(' ')}>
        <div className={styles.collapseLabel} style={!visible && hideLabelBorderWhenCollapsed ? { border: 'none' } : undefined}>
          <div onClick={handleLabelClick} className={styles.collapseLabelTitle}>
            <Icon id="dropdown-open" className={!visible ? styles.rotate : ''} />
            {label}
          </div>

          {addButton && (
            <div className={styles.collapseLabelAddButton}>
              <div className={styles.collapseLabelAddButtonLabel}>{addButtonLabel}</div>
              <IconAddWithLoader loading={false} onClick={onAddButtonClick} className={styles.collapseLabelAddButtonIcon} />
            </div>
          )}

          {!isNaN(count) && <div className={styles.collapseCount}>{count}</div>}
        </div>
        <div
          className={[styles.collapseChildren, visible ? styles.show : styles.hide].join(' ')}
          style={{
            height,
            // Fix for Select content cut in collapses
            ...(height === 'auto' ? { overflow: 'visible' } : null)
          }}>
          <div className={styles.collapseChildrenContent} ref={childrenRef}>
            {description && <div className={styles.labelDescription}>{description}</div>}
            {actions && (
              <div
                className={[
                  styles.labelActions,
                  actionsBorderTop ? styles.borderTop : '',
                  actionsBorderBottom ? styles.borderBottom : ''
                ].join(' ')}>
                {actions}
              </div>
            )}

            {visible && children}
          </div>
        </div>
      </div>
    );
  }
);

export default Collapse;
