import { useMemo, useContext } from 'react';

import { IntegrationsContext } from 'contexts';

import CONFIG from './config/helpScout';

// Fallback for special case use of HelpScout outside of IntegrationsContext (in SomethingWentWrongBoundary);
const fallbackGetHelpScoutAPI = () => {
  window.Beacon('init', CONFIG.personalKey);
  window.Beacon('config', CONFIG.beaconConfig);

  return window.Beacon;
};

const useHelpScout = () => {
  /* ---------------------------------- STATE --------------------------------- */

  const context = useContext(IntegrationsContext);

  const getHelpScoutAPI = context ? context.getHelpScoutAPI : fallbackGetHelpScoutAPI;

  /* ----------------------------------- API ---------------------------------- */

  return useMemo(() => {
    return {
      close: async () => {
        const helpScoutAPI = await getHelpScoutAPI();
        if (helpScoutAPI) {
          helpScoutAPI('close');
        }
      },

      open: async () => {
        const helpScoutAPI = await getHelpScoutAPI();
        if (helpScoutAPI) {
          helpScoutAPI('open');
        }
      },

      openArticle: async (articleKey, mode = { type: 'modal' }) => {
        const helpScoutAPI = await getHelpScoutAPI();
        if (helpScoutAPI) {
          const articleId = CONFIG.articles[articleKey];
          if (!articleId) {
            if (process.env.NODE_ENV === 'development') {
              throw new Error(`DEBUG: article '${articleKey}' was not defined in integrations/config/helpScout : {articles}.`);
            }
            return;
          }

          helpScoutAPI('article', articleId, mode);
        }
      },

      openAsk: async () => {
        const helpScoutAPI = await getHelpScoutAPI();
        if (helpScoutAPI) {
          helpScoutAPI('open');
          helpScoutAPI('navigate', '/ask/');
          helpScoutAPI('prefill', {
            subject: '',
            text: ''
          });
        }
      },

      openReportIssue: async (errorMessage) => {
        const helpScoutAPI = await getHelpScoutAPI();
        if (helpScoutAPI) {
          let messageTemplate = 'Your feedback:\n\n...';
          if (errorMessage) {
            messageTemplate += `\n\nI came across this issue:\n\n ${errorMessage}\n\n\n\n${window.location.href}`;
          }

          helpScoutAPI('open');
          helpScoutAPI('navigate', '/ask/');
          helpScoutAPI('prefill', {
            subject: '[BUG REPORT] I would like to report an issue',
            text: messageTemplate
          });
        }
      }
    };
  }, [getHelpScoutAPI]);
};

export default useHelpScout;
