/* --------------------------------- CONFIG --------------------------------- */

const DIRECTION_OPTIONS_BY_DATA_TYPE = {
  string: [
    { value: 'asc', label: 'A > Z' },
    { value: 'desc', label: 'Z > A' }
  ],
  number: [
    { value: 'asc', label: 'Smallest > Biggest' },
    { value: 'desc', label: 'Biggest > Smallest' }
  ],
  date: [
    { value: 'asc', label: 'Oldest > Newest' },
    { value: 'desc', label: 'Newest > Oldest' }
  ],
  boolean: [
    { value: 'asc', label: 'No > Yes' },
    { value: 'desc', label: 'Yes > No' }
  ],
  countable: [
    { value: 'asc', label: 'Less items > More items' },
    { value: 'desc', label: 'More items > Less items' }
  ],
  fillable: [
    { value: 'asc', label: 'Not filled > Filled' },
    { value: 'desc', label: 'Filled > Not filled' }
  ]
};

export default DIRECTION_OPTIONS_BY_DATA_TYPE;

/* ------------------------------- VALIDATION ------------------------------- */

if (process.env.NODE_ENV === 'development') {
  const ALLOWED_DIRECTIONS = ['asc', 'desc'];

  for (const type in DIRECTION_OPTIONS_BY_DATA_TYPE) {
    const options = DIRECTION_OPTIONS_BY_DATA_TYPE[type];
    for (const option of options) {
      if (
        !option ||
        // Required props
        !option.value ||
        !option.label ||
        // Allowed values
        !ALLOWED_DIRECTIONS.includes(option.value)
      ) {
        throw new Error(`DEBUG: invalid config for ${type} sorting direction. ${JSON.stringify(option, 0, 2)}`);
      }
    }
  }
}
