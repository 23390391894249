import { ioEmitRequest } from './helpers';

export default (formId) => async (params) => {
  if (!formId) return null;
  return await ioEmitRequest('scheduleSubmissionsExport', {
    form: formId,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...params
  });
};
