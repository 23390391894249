import styles from './TabularNumber.module.css';

import React from 'react';

// Used to display numbers in mono-width mode (looks better when pagination/counters increments)
const TabularNumber = ({ strong, ...rest }) => {
  if (strong) {
    return <strong className={styles.tabularNumber} {...rest} />;
  } else {
    return <span className={styles.tabularNumber} {...rest} />;
  }
};

export default TabularNumber;
