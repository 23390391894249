import React, { useContext, useMemo, useState, useCallback } from 'react';

// Used to reduce DOM reflows when detecting left offset of sticky columns
const TableCellContext = React.createContext(null);

/* -------------------------------------------------------------------------- */
/*                              CONTEXT PROVIDER                              */
/* -------------------------------------------------------------------------- */

/*
    This logic was separated from main table component, to prevent useTable hook from
    being called on every context change. This reduces full table re-renders due to sticky
    columns updates significantly (up to 3x less).
*/
export const TableCellContextManager = React.memo(({ visibleColumns, children }) => {
  const columnsIndexesById = useMemo(() => {
    const map = new Map();

    for (let i = 0; i < visibleColumns.length; i++) {
      map.set(visibleColumns[i].id, i);
    }

    return map;
  }, [visibleColumns]);

  // Widths of some columns are stored (needed for computing left offsets of sticky columns)
  const [columnsWidthsById, setColumnsWidthsById] = useState({});
  const registerColumnWidth = useCallback((id, width) => {
    setColumnsWidthsById((columnsWidths) => {
      const hasChanged = columnsWidths[id] !== width;

      if (hasChanged) {
        return { ...columnsWidths, [id]: width };
      } else {
        return columnsWidths;
      }
    });
  }, []);

  /*
    Computes given column left offset based on widths of columns that are before
    Left offset of element's cant be used directly because width change of
    preceding columns can affect left offset, and ResizeObserver would miss that.
  */
  const getColumnLeftOffset = useCallback(
    (id) => {
      const columnIndex = columnsIndexesById.get(id);

      if (typeof columnIndex === 'undefined') {
        if (process.env.NODE_ENV === 'development') {
          throw new Error('DEBUG: requested left offset of non existing column');
        }
        return null;
      }

      let leftOffset = 0;

      for (let i = 0; i < columnIndex; i++) {
        const precedingColumnWidth = columnsWidthsById[visibleColumns[i].id];
        if (typeof precedingColumnWidth === 'undefined') {
          // Width of one or more preceding columns required for computations
          // wasn't obtained yet, need early return
          return undefined;
        }

        leftOffset += precedingColumnWidth;
      }

      return leftOffset;
    },
    [columnsIndexesById, visibleColumns, columnsWidthsById]
  );

  const context = useMemo(() => {
    return {
      registerColumnWidth,
      getColumnLeftOffset
    };
  }, [registerColumnWidth, getColumnLeftOffset]);

  return <TableCellContext.Provider value={context}>{children}</TableCellContext.Provider>;
});

/* -------------------------------------------------------------------------- */
/*                                ACCESSOR HOOK                               */
/* -------------------------------------------------------------------------- */

export const useTableCellContext = () => useContext(TableCellContext);
