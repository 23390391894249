import styles from './OpenCloseIcon.module.css';

import React from 'react';

import { Icon } from '../Icon/Icon';

const OpenCloseIcon = React.memo(
  React.forwardRef(({ opened }, ref) => {
    return (
      <>
        <div className={[styles.openCloseIcon, opened ? styles.open : ''].join(' ')} ref={ref}>
          <Icon id="dropdown-closed-thin" />
          <Icon id="dropdown-closed-thin" />
        </div>
      </>
    );
  })
);

export default OpenCloseIcon;
