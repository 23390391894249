import styles from './Modal.module.css';

import React, { Component } from 'react';
import ReactModal from 'react-modal';

import { Loader } from 'ui';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 128
  },  
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    overflow: 'visible',
    borderRadius: 8,
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 0
  }
};

ReactModal.setAppElement('#root');

export class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let {
      isOpen, onRequestClose, onAfterOpen, children, header, footer, width, loading
    } = this.props;

    customStyles.content.width = width;

    return (
      <>
        <ReactModal isOpen={isOpen}
                    onAfterOpen={onAfterOpen}
                    onRequestClose={onRequestClose}
                    style={customStyles}>
          <div className={styles.header}>
            <span>{header}</span>
          </div>
          <div className={styles.content}>
            {children}
          </div>
          <div className={styles.footer}>
            {footer}
          </div>

          {loading && <div className={styles.loader}>
            <Loader size={30} />
          </div>}
        </ReactModal>
      </>
    )
  }
}