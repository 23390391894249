import styles from './SortingModeSelect.module.css';

import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Popover } from '../Tooltip/Tooltip';
import OpenCloseIcon from '../OpenCloseIcon/OpenCloseIcon';
import { Icon } from '../Icon/Icon';

/* -------------------------------------------------------------------------- */
/*                                   CONFIG                                   */
/* -------------------------------------------------------------------------- */

const SORTING_ORDERS = [
  { value: 'asc', label: 'Ascending' },
  { value: 'desc', label: 'Descending' }
];

/* -------------------------------------------------------------------------- */
/*                                SINGLE OPTION                               */
/* -------------------------------------------------------------------------- */

const SortingOption = ({ label, value, onToggleOn }) => {
  return (
    <button
      className={[styles.option, value ? styles.enabled : null].join(' ')}
      onClick={() => {
        if (!value) onToggleOn();
      }}>
      <div className={styles.optionLabel}>{label}</div>
      <div className={styles.optionCheck}>{value && <Icon id="check" />}</div>
    </button>
  );
};

/* -------------------------------------------------------------------------- */
/*                           OPTIONS POPOVER CONTENT                          */
/* -------------------------------------------------------------------------- */

const SortingOptions = ({ value, onChange, options = [] }) => {
  const sortBy = value?.[0];
  const order = value?.[1];

  /* ----------------------------- EVENT HANDLERS ----------------------------- */

  const handleSortByChange = (nextSortBy) => {
    const option = options.find((o) => o.value === nextSortBy);

    // Should not happen
    if (!option) return;

    const nextOrder = order || SORTING_ORDERS[0].value;
    onChange([nextSortBy, nextOrder]);
  };

  const handleOrderChange = (nextOrder) => {
    const nextSortBy = sortBy || options[0].value || null;
    onChange([nextSortBy, nextOrder]);
  };

  const handleClearSorting = () => {
    onChange(null);
  };

  /* ----------------------------------- JSX ---------------------------------- */

  return (
    <div className={styles.sortingOptions}>
      <div className={styles.optionsGroup}>
        <div className={styles.optionsGroupHeader}>Sort by</div>
        {options.map(({ value, label }) => {
          return <SortingOption key={value} label={label} value={sortBy === value} onToggleOn={() => handleSortByChange(value)} />;
        })}
      </div>

      <div className={styles.optionsGroup}>
        <div className={styles.optionsGroupHeader}>Order</div>
        {SORTING_ORDERS.map(({ value, label }) => {
          return <SortingOption key={value} label={label} value={order === value} onToggleOn={() => handleOrderChange(value)} />;
        })}
      </div>

      <div className={styles.sortingOptionsGroup}>
        <div className={styles.optionsGroupHeader}>Manual</div>
        <SortingOption label="Manual ordering" value={!value} onToggleOn={handleClearSorting} />
      </div>
    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

// Value format:

const SortingModeSelect = ({
  className = '',
  value, // For example: ['name', 'asc'] or null for manual.
  onChange,
  options = [],
  label = 'Sort by:'
}) => {
  const [isOpen, setIsOpen] = useState(false);

  /* ----------------------------- EVENT HANLDERS ----------------------------- */

  const handleContainerClick = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const handleRequestClose = () => {
    setIsOpen(false);
  };

  const handleChange = useCallback(
    (nextValue) => {
      onChange(nextValue);
    },
    [onChange]
  );

  /* ----------------------------------- JSX ---------------------------------- */

  const visibleLabel = value
    ? options.find((o) => o.value === value?.[0])?.label ?? '[UNDEFINED OPTION]'
    : // When value === null
      'Manual ordering';

  return (
    <div className={[className, styles.sortingModeSelect].join(' ')}>
      <Popover
        theme="black"
        hideOnScroll={true}
        arrow={false}
        offset={[0, 5]}
        visible={isOpen}
        backdrop={false}
        onRequestClose={handleRequestClose}
        placement="bottom-start"
        content={<SortingOptions value={value} onChange={handleChange} options={options} />}>
        <div className={styles.sortingModeSelectPreview} onClick={handleContainerClick}>
          {label && <div className={styles.label}>{label}</div>}
          <div className={styles.currentOptionLabel}>{visibleLabel}</div>
          <div className={styles.dropdownIcon}>
            <OpenCloseIcon opened={isOpen} />
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default SortingModeSelect;
