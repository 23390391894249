import styles from './Modal.module.css';

import React, { useState, useMemo, useEffect } from 'react';
import ReactModal from 'react-modal';

import { Loader } from 'ui';

import { useTimeout } from 'helpers';

ReactModal.setAppElement('#root');

const THEME_STYLES = {
  default: {
    overflow: 'visible'
  },
  tight: {
    overflow: 'hidden',
    padding: 0
  }
};

const CLOSE_TIMEOUT_MS = 500;

/* -------------------------------------------------------------------------- */
/*                                 BASE MODAL                                 */
/* -------------------------------------------------------------------------- */

const DefaultModal = ({
  theme = 'default',
  zIndex = 100,
  isOpen,
  onRequestClose,
  onAfterOpen,
  children,
  title,
  footer,
  width,
  loading,
  description
}) => {
  const customStyles = useMemo(
    () => ({
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        overflow: 'visible',
        borderRadius: 8,
        marginRight: '-50%',
        border: 0,

        width,
        ...THEME_STYLES[theme]
      },
      overlay: {
        zIndex: zIndex
      }
    }),
    [theme, width, zIndex]
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      closeTimeoutMS={CLOSE_TIMEOUT_MS}>
      {(title || description) && (
        <div className={styles.header}>
          {title && <div className={styles.title}>{title}</div>}
          {description && <div className={styles.description}>{description}</div>}
        </div>
      )}
      <div className={styles.content}>{children}</div>
      {footer && <div className={styles.footer}>{footer}</div>}

      {loading && (
        <div className={styles.loader}>
          <Loader size={30} />
        </div>
      )}
    </ReactModal>
  );
};

/* -------------------------------------------------------------------------- */
/*                           MODAL FOR SPECIAL CASES                          */
/* -------------------------------------------------------------------------- */

/*
  Fix for profile modal.
  Enforced unmount will reorder modal to the top on mount even if in the
  code it's defined earlier than other modals.
*/

const ModalWithEnforcedUnmount = ({ isOpen, ...rest }) => {
  const [canBeUnmounted, setCanBeUnmounted] = useState(true);

  const closeTimeout = useTimeout();

  useEffect(() => {
    if (isOpen) {
      closeTimeout(null);
      setCanBeUnmounted(false);
    } else {
      // Makes sure that modal is not unmounted before closing animation finishes
      closeTimeout(() => {
        setCanBeUnmounted(true);
      }, CLOSE_TIMEOUT_MS);
    }
  }, [isOpen, closeTimeout]);

  return canBeUnmounted ? null : <DefaultModal isOpen={isOpen} {...rest} />;
};

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

export const Modal = ({ showOnTop = false, ...rest }) => {
  if (showOnTop) {
    return <ModalWithEnforcedUnmount {...rest} />;
  } else {
    return <DefaultModal {...rest} />;
  }
};
