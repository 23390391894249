/* --------------------------- PARTIAL VALIDATORS --------------------------- */

// Validates payment settings related to taxes
const validatePaymentsTaxes = ({ payments, fields = [] }) => {
  const errors = {};

  if (payments.taxType) {
    if (payments.taxType === 'flat' && !payments.taxRate) {
      errors.taxRate = 'Tax rate has to be a positive percentage value.';
    }
    if (payments.taxType === 'dynamic') {
      const userCountryField = fields.find((f) => f._id === payments.userCountryField);
      const userTaxNumberField = fields.find((f) => f._id === payments.userTaxNumberField);

      if (!payments.ownerCountry) {
        errors.ownerCountry = 'You need to specify your country.';
      }
      if (!userCountryField) {
        errors.userCountryField = 'You need to choose a field that is used to collect countries from users.';
      }
      if (!userTaxNumberField) {
        errors.userTaxNumberField = 'You need to choose a field that is used to collect tax numbers from users.';
      }

      // Optional
      if (payments.userStateField) {
        const userStateField = fields.find((f) => f._id === payments.userStateField);
        if (!userStateField) {
          errors.userStateField = 'You need to choose a field that is used to collect states from users.';
        }
      }
    }
  }

  return Object.keys(errors).length ? errors : false;
};

// Validates payments general settings
const validatePaymentsSettings = ({ payments, fields = [] }) => {
  const errors = {};

  if (!payments.collectType) {
    errors.collectType = 'Payment collection method is not specified.';
  }
  if (payments.collectType === 'flat' && payments.collectFlatFee === null) {
    errors.collectFlatFee = 'Flat fee has to be a non negative value.';
  }
  if (payments.collectType === 'field') {
    const field = fields.find((f) => f._id === payments.collectField);

    if (!field || field.type !== 'shortText' || field.format !== 'number') {
      errors.collectField = 'You need to choose a number field.';
    }
  }
  if (payments.collectType === 'variable' && !payments.collectVariable) {
    errors.collectVariable = 'You need to choose a calculation variable.';
  }

  return Object.keys(errors).length ? errors : false;
};

/* ----------------------------- MAIN VALIDATOR ----------------------------- */

// Payments error format: { taxes: {...}, settings: {...} }
const validateFormPayments = ({ payments, fields = [] }) => {
  const errors = {};

  if (payments) {
    errors.taxes = validatePaymentsTaxes({ payments, fields });
    errors.settings = validatePaymentsSettings({ payments, fields });
  }

  return Object.keys(errors).length ? errors : false;
};

export default validateFormPayments;
