export default function sortFieldsByPosition(arr) {
  return arr.sort((a, b) => {
    if (a.position < b.position) {
      return -1;
    }

    if (a.position > b.position) {
      return 1;
    }

    return 0;
  });
};
