import styles from './FilteringMatrix.module.css';

import React, { useState, useEffect, useCallback, useMemo } from 'react';

import InlineSelect from '../InlineSelect/InlineSelect';
import { Select as SelectComponent } from '../Select/Select';
import { Input } from '../Input/Input';
import DateInput from '../DateInput/DateInput';
import { Button } from '../Button/Button';

// Config
import CONDITION_OPTIONS_BY_DATA_TYPE, { assertConditionOptions } from './conditionOptionsByDataType';

const SUPPORTED_DATA_TYPES = Object.keys(CONDITION_OPTIONS_BY_DATA_TYPE);

const modesOptions = [
  { value: 'and', label: 'And' },
  { value: 'or', label: 'Or' }
];

// Select with fixed positioning
const Select = (props) => <SelectComponent menuPosition="fixed" menuPlacement="auto" menuPortalTarget={document.body} {...props} />;

/* -------------------------------------------------------------------------- */
/*                              INPUTS COMPONENTS                             */
/* -------------------------------------------------------------------------- */

const NUMBER_SELECT_MAX_VALUES = 100;

// Displays either text input or select depending on passed props
const AdaptiveNumberInput = ({ value, onChange, min, max, step, ...rest }) => {
  const parsedStep = Number(step);
  const parsedMin = Number(min);
  const parsedMax = Number(max);

  // Range with two selects for integer values (up to 100 possible values)
  if (
    Number.isInteger(parsedStep) &&
    Number.isInteger(parsedMin) &&
    Number.isInteger(parsedMax) &&
    (parsedMax - parsedMin + 1) / parsedStep < NUMBER_SELECT_MAX_VALUES
  ) {
    const valueOptions = [];

    let parsedValue = parseInt(value);

    // Something is wrong with input, so options will be prepared as if there was no input yet.
    if (parsedValue < parsedMin || parsedValue > parsedMax) {
      parsedValue = NaN;
    }

    for (let value = parsedMin; value <= parsedMax; value += parsedStep) {
      valueOptions.push({ value: value, label: String(value) });
    }

    const handleChange = ({ value: nextValue }) => onChange(nextValue);
    return <Select options={valueOptions} placeholder="" value={value} onChange={handleChange} {...rest} />;
  }
  // Range with two text inputs
  else {
    const handleChange = (nextValue) => onChange(nextValue);
    return <Input type="number" value={value} onChange={handleChange} min={min} max={max} step={step} {...rest} />;
  }
};

// Displays either text inputs or selects depending on passed props
const AdaptiveNumberRangeInput = ({ value: values, onChange, min, max, step, ...rest }) => {
  const parsedStep = Number(step);
  const parsedMin = Number(min);
  const parsedMax = Number(max);

  const [valueA = '', valueB = ''] = values || [];

  // Range with two selects for integer values (up to 100 possible values)
  if (
    Number.isInteger(parsedStep) &&
    Number.isInteger(parsedMin) &&
    Number.isInteger(parsedMax) &&
    (parsedMax - parsedMin + 1) / parsedStep < NUMBER_SELECT_MAX_VALUES
  ) {
    const valueAOptions = [];
    const valueBOptions = [];

    let parsedValueA = parseInt(valueA);
    let parsedValueB = parseInt(valueB);

    // Something is wrong with input, so options will be prepared as if there was no input yet.
    if (
      parsedValueA < parsedMin ||
      parsedValueA > parsedMax ||
      parsedValueB < parsedMin ||
      parsedValueB > parsedMax ||
      parsedValueA > parsedValueB
    ) {
      parsedValueA = NaN;
      parsedValueB = NaN;
    }

    const valueARightBoundary = isNaN(parsedValueB) ? parsedMax : parsedValueB;
    const valueALeftBoundary = isNaN(parsedValueA) ? parsedMin : parsedValueA;

    for (let value = parsedMin; value <= valueARightBoundary; value += parsedStep) {
      valueAOptions.push({ value: value, label: String(value) });
    }
    for (let value = valueALeftBoundary; value <= parsedMax; value += parsedStep) {
      valueBOptions.push({ value: value, label: String(value) });
    }

    const handleChangeA = ({ value: nextValue }) => onChange([nextValue, valueB]);
    const handleChangeB = ({ value: nextValue }) => onChange([valueA, nextValue]);

    return (
      <div className={styles.rangeInput}>
        <Select options={valueAOptions} placeholder="" value={valueA} onChange={handleChangeA} {...rest} />
        <span>and</span>
        <Select options={valueBOptions} placeholder="" value={valueB} onChange={handleChangeB} {...rest} />
      </div>
    );
  }
  // Range with two text inputs
  else {
    const handleChangeA = (nextValue) => onChange([nextValue, valueB]);
    const handleChangeB = (nextValue) => onChange([valueA, nextValue]);

    const valueAMax = valueB ?? parsedMax;
    const valueBMin = valueA ?? parsedMin;

    return (
      <div className={styles.rangeInput}>
        <Input
          type="number"
          value={valueA}
          onChange={handleChangeA}
          {...rest}
          min={min}
          max={isNaN(valueAMax) ? undefined : valueAMax}
          step={step}
        />
        <span>and</span>
        <Input
          type="number"
          value={valueB}
          onChange={handleChangeB}
          {...rest}
          min={isNaN(valueBMin) ? undefined : valueBMin}
          max={max}
          step={step}
        />
      </div>
    );
  }
};

// Wrapper for Select component that adjust value/onChange behavior to match the one of other possible components like Input/DateTime.
const SelectWithNormalizedOnChange = ({ value, onChange, isMulti, ...rest }) => {
  const handleChange = useCallback(
    (data) => {
      if (!data) {
        onChange(null);
        return;
      }

      if (isMulti) {
        onChange(data.map((option) => option.value));
      } else {
        onChange(data.value);
      }
    },
    [isMulti, onChange]
  );

  return <Select value={value} onChange={handleChange} isMulti={isMulti} {...rest} />;
};

const INPUTS_COMPONENTS_BY_TYPE = {
  number: AdaptiveNumberInput,
  range: AdaptiveNumberRangeInput,

  text: (props) => {
    return <Input type="text" {...props} />;
  },
  date: (props) => {
    return <DateInput {...props} />;
  },
  select: (props) => {
    return <SelectWithNormalizedOnChange isClearable={false} {...props} />;
  },
  'multi-select': (props) => {
    return <SelectWithNormalizedOnChange isClearable={false} {...props} isMulti />;
  }
};

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

const getConditionOptionsByType = (type) => {
  if (process.env.NODE_ENV === 'development') {
    if (!SUPPORTED_DATA_TYPES.includes(type)) {
      throw new Error('DEBUG: unsupported type ' + type);
    }
  }

  return CONDITION_OPTIONS_BY_DATA_TYPE[type];
};

const geInputComponentByType = (type) => {
  if (!type) return null;

  if (process.env.NODE_ENV === 'development') {
    if (!Object.keys(INPUTS_COMPONENTS_BY_TYPE).includes(type)) {
      throw new Error('DEBUG: unsupported input type ' + type);
    }
  }

  return INPUTS_COMPONENTS_BY_TYPE[type];
};

/* -------------------------------------------------------------------------- */
/*                                ROW COMPONENT                               */
/* -------------------------------------------------------------------------- */

const FilteringRow = React.memo(
  ({
    readOnly,
    showEmptyErrors,
    // Data
    index,
    value: currentRowState,
    onRowChange,
    onRowRemoval,
    mode,
    onModeChange,
    // Computed config
    columnOptions,
    columnsById
  }) => {
    const { column: currentColumn = null, condition: currentCondition = null, value: currentValue = null } = currentRowState;

    /* --------------------------------- CONFIG --------------------------------- */

    const columnData = columnsById[currentColumn];
    const isDisabled = columnData?.disabled;

    // Condition options are a sum of all predefined options selected by filtersAs,
    // and all manually passed conditions for current column
    const conditionOptions = useMemo(() => {
      if (!columnData?.filtersAs) {
        return [];
      }

      const predefinedConditionOptions = getConditionOptionsByType(columnData?.filtersAs);
      const customConditionOptions = columnData?.conditions || [];

      if (process.env.NODE_ENV === 'development') {
        assertConditionOptions(customConditionOptions);
      }

      return [...predefinedConditionOptions, ...customConditionOptions];
    }, [columnData?.conditions, columnData?.filtersAs]);

    /* ---------------------------------- VALUE --------------------------------- */

    const currentColumnOption = columnOptions.find((o) => o.value === currentColumn);
    const isCurrentColumnOptionUnknown = Boolean(currentColumn && !currentColumnOption);

    const currentConditionOption = conditionOptions.find(({ value }) => value === currentCondition);
    const isCurrentConditionOptionUnknown = Boolean(currentCondition && !currentConditionOption);

    const InputComponent = currentConditionOption ? geInputComponentByType(currentConditionOption.input) : null;

    // Additional props passed to InputComponent
    const inputComponentProps = InputComponent
      ? {
          // Global column inputProps are passed to Input component of every condition
          ...columnData?.inputProps,
          // but per-condition inputProps props can overwrite these
          ...currentConditionOption?.inputProps
        }
      : null;

    /* ----------------------------- EVENT HANDLERS ----------------------------- */

    const handleRemoval = () => onRowRemoval(index);

    const handleChange = useCallback(
      (property, nextValue) => {
        const changes = { [property]: nextValue === '' ? null : nextValue ?? null };

        // Resets condition and value when column changes (condition options may change)
        if (property === 'column') {
          changes.condition = null;
          changes.value = null;
        }
        // Resets value when condition changes (input type may change)
        if (property === 'condition') {
          changes.value = null;
        }

        onRowChange({ ...currentRowState, ...changes }, index);
      },
      [index, currentRowState, onRowChange]
    );

    /* ----------------------------------- JSX ---------------------------------- */

    // Mode selection (first column)
    let modeInputJsx = null;
    if (index === 0) {
      modeInputJsx = 'When';
    } else if (index === 1) {
      modeInputJsx = <InlineSelect disabled={readOnly} options={modesOptions} value={mode} onChange={onModeChange} />;
    } else {
      modeInputJsx = modesOptions.find((option) => option.value === mode)?.label;
    }

    return (
      <div className={[styles.filteringRow, isDisabled ? styles.disabled : '', readOnly ? styles.readOnly : ''].join(' ')}>
        <div className={styles.modeInput}>{modeInputJsx}</div>
        <div className={styles.columnInput}>
          <Select
            placeholder="Choose column"
            options={columnOptions}
            disabled={readOnly}
            value={
              isCurrentColumnOptionUnknown
                ? {
                    value: currentColumn,
                    label: <b style={{ color: '#F05252', fontWeight: 500 }}>[UNKNOWN]</b>
                  }
                : currentColumn
            }
            error={isCurrentColumnOptionUnknown}
            onChange={(data) => handleChange('column', data.value)}
          />
        </div>
        <div className={styles.conditionInput}>
          {isDisabled && <span>Hidden fields can't be filtered</span>}
          {!isDisabled && currentColumn && (
            <InlineSelect
              placeholder="Choose condition"
              options={conditionOptions}
              disabled={isCurrentConditionOptionUnknown || readOnly}
              value={currentCondition}
              error={isCurrentConditionOptionUnknown || showEmptyErrors ? !currentCondition : false}
              onChange={(value) => handleChange('condition', value)}
            />
          )}
        </div>
        <div className={styles.valueInput}>
          {!isDisabled && InputComponent && (
            <InputComponent
              error={false || showEmptyErrors ? !currentValue : false}
              {...inputComponentProps}
              disabled={readOnly}
              value={currentValue}
              onChange={(value) => handleChange('value', value)}
            />
          )}
          {Boolean(!isDisabled && currentCondition && !currentColumnOption) && (
            <Input disabled error={true} value={JSON.stringify(currentValue)} />
          )}
        </div>
        {!readOnly && (
          <div className={styles.deleteButton}>
            <Button theme="transparent" icon="close" onClick={handleRemoval} />
          </div>
        )}
      </div>
    );
  }
);

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENT                               */
/* -------------------------------------------------------------------------- */

const FilteringMatrix = React.memo(
  ({
    className = '',

    placeholder,
    disabled: readOnly = false,
    showEmptyErrors = false,

    /*
      [
        {
          id: string,
          label: string,

          filtersAs: string, // what pre-defined options to use
          options: [
            {
              value: string,
              label: string,
              input: string,
              inputProps: object
            }, ...
          ], 

          disabled: boolean
        }, ...
      ]
    */
    columns = [],
    value: values = [], // [ {column: string, condition: string, value: string/array}, ...]
    mode = 'and', // 'and' / 'or'

    onChange,
    onModeChange
  }) => {
    /* ---------------------------------- STATE --------------------------------- */

    // Local controlled state that allows better re-rendering optimizations
    const [localValues, setLocalValues] = useState(values);
    useEffect(() => {
      setLocalValues(values);
    }, [values]);

    const { columnOptions, columnsById } = useMemo(() => {
      const columnOptions = columns.map(({ id, label, disabled }) => ({ value: id, label }));

      const columnsById = {};
      for (const column of columns) {
        columnsById[column.id] = column;
      }
      return { columnOptions, columnsById };
    }, [columns]);

    /* ----------------------------- EVENT HANDLERS ----------------------------- */

    const handleRowChange = useCallback(
      (nextRowValue, rowIndex) => {
        let nextValues = null;

        setLocalValues((localValues) => {
          nextValues = [...localValues];
          nextValues[rowIndex] = nextRowValue;
          return nextValues;
        });

        onChange(nextValues);
      },
      [onChange]
    );

    const handleRowRemoval = useCallback(
      (rowIndex) => {
        let nextValues = null;

        setLocalValues((localValues) => {
          nextValues = [...localValues];
          nextValues.splice(rowIndex, 1);
          return nextValues;
        });

        onChange(nextValues);
      },
      [onChange]
    );

    /* ----------------------------------- JSX ---------------------------------- */

    let rowsJsx = null;
    if (localValues?.length) {
      rowsJsx = localValues.map((value, i) => {
        return (
          <FilteringRow
            key={'' + i + value.column}
            index={i}
            value={value}
            onRowChange={handleRowChange}
            onRowRemoval={handleRowRemoval}
            mode={mode}
            onModeChange={onModeChange}
            columnOptions={columnOptions}
            columnsById={columnsById}
            readOnly={readOnly}
            showEmptyErrors={showEmptyErrors}
          />
        );
      });
    }

    return (
      <div className={[className, styles.filteringMatrix].join(' ')}>
        {rowsJsx ? rowsJsx : <span className={styles.placeholder}>{placeholder}</span>}
      </div>
    );
  }
);

FilteringMatrix.CONDITION_OPTIONS_BY_DATA_TYPE = CONDITION_OPTIONS_BY_DATA_TYPE;
FilteringMatrix.SUPPORTED_DATA_TYPES = SUPPORTED_DATA_TYPES;

export default FilteringMatrix;
