import styles from './UpdatePlan.module.css';

import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import io from 'io.js';
import config from 'config.js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import asyncSocketAckEmit from 'helpers/asyncSocketAckEmit.js';
import { formatDuration, intervalToDuration } from 'date-fns';

import { Panel, Button, CreditCard, Checkbox, Header, FormRow, Radio, Select } from 'ui-components';

import { MainContext } from 'contexts/main.js';

const plansPrice = {
  trial: 0,
  personal: 5,
  starter: 10,
  pro: 20
};

const responsesPrice = {
  0: 0,
  500: 0,
  2500: 0,
  10000: 0,
  20000: 25,
  30000: 50,
  40000: 75,
  50000: 100,
  100000: 200,
  200000: 300
};

const CheckoutForm = ({
  selectedPlan,
  setLoading,
  isUpgrading,
  setSuccess,
  onSuccess,
  allowPaymentInformationChange,
  allowQuantityUpdate,
  planResponses
}) => {
  const { user, users } = useContext(MainContext);

  const guests = users.filter((u) => u.role === 'guest').sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt));
  const collaborators = users
    .filter((u) => u.role === 'user' || u.role === 'admin')
    .sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt));

  const stripe = useStripe();
  const elements = useElements();

  const [ccError, setCcError] = useState(null);
  const [summary, setSummary] = useState({});
  const [quantity, setQuantity] = useState(null);
  const [usersToRemove, setUsersToRemove] = useState([]);
  const [showNewCC, setShowNewCC] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const getData = async () => {
    setLoading(true);

    const subscriptionInformations = await asyncSocketAckEmit('getUserSubscriptionInformations', {
      paymentMethods: true
    });

    subscriptionInformations.paymentMethods = subscriptionInformations.paymentMethods || []; 

    setPaymentMethods(subscriptionInformations.paymentMethods);

    if (subscriptionInformations.paymentMethods.length === 0) {
      setShowNewCC(true);
    } else {
      const defaultPaymentMethods = subscriptionInformations.paymentMethods.find((paymentMethod) => paymentMethod.default);

      if (defaultPaymentMethods) setSelectedPaymentMethod(defaultPaymentMethods._id);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (selectedPlan === 'personal') {
      setUsersToRemove(users.filter((u) => u.role !== 'holder').map((u) => u._id));
    } else {
      let toRemove = [];

      if (selectedPlan === 'starter') toRemove = [...toRemove, ...guests.slice(100).map((u) => u._id)];
      toRemove = [...toRemove, ...collaborators.slice(quantity).map((u) => u._id)];

      setUsersToRemove(toRemove);
    }

    getData();
  }, [quantity]);

  useEffect(() => {
    setLoading(true);

    io.socket.emit(
      'getUserSubscriptionProposition',
      {
        plan: selectedPlan,
        users: quantity,
        responses: planResponses
      },
      (payload) => {
        setSummary(payload);
        setLoading(false);
      }
    );
  }, [quantity, usersToRemove.length]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitDisabled) return;

    setLoading(true);
    setCcError(null);

    let paymentMethod;

    if (showNewCC) {
      const newPaymentMethod = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)
      });

      if (newPaymentMethod.error) {
        setCcError(newPaymentMethod.error.message);
        setLoading(false);
        return;
      }

      paymentMethod = newPaymentMethod.paymentMethod.id;
    } else {
      paymentMethod = selectedPaymentMethod;
    }

    io.socket.emit(
      'updateUserSubscription',
      {
        paymentMethodId: paymentMethod,
        plan: selectedPlan,
        usersToRemove,
        quantity: quantity,
        responses: planResponses
      },
      ({ success, data }) => {
        setLoading(false);
        if (success) {
          setSuccess(true);
          onSuccess(data);
        } else {
          setCcError(data.message);
        }
      }
    );
  };

  const toggleUserToRemove = (type, _id) => {
    let usersToRemoveCopy = [...usersToRemove];
    const index = usersToRemove.indexOf(_id);

    if (index === -1) {
      usersToRemoveCopy.push(_id);
    } else {
      let countSelected = 0;

      if (type === 'collaborators') {
        countSelected = collaborators.filter((collaborator) => usersToRemove.indexOf(collaborator._id) === -1).length;
      } else {
        countSelected = guests.filter((guest) => usersToRemove.indexOf(guest._id) === -1).length;
      }

      if (countSelected >= 100) return;

      usersToRemoveCopy.splice(index, 1);
    }

    setUsersToRemove(usersToRemoveCopy);
  };

  const planCoundown = useMemo(
    () =>
      formatDuration(
        intervalToDuration({
          start: new Date(user.planPeriod.end),
          end: new Date()
        }),
        {
          format: ['days']
        }
      ),
    [user.planPeriod.end]
  );

  const collaboratorsNumber = useMemo(() => {
    if (selectedPlan === 'starter') {
      return [0, 1, 2, 3, 4, 5].map((number) => ({ label: number === 0 ? 'Just me' : number, value: number }));
    } else if (selectedPlan === 'pro') {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100].map((number) => ({
        label: number === 0 ? 'Just me' : number,
        value: number
      }));
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (allowQuantityUpdate) {
      setQuantity(user.plan.users);
    } else if (allowQuantityUpdate !== null) {
      setQuantity(collaborators.filter((collaborator) => usersToRemove.indexOf(collaborator._id) === -1).length);
    }
  }, [allowQuantityUpdate]);

  const isSubmitDisabled = useMemo(() => {
    const billingQuantity = user.billing.quantity - 1;

    if (selectedPlan === 'pro' && selectedPlan === user.billing.plan && planResponses === user.billing.responses && billingQuantity === quantity) return true;
    if (selectedPlan === 'starter' && selectedPlan === user.billing.plan && billingQuantity === quantity) return true;

    return false;
  }, [user.billing, quantity, selectedPlan, planResponses]);

  return (
    <form onSubmit={handleSubmit}>
      {selectedPlan !== 'personal' && allowQuantityUpdate && (
        <>
          <Header style={{ margin: '0 0 15px 0' }}>Collaborators</Header>

          <Select
            width="100%"
            options={collaboratorsNumber}
            value={quantity}
            onChange={(selected) => setQuantity(selected.value)}
            style={{ margin: '0 0 30px 0' }}
          />
        </>
      )}

      {selectedPlan === 'personal' && (collaborators.length > 0 || guests.length > 0) && (
        <>
          <Header style={{ margin: '0 0 15px 0' }}>Important information</Header>

          <div className={styles.description} style={{ margin: '0 0 20px 0' }}>
            The Personal plan does not allow you to work together with collaborators or guests. This means that switching from{' '}
            <span className={styles[user.billing.plan || 'trial']}>{user.billing.plan || 'Trial'}</span> to{' '}
            <span className={styles[selectedPlan]}>{selectedPlan}</span>{' '}
            <span className={styles.smallPrice}>will remove all collaborators and guests</span> from your account.
          </div>
        </>
      )}

      {((selectedPlan === 'starter' && guests.length > 100) ||
        (selectedPlan !== 'personal' && collaborators.length > quantity && quantity > 0) ||
        (selectedPlan !== 'personal' && collaborators.length > 0 && quantity === 0)) && (
        <Header style={{ margin: '0 0 15px 0' }}>Important information</Header>
      )}

      {selectedPlan === 'starter' && guests.length > 100 && (
        <>
          <div className={styles.description} style={{ margin: '0 0 5px 0' }}>
            Please select which collaborators you would like to keep, up to 100:
          </div>
          <ul className={styles.usersList}>
            {guests.map((guest) => (
              <li>
                <Checkbox
                  value={usersToRemove.indexOf(guest._id) === -1}
                  style={{ width: '100%' }}
                  onChange={() => {}}
                  onClick={() => toggleUserToRemove('guests', guest._id)}>
                  {guest.email}
                </Checkbox>
              </li>
            ))}
          </ul>
        </>
      )}

      {selectedPlan !== 'personal' && collaborators.length > quantity && quantity > 0 && (
        <>
          <div className={styles.description} style={{ margin: '0 0 5px 0' }}>
            Please select which collaborators you would like to keep, up to {quantity}:
          </div>
          <ul className={styles.usersList}>
            {collaborators.map((collaborator) => (
              <li>
                <Checkbox
                  value={usersToRemove.indexOf(collaborator._id) === -1}
                  style={{ width: '100%' }}
                  onChange={() => {}}
                  onClick={() => toggleUserToRemove('collaborators', collaborator._id)}>
                  {collaborator.email}
                </Checkbox>
              </li>
            ))}
          </ul>
        </>
      )}

      {selectedPlan !== 'personal' && collaborators.length > 0 && quantity === 0 && (
        <>
          <div className={styles.description} style={{ margin: '0 0 20px 0' }}>
            You have not added any additional collaborator seats to your plan, even though you already have invited some collaborators to
            your account. This means that <span className={styles.smallPrice}>we’ll have to remove all collaborators</span> from your
            account.
          </div>
        </>
      )}

      {allowPaymentInformationChange && (
        <>
          <Header style={{ margin: '0 0 15px 0' }}>Payment Information</Header>

          {!showNewCC && (
            <>
              <ul className={styles.paymentMethods}>
                {paymentMethods.map((paymentMethod) => (
                  <li key={paymentMethod._id}>
                    <Radio value={selectedPaymentMethod === paymentMethod._id} onClick={() => setSelectedPaymentMethod(paymentMethod._id)}>
                      ({paymentMethod.brand}) **** **** **** {paymentMethod.last4}
                    </Radio>
                  </li>
                ))}
              </ul>

              <Button theme="black-underline" onClick={() => setShowNewCC(true)} style={{ margin: '10px 0 0 0' }}>
                Add a new card
              </Button>
            </>
          )}

          {showNewCC && (
            <>
              <FormRow label="Card information" required={true} errorMessage={ccError}>
                <CreditCard />
              </FormRow>

              {paymentMethods.length > 0 && (
                <Button theme="black-underline" onClick={() => setShowNewCC(false)}>
                  Cancel, select saved card instead
                </Button>
              )}
            </>
          )}
        </>
      )}
      <Header style={{ margin: '30px 0 15px 0' }}>Summary</Header>

      <div className={styles.description}>
        QuestionScout will charge you in <span className={styles.smallPrice}>{planCoundown}</span> for{' '}
        <strong className={styles.price}>${summary.priceNetWithProrate}</strong>.{' '}
        {selectedPlan !== 'personal' && (
          <>
            <ul className={styles.priceInclude}>
              <li>
                <span className={styles.smallPrice}>${plansPrice[selectedPlan]}</span> for your monthly subscription in the next billing
                period.
              </li>
              {selectedPlan === 'pro' && planResponses > 10000 && (
                <li>
                  <span className={styles.smallPrice}>${responsesPrice[planResponses]}</span> for {planResponses} monthly responses.
                </li>
              )}
              {summary.additionalUsers > 0 && (
                <li>
                  <>
                    <span className={styles.smallPrice}>${summary.additionalUsersPrice}</span> for {summary.additionalUsers} additional
                    collaborator{summary.additionalUsers === 1 ? '' : 's'} in the next billing period.
                  </>
                </li>
              )}
              {summary.prorationNet > 0 && (
                <li>
                  <>
                    <span className={styles.smallPrice}>${summary.prorationNet}</span> for using an upgraded plan during the next{' '}
                    <span className={styles.smallPrice}>{planCoundown}</span> of your current billing period.
                  </>
                </li>
              )}
            </ul>
          </>
        )}
        Afterwards, your <span className={styles[selectedPlan]}>{selectedPlan}</span> subscription will continue to be billed monthly for{' '}
        <strong className={styles.price}>${summary.priceNet}</strong> and will automatically renew.
      </div>

      {summary.taxRate > 0 && (
        <div className={styles.description}>
          You will be charged <span className={styles.smallPrice}>{summary.taxRate}%</span> VAT.
        </div>
      )}

      <Button theme="black" type="submit" disabled={!stripe || isSubmitDisabled} fullWidth className={styles.submit}>
        {user.billing.plan === selectedPlan && 'Update Plan'}
        {user.billing.plan !== selectedPlan && <>{isUpgrading ? 'Upgrade' : 'Downgrade'} Plan</>}
      </Button>
    </form>
  );
};

const UpdatePlan = ({ show, onClose, onSuccess, selectedPlan, planResponses, allowPaymentInformationChange, allowQuantityUpdate }) => {
  const { user, profilePage, setProfilePage } = useContext(MainContext);

  const stripePromise = loadStripe(config.stripePublicKey);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [isUpgrading, setIsUpgrading] = useState(null);

  useEffect(() => {
    setIsUpgrading(plansPrice[user.billing.plan || 'trial'] < plansPrice[selectedPlan]);
  }, []);

  const cancel = () => {
    setLoading(false);
    setSuccess(false);
    onClose();
  };

  const handleSuccess = (data) => {
    setTimeout(() => {
      setSuccess(false);
      setShowSuccessScreen(true);
      if (profilePage === 'upgrade') setProfilePage('billing');
      if (onSuccess) onSuccess();
    }, 300);
  };

  return (
    <Panel
      mainStyle={{
        height: '90vh'
      }}
      coverStyle={{
        width: '80vw',
        height: '90vh',
        maxWidth: '950px',
        minWidth: '850px',
        left: '-200px'
      }}
      className={styles.main}
      title={
        <div>
          {user.billing.plan === selectedPlan && <>Updating your plan</>}

          {user.billing.plan !== selectedPlan && (
            <>
              {isUpgrading ? 'Upgrading' : 'Downgrading'} to <span className={styles[selectedPlan]}>{selectedPlan}</span>
            </>
          )}
        </div>
      }
      show={show}
      loading={loading}
      success={success}
      onOutsideClick={cancel}>
      <div className={styles.content}>
        {!showSuccessScreen && (
          <Elements stripe={stripePromise}>
            <CheckoutForm
              selectedPlan={selectedPlan}
              allowQuantityUpdate={allowQuantityUpdate}
              allowPaymentInformationChange={allowPaymentInformationChange}
              setLoading={setLoading}
              setSuccess={setSuccess}
              onSuccess={handleSuccess}
              isUpgrading={isUpgrading}
              planResponses={planResponses}
            />
          </Elements>
        )}

        {showSuccessScreen && (
          <>
            <p>Success</p>
          </>
        )}
      </div>
    </Panel>
  );
};

export default UpdatePlan;
