import styles from './SearchInput.module.css';
import React from 'react';

import { Icon } from 'ui-components';

const SearchInput = ({ value, placeholder = 'Search', onChange: handleChange }) => {
  return (
    <div className={[styles.container, value ? styles.filled : ''].join(' ')}>
      <Icon id="search" className={styles.icon} />
      <input
        className={styles.field}
        type="text"
        value={value || ''}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchInput;
