import styles from './AddEditConnectionPanel.module.css';

import React, { useState, useEffect } from 'react';
import io from 'io.js';

import { Panel, Button, Input, Select, FormRow, Header } from 'ui-components';

import config from 'config.js';

const modesOptions = [
  { label: 'Production', value: 'production' },
  { label: 'Sandbox', value: 'sandbox' }
];

const currenciesOptions = [
  { value: 'AED', label: 'AED (United Arab Emirates Dirham))' },
  { value: 'AFN', label: 'AFN (Afghan Afghani)' },
  { value: 'ALL', label: 'ALL (Albanian Lek)' },
  { value: 'AMD', label: 'AMD (Armenian Dram)' },
  { value: 'ANG', label: 'ANG (Netherlands Antillean Guilder)' },
  { value: 'AOA', label: 'AOA (Angolan Kwanza)' },
  { value: 'ARS', label: 'ARS (Argentine Peso)' },
  { value: 'AUD', label: 'AUD (Australian Dollar)' },
  { value: 'AWG', label: 'AWG (Aruban Florin)' },
  { value: 'AZN', label: 'AZN (Azerbaijani Manat)' },
  { value: 'BAM', label: 'BAM (Bosnia-Herzegovina Convertible Mark)' },
  { value: 'BBD', label: 'BBD (Barbadian Dollar)' },
  { value: 'BDT', label: 'BDT (Bangladeshi Taka)' },
  { value: 'BGN', label: 'BGN (Bulgarian Lev)' },
  { value: 'BHD', label: 'BHD (Bahraini Dinar)' },
  { value: 'BIF', label: 'BIF (Burundian Franc)' },
  { value: 'BMD', label: 'BMD (Bermudan Dollar)' },
  { value: 'BND', label: 'BND (Brunei Dollar)' },
  { value: 'BOB', label: 'BOB (Bolivian Boliviano)' },
  { value: 'BRL', label: 'BRL (Brazilian Real)' },
  { value: 'BSD', label: 'BSD (Bahamian Dollar)' },
  { value: 'BTC', label: 'BTC (Bitcoin)' },
  { value: 'BTN', label: 'BTN (Bhutanese Ngultrum)' },
  { value: 'BWP', label: 'BWP (Botswanan Pula)' },
  { value: 'BYN', label: 'BYN (Belarusian Ruble)' },
  { value: 'BZD', label: 'BZD (Belize Dollar)' },
  { value: 'CAD', label: 'CAD (Canadian Dollar)' },
  { value: 'CDF', label: 'CDF (Congolese Franc)' },
  { value: 'CHF', label: 'CHF (Swiss Franc)' },
  { value: 'CLF', label: 'CLF (Chilean Unit of Account (UF))' },
  { value: 'CLP', label: 'CLP (Chilean Peso)' },
  { value: 'CNH', label: 'CNH (Chinese Yuan (Offshore))' },
  { value: 'CNY', label: 'CNY (Chinese Yuan)' },
  { value: 'COP', label: 'COP (Colombian Peso)' },
  { value: 'CRC', label: 'CRC (Costa Rican Colón)' },
  { value: 'CUC', label: 'CUC (Cuban Convertible Peso)' },
  { value: 'CUP', label: 'CUP (Cuban Peso)' },
  { value: 'CVE', label: 'CVE (Cape Verdean Escudo)' },
  { value: 'CZK', label: 'CZK (Czech Republic Koruna)' },
  { value: 'DJF', label: 'DJF (Djiboutian Franc)' },
  { value: 'DKK', label: 'DKK (Danish Krone)' },
  { value: 'DOP', label: 'DOP (Dominican Peso)' },
  { value: 'DZD', label: 'DZD (Algerian Dinar)' },
  { value: 'EGP', label: 'EGP (Egyptian Pound)' },
  { value: 'ERN', label: 'ERN (Eritrean Nakfa)' },
  { value: 'ETB', label: 'ETB (Ethiopian Birr)' },
  { value: 'EUR', label: 'EUR (Euro)' },
  { value: 'FJD', label: 'FJD (Fijian Dollar)' },
  { value: 'FKP', label: 'FKP (Falkland Islands Pound)' },
  { value: 'GBP', label: 'GBP (British Pound Sterling)' },
  { value: 'GEL', label: 'GEL (Georgian Lari)' },
  { value: 'GGP', label: 'GGP (Guernsey Pound)' },
  { value: 'GHS', label: 'GHS (Ghanaian Cedi)' },
  { value: 'GIP', label: 'GIP (Gibraltar Pound)' },
  { value: 'GMD', label: 'GMD (Gambian Dalasi)' },
  { value: 'GNF', label: 'GNF (Guinean Franc)' },
  { value: 'GTQ', label: 'GTQ (Guatemalan Quetzal)' },
  { value: 'GYD', label: 'GYD (Guyanaese Dollar)' },
  { value: 'HKD', label: 'HKD (Hong Kong Dollar)' },
  { value: 'HNL', label: 'HNL (Honduran Lempira)' },
  { value: 'HRK', label: 'HRK (Croatian Kuna)' },
  { value: 'HTG', label: 'HTG (Haitian Gourde)' },
  { value: 'HUF', label: 'HUF (Hungarian Forint)' },
  { value: 'IDR', label: 'IDR (Indonesian Rupiah)' },
  { value: 'ILS', label: 'ILS (Israeli New Sheqel)' },
  { value: 'IMP', label: 'IMP (Manx pound)' },
  { value: 'INR', label: 'INR (Indian Rupee)' },
  { value: 'IQD', label: 'IQD (Iraqi Dinar)' },
  { value: 'IRR', label: 'IRR (Iranian Rial)' },
  { value: 'ISK', label: 'ISK (Icelandic Króna)' },
  { value: 'JEP', label: 'JEP (Jersey Pound)' },
  { value: 'JMD', label: 'JMD (Jamaican Dollar)' },
  { value: 'JOD', label: 'JOD (Jordanian Dinar)' },
  { value: 'JPY', label: 'JPY (Japanese Yen)' },
  { value: 'KES', label: 'KES (Kenyan Shilling)' },
  { value: 'KGS', label: 'KGS (Kyrgystani Som)' },
  { value: 'KHR', label: 'KHR (Cambodian Riel)' },
  { value: 'KMF', label: 'KMF (Comorian Franc)' },
  { value: 'KPW', label: 'KPW (North Korean Won)' },
  { value: 'KRW', label: 'KRW (South Korean Won)' },
  { value: 'KWD', label: 'KWD (Kuwaiti Dinar)' },
  { value: 'KYD', label: 'KYD (Cayman Islands Dollar)' },
  { value: 'KZT', label: 'KZT (Kazakhstani Tenge)' },
  { value: 'LAK', label: 'LAK (Laotian Kip)' },
  { value: 'LBP', label: 'LBP (Lebanese Pound)' },
  { value: 'LKR', label: 'LKR (Sri Lankan Rupee)' },
  { value: 'LRD', label: 'LRD (Liberian Dollar)' },
  { value: 'LSL', label: 'LSL (Lesotho Loti)' },
  { value: 'LYD', label: 'LYD (Libyan Dinar)' },
  { value: 'MAD', label: 'MAD (Moroccan Dirham)' },
  { value: 'MDL', label: 'MDL (Moldovan Leu)' },
  { value: 'MGA', label: 'MGA (Malagasy Ariary)' },
  { value: 'MKD', label: 'MKD (Macedonian Denar)' },
  { value: 'MMK', label: 'MMK (Myanma Kyat)' },
  { value: 'MNT', label: 'MNT (Mongolian Tugrik)' },
  { value: 'MOP', label: 'MOP (Macanese Pataca)' },
  { value: 'MRO', label: 'MRO (Mauritanian Ouguiya (pre-2018))' },
  { value: 'MRU', label: 'MRU (Mauritanian Ouguiya)' },
  { value: 'MUR', label: 'MUR (Mauritian Rupee)' },
  { value: 'MVR', label: 'MVR (Maldivian Rufiyaa)' },
  { value: 'MWK', label: 'MWK (Malawian Kwacha)' },
  { value: 'MXN', label: 'MXN (Mexican Peso)' },
  { value: 'MYR', label: 'MYR (Malaysian Ringgit)' },
  { value: 'MZN', label: 'MZN (Mozambican Metical)' },
  { value: 'NAD', label: 'NAD (Namibian Dollar)' },
  { value: 'NGN', label: 'NGN (Nigerian Naira)' },
  { value: 'NIO', label: 'NIO (Nicaraguan Córdoba)' },
  { value: 'NOK', label: 'NOK (Norwegian Krone)' },
  { value: 'NPR', label: 'NPR (Nepalese Rupee)' },
  { value: 'NZD', label: 'NZD (New Zealand Dollar)' },
  { value: 'OMR', label: 'OMR (Omani Rial)' },
  { value: 'PAB', label: 'PAB (Panamanian Balboa)' },
  { value: 'PEN', label: 'PEN (Peruvian Nuevo Sol)' },
  { value: 'PGK', label: 'PGK (Papua New Guinean Kina)' },
  { value: 'PHP', label: 'PHP (Philippine Peso)' },
  { value: 'PKR', label: 'PKR (Pakistani Rupee)' },
  { value: 'PLN', label: 'PLN (Polish Zloty)' },
  { value: 'PYG', label: 'PYG (Paraguayan Guarani)' },
  { value: 'QAR', label: 'QAR (Qatari Rial)' },
  { value: 'RON', label: 'RON (Romanian Leu)' },
  { value: 'RSD', label: 'RSD (Serbian Dinar)' },
  { value: 'RUB', label: 'RUB (Russian Ruble)' },
  { value: 'RWF', label: 'RWF (Rwandan Franc)' },
  { value: 'SAR', label: 'SAR (Saudi Riyal)' },
  { value: 'SBD', label: 'SBD (Solomon Islands Dollar)' },
  { value: 'SCR', label: 'SCR (Seychellois Rupee)' },
  { value: 'SDG', label: 'SDG (Sudanese Pound)' },
  { value: 'SEK', label: 'SEK (Swedish Krona)' },
  { value: 'SGD', label: 'SGD (Singapore Dollar)' },
  { value: 'SHP', label: 'SHP (Saint Helena Pound)' },
  { value: 'SLL', label: 'SLL (Sierra Leonean Leone)' },
  { value: 'SOS', label: 'SOS (Somali Shilling)' },
  { value: 'SRD', label: 'SRD (Surinamese Dollar)' },
  { value: 'SSP', label: 'SSP (South Sudanese Pound)' },
  { value: 'STN', label: 'STN (São Tomé and Príncipe Dobra)' },
  { value: 'SVC', label: 'SVC (Salvadoran Colón)' },
  { value: 'SYP', label: 'SYP (Syrian Pound)' },
  { value: 'SZL', label: 'SZL (Swazi Lilangeni)' },
  { value: 'THB', label: 'THB (Thai Baht)' },
  { value: 'TJS', label: 'TJS (Tajikistani Somoni)' },
  { value: 'TMT', label: 'TMT (Turkmenistani Manat)' },
  { value: 'TND', label: 'TND (Tunisian Dinar)' },
  { value: 'TOP', label: "TOP (Tongan Pa'anga)" },
  { value: 'TRY', label: 'TRY (Turkish Lira)' },
  { value: 'TTD', label: 'TTD (Trinidad and Tobago Dollar)' },
  { value: 'TWD', label: 'TWD (New Taiwan Dollar)' },
  { value: 'TZS', label: 'TZS (Tanzanian Shilling)' },
  { value: 'UAH', label: 'UAH (Ukrainian Hryvnia)' },
  { value: 'UGX', label: 'UGX (Ugandan Shilling)' },
  { value: 'USD', label: 'USD (United States Dollar)' },
  { value: 'UYU', label: 'UYU (Uruguayan Peso)' },
  { value: 'UZS', label: 'UZS (Uzbekistan Som)' },
  { value: 'VEF', label: 'VEF (Venezuelan Bolívar Fuerte)' },
  { value: 'VND', label: 'VND (Vietnamese Dong)' },
  { value: 'VUV', label: 'VUV (Vanuatu Vatu)' },
  { value: 'WST', label: 'WST (Samoan Tala)' },
  { value: 'XAF', label: 'XAF (CFA Franc BEAC)' },
  { value: 'XAG', label: 'XAG (Silver Ounce)' },
  { value: 'XAU', label: 'XAU (Gold Ounce)' },
  { value: 'XCD', label: 'XCD (East Caribbean Dollar)' },
  { value: 'XDR', label: 'XDR (Special Drawing Rights)' },
  { value: 'XOF', label: 'XOF (CFA Franc BCEAO)' },
  { value: 'XPD', label: 'XPD (Palladium Ounce)' },
  { value: 'XPF', label: 'XPF (CFP Franc)' },
  { value: 'XPT', label: 'XPT (Platinum Ounce)' },
  { value: 'YER', label: 'YER (Yemeni Rial)' },
  { value: 'ZAR', label: 'ZAR (South African Rand)' },
  { value: 'ZMW', label: 'ZMW (Zambian Kwacha)' },
  { value: 'ZWL', label: 'ZWL (Zimbabwean Dollar' }
];

const AddEditConnectionPanel = ({ show, onClose, service, connections, selectedConnection }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [name, setName] = useState('');
  const [currency, setCurrency] = useState(null);
  const [mode, setMode] = useState('production');

  const [payPalEmail, setPayPalEmail] = useState('');
  const [stripePublishableKey, setStripePublishableKey] = useState('');
  const [stripeSecretKey, setStripeSecretKey] = useState('');
  const [mollieZapierKey, setMollieZapierKey] = useState('');
  const [squareAccessToken, setSquareAccessToken] = useState('');
  const [squareLocationId, setSquareLocationId] = useState('');

  const isEdit = false;

  useEffect(() => {
    const editedConnection = selectedConnection ? connections.find((connection) => connection._id === selectedConnection._id) : null;

    if (editedConnection) {
      setName(editedConnection.name);
      setCurrency(editedConnection.currency);
      setMode(editedConnection.mode);

      switch (editedConnection.type) {
        case 'stripe':
          setStripePublishableKey(editedConnection.credentials.publishableKey);
          setStripeSecretKey(editedConnection.credentials.secretKey);
          break;
        case 'paypal':
          setPayPalEmail(editedConnection.credentials.email);
          break;
        case 'mollie':
          setMollieZapierKey(editedConnection.credentials.apiKey);
          break;
        case 'square':
          setSquareAccessToken(editedConnection.credentials.accessToken);
          setSquareLocationId(editedConnection.credentials.locationId);
          break;
        default:
          break;
      }
    } else {
      setName('');
      setCurrency(null);
      setMode('production');
      setPayPalEmail('');
      setStripePublishableKey('');
      setStripeSecretKey('');
      setMollieZapierKey('');
      setSquareAccessToken('');
      setSquareLocationId('');
    }
  }, [selectedConnection, connections]);

  const handleEnterKeyPress = (e) => {
    if (e.key !== 'Enter') return;

    if (isValid() && !loading) submit();
  };

  const cancel = () => {
    onClose();
    setName('');
    setCurrency(null);
    setMode('production');
    setPayPalEmail('');
    setStripePublishableKey('');
    setStripeSecretKey('');
    setMollieZapierKey('');
    setSquareAccessToken('');
    setSquareLocationId('');
  };

  const submit = () => {
    const actionType = selectedConnection ? 'updateConnection' : 'createConnection';

    let data = {
      name,
      provider: service._id,
      currency,
      mode,
      credentials: {}
    };

    switch (service._id) {
      case 'stripe':
        data.credentials.publishableKey = stripePublishableKey;
        data.credentials.secretKey = stripeSecretKey;
        break;
      case 'paypal':
        data.credentials.email = payPalEmail;
        break;
      case 'mollie':
        data.credentials.apiKey = mollieZapierKey;
        break;
      case 'square':
        data.credentials.accessToken = squareAccessToken;
        data.credentials.locationId = squareLocationId;
        break;
      default:
        break;
    }

    if (selectedConnection) data._id = selectedConnection._id;

    io.socket.once(actionType, async (payload) => {
      if (payload.connection && payload.connection.type === 'stripe' && !selectedConnection)
        io.socket.emit('createConnectionWebhook', payload.connection);

      cancel();
    });

    io.socket.emit(actionType, data);
  };

  const isValid = () => {
    if (!currency || !service) return false;

    switch (service._id) {
      case 'stripe':
        return stripePublishableKey;
      case 'paypal':
        return payPalEmail;
      case 'mollie':
        return mollieZapierKey;
      case 'square':
        return squareAccessToken && squareLocationId;
      default:
        return false;
    }
  };

  return (
    <Panel
      mainStyle={{
        height: '90vh'
      }}
      coverStyle={{
        width: '80vw',
        height: '90vh',
        maxWidth: '950px',
        minWidth: '850px',
        left: '-200px'
      }}
      className={styles.main}
      title={isEdit ? `Editing ${service ? service.name : ''} connection` : `Connecting ${service ? service.name : ''}`}
      show={show}
      loading={loading}
      success={success}
      buttons={[
        <Button theme="white" onClick={() => !loading && cancel()}>
          Cancel
        </Button>,
        <Button theme="black" onClick={() => submit()} disabled={!isValid() || loading}>
          {isEdit ? 'Edit connection' : 'Create connection'}
        </Button>
      ]}
      onOutsideClick={() => !loading && cancel()}>
      <div className={[styles.content, config.fullStoryExcludeClass].join(' ')}>
        <Header style={{ margin: '0 0 15px 0' }}>General information</Header>

        <div className={styles.doubleRow}>
          <FormRow label="Name" style={{ width: '62%' }}>
            <Input value={name} onChange={(value) => setName(value)} onKeyPress={handleEnterKeyPress} />
          </FormRow>
          <FormRow label="Mode" style={{ width: '35%' }}>
            <Select
              width="100%"
              options={modesOptions}
              menuPortalTarget={document.body}
              value={mode}
              onChange={(selected) => setMode(selected.value)}
            />
          </FormRow>
        </div>

        <FormRow label="Currency">
          <Select
            width={'100%'}
            filter={true}
            options={currenciesOptions}
            menuPortalTarget={document.body}
            value={currency}
            onChange={(selected) => setCurrency(selected.value)}
          />
        </FormRow>

        {service && (
          <>
            <Header style={{ margin: '20px 0 15px 0' }}>Connection information</Header>

            {service._id === 'stripe' && (
              <>
                <FormRow label="Publishable key">
                  <Input
                    value={stripePublishableKey}
                    type="email"
                    placeholder="pk_"
                    onChange={(value) => setStripePublishableKey(value)}
                    onKeyPress={handleEnterKeyPress}
                  />
                </FormRow>

                <FormRow
                  label="Restricted key"
                  description={
                    <>
                      Create a new restricted key on the Stripe API Keys page with <strong>All Checkout Resources</strong> set to{' '}
                      <strong>Write</strong> and <strong>All Webhook Resources</strong> set to <strong>Write</strong>, and provide the token
                      here. Please make sure to not use your secret key.
                    </>
                  }>
                  <Input
                    value={stripeSecretKey}
                    type="email"
                    placeholder="rk_"
                    onChange={(value) => setStripeSecretKey(value)}
                    onKeyPress={handleEnterKeyPress}
                  />
                </FormRow>
              </>
            )}

            {service._id === 'paypal' && (
              <>
                <FormRow label="PayPal email">
                  <Input value={payPalEmail} onChange={(value) => setPayPalEmail(value)} onKeyPress={handleEnterKeyPress} />
                </FormRow>
              </>
            )}

            {service._id === 'mollie' && (
              <>
                <FormRow label="API key">
                  <Input value={mollieZapierKey} onChange={(value) => setMollieZapierKey(value)} onKeyPress={handleEnterKeyPress} />
                </FormRow>
              </>
            )}

            {service._id === 'square' && (
              <>
                <FormRow
                  label="Access Token"
                  description={
                    <>
                      Sign in to your{' '}
                      <a href="https://developer.squareup.com/apps" target="_blank" rel="noreferrer">
                        Square Developer
                      </a>{' '}
                      account and open/create a new Application. Afterwards, paste in the <strong>Production Access Token</strong> here.
                    </>
                  }>
                  <Input value={squareAccessToken} onChange={(value) => setSquareAccessToken(value)} onKeyPress={handleEnterKeyPress} />
                </FormRow>

                <FormRow
                  label="Location ID"
                  description={
                    <>
                      Sign in to your{' '}
                      <a href="https://developer.squareup.com/apps" target="_blank" rel="noreferrer">
                        Square Developer
                      </a>{' '}
                      account and open/create a new Application. Afterwards, go to the Locations page and paste the{' '}
                      <strong>Location ID</strong> here.
                    </>
                  }>
                  <Input value={squareLocationId} onChange={(value) => setSquareLocationId(value)} onKeyPress={handleEnterKeyPress} />
                </FormRow>

                <FormRow
                  label="Webhook Connection"
                  description={
                    <>
                      Sign in to your{' '}
                      <a href="https://developer.squareup.com/apps" target="_blank" rel="noreferrer">
                        Square Developer
                      </a>{' '}
                      account and open an existing or create a new application. Afterwards, go to the Webhooks page and click{' '}
                      <strong>Add Endpoint</strong>. Insert the URL as shown above inside of the URL field within the Add Endpoint popup,
                      and assign the <strong>payment.created</strong> and <strong>payment.updated</strong> events to the webhook.
                    </>
                  }>
                  <Input
                    value={'https://api2.questionscout.com/api/payments/square/webhook'}
                    onChange={() => {}}
                    onFocus={(e) => e.target.select()}
                  />
                </FormRow>
              </>
            )}
          </>
        )}
      </div>
    </Panel>
  );
};

export default AddEditConnectionPanel;
