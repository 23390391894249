import styles from './SearchBox.module.css';

import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { useTimeout } from 'helpers';

import { Icon } from '../Icon/Icon';
import { Spinner } from '../Spinner/Spinner';

/* -------------------------------------------------------------------------- */
/*                    SEARCH BOX THAT FETCHES EXTERNAL DATA                   */
/* -------------------------------------------------------------------------- */

const SearchBoxAsync = React.memo(
  React.forwardRef(
    (
      {
        // Styling
        className = '',
        theme = 'inline',

        // Async callback for fetching data (expects returned array)
        onRequestData,

        // Support for controlled search term
        searchTerm: controlledSearchTerm = '',
        onSearchTermChange,

        // Renderer
        renderMatchedData,

        // Config
        inputPlaceholder = 'Type something in to start...',
        debounceMs = 200,
        requestEmptyTerms = false,

        // Event handlers
        onInputKeyDown,
        onInputFocus,
        onInputBlur
      },
      ref
    ) => {
      /* ---------------------------------- STATE --------------------------------- */

      const setFetchDataTimeout = useTimeout();

      const [isLoading, setIsLoading] = useState(true);
      const [matchedData, setMatchedData] = useState([]);
      const [searchTerm, setSearchTerm] = useState(controlledSearchTerm);

      useEffect(() => {
        setSearchTerm(controlledSearchTerm);
      }, [controlledSearchTerm]);

      /* ------------------------------ FETCHING DATA ----------------------------- */

      const fetchData = useCallback(
        async (searchTerm) => {
          const data = await onRequestData(searchTerm);
          setMatchedData(data);

          setIsLoading(false);
        },
        [onRequestData]
      );

      useEffect(() => {
        setFetchDataTimeout(null);

        if (searchTerm || requestEmptyTerms) {
          setIsLoading(true);

          setFetchDataTimeout(() => {
            fetchData(searchTerm);
          }, debounceMs);
        } else {
          setMatchedData([]);
          setIsLoading(false);
        }
      }, [searchTerm, fetchData, debounceMs, requestEmptyTerms, setFetchDataTimeout]);

      /* ----------------------------- EVENT HANDLERS ----------------------------- */

      const handleInputChange = useCallback(
        (e) => {
          const nextSearchTerm = e.target.value;

          setSearchTerm(nextSearchTerm);
          if (onSearchTermChange) {
            onSearchTermChange(nextSearchTerm);
          }
        },
        [onSearchTermChange]
      );

      /* ----------------------------------- JSX ---------------------------------- */

      let resultsJsx = null;

      if (isLoading) {
        resultsJsx = (
          <div className={styles.placeholder}>
            <Spinner size={20} />
          </div>
        );
      } else {
        resultsJsx = renderMatchedData(matchedData, searchTerm);

        if (!resultsJsx || !resultsJsx?.length) {
          resultsJsx = (
            <div className={styles.placeholder}>
              <div>No match found</div>
            </div>
          );
        }
      }

      return (
        <div className={[className, styles.searchBox, styles[`theme-${theme}`]].join(' ')} ref={ref}>
          <div className={styles.searchBar}>
            <Icon className={styles.icon} id="search" />
            <input
              className={styles.input}
              placeholder={inputPlaceholder}
              value={searchTerm}
              onKeyDown={onInputKeyDown}
              onChange={handleInputChange}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
            />
          </div>
          {resultsJsx && <div className={styles.searchResults}>{resultsJsx}</div>}
        </div>
      );
    }
  )
);

/* -------------------------------------------------------------------------- */
/*                         SEARCH BOX WITH LOCAL DATA                         */
/* -------------------------------------------------------------------------- */

/*
    Wrapper for SearchBoxAsync.
    Creates artificial sync data fetcher based on passed data and dataStringifier.
    Stringified data will be cases without case sensitivity.
*/
const SearchBox = React.memo(
  React.forwardRef(
    (
      {
        // Raw data that will be passed to renderElement
        data = [],
        // Function that has to convert raw data instance to string
        dataStringifier,

        // Passed down to SearchBox
        ...searchBoxProps
      },
      ref
    ) => {
      /* ---------------------------------- STATE --------------------------------- */

      const parsedData = useMemo(() => {
        return data.map((v) => {
          return {
            text: dataStringifier(v).toLowerCase(),
            raw: v
          };
        });
      }, [data, dataStringifier]);

      /* ----------------------------- EVENT HANDLERS ----------------------------- */

      const handleRequestData = useCallback(
        (searchTerm) => {
          let matches = [];

          // Filters by stringified data,
          if (searchTerm) {
            searchTerm = searchTerm.toLowerCase();
            matches = parsedData.filter(({ text }) => {
              return text.indexOf(searchTerm) !== -1;
            });
          }
          // or returns all if there is no search term
          else {
            matches = parsedData;
          }

          return matches.map(({ raw }) => raw);
        },
        [parsedData]
      );

      /* ----------------------------------- JSX ---------------------------------- */

      return <SearchBoxAsync {...searchBoxProps} onRequestData={handleRequestData} requestEmptyTerms ref={ref} />;
    }
  )
);

SearchBox.Async = SearchBoxAsync;

export default SearchBox;
