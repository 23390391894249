import React, { useMemo, useContext } from 'react';
import { useRouteChanger } from 'helpers';

import { MainContext, DashboardContext } from 'contexts';
import TutorialImg from '../components/TutorialImg';

/* -------------------------------------------------------------------------- */
/*                                  CALLBACK                                  */
/* -------------------------------------------------------------------------- */

const createDashboardIntroductionSteps = (params) => {
  /* --------------------- PARAM VALIDATION AND CALLBACKS --------------------- */

  const { workspaceId, userCanCreate, userRole, navigateInApp } = params;

  if (!workspaceId || !userRole || !navigateInApp) return null;

  const navigateToWorkspace = () => navigateInApp(`/workspace/${workspaceId}`);
  const navigateToThemes = () => navigateInApp('/themes');

  /* ---------------------------------- STEPS --------------------------------- */

  return [
    {
      title: 'Dashboard introduction',
      titleSize: 'large',
      onAfterChange: navigateToWorkspace,

      content: (
        <>
          <p>
            The dashboard is a place when you can see all your <b>forms</b> and <b>themes</b>. It's also a place where you can manage your{' '}
            <b>collaborators</b> and <b>guests</b> if you plan to work together with others.
          </p>
          <small>🕐 You'll need approx. 2 minutes to finish this tutorial.</small>
        </>
      )
    },
    {
      target: '.intro-form-create',
      targetIsOptional: true,
      spotlightPadding: 10,
      placement: 'right',
      onAfterChange: navigateToWorkspace,

      title: 'Creating forms',
      content: (
        <>
          {userCanCreate ? (
            <p>
              To create a new form, simply click <dfn>+</dfn> button.
            </p>
          ) : (
            <p>
              To create a new form, simply click <dfn>+</dfn> button (currently it's hidden because you don't have create permissions in
              this workspace).
            </p>
          )}

          <p>You'll be asked to choose one of two form types: </p>
          <ul>
            <li>
              <b>Traditional forms</b> look like a regular questionnaire,
            </li>
            <li>
              <b>Conversational forms</b> have a more modern look and give more emphasis to individual questions.
            </li>
          </ul>
          <p>
            You can also explore some of our <b>templates</b> to get a quick start, or to explore some ideas of what is possible with
            QuestionScout.
          </p>
        </>
      )
    },
    {
      target: '.intro-form',
      targetIsOptional: true,
      spotlightPadding: 10,
      placement: 'right',
      onAfterChange: navigateToWorkspace,

      title: 'Existing forms',
      content: (
        <>
          <p>
            Previously created forms will be displayed in the middle area. You'll see a small preview, publication status, how many
            responses were collected so far, and find other account users that currently viewing or editing this form.
          </p>
          <TutorialImg name="dashboard_form_preview" />
        </>
      )
    },
    {
      target: '.intro-workspaces',
      placement: 'right',
      onAfterChange: navigateToWorkspace,

      title: 'Workspaces',
      content: (
        <>
          <p>Every form lives in a workspace. Workspaces act like folders and could be useful to you if:</p>
          <ul>
            <li>You want to group your forms into categories,</li>
            <li>You will work with collaborators or guests, since you can assign different account users to workspaces.</li>
          </ul>
        </>
      )
    },
    // NOTE: Permissions management is only visible for admins
    userRole === 'admin' && {
      target: '.intro-workspace-permissions',
      spotlightPadding: 15,
      placement: 'left',
      onAfterChange: navigateToWorkspace,

      title: 'Collaborators permissions',
      content: (
        <p>
          In this area you can manage collaborator permissions within a currently opened workspace. You can, for example, hide this
          workspaces from other collaborators that do not have permission, or reduce collaborator permissions within a given workspace.
        </p>
      )
    },
    {
      target: '.intro-profile-button',
      placement: 'bottom',
      onAfterChange: navigateToWorkspace,

      title: 'Profile',
      content: (
        <>
          <p>To see and edit your profile settings you can click this button. In your profile you can:</p>
          <ul>
            <li>edit personal information,</li>
            <li>
              add <b>collaborators</b>,
            </li>
            <li>
              manage <b>external services integrations</b>, <strong>payments</strong> and more.
            </li>
          </ul>
        </>
      )
    },
    // NOTE: Themes are only visible for non-guests
    userRole === 'guest' && {
      onAfterChange: navigateToWorkspace,

      title: 'Themes page',
      content: (
        <>
          <p>
            There is also a <b>Themes</b> page, however it's not available in a guest user mode. Please contact yor account holder if you'd
            like to edit themes.
          </p>
        </>
      )
    },
    userRole !== 'guest' && {
      target: '.intro-themes-button',
      placement: 'right',
      onAfterChange: navigateToThemes,

      title: 'Form themes [1/2]',
      content: (
        <>
          <p>
            To open themes library you need to click this button. Here you can explore all <strong>stock themes</strong> and create your own{' '}
            <b>custom themes</b>.
          </p>
        </>
      )
    },
    userRole !== 'guest' && {
      target: '.intro-themes-theme-preview',
      targetIsOptional: true,
      placement: 'right',
      spotlightPadding: 10,
      onAfterChange: navigateToThemes,

      title: 'Form themes [2/2]',
      content: (
        <>
          <p>
            Click a stock theme to create a custom theme based on it, or click an already existing custom theme to continue editing in the{' '}
            <b>themes editor</b>.
          </p>
        </>
      )
    },
    userRole !== 'guest' && {
      target: '.intro-workspace',
      placement: 'right',
      onAfterChange: navigateToWorkspace,

      title: 'Going back to forms',
      content: <p>To go back to your forms, just click any workspace.</p>
    },
    {
      target: '.intro-walkthrough-button',
      placement: 'right',
      onAfterChange: navigateToWorkspace,

      title: 'Walkthroughs',
      content: (
        <p>
          This tutorial and many others can be accessed at any time clicking the <b>Walkthroughs</b> button on your dashboard.
        </p>
      )
    }
  ].filter(Boolean);
};

/* -------------------------------------------------------------------------- */
/*                                    HOOK                                    */
/* -------------------------------------------------------------------------- */

const useDashboardIntroductionSteps = () => {
  const { replace: navigateInApp } = useRouteChanger();

  // Required data
  const { user } = useContext(MainContext);
  const { workspaces } = useContext(DashboardContext);
  const userRole = user?.role;

  // Looking for a workspace available to user
  const workspaceAvailableToUser = (workspaces || []).find((workspace) => {
    return workspace.users.find((permission) => user && permission.user._id === user._id);
  });

  const workspaceId = workspaceAvailableToUser?._id || null;

  const permissions = workspaceAvailableToUser?.users?.find((u) => u.user._id === user._id);
  const userIsHolderOrAdmin = ['holder', 'admin'].indexOf(user.role) !== -1;
  const userCanCreate = userIsHolderOrAdmin || permissions?.create;

  // Steps
  return useMemo(
    () =>
      createDashboardIntroductionSteps({
        workspaceId,
        userRole,
        userCanCreate,
        navigateInApp
      }),
    [workspaceId, userCanCreate, userRole, navigateInApp]
  );
};

export default useDashboardIntroductionSteps;
