import styles from './MacBar.module.css';

import React from 'react';

import Aligner from '../Aligner/Aligner.js';

const MacBar = ({ className = '', left, right, children, ...rest }) => {
  // Positions elements so that the title tries to by in center
  return (
    <Aligner
      align="center-absolute"
      classes={{
        root: [className, styles.macBar].join(' '),
        left: styles.left,
        right: styles.right,
        middle: styles.middle
      }}
      left={
        <>
          <div className={styles.dots}>
            <div style={{ background: '#FD6254' }} />
            <div style={{ background: '#25C43F' }} />
            <div style={{ background: '#FFBC2D' }} />
          </div>
          {left}
        </>
      }
      right={right}>
      {children}
    </Aligner>
  );
};

export default MacBar;
