import styles from './RadioLabel.module.css';

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Radio } from '../../Radio/Radio.js';

const RadioLabel = React.forwardRef(({ label, style, className, value, onClick, disabled, borderTop, borderBottom }, ref) => {
  let classList = [styles.checkLabel, className, borderTop ? styles.borderTop : '', borderBottom ? styles.borderBottom : ''];

  return (
    <div ref={ref} style={style} className={classList.join(' ')} onClick={() => onClick(!value)}>
      {label}
      <Radio onClick={() => {}} value={value} disabled={disabled} style={{ padding: '0 0 0 5px' }} />
    </div>
  );
});

export default RadioLabel;
