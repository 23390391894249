import parseMention from './parseMention';

// Creates react-select options from calc form.fields
const optionsFromFields = (fields) => {
  return fields.map((field) => {
    return { label: parseMention(field.label), value: field._id };
  });
};

export default optionsFromFields;
