import styles from './Color.module.css';

import React from 'react';
import color from 'color';

const Color = React.memo(({ className, value, size = 'small', shape = 'circle', glow }) => {
  let valueDark = null;
  try {
    valueDark = color(value).darken(0.25).hex();
  } catch (e) {
    console.error(e);
  }

  return (
    <div
      className={[className, styles.color, styles[`size-${size}`], styles[`shape-${shape}`]].join(' ')}
      style={{ background: value, borderColor: valueDark, boxShadow: glow ? `0px 0px 2px ${valueDark}` : 'none' }}
    />
  );
});

export default Color;
