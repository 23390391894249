import styles from './ColorPicker.module.css';

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ChromePicker } from 'react-color';
import Color from 'color';

import { Colors } from '../Colors/Colors.js';

import {} from 'helpers';

const parseColor = (c) => {
  return Color(c.hex).alpha(c.rgb.a).string();
};

/* ------------------------------ COLOR PICKER ------------------------------ */

const ColorPicker = ({
  className,
  // Color picker
  value,
  onChange,
  // Palette
  colors
}) => {
  return (
    <div className={[className, styles.colorPicker].join(' ')}>
      <div className={styles.chromePicker}>
        <ChromePicker color={value} onChange={(c) => onChange(parseColor(c))} />
      </div>
      {false && (
        <div className={styles.palette}>
          <Colors colors={colors} value={value} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
