import styles from './DeleteConnectionPanel.module.css';

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'io.js';

import { Icon, Panel, Button, Input, Label, Select, Hr, IconUpgrade, Avatar, Slider, FormRow } from 'ui-components';

const DeleteConnectionPanel = ({ show, onClose, selectedConnection }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const cancel = () => {
    onClose();
    setLoading(false);
    setSuccess(false);
  };

  const confirm = () => {
    if (loading) return;

    setLoading(true);

    io.socket.once('deleteConnection', (payload) => {
      setLoading(false);
      setSuccess(true);

      setTimeout(() => cancel(), 1000);
    });

    io.socket.emit('deleteConnection', { _id: selectedConnection._id });
  };

  return (
    <Panel
      mainStyle={{
        height: '90vh'
      }}
      coverStyle={{
        width: '80vw',
        height: '90vh',
        maxWidth: '950px',
        minWidth: '850px',
        left: '-200px'
      }}
      className={styles.main}
      title="Confirm connnection delete"
      show={show}
      loading={loading}
      success={success}
      buttons={[
        <Button theme="white" onClick={() => !loading && cancel()}>
          Cancel
        </Button>,
        <Button icon={<Icon id="delete" color="inherit" />} delay={1000} theme="black" onClick={() => confirm()}>
          Delete connnection
        </Button>
      ]}
      onOutsideClick={() => !loading && cancel()}>
      <div className={styles.content}>
        {selectedConnection && <p>Are you sure you want to delete {selectedConnection.name} connection?</p>}
      </div>
    </Panel>
  );
};

export default DeleteConnectionPanel;
