import styles from './Table.module.css';

import React, { useEffect, useState, useContext } from 'react';

import UpdateInvoiceInformation from './panels/UpdateInvoiceInformation.js';
import InitializePlan from './panels/InitializePlan.js';
import UpdatePlan from './panels/UpdatePlan.js';

import Table from './Table.js';
import { MainContext } from 'contexts/main.js';
import { ProfileContext } from 'contexts/profile.js';

const Upgrade = () => {
  const { user } = useContext(MainContext);
  const { setPage } = useContext(ProfileContext);

  const [showUpdateInvoiceInformation, setShowUpdateInvoiceInformation] = useState(false);
  const [showInitializePlan, setShowInitializePlan] = useState(false);
  const [showUpdatePlan, setShowUpdatePlan] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedUsersNumber, setSelectedUsersNumber] = useState(0);
  const [allowQuantityUpdate, setAllowQuantityUpdate] = useState(false);
  const [planResponses, setPlanResponses] = useState(10000);

  const downgrade = (plan) => {
    const newUserNumber = user.planUsage.users - 1;
    
    if (plan === 'personal') setPlanResponses(500);
    if (plan === 'starter') setPlanResponses(2500);
    if (plan === 'pro' && planResponses < 10000) setPlanResponses(user.billing.responses || 10000);

    setAllowQuantityUpdate(true);
    setSelectedUsersNumber(newUserNumber < 0 ? 0 : newUserNumber);
    setSelectedPlan(plan);
    setShowUpdatePlan(true);
  };

  const upgrade = (plan) => {
    const newUserNumber = user.planUsage.users - 1;

    setSelectedUsersNumber(newUserNumber < 0 ? 0 : newUserNumber);
    setSelectedPlan(plan);

    if (plan === 'personal') setPlanResponses(500);
    if (plan === 'starter') setPlanResponses(2500);
    if (plan === 'pro' && planResponses < 10000) setPlanResponses(user.billing.responses || 10000);

    if (user.billing && user.billing.plan !== null && user.billing.plan !== 'trial') {
      setAllowQuantityUpdate(true);
      setShowUpdatePlan(true);
    } else {
      setAllowQuantityUpdate(false);
      setShowUpdateInvoiceInformation(true);
    }
  };

  const onUpdateInvoiceInformationSuccess = () => {
    setTimeout(() => setShowUpdateInvoiceInformation(false), 1000);
    setTimeout(() => setShowInitializePlan(true), 950);
  };

  const handleUpdate = () => {
    setAllowQuantityUpdate(true);
    setSelectedPlan(user.billing.plan);
    setSelectedUsersNumber(user.billing.plan === 'personal' ? 1 : user.planUsage.users);
    setShowUpdatePlan(true);
  };

  useEffect(() => {
    // Allow viewing in development for testing porpoises
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    if (user.role !== 'holder') setPage('profile');
  }, [user.role, setPage]);

  useEffect(() => {
    if (user.billing.responses) setPlanResponses(user.billing.responses);
  }, [user.billing.responses]);

  return (
    <>
      <Table onUpgrade={upgrade} onDowngrade={downgrade} onUpdate={handleUpdate} responses={planResponses} setResponses={setPlanResponses} />

      {showInitializePlan && (
        <InitializePlan
          show={showInitializePlan}
          onClose={() => setShowInitializePlan(false)}
          selectedPlan={selectedPlan}
          selectedUsersNumber={selectedUsersNumber}
          planResponses={planResponses}
        />
      )}
      {showUpdatePlan && (
        <UpdatePlan
          show={showUpdatePlan}
          onClose={() => setShowUpdatePlan(false)}
          selectedPlan={selectedPlan}
          allowPaymentInformationChange={true}
          allowQuantityUpdate={allowQuantityUpdate}
          planResponses={planResponses}
        />
      )}
      {showUpdateInvoiceInformation && (
        <UpdateInvoiceInformation
          show={showUpdateInvoiceInformation}
          onClose={() => setShowUpdateInvoiceInformation(false)}
          onSuccess={onUpdateInvoiceInformationSuccess}
          submitText="Continue to payment info"
          selectedUsersNumber={selectedUsersNumber}
          setSelectedUsersNumber={setSelectedUsersNumber}
          selectedPlan={selectedPlan}
          showCollaboratorsSelect={selectedPlan !== 'personal'}
          title={
            <div>
              Upgrading to <span className={styles[selectedPlan]}>{selectedPlan}</span>
            </div>
          }
        />
      )}
    </>
  );
};

export default Upgrade;
