import axios from 'axios';

import config from 'config.js';

// Constants
const CLEAR = 'plan/CLEAR';
const UPDATE_FORM = 'plan/UPDATE_FORM';
const UPDATE_TAB = 'plan/UPDATE_TAB';
const SAVE_TMP_PLAN = 'plan/SAVE_TMP_PLAN';
const UPDATE_LOADING = 'plan/UPDATE_LOADING';
const UPDATE_CREDIT_CARD_ERROR_MESSAGE = 'plan/UPDATE_CREDIT_CARD_ERROR_MESSAGE';
const UPDATE_FORM_ERRORS = 'plan/UPDATE_FORM_ERRORS';
const UPDATE_SELECTED_PLAN = 'plan/UPDATE_SELECTED_PLAN';
const UPDATE_SELECTED_PLAN_TYPE = 'plan/UPDATE_SELECTED_PLAN_TYPE';
const RESET_SELECTED_PLAN = 'plan/RESET_SELECTED_PLAN';
const SET_PROTATION_PREVIEW_DATE = 'plan/SET_PROTATION_PREVIEW_DATE';
const UPDATE_PROTATION_LOADING = 'plan/UPDATE_PROTATION_LOADING';
const UPDATE_TMP_LTD_PLAN = 'plan/UPDATE_TMP_LTD_PLAN';
const UPDATE_TMP_LTD_USERS = 'plan/UPDATE_TMP_LTD_USERS';

const GET_USER_SUCCESS = '_users/GET_USER_SUCCESS';

const CONVERT_TO_LTD_REQUEST = 'plan/CONVERT_TO_LTD_REQUEST';
const CONVERT_TO_LTD_SUCCESS = 'plan/CONVERT_TO_LTD_SUCCESS';
const CONVERT_TO_LTD_FAILURE = 'plan/CONVERT_TO_LTD_FAILURE';

const CREATE_MOLLIE_ORDER_REQUEST = 'plan/CREATE_MOLLIE_ORDER_REQUEST';
const CREATE_MOLLIE_ORDER_SUCCESS = 'plan/CREATE_MOLLIE_ORDER_SUCCESS';
const CREATE_MOLLIE_ORDER_FAILURE = 'plan/CREATE_MOLLIE_ORDER_FAILURE';

const GET_MOLLIE_ORDER_REQUEST = 'plan/GET_MOLLIE_ORDER_REQUEST';
const GET_MOLLIE_ORDER_SUCCESS = 'plan/GET_MOLLIE_ORDER_SUCCESS';
const GET_MOLLIE_ORDER_FAILURE = 'plan/GET_MOLLIE_ORDER_FAILURE';

const UPDATE_COLLECTION_METHOD_REQUEST = 'plan/UPDATE_COLLECTION_METHOD_REQUEST';
const UPDATE_COLLECTION_METHOD_SUCCESS = 'plan/UPDATE_COLLECTION_METHOD_SUCCESS';
const UPDATE_COLLECTION_METHOD_FAILURE = 'plan/UPDATE_COLLECTION_METHOD_FAILURE';

const SAVE_USER_SOURCE_REQUEST = 'plan/SAVE_USER_SOURCE_REQUEST';
const SAVE_USER_SOURCE_SUCCESS = 'plan/SAVE_USER_SOURCE_SUCCESS';
const SAVE_USER_SOURCE_FAILURE = 'plan/SAVE_USER_SOURCE_FAILURE';

const SAVE_CUSTOMER_ADDRESS_REQUEST = 'plan/SAVE_CUSTOMER_ADDRESS_REQUEST';
const SAVE_CUSTOMER_ADDRESS_SUCCESS = 'plan/SAVE_CUSTOMER_ADDRESS_SUCCESS';
const SAVE_CUSTOMER_ADDRESS_FAILURE = 'plan/SAVE_CUSTOMER_ADDRESS_FAILURE';

const ASSIGN_SUBSCRIPTION_REQUEST = 'plan/ASSIGN_SUBSCRIPTION_REQUEST';
const ASSIGN_SUBSCRIPTION_SUCCESS = 'plan/ASSIGN_SUBSCRIPTION_SUCCESS';
const ASSIGN_SUBSCRIPTION_FAILURE = 'plan/ASSIGN_SUBSCRIPTION_FAILURE';

const CANCEL_SUBSCRIPTION_REQUEST = 'plan/CANCEL_SUBSCRIPTION_REQUEST';
const CANCEL_SUBSCRIPTION_SUCCESS = 'plan/CANCEL_SUBSCRIPTION_SUCCESS';
const CANCEL_SUBSCRIPTION_FAILURE = 'plan/CANCEL_SUBSCRIPTION_FAILURE';

const GET_PROTATION_PREVIEW_REQUEST = 'plan/GET_PROTATION_PREVIEW_REQUEST';
const GET_PROTATION_PREVIEW_SUCCESS = 'plan/GET_PROTATION_PREVIEW_SUCCESS';
const GET_PROTATION_PREVIEW_FAILURE = 'plan/GET_PROTATION_PREVIEW_FAILURE';

const UPDATE_SUBSCRIPTION_REQUEST = 'plan/UPDATE_SUBSCRIPTION_REQUEST';
const UPDATE_SUBSCRIPTION_SUCCESS = 'plan/UPDATE_SUBSCRIPTION_SUCCESS';
const UPDATE_SUBSCRIPTION_FAILURE = 'plan/UPDATE_SUBSCRIPTION_FAILURE';

const GET_COUPON_REQUEST = 'plan/GET_COUPON_REQUEST';
const GET_COUPON_SUCCESS = 'plan/GET_COUPON_SUCCESS';
const GET_COUPON_FAILURE = 'plan/GET_COUPON_FAILURE';
const CLEAR_COUPON = 'plan/CLEAR_COUPON';

const GET_VAT_REQUEST = 'plan/GET_VAT_REQUEST';
const GET_VAT_SUCCESS = 'plan/GET_VAT_SUCCESS';
const GET_VAT_FAILURE = 'plan/GET_VAT_FAILURE';

// Initiual State
const initialState = {
  tab: 'form',
  prorationPreviewDate: null,
  prorate: 0,
  prorateLoading: false,
  coupon: {},
  vatRate: 0,
  tmpPlan: {
    forms: 0,
    submissions: 0,
    users: 0,
    filesStorage: 0,
    domains: 0,
    brandingRemoval: 0
  },
  tmpLtdPlan: null,
  tmpLtdUsers: null,
  selectedPlan: {
    forms: 0,
    submissions: 0,
    users: 0,
    filesStorage: 0,
    domains: 0,
    brandingRemoval: 0
  },
  selectedPlanType: 'flex',
  loading: false,
  form: {
    collectionMethod: 'charge_automatically'
  },
  formErrors: {},
  creditCardErrorMessage: null,
  tmpLtdStacks: null
};

const clearInitialState = JSON.parse(JSON.stringify(initialState));

let getProrationPreviewCancelTokens = [];

// Reducer
export default function reducer(state = initialState, action = {}) {
  if (action.type === CLEAR) {
    return { ...JSON.parse(JSON.stringify(clearInitialState)) };
  }

  if (action.type === RESET_SELECTED_PLAN) {
    state.selectedPlan = { ...action.payload.plan };

    return { ...state };
  }

  if (action.type === SET_PROTATION_PREVIEW_DATE) {
    state.prorationPreviewDate = action.payload.date;

    return { ...state };
  }

  if (action.type === GET_USER_SUCCESS) {
    state.selectedPlan = { ...JSON.parse(JSON.stringify(action.payload.plan)) };
    state.selectedPlanType = action.payload.planType;

    return { ...state };
  }

  if (action.type === UPDATE_SELECTED_PLAN) {
    state.selectedPlan[action.payload.key] = Math.round((action.payload.value) * 100) / 100;
    state.selectedPlan = { ...state.selectedPlan };

    return { ...state };
  }

  if (action.type === UPDATE_SELECTED_PLAN_TYPE) {
    state.selectedPlanType = action.payload.value;;

    return { ...state };
  }
  
  if (action.type === UPDATE_FORM) {
    state.form[action.payload.key] = action.payload.value;

    if (action.payload.validate) {
      state.formErrors[action.payload.key] = !state.form[action.payload.key];
    }

    state.form = { ...state.form };

    return { ...state };
  }

  if (action.type === UPDATE_FORM_ERRORS) {
    state.formErrors[action.payload.key] = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_TAB) {
    state.tab = action.payload.tab;

    return { ...state };
  }

  if (action.type === SAVE_TMP_PLAN) {
    state.tmpPlan = { ...action.payload.plan };

    return { ...state };
  }

  if (action.type === UPDATE_LOADING) {
    state.loading = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_CREDIT_CARD_ERROR_MESSAGE) {
    state.creditCardErrorMessage = action.payload.value;

    return { ...state };
  }

  if (action.type === GET_PROTATION_PREVIEW_REQUEST) {
    state.prorateLoading = true;

    return { ...state };
  }

  if (action.type === GET_PROTATION_PREVIEW_SUCCESS) {
    state.prorate = action.payload.price;
    state.prorateLoading = false;

    return { ...state };
  }

  if (action.type === GET_PROTATION_PREVIEW_FAILURE) {
    state.prorateLoading = action.payload;

    return { ...state };
  }

  if (action.type === UPDATE_PROTATION_LOADING) {
    state.prorateLoading = action.payload.value;

    return { ...state };
  }

  if (action.type === GET_COUPON_REQUEST) {
    state.loading = true;

    return { ...state };
  }

  if (action.type === GET_COUPON_SUCCESS) {
    state.loading = false;
    state.coupon = action.payload;

    return { ...state };
  }

  if (action.type === GET_COUPON_FAILURE) {
    state.loading = false;

    return { ...state };
  }

  if (action.type === CLEAR_COUPON) {
    state.coupon = {};

    return { ...state };
  }

  if (action.type === GET_VAT_SUCCESS) {
    state.vatRate = action.payload.rate;

    return { ...state };
  }
  
  if (action.type === UPDATE_TMP_LTD_PLAN) {
    state.tmpLtdPlan = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_TMP_LTD_USERS) {
    state.tmpLtdUsers = action.payload.value;

    return { ...state };
  }

  return state;
}

// Action Creators
export function clear() {
  return async (dispatch) => {
    return dispatch({ type: CLEAR });
  };
}

export function updateSelectedPlan(key, value) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_SELECTED_PLAN, payload: { key, value } });
  };
}

export function resetSelectedPlan() {
  return async (dispatch, getState) => {
    const state = getState();

    if (!state._users.user.plan) return;

    const plan = JSON.parse(JSON.stringify(state._users.user.plan));

    return dispatch({ type: RESET_SELECTED_PLAN, payload: { plan } });
  };
}

export function updateFormErrors(key, value) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_FORM_ERRORS, payload: { key, value } });
  };
}

export function updateForm(key, value, validate) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_FORM, payload: { key, value, validate } });
  };
}

export function updateLoading(value) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_LOADING, payload: { value } });
  };
}

export function updateSelectedPlanType(value) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_SELECTED_PLAN_TYPE, payload: { value } });
  };
}

export function updateTab(tab) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_TAB, payload: { tab } });
  };
}

export function setProrationPreviewDate(date) {
  return async (dispatch) => {
    return dispatch({ type: SET_PROTATION_PREVIEW_DATE, payload: { date } });
  };
}

export function saveTmpPlan() {
  return async (dispatch, getState) => {
    const state = getState();
    const plan = JSON.parse(JSON.stringify(state.plan.selectedPlan));

    return dispatch({ type: SAVE_TMP_PLAN, payload: { plan } });
  };
}

export function updateCreditCardErrorMessage(value) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_CREDIT_CARD_ERROR_MESSAGE, payload: { value } });
  };
}

export function updateProrateLoading(value) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_PROTATION_LOADING, payload: { value } });
  };
}

export function updateTmpLtdPlan(value) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_TMP_LTD_PLAN, payload: { value } });
  };
}

export function updateTmpLtdUsers(value) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_TMP_LTD_USERS, payload: { value } });
  };
}


export function clearCoupon() {
  return async (dispatch) => {
    return dispatch({ type: CLEAR_COUPON });
  };
}

export function updateCollectionMethod() {
  const request = () => { return { type: UPDATE_COLLECTION_METHOD_REQUEST } };
  const success = (data) => { return { type: UPDATE_COLLECTION_METHOD_SUCCESS, payload: data } };
  const failure = () => { return { type: UPDATE_COLLECTION_METHOD_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.updateCollectionMethod(state._users.token, state.plan.form);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function saveUserSource(value) {
  const request = () => { return { type: SAVE_USER_SOURCE_REQUEST } };
  const success = (data) => { return { type: SAVE_USER_SOURCE_SUCCESS, payload: data } };
  const failure = () => { return { type: SAVE_USER_SOURCE_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.saveUserSource(state._users.token, value);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function saveCustomerAddress() {
  const request = () => { return { type: SAVE_CUSTOMER_ADDRESS_REQUEST } };
  const success = () => { return { type: SAVE_CUSTOMER_ADDRESS_SUCCESS } };
  const failure = () => { return { type: SAVE_CUSTOMER_ADDRESS_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.saveCustomerAddress(state._users.token, state.plan.form);

    if (response) {
      return dispatch(success());
    } else {
      return dispatch(failure());
    }
  };
}

export function assignSubscription() {
  const request = () => { return { type: ASSIGN_SUBSCRIPTION_REQUEST } };
  const success = () => { return { type: ASSIGN_SUBSCRIPTION_SUCCESS } };
  const failure = () => { return { type: ASSIGN_SUBSCRIPTION_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.assignSubscription(state._users.token);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}

export function createMollieOrder(data) {
  const request = () => { return { type: CREATE_MOLLIE_ORDER_REQUEST } };
  const success = () => { return { type: CREATE_MOLLIE_ORDER_SUCCESS } };
  const failure = () => { return { type: CREATE_MOLLIE_ORDER_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.createMollieOrder(state._users.token, data);

    if (response) {
      dispatch(success(response));

      return Promise.resolve(response);
    } else {
      dispatch(failure());

      return Promise.reject();
    }
  };
}

export function getMollieOrder(id) {
  const request = () => { return { type: GET_MOLLIE_ORDER_REQUEST } };
  const success = () => { return { type: GET_MOLLIE_ORDER_SUCCESS } };
  const failure = () => { return { type: GET_MOLLIE_ORDER_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getMollieOrder(state._users.token, id);

    if (response) {
      dispatch(success(response));

      return Promise.resolve(response);
    } else {
      dispatch(failure());

      return Promise.reject();
    }
  };
}

export function publicGetMollieOrder(id) {
  return async () => {
    const response = await services.publicGetMollieOrder(id);

    if (response) {
      return Promise.resolve(response);
    } else {
      return Promise.reject();
    }
  };
}

export function cancelSubscription() {
  const request = () => { return { type: CANCEL_SUBSCRIPTION_REQUEST } };
  const success = () => { return { type: CANCEL_SUBSCRIPTION_SUCCESS } };
  const failure = () => { return { type: CANCEL_SUBSCRIPTION_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.cancelSubscription(state._users.token);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function updateSubscription() {
  const request = () => { return { type: UPDATE_SUBSCRIPTION_REQUEST } };
  const success = () => { return { type: UPDATE_SUBSCRIPTION_SUCCESS } };
  const failure = () => { return { type: UPDATE_SUBSCRIPTION_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.updateSubscription(
      state._users.token,
      state.plan.selectedPlan,
      state.plan.selectedPlanType,
      state.plan.prorationPreviewDate,
      state.plan.prorate
    );

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function getCoupon(id) {
  const request = () => { return { type: GET_COUPON_REQUEST } };
  const success = (res) => { return { type: GET_COUPON_SUCCESS, payload: res } };
  const failure = () => { return { type: GET_COUPON_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getCoupon(state._users.token, id);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function getProrationPreview() {
  const request = () => { return { type: GET_PROTATION_PREVIEW_REQUEST } };
  const success = (data) => { return { type: GET_PROTATION_PREVIEW_SUCCESS, payload: data } };
  const failure = (canceled) => { return { type: GET_PROTATION_PREVIEW_FAILURE, payload: canceled } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();

    const response = await services.getProrationPreview(
      state._users.token,
      state.plan.selectedPlan,
      state.plan.selectedPlanType,
      state.plan.prorationPreviewDate
    );

    if (response.status === 200) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response === true));
    }
  };
}

export function getVat(data) {
  const request = () => { return { type: GET_VAT_REQUEST } };
  const success = (res) => { return { type: GET_VAT_SUCCESS, payload: res } };
  const failure = () => { return { type: GET_VAT_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getVat(state._users.token, data);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}


export function convertToLtd() {
  const request = () => { return { type: CONVERT_TO_LTD_REQUEST } };
  const success = () => { return { type: CONVERT_TO_LTD_SUCCESS } };
  const failure = () => { return { type: CONVERT_TO_LTD_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.convertToLtd(state._users.token);

    if (response) {
      dispatch(success());

      return Promise.resolve();
    } else {
      dispatch(failure());

      return Promise.reject();
    }
  };
}

const services = {
  convertToLtd: async (token) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/billing/ltdconvert`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  saveUserSource: async (token, sourceId) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/billing/source`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data: {
          sourceId
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  saveCustomerAddress: async (token, form) => {
    let data = {
      user: {
        name: form.name,
        email: form.email,
        address: {
          line1: form.address1 || '',
          line2: form.address2 || '',
          city: form.city || '',
          country: form.country,
          postal_code: form.postal || ''
        }
      }
    };

    if (form.vatEu) {
      data.vatEu = {
        type: 'eu_vat',
        value: form.vatEu
      };
    }

    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/billing/address`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  assignSubscription: async (token) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/billing/subscription`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  createMollieOrder: async (token, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/billing/mollie`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }, data
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  getMollieOrder: async (token, id) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/billing/mollie/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  publicGetMollieOrder: async (id) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/mollie/order/${id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  cancelSubscription: async (token) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${config.apiUrl}/billing/subscription`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateCollectionMethod: async (token, form) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/billing/collectionmethod`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data: {
          collectionMethod: form.collectionMethod
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  getProrationPreview: async (token, plan, planType, prorationDate) => {
    const CancelToken = axios.CancelToken;

    try {
      getProrationPreviewCancelTokens.map((cancel) => {
        cancel();
      });

      getProrationPreviewCancelTokens = [];

      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/proration/preview`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data: {
          plan, planType, prorationDate
        },
        cancelToken: new CancelToken((cancel) => {
          getProrationPreviewCancelTokens.push(cancel);
        })
      });

      return response;
    } catch (e) {
      return axios.isCancel(e);
    }
  },
  updateSubscription: async (token, plan, planType, prorationDate, prorate) => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${config.apiUrl}/billing/subscription`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data: {
          plan, planType, prorationDate, prorate
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  getCoupon: async (token, id) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/coupons/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  getVat: async (token, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/billing/vat`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response.data;
    } catch (e) {
      return false;
    }
  }
};
